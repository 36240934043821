import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import { dispatchContext } from "../../Statemanagement/Statecontext";
import styled from "styled-components";
import "./CampaignJourney.css";
import Button from "@mui/material/Button";
import Axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import useWindowDimensions from "../../customAPIs/useWindowDimensions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isAuthenticated, ApiToken } from "../../Helper/Endpoints/Endpoints";
import { Link } from "react-router-dom";
import Spinner from "../Loader/Spinner";
import Pagination from "@mui/material/Pagination";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select2 from "@mui/material/Select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TextField } from "@mui/material";
import { Cancel } from "@material-ui/icons";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageCropper from "../ImageCropper/ImageCropper";
import { getCroppedImageService } from "../Generic/messageService";
import Image from "../../Components/Images/upload_library_asset.png";

let ctaId = "";
let heroId = "1088";
let designId = "";
let objectiveId = "";

const Wrapper = styled.div`
	display: flex;
	background: #414652;
	box-shadow: 0px 4px 30px 3px #030023;
	border-radius: 36px;
	width: 100%;
	height: 84vh;
	margin-top: 10px;
	margin-right: 2vh;
`;
const CreateDiv = styled.div`
	margin: 2vh auto;
`;

const BodyContainer = styled.div`
	color: rgba(255, 255, 255, 1);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	@media (min-width: 1278px) {
		width: 100%;
	}
`;

const ButtonWrapper = styled.div`
	//   margin-top: 10%;
	//   padding; 5px;
	text-align: center;
`;
const Title = styled.div`
	text-align: left;
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 3vh;
	margin-top: 4vh;
	margin-bottom: 3vh;
	margin-right: 1vh;
	text-align: center;
	color: #c8daff;
`;

const TagsDiv = styled.div`
	background-color: #343943;
	color: rgba(255, 255, 255, 0.5);
	padding: 2vh 5vh;
	border-radius: 100px;
	border: 2px solid #555b68;
	margin-right: 1vh;
	margin-bottom: 1vh;
	cursor: pointer;
	color: #eaebec;
	font-size: 16px;
`;

var flag = false;
var text_1_length = 0;

function CampaignJourney(props) {
	// const [textOptions, setTextOptions]= useState([]);

	const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();
	const [step, setStep] = useState(0);
	const { width } = useWindowDimensions();
	const [selectedText, setSelectedText] = useState("");
	const TitlesArray = ["Template", "Post Topic"];
	// const SubTitlesArray = ['Choose the template of your preferred ratio', 'Which side do you want your text to be?', 'Select or add a maximum of 3 tags to describe your query. Each tag should be a single word only.', 'Select text from our recommendations below, or enter your text manually by clicking on generate', 'Select or add an additional phrase that will go on your creative. E.g.- "Read More" '];
	const [selectedThumbnail, setSelectedThumbnail] = useState(true);
	const [selectedAdditionalThumbnail, setSelectedAdditionalThumbnail] =
		useState(true);
	const [tagsInput, setTagsInput] = useState("");
	const dispatch = useContext(dispatchContext);
	// const [selectedObjective, setSelectedObjective] = useState('');
	const [selectedRatio, setSelectedRatio] = useState(0);
	const [inLoading, setInLoading] = useState(false);
	const [generatedId, setGeneratedId] = useState(0);
	const { userRegistered } = isAuthenticated() ? isAuthenticated() : ApiToken();

	const [loading, setLoading] = useState(false);
	const [numberOfTemplatePages, setNumberOfTemplatePages] = useState(1);
	const [currentTemplatePage, setCurrentTemplatePage] = useState(1);
	const [selectedTemplateType, setSelectedTemplateType] = useState("allIdeas");

	const [TemplatesThumbnail, setTemplatesThumbnail] = useState([
		{
			newest_thumbnails: [
				{
					id: 1,
					title: "Templates coming soon..",
					ids: "0:0",
					url: "null",
					has_additional_text1: true,
					has_additional_text2: true,
					// "count": 0
				},
			],
		},
	]);

	const [errors, setErrors] = useState({
		objectives: "",
		design: "",
		tags: "",
		heroText: "",
		cta: "",
	});

	const [secondaryErrors, setSecondaryErrors] = useState({
		objectives: "require",
		tags: "require",
	});

	const [fetchingUploads, setFetchingUploads] = useState(false);
	const [user_assets, setUserAssets] = useState([]);
	const [modalIsOpen, setmodalIsOpen] = useState(false);
	const [cropperIsOpen, setcropperIsOpen] = useState(false);
	const [selectedImage, setselectedImage] = useState(null);
	const [ImageURLs, setImageURLs] = useState({});
	const [uniqueImages, setUniqueImages] = useState([]);
	const [cropperPosition, setcropperPosition] = useState({});
	const [assetNote, setassetNote] = useState("");
	const [addingAssets, setaddingAssets] = useState(false);
	const [assetClicked, setAssetClicked] = useState(false);
	const [selectedAsset, setSelectedAsset] = useState({});

	const newCampaign = async () => {
		flag = false;
		text_1_length = 0;
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();

		dispatch({ type: "LOADING" });

		const delete_campaign_response = await Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND}/delete_query_result/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			data: {
				generated_id: generatedId,
			},
		});

		setErrors({
			objectives: "",
			design: "",
			tags: "",
			heroText: "",
			cta: "",
		});
	};

	function openModal() {
		setmodalIsOpen(true);
	}

	function closeModal() {
		setmodalIsOpen(false);
	}

	function onFileChange(e) {
		let images = {};
		let position = {};

		for (let file of e.target.files) {
			var url = URL.createObjectURL(file);
			images[url] = file;
			position[url] = {};
		}

		setselectedImage(Object.keys(images)[0]);
		setImageURLs(images);
		setcropperPosition(position);
	}

	function addAsset() {
		setaddingAssets(true);
		var form_data = new FormData();
		form_data.append("asset_note", assetNote);
		for (const [img, file] of Object.entries(ImageURLs))
			form_data.append("assets", file);

		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();
		var requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/upload_multiple_user_assets/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				toast.success("Asset added to Library", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				setaddingAssets(false);
				setselectedImage(null);
				setassetNote("");
				setImageURLs({});

				let temp_user_assets = [];
				for (let ele of data.data) temp_user_assets.push(ele[0]);

				var new_assets = temp_user_assets.concat(user_assets);
				setUserAssets(new_assets);
				updateUniqueImages(new_assets);
			})
			.catch((err) => {
				console.log("Error: " + err);
				toast.error("Asset failed to be added to Library", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				setaddingAssets(false);
				setselectedImage(null);
				setassetNote("");
				setImageURLs({});
			});
	}

	function sameAssetNote(asset_note) {
		let arr = [];
		user_assets.map((asset) => {
			if (asset.asset_note === asset_note) arr.push(asset);
		});
		setassetNote(asset_note);
	}

	function handleTextFieldChange(e) {
		setassetNote(e.target.value);
	}

	function updateUniqueImages(data) {
		var res = data.reduce(function (a, b) {
			if (!a[b["asset_note"]]) {
				a[b["asset_note"]] = b;
			}
			return a;
		}, {});
		setUniqueImages(Object.keys(res).map((key) => res[key]));
	}

	useEffect(() => {
		if (window.Tawk_API) {
			window.Tawk_API.hideWidget();
		}

		document.title = "MyEra | Create";
		setLoading(false);
		setFetchingUploads(true);
		const getData = async () => {
			const { accessToken } = isAuthenticated()
				? isAuthenticated()
				: ApiToken();
			const headers = {
				Authorization: `Bearer ${accessToken}`,
			};
			var requestOptions = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			};

			fetch(`${process.env.REACT_APP_BACKEND}/get_user_assets/`, { headers })
				.then((response) => response.json())
				.then((data) => {
					console.log("API CALL!");
					// Getting only product assets
					var updated_data = data.filter(function (asset) {
						return asset.is_background != true;
					});
					setUserAssets(updated_data);

					// First Images for each asset note
					updateUniqueImages(updated_data);
					setFetchingUploads(false);
				});

			const designs_response = await Axios.get(
				`${process.env.REACT_APP_BACKEND}/get_design_types/`
			);

			setInLoading(true);
			const TemplatesThumbnails = await Axios({
				method: "GET",
				// url:`${process.env.REACT_APP_BACKEND}/get_templates/0/`,
				url: `${process.env.REACT_APP_BACKEND}/get_templates/0?page=${currentTemplatePage}`,
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});

			setCurrentTemplatePage(TemplatesThumbnails.data.page_number);
			setNumberOfTemplatePages(TemplatesThumbnails.data.total_pages);
			// setTemplatesThumbnail(TemplatesThumbnails.data[0].user_specific_thumbnails.concat(TemplatesThumbnails.data[1].most_chosen_thumbnails.concat(TemplatesThumbnails.data[2].industry_specific_thumbnails.concat(TemplatesThumbnails.data[3].newest_thumbnails))));
			setTemplatesThumbnail(
				TemplatesThumbnails.data.templates.filter((ThumbNail) => {
					return ThumbNail.is_product_mockup === true;
				})
			);
			setInLoading(false);

			// const TextJson = await Axios({
			//     method: 'GET',
			//     url: `${process.env.REACT_APP_BACKEND}/get_text_suggestions/`,
			//     headers: {
			//         Authorization: `Bearer ${accessToken}`
			//     }
			// });
			// var dummy =[];
			// dummy.push(TextJson.data.default[0].groupedText);
			// for(var i =0;i<TextJson.data.user_specific.length; i++){
			//     dummy.push(TextJson.data.user_specific[i].groupedText);
			// }
			// for(var i =0;i<TextJson.data.industry_specific.length; i++){
			//     dummy.push(TextJson.data.industry_specific[i].groupedText);
			// }
			// for(var i =0;i<TextJson.data.common.length; i++){
			//     dummy.push(TextJson.data.common[i].groupedText);
			// }
			// setTextOptions(dummy);
		};
		getData();
	}, []);

	useEffect(() => {
		getCroppedImageService.getMessage().subscribe((message) => {
			var byteString = atob(message.imageURL.split(",")[1]);
			var mimeString = message.imageURL
				.split(",")[0]
				.split(":")[1]
				.split(";")[0];
			var ab = new ArrayBuffer(byteString.length);
			var ia = new Uint8Array(ab);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			var blob = new Blob([ab], { type: mimeString });
			var file = new File([blob], "image.png");

			let updated = ImageURLs;
			let pos = cropperPosition;
			updated[message.originalURL] = file;
			pos[message.originalURL] = message.position;

			// setImageURLs(updated);
			setcropperPosition(pos);
		});
	}, [ImageURLs]);

	async function onTemplatePageChange(event, page_number) {
		setInLoading(true);
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();

		const TemplatesThumbnails = await Axios({
			method: "GET",
			// url:`${process.env.REACT_APP_BACKEND}/get_templates/0/`,
			url: `${process.env.REACT_APP_BACKEND}/get_templates/${selectedRatio}?page=${page_number}`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		console.log(TemplatesThumbnails.data);

		setCurrentTemplatePage(TemplatesThumbnails.data.page_number);
		setNumberOfTemplatePages(TemplatesThumbnails.data.total_pages);
		// setTemplatesThumbnail(TemplatesThumbnails.data[0].user_specific_thumbnails.concat(TemplatesThumbnails.data[1].most_chosen_thumbnails.concat(TemplatesThumbnails.data[2].industry_specific_thumbnails.concat(TemplatesThumbnails.data[3].newest_thumbnails))));
		setTemplatesThumbnail(
			TemplatesThumbnails.data.templates.filter((ThumbNail) => {
				return ThumbNail.is_product_mockup === true;
			})
		);
		setInLoading(false);
	}

	const onChangeText = (item) => {
		setSelectedText(item);
		setErrors({
			objectives: errors.objectives,
			design: errors.design,
			tags: errors.tags,
			text: "",
			heroText: errors.heroText,
			cta: "",
		});
	};
	const customStylesModal = {
		content: {
			backgroundColor: "rgba(1,15,30,255)",

			borderRadius: "3vh",
		},
		overlay: {
			position: "fixed",

			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
		},
	};

	const TemplatesTypeThumbnails = async (template_ratio_id) => {
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();
		setCurrentTemplatePage(1);
		setInLoading(true);
		const TemplatesThumbnails = await Axios({
			method: "GET",
			url: `${process.env.REACT_APP_BACKEND}/get_templates/${template_ratio_id}?page=1`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		setCurrentTemplatePage(TemplatesThumbnails.data.page_number);
		setNumberOfTemplatePages(TemplatesThumbnails.data.total_pages);
		setSelectedRatio(template_ratio_id);
		setTemplatesThumbnail(
			TemplatesThumbnails.data.templates.filter((ThumbNail) => {
				return ThumbNail.is_product_mockup === true;
			})
		);
		setCurrentTemplatePage(1);
		setInLoading(false);
	};

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			borderBottom: "1px solid rgba(33, 42, 64, 1)",
			backgroundColor: "#121828",
			color: state.isSelected
				? "rgba(255, 255, 255, 1)"
				: "rgba(255, 255, 255, 0.3)",
			padding: 20,
		}),
		control: (_, { selectProps: { width } }) => ({
			display: "flex",
			border: "solid 1px rgba(132, 232, 244, 1)",
			color: "black",
			borderRadius: "1vh",
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";

			return { ...provided, opacity, transition, color: "black" };
		},
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: "rgba(36, 171, 226, 0.24)",
				color: "#24abe2",
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#24abe2",
		}),
	};

	const theme = createTheme({
		palette: {
			mode: "dark",
			primary: {
				main: "rgba(98, 77, 232, 1)",
			},
		},
	});

	const lightTheme = createTheme({
		palette: {
			mode: "light",
			primary: {
				main: "rgba(98, 77, 232, 1)",
			},
		},
	});

	const modalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			backgroundColor: "rgba(1,15,30,255)",
			borderRadius: "3vh",
			textAlign: "center",
			height: "80%",
			width: "50%",
			color: "#FFFFFF",
			padding: "0%",
		},

		overlay: {
			background: "rgba(0, 0, 0, 0.25)",
		},
	};

	// function setTextParams(){
	//     ctaId = selectedText.cta_id;
	//     if(typeof ctaId==='undefined') ctaId=textOptions[0].cta_id;
	//     heroId = selectedText.hero_id;
	//     if(typeof heroId==='undefined') heroId=textOptions[0].hero_id;
	// }
	const getStarted = async () => {
		setStep((step) => step + 1);
	};

	const handleTagsInput = (event) => {
		setTagsInput(event.target.value);
	};

	return (
		<>
			<div className="semi-outer-container">
				<CircularProgress
					style={{
						marginBottom: "25%",
						marginTop: "25%",
						display: fetchingUploads ? "block" : "none",
						color: "rgb(132, 232, 244)",
					}}
				></CircularProgress>
				<div
					className="left"
					style={{ display: !fetchingUploads ? "block" : "none", width: "85%" }}
				>
					<div
						{...props}
						style={{ ...customStylesModal, width: "100%" }}
						// style={{width:'100%'}}
						className={width < 1287 ? `Modal` : ""}
						overlayClassName={width < 1287 ? "Overlay" : ""}
						contentLabel="Example Modal"
					>
						<div style={{ marginTop: "3vh" }}>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Wrapper>
									<BodyContainer>
										{step === 0 && (
											<>
												<div
													style={{
														fontFamily: "Montserrat, sans-serif",
														fontWeight: "600",
														fontSize: "3vh",
														marginTop: "5vh",
														marginBottom: "3vh",
														marginLeft: "auto",
														textAlign: "center",
														color: "#c8daff",
													}}
												>
													Choose Product
												</div>
												<button
													onClick={() => {
														setassetNote("");
														openModal();
													}}
													style={{
														cursor: "pointer",
														borderRadius: "100px",
														border: "1px solid #84E8F4",
														fontSize: "2vh",
														backgroundColor: "rgba(132, 232, 244, 0.001)",
														color: "white",
														width: "21vh",
														height: "5.3vh",
														display: "flex",
														marginLeft: "auto",
														marginRight: "40px",
													}}
												>
													<AddIcon
														style={{
															color: "#84E8F4",
															margin: "auto",
														}}
													/>
													<div
														style={{
															margin: "auto",
															marginLeft: "-30px",
															color: "#84E8F4",
														}}
													>
														Products
													</div>
												</button>

												{
													<>
														<div
															style={{
																display: "flex",
																flexWrap: "wrap",
																marginLeft: "6.5%",
																fontSize: "20px",
																fontSize: "2vh",
																paddingRight: "6vh",
															}}
														></div>
														<div
															className="uploads"
															style={{
																display: "flex",
																flexWrap: "wrap",
																width: "55%",
																margin: "auto",
																height: "50vh",
																overflowY: "scroll",
																marginBottom: "10px",
															}}
														>
															{uniqueImages.map((asset) => {
																return (
																	<div
																		className="col-sm-4 img-wrap2"
																		style={{ width: "auto" }}
																		key={asset.id}
																	>
																		<div className="overlayDiv">
																			<img
																				className="styleImage2"
																				style={{
																					border:
																						assetClicked &&
																						selectedAsset.id === asset.id
																							? "5px solid #84E8F4"
																							: "",
																				}}
																				src={asset.asset}
																				onClick={() => {
																					sameAssetNote(asset.asset_note);
																					if (assetClicked === false) {
																						setAssetClicked(true);
																						setSelectedAsset(asset);
																					} else setAssetClicked(false);
																				}}
																			></img>
																		</div>
																		<p
																			style={{
																				color: "white",
																				textAlign: "center",
																				fontSize: "17px",
																				marginLeft: "20px",
																			}}
																		>
																			{asset.asset_note}
																		</p>
																	</div>
																);
															})}
														</div>
														<Button
															style={{
																// backgroundColor: 'rgba(98, 77, 232, 1)',
																borderRadius: "100px",
																fontFamily: "Open Sans",
																width: "18vh",
																textTransform: "none",
																height: "5vh",
																marginBottom: "5vh",
																backgroundColor: assetClicked
																	? "#84E8F4"
																	: "#A1B0B1",
																color: "black",
																marginTop: "4%",
																marginLeft: "45%",
															}}
															variant="contained"
															color="secondary"
															onClick={assetClicked ? getStarted : null}
														>
															{"Next"}
															<ArrowForwardIcon
																style={{ marginLeft: "10px" }}
															/>
														</Button>
													</>
												}
											</>
										)}
										{step === 1 && (
											<>
												<Title>{TitlesArray[0]}</Title>
												{/* <SubTitle>{SubTitlesArray[0]}</SubTitle> */}
												{/* <br /> */}

												<div
													style={{
														width: "70%",
														display: "flex",
														margin: "auto",
														marginBottom: "1%",
													}}
												>
													<Button
														style={{
															background: "#84E8F4",
															border: "2px solid #84E8F4",
															borderRadius: "100px",
															color: "#00272C",
														}}
													>
														{assetNote}
													</Button>

													<div
														onClick={() => {
															if (
																errors.cta ===
																"No assets found for these inputs"
															) {
																setErrors((errors) => ({ ...errors, cta: "" }));
															}
															setStep((step) => step - 1);
														}}
														style={{
															color: "#84E8F4",
															cursor: "pointer",
															marginLeft: "4%",
															marginBottom: "1%",
															display: "flex",
															justifyContent: "center",
															alignItems: "end",
															textDecorationLine: "underline",
														}}
													>
														Change Product
													</div>
												</div>

												<ThemeProvider theme={theme}>
													<div
														style={{
															width: "70%",
															display: "flex",
															flexWrap: "wrap",
															margin: "auto",
														}}
													>
														<Button
															id="defaultButton"
															style={{
																background:
																	selectedTemplateType === "allIdeas"
																		? "#84E8F4"
																		: "#343943",
																border:
																	selectedTemplateType === "allIdeas"
																		? "2px solid #84E8F4"
																		: "2px solid #555B68",
																borderRadius: "100px",
																color:
																	selectedTemplateType === "allIdeas"
																		? "#00272C"
																		: "#EAEBEC",
															}}
															onClick={() => {
																setSelectedTemplateType("allIdeas");
																TemplatesTypeThumbnails(0);
															}}
														>
															All Sizes
														</Button>

														<Button
															id="instaPostButton"
															style={{
																background:
																	selectedTemplateType === "instaPost"
																		? "#84E8F4"
																		: "#343943",
																border:
																	selectedTemplateType === "instaPost"
																		? "2px solid #84E8F4"
																		: "2px solid #555B68",
																borderRadius: "100px",
																color:
																	selectedTemplateType === "instaPost"
																		? "#00272C"
																		: "#EAEBEC",
																marginLeft: "2%",
																marginRight: "2%",
															}}
															onClick={() => {
																setSelectedTemplateType("instaPost");
																TemplatesTypeThumbnails(1);
															}}
														>
															1:1 Instagram Post
														</Button>

														<FormControl
															style={{ width: "15%", height: "20px" }}
														>
															<InputLabel
																id="demo-simple-select-label"
																style={{ marginTop: "-7px" }}
															>
																More
															</InputLabel>
															<Select2
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={
																	selectedTemplateType === "allIdeas" ||
																	selectedTemplateType === "instaPost"
																		? "none"
																		: selectedTemplateType === "instaStory"
																		? 3
																		: 4
																}
																label="Category"
																style={{ height: "40px" }}
															>
																{/* <MenuItem value={1} onClick={() => { setSelectedTemplateType('allIdeas'); TemplatesTypeThumbnails(0); }}>All</MenuItem>
                                                            <MenuItem value={2} onClick={() => { setSelectedTemplateType('instaPost'); TemplatesTypeThumbnails(1); }}>Insta Post</MenuItem> */}
																<MenuItem
																	value={3}
																	onClick={() => {
																		setSelectedTemplateType("instaStory");
																		TemplatesTypeThumbnails(2);
																	}}
																>
																	Insta Story
																</MenuItem>
																<MenuItem
																	value={4}
																	onClick={() => {
																		setSelectedTemplateType("presentation");
																		TemplatesTypeThumbnails(3);
																	}}
																>
																	Presentation
																</MenuItem>
															</Select2>
														</FormControl>
														<div
															style={{
																fontSize: "17px",
																marginLeft: "auto",
																alignSelf: "center",
															}}
														>
															<button
																style={{
																	cursor: "pointer",
																	borderRadius: "100px",
																	border: "1px solid #84E8F4",
																	fontSize: "2vh",
																	backgroundColor: "rgba(132, 232, 244, 0.001)",
																	color: "white",
																	width: "28vh",
																	height: "5vh",
																	display: "flex",
																}}
															>
																<AddIcon
																	style={{
																		color: "#84E8F4",
																		margin: "auto",
																	}}
																></AddIcon>
																<a
																	href="https://forms.zohopublic.in/myerapro/form/MyEraTemplateRequest/formperma/CnaRQXKAxLABkPsCzLqIA3oFVpt06f5a_ilkmGPh3TM"
																	target="_blank"
																	style={{
																		display: "flex",
																		textDecoration: "none",
																		margin: "auto",
																		color: "#84E8F4",
																		marginLeft: "-5px",
																	}}
																	rel="noreferrer"
																>
																	{"Request a New Template"}
																</a>
															</button>
														</div>
													</div>
													<br></br>
													<LinearProgress
														style={{
															display: inLoading ? "block" : "none",
															width: "95%",
															marginLeft: "3%",
														}}
														sx={{ color: "rgba(98, 77, 232, 1)" }}
													></LinearProgress>
												</ThemeProvider>

												{loading && <Spinner></Spinner>}

												{!loading && (
													<div
														className="templatesScroll"
														style={{
															width: "70%",
															height: "43vh",
															display: "flex",
															flexWrap: "wrap",
															overflowY: "scroll",
															margin: "auto",
														}}
													>
														{TemplatesThumbnail.map((nm) =>
															nm.url != null ? (
																<CreateDiv
																	key={nm.id}
																	style={{
																		width: selectedRatio === 3 ? "45%" : "15%",
																		borderRadius: "4px",
																		boxSizing: "border-box",
																		margin: "auto",
																	}}
																>
																	<img
																		id={"img" + `${nm.id}`}
																		src={nm.url}
																		alt={"template " + `${nm.id}`}
																		width="100%"
																		height="auto"
																		style={{
																			opacity: "1",
																			display: "block",
																			marginLeft: "auto",
																			marginRight: "auto",
																			borderRadius: "4px",
																			marginBottom: "15%",
																		}}
																		onMouseOver={() => {
																			document.getElementById(
																				"img" + `${nm.id}`
																			).style.cursor = "pointer";
																		}}
																		onClick={() => {
																			if (
																				document.getElementById(
																					"img" + `${nm.id}`
																				).style.opacity === "1"
																			) {
																				TemplatesThumbnail.forEach((a) => {
																					if (a.url != null)
																						document.getElementById(
																							"img" + `${a.id}`
																						).style.opacity = "1";
																				});
																				document.getElementById(
																					"img" + `${nm.id}`
																				).style.opacity = "0.4";

																				console.log("TEMPLATES DATA IS: ", nm);

																				if (nm.has_additional_text1)
																					setSelectedThumbnail(true);
																				else setSelectedThumbnail(false);

																				if (nm.has_additional_text2)
																					setSelectedAdditionalThumbnail(true);
																				else
																					setSelectedAdditionalThumbnail(false);

																				let str = nm.title;
																				const myArr = str.split(":");
																				let str2 = nm.ids;
																				const myArr2 = str2.split(":");
																				//  console.log(myArr2)
																				objectiveId = myArr2[0];
																				designId = myArr2[1];
																				//  setSelectedObjective(myArr2[0]);
																				//  setSelectedDesign(myArr2[1]);
																				// console.log(objectiveId,designId);
																				setErrors({
																					objectives: "",
																					design: "",
																					tags: errors.tags,
																					heroText: errors.heroText,
																					cta: errors.cta,
																				});
																				setSecondaryErrors({
																					objectives: "",
																					tags: secondaryErrors.tags,
																				});
																			} else {
																				document.getElementById(
																					"img" + `${nm.id}`
																				).style.opacity = "1";
																				let str = nm.title;
																				const myArr = str.split(":");
																				let str2 = nm.ids;
																				const myArr2 = str2.split(":");
																				// objectiveId = myArr2[0];
																				objectiveId = "";
																				// designId = myArr2[1];
																				designId = "";
																				console.log(objectiveId, designId);
																				//  setSelectedObjective('');
																				//  setSelectedDesign('');
																			}
																			//  console.log(selectedObjective, selectedDesign);
																		}}
																	/>
																</CreateDiv>
															) : null
														)}
													</div>
												)}

												{errors.objectives && (
													<div
														style={{
															color: "#FE7373",
															width: "69%",
															marginLeft: "auto",
															marginRight: "auto",
															marginTop: "-1%",
														}}
													>
														{errors.objectives}
													</div>
												)}
												<br></br>
												<div
													style={{ display: "flex", justifyContent: "center" }}
												>
													<ThemeProvider theme={lightTheme}>
														<Pagination
															color="primary"
															count={numberOfTemplatePages}
															page={currentTemplatePage}
															onChange={onTemplatePageChange}
														></Pagination>
													</ThemeProvider>
												</div>

												{/* setTimeout(() => {
                                                setLoading(false)
                                            }, 5000); */}

												<Link
													to={{
														pathname: props.pathname
															? props.pathname
															: "/create-now/generate_product_creative_api",
														state: {
															objectiveId: { objectiveId },
															designId: { designId },
															ctaId: { ctaId },
															heroId: { heroId },
															hashTagsSelected: {
																hashTagsSelected: [
																	"#" + selectedAsset.asset_note,
																],
															},
														},
													}}
													style={{ textDecoration: "none" }}
												>
													<Button
														disabled={objectiveId === ""}
														style={{
															// backgroundColor: 'rgba(98, 77, 232, 1)',
															borderRadius: "100px",
															fontFamily: "Open Sans",
															width: "18vh",
															textTransform: "none",
															height: "5vh",
															marginBottom: "5vh",
															backgroundColor: "#84E8F4",
															color: "black",
															marginTop: "-1%",
															marginLeft: "75%",
														}}
														variant="contained"
														color="secondary"
														// onClick={getStarted}
													>
														{"Generate"}
													</Button>
												</Link>
											</>
										)}

										{/* {errors.cta && <p style={{ color: '#FE7373', textAlign: 'left' }}>{errors.cta}</p>} */}
									</BodyContainer>
								</Wrapper>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal isOpen={modalIsOpen} style={modalStyles} ariaHideApp={false}>
				<div>
					<CloseIcon
						style={{
							position: "absolute",
							top: "25px",
							right: "25px",
							cursor: "pointer",
						}}
						onClick={() => {
							setassetNote("");
							closeModal();
						}}
					></CloseIcon>
				</div>

				<div style={{ textAlign: "left", marginTop: "10%", marginLeft: "12%" }}>
					<img
						style={{
							marginRight: "12%",
							float: "right",
							width: "12.3vw",
							height: "25vh",
						}}
						src={Image}
					></img>
					<h3>Create New Product Line</h3>
					<p style={{ color: "grey" }}>
						Upload multiple images of your product at once
						<br></br>and save them under your product name
					</p>

					<br></br>
					<br></br>
					<br></br>
					<TextField
						id="Temp"
						label="Enter Product Name..."
						value={assetNote}
						onChange={handleTextFieldChange}
						autoComplete="off"
						style={{ width: "18vw" }}
						sx={{
							input: { color: "white" },
							"& .MuiOutlinedInput-root": {
								"& > fieldset": { borderColor: "white" },
							},
							"& .MuiOutlinedInput-root:hover": {
								"& > fieldset": { borderColor: "wheat" },
							},
							"& .MuiOutlinedInput-root.Mui-focused:hover": {
								"& > fieldset": {
									borderColor: "#0d6efd",
								},
							},
						}}
						InputLabelProps={{
							sx: { color: "white" },
						}}
						InputProps={{
							sx: { borderRadius: "15px" },
						}}
					/>
				</div>
				<div
					style={{
						marginTop: "8%",
						width: "100%",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<label
						style={{
							display: "inline-block",
						}}
					>
						<input
							accept="image/*"
							id="contained-button-file"
							multiple
							type="file"
							onChange={(e) => onFileChange(e)}
							style={{ display: "none" }}
						/>
						<LoadingButton
							variant="contained"
							component="span"
							loading={addingAssets}
							onClick={() => {
								setcropperIsOpen(true);
							}}
							style={{
								cursor: "pointer",
								textTransform: "none",
								backgroundColor: "#84E8F4",
								color: "black",
								fontWeight: "lighter",
								borderRadius: "100px",
								fontSize: "1.8vh",
								width: "21vh",
								height: "5vh",
							}}
						>
							<FileUploadOutlinedIcon
								style={{
									color: "black",
									margin: "auto",
									fontSize: "2.1vh",
								}}
							/>
							<div
								style={{
									margin: "auto",

									color: "black",
								}}
							>
								Upload Images
							</div>
						</LoadingButton>
					</label>
				</div>
				<hr style={{ margin: "auto", marginTop: "5%", width: "80%" }}></hr>
				<div style={{ textAlign: "left", marginTop: "5%", marginLeft: "12%" }}>
					<h3>Modify Existing Products</h3>
					<p style={{ color: "grey" }}>
						Add/Remove products from existing product lines
					</p>
				</div>
				<Link
					to="/upload-library"
					onClick={() => {
						setassetNote("");
					}}
					style={{
						cursor: "pointer",
						borderRadius: "100px",
						border: "1px solid #84E8F4",
						fontSize: "2vh",
						backgroundColor: "rgba(132, 232, 244, 0.001)",
						color: "white",
						width: "21vh",
						height: "5.3vh",
						display: "flex",
						margin: "auto",
						marginTop: "5%",
						textDecoration: "none",
					}}
				>
					<CloudUploadIcon
						style={{ color: "#84E8F4", margin: "auto", fontSize: "3vh" }}
					/>
					<div
						style={{
							margin: "auto",
							marginLeft: "-10px",
							color: "#84E8F4",
						}}
					>
						My Uploads
					</div>
				</Link>
			</Modal>

			<Modal isOpen={cropperIsOpen} style={modalStyles} ariaHideApp={false}>
				<LinearProgress style={{ display: "none" }} />
				<div>
					<CloseIcon
						style={{
							position: "absolute",
							top: "25px",
							right: "25px",
							cursor: "pointer",
						}}
						onClick={() => {
							setassetNote("");
							setmodalIsOpen(false);
							setselectedImage(null);
							setImageURLs({});
							setcropperIsOpen(false);
						}}
					></CloseIcon>
				</div>

				{selectedImage ? (
					<div
						className="user_assets"
						style={{
							position: "absolute",
							cursor: "pointer",
							top: 130,
							left: 15,
							height: "45vh",
							width: "25%",
							overflow: "auto",
						}}
					>
						{Object.entries(ImageURLs).map(([image, file]) => {
							return (
								<div
									key={image}
									className="img-wrap2"
									onClick={() => {
										setselectedImage(image);
									}}
									style={{
										borderRadius: "10px",
										height: "75px",
										width: "50px",
										marginTop: "15px",
										marginBottom: "50px",
									}}
								>
									<img className="styleAssetImage1" src={image} />
								</div>
							);
						})}
					</div>
				) : (
					<></>
				)}

				<br></br>
				<br></br>
				<br></br>
				<h3>Upload Product Images</h3>

				{selectedImage ? (
					<div key={selectedImage} style={{ display: "flex" }}>
						<div style={{ marginLeft: "25%" }}>
							<ImageCropper
								src={selectedImage}
								position={cropperPosition[selectedImage]}
							></ImageCropper>
						</div>
						<Cancel
							onClick={() => {
								let updated = { ...ImageURLs };
								delete updated[selectedImage];
								if (ImageURLs.length === 1) setselectedImage(null);
								else if (Object.keys(ImageURLs)[0] === selectedImage)
									setselectedImage(Object.keys(ImageURLs)[1]);
								else setselectedImage(Object.keys(ImageURLs)[0]);
								setImageURLs(updated);
							}}
							style={{ marginLeft: "10%" }}
						></Cancel>
					</div>
				) : (
					<div
						style={{
							borderRadius: "5%",
							height: "250px",
							width: "300px",
							margin: "auto",
							marginTop: "20px",
							display: selectedImage ? "none" : "block",
						}}
					>
						<div>
							<CloudUploadIcon
								style={{
									color: "#84E8F4",
									fontSize: "30vh",
								}}
							></CloudUploadIcon>
						</div>
					</div>
				)}

				<div style={{ marginTop: "30px", color: "grey" }}>
					Upload more images to add to this product line
				</div>
				<br></br>
				<br></br>
				<br></br>
				{selectedImage ? (
					<LoadingButton
						variant="contained"
						component="span"
						loading={addingAssets}
						style={{
							cursor: "pointer",
							textTransform: "none",
							backgroundColor: "#84E8F4",
							width: "21%",
							borderRadius: "50px",
							height: "45px",
							color: "black",
							fontSize: "18px",
							fontWeight: "lighter",
						}}
						onClick={() => addAsset()}
					>
						Save
					</LoadingButton>
				) : (
					<div
						style={{ width: "100%", display: "flex", justifyContent: "center" }}
					>
						<label
							style={{
								display: "inline-block",
							}}
						>
							<input
								accept="image/*"
								id="contained-button-file"
								multiple
								type="file"
								onChange={(e) => onFileChange(e)}
								style={{ display: "none" }}
							/>
							<LoadingButton
								variant="contained"
								component="span"
								loading={addingAssets}
								style={{
									cursor: "pointer",
									textTransform: "none",
									backgroundColor: "#84E8F4",
									color: "black",
									fontWeight: "lighter",
									borderRadius: "100px",
									fontSize: "1.8vh",
									width: "21vh",
									height: "5vh",
								}}
							>
								<FileUploadOutlinedIcon
									style={{
										color: "black",
										marginRight: "5px",
										fontSize: "20px",
									}}
								/>
								Upload Images
							</LoadingButton>
						</label>
					</div>
				)}
			</Modal>
		</>
	);
}

export default CampaignJourney;
