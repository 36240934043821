import React from "react";
import { AiOutlineMenu } from "react-icons/ai";

import styles from "./Navbar.module.css";
import Logo from "../Images/myEra_NewLogo.png";

const Navbar = () => {
	return (
		<div className={styles.navbar}>
			<div>
				<AiOutlineMenu />
				<img src={Logo} className={styles.logo} alt="MyEra Logo" />
			</div>
			<span>Your Brand</span>
		</div>
	);
};

export default Navbar;
