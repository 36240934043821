import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Overview from "./Overview";

const Wrapper = styled.div`
  display: flex;
  background: rgb(13, 16, 22);
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

export default function OverviewMain() {
  return (
    <Wrapper id="outer-container-1">
      <Sidebar />
      <MainDashboard>
        <Topbar />
        <Overview></Overview>
      </MainDashboard>
    </Wrapper>
  );
}
