import React, { useEffect, useState, useRef } from "react";
import styles from "./ImageCropper.module.css";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { LoadingButton } from "@mui/lab";
import lodash from 'lodash';

const ImageCropper = ({ images, onSave }) => {
	const [ preview, setPreview ] = useState();
	const [ selectedImage, setSelectedImage ] = useState(
		images.length !== 0 ? images[ 0 ] : null
	);
	const [ imageObjects, setImageObjects ] = useState(
		images.map((image) => ({
			original_image: image,
			cropped_image: null,
			cropData: null,
		}))
	);
	const imageElement = useRef(null);
	const cropperRef = useRef(null);

	const base64ToBlobURL = (base64, mimeType) => {
		const byteString = atob(base64.split(",")[ 1 ]);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const intArray = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			intArray[ i ] = byteString.charCodeAt(i);
		}
		const blob = new Blob([ arrayBuffer ], { type: mimeType });
		return URL.createObjectURL(blob);
	};

	const debouncedCrop = useRef(
		lodash.debounce(() => {
			const cropper = cropperRef.current;
			if (cropper) {
				const canvas = cropper.getCroppedCanvas();
				let croppedImage = base64ToBlobURL(
					canvas.toDataURL("image/png"),
					"image/png"
				);
				setPreview(croppedImage);
				updateCroppedImage(croppedImage);
			}
		}, 200)
	).current;

	useEffect(() => {
		const cropper = new Cropper(imageElement.current, {
			zoomable: false,
			scalable: false,
			movable: false,
			aspectRatio: 1,
			autoCropArea: 1,
			crop: debouncedCrop,
		});

		cropperRef.current = cropper;

		const currentImageObject = imageObjects.find(
			(obj) => obj.original_image === selectedImage
		);
		if (currentImageObject && currentImageObject.cropData) {
			cropper.setData(currentImageObject.cropData);
		}

		return () => {
			if (cropper) {
				cropper.destroy();
			}
		};
	}, [ selectedImage ]);

	const updateCroppedImage = (cropped_image) => {
		setImageObjects((currentObjects) =>
			currentObjects.map((obj) =>
				obj.original_image === selectedImage
					? {
						...obj,
						cropped_image: cropped_image,
						cropData: cropperRef.current.getData(),
					}
					: obj
			)
		);
	};

	return (
		<div className={ styles.outer_wrapper }>
			<div className={ styles.wrapper }>
				{ images.length > 1 && (
					<div className={ styles.vertical_preview }>
						{ images.map((image, i) => (
							<div key={ i } onClick={ () => setSelectedImage(image) }>
								<img
									className={
										image === selectedImage
											? `${styles.image} ${styles.selected_image}`
											: styles.image
									}
									src={ image }
									alt={ `Preview ${i}` }
								/>
							</div>
						)) }
					</div>
				) }
				<div className={ styles.cropper }>
					<img
						ref={ imageElement }
						src={ selectedImage }
						style={ { borderRadius: "5%" } }
						className={ styles.cropper_image }
						alt="Source"
						crossorigin="anonymous"
						key={ selectedImage }
					/>
				</div>
			</div>
			<div className={ styles.submit_box }>
				<LoadingButton
					variant="contained"
					component="span"
					sx={ {
						color: "#fff",
						backgroundColor: "var(--myera-script-primary-color)",
						":hover": {
							color: "#fff",
							backgroundColor: "var(--myera-script-primary-color)",
						},
					} }
					onClick={ () => {
						onSave(
							imageObjects.map((obj) =>
								obj.cropped_image ? obj.cropped_image : obj.original_image
							)
						); // Send cropped images
					} }
				>
					Save & Continue
				</LoadingButton>
			</div>
		</div>
	);
};

export default ImageCropper;
