import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import styles from './product_create.module.css'
import Slider from "@mui/material/Slider";
import { updateImageSliderValue } from '../../Generic/messageService';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const ElementSlider = forwardRef((props) => {

    const [sliderValue, setSliderValue] = useState(150)

    const theme = createTheme({
		palette: {
			mode: "light",
			primary: {
				main: props.primaryColor,
			},
		},
	});

    updateImageSliderValue.getMessage().subscribe((message) => {
        if (message.value) {
            setSliderValue(message.value)
        }
    })
    
    console.log()
    return(
        <ThemeProvider theme={theme}>
            <Slider
                size="small"
                defaultValue={150}
                aria-label="Small"
                valueLabelDisplay="auto"
                min={150} 
                max={750}
                value={sliderValue ?? 150}
                className={styles.largeSlider}
                disabled={props.disabled}
                onChange={(e) => {
                    setSliderValue(e.target.value)
                    props.onChange(e)}
                }
                // color='primary'
            />
        </ThemeProvider>
    )
})

export default ElementSlider;