import React, { useState, useEffect } from "react";
import { isAuthenticated } from "../../../../Helper/Endpoints/Endpoints";
import Preview from "./Preview";
import ReviewInProgress from "./ReviewInProgress";

const Submit = (props) => {
	const [requestState, setRequestState] = useState();

	useEffect(() => {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/get_product_review_status/${props.product}/`,
			{ headers }
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				setRequestState(data.product_status);
			});
	}, []);

	function renderContent() {
		if (requestState === "Requested for review") {
			return <ReviewInProgress></ReviewInProgress>;
		} else {
			return <Preview product={props.product}></Preview>;
		}
	}

	return <>{renderContent()}</>;
};

export default Submit;
