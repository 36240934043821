import { Slider } from "@mui/material";
import React, { useState } from "react";
import styles from "./tabs.module.css";
import { SearchRounded } from "@material-ui/icons";
import ImageUploadModal from "../../Common/ImageUploadModal/ImageUploadModal";
import ImageCaptureModal from "../../Common/ImageCaptureModal/ImageCaptureModal";
import OptionDialog from "../../Common/OptionDialog/OptionDialog";

/*
{
	id: draft[ selectedStage ].length,
	name: "Single Image",
	type: TabType.SingleImage,
	priority: 0,
	tabPriority: 0,
	identifier: "Single Image",
	allowNull: false,
	sendAsLineItem: false,
	lineItemLabel: "Single Image",
	export: true,
	element: {
		name: "Image Element",
		x: 0,
		y: 0,
		width: 500,
		height: 500,
		rotation: 0,
		default_image: {
			asset: gen1,
			thumbnail: null,
		},
		is_movable: true,
		is_rotatable: true,
		is_scalable: true,
		priority: 1,
		export: true,
	},
	images: [],
	selectedImage: null,
	isUploadable: true,
	allowCameraUpload: true,
	scale: 1,
	uploadedImages: [],
	stage: selectedStage,
	guideline: "",
}
*/

const SingleImageTab = ({
	onSelectImage,
	selectedImage,
	images,
	isUploadable,
	setUploadedImages,
	uploadedImages,
	scale,
	onScaleChange,
	allowCameraUpload,
	guideline,
	tab,
	setTab,
	exportImage = true,
	showResultCount = true,
}) => {
	const [ isUploadModalOpen, setIsUploadModalOpen ] = useState(false);
	const [ isCaptureModalOpen, setIsCaptureModalOpen ] = useState(false);
	const [ showOptionDialog, setShowOptionDialog ] = useState(false);

	function onCropperSave(images) {
		setUploadedImages(images);
		setIsUploadModalOpen(false);
	}

	const handleUploadClick = () => {
		if (allowCameraUpload) {
			setShowOptionDialog(true);
		} else {
			document.querySelector("#myera__script__upload__button").click();
		}
	};

	const handleCaptureSubmit = (image) => {
		setUploadedImages([ image ]);
	};

	return (
		<div className={ styles.tab_container }>
			<div className={ styles.upload_row }>
				{ showResultCount && (
					<div>
						{ images.length + uploadedImages.length } result{ "" }
						{ images.length + uploadedImages.length === 1 ? "" : "s" }
					</div>
				) }
				{ isUploadable && (
					<input
						type="file"
						id="myera__script__upload__button"
						hidden
						multiple
						accept="image/*"
						onChange={ (e) => {
							const files = e.target.files;
							const newFiles = [];
							for (let file of files) {
								newFiles.push(URL.createObjectURL(file));
							}
							setUploadedImages([ ...uploadedImages, ...newFiles ]);
							setIsUploadModalOpen(true);
						} }
					/>
				) }
				{ isUploadable && (
					<button
						className={ [ styles.button, styles.secondary ].join(" ") }
						onClick={ handleUploadClick }
					>
						Upload Image
					</button>
				) }
			</div>

			<div className={ styles.image_list }>
				{ uploadedImages.map((image) => (
					<div
						style={ {
							position: "relative",
						} }
						className={ [
							styles.image_container,
							image === selectedImage?.image && styles.selected_image,
						].join(" ") }
					>
						<img
							src={ image }
							alt="uploaded"
							onClick={ () => {
								onSelectImage({
									image,
									thumbnail: image,
								});
							} }
							className={ [ styles.image ].join(" ") }
						/>
					</div>
				)) }
			</div>
			{ uploadedImages.length > 0 && (
				<div className={ styles.slider_container }>
					<SearchRounded />
					<Slider
						disabled={
							uploadedImages.includes(selectedImage.image) ? false : true
						}
						sx={ {
							marginLeft: "8px",
							root: {
								color: "var(--myera-script-primary-color)",
							},
							"& .MuiSlider-thumb": {
								backgroundColor: "var(--myera-script-primary-color)",
								border: "2px solid var(--myera-script-primary-color)",
							},
							"& .MuiSlider-track": {
								backgroundColor: "var(--myera-script-primary-color)",
							},
							"& .MuiSlider-rail": {
								backgroundColor: "var(--myera-script-primary-color)",
							},
							"&.Mui-disabled": {
								color: "#bdbdbd",
								"& .MuiSlider-thumb": {
									backgroundColor: "#bdbdbd",
									border: "2px solid #bdbdbd",
								},
								"& .MuiSlider-track": {
									backgroundColor: "#bdbdbd",
								},
								"& .MuiSlider-rail": {
									backgroundColor: "#bdbdbd",
								},
							},
						} }
						value={ scale }
						onChange={ (e, val) => {
							onScaleChange(val);
						} }
						min={ 0 }
						max={ 2 }
						step={ 0.05 }
					/>
				</div>
			) }
			{ uploadedImages.length > 0 && images.length > 0 && <hr /> }
			<div className={ styles.image_list }>
				{ images.map((image) => (
					<div
						style={ {
							position: "relative",
						} }
						className={ [
							styles.image_container,
							(image.image?.asset || image.asset) === selectedImage?.image &&
							styles.selected_image,
						].join(" ") }
					>
						<img
							src={
								image.thumbnail?.asset ||
								image.image?.asset ||
								image.thumbnail ||
								image.asset
							}
							alt="uploaded"
							onClick={ () => {
								onSelectImage({
									image: image.image?.asset || image.asset,
									thumbnail:
										image.thumbnail?.asset ||
										image.image?.asset ||
										image.thumbnail ||
										image.asset,
									label: image.label,
								});
							} }
							className={ [ styles.image ].join(" ") }
						/>
						{ image.label && (
							<div className={ styles.image_label }>{ image.label }</div>
						) }
					</div>
				)) }
			</div>
			<div className={ styles.tab_details }>
				<div className={ styles.detail }>
					<label>Name:</label>
					<input type="text"
						value={ tab.name }
						onChange={ (e) => setTab({ ...tab, name: e.target.value }) }
					/>
				</div>
				<div className={ styles.detail }>
					<label>Priority:</label>
					<input type="number"
						value={ tab.priority }
						onChange={ (e) => setTab({ ...tab, priority: e.target.value }) }
					/>
				</div>
				<div className={ styles.detail }>
					<label>Tab Priority:</label>
					<input type="number"
						value={ tab.tabPriority }
						onChange={ (e) => setTab({ ...tab, tabPriority: e.target.value }) }
					/>
				</div>
				<div className={ styles.detail }>
					<label>Export:</label>
					<input type="checkbox"
						checked={ tab.export }
						onChange={ (e) => setTab({ ...tab, export: e.target.checked }) }
					/>
				</div>

				<div className={ styles.detail }>
					<label>Allow Null:</label>
					<input type="checkbox"
						checked={ tab.allowNull }
						onChange={ (e) => setTab({ ...tab, allowNull: e.target.checked }) }
					/>

				</div>

				<div className={ styles.detail }>
					<label>Uploadable:</label>
					<input type="checkbox"
						checked={ tab.isUploadable }
						onChange={ (e) => setTab({ ...tab, isUploadable: e.target.checked }) }
					/>

				</div>

				<div className={ styles.detail }>
					<div>Element:</div>
					<div className={ styles.element_detail }>
						<div>
							<label>Name:</label>
							<input type="text"
								value={ tab.element.name }
								onChange={ (e) =>
									setTab({
										...tab,
										element: { ...tab.element, name: e.target.value },
									})
								}
							/>
						</div>
						<div>
							<label>X:</label>
							<input type="number"
								value={ tab.element.x }
								onChange={ (e) =>
									setTab({
										...tab,
										element: { ...tab.element, x: e.target.value },
									})
								}
							/>
						</div>
						<div>
							<label>Y:</label>
							<input type="number"
								value={ tab.element.y }
								onChange={ (e) =>
									setTab({
										...tab,
										element: { ...tab.element, y: e.target.value },
									})
								}
							/>
						</div>
						<div>
							<label>Width:</label>
							<input type="number"
								value={ tab.element.width }
								onChange={ (e) =>
									setTab({
										...tab,
										element: { ...tab.element, width: e.target.value },
									})
								}
							/>
						</div>
						<div>
							<label>Height:</label>
							<input type="number"
								value={ tab.element.height }
								onChange={ (e) =>
									setTab({
										...tab,
										element: { ...tab.element, height: e.target.value },
									})
								}
							/>
						</div>
						<div>
							<label>Rotation:</label>
							<input type="number"
								value={ tab.element.rotation }
								onChange={ (e) =>
									setTab({
										...tab,
										element: { ...tab.element, rotation: e.target.value },
									})
								}
							/>
						</div>
						<div>
							<label>Is Movable:</label>
							<input type="checkbox"
								checked={ tab.element.is_movable }
								onChange={ (e) =>
									setTab({
										...tab,
										element: { ...tab.element, is_movable: e.target.checked },
									})
								}
							/>
						</div>
					</div>
				</div>

			</div>
			<ImageUploadModal
				isOpen={ isUploadModalOpen }
				setIsOpen={ setIsUploadModalOpen }
				images={ uploadedImages }
				state={ "cropper" }
				onCropperSave={ onCropperSave }
			></ImageUploadModal>
			<ImageCaptureModal
				isOpen={ isCaptureModalOpen }
				setIsOpen={ setIsCaptureModalOpen }
				guideline={ guideline }
				onSubmit={ handleCaptureSubmit }
			/>
			<OptionDialog
				open={ showOptionDialog }
				onClose={ () => {
					setShowOptionDialog(false);
				} }
				onCaptureFromCamera={ () => {
					setShowOptionDialog(false);
					setIsCaptureModalOpen(true);
				} }
				onUploadFromGallery={ () => {
					setShowOptionDialog(false);
					document.querySelector("#myera__script__upload__button").click();
				} }
			/>
		</div>
	);
};

export default SingleImageTab;
