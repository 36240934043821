import React, { Component} from "react";
import styled from "styled-components";
import Select from 'react-select';
// import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { isAuthenticated } from '../../Helper/Endpoints/Endpoints';
import {FaCopy } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress, Divider } from "@mui/material";
import { LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";

import './trendingtags.css'

const Card = styled.div`
  border-radius: 14px;
  background-color: #2C303A;
  width: 40%;
  height: 450px;
`;

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    display: "flex",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "100px",
    backgroundColor: "#343943",
    height: '36px',
    fontSize: "14px",
    color: 'white'
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
      color: "white",
      fontFamily: 'Montserrat',
    };
  },
};

class TrendingTags extends Component{

    constructor(props) {
      super(props);
      this.queryRef = React.createRef();
    }

    state = {
      
      industries: [],
      places: require('./places_data_modified.json'),
      // places : [
      //   {'label' : 'India', 'value': 'India'},
      //   {'label' : 'Global', 'value': 'Global'},
      //   {'label' : 'Mumbai', 'value': 'Mumbai'},
      //   {'label' : 'Hyderabad', 'value': 'Hyderabad'},
      //   {'label' : 'Delhi', 'value': 'NewDelhi'},
      //   {'label' : 'Jaipur', 'value': 'Jaipur'},
      //   {'label': 'Chennai', 'value': 'Chennai'},
      //   {'label': 'Kolkata', 'value': 'Kolkata'},
      //   {'label': 'Pune', 'value': 'Pune'},
      //   {'label': 'Ahmedabad', 'value': 'Ahmedabad'},
      //   {'label': 'Surat', 'value': 'Surat'},
      //   {'label': 'Lucknow', 'value': 'Lucknow'},
        
      // ],

      tags : [],

      selectedPlace: {
          "placeType": {
              "code": 12,
              "name": "Country"
          },
          "url": "http://where.yahooapis.com/v1/place/23424848",
          "parentid": 1,
          "country": "India",
          "woeid": 23424848,
          "countryCode": "IN",
          "label": "India",
          "value": "India"
      },
      fetchingTags: true
    }

    handleDropdownChange = (selectedPlace) =>{

      this.setState({fetchingTags: true})
      var place = selectedPlace.woeid
      const {accessToken} = isAuthenticated();
    
      var requestOptions = {
          method: 'GET',
          headers: {
              Authorization: `Bearer ${accessToken}`,
          }   
      }

      fetch(`${process.env.REACT_APP_BACKEND}/get_trending_tags/` + place + '/', requestOptions)
        .then(response => response.json())
          .then(data => {
            console.log(data)
            this.setState({tags: data.trends, fetchingTags: false})
          })
    }

    onCopy(e, tag){
      navigator.clipboard.writeText(tag.substring(1))
      toast.success('Tag Copied', {
        position: "top-center",
        theme: 'dark',
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }

    render = () => {

        return(
            <Card className = 'cardStyle'>
              <div style={{color: "white", fontSize: "26px", marginLeft: "4%", paddingTop: "3%"}}>
                Trending Hashtags
              </div>

              <div style={{display: "flex",  marginTop: "4%" , paddingLeft: '3%'}}>
                <div style={{ width: '30%'}}>
                <Select 
                    styles={customStyles}
                    // defaultValue = {this.state.selectedPlace}
                    onChange={this.handleDropdownChange} 
                    options={this.state.places} 
                    placeholder={'All Location'} 
                    theme={theme => ({
                      ...theme,
                      colors: {
                          ...theme.colors,
                          neutral50: '#FFFFFF',  // Placeholder color
                      },
                    })}
                  />
                </div>
                {/* <div style={{ width: '30%'}}>
                <Select 
                    styles={customStyles}
                    // defaultValue = {this.state.selectedPlace}
                    onChange={this.handleDropdownChange} 
                    options={this.state.places} 
                    placeholder={'All Location'} 
                    theme={theme => ({
                      ...theme,
                      colors: {
                          ...theme.colors,
                          neutral50: '#FFFFFF',  // Placeholder color
                      },
                    })}
                  />
                </div> */}
                {/* <div style={{ width: '30%'}}>
                <Select 
                    styles={customStyles}
                    // defaultValue = {this.state.selectedPlace}
                    onChange={this.handleDropdownChange} 
                    options={this.state.places} 
                    placeholder={'All Platform'} 
                    theme={theme => ({
                      ...theme,
                      colors: {
                          ...theme.colors,
                          neutral50: '#FFFFFF',  // Placeholder color
                      },
                    })}
                  />
                </div> */}
              </div>

              <LinearProgress style={{display: (this.state.fetchingTags ? 'block' : 'none')}}/>
              {/* <CircularProgress style={{marginLeft: '50%', marginTop: '2%', marginBottom: '2%', display: this.state.fetchingTags ? 'block' : 'none'}}></CircularProgress> */}
              <div style={{display: this.state.fetchingTags ? 'block' : 'none', height: '100px'}}></div>
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: "column", padding: '1.5%', display: this.state.fetchingTags ? 'none' : 'flex'}}>
                  {
                    this.state.tags.slice(0, 5).map((tag, index) => {
                      return (
                        <div style={{margin: '0.75%', borderRadius: '5px'}}>
                          <div  class="copyText" style={{fontSize: '20px', fontWeight: '100', padding: '5px', color: "white"}} onClick={ (e) => this.onCopy(e, tag.name)}>
                            {index + 1}. {tag.name}
                          </div>
                        </div>
                      )
                    })
                  }
                </div>

                <div style={{textAlign: "center", color: "#007AFF", marginTop: "2%"}}>
                  <Link to="/trendwatch/trending-hashtag" style={{textDecoration: "none" ,  fontSize:'15px'}}>View All</Link>
                </div>

            </Card>
        )
    }

    componentDidMount(){
      const {accessToken} = isAuthenticated();
    
      var requestOptions = {
          method: 'GET',
          headers: {
              Authorization: `Bearer ${accessToken}`,
          }   
      }

      fetch(`${process.env.REACT_APP_BACKEND}/get_trending_tags/` + this.state.selectedPlace.woeid + '/', requestOptions)
        .then(response => response.json())
          .then(data => {
            console.log(data.trends)
            this.setState({tags: data.trends, fetchingTags: false})
            
          })        
    }
}

export default TrendingTags;
