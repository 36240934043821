import React, { Component, useEffect, useState } from "react";
import "../Campaign/templateScroll.css";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import "react-toastify/dist/ReactToastify.css";
import "./SocialAccounts.css";
import { ToastContainer, toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
// import { useLinkedIn } from "react-linkedin-login-oauth2";
// import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import linkedinIcon from "./icons8-linkedin.png";
// import TwitterLogin from "react-twitter-login";
import axios from "axios";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import Modal from "react-modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import swal from "sweetalert";
import { Cancel } from "@material-ui/icons";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getCroppedImageService } from "../Generic/messageService";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "rgb(132, 232, 244)",
		},
	},
});

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: "67.5%",
		width: "40%",
		color: "#FFFFFF",
		padding: "0%",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

const modalStylesLinkedIn = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		// textAlign: "center",
		height: "auto",
		width: "35%",
		color: "#FFFFFF",
		padding: "0%",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

export default function SocialAccounts(props) {
	// destructuring the props
	const {
		showLinkedinLogin,
		setShowLinkedinLogin,
		showTwitterLogin,
		setShowTwitterLogin,
	} = props;
	const [fetchingUserDetails, setFetchingUserDetails] = useState(true);
	const [showLogin, setShowLogin] = useState(false);
	const [selectedOrg, setSelectedOrg] = useState();
	// Lifting up the state
	// const [showLinkedinLogin, setShowLinkedinLogin] = useState(false);
	// const [showTwitterLogin, setShowTwitterLogin] = useState(false);
	// Lifting up the state

	const [code, setCode] = React.useState("");
	const [errorMessage, setErrorMessage] = React.useState("");
	const [linkedinOrganizations, setLinkedinOrganizations] = useState([]);
	const [showLinkedInOrgModal, setShowLinkedinOrgModal] = useState(false);
	const [fbPageName, setFbPageName] = useState();
	const [instaUsername, setInstaUsername] = useState();
	const [linkedinOrgName, setLinkedinOrgName] = useState();
	const [linkedinProfileName, setLinkedinProfileName] = useState();
	const [twitterScreenName, setTwitterScreenName] = useState();
	const [isFacebookLinked, setIsFacebookLinked] = useState(false);
	const [isTwitterLinked, setIsTwitterLinked] = useState(false);
	const [isLinkedinProfileLinked, setIsLinkedinProfileLinked] = useState(false);
	const [isLinkedinOrgLinked, setIsLinkedinOrgLinekd] = useState(false);

	// const handleSelectedTags =(tags)=>{
	//   setobjectives(tags);
	// }

	function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	useEffect(() => {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		if (window.location.href.includes("twitter/callback/")) {
			let callback_url = window.location.href;
			var url = new URL(callback_url);
			let oauth_token = url.searchParams.get("oauth_token");
			let oauth_verifier = url.searchParams.get("oauth_verifier");

			fetch(
				`${process.env.REACT_APP_BACKEND}/get_twitter_access_token?` +
					new URLSearchParams({
						oauth_token: oauth_token,
						oauth_verifier: oauth_verifier,
					}),
				{ headers }
			)
				.then((request) => request.json())
				.then((data) => {
					console.log(data);
					// setRedirect(true);

					var userData = JSON.parse(localStorage.getItem("Erajwt"));
					userData.is_twitter_linked = true;
					userData.twitter_name = `twitter:${data.screen_name}`;
					localStorage.setItem("Erajwt", JSON.stringify(userData));

					window.location.reload();
				});
		}

		const userData = JSON.parse(localStorage.getItem("Erajwt"));
		setIsFacebookLinked(userData.is_facebook_linked);
		setIsLinkedinProfileLinked(userData.is_linkedin_linked);
		setIsLinkedinOrgLinekd(userData.is_linkedin_org_linked);
		setIsTwitterLinked(userData.is_twitter_linked);

		setFbPageName(userData.fb_page_name);
		setInstaUsername(userData.insta_username);
		setTwitterScreenName(userData.twitter_name);
		setLinkedinOrgName(userData.linkedin_org_name);
		setLinkedinProfileName(userData.linkedin_profile_name);

		if (!userData.is_facebook_linked) {
			console.log("!facebookLinked ran!");
			setShowLogin(false);
		} else setShowLogin(true);

		if (userData.is_linkedin_linked) {
			setShowLinkedinLogin(true);
		} else setShowLinkedinLogin(false);

		if (userData.is_twitter_linked) setShowTwitterLogin(true);
		else setShowTwitterLogin(false);

		async function getUserData() {
			fetch(`${process.env.REACT_APP_BACKEND}/get_user/`, { headers })
				.then((response) => response.json())
				.then((data) => {
					setFetchingUserDetails(false);
				});
		}
		getUserData();
	}, []);

	const responseFacebook = async (response) => {
		console.log("Linking...");
		console.log(response.accessToken);
		const { accessToken } = isAuthenticated();
		var requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				access_token: response.accessToken,
			}),
		};

		fetch(`${process.env.REACT_APP_BACKEND}/link_facebook/`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				var userData = JSON.parse(localStorage.getItem("Erajwt"));
				userData.is_facebook_linked = true;
				localStorage.setItem("Erajwt", JSON.stringify(userData));
				console.log(JSON.parse(localStorage.getItem("Erajwt")));
				window.location.reload();
			});
		console.log("linked");

		// var userData = JSON.parse(localStorage.getItem("Erajwt"))
		// userData.is_facebook_linked = true
		// localStorage.setItem('Erajwt', JSON.stringify(userData))

		// localStorage.setItem(
		//   "facebook_creds",
		//   JSON.stringify({
		//     accessToken: response.accessToken,
		//     userID: response.userID,
		//     name: response.name,
		//   })
		// );
		// const accounts_response = await Axios.get(
		//   `https://graph.facebook.com/v11.0/me?fields=id,name,accounts&access_token=${response.accessToken}`
		// );

		// localStorage.setItem(
		//   "facebook_pages",
		//   JSON.stringify({ pages: accounts_response.data.accounts.data })
		// );
		// const pagesArray = accounts_response.data.accounts.data;
		// const instagram_business_accounts = [];
		// for (let i = 0; i < pagesArray.length; i++) {
		//   const instagram_business_account = await Axios.get(
		//     `https://graph.facebook.com/v11.0/${pagesArray[i].id}?fields=instagram_business_account&access_token=${pagesArray[i].access_token}`
		//   );

		//   if (instagram_business_account.data.instagram_business_account) {
		//     instagram_business_accounts.push({
		//       id: instagram_business_account.data.instagram_business_account.id,
		//       access_token: pagesArray[i].access_token,
		//       facebook_page_id: pagesArray[i].id,
		//       facebook_page: pagesArray[i].name,
		//     });
		//   }
		// }
		// localStorage.setItem(
		//   "insta_accounts",
		//   JSON.stringify({ accounts: instagram_business_accounts })
		// );
		// localStorage.setItem(
		//   "ig-user-id",
		//   JSON.stringify({ id: instagram_business_accounts[0].id })
		// );
		// window.location.reload();
	};

	async function twitterLogin() {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/request_twitter_token`, { headers })
			.then((request) => request.json())
			.then((data) => {
				console.log(data);
				window.location.href = data.authorization_url;
				var userData = JSON.parse(localStorage.getItem("Erajwt"));
				userData.is_twitter_linked = true;
				localStorage.setItem("Erajwt", JSON.stringify(userData));
				// window.location.reload();
			});

		console.log(window.location.href);
	}

	const authHandler = (err, data) => {
		console.log(err, data);
	};

	const { linkedInLogin } = useLinkedIn({
		clientId: "77l5e7nu3i10q5",
		redirectUri: `${window.location.origin}/linkedin/callback/`,
		onSuccess: (code) => {
			console.log(code);
			setCode(code);
			// alert(`Hurray!! LinkedIn is connected. \nCode: ${code}`)

			const { accessToken } = isAuthenticated();
			const requestOptions = {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			};

			fetch(
				`${process.env.REACT_APP_BACKEND}/link_linkedin/${code}/`,
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					var userData = JSON.parse(localStorage.getItem("Erajwt"));
					userData.is_linkedin_linked = true;
					userData.linkedin_profile_name = `linkedin_profile_name:${data["profile_name"]}`;
					localStorage.setItem("Erajwt", JSON.stringify(userData));
					// window.location.reload();
					get_linkedin_organizations();
				});

			// fetch(`${process.env.REACT_APP_BACKEND}/get_linkedin_organizations/`, requestOptions)
			// .then(response => response.json())
			// .then(data => {
			//   console.log(data)
			//   var organizations = []
			//   for(var org of data.organizations)
			//   {
			//     organizations.push({'organization_name' : org.localizedName, 'organization_urn': org.$URN})
			//   }
			//   localStorage.setItem('linkedinOrganizations', JSON.stringify(organizations))
			// })
		},
		scope:
			"r_organization_social rw_organization_admin w_organization_social r_emailaddress r_liteprofile w_member_social",
		onError: (error) => {
			console.log(error);
			setCode("");
			setErrorMessage(error.errorMessage);
		},
	});

	async function get_linkedin_organizations() {
		// alert("IN FUNCTION")
		const { accessToken } = isAuthenticated();
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/get_linkedin_organizations/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				var organizations = [];
				for (var org of data.organizations) {
					organizations.push({
						organization_name: org.localizedName,
						organization_urn: org.$URN,
					});
				}
				setLinkedinOrganizations(organizations);
				setShowLinkedinOrgModal(true);
			});
	}

	function setLinkedinOrganization() {
		const { accessToken } = isAuthenticated();
		var selected_org = {};
		for (var org of linkedinOrganizations)
			if (org.organization_urn === selectedOrg) {
				selected_org.organization_name = org.organization_name;
				selected_org.organization_urn = org.organization_urn;
			}

		console.log(selected_org);

		if (selected_org === {}) {
			toast.error(`Error linking organization`, {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			setShowLinkedinOrgModal(false);
			return;
		}

		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(selected_org),
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/set_linkedin_organization/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				toast.success(
					`Organization ${selected_org.organization_name} linked successfully`,
					{
						position: "top-center",
						theme: "dark",
						autoClose: 2500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					}
				);
				setShowLinkedinOrgModal(false);
				var userData = JSON.parse(localStorage.getItem("Erajwt"));
				userData.is_linkedin_org_linked = true;
				userData.linkedin_org_name = `linkedin_org_name:${selected_org.organization_name}`;
				localStorage.setItem("Erajwt", JSON.stringify(userData));
				window.location.reload();
			})
			.catch((error) => {
				console.error(error);
				toast.error(`Error linking organization`, {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				setShowLinkedinOrgModal(false);
			});
	}

	function delinkFacebook() {
		const { accessToken } = isAuthenticated();
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};

		fetch(`${process.env.REACT_APP_BACKEND}/delink_facebook/`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				toast.success("Removed Facebook Account Successfully", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				var userData = JSON.parse(localStorage.getItem("Erajwt"));
				delete userData["insta_username"];
				delete userData["fb_page_name"];
				userData.is_facebook_linked = false;
				localStorage.setItem("Erajwt", JSON.stringify(userData));
				window.location.reload();
			});

		console.log(JSON.parse(localStorage.getItem("Erajwt")));
	}

	function linkOrganization() {
		get_linkedin_organizations();
		setShowLinkedinOrgModal(true);
	}

	function delinkLinkedin() {
		const { accessToken } = isAuthenticated();
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};

		fetch(`${process.env.REACT_APP_BACKEND}/delink_linkedin/`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				var userData = JSON.parse(localStorage.getItem("Erajwt"));
				// delete userData[''];
				userData.is_linkedin_linked = false;
				userData.is_linkedin_org_linked = false;
				localStorage.setItem("Erajwt", JSON.stringify(userData));
				window.location.reload();

				toast.success("Removed LinkedIn Account Successfully", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	}

	function delinkTwitter() {
		const { accessToken } = isAuthenticated();
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};

		fetch(`${process.env.REACT_APP_BACKEND}/delink_twitter/`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				toast.success("Removed Twitter Account Successfully", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				var userData = JSON.parse(localStorage.getItem("Erajwt"));
				delete userData["twitter_name"];
				userData.is_twitter_linked = false;
				localStorage.setItem("Erajwt", JSON.stringify(userData));
				window.location.reload();
			});
	}

	function confirmDelinkTwitter() {
		swal(
			"Are you sure you want to delink your account from MyEra? You won't be able to schedule or analyze posts using MyEra if you proceed.",
			{
				icon: "warning",
				buttons: {
					cancel_bt: {
						text: "Cancel",
						className: "cancel",
					},
					confirm: {
						text: "Yes, proceed",
						className: "delete-confirmation",
						value: "delete",
					},
				},
			}
		).then((value) => {
			if (value === "delete") delinkTwitter();
		});

		// if(window.confirm("Are you sure whether you want to remove your account or not from MyEra. You won't be able to schedule posts from MyEra if you unlink.") === true)
		// {
		//   delinkTwitter();
		// }
	}

	function confirmDelinkFacebook() {
		swal(
			"Are you sure you want to delink your account from MyEra? You won't be able to schedule or analyze posts using MyEra if you proceed.",
			{
				icon: "warning",
				buttons: {
					cancel_bt: {
						text: "Cancel",
						className: "cancel",
					},
					confirm: {
						text: "Yes, proceed",
						className: "delete-confirmation",
						value: "delete",
					},
				},
			}
		).then((value) => {
			if (value === "delete") delinkFacebook();
		});
		// if(window.confirm("Are you sure whether you want to remove your account or not from MyEra. You won't be able to schedule posts from MyEra if you unlink.") === true)
		// {
		//   delinkFacebook();
		// }
	}

	function confirmDelinkLinkedin() {
		swal(
			"Are you sure you want to delink your account from MyEra? You won't be able to schedule or analyze posts using MyEra if you proceed.",
			{
				icon: "warning",
				buttons: {
					cancel_bt: {
						text: "Cancel",
						className: "cancel",
					},
					confirm: {
						text: "Yes, proceed",
						className: "delete-confirmation",
						value: "delete",
					},
				},
			}
		).then((value) => {
			if (value === "delete") delinkLinkedin();
		});
		// if(window.confirm("Are you sure whether you want to remove your account or not from MyEra. You won't be able to schedule posts from MyEra if you unlink.") === true)
		// {
		//   delinkLinkedin();
		// }
	}

	function get_org_label(org_name) {
		return (
			<div style={{ textAlign: "left", display: "flex", width: "150%" }}>
				<img
					src="https://img.icons8.com/color/96/000000/company.png"
					height="20px"
				/>
				<div style={{ marginLeft: "4%" }}>{org_name}</div>
			</div>
		);
	}

	function linkOrgLater() {
		setShowLinkedinOrgModal(false);
		window.location.reload();
	}

	return (
		<div
			style={{
				marginTop: "0%",
				backgroundColor: "rgb(13,16, 22)",
				height: "92vh",
			}}
		>
			<CircularProgress
				style={{
					marginLeft: "50%",
					marginBottom: "25%",
					marginTop: "25%",
					display: fetchingUserDetails ? "block" : "none",
					color: "rgb(132, 232, 244)",
				}}
			></CircularProgress>
			<div
				style={{
					width: "100%",
					display: "flex",
					marginBottom: "4vh",
					display: fetchingUserDetails ? "none" : "flex",
				}}
			>
				<div style={{ textAlign: "left", width: "100%" }}>
					<label
						style={{
							marginRight: "5%",
							fontSize: "26px",
							color: "white",
							marginLeft: "4%",
							paddingTop: "3%",
							marginBottom: "0.%",
						}}
					>
						Social Accounts
					</label>
					<hr style={{ color: "white" }} />

					<div
						style={{
							background: "#2C303A",
							width: "80%",
							marginLeft: "1%",
							borderRadius: "10px",
							marginTop: "20px",
						}}
					>
						{/* <div style={{ marginLeft: "1%", borderRadius: '10px', marginTop: '5px' }}> */}
						<div style={{ width: "100%", padding: "2%" }}>
							<div
								style={{
									fontSize: "20px",
									fontWeight: "100",
									padding: "5px",
									color: "white",
									display: "flex",
								}}
							>
								<img src={linkedinIcon} height="50px"></img>
								{showLinkedinLogin ? (
									<div
										style={{
											marginLeft: "1%",
											marginTop: "1%",
											display: "flex",
										}}
									>
										Linked
										{/* <img onClick={confirmDelinkLinkedin} src="https://img.icons8.com/ios-glyphs/60/000000/filled-trash.png" height='18px' style={{marginLeft: '7%', cursor: 'pointer'}}></img> */}
										<div className="profile-name-style-1">
											<nobr>{linkedinProfileName.split(":")[1]}</nobr>
											<PersonIcon
												color="primary"
												sx={{ ml: "5%", mb: "1.5%" }}
											></PersonIcon>
										</div>
										{isLinkedinOrgLinked ? (
											<div className="profile-name-style-1">
												<nobr>{linkedinOrgName.split(":")[1]}</nobr>
												<img
													src="https://img.icons8.com/color/96/000000/company.png"
													height="25px"
													style={{ marginLeft: "5%" }}
												/>
											</div>
										) : (
											<Button
												variant="contained"
												style={{
													textTransform: "none",
													backgroundColor: "rgb(132, 232, 244)",
													borderRadius: "10px",
													color: "black",
													maxWidth: "130px",
													minWidth: "130px",
													maxHeight: "40px",
													minHeight: "40px",
													marginLeft: "10%",
													marginTop: "-5px",
												}}
												onClick={linkOrganization}
											>
												<nobr>Link Organization</nobr>
											</Button>
										)}
										<DeleteIcon
											fontSize="large"
											onClick={confirmDelinkLinkedin}
											style={{
												marginLeft: "3%",
												cursor: "pointer",
											}}
										></DeleteIcon>
									</div>
								) : (
									<div
										style={{
											marginLeft: "1.5%",
											marginTop: "1%",
											display: "flex",
											cursor: "pointer",
										}}
									>
										<p onClick={linkedInLogin}>Link Now</p>
									</div>
								)}
								{/* <div style={{marginLeft: '7%', marginTop: '10%', display: 'flex'}}>Linked <img src="https://img.icons8.com/color/48/undefined/ok--v1.png" height='18px'/></div> */}
							</div>
						</div>

						<div style={{ width: "100%", padding: "2%" }}>
							<div
								style={{
									fontSize: "20px",
									fontWeight: "100",
									padding: "5px",
									color: "white",
									display: "flex",
								}}
							>
								<img
									src="https://img.icons8.com/color/48/000000/facebook-new.png"
									height="50px"
								></img>
								{showLogin ? (
									<div
										style={{
											marginLeft: "1%",
											marginTop: "1%",
											display: "flex",
										}}
									>
										Linked
										{/* <img onClick={confirmDelinkFacebook} src="https://img.icons8.com/ios-glyphs/60/000000/filled-trash.png" height='25x' style={{marginLeft: '7%', cursor: 'pointer'}}></img> */}
										<div className="profile-name-style">
											<nobr>{fbPageName.split(":")[1]}</nobr>
										</div>
										<DeleteIcon
											fontSize="large"
											onClick={confirmDelinkFacebook}
											style={{
												marginTop: "-2%",
												marginLeft: "7%",
												cursor: "pointer",
											}}
										></DeleteIcon>
									</div>
								) : (
									<FacebookLogin
										// textButton="  Link Facebook  "
										// cssClass="btnFacebook"
										// icon="fa-facebook"
										appId="4216286471795118"
										autoLoad={false}
										fields="name,email,picture"
										scope="instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement,instagram_content_publish, pages_manage_posts"
										callback={responseFacebook}
										render={(renderProps) => (
											<div
												style={{
													marginLeft: "1.5%",
													marginTop: "1%",
													display: "flex",
													cursor: "pointer",
												}}
											>
												<p onClick={renderProps.onClick}>Link Now</p>
											</div>
										)}
									/>
								)}
								{/* <div style={{marginLeft: '7%', marginTop: '10%', display: 'flex'}}>Linked <img src="https://img.icons8.com/color/48/undefined/ok--v1.png" height='18px'/></div> */}
							</div>
						</div>

						<div style={{ width: "100%", padding: "2%" }}>
							<div
								style={{
									fontSize: "20px",
									fontWeight: "100",
									padding: "5px",
									color: "white",
									display: "flex",
								}}
							>
								<img
									src="https://img.icons8.com/color/48/undefined/twitter--v1.png"
									height="50px"
								></img>
								{/* <div style={{marginLeft: '7%', marginTop: '10%', display: 'flex'}}> */}
								{showTwitterLogin ? (
									<div
										style={{
											marginLeft: "1%",
											marginTop: "1%",
											display: "flex",
										}}
									>
										Linked
										{/* <img onClick={confirmDelinkTwitter} src="https://img.icons8.com/ios-glyphs/60/000000/trash--v1.png" height='30px' style={{ marginLeft: '7%', cursor: 'pointer'}}></img> */}
										<div className="profile-name-style">
											<nobr>{twitterScreenName.split(":")[1]}</nobr>
										</div>
										<DeleteIcon
											fontSize="large"
											onClick={confirmDelinkTwitter}
											style={{
												marginTop: "-2%",
												marginLeft: "7%",
												cursor: "pointer",
											}}
										></DeleteIcon>
									</div>
								) : (
									<div
										style={{
											marginLeft: "1.5%",
											marginTop: "1%",
											display: "flex",
											cursor: "pointer",
										}}
									>
										<p onClick={twitterLogin}>Link Now</p>
									</div>
								)}
								{/* </div> */}
							</div>
						</div>

						<div style={{ width: "100%", padding: "2%" }}>
							<div
								style={{
									fontSize: "20px",
									fontWeight: "100",
									padding: "5px",
									color: "white",
									display: "flex",
								}}
							>
								<img
									src="https://img.icons8.com/fluency/48/000000/instagram-new.png"
									height="50px"
								></img>
								{showLogin ? (
									<div
										style={{
											marginLeft: "1%",
											marginTop: "1%",
											display: "flex",
										}}
									>
										Linked
										{/* <img onClick={confirmDelinkFacebook} src="https://img.icons8.com/ios-glyphs/60/000000/filled-trash.png" height='18px' style={{marginLeft: '7%', cursor: 'pointer'}}></img> */}
										<div className="profile-name-style">
											<nobr>{instaUsername.split(":")[1]}</nobr>
										</div>
										<DeleteIcon
											fontSize="large"
											onClick={confirmDelinkFacebook}
											style={{
												marginTop: "-2%",
												marginLeft: "7%",
												cursor: "pointer",
											}}
										></DeleteIcon>
									</div>
								) : (
									<FacebookLogin
										// textButton="  Link Facebook  "
										// cssClass="btnFacebook"
										// icon="fa-facebook"
										appId="4216286471795118"
										autoLoad={false}
										fields="name,email,picture"
										scope="instagram_basic,pages_show_list,instagram_manage_insights,pages_read_engagement,instagram_content_publish, pages_manage_posts, read_insights"
										callback={responseFacebook}
										render={(renderProps) => (
											<div
												style={{
													marginLeft: "1.5%",
													marginTop: "1%",
													display: "flex",
													cursor: "pointer",
												}}
											>
												<p onClick={renderProps.onClick}>Link Now</p>
											</div>
										)}
									/>
								)}
								{/* <div style={{marginLeft: '7%', marginTop: '10%', display: 'flex'}}>Linked <img src="https://img.icons8.com/color/48/undefined/ok--v1.png" height='18px'/></div> */}
							</div>
						</div>
					</div>
					<br />
				</div>
				{/* User Tags */}
				{/* <label
                  
                  style={{ marginBottom: "1vh" }}
                >
                  Add tags to describe your brand
                </label>
                <div style={{width: '30%'}}>
                  <TagsInput selectedTags={handleSelectedTags} defaultTags={objective} />
                </div>  */}
			</div>

			<ThemeProvider theme={theme}>
				<Modal isOpen={showLinkedInOrgModal} style={modalStylesLinkedIn}>
					<div>
						<CloseIcon
							style={{
								position: "absolute",
								top: "25px",
								right: "25px",
								cursor: "pointer",
							}}
							onClick={() => linkOrgLater()}
						></CloseIcon>
					</div>
					<br />
					<br></br>
					<div className="org-modal-header">
						Select LinkedIn organizations to link to MyEra
					</div>
					<br></br>
					<br></br>

					<FormControl sx={{ ml: "25%", alignItems: "center" }}>
						<FormLabel id="demo-radio-buttons-group-label">
							Organization
						</FormLabel>

						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							value={selectedOrg}
							// defaultValue={linkedinOrganizations[0].$URN}
							name="radio-buttons-group"
							onChange={(e) => setSelectedOrg(e.target.value)}
						>
							<div>
								{linkedinOrganizations.map((org) => {
									return (
										<div>
											<FormControlLabel
												value={org.organization_urn}
												data-org-name={org.organization_name}
												control={<Radio />}
												label={get_org_label(org.organization_name)}
											/>
										</div>
									);
								})}
							</div>
						</RadioGroup>
					</FormControl>
					<br></br>
					<br></br>
					<div style={{ display: "flex", justifyContent: "space-evenly" }}>
						<LoadingButton
							variant="contained"
							style={{
								textTransform: "none",
								backgroundColor: "rgb(132, 232, 244)",
								width: "31%",
								borderRadius: "20px",
								// height: "50px",
								color: "black",
								// fontWeight: "lighter",
							}}
							onClick={() => setLinkedinOrganization()}
						>
							Select Organization
						</LoadingButton>
						<Button
							variant="contained"
							style={{
								textTransform: "none",
								backgroundColor: "rgb(132, 232, 244)",
								// width: "31%",
								borderRadius: "20px",
								// height: "50px",
								color: "black",
								// fontWeight: "lighter",
								// marginLeft: '5%'
							}}
							onClick={() => setShowLinkedinOrgModal(false)}
						>
							Link Later
						</Button>
					</div>
					<br></br>
				</Modal>
			</ThemeProvider>
		</div>
	);
}
