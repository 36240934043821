import React, { Component, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Modal from "react-modal";
import { TextField } from "@mui/material";
import { Cancel } from "@material-ui/icons";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import { toast } from "react-toastify";
import LinearProgress from "@mui/material/LinearProgress";
import { LoadingButton } from "@mui/lab";
import "./upload_library.css";
import { CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ImageCropper from "../ImageCropper/ImageCropper";
import { getCroppedImageService } from "../Generic/messageService";
import Image from "../../Components/Images/upload_library_asset.png";
import Axios from "axios";

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "rgb(132, 232, 244)",
		},
	},
	specialOutline: {
		borderColor: "pink",
		borderWidth: 4,
	},
});

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: "67.5%",
		width: "45%",
		color: "#FFFFFF",
		padding: "0%",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

export function UploadLibrary(props) {
	const [fetchingUploads, setFetchingUploads] = useState(true);
	const [selectedEditorType, setSelectedEditorType] = useState("ProductType");
	const [user_assets, setUserAssets] = useState([]);
	const [user_bg, setUserBg] = useState([]);
	const [modalIsOpen, setmodalIsOpen] = useState(false);
	const [cropperIsOpen, setcropperIsOpen] = useState(false);
	const [bgcropperIsOpen, setbgcropperIsOpen] = useState(false);
	const [logocropperIsOpen, setlogocropperIsOpen] = useState(false);
	const [selectedImage, setselectedImage] = useState(null);
	const [ImageURLs, setImageURLs] = useState({});
	const [uniqueImages, setUniqueImages] = useState([]);
	const [cropperPosition, setcropperPosition] = useState({});
	const [assetNote, setassetNote] = useState("");
	const [addingAssets, setaddingAssets] = useState(false);
	const [assetClicked, setAssetClicked] = useState(false);
	const [samename_assets, setSameNameassets] = useState([]);
	const [userlogos, setuserLogos] = useState([]);
	const [fetchingUserDetails, setFetchingUserDetails] = useState(true);
	const [sameAssetCount, setsameAssetCount] = useState({});

	useEffect(() => {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/get_user_assets/`, { headers })
			.then((response) => response.json())
			.then((data) => {
				console.log("API CALL!");

				// Getting only product assets
				var updated_data = data.filter(function (asset) {
					return asset.is_background != true;
				});
				setUserAssets(updated_data);
				sameAssetNoteCount(updated_data);

				// First Images for each asset note
				updateUniqueImages(updated_data);
			});

		fetch(`${process.env.REACT_APP_BACKEND}/get_backgrounds/`, { headers })
			.then((response) => response.json())
			.then((data) => {
				setUserBg(data);
				setFetchingUploads(false);
			});

		fetch(`${process.env.REACT_APP_BACKEND}/get_user/`, { headers })
			.then((response) => response.json())
			.then((data) => {
				setuserLogos(data.user_logos);
				setFetchingUserDetails(false);
			});
	}, []);

	useEffect(() => {
		getCroppedImageService.getMessage().subscribe((message) => {
			var byteString = atob(message.imageURL.split(",")[1]);
			var mimeString = message.imageURL
				.split(",")[0]
				.split(":")[1]
				.split(";")[0];
			var ab = new ArrayBuffer(byteString.length);
			var ia = new Uint8Array(ab);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			var blob = new Blob([ab], { type: mimeString });
			var file = new File([blob], "image.png");

			let updated = ImageURLs;
			let pos = cropperPosition;
			updated[message.originalURL] = file;
			pos[message.originalURL] = message.position;

			// setImageURLs(updated);
			setcropperPosition(pos);
		});
	}, [ImageURLs]);

	function openModal() {
		setmodalIsOpen(true);
	}

	function closeModal() {
		setmodalIsOpen(false);
	}

	function onFileChange(e) {
		let images = {};
		let position = {};

		for (let file of e.target.files) {
			var url = URL.createObjectURL(file);
			images[url] = file;
			position[url] = {};
		}

		setselectedImage(Object.keys(images)[0]);
		setImageURLs(images);
		setcropperPosition(position);
	}

	function addAsset() {
		setaddingAssets(true);
		var form_data = new FormData();
		form_data.append("asset_note", assetNote);
		for (const [img, file] of Object.entries(ImageURLs))
			form_data.append("assets", file);

		const { accessToken } = isAuthenticated();
		var requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/upload_multiple_user_assets/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				toast.success("Asset added to Library", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				setaddingAssets(false);
				setselectedImage(null);
				setassetNote("");
				setImageURLs({});

				let temp_user_assets = [];
				for (let ele of data.data) temp_user_assets.push(ele[0]);

				var new_assets = temp_user_assets.concat(user_assets);
				setUserAssets(new_assets);
				setSameNameassets(samename_assets.concat(temp_user_assets));
				sameAssetNoteCount(new_assets);
				updateUniqueImages(new_assets);
			})
			.catch((err) => {
				console.log("Error: " + err);
				toast.error("Asset failed to be added to Library", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				setaddingAssets(false);
				setselectedImage(null);
				setassetNote("");
				setImageURLs({});
			});
	}

	function addBackground() {
		setaddingAssets(true);

		var form_data = new FormData();
		form_data.append("is_background", true);
		form_data.append("asset_note", assetNote);
		for (const [img, file] of Object.entries(ImageURLs))
			form_data.append("assets", file);

		const { accessToken } = isAuthenticated();
		var requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/upload_multiple_user_assets/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				toast.success("Asset added to Library", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				setaddingAssets(false);
				setselectedImage(null);
				setassetNote("");
				setImageURLs({});

				let temp_user_bg = [];
				for (let ele of data.data) temp_user_bg.push(ele[0]);
				var new_bg = temp_user_bg.concat(user_bg);
				setUserBg(new_bg);
			})
			.catch((err) => {
				console.log("Error: " + err);
				toast.error("Asset failed to be added to Library", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				setaddingAssets(false);
				setselectedImage(null);
				setassetNote("");
				setImageURLs({});
			});
	}

	function handleDeleteButton(id) {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/delete_user_asset/${id}`, {
			headers,
		})
			.then((response) => response.json())
			.then((data) => {
				let updated_assets = user_assets.filter(function (asset) {
					return asset.id != id;
				});

				setUserAssets(updated_assets);
				setSameNameassets(
					samename_assets.filter(function (asset) {
						return asset.id != id;
					})
				);

				setUserBg(
					user_bg.filter(function (asset) {
						return asset.id != id;
					})
				);
				updateUniqueImages(updated_assets);
				sameAssetNoteCount(updated_assets);
				toast.success("Asset deleted!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	}

	function handleDeleteAllButton(note) {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		var iserror = false;
		user_assets.map((asset) => {
			if (asset.asset_note === note) {
				fetch(
					`${process.env.REACT_APP_BACKEND}/delete_user_asset/${asset.id}`,
					{
						headers,
					}
				)
					.then((response) => response.json())
					.then((data) => {})
					.catch((err) => {
						iserror = true;
					});
			}
		});

		if (!iserror) {
			toast.success("Asset deleted!", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			let updated_assets = user_assets.filter(function (asset) {
				return asset.asset_note != note;
			});
			setUserAssets(updated_assets);
			setSameNameassets(
				samename_assets.filter(function (asset) {
					return asset.asset_note != note;
				})
			);
			updateUniqueImages(updated_assets);
			sameAssetNoteCount(updated_assets);
		}
	}

	function addLogoFile() {
		const { accessToken } = isAuthenticated();
		var form_data3 = new FormData();
		form_data3.append("logo", ImageURLs[Object.keys(ImageURLs)[0]]);

		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND}/add_user_logo/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			data: form_data3,
		})
			.then((res) => {
				setuserLogos([...userlogos, res["data"]]);
				toast.success("Logo Added !!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((err) => {
				console.log(err);
				toast.error("Error: Couldn't add new logo!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});

		// closeModal();
		setImageURLs({});
	}

	function deleteLogoFile(logo) {
		const { accessToken } = isAuthenticated();
		setuserLogos((current) => current.filter((image) => image.id !== logo.id));
		Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND}/delete_user_logo/` + logo["id"],
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
			.then((res) => {
				console.log("Deleted: " + res);
				toast.success("Logo Deleted !!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((err) => {
				toast.error("Error: Couldn't delete logo!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	}

	function sameAssetNote(asset_note) {
		let arr = [];
		user_assets.map((asset) => {
			if (asset.asset_note === asset_note) arr.push(asset);
		});
		setSameNameassets(arr);
		setassetNote(asset_note);
	}

	function sameAssetNoteCount(user_asset) {
		var assetCount = {};
		for (let asset of user_asset) {
			if (asset.asset_note in assetCount)
				assetCount[asset.asset_note] = assetCount[asset.asset_note] + 1;
			else assetCount[asset.asset_note] = 1;
		}

		setsameAssetCount(assetCount);
	}

	function handleTextFieldChange(e) {
		setassetNote(e.target.value);
	}

	function updateUniqueImages(data) {
		var res = data.reduce(function (a, b) {
			if (!a[b["asset_note"]]) {
				a[b["asset_note"]] = b;
			}
			return a;
		}, {});
		setUniqueImages(Object.keys(res).map((key) => res[key]));
	}

	return (
		<>
			<div
				style={{
					marginTop: "0%",
					backgroundColor: "rgb(13,16, 22)",
					height: "92vh",
				}}
			>
				<CircularProgress
					style={{
						marginLeft: "50%",
						marginBottom: "25%",
						marginTop: "25%",
						display: fetchingUploads && fetchingUserDetails ? "block" : "none",
						color: "rgb(132, 232, 244)",
					}}
				></CircularProgress>

				<div
					style={{
						width: "100%",
						display: "flex",
						marginBottom: "4vh",
						display: fetchingUploads && fetchingUserDetails ? "none" : "flex",
					}}
				>
					<div
						style={{
							background: "#2C303A",
							width: "80%",
							borderRadius: "40px",
							marginTop: "8vh",
							marginLeft: "9vw",
						}}
					>
						<label
							style={{
								fontFamily: "Montserrat, sans-serif",
								fontWeight: "600",
								fontSize: "3vh",
								marginTop: "5vh",
								marginBottom: "3vh",
								marginLeft: "auto",
								textAlign: "center",
								color: "#c8daff",
							}}
						>
							My Uploads
						</label>

						{!assetClicked || samename_assets.length === 0 ? (
							<>
								<div
									style={{
										display: "flex",
										flexWrap: "wrap",
										marginLeft: "6.5%",
										fontSize: "20px",
										marginTop: "30px",
										fontSize: "2vh",
										paddingRight: "6vh",
									}}
								>
									<div
										id="defaultProductButton"
										type="submit"
										style={{
											textTransform: "none",
											// width: "5%",
											height: "3vh",
											color:
												selectedEditorType === "ProductType"
													? "#84E8F4"
													: "#FFFFFF",
											borderBottom:
												selectedEditorType === "ProductType"
													? "1px solid #84E8F4"
													: "none",
											cursor: "pointer",
										}}
										onClick={() => {
											document.getElementById(
												"defaultProductButton"
											).style.color = "rgba(98, 77, 232, 1)";
											document.getElementById(
												"customBackgroundButton"
											).style.color = "rgba(255, 255, 255, 0.7)";
											setSelectedEditorType("ProductType");
										}}
									>
										{/* <img width='25vh' height='25vh' src={text}
                                                 style={{marginRight: '1vh', marginBottom: '0.5vh'}}/> */}
										Product
									</div>

									<div
										id="customBackgroundButton"
										type="submit"
										style={{
											marginLeft: "7vh",
											textTransform: "none",
											color:
												selectedEditorType === "customBackgroundType"
													? "#84E8F4"
													: "#FFFFFF",
											borderBottom:
												selectedEditorType === "customBackgroundType"
													? "1px solid #84E8F4"
													: "none",
											// width: "9%",
											height: "3vh",
										}}
										onClick={() => {
											document.getElementById(
												"customBackgroundButton"
											).style.color = "rgba(98, 77, 232, 1)";
											document.getElementById(
												"defaultProductButton"
											).style.color = "rgba(255, 255, 255, 0.7)";
											setSelectedEditorType("customBackgroundType");
										}}
									>
										Background
									</div>

									<div
										id="customLogoButton"
										type="submit"
										style={{
											marginLeft: "7vh",
											textTransform: "none",
											color:
												selectedEditorType === "customLogoType"
													? "#84E8F4"
													: "#FFFFFF",
											borderBottom:
												selectedEditorType === "customLogoType"
													? "1px solid #84E8F4"
													: "none",
											// width: "13%",
											height: "4vh",
										}}
										onClick={() => {
											document.getElementById("customLogoButton").style.color =
												"rgba(98, 77, 232, 1)";
											document.getElementById(
												"defaultProductButton"
											).style.color = "rgba(255, 255, 255, 0.7)";
											setSelectedEditorType("customLogoType");
										}}
									>
										Logo
									</div>

									<button
										onClick={() => {
											setassetNote("");
											if (selectedEditorType === "ProductType") {
												openModal();
											} else if (selectedEditorType === "customLogoType")
												setlogocropperIsOpen(true);
											else setbgcropperIsOpen(true);
										}}
										style={{
											cursor: "pointer",
											borderRadius: "100px",
											border: "1px solid #84E8F4",
											fontSize: "2vh",
											backgroundColor: "rgba(132, 232, 244, 0.001)",
											color: "white",
											width: "21vh",
											height: "5.3vh",
											display: "flex",
											marginLeft: "auto",
										}}
									>
										<AddIcon
											style={{
												color: "#84E8F4",
												margin: "auto",
											}}
										/>
										<div
											style={{
												margin: "auto",
												marginLeft: "-30px",
												color: "#84E8F4",
											}}
										>
											{selectedEditorType === "ProductType"
												? "Products"
												: selectedEditorType === "customLogoType"
												? "Logo"
												: "Background"}
										</div>
									</button>
								</div>
								<div
									className="uploads"
									style={{
										display: "flex",
										flexWrap: "wrap",
										width: "93%",
										height: "50vh",
										overflowY: "scroll",
										marginTop: "20px",
									}}
								>
									{selectedEditorType === "ProductType"
										? uniqueImages.map((asset) => {
												return (
													<div
														className="col-sm-4 img-wrap2"
														style={{ width: "auto" }}
														key={asset.id}
													>
														<div className="overlayDiv">
															<div
																onClick={() => {
																	handleDeleteAllButton(asset.asset_note);
																}}
																className="close"
															>
																&times;
															</div>
															<img
																className="styleImage1"
																src={asset.asset}
																onClick={() => {
																	sameAssetNote(asset.asset_note);
																	if (selectedEditorType === "ProductType")
																		setAssetClicked(true);
																}}
															></img>
															<img
																className="overlay"
																src="https://img.icons8.com/ios-glyphs/60/D6D5CB/edit--v1.png"
																onClick={() => {
																	sameAssetNote(asset.asset_note);
																	if (selectedEditorType === "ProductType")
																		setAssetClicked(true);
																}}
															></img>
														</div>
														<div className="count">
															{sameAssetCount[asset.asset_note]}
														</div>

														<p
															style={{
																color: "white",
																textAlign: "center",
																fontSize: "17px",
																marginLeft: "20px",
															}}
														>
															{asset.asset_note}
														</p>
													</div>
												);
										  })
										: selectedEditorType === "customLogoType"
										? userlogos.map((asset, index) => {
												return (
													<div
														className="col-sm-4 img-wrap2"
														style={{ width: "auto" }}
														key={asset.id}
													>
														<div className="overlayDiv">
															<div
																onClick={() => {
																	deleteLogoFile(asset);
																}}
																className="close"
															>
																&times;
															</div>

															<img
																className="styleImage1"
																src={asset.logo}
															></img>
														</div>
													</div>
												);
										  })
										: user_bg.map((asset, index) => {
												return (
													<div
														className="col-sm-4 img-wrap2"
														style={{ width: "auto" }}
														key={asset.id}
													>
														<div className="overlayDiv">
															<div
																onClick={() => {
																	handleDeleteButton(asset.id);
																}}
																className="close"
															>
																&times;
															</div>

															<img
																className="styleImage1"
																src={asset.asset}
															></img>
														</div>
														<p
															style={{
																color: "white",
																textAlign: "center",
																fontSize: "17px",
																marginLeft: "20px",
															}}
														>
															{asset.asset_note}
														</p>
													</div>
												);
										  })}
								</div>
							</>
						) : (
							<>
								<div
									style={{
										display: "flex",
										flexWrap: "wrap",
										marginLeft: "6.5%",
										fontSize: "20px",
										marginTop: "30px",
										fontSize: "2vh",
										paddingRight: "6vh",
									}}
								>
									<div
										id="defaultProductButton"
										type="submit"
										style={{
											textTransform: "none",
											// width: "5%",
											height: "3vh",
											color: "#84E8F4",
										}}
									>
										{/* <img width='25vh' height='25vh' src={text}
                                                 style={{marginRight: '1vh', marginBottom: '0.5vh'}}/> */}
										{"Product > " + samename_assets[0].asset_note}
									</div>
									<div
										onClick={() => {
											setAssetClicked(false);
										}}
										style={{
											borderBottom: "1px solid #84E8F4",
											marginLeft: "60px",
											cursor: "pointer",
											color: "#84E8F4",
										}}
									>
										Back
									</div>
								</div>
								<div
									className="uploads"
									style={{
										display: "flex",
										flexWrap: "wrap",
										width: "93%",
										height: "50vh",
										overflowY: "scroll",
										marginTop: "20px",
									}}
								>
									{samename_assets.map((asset) => {
										return (
											<div
												className="col-sm-4 img-wrap2"
												style={{ width: "auto" }}
												key={asset.id}
											>
												<div className="overlayDiv">
													<div
														className="close"
														onClick={() => {
															handleDeleteButton(asset.id);
														}}
													>
														&times;
													</div>

													<img className="styleImage1" src={asset.asset}></img>
												</div>
											</div>
										);
									})}
									<ControlPointIcon
										onClick={() => setcropperIsOpen(true)}
										style={{
											marginLeft: "60px",
											marginTop: "7vh",
											fontSize: "5vh",
											color: "#84E8F4",
											cursor: "pointer",
										}}
									></ControlPointIcon>
								</div>
							</>
						)}
					</div>
				</div>
			</div>

			<Modal isOpen={modalIsOpen} style={modalStyles} ariaHideApp={false}>
				<div>
					<CloseIcon
						style={{
							position: "absolute",
							top: "25px",
							right: "25px",
							cursor: "pointer",
						}}
						onClick={() => {
							setassetNote("");
							closeModal();
						}}
					></CloseIcon>
				</div>
				<div style={{ textAlign: "left", marginTop: "23%", marginLeft: "12%" }}>
					<img
						style={{
							marginRight: "12%",
							float: "right",
							width: "12.3vw",
							height: "25vh",
						}}
						src={Image}
					></img>
					<h3>Create New Product Line</h3>
					<p style={{ color: "grey" }}>
						Upload multiple images of your product at once
						<br></br>and save them under your product name
					</p>

					<br></br>
					<br></br>
					<br></br>
					<TextField
						id="Temp"
						label="Enter Product Name..."
						value={assetNote}
						onChange={handleTextFieldChange}
						autoComplete="off"
						style={{ width: "18vw" }}
						sx={{
							input: { color: "white" },
							"& .MuiOutlinedInput-root": {
								"& > fieldset": { borderColor: "white" },
							},
							"& .MuiOutlinedInput-root:hover": {
								"& > fieldset": { borderColor: "wheat" },
							},
							"& .MuiOutlinedInput-root.Mui-focused:hover": {
								"& > fieldset": {
									borderColor: "#0d6efd",
								},
							},
						}}
						InputLabelProps={{
							sx: { color: "white" },
						}}
						InputProps={{
							sx: { borderRadius: "15px" },
						}}
					/>
				</div>
				<div
					style={{ width: "100%", display: "flex", justifyContent: "center" }}
				>
					<label
						style={{
							display: "inline-block",
							marginTop: "10%",
						}}
					>
						<LoadingButton
							variant="contained"
							component="span"
							loading={addingAssets}
							onClick={() => {
								setcropperIsOpen(true);
							}}
							style={{
								cursor: "pointer",
								borderRadius: "100px",
								fontSize: "1.8vh",
								backgroundColor: "#84E8F4",
								width: "21vh",
								height: "5vh",
								textTransform: "none",
							}}
						>
							<FileUploadOutlinedIcon
								style={{
									color: "black",
									margin: "auto",
									fontSize: "2.1vh",
								}}
							/>
							<div
								style={{
									margin: "auto",
									marginLeft: "0px",
									color: "black",
								}}
							>
								Upload Images
							</div>
						</LoadingButton>
						<input
							accept="image/*"
							id="contained-button-file"
							multiple
							type="file"
							onChange={(e) => onFileChange(e)}
							style={{ display: "none" }}
						/>
					</label>
				</div>
			</Modal>

			<Modal isOpen={bgcropperIsOpen} style={modalStyles} ariaHideApp={false}>
				<LinearProgress style={{ display: "none" }} />
				<div>
					<CloseIcon
						style={{
							position: "absolute",
							top: "25px",
							right: "25px",
							cursor: "pointer",
						}}
						onClick={() => {
							setselectedImage(null);
							setImageURLs({});
							setbgcropperIsOpen(false);
							setassetNote("");
						}}
					></CloseIcon>
				</div>

				{selectedImage ? (
					<div
						className="user_assets"
						style={{
							position: "absolute",
							cursor: "pointer",
							top: 130,
							left: 15,
							height: "45vh",
							width: "25%",
							overflow: "auto",
						}}
					>
						{Object.entries(ImageURLs).map(([image, file]) => {
							return (
								<div
									key={image}
									className="img-wrap2"
									onClick={() => {
										setselectedImage(image);
									}}
									style={{
										borderRadius: "10px",
										height: "75px",
										width: "50px",
										marginTop: "15px",
										marginBottom: "50px",
									}}
								>
									<img className="styleAssetImage1" src={image} />
								</div>
							);
						})}
					</div>
				) : (
					<></>
				)}

				<br></br>
				<br></br>
				<br></br>
				<h3>Upload Background</h3>

				{selectedImage ? (
					<div key={selectedImage} style={{ display: "flex" }}>
						<div style={{ marginLeft: "25%" }}>
							<ImageCropper
								src={selectedImage}
								position={cropperPosition[selectedImage]}
							></ImageCropper>
						</div>
						<Cancel
							onClick={() => {
								const updated = { ...ImageURLs };
								delete updated[selectedImage];
								setImageURLs(updated);

								if (ImageURLs.length === 1) setselectedImage(null);
								else if (Object.keys(ImageURLs)[0] === selectedImage)
									setselectedImage(Object.keys(ImageURLs)[1]);
								else setselectedImage(Object.keys(ImageURLs)[0]);
							}}
							style={{ marginLeft: "10%" }}
						></Cancel>
					</div>
				) : (
					<div
						style={{
							borderRadius: "5%",
							height: "250px",
							width: "300px",
							margin: "auto",
							marginTop: "20px",
							display: selectedImage ? "none" : "block",
						}}
					>
						<div>
							<CloudUploadIcon
								style={{
									color: "#84E8F4",
									fontSize: "30vh",
								}}
							></CloudUploadIcon>
						</div>
					</div>
				)}

				<div style={{ marginTop: "30px", color: "grey" }}>
					Uploaded Images to be used as background
				</div>
				<br></br>
				<TextField
					label="Background Name..."
					value={assetNote}
					autoComplete="off"
					sx={{
						width: "30%",
						input: { textAlign: "center", color: "white" },
						"& .MuiOutlinedInput-root": {
							"& > fieldset": { borderColor: "white" },
						},
						"& .MuiOutlinedInput-root:hover": {
							"& > fieldset": { borderColor: "wheat" },
						},
						"& .MuiOutlinedInput-root.Mui-focused:hover": {
							"& > fieldset": {
								borderColor: "#0d6efd",
							},
						},
					}}
					InputLabelProps={{
						sx: { color: "white" },
					}}
					onChange={(e) => handleTextFieldChange(e)}
				></TextField>
				<br></br>
				<br></br>
				<br></br>
				{selectedImage ? (
					<label style={{ display: "flex", justifyContent: "center" }}>
						<LoadingButton
							variant="contained"
							component="span"
							loading={addingAssets}
							style={{
								cursor: "pointer",
								textTransform: "none",
								backgroundColor: "#84E8F4",
								width: "21%",
								borderRadius: "50px",
								height: "45px",
								color: "black",
								fontSize: "18px",
								fontWeight: "lighter",
							}}
							onClick={() => addBackground()}
						>
							<div>Save</div>
						</LoadingButton>
					</label>
				) : (
					<div
						style={{ width: "100%", display: "flex", justifyContent: "center" }}
					>
						<label
							style={{
								display: "inline-block",
							}}
						>
							<input
								accept="image/*"
								id="contained-button-file"
								multiple
								type="file"
								onChange={(e) => onFileChange(e)}
								style={{ display: "none" }}
							/>
							<Button
								variant="contained"
								component="span"
								style={{
									cursor: "pointer",
									borderRadius: "100px",
									fontSize: "1.8vh",
									backgroundColor: "#84E8F4",
									width: "21vh",
									height: "5vh",
									textTransform: "none",
									color: "black",
									fontWeight: "lighter",
								}}

								// onClick={() => addAsset()}
							>
								<div>
									<FileUploadOutlinedIcon
										style={{
											color: "black",
											marginRight: "5px",
											fontSize: "20px",
										}}
									/>
								</div>
								<div>Upload</div>
							</Button>
						</label>
					</div>
				)}
			</Modal>

			<Modal isOpen={logocropperIsOpen} style={modalStyles} ariaHideApp={false}>
				<LinearProgress style={{ display: "none" }} />
				<div>
					<CloseIcon
						style={{
							position: "absolute",
							top: "25px",
							right: "25px",
							cursor: "pointer",
						}}
						onClick={() => {
							setselectedImage(null);
							setImageURLs({});
							setlogocropperIsOpen(false);
						}}
					></CloseIcon>
				</div>

				{selectedImage ? (
					<div
						className="user_assets"
						style={{
							position: "absolute",
							cursor: "pointer",
							top: 130,
							left: 15,
							height: "45vh",
							width: "25%",
							overflow: "auto",
						}}
					>
						{Object.entries(userlogos).map(([image, file]) => {
							return (
								<div
									key={image}
									className="img-wrap2"
									onClick={() => {
										setselectedImage(image);
									}}
									style={{
										borderRadius: "10px",
										height: "75px",
										width: "50px",
										marginTop: "15px",
										marginBottom: "50px",
									}}
								>
									<img className="styleAssetImage" src={image.logo} />
								</div>
							);
						})}
					</div>
				) : (
					<></>
				)}

				<br></br>
				<br></br>
				<br></br>
				<h3>Upload Logo</h3>

				{selectedImage ? (
					<div key={selectedImage} style={{ display: "flex" }}>
						<div style={{ marginLeft: "25%" }}>
							<ImageCropper
								src={selectedImage}
								position={cropperPosition[selectedImage]}
							></ImageCropper>
						</div>
						<Cancel
							onClick={() => {
								const updated = { ...ImageURLs };
								delete updated[selectedImage];
								setImageURLs(updated);
								if (ImageURLs.length === 1) setselectedImage(null);
								else if (Object.keys(ImageURLs)[0] === selectedImage)
									setselectedImage(Object.keys(ImageURLs)[1]);
								else setselectedImage(Object.keys(ImageURLs)[0]);
							}}
							style={{ marginLeft: "10%" }}
						></Cancel>
					</div>
				) : (
					<div
						style={{
							borderRadius: "5%",
							height: "250px",
							width: "300px",
							margin: "auto",
							marginTop: "20px",
							display: selectedImage ? "none" : "block",
						}}
					>
						<div>
							<CloudUploadIcon
								style={{
									color: "#84E8F4",
									fontSize: "30vh",
								}}
							></CloudUploadIcon>
						</div>
					</div>
				)}

				<div style={{ marginTop: "30px", color: "grey" }}>
					Upload More Logos
				</div>
				<br></br>
				<br></br>
				<br></br>
				{selectedImage ? (
					<LoadingButton
						variant="contained"
						component="span"
						loading={addingAssets}
						style={{
							cursor: "pointer",
							textTransform: "none",
							backgroundColor: "#84E8F4",
							width: "21%",
							borderRadius: "50px",
							height: "45px",
							color: "black",
							fontSize: "18px",
							fontWeight: "lighter",
						}}
						onClick={() => {
							addLogoFile();
							setselectedImage(null);
						}}
					>
						Save
					</LoadingButton>
				) : (
					<div
						style={{ width: "100%", display: "flex", justifyContent: "center" }}
					>
						<label
							style={{
								display: "inline-block",
							}}
						>
							<input
								accept="image/*"
								id="contained-button-file"
								multiple
								type="file"
								onChange={(e) => onFileChange(e)}
								style={{ display: "none" }}
							/>
							<LoadingButton
								variant="contained"
								component="span"
								loading={addingAssets}
								style={{
									cursor: "pointer",
									borderRadius: "100px",
									fontSize: "1.8vh",
									backgroundColor: "#84E8F4",
									width: "21vh",
									height: "5vh",
									textTransform: "none",
									color: "black",
									fontWeight: "lighter",
								}}
							>
								<FileUploadOutlinedIcon
									style={{
										color: "black",
										marginRight: "5px",
										fontSize: "20px",
									}}
								/>
								Upload
							</LoadingButton>
						</label>
					</div>
				)}
			</Modal>

			<Modal isOpen={cropperIsOpen} style={modalStyles} ariaHideApp={false}>
				<LinearProgress style={{ display: "none" }} />
				<div>
					<CloseIcon
						style={{
							position: "absolute",
							top: "25px",
							right: "25px",
							cursor: "pointer",
						}}
						onClick={() => {
							setassetNote("");
							setmodalIsOpen(false);
							setselectedImage(null);
							setImageURLs({});
							setcropperIsOpen(false);
						}}
					></CloseIcon>
				</div>

				{selectedImage ? (
					<div
						className="user_assets"
						style={{
							position: "absolute",
							cursor: "pointer",
							top: 130,
							left: 15,
							height: "45vh",
							width: "25%",
							overflow: "auto",
						}}
					>
						{Object.entries(ImageURLs).map(([image, file]) => {
							return (
								<div
									key={image}
									className="img-wrap2"
									onClick={() => {
										setselectedImage(image);
									}}
									style={{
										borderRadius: "10px",
										height: "75px",
										width: "50px",
										marginTop: "15px",
										marginBottom: "50px",
									}}
								>
									<img className="styleAssetImage1" src={image} />
								</div>
							);
						})}
					</div>
				) : (
					<></>
				)}

				<br></br>
				<br></br>
				<br></br>
				<h3>Upload Product Images</h3>

				{selectedImage ? (
					<div key={selectedImage} style={{ display: "flex" }}>
						<div style={{ marginLeft: "25%" }}>
							<ImageCropper
								src={selectedImage}
								position={cropperPosition[selectedImage]}
							></ImageCropper>
						</div>
						<Cancel
							onClick={() => {
								let updated = { ...ImageURLs };
								delete updated[selectedImage];
								if (ImageURLs.length === 1) setselectedImage(null);
								else if (Object.keys(ImageURLs)[0] === selectedImage)
									setselectedImage(Object.keys(ImageURLs)[1]);
								else setselectedImage(Object.keys(ImageURLs)[0]);
								setImageURLs(updated);
							}}
							style={{ marginLeft: "10%" }}
						></Cancel>
					</div>
				) : (
					<div
						style={{
							borderRadius: "5%",
							height: "250px",
							width: "300px",
							margin: "auto",
							marginTop: "20px",
							display: selectedImage ? "none" : "block",
						}}
					>
						<div>
							<CloudUploadIcon
								style={{
									color: "#84E8F4",
									fontSize: "30vh",
								}}
							></CloudUploadIcon>
						</div>
					</div>
				)}

				<div style={{ marginTop: "30px", color: "grey" }}>
					Upload more images to add to this product line
				</div>
				<br></br>
				<br></br>
				<br></br>
				{selectedImage ? (
					<LoadingButton
						variant="contained"
						component="span"
						loading={addingAssets}
						style={{
							cursor: "pointer",
							textTransform: "none",
							backgroundColor: "#84E8F4",
							width: "21%",
							borderRadius: "50px",
							height: "45px",
							color: "black",
							fontSize: "18px",
							fontWeight: "lighter",
						}}
						onClick={() => addAsset()}
					>
						Save
					</LoadingButton>
				) : (
					<div
						style={{ width: "100%", display: "flex", justifyContent: "center" }}
					>
						<label
							style={{
								display: "inline-block",
							}}
						>
							<input
								accept="image/*"
								id="contained-button-file"
								multiple
								type="file"
								onChange={(e) => onFileChange(e)}
								style={{ display: "none" }}
							/>
							<LoadingButton
								variant="contained"
								component="span"
								loading={addingAssets}
								style={{
									cursor: "pointer",
									textTransform: "none",
									backgroundColor: "#84E8F4",
									color: "black",
									fontWeight: "lighter",
									borderRadius: "100px",
									fontSize: "1.8vh",
									width: "21vh",
									height: "5vh",
								}}
							>
								<FileUploadOutlinedIcon
									style={{
										color: "black",
										marginRight: "5px",
										fontSize: "20px",
									}}
								/>
								Upload Images
							</LoadingButton>
						</label>
					</div>
				)}
			</Modal>
		</>
	);
}
