import React, { useEffect } from "react";
import Logo from "../Images/myEra_NewLogo.png";
import { Link } from "react-router-dom";
// import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Era_Elephant from "../Images/Era_Elephant.png";
import * as Yup from "yup";
import "./login.css";
import { useFormik } from "formik";
import { Shopifysignup } from "../../Helper/Endpoints/Endpoints";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("must be a valid email")
    .max(255)
    .required("required"),
  password: Yup.string().min(6).required("required"),
});

export default function ShopifyLogin(props) {
  if (document.getElementById("outer-container"))
    document.getElementById("outer-container").style.backgroundColor =
      "rgba(11, 17, 32, 1)";

  const formik = useFormik({
    initialValues: {
      email: "",
      storeURL: "",
      password: "",
    },
    validationSchema,
    onSubmit: async ({ email, storeURL, password }) => {
      Shopifysignup({ email, storeURL, password }).then((response) => {
        if (response.error) {
          formik.setErrors({ email: "Email and password do not match" });
          return;
        }

        localStorage.setItem(
          "Userstore",
          JSON.stringify({
            storeURL,
          })
        );
        props.history.push({
          pathname: `/shopify-form`,
        });
      });
    },
  });

  useEffect(() => {
    document.title = "MyEra | Shopify Login";
  }, []);

  return (
    <div>
      <div className="mobilenave_outer">
        <Link to="/">
          <img
            class="eralogo"
            src={Logo}
            alt="Logo"
            style={{ marginTop: "4px", marginRight: "5px" }}
          />
        </Link>
      </div>
      <div>
        <div class="EraElephantDiv">
          <img class="EraElephant_img" src={Era_Elephant} style={{}} alt="" />
        </div>
      </div>

      <div class="Wrapperdiv">
        <div class="bodycontainer">
          <div class="Bodycontainer_inner">Register to your account</div>
          <div class=" InputLabel" style={{ textAlign: "center" }}>
            Welcome to the world of customizations!
          </div>
          <div class="outside_container" style={{}}>
            <div class="InputLabel">Email Address*</div>
            <div class="inputwrapper">
              <input
                class="input_email Inputs"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
              />
              {formik.errors.email && formik.touched.email && (
                <p>{formik.errors.email}</p>
              )}
            </div>
            <div class="InputLabel">Your Shopify Store URL (eg ....)*</div>
            <div class="inputwrapper">
              <input
                class="input_storeURL Inputs"
                id="storeURL"
                value={formik.values.storeURL}
                onChange={formik.handleChange}
                type="text"
              />
            </div>
            <div class="InputLabel">Password*</div>
            <div class="inputwrapper">
              <input
                class="input_pass Inputs"
                id="password"
                value={formik.values.password}
                type="password"
                onChange={formik.handleChange}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    formik.handleSubmit();
                  }
                }}
              />
              {formik.errors.password && formik.touched.password && (
                <p style={{}}>{formik.errors.password}</p>
              )}
            </div>
          </div>

          <div class="ButtonWrapper">
            <Button
              class="submit_button"
              style={{}}
              variant="contained"
              color="secondary"
              type="button"
              fullWidth={true}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
