import React from 'react';

export default function TermsOfUse() {
  return (
    <div style={{ color: 'white', width: '90%', margin: 'auto' }}>
      <div style={{ marginTop: '5vh', fontSize: '5vh' }}>Terms Of Use</div>
      <div style={{ fontSize: '4vh', marginTop: '4vh', marginBottom: '2vh' }}>Last updated: 3rd August, 2021</div>

      <div>
        <div style={{ marginBottom: '2vh' }}>Please read these Terms of Service ("Terms", "Terms of Service") carefully before entering into the service period, accepting the services offered by MyEra Technologies Pvt. Ltd. (Registered at G-1, MSSC., Shastri Nagar, Jaipur) commonly known by the trade name MyEra ("us", "we", "our" and "the company").</div>
        <div style={{ marginBottom: '2vh' }}>Your ("The Client") access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all clients, associates and others who avail our services, directly or indirectly.</div>
        <div style={{ marginBottom: '2vh' }}>By availing the Service(s) you agree to be bound by these Terms. If you disagree with any part of the terms then you may not be able to avail our Service(s) and become liable for legal action against the usage of any Intellectual Property that may be generated by us during the service period, including the curated campaigns report and proposed action plan.</div>
        <div style={{ marginBottom: '2vh' }}>Termination</div>
        <div style={{ marginBottom: '2vh' }}>We may terminate or suspend providing our Service(s) immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</div>
        <div style={{ marginBottom: '2vh' }}>The Client and/or their Associates shall be liable for any financial dues incurred by us during the service period prior to the point of termination, provided there is a compliant financial record maintained by us bifurcating all the expenses incurred during the service period towards providing our service(s) to the client</div>
        <div style={{ marginBottom: '2vh' }}>All provisions of the Terms which by their nature should survive the termination shall survive termination, including, without limitation, ownership provisions to the created Intellectual Property, indemnity and limitations of liability.</div>
        <div style={{ marginBottom: '2vh' }}>Content</div>
        <div style={{ marginBottom: '2vh' }}>This clause serves as a provision to notify the client of the ownership rights to the various collateral that may be generated by us during the service period in the event of termination. The client reserves the right to the ownership of content created by us (refer below for a list of covered and non-covered collateral under this clause). All content that is listed as covered will survive termination and the client will lose ownership of all content listed as non-covered in the event of termination, with immediate effect. However, in the event of expiry of the service period, the client reserves all rights to the content, both covered and non-covered in the termination clause, as stated above.</div>
        <div style={{ marginBottom: '2vh' }}>The following list of collateral are classified as Intellectual Property of the company that are covered by the clause:</div>
        <div style={{ marginBottom: '2vh' }}>The client reserves complete rights to any registered trademarks or Intellectual Property shared by the client to be used by the company during the service period, without the additional modifications, if any, done by the company.</div>
        <div style={{ marginBottom: '2vh' }}>Changes</div>
        <div style={{ marginBottom: '2vh' }}>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 7 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</div>
        <div style={{ marginBottom: '2vh' }}>IndemnityLimitations of LiabilityContact Us</div>
        <div style={{ marginBottom: '2vh' }}>If you have any questions about these Terms, please contact us.</div>
      </div>
    </div>
  );
}
