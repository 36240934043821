import React from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

const OptionDialog = ({ open, onClose, onCaptureFromCamera, onUploadFromGallery }) => {
    return (
        <Dialog open={ open } onClose={ onClose }>
            <DialogTitle>Choose an option</DialogTitle>
            <List>
                <ListItem button onClick={ onCaptureFromCamera }>
                    <ListItemIcon>
                        <CameraAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Capture from Camera" />
                </ListItem>
                <ListItem button onClick={ onUploadFromGallery }>
                    <ListItemIcon>
                        <PhotoLibraryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Upload from Gallery" />
                </ListItem>
            </List>
        </Dialog>
    );
};

export default OptionDialog;