import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import "../Campaign/CampaignJourney.css";
import { Cancel } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageCropper from "../ImageCropper/ImageCropper";
import { getCroppedImageService } from "../Generic/messageService";
import styles from "./ImageUploadModal.module.css";
import head from "./Human_Head.png";
import { Checkbox, FormControlLabel } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		transform: "translate(-50%, -50%)",
		backgroundColor: "white",
		borderRadius: "3vh",
		textAlign: "center",
		height: "80%",
		width: "90%",
		color: "#FFFFFF",
		padding: "0%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		maxWidth: "900px",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

const ImageUploadModal = ({ children, ...props }) => {
	const [ImageURLs, setImageURLs] = useState({});
	const [cropperPosition, setcropperPosition] = useState({});
	const [addingAssets, setaddingAssets] = useState(false);
	const [selectedImage, setselectedImage] = useState(null);
	const [isAboveSizeLimit, setIsAboveSizeLimit] = useState(false);
	const [isAboveLengthLimit, setIsAboveLengthLimit] = useState(false);
	const buttonRef = useRef(null);
	const [cameraOpen, setCameraopen] = useState(false); //this is use for open the camera and guidelines in upload section
	const [stream, setStream] = useState(null); // to stop the camera
	const [selfie, setSelfie] = useState(false); // to display selfi
	let videoRef = useRef(null); //ref for camera
	let photoRef = useRef(null); // ref for clicked image
	const [inBgRemoval, setInBgRemoval] = useState(false);
	const [removedBgImage, setRemovedBgImage] = useState(null);
	const [cameraBgRemoval, setCameraBgRemoval] = useState(props.cameraBgRemoval);
	const [allowCameraUploads, setAllowCameraUploads] = useState(
		props.allowCameraUploads
	);
	const [showGuidelines, setShowGuidelines] = useState(props.showGuidelines);
	const [cameraBgRemovalChoice, setCameraBgRemovalChoice] = useState(false);

	const theme = createTheme({
		palette: {
			mode: "light",
			primary: {
				main: props.primaryColor,
			},
		},
	});

	const handle_take_capture_now_to_open_camera = () => {
		setCameraopen(true);
		getUserCamera();
	};

	const handle_take_picture_after_userClicksImage = () => {
		setSelfie(true);

		setTimeout(function () {
			let width = 500;
			let height = 500;
			let photo = photoRef.current;
			let video = videoRef.current;
			photo.width = width;
			photo.height = height;

			let ctx = photo.getContext("2d");
			ctx.translate(photo.width, 0);
			ctx.scale(-1, 1);
			ctx.drawImage(video, 0, 0, photo.width, photo.height);

			const dataURI = photo.toDataURL();
			var blob = dataURItoBlob(dataURI);
			var file = new File([blob], "image.png");

			if (
				cameraBgRemoval === "Remove Background" ||
				(cameraBgRemoval === "Ask User" && cameraBgRemovalChoice)
			) {
				const form_data = new FormData();
				form_data.append("image", file);

				var requestOptions = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${props.accessToken}`,
					},
					body: form_data,
				};

				setInBgRemoval(true);
				fetch(
					`${process.env.REACT_APP_BACKEND}/remove_background/`,
					requestOptions
				)
					.then((response) => response.blob())
					.then((data) => {
						// var url = URL.createObjectURL(data);
						setRemovedBgImage(data);
					})
					.finally(() => {
						setInBgRemoval(false);
					});
			} else {
				setRemovedBgImage(file);
			}
		}, 10);
	};

	function dataURItoBlob(dataURI) {
		var byteString = atob(dataURI.split(",")[1]);
		var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ab], { type: mimeString });
	}

	const handleConfirmButton = () => {
		function_to_close_camera();
		setSelfie(false);
		setCameraopen(false);

		var images = [];
		var url = URL.createObjectURL(removedBgImage);
		images[url] = removedBgImage;
		props.onSave(images);
		setRemovedBgImage(null);
	};

	const closeCamera = () => {
		// use to close the user camera by calling another function
		setCameraopen(false);
		function_to_close_camera();
	};

	const function_to_close_camera = () => {
		// use to close the user camera

		if (stream) {
			// Stop all tracks in the stream
			stream.getTracks().forEach((track) => {
				track.stop();
			});
			// Remove the stream from state
			setStream(null);
			// Pause the video element
			if (videoRef.current) {
				videoRef.current.pause();
				videoRef.current.srcObject = null;
			}
		}
	};

	const getUserCamera = () => {
		//this function opens the camera
		navigator.mediaDevices
			.getUserMedia({
				video: true,
			})
			.then((stream) => {
				let video = videoRef.current;

				video.srcObject = stream;
				video.play();
				setStream(stream);
			})
			.catch((error) => {
				return <div>ERROR {error.data}</div>;
			});
	};

	function onFileChange(e) {
		let images = {};
		let position = {};

		if (e.target.files.length > 5) {
			setIsAboveLengthLimit(true);
			return;
		} else {
			setIsAboveLengthLimit(false);
		}

		let upload_limit_satisfied = checkUploadLimit(e.target.files);
		setIsAboveSizeLimit(!upload_limit_satisfied);
		if (!upload_limit_satisfied) {
			return;
		}

		for (let file of e.target.files) {
			var url = URL.createObjectURL(file);
			images[url] = file;
			position[url] = {};
		}

		setselectedImage(Object.keys(images)[0]);
		setImageURLs(images);
		setcropperPosition(position);
	}

	const sleep = (ms) => {
		return new Promise((resolve) => setTimeout(resolve, ms));
	};

	useEffect(() => {
		// console.log("In Use Effect");
		// sleep(200).then((r) => {
		// 	buttonRef.current.click();
		// 	console.log("Clicked");
		// });
	}, [props]);

	useEffect(() => {
		getCroppedImageService.getMessage().subscribe((message) => {
			if (message.imageURL) {
				let byteString = atob(message.imageURL.split(",")[1]);
				let mimeString = message.imageURL
					.split(",")[0]
					.split(":")[1]
					.split(";")[0];
				let ab = new ArrayBuffer(byteString.length);
				let ia = new Uint8Array(ab);
				for (let i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				}

				let blob = new Blob([ab], { type: mimeString });
				let file = new File([blob], "image.png");

				let updated = ImageURLs;
				let pos = cropperPosition;
				updated[message.originalURL] = file;
				pos[message.originalURL] = message.position;

				// setImageURLs(updated);
				setcropperPosition(pos);
			}
		});
	}, [ImageURLs]);

	function checkUploadLimit(files) {
		for (let file of files) {
			if (file.size / 1024 > 1024 * 10) {
				return false;
			}
		}

		return true;
	}

	return (
		<ThemeProvider theme={theme}>
			<Modal isOpen={props.isOpen} style={modalStyles} ariaHideApp={false}>
				<div>
					<CloseIcon
						style={{
							position: "absolute",
							top: "25px",
							right: "25px",
							cursor: "pointer",
							color: "black",
						}}
						onClick={() => {
							props.onClose();
						}}
					></CloseIcon>
				</div>

				<h5
					style={{
						color: "black",
					}}
				>
					{Object.keys(ImageURLs).length != 0
						? "Crop image"
						: props.heading || "Upload Product Images"}
				</h5>

				{selectedImage ? (
					<>
						<div className={styles.cropper_wrapper}>
							<div className={styles.user_assets}>
								{Object.entries(ImageURLs).map(([image, file]) => {
									return (
										<div
											key={image}
											className={styles.img_wrap}
											onClick={() => {
												setselectedImage(image);
											}}
										>
											<img className="styleAssetImage1" src={image} />
										</div>
									);
								})}
							</div>
							<div key={selectedImage} style={{ display: "flex" }}>
								<ImageCropper
									src={selectedImage}
									position={cropperPosition[selectedImage]}
								></ImageCropper>
								<Cancel
									onClick={() => {
										let updated = { ...ImageURLs };
										delete updated[selectedImage];
										if (ImageURLs.length === 1) setselectedImage(null);
										else if (Object.keys(ImageURLs)[0] === selectedImage)
											setselectedImage(Object.keys(ImageURLs)[1]);
										else setselectedImage(Object.keys(ImageURLs)[0]);
										setImageURLs(updated);
									}}
									style={{ marginLeft: "10%" }}
								></Cancel>
							</div>
						</div>
						<LoadingButton
							variant="contained"
							component="span"
							loading={addingAssets}
							sx={{
								backgroundColor: props.primaryColor,
								color: "#fff",

								"&:hover": {
									backgroundColor: "#1976d2",
									color: "#fff",
								},
							}}
							onClick={() => {
								setImageURLs({});
								setselectedImage(null);
								props.onSave(ImageURLs);
							}}
						>
							Save
						</LoadingButton>
					</>
				) : (
					<>
						<div
							style={{
								borderRadius: "5%",
								height: "250px",
								width: "300px",
								display: selectedImage ? "none" : "block",
							}}
						>
							<div>
								<CloudUploadIcon
									style={{
										color: props.primaryColor,
										fontSize: "30vh",
									}}
								></CloudUploadIcon>
							</div>
						</div>
						<div
							className={styles.action_box}
							// style={{ width: "100%", display: "flex", justifyContent: "space-evenly", flexDirection:"row" }}
						>
							<label
								style={{
									display: "inline-block",
								}}
							>
								<input
									accept="image/*"
									id="contained-button-file"
									multiple
									type="file"
									onChange={(e) => {
										onFileChange(e);
									}}
									style={{ display: "none" }}
								/>
								<LoadingButton
									variant="contained"
									component="span"
									loading={addingAssets}
									ref={buttonRef}
									sx={{
										backgroundColor: props.primaryColor,
										color: "#fff",
										width: "170px",

										"&:hover": {
											backgroundColor: "#1976d2",
											color: "#fff",
										},
									}}
								>
									<FileUploadOutlinedIcon
										style={{
											color: "white",

											fontSize: "20px",
										}}
									/>
									Browse
								</LoadingButton>
							</label>
							{allowCameraUploads ? (
								<label
									style={{
										display: "inline-block",
									}}
								>
									<LoadingButton
										onClick={() => handle_take_capture_now_to_open_camera()}
										variant="outlined"
										loading={addingAssets}
										ref={buttonRef}
									>
										<FileUploadOutlinedIcon
											style={{
												color: props.primaryColor,

												fontSize: "20px",
											}}
										/>
										Take Picture
									</LoadingButton>
								</label>
							) : null}
						</div>
						{cameraOpen ? (
							<div className={styles.camera_and_guidelines_section}>
								<div className={styles.close_x} onClick={() => closeCamera()}>
									X
								</div>
								<div className={styles.camera_and_guidelines_section_upperbox}>
									<div className={styles.camera_area}>
										<video className={styles.camera} ref={videoRef}></video>
										{showGuidelines ? (
											<img
												src={props.guidelineFrame ? props.guidelineFrame : head}
												className={styles.user_head_area_in_camera}
											/>
										) : null}
									</div>
									<div className={styles.guideline_area}>
										<div
											style={{
												textDecoration: "underline",
												textAlign: "center",
												marginBottom: "12px",
											}}
										>
											Guidelines
										</div>
										<div>
											{" "}
											1. Ensure that you are in a well lit area <br /> <br />
											2. Position yourself inside the frame <br /> <br />
											3. Click the picture
										</div>
									</div>
								</div>
								<div className={styles.camera_and_guidelines_section_lowerbox}>
									{cameraBgRemoval === "Ask User" ? (
										<FormControlLabel
											control={
												<Checkbox
													checked={cameraBgRemovalChoice}
													sx={{
														"&.Mui-checked": { color: props.primaryColor },
													}}
													onChange={(e) => {
														setCameraBgRemovalChoice(e.target.checked);
													}}
												/>
											}
											label="Remove Background"
											color="primary"
											sx={{ color: "black" }}
										/>
									) : null}
									<LoadingButton
										variant="contained"
										component="span"
										loading={addingAssets}
										ref={buttonRef}
										sx={{
											backgroundColor: props.primaryColor,
											color: "#fff",
											width: "100%",
											marginTop: "10px",
											marginBottom: "10px",

											"&:hover": {
												backgroundColor: "#1976d2",
												color: "#fff",
											},
										}}
										onClick={() => handle_take_picture_after_userClicksImage()}
									>
										Take Picture
									</LoadingButton>
								</div>
							</div>
						) : null}

						{selfie ? (
							<div className={styles.selfie_box}>
								<div className={styles.yr_selfie}>
									{inBgRemoval ? (
										<div className={styles.progress_container}>
											<CircularProgress />
										</div>
									) : removedBgImage ? (
										<>
											<div>Are you sure you want to use this image?</div>
											<img
												src={URL.createObjectURL(removedBgImage)}
												className={styles.bgRemoved_image}
												style={{ transform: "scaleX(1)" }}
											></img>
										</>
									) : (
										<canvas
											className={styles.canvas}
											ref={photoRef}
											height="500"
											width="500"
										></canvas>
									)}
								</div>

								<div className={styles.selfie_buttons}>
									<LoadingButton
										variant="contained"
										component="span"
										loading={addingAssets}
										sx={{
											backgroundColor: props.primaryColor,
											color: "#fff",
											width: "180px",
											margin: "15px",

											"&:hover": {
												backgroundColor: "#1976d2",
												color: "#fff",
											},
										}}
										onClick={() => handleConfirmButton()}
									>
										Confirm
									</LoadingButton>

									<LoadingButton
										variant="contained"
										component="span"
										loading={addingAssets}
										sx={{
											backgroundColor: "white",
											color: props.primaryColor,
											border: "1px solid #020441",
											width: "170px",
											margin: "15px",

											"&:hover": {
												backgroundColor: "#1976d2",
												color: "#fff",
											},
										}}
										onClick={() => {
											setSelfie(false);
											setRemovedBgImage(null);
										}}
									>
										Retake
									</LoadingButton>
								</div>
							</div>
						) : null}
					</>
				)}

				{isAboveSizeLimit ? (
					<div style={{ color: "red" }}>Error: Upload limit is 10MB</div>
				) : (
					<></>
				)}
				{isAboveLengthLimit ? (
					<div style={{ color: "red" }}>
						Error: You can upload maximum of 5 images at a time
					</div>
				) : (
					<></>
				)}
				<div style={{ marginTop: "30px", color: "grey" }}>
					{props.message
						? props.message
						: "Upload more images to add to this product line"}
				</div>
			</Modal>
		</ThemeProvider>
	);
};

export default ImageUploadModal;
