import React from 'react'
import './PaymentPage.css'

export default function PaymentPage() {
  return (
    <>
<div class="columns">
  <ul class="price">
    <li class="header">Monthly</li>
    <li class="grey"> 99 / month</li>
    <li>10GB Storage</li>
    <li>10 Emails</li>
    <li>10 Domains</li>
    <li>1GB Bandwidth</li>
    <li class="grey"><a href="#" class="button">Buy</a></li>
  </ul>
</div>
<div class="columns">
  <ul class="price">
    <li class="header">Yearly</li>
    <li class="grey"> 1000 / year</li>
    <li>10GB Storage</li>
    <li>10 Emails</li>
    <li>10 Domains</li>
    <li>1GB Bandwidth</li>
    <li class="grey"><a href="#" class="button">Buy</a></li>
  </ul>
</div>
</>
  )
}
