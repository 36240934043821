import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar"
import ProductJourney from "../Campaign/ProductJourney";
const Wrapper = styled.div`
  display: flex;
  background: rgb(238,245,255);
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

export default function Test3(){
    return(
        <Wrapper id="outer-container-1">
            <Sidebar/>
            <MainDashboard>
                <Topbar/>
                <ProductJourney pathname={"/create-now/generate_product_creative"} />
            </MainDashboard>
        </Wrapper>
    )
}