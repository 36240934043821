import React, { useEffect, useState } from 'react';
import Logo from '../../Components/Images/myEra_NewLogo.png';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../customAPIs/useWindowDimensions';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import './blog.css'
import { useParams } from "react-router-dom";

const EraLogo = styled.img`
  margin-top: 1vh;
  height: 5vh;
`;

const Text = styled.div`
  color: white;
  font-weight: 549;
  
  margin-bottom: auto;
  margin-left: 1vh;
  font-size: 3vh;
`;

const CreateDiv = styled.div`
  background-color: white;
  margin: 3.5vh auto;
  height: 60%;
  
`;

export default function Blog({}) {
    const { width } = useWindowDimensions(); 
    console.log('Width(BlogPage) = ',width);
    const { blogid } = useParams();
    // console.log('Blog param: ', {blogid});
    const[currentBlog, setCurrentBlog] = useState('')
    //setCurrentBlog(BlogsMenu[parseInt(blogid)]);
    //console.log('Data: ', BlogsMenu[parseInt(blogid -1)]);
    
    let main_body_style = 
    { textAlign: 'center',
      width: '80%',
      borderRadius: '15px',
      border: '1px solid #383535',
      boxSizing: 'border-box',
      boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.25)'};

    if(width<='800'&&width>='600'){
      main_body_style = {...main_body_style , width: '90%'}
    }
    else if(width<='600'){
      main_body_style = {...main_body_style , width: '100%'}
    }
    useEffect(()=>{

        fetch(`${process.env.REACT_APP_BACKEND}/blog/${blogid}`)
        .then(response => response.json())
        .then(data => {
            // console.log(data[0])
            setCurrentBlog(data[0])
            document.title=data[0].Title
            document.querySelector('meta[name="description"]').setAttribute("content", data[0].Description);
            document.getElementsByTagName("meta")["keywords"].content = data[0].Tags.toString().replace(/,/g," ")
        })

    },[]);

return (
    <>
        <div style={width>='700'?{marginLeft: '2vh', marginRight: '2vh'}:{marginLeft: '2vh', marginRight: '0'}}>
            <div style={{display: 'flex', alignItems: 'center', height: '10vh', width: '97.2%', position: 'fixed', top: '0', backgroundColor: 'rgb(25,25,26)'}}>
                <div style={{display: 'flex', width: '60%'}}>
                    <Link className="navbarLinks" to="/"><EraLogo src={Logo} alt="Logo" style={{marginBottom: '1vh'}} /></Link>
                </div>
                <div style={{display: 'flex', width: '40%', alignItems: 'center', justifyContent: 'space-evenly'}}>
                    <Link className="navbarLinks" smooth to="/blog" style={{textDecoration:'none',color:'white',opacity:'0.5'}}>Blogs</Link>
                </div>        
            </div>
            <div style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontWeight: 'bold', fontSize: 'clamp(24px,4vw,45px)', lineHeight: '52px', textAlign: 'center', letterSpacing: '0.01em', color: '#EFEFEF', marginLeft: '1vh', marginRight: '1vh', marginTop: '12vh'}}>
                {currentBlog.Heading}
            </div>
            <div style={{fontFamily: 'sans-serif', fontStyle: 'normal', fontWeight: 'normal', fontSize: '18px', lineHeight: '20px', textAlign: 'center', letterSpacing: '0.01em', color: 'rgba(255, 255, 255, 0.4)', marginTop: '1vh', marginLeft: '1vh', marginRight: '1vh'}}>
                Published Date - {currentBlog.Dateofpublish} 
            </div>
            <div style={{margin: '1vh'}}>
                <CreateDiv style={main_body_style}>
                    {/* <img src={BlogsMenu[parseInt(blogid -1)].Image1} style={{borderRadius: '15px', marginTop: '5vh' , width: '80%', height: '20%'}}/> */}
                    <div style={{fontFamily: 'montserrat', fontWeight: 'normal', fontSize: '20px', marginTop: '5vh', marginBottom: '5vh', padding: '3%', textAlign: 'left'}}>
                    <div dangerouslySetInnerHTML={{ __html: currentBlog.Texting_area }} />
                    <span style={{marginLeft:'44%', fontFamily: 'inherit', fontSize: '12px'}}>Follow us on Social Media</span>
                    <div style={{display: 'block', marginLeft: '44%'}}>
                        <a href="https://www.facebook.com/myera.pro/" target='blank'><img src="https://img.icons8.com/fluency/48/000000/facebook-new.png" style={{width: '25px', height: '25px', margin: '1%'}}></img></a>
                        <a href="https://www.instagram.com/myera.pro/" target='blank'><img src="https://img.icons8.com/fluency/48/000000/instagram-new.png" style={{width: '25px', height: '25px', margin: '1%'}}></img></a>
                        <a href="https://in.linkedin.com/company/myerapro" target='blank'><img src="https://img.icons8.com/fluency/48/000000/linkedin.png" style={{width: '25px', height: '25px', margin: '1%'}}/></a>
                        <a href="https://twitter.com/myerapro" target='blank'><img src="https://img.icons8.com/fluency/48/000000/twitter.png" style={{width: '25px', height: '25px', margin: '1%'}}/></a>
                    </div>
                    </div>
                </CreateDiv>
            </div>
        </div>
        <p className="footerparaline1"> 
          {" "} 
          @2021 <strong>MyEra</strong> is recognized as a 'Startup' by the 
          Department for Promotion of Industry and Internal Trade (DPIIT), under 
          the Startup India Scheme. All Rights Reserved. 
        </p> 
        <p className="footerparaline2"> 
          Address: Jaipur Office (Reg): MyEra Technologies Pvt. Ltd., Major SS 
          Colony, Shastri Nagar, Jaipur (RAJ) 
        </p> 
        <div className="footerparaline3"> 
          <p className="footerparaline4"> 
            Website owned and managed by MyEra Technologies Private Limited, 
            India. 
          </p> 
          
        </div> 

        <div className="terms_and_conditions">
        <p className="footerparaline5"> 
            <Link to="/terms-of-use" style={{ color: "#f0f9ff" }}> 
              {" "} 
              Terms Of Use{" "} 
            </Link> 
          </p> 
          <p className="footerparaline4"> 
            <Link to="/privacy-policy" style={{ color: "#f0f9ff" }}> 
              {" "} 
              Privacy Policy{" "} 
            </Link> 
          </p> 
        </div>
    </>

);
}
