import {useState} from 'react'
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar"
import SocialAccounts from './SocialAccounts'
// import {useEffect} from "react";
import {Helmet} from "react-helmet"

const Wrapper = styled.div`
  display: flex;
  background: rgb(238,245,255);
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

export default function SocialAccountsMain(){

  const [showLinkedinLogin, setShowLinkedinLogin] = useState(false);
  const [showTwitterLogin, setShowTwitterLogin] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

    return(
        <Wrapper id="outer-container">
          <Helmet>
            <title>MyEra | Social Accounts Page</title>
          </Helmet>
            <Sidebar/>
            <MainDashboard>
            <Topbar/>

            <SocialAccounts
            showLinkedinLogin={showLinkedinLogin}
            setShowLinkedinLogin = {setShowLinkedinLogin}
            showTwitterLogin = {showTwitterLogin}
            setShowTwitterLogin = {setShowTwitterLogin}
            >
            </SocialAccounts>
            </MainDashboard>
        </Wrapper>
    )
}