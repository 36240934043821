import React, {useEffect, useState} from "react";
import styles from "./Sidebar.module.css";

import {
	Menu,
	MenuItem,
	ProSidebar,
	SidebarHeader,
	SubMenu,
  } from "react-pro-sidebar";
  import { AiOutlineMenu } from "react-icons/ai";

const Sidebar = (props) => {

	const [selected, setSelected] = useState("choose_theme");
	const [collapsed, setCollapsed] = useState(true);

	// useEffect( () => {
	// 	console.log("IN USEFFECT")
	// 	props.setTab(selected)
	// }, [selected]);

	const onClickMenuIcon = () => {
		setCollapsed(!collapsed);
	  };

	return (
		<ProSidebar>
			<Menu title="Manage Products">
				<SubMenu title="Manage Products" style={{
                  marginTop: "3%",
                  color: "#84E8F4 ",
                  backgroundColor: "#292E3B",
                  width: "85%",
                  borderRadius: "0px 100px 100px 0px",
                  fontSize: "18px",
                }}>
					<MenuItem>My products</MenuItem>
					<MenuItem>Images upload</MenuItem>
					<MenuItem>(P)review</MenuItem>
				</SubMenu>
			</Menu>

			<Menu title="Create Product">
				<SubMenu title="Create Product" style={{ marginTop: "3%", fontSize: "18px" }}>
					<MenuItem>Choose theme</MenuItem>
					<MenuItem>Choose name & template</MenuItem>
				</SubMenu>
			</Menu>
		</ProSidebar>
	);
};

export default Sidebar;
