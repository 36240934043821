import React from "react";
import { Layer } from "react-konva";
import URLImageElement from "../../Common/Stage/Elements/URLImageElement";

const SingleImageLayer = ({
	element,
	selectedImage,
	setElement,
	scale = 1,
	exportLayer = true,
	allowDefaultImagesToMove = false,
}) => {
	const image = selectedImage ?? element.default_image.asset;
	return (
		<Layer className={ !exportLayer ? "myera-layer-ignore" : "" }>
			<URLImageElement
				x={ element.x }
				y={ element.y }
				width={ element.width * scale }
				height={ element.height * scale }
				rotation={ element.rotation }
				id={ element.id }
				image={ image }
				onSelect={ () => { } }
				onChange={ (new_ele) => {
					setElement({
						...element,
						...new_ele,
					});
				} }
				isSelected={ false }
				onTransformEnd={ () => { } }
				draggable={ element.is_movable && (allowDefaultImagesToMove || !image.startsWith("http")) }
				listening={ element.is_movable }
				updateElementRefs={ (ref) => { } }
			></URLImageElement>
		</Layer>
	);
};

export default SingleImageLayer;
