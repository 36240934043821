import React, {
	useState,
	forwardRef,
	useReducer,
	useImperativeHandle,
} from "react";
import { Stage, Layer, Image as KonvaImage, Transformer } from "react-konva";
import useImage from "use-image";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";

const URLImage = forwardRef((props, ref) => {
	const image = props.image;
	const isSelected = props.isSelected;
	const onSelect = props.onSelect;
	const onChange = props.onChange;
	const listening = props.listening;
	const updateElementRefs = props.updateElementRefs;
	var lastCenter = null;
	var lastDist = 0;

	const componentRef = React.useRef();
	const imageRef = React.useRef();
	const trRef = React.useRef();
	const [img, setImg] = useState();
	const [x, setX] = useState(image.x);
	const [y, setY] = useState(image.y);
	const [width, setWidth] = useState(image.width);
	const [height, setHeight] = useState(image.height);
	const [transformer, setTransformer] = useState(false);
	const [editable, setEditable] = useState(listening);
	const [rotation, setRotation] = useState(image.rotation);

	React.useEffect(() => {
		let img_var = new window.Image();
		if (typeof image.image === "string") img_var.src = image.image;
		else if (typeof image.image != "undefined")
			img_var.src = image.image.currentSrc;
		img_var.crossOrigin = "Anonymous";
		// console.log("IMAG_VAR IS: ", img_var);
		setImg(img_var);
	}, [image]);

	React.useEffect(() => {
		if (transformer) {
			// we need to attach transformer manually
			trRef.current.nodes([imageRef.current]);
			trRef.current.getLayer().batchDraw();
		}
	}, [transformer]);

	React.useEffect(() => {
		updateElementRefs(componentRef);
	}, []);

	function getImageCenter(_width, _height) {
		return {
			x: x + _width / 2,
			y: y + _height / 2,
		};
	}

	function setCenter(center, _width, _height) {
		setX(center.x - _width / 2);
		setY(center.y - _height / 2);
		setWidth(_width);
		setHeight(_height);
	}

	useImperativeHandle(componentRef, () => ({
		updateImageURL(url) {
			let image = img;
			image.src = url;
			setImg(image);
		},

		getImageSrc() {
			return imageRef.current.attrs.image.src;
		},

		updateImageDimensions(_width, _height) {
			let center = getImageCenter(width, height);
			setCenter(center, _width, _height);
		},

		setListening(listening) {
			setEditable(listening);
		},

		resetImagePostition(pos) {
			setX(pos.x);
			setY(pos.y);
			setWidth(pos.width);
			setHeight(pos.height);
		},

		setHeightAndWidthForUploads() {
			setX(250 - 200 / 2);
			setY(250 - 200 / 2);
			setWidth(200);
			setHeight(200);
		},
	}));

	function setTransformerState() {
		console.log(transformer);
		if (transformer) {
			setTransformer(false);
		} else {
			setTransformer(true);
		}
	}

	function getDistance(p1, p2) {
		return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
	}

	function getCenter(p1, p2) {
		return {
			x: (p1.x + p2.x) / 2,
			y: (p1.y + p2.y) / 2,
		};
	}

	function handleTouch(e) {
		e.evt.preventDefault();
		var touch1 = e.evt.touches[0];
		var touch2 = e.evt.touches[1];
		const stage = imageRef.current;
		if (stage !== null) {
			if (touch1 && touch2) {
				if (stage.isDragging()) {
					stage.stopDrag();
				}

				var p1 = {
					x: touch1.clientX,
					y: touch1.clientY,
				};
				var p2 = {
					x: touch2.clientX,
					y: touch2.clientY,
				};

				if (!lastCenter) {
					lastCenter = getCenter(p1, p2);
					return;
				}
				var newCenter = getCenter(p1, p2);

				var dist = getDistance(p1, p2);

				if (!lastDist) {
					lastDist = dist;
				}

				// local coordinates of center point
				var pointTo = {
					x: (newCenter.x - stage.x()) / stage.scaleX(),
					y: (newCenter.y - stage.y()) / stage.scaleX(),
				};

				var scale = stage.scaleX() * (dist / lastDist);

				stage.scaleX(scale);
				stage.scaleY(scale);

				// calculate new position of the stage
				var dx = newCenter.x - lastCenter.x;
				var dy = newCenter.y - lastCenter.y;

				var newPos = {
					x: newCenter.x - pointTo.x * scale + dx,
					y: newCenter.y - pointTo.y * scale + dy,
				};

				stage.position(newPos);
				stage.draw();

				lastDist = dist;
				lastCenter = newCenter;
			}
		}
	}

	function handleTouchEnd() {
		lastCenter = null;
		lastDist = 0;
	}

	return (
		<React.Fragment>
			<KonvaImage
				crossorigin="anonymous"
				onClick={() => setTransformerState()}
				onTap={() => setTransformerState()}
				ref={imageRef}
				image={img}
				x={x}
				y={y}
				width={width}
				height={height}
				rotation={rotation}
				id={image.id}
				draggable
				listening={editable}
				// hitGraphEnabled={true}
				onDragEnd={(e) => {
					setX(e.target.x());
					setY(e.target.y());
					// onChange({
					//   image: img,
					//  width:image.width,
					//   height:image.height,
					//   id:image.id,
					//   x: e.target.x(),
					//   y: e.target.y(),
					// });
				}}
				onTransformEnd={(e) => {
					// transformer is changing scale of the node
					// and NOT its width or height
					// but in the store we have only width and height
					// to match the data better we will reset scale on transform end
					const node = imageRef.current;
					const scaleX = node.scaleX();
					const scaleY = node.scaleY();

					// we will reset it back
					node.scaleX(1);
					node.scaleY(1);
					console.log("old x and y: " + image.x + " " + image.y);
					console.log("new x and y: " + node.x() + " " + e.target.y());
					console.log(
						"old width and height: " + image.width + " " + image.height
					);

					setX(node.x());
					setY(node.y());
					setWidth(Math.max(5, node.width() * scaleX));
					setHeight(Math.max(node.height() * scaleY));
					// onChange({
					//   image: img,
					//   id:image.id,
					//   x: node.x(),
					//   y: node.y(),
					//   // set minimal value
					//   width: Math.max(5, node.width() * scaleX),
					//   height: Math.max(node.height() * scaleY),
					// });
				}}
				onTouchMove={handleTouch}
				onTouchEnd={handleTouchEnd}
			/>
			{transformer && (
				<>
					<Transformer
						ref={trRef}
						boundBoxFunc={(oldBox, newBox) => {
							// limit resize
							if (newBox.width < 5 || newBox.height < 5) {
								return oldBox;
							}
							return newBox;
						}}
					/>
				</>
			)}
		</React.Fragment>
	);
});

export default URLImage;
