import React, {
	useRef,
	useState,
	forwardRef,
	useImperativeHandle,
} from "react";
import styles from "./HorizontalCarousel.module.css";
import { ChevronLeftRounded, ChevronRightRounded } from "@material-ui/icons";

const HorizontalCarousel = forwardRef(({ children, ...props }) => {
	const carouselRef = useRef(null);

	const [currIndex, setStateCurrIndex] = useState(0);

	const carouselLength = children[1].length + 1;

	React.useEffect(() => {
		props.updateCarouselRef(carouselRef);

		const r = document.querySelector(":root");
		r.style.setProperty(
			"--carousel-flex-direction",
			props.invertCanvasView ? "row" : "row-reverse"
		);
		r.style.setProperty(
			"--carousel-margin-right",
			props.invertCanvasView ? "40px" : "0px"
		);
		r.style.setProperty(
			"--carousel-margin-left",
			props.invertCanvasView ? "0px" : "40px"
		);
	}, [props.invertCanvasView, props.updateCarouselRef]);

	const setCurrIndex = (index) => {
		setStateCurrIndex(index);

		if (props.showVerticalCarousel) {
			document.getElementById("vertical_preview_" + index).scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "center",
			});
		}
	};

	useImperativeHandle(carouselRef, () => ({
		resetIndex() {
			setCurrIndex(0);
		},
	}));

	const scrollLeft = () => {
		if (currIndex > 0) {
			setCurrIndex(currIndex - 1);
		}
	};

	const scrollRight = () => {
		if (currIndex < carouselLength - 1) {
			setCurrIndex(currIndex + 1);
		}
	};

	return (
		<div className={styles.carousel_container}>
			{props.showVerticalCarousel && (
				<div className={styles.vertical_preview}>
					<div
						style={{
							transform: "scale(0.16)",
							transformOrigin: window.matchMedia("(min-width: 768px)").matches
								? "top"
								: "left",
							marginTop: "0",
							marginBottom: window.matchMedia("(max-width: 768px)").matches
								? "0"
								: "-420px",
							marginLeft: "0",
							marginRight: window.matchMedia("(max-width: 768px)").matches
								? "-420px"
								: "0",
							border: currIndex === 0 ? "2px solid #020441" : "",
							width: "504px",
							height: "504px",
						}}
						id="vertical_preview_0"
						onClick={() => {
							setCurrIndex(0);
						}}
					>
						{children[0]}
					</div>
					{children[1].map((child, index) => {
						return (
							<div
								key={index}
								id={"vertical_preview_" + (index + 1)}
								className={
									styles.preview_item +
									" " +
									(currIndex === index + 1 ? styles.selected : "")
								}
								onClick={() => {
									setCurrIndex(index + 1);
								}}
							>
								{child}
							</div>
						);
					})}
				</div>
			)}

			<div className={styles.horizontal_carousel}>
				<div
					ref={carouselRef}
					className={styles.carousel_content}
					style={{
						transform: `translateX(-${currIndex * 100}%)`,
					}}
				>
					{children}
				</div>
				<button className={styles.carousel_nav_left} onClick={scrollLeft}>
					<ChevronLeftRounded />
				</button>
				<button className={styles.carousel_nav_right} onClick={scrollRight}>
					<ChevronRightRounded />
				</button>
			</div>
		</div>
	);
});

export default HorizontalCarousel;
