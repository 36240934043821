import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar"
import Calander from "../SocialPostsCalander/Calander";
import { useEffect } from "react";

const Wrapper = styled.div`
  display: flex;
  background: rgb(13,16, 22);
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

export default function Scheduler() {

    useEffect(() => {
        document.title = "MyEra | Publish";
    }, []);

    return(
        <Wrapper id="outer-container">
            <Sidebar/>
            <MainDashboard>
                <Topbar/>
            <div style={{padding: '2%'}}>
                <Calander/>
            </div>
            
            </MainDashboard>
        </Wrapper>
    )
}