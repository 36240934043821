import React, { Component, useEffect, useState } from "react";
import "../Campaign/templateScroll.css";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import Axios from "axios";
import { IsoRounded } from "@material-ui/icons";
import crossIcon from "../Images/close.png";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import TagsInput from "../TagsInput/TagsInput";
import Tick from "../Images/Tick.png";
import Button from "@material-ui/core/Button";
import InputColor from "react-input-color";
import { createColor } from "material-ui-color";
import { Prompt } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import "./profile.css";
import { CircularProgress } from "@material-ui/core";
// import { useLinkedIn } from "react-linkedin-login-oauth2";
// import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import linkedinIcon from "./icons8-linkedin.png";
// import TwitterLogin from "react-twitter-login";
import axios from "axios";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import Modal from "react-modal";
import ImageCropper from "../ImageCropper/ImageCropper";
import { Cancel } from "@material-ui/icons";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getCroppedImageService } from "../Generic/messageService";
import CloseIcon from "@mui/icons-material/Close";

// const theme = createTheme({
//   palette: {
//       mode: 'dark',
//       primary: {
//       main: "rgb(132, 232, 244)",
//       }
//   },
// });

const customStyles = {
	option: (provided, state) => ({
		...provided,
		borderBottom: "1px solid rgba(33, 42, 64, 1)",
		backgroundColor: "#121828",
		color: state.isSelected
			? "rgba(255, 255, 255, 1)"
			: "rgba(255, 255, 255, 0.3)",
		padding: 20,
	}),
	control: (_, { selectProps: { width } }) => ({
		display: "flex",
		padding: "0.5vh",
		color: "rgba(0, 0, 0, 1)",
		borderRadius: "1vh",
		backgroundColor: "#ffffff",
		height: "5.5vh",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";

		return {
			...provided,
			opacity,
			transition,
			color: "rgba(0, 0, 0, 0.8)",
			fontFamily: "Montserrat",
		};
	},
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: "rgba(36, 171, 226, 0.24)",
			color: "#24abe2",
		};
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: "#24abe2",
	}),
};

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: "67.5%",
		width: "40%",
		color: "#FFFFFF",
		padding: "0%",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};
export default function Profiles(props) {
	// destructuring the props
	const {
		showLinkedinLogin,
		setShowLinkedinLogin,
		showTwitterLogin,
		setShowTwitterLogin,
	} = props;

	const [brand_name, setbrand_name] = useState("");
	const [userlogos, setuserLogos] = useState([]);
	const [brand_colors, setbrand_colors] = useState([]);
	const [selectedIndustry, setSelectedIndustry] = useState("");
	//const [industry, setIndustry] = useState("");
	const [industryOptions, setindustryOptions] = useState();
	//const [brand_font, setbrand_font] = useState("");
	const [fontOptions, setFontOptions] = useState([]);
	const [selectedFont, setSelectedFont] = useState("");
	const [userId, setuserId] = useState("");
	const [objective, setobjectives] = useState([]);
	const [company_type, setcompany_type] = useState("Product");
	//const [tagsArray, setTagsArray] = useState([]);
	const [product, setProduct] = useState(1);
	const [color, setColor] = useState(createColor("red"));
	const [fetchingUserDetails, setFetchingUserDetails] = useState(true);
	const [showLogin, setShowLogin] = useState(false);

	// Lifting up the state
	// const [showLinkedinLogin, setShowLinkedinLogin] = useState(false);
	// const [showTwitterLogin, setShowTwitterLogin] = useState(false);
	// Lifting up the state

	const [code, setCode] = React.useState("");
	const [errorMessage, setErrorMessage] = React.useState("");
	const [linkedinOrganizations, setLinkedinOrganizations] = useState([]);
	const [showLinkedInOrgModal, setShowLinkedinOrgModal] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const [unsavedChanges, setunsavedChanges] = useState(false);

	// const handleSelectedTags =(tags)=>{
	//   setobjectives(tags);
	// }

	const onchangeIndustry = (item) => {
		setSelectedIndustry(item);
		setunsavedChanges(true);
		console.log("SELECTED INDUSTRY: ", selectedIndustry);
	};
	const onchangeFont = (item) => {
		// console.log('ON CHANGE FONT: ', item)
		setSelectedFont(item);
		setunsavedChanges(true);
	};

	useEffect(() => {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		if (window.location.href.includes("twitter/callback/")) {
			let callback_url = window.location.href;
			var url = new URL(callback_url);
			let oauth_token = url.searchParams.get("oauth_token");
			let oauth_verifier = url.searchParams.get("oauth_verifier");

			fetch(
				`${process.env.REACT_APP_BACKEND}/get_twitter_access_token?` +
					new URLSearchParams({
						oauth_token: oauth_token,
						oauth_verifier: oauth_verifier,
					}),
				{ headers }
			)
				.then((request) => request.json())
				.then((data) => {
					console.log(data);
					// setRedirect(true);
					var userData = JSON.parse(localStorage.getItem("Erajwt"));
					userData.is_twitter_linked = true;
					userData.twitter_name = data.screen_name;
					localStorage.setItem("Erajwt", JSON.stringify(userData));
					window.location.reload();
				});
		}

		const userData = JSON.parse(localStorage.getItem("Erajwt"));
		const facebookLinked = userData["is_facebook_linked"];
		const linkedinLinked = userData["is_linkedin_linked"];
		const twitterLinked = userData["is_twitter_linked"];

		console.log("Inside profile.js, facebookLinked = ", facebookLinked);

		if (!facebookLinked) {
			console.log("!facebookLinked ran!");
			setShowLogin(false);
		} else setShowLogin(true);

		if (linkedinLinked) {
			setShowLinkedinLogin(true);
		} else setShowLinkedinLogin(false);

		if (twitterLinked) setShowTwitterLogin(true);
		else setShowTwitterLogin(false);

		async function getUserData() {
			fetch(`${process.env.REACT_APP_BACKEND}/get_user/`, { headers })
				.then((response) => response.json())
				.then((data) => {
					console.log("API CALL!");
					console.log(data);
					setbrand_name(data.brand_name);
					setuserId(data.user.email);
					setbrand_colors(data.brand_colors.split(","));
					setuserLogos(data.user_logos);
					setSelectedIndustry(data.industry);
					setSelectedFont(data.brand_font);
					setcompany_type(data.company_type);
					if (data.company_type === "Product") setProduct(1);
					else setProduct(0);
					setobjectives(data.objectives.split(","));
					setFetchingUserDetails(false);
				});
		}
		getUserData();

		async function getIndustries() {
			const response_industries = await Axios.get(
				`${process.env.REACT_APP_BACKEND}/industries/`
			);
			setindustryOptions(response_industries.data);
			const response_fonts = await Axios.get(
				`${process.env.REACT_APP_BACKEND}/get_fonts/`
			);
			setFontOptions(response_fonts.data);
		}
		getIndustries();

		getCroppedImageService.getMessage().subscribe((message) => {
			var byteString = atob(message.imageURL.split(",")[1]);
			var mimeString = message.imageURL
				.split(",")[0]
				.split(":")[1]
				.split(";")[0];
			var ab = new ArrayBuffer(byteString.length);
			var ia = new Uint8Array(ab);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			var blob = new Blob([ab], { type: mimeString });
			var file = new File([blob], "image.png");
			console.log("hello", file);
			setCroppedImage(file);
			console.log("cropped image", croppedImage);
		});
	}, []);

	const resetUserProfile = async () => {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/get_user/`, { headers })
			.then((response) => response.json())
			.then((data) => {
				setbrand_name(data.brand_name);
				setuserId(data.user.email);
				setbrand_colors(data.brand_colors.split(","));
				setSelectedIndustry(data.industry);
				setSelectedFont(data.brand_font);
				setcompany_type(data.company_type);
				if (data.company_type === "Product") setProduct(1);
				else setProduct(0);
				setobjectives(data.objectives.split(","));
				toast.success("User Profile reset successfully!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	};

	const updateUserProfile = async () => {
		const { accessToken } = isAuthenticated();
		var industry_id = 7;
		if (selectedIndustry != null) industry_id = selectedIndustry.id;

		// var form_data = new FormData();
		// form_data.append('image_file', logoFile)
		// form_data.append('objectives', objective.toString())
		// form_data.append('brand_name', brand_name)
		// form_data.append('company_type', company_type)
		// form_data.append('industry_id', industry_id)

		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND}/update_user/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			data: {
				objectives: objective.toString(),
				brand_name: brand_name,
				company_type: company_type,
				industry_id: industry_id,
			},
		})
			.then((res) => {
				console.log(res);
			})
			.catch((err) => {
				toast.error("Error: Couldn't update user profile!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
		const font_ids = selectedFont.map((fontObj, index) => fontObj.id);

		var form_data2 = new FormData();
		form_data2.append("brand_colors", brand_colors.toString());
		form_data2.append("font_ids", font_ids.toString());

		await Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND}/update_branding/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			data: form_data2,
		}).then((res) => {
			console.log(res);
			toast.success("User Profile Updated successfully!", {
				position: "top-center",
				theme: "light",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		});

		//Commented because for update_branding the response is Account is not verified yet, even if the values are updated in admin dashboard
		// .catch((err)=>{
		//   if(err.error==="Account not approved yet"){
		//     toast.success("User Profile Updated successfully!", {
		//       position: "top-center",
		//       theme: "light",
		//       autoClose: 2500,
		//       hideProgressBar: true,
		//       closeOnClick: true,
		//       pauseOnHover: true,
		//       draggable: true,
		//       progress: undefined,
		//     });
		//   }
		//   else{
		//   toast.error("Error: Couldn't update user profile!", {
		//     position: "top-center",
		//     theme: "light",
		//     autoClose: 2500,
		//     hideProgressBar: true,
		//     closeOnClick: true,
		//     pauseOnHover: true,
		//     draggable: true,
		//     progress: undefined,
		//   });
		//   }
		// });
	};

	// const responseFacebook = async (response) => {

	//   console.log('Linking...')
	//   console.log(response.accessToken)
	//   const {accessToken} = isAuthenticated();
	//     var requestOptions = {
	//       method: 'POST',
	//             headers: {
	//                 'Content-Type': 'application/json',
	//                 Authorization: `Bearer ${accessToken}`,
	//             },
	//             body: JSON.stringify({
	//                 'access_token': response.accessToken
	//             })
	//     }

	//     fetch(`${process.env.REACT_APP_BACKEND}/link_facebook/`, requestOptions)
	//     .then(response => response.json())
	//       .then(data => {
	//           console.log(data)
	//           var userData = JSON.parse(localStorage.getItem("Erajwt"))
	//           userData.is_facebook_linked = true
	//           localStorage.setItem('Erajwt', JSON.stringify(userData))
	//           console.log(JSON.parse(localStorage.getItem("Erajwt")))
	//           window.location.reload();
	//       })
	//   console.log('linked')

	//   // var userData = JSON.parse(localStorage.getItem("Erajwt"))
	//   // userData.is_facebook_linked = true
	//   // localStorage.setItem('Erajwt', JSON.stringify(userData))

	//   // localStorage.setItem(
	//   //   "facebook_creds",
	//   //   JSON.stringify({
	//   //     accessToken: response.accessToken,
	//   //     userID: response.userID,
	//   //     name: response.name,
	//   //   })
	//   // );
	//   // const accounts_response = await Axios.get(
	//   //   `https://graph.facebook.com/v11.0/me?fields=id,name,accounts&access_token=${response.accessToken}`
	//   // );

	//   // localStorage.setItem(
	//   //   "facebook_pages",
	//   //   JSON.stringify({ pages: accounts_response.data.accounts.data })
	//   // );
	//   // const pagesArray = accounts_response.data.accounts.data;
	//   // const instagram_business_accounts = [];
	//   // for (let i = 0; i < pagesArray.length; i++) {
	//   //   const instagram_business_account = await Axios.get(
	//   //     `https://graph.facebook.com/v11.0/${pagesArray[i].id}?fields=instagram_business_account&access_token=${pagesArray[i].access_token}`
	//   //   );

	//   //   if (instagram_business_account.data.instagram_business_account) {
	//   //     instagram_business_accounts.push({
	//   //       id: instagram_business_account.data.instagram_business_account.id,
	//   //       access_token: pagesArray[i].access_token,
	//   //       facebook_page_id: pagesArray[i].id,
	//   //       facebook_page: pagesArray[i].name,
	//   //     });
	//   //   }
	//   // }
	//   // localStorage.setItem(
	//   //   "insta_accounts",
	//   //   JSON.stringify({ accounts: instagram_business_accounts })
	//   // );
	//   // localStorage.setItem(
	//   //   "ig-user-id",
	//   //   JSON.stringify({ id: instagram_business_accounts[0].id })
	//   // );
	//   // window.location.reload();
	// };

	// async function twitterLogin(){
	//   const { accessToken } = isAuthenticated();
	//   const headers = {
	//     Authorization: `Bearer ${accessToken}`
	//   }

	//   fetch(`${process.env.REACT_APP_BACKEND}/request_twitter_token`, {headers})
	//   .then(request => request.json())
	//   .then(data => {
	//     console.log(data)
	//     window.location.href = data.authorization_url
	//     var userData = JSON.parse(localStorage.getItem("Erajwt"))
	//     userData.is_twitter_linked = true
	//     localStorage.setItem('Erajwt', JSON.stringify(userData))
	//     // window.location.reload();
	//   })

	//   console.log(window.location.href)

	// }

	const authHandler = (err, data) => {
		console.log(err, data);
	};

	// const { linkedInLogin } = useLinkedIn({
	//   clientId: "77l5e7nu3i10q5",
	//   redirectUri: `${window.location.origin}/linkedin/callback/`,
	//   onSuccess: (code) => {
	//     console.log(code);
	//     setCode(code);
	//     // alert(`Hurray!! LinkedIn is connected. \nCode: ${code}`)

	//     const { accessToken } = isAuthenticated();
	//     const requestOptions = {
	//         headers: {
	//         Authorization: `Bearer ${accessToken}`
	//         }
	//     }

	//     fetch(`${process.env.REACT_APP_BACKEND}/link_linkedin/${code}/`, requestOptions)
	//     .then(response => response.json())
	//     .then(data => {
	//         console.log(data)
	//         var userData = JSON.parse(localStorage.getItem("Erajwt"))
	//         userData.is_linkedin_linked = true
	//         localStorage.setItem('Erajwt', JSON.stringify(userData))
	//         // window.location.reload();
	//         alert("Hello")
	//         setTimeout(function() { get_linkedin_organizations(); }, 10000);
	//     })

	//     fetch(`${process.env.REACT_APP_BACKEND}/get_linkedin_organizations/`, requestOptions)
	//     .then(response => response.json())
	//     .then(data => {
	//       console.log(data)
	//       var organizations = []
	//       for(var org of data.organizations)
	//       {
	//         organizations.push({'organization_name' : org.localizedName, 'organization_urn': org.$URN})
	//       }
	//       localStorage.setItem('linkedinOrganizations', JSON.stringify(organizations))
	//     })

	//   },
	//   scope: "r_organization_social rw_organization_admin w_organization_social r_emailaddress r_liteprofile w_member_social",
	//   onError: (error) => {
	//     console.log(error);
	//     setCode("");
	//     setErrorMessage(error.errorMessage);
	//   },
	// });

	// function get_linkedin_organizations() {
	//   alert("IN FUNCTION")
	//   const { accessToken } = isAuthenticated();
	//   const requestOptions = {
	//       headers: {
	//       Authorization: `Bearer ${accessToken}`
	//       }
	//   }

	//   fetch(`${process.env.REACT_APP_BACKEND}/get_linkedin_organizations/`, requestOptions)
	//   .then(response => response.json())
	//   .then(data => {
	//     console.log(data)
	//     var organizations = []
	//     for(var org of data.organizations)
	//     {
	//       organizations.push({'organization_name' : org.localizedName, 'organization_urn': org.$URN})
	//     }
	//     setLinkedinOrganizations(organizations)
	//     setShowLinkedinOrgModal(true);
	//   })
	// }

	// function delinkFacebook(){

	//   const { accessToken } = isAuthenticated();
	//   const requestOptions = {
	//       headers: {
	//         Authorization: `Bearer ${accessToken}`
	//       }
	//   }

	//    fetch(`${process.env.REACT_APP_BACKEND}/delink_facebook/`, requestOptions)
	//   .then(response => response.json())
	//   .then(data => {
	//     toast.success('Removed Facebook Account Successfully', {
	//       position: "top-center",
	//       theme: 'dark',
	//       autoClose: 2500,
	//       hideProgressBar: true,
	//       closeOnClick: true,
	//       pauseOnHover: true,
	//       draggable: true,
	//       progress: undefined,
	//     });

	//     var userData = JSON.parse(localStorage.getItem("Erajwt"))
	//     delete userData['insta_username'];
	//     delete userData['fb_page_name'];
	//     userData.is_facebook_linked = false
	//     localStorage.setItem('Erajwt', JSON.stringify(userData))
	//     window.location.reload();

	//   });

	//   console.log(JSON.parse(localStorage.getItem("Erajwt")))
	// }

	// function delinkLinkedin(){
	//   const { accessToken } = isAuthenticated();
	//   const requestOptions = {
	//       headers: {
	//         Authorization: `Bearer ${accessToken}`
	//       }
	//   }

	//    fetch(`${process.env.REACT_APP_BACKEND}/delink_linkedin/`, requestOptions)
	//   .then(response => response.json())
	//   .then(data => {
	//       var userData = JSON.parse(localStorage.getItem("Erajwt"))
	//       // delete userData[''];
	//       userData.is_linkedin_linked = false
	//       localStorage.setItem('Erajwt', JSON.stringify(userData))
	//       window.location.reload();

	//       toast.success('Removed LinkedIn Account Successfully', {
	//           position: "top-center",
	//           theme: 'dark',
	//           autoClose: 2500,
	//           hideProgressBar: true,
	//           closeOnClick: true,
	//           pauseOnHover: true,
	//           draggable: true,
	//           progress: undefined,
	//         });
	//   })
	// }

	// function delinkTwitter(){
	//   const { accessToken } = isAuthenticated();
	//   const requestOptions = {
	//       headers: {
	//         Authorization: `Bearer ${accessToken}`
	//       }
	//   }

	//    fetch(`${process.env.REACT_APP_BACKEND}/delink_twitter/`, requestOptions)
	//   .then(response => response.json())
	//   .then(data => {
	//     toast.success('Removed Twitter Account Successfully', {
	//       position: "top-center",
	//       theme: 'dark',
	//       autoClose: 2500,
	//       hideProgressBar: true,
	//       closeOnClick: true,
	//       pauseOnHover: true,
	//       draggable: true,
	//       progress: undefined,
	//     });

	//       var userData = JSON.parse(localStorage.getItem("Erajwt"))
	//       delete userData['twitter_name'];
	//       userData.is_twitter_linked = false
	//       localStorage.setItem('Erajwt', JSON.stringify(userData))
	//       window.location.reload();

	//   })

	// }

	// function confirmDelinkTwitter(){

	//   swal("Are you sure you want to delink your account from MyEra? You won't be able to schedule or analyze posts using MyEra if you proceed.", {
	//     icon: "warning",
	//     buttons: {
	//       cancel_bt: {
	//         text: "Cancel",
	//         className: "cancel",
	//       },
	//       confirm: {
	//         text: "Yes, proceed",
	//         className: "delete-confirmation",
	//         value: "delete"
	//       },
	//     },

	//   })
	//   .then((value)=>{
	//     if(value==='delete')
	//       delinkTwitter();
	//   });

	//   // if(window.confirm("Are you sure whether you want to remove your account or not from MyEra. You won't be able to schedule posts from MyEra if you unlink.") === true)
	//   // {
	//   //   delinkTwitter();
	//   // }
	// }

	// function confirmDelinkFacebook(){
	//   swal("Are you sure you want to delink your account from MyEra? You won't be able to schedule or analyze posts using MyEra if you proceed.", {
	//     icon: "warning",
	//     buttons: {
	//       cancel_bt: {
	//         text: "Cancel",
	//         className: "cancel",
	//       },
	//       confirm: {
	//         text: "Yes, proceed",
	//         className: "delete-confirmation",
	//         value: "delete"
	//       },
	//     },

	//   })
	//   .then((value)=>{
	//     if(value==='delete')
	//     delinkFacebook();
	//   });
	//   // if(window.confirm("Are you sure whether you want to remove your account or not from MyEra. You won't be able to schedule posts from MyEra if you unlink.") === true)
	//   // {
	//   //   delinkFacebook();
	//   // }
	// }

	const confirmDeleteAccount = () => {
		if (
			window.confirm(
				"Are you sure you want to delete your MyEra account? You won't be able to access it again in the future."
			) === true
		) {
			deleteAccount();
		}
	};

	const deleteAccount = () => {
		const { accessToken } = isAuthenticated();
		Axios({
			method: "GET",
			url: `${process.env.REACT_APP_BACKEND}/delete_user/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}).then((response) => {
			window.location.href = `${window.location.origin}/signup`;
			console.log("account deleted");
		});

		toast.success("Account Deleted Successfully", {
			position: "top-center",
			theme: "light",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	// function confirmDelinkLinkedin(){
	//   swal("Are you sure you want to delink your account from MyEra? You won't be able to schedule or analyze posts using MyEra if you proceed.", {
	//     icon: "warning",
	//     buttons: {
	//       cancel_bt: {
	//         text: "Cancel",
	//         className: "cancel",
	//       },
	//       confirm: {
	//         text: "Yes, proceed",
	//         className: "delete-confirmation",
	//         value: "delete"
	//       },
	//     },

	//   })
	//   .then((value)=>{
	//     if(value==='delete')
	//     delinkLinkedin();
	//   });
	//   // if(window.confirm("Are you sure whether you want to remove your account or not from MyEra. You won't be able to schedule posts from MyEra if you unlink.") === true)
	//   // {
	//   //   delinkLinkedin();
	//   // }
	// }

	function openModal() {
		setModalIsOpen(true);
	}

	function closeModal() {
		setModalIsOpen(false);
	}

	function onFileChange(e) {
		var url = URL.createObjectURL(e.target.files[0]);
		setSelectedImage(url);
		setSelectedFile(e.target.files[0]);
		console.log(url);
	}

	function deleteLogoFile(logo) {
		const { accessToken } = isAuthenticated();
		setuserLogos((current) => current.filter((image) => image.id !== logo.id));
		Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND}/delete_user_logo/` + logo["id"],
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
			.then((res) => {
				console.log("Deleted: " + res);
				toast.success("Logo Deleted !!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((err) => {
				toast.error("Error: Couldn't delete logo!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	}

	function addLogoFile() {
		const { accessToken } = isAuthenticated();
		var form_data3 = new FormData();
		form_data3.append("logo", croppedImage);

		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND}/add_user_logo/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			data: form_data3,
		})
			.then((res) => {
				setuserLogos([...userlogos, res["data"]]);
				toast.success("Logo Added !!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((err) => {
				toast.error("Error: Couldn't add new logo!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});

		closeModal();
	}
	let Cropper = <></>;
	if (selectedImage) {
		console.log("Cropping");

		Cropper = (
			<div style={{ display: "flex" }}>
				<div style={{ marginLeft: "15%" }}>
					<ImageCropper src={selectedImage}></ImageCropper>
				</div>
				<Cancel
					onClick={() => setSelectedImage(null)}
					style={{ marginLeft: "10%" }}
				></Cancel>
			</div>
		);
	} else {
		Cropper = (
			<div
				style={{
					borderRadius: "5%",
					border: "1px solid rgb(255, 255, 255, 0.2)",
					height: "250px",
					width: "250px",
					margin: "auto",
					marginTop: "20px",
					display: selectedImage ? "none" : "block",
				}}
			>
				<label style={{ display: "block" }}>
					<input
						accept="image/*"
						id="contained-button-file"
						multiple
						type="file"
						onChange={(e) => onFileChange(e)}
						style={{ display: "none" }}
					/>
					<Button
						sx={{ mt: "25%", textTransform: "none" }}
						color="primary"
						aria-label="upload picture"
						component="span"
					>
						<div>
							<div>
								<CloudUploadOutlinedIcon
									style={{ width: "75px", height: "75px", marginTop: "6vh" }}
								></CloudUploadOutlinedIcon>
							</div>
							<div style={{ display: "block", fontSize: "20px" }}>
								Select File
							</div>
						</div>
					</Button>
				</label>
			</div>
		);
	}

	return (
		<div
			style={{
				marginTop: "0%",
				backgroundColor: "rgb(13,16, 22)",
				height: "92vh",
			}}
		>
			<Prompt
				when={unsavedChanges}
				message="You have unsaved changes, are you sure you want to leave?"
			/>

			<CircularProgress
				style={{
					marginLeft: "50%",
					marginBottom: "25%",
					marginTop: "25%",
					display: fetchingUserDetails ? "block" : "none",
					color: "rgb(132, 232, 244)",
				}}
			></CircularProgress>
			<div
				style={{
					width: "100%",
					display: "flex",
					marginBottom: "4vh",
					justifyContent: "space-evenly",
					display: fetchingUserDetails ? "none" : "flex",
				}}
			>
				<div style={{ textAlign: "left", marginTop: "10vh", width: "40%" }}>
					<label style={{ fontFamily: "Montserrat", color: "white" }}>
						Brand Name
					</label>
					<br></br>
					<input
						type="text"
						value={brand_name}
						onChange={(e) => {
							setbrand_name(e.target.value);
							setunsavedChanges(true);
						}}
						placeholder=""
						style={{
							height: "5.5vh",
							backgroundColor: "#ffffff",
							borderRadius: "0.7vh",
							border: "0vh",
							width: "100%",
							marginTop: "5px",
							fontFamily: "Montserrat",
							paddingLeft: "1.5vh",
						}}
					/>
					<br></br>
					<br></br>
					{/* <label>Logo)</label><br></br>
                <input type="file"  value={this.state.brand_logo} onChange={(e) =>this.setState({brand_logo:e.target.value})}  placeholder="No File Chosen" style={{color:'white',padding: '5px 10px',width:'55vh',height:'6vh',backgroundColor: '#69685c',borderRadius:'1vh',border:'0vh'}}/><br></br><br></br> */}
					<label style={{ fontFamily: "Montserrat", color: "white" }}>
						Email
					</label>
					<br></br>
					<input
						type="email"
						value={userId}
						onChange={(e) => {
							setuserId(e.target.value);
							setunsavedChanges(true);
						}}
						placeholder=""
						style={{
							height: "5.5vh",
							backgroundColor: "#ffffff",
							borderRadius: "0.7vh",
							border: "0vh",
							width: "100%",
							marginTop: "5px",
							fontFamily: "Montserrat",
							paddingLeft: "1.5vh",
						}}
						readonly="readonly"
					/>
					<br></br>
					<br></br>
					<label style={{ fontFamily: "Montserrat", color: "white" }}>
						Your Industry
					</label>
					<br></br>
					<div style={{ width: "100%", marginTop: "5px" }}>
						<Select
							styles={customStyles}
							value={selectedIndustry}
							onChange={onchangeIndustry}
							// onChange={function(event) {onchangeIndustry(); setunsavedChanges(true)}}
							options={industryOptions}
							getOptionValue={(option) => option.industry_name}
							getOptionLabel={(option) => option.industry_name}
						/>
					</div>
					<br></br>
					<br></br>
					<label style={{ fontFamily: "Montserrat", color: "white" }}>
						{" "}
						You define as a
					</label>
					<div style={{ marginTop: "5px" }}>
						<Button
							style={
								product === 1
									? {
											fontFamily: "Montserrat",
											fontSize: "20px",
											fontWeight: "100",
											textTransform: "none",
											height: "7vh",
											width: "45%",
											color: "rgba(218, 218, 218, 1)",
											backgroundColor: "rgba(23, 31, 44, 1)",
											borderRadius: "1vh",
											border: "solid 1px rgba(111, 111, 111, 1)",
											marginRight: "6.5vh",
									  }
									: {
											fontFamily: "Montserrat",
											fontSize: "15px",
											backgroundColor: "rgba(23, 31, 44, 1)",
											borderRadius: "1vh",
											color: "rgba(255, 255, 255, 0.35)",
											fontWeight: "100",
											textTransform: "none",
											height: "7vh",
											width: "45%",
											marginRight: "6.5vh",
									  }
							}
							variant="outlined"
							onClick={() => {
								setProduct(1);
								setcompany_type("Product");
								setunsavedChanges(true);
							}}
							fullWidth={true}
						>
							{product === 1 ? (
								<img
									style={{
										marginRight: "2vh",
										marginBottom: "0.5vh",
										height: "12px",
										width: "12px",
									}}
									src={Tick}
									alt=""
								/>
							) : (
								""
							)}
							Product
						</Button>
						<Button
							style={
								product === 0
									? {
											fontFamily: "Montserrat",
											fontWeight: "100",
											fontSize: "20px",
											textTransform: "none",
											height: "7vh",
											width: "45%",
											color: "rgba(218, 218, 218, 1)",
											backgroundColor: "rgba(23, 31, 44, 1)",
											borderRadius: "1vh",
											border: "solid 1px rgba(111, 111, 111, 1)",
									  }
									: {
											fontFamily: "Montserrat",
											fontSize: "15px",
											backgroundColor: "rgba(23, 31, 44, 1)",
											borderRadius: "1vh",
											color: "rgba(255, 255, 255, 0.35)",
											fontWeight: "100",
											textTransform: "none",
											height: "7vh",
											width: "45%",
									  }
							}
							variant="outlined"
							onClick={() => {
								setProduct(0);
								setcompany_type("Service");
								setunsavedChanges(true);
							}}
							fullWidth={true}
						>
							{product === 0 ? (
								<img
									src={Tick}
									style={{
										marginRight: "2vh",
										marginBottom: "0.5vh",
										height: "12px",
										width: "12px",
									}}
									alt=""
								/>
							) : (
								""
							)}
							Service
						</Button>
					</div>
					<br></br>
					<br></br>
					<label style={{ fontFamily: "Montserrat", color: "white" }}>
						Brand Fonts
					</label>
					<br></br>
					<div style={{ width: "100%", marginTop: "5px" }}>
						<Select
							styles={customStyles}
							value={selectedFont}
							onChange={onchangeFont}
							isMulti
							options={fontOptions}
							getOptionValue={(option) => option.font_name}
							getOptionLabel={(option) => option.font_name}
						/>
					</div>{" "}
					<br></br>
				</div>

				<div style={{ textAlign: "left", width: "40%" }}>
					<div
						style={{
							width: "38%",
							textAlign: "right",
							marginLeft: "auto",
							marginRight: "-21vh",
							display: "flex",
							flexDirection: "row",
							marginTop: "4.5vh",
						}}
					>
						<Button
							style={{
								backgroundColor: "rgba(132, 232, 244, 1)",
								color: "black",
								fontFamily: "Open Sans",
								borderRadius: "2vh",
								textTransform: "none",
								height: "5vh",
							}}
							variant="contained"
							color="secondary"
							// onClick={updateUserProfile}
							onClick={function (event) {
								updateUserProfile();
								setunsavedChanges(false);
							}}
						>
							Save
						</Button>
					</div>

					<label
						style={{
							marginRight: "5%",
							fontFamily: "Montserrat",
							color: "white",
						}}
					>
						Brand Logo
					</label>
					<div
						style={{
							backgroundColor: "#ffffff",
							borderRadius: "10px",
							display: "flex",
							justifyContent: "wrap",
							marginTop: "5px",
						}}
					>
						<div
							className="logos"
							style={{
								display: "flex",
								flexWrap: "wrap",
								height: "20vh",
								width: "78%",
								overflowY: "scroll",
								marginRight: "10px",
							}}
						>
							{userlogos.map((image, index) => {
								return (
									<div
										key={image.id}
										className="img-wrap"
										style={{
											borderRadius: "10px",
											height: "75px",
											margin: "20px 0px",
										}}
									>
										<div
											className="close"
											onClick={() => deleteLogoFile(image)}
										>
											&times;
										</div>
										<img className="styleImageBrandLogo" src={image.logo} />
									</div>
								);
							})}
						</div>
						<Modal isOpen={modalIsOpen} style={modalStyles}>
							{/* <LinearProgress style={{display: (this.state.addingAssets ? 'block' : 'none')}}/> */}
							<div>
								<CloseIcon
									style={{
										position: "absolute",
										top: "25px",
										right: "25px",
										cursor: "pointer",
									}}
									onClick={() => closeModal()}
								></CloseIcon>
							</div>
							<br></br>
							<br></br>
							<br></br>
							<h3>Brand Logo Upload</h3>

							{/* <div style={{borderRadius: '5%', border: '1px solid rgb(255, 255, 255, 0.2)', height: '275px', width: '275px', marginLeft: '33%', display: this.state.selectedImage ? 'none' : 'block'}}>
                        <label style={{display: 'block'}}>
                        <input accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => this.onFileChange(e)} onInput={() => this.handleImageCrop()} style={{display: 'none'}}/>
                        <Button sx={{mt: '45%', textTransform: 'none'}} color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera></PhotoCamera> Upload
                        </Button>
                        </label>
                    </div> */}
							{/* <div style={{display: this.state.selectedImage ? 'flex' : 'none', justifyContent: 'center', marginLeft: '5%'}}> 
                        {Cropper}
                        <ImageCropper src = "https://quarkme-assets.s3.amazonaws.com/media/image_fZup4z7.png"></ImageCropper>
                        <Button onClick={() => this.setState({selectedImage: null})}>Choose another</Button>

                        <Cancel onClick={() => this.setState({selectedImage: null, selectedFile: null})}></Cancel>
                    </div> */}
							{Cropper}

							<br></br>
							{/* <TextField label="Asset Name" sx={{width: '30%', input: {textAlign: "center"}}} value={this.state.assetNote} onChange={(e) => this.setState({assetNote: e.target.value})}></TextField> */}
							<br></br>
							<br></br>
							<div style={{ display: "flex", justifyContent: "center" }}>
								{
									<LoadingButton
										variant="contained"
										style={{
											textTransform: "none",
											backgroundColor: "#634fe5",
											width: "30%",
											borderRadius: "20px",
											height: "50px",
											color: "white",
											fontSize: "22px",
											fontWeight: "lighter",
											marginRight: "5px",
										}}
										onClick={() => {
											addLogoFile();
											setSelectedImage(null);
											setCroppedImage(null);
										}}
									>
										Upload
									</LoadingButton>
								}
							</div>
						</Modal>
						<div style={{ width: "25%" }}>
							<Button
								variant="contained"
								component="label"
								style={{ marginTop: "40%", marginRight: "5px" }}
								onClick={() => openModal()}
							>
								Upload Logo
								{/* <input
                className="Input"
                accept="image/*"
                id="file"
                onChange={(e) => { setbrand_logo(URL.createObjectURL(e.target.files[0])); setLogoFile(e.target.files[0]) }}
                multiple
                type="file"
                hidden
              /> */}
							</Button>
						</div>
					</div>
					<br></br>

					<label style={{ fontFamily: "Montserrat", color: "white" }}>
						Brand Color
					</label>
					<br></br>
					<div
						style={{
							marginTop: "5px",
							backgroundColor: "#ffffff",
							padding: "10px",
							borderRadius: "0.7vh",
						}}
					>
						<div
							style={{
								display: "flex",
								flexWrap: "wrap",
								height: "13vh",
								width: "100%",
								overflowY: "scroll",
							}}
						>
							{brand_colors.map((color, index) => {
								return (
									<div
										style={{
											width: "15vh",
											height: "5vh",
											marginRight: "2vh",
											backgroundColor: `${color}`,
											color: `${color}` > "#8b8680ff" ? "black" : "white",
											padding: "1vh",
											borderRadius: "7px",
											marginBottom: "2vh",
										}}
										key={index}
									>
										{color.substring(0, 7)}
										<div
											style={{
												width: "20%",
												backgroundColor: "#ffffff",
												display: "flex",
												justifyContent: "center",
												borderRadius: "25px",
												marginLeft: "80%",
												marginTop: "-15%",
											}}
										>
											<img
												src={crossIcon}
												width="50%"
												height="50%"
												style={{ margin: "5px 0px" }}
												onClick={() => {
													var filtered = brand_colors.filter(
														(val) => val !== color
													);
													setbrand_colors(filtered);
												}}
											/>
										</div>
									</div>
								);
							})}
						</div>
						<hr
							style={{
								color: "#000000",
								backgroundColor: "#000000",
								height: 0.5,
								borderColor: "#000000",
							}}
						/>
						<div style={{}}>
							<div
								style={{
									display: "flex ",
									marginTop: "2vh",
									// justifyContent: "space-evenly",
									// fontFamily: 'Montserrat'
								}}
							>
								<div
									style={{
										fontFamily: "Montserrat",
										width: "50%",
										display: "flex ",
										alignItems: "left",
									}}
								>
									Select Color
									<InputColor
										style={{ marginLeft: "15px", marginRight: "5px" }}
										initialValue="#5e72e4"
										onChange={setColor}
										placement="right"
									/>
									{color.hex.replace("#", "").toUpperCase().substring(0, 6)}
								</div>

								<div
									style={{
										marginLeft: "5vw",
										width: "40%",
										display: "flex ",
										justifyContent: "right",
									}}
								>
									<Button
										style={{
											backgroundColor: "rgba(132, 232, 244, 1)",
											fontFamily: "Montserrat",
											color: "black",
											textTransform: "none",
											height: "4vh",
											borderRadius: "25px",
											marginRight: "3vh",
											float: "right",
										}}
										variant="contained"
										onClick={() => {
											if (brand_colors.indexOf(color.hex) !== -1) {
												toast.info(
													"Brand color already exists in user's color set!",
													{
														position: "top-center",
														theme: "light",
														autoClose: 2500,
														hideProgressBar: true,
														closeOnClick: true,
														pauseOnHover: true,
														draggable: true,
														progress: undefined,
													}
												);
											} else {
												const newArray = [...brand_colors];
												newArray.push(color.hex);
												// console.log(newArray)
												setbrand_colors(newArray);
												setunsavedChanges(true);
												// console.log(brand_colors);
											}
										}}
									>
										Add
									</Button>
								</div>
							</div>
						</div>
					</div>
					<Button
						style={{
							color: "black",
							// marginLeft: "30vw",
							fontFamily: "Open Sans",
							borderRadius: "5vh",
							textTransform: "none",
							height: "6vh",
							marginTop: "5vh",
							lineHeight: "15px",
							float: "right",
						}}
						variant="contained"
						color="secondary"
						onClick={confirmDeleteAccount}
					>
						Delete Account
					</Button>
				</div>
				{/* User Tags */}
				{/* <label
                  
                  style={{ marginBottom: "1vh" }}
                >
                  Add tags to describe your brand
                </label>
                <div style={{width: '30%'}}>
                  <TagsInput selectedTags={handleSelectedTags} defaultTags={objective} />
                </div>  */}
			</div>
			{/* <ThemeProvider theme={theme}>
      <Modal isOpen={showLinkedInOrgModal} style={modalStylesLinkedIn}>
          <div>
            <CloseIcon
              style={{
                position: "absolute",
                top: "25px",
                right: "25px",
                cursor: "pointer",
              }}
              onClick={() => setShowLinkedinOrgModal(false)}
            ></CloseIcon>
          </div>
          <br/><br></br>
          <div class="org-modal-header">Select LinkedIn organizations to link to MyEra</div>
          <br></br><br></br>

          <FormControl sx={{ml: '25%', alignItems: 'center'}}>
            <FormLabel id="demo-radio-buttons-group-label">Organization</FormLabel>
            
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={selectedOrg}
              // defaultValue={linkedinOrganizations[0].$URN}
              name="radio-buttons-group"
              onChange={(e) => setSelectedOrg(e.target.value)}
            >
            <div>
              {linkedinOrganizations.map(org => {
                return (
                  <div>
                    <FormControlLabel value={org.organization_urn} data-org-name={org.organization_name} control={<Radio />} label={get_org_label(org.organization_name)} />
                  </div>
                )
              })}
            </div>
            </RadioGroup>
          </FormControl>
        <br></br><br></br>
        <div style={{ display: "flex", justifyContent: "center" }}>
                {
                  <LoadingButton
                    variant="contained"
                    style={{
                      textTransform: "none",
                      backgroundColor: "rgb(132, 232, 244)",
                      width: "31%",
                      borderRadius: "20px",
                      // height: "50px",
                      color: "black",
                      // fontWeight: "lighter",
                    }}
                    onClick={() => setLinkedinOrganization()}
                  >
                    Select Organization
                  </LoadingButton>
                }
              </div>
        <br></br>
      </Modal>
      </ThemeProvider> */}
		</div>
	);
}
