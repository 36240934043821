import React, { useState } from "react";
import { Stage, Layer, Image as KonvaImage, Transformer } from "react-konva";
import useImage from "use-image";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";

const URLImage = ({ image, isSelected, onSelect, onChange, listening }) => {
	const imageRef = React.useRef();
	const trRef = React.useRef();
	const [img, setImg] = useState();

	React.useEffect(() => {
		// console.log("IMAGE: ", image);
		let img_var = new window.Image();
		if (typeof image.image === "string") img_var.src = image.image;
		else if (typeof image.image != "undefined")
			img_var.src = image.image.currentSrc;
		img_var.crossOrigin = "Anonymous";
		// console.log("IMAG_VAR IS: ", img_var);
		setImg(img_var);

		if (isSelected && image.id != "background") {
			// we need to attach transformer manually
			trRef.current.nodes([imageRef.current]);
			console.log("Z Idx:", trRef.current.zIndex());
			trRef.current.zIndex(5);
			console.log("Z Idx:", trRef.current.zIndex());
			trRef.current.getLayer().batchDraw();
		}
	}, [isSelected, image, onChange]);

	return (
		<React.Fragment>
			<KonvaImage
				crossorigin="anonymous"
				onClick={onSelect}
				onTap={onSelect}
				ref={imageRef}
				image={img}
				x={image.x}
				y={image.y}
				width={image.width}
				height={image.height}
				id={image.id}
				draggable
				listening={listening}
				// hitGraphEnabled={true}
				onDragEnd={(e) => {
					onChange({
						image: img,
						width: image.width,
						height: image.height,
						id: image.id,
						x: e.target.x(),
						y: e.target.y(),
					});
				}}
				onTransformEnd={(e) => {
					// transformer is changing scale of the node
					// and NOT its width or height
					// but in the store we have only width and height
					// to match the data better we will reset scale on transform end
					const node = imageRef.current;
					const scaleX = node.scaleX();
					const scaleY = node.scaleY();

					// we will reset it back
					node.scaleX(1);
					node.scaleY(1);
					console.log("old x and y: " + image.x + " " + image.y);
					console.log("new x and y: " + node.x() + " " + e.target.y());
					console.log(
						"old width and height: " + image.width + " " + image.height
					);
					onChange({
						image: img,
						id: image.id,
						x: node.x(),
						y: node.y(),
						// set minimal value
						width: Math.max(5, node.width() * scaleX),
						height: Math.max(node.height() * scaleY),
					});
				}}
			/>
			{isSelected && image.id != "background" && (
				<Transformer
					ref={trRef}
					boundBoxFunc={(oldBox, newBox) => {
						// limit resize
						if (newBox.width < 5 || newBox.height < 5) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			)}
		</React.Fragment>
	);
};
export default URLImage;
