import paymentQR from "../Images/paymentQR.jpg";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar"

const Wrapper = styled.div`
  display: flex;
  background: rgb(238,245,255);
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

function RemoveWatermark() {
  return (

    <Wrapper id="outer-container">

      <Sidebar />

      <MainDashboard>
        <Topbar />
        <div style={{ textAlign: "center" }}>
          <h3>Remove Watermark</h3>
          <h5>
            Scan the QR code on any UPI app to make a payment of INR
            200/month* (Billed annually).
          </h5>
          <div>
            Please contact dave@myera.pro once you have made the payment
            to get the watermark removed
          </div>
          <img
            src={paymentQR}
            width="600"
            height="360"
            style={{ marginTop: "5vh" }}
          />
          <div>
            *This is a limited time offer and is subject to change
            without prior notice.
          </div>
        </div>
      </MainDashboard>

    </Wrapper>

    // <div style={{ display: 'flex', flexDirection: 'row' }}>
    //   <Sidebar />
    //   <div style={{ textAlign: "center", marginLeft: '10%  ' }}>
    //   <Topbar /><br /><br />
    //     <h3>Remove Watermark</h3>
    //     <h5>
    //       Scan the QR code on any UPI app to make a payment of INR
    //       200/month* (Billed annually).
    //     </h5>
    //     <div>
    //       Please contact dave@myera.pro once you have made the payment
    //       to get the watermark removed
    //     </div>
    //     <img
    //       src={paymentQR}
    //       width="600"
    //       height="360"
    //       style={{ marginTop: "5vh" }}
    //     />
    //     <div>
    //       *This is a limited time offer and is subject to change
    //       without prior notice.
    //     </div>
    //   </div>
    //   </div>
  );
}

export default RemoveWatermark;