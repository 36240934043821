import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import { dispatchContext } from "../../Statemanagement/Statecontext";
import styled from "styled-components";
import "./CampaignJourney.css";
// import Select from 'react-select';
import Button from "@mui/material/Button";
import Axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import useWindowDimensions from "../../customAPIs/useWindowDimensions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isAuthenticated, ApiToken } from "../../Helper/Endpoints/Endpoints";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Spinner from "../Loader/Spinner";
import Pagination from "@mui/material/Pagination";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select2 from "@mui/material/Select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

let ctaId = "";
let heroId = "1088";
let designId = "";
let objectiveId = "";
let hashTagsSelected = [];
const Wrapper = styled.div`
	display: flex;
	background: #414652;
	box-shadow: 0px 4px 30px 3px #030023;
	border-radius: 36px;
	width: 100%;
	height: 84vh;
	margin-top: 10px;
	margin-right: 2vh;
`;
const CreateDiv = styled.div`
	margin: 2vh auto;
`;

const BodyContainer = styled.div`
	color: rgba(255, 255, 255, 1);
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	@media (min-width: 1278px) {
		width: 100%;
	}
`;

const ButtonWrapper = styled.div`
	//   margin-top: 10%;
	//   padding; 5px;
	text-align: center;
`;
const Title = styled.div`
	text-align: left;
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
	font-size: 3vh;
	margin-top: 4vh;
	margin-bottom: 3vh;
	margin-right: 1vh;
	text-align: center;
	color: #c8daff;
`;

const TagsDiv = styled.div`
	background-color: #343943;
	color: rgba(255, 255, 255, 0.5);
	padding: 2vh 5vh;
	border-radius: 100px;
	border: 2px solid #555b68;
	margin-right: 1vh;
	margin-bottom: 1vh;
	cursor: pointer;
	color: #eaebec;
	font-size: 16px;
`;

var flag = false;

var text_1_length = 0;

function CampaignJourney(props) {
	// const [textOptions, setTextOptions]= useState([]);
	const [addTags, setAddTags] = useState(false);
	const [selectedDesign, setSelectedDesign] = useState("");
	const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();
	const [tagsArray, setTagsArray] = useState([]);
	const [selectedTagsArray, setSelectedTagsArray] = useState([]);
	const [step, setStep] = useState(0);
	const { width } = useWindowDimensions();
	const [selectedText, setSelectedText] = useState("");
	const TitlesArray = ["Template", "Post Topic"];
	// const SubTitlesArray = ['Choose the template of your preferred ratio', 'Which side do you want your text to be?', 'Select or add a maximum of 3 tags to describe your query. Each tag should be a single word only.', 'Select text from our recommendations below, or enter your text manually by clicking on generate', 'Select or add an additional phrase that will go on your creative. E.g.- "Read More" '];
	const [selectedThumbnail, setSelectedThumbnail] = useState(true);
	const [selectedAdditionalThumbnail, setSelectedAdditionalThumbnail] =
		useState(true);
	const [tagsInput, setTagsInput] = useState("");
	const dispatch = useContext(dispatchContext);
	// const [selectedObjective, setSelectedObjective] = useState('');
	const [selectedRatio, setSelectedRatio] = useState(0);
	const [inLoading, setInLoading] = useState(false);
	const [generatedId, setGeneratedId] = useState(0);
	const { userRegistered } = isAuthenticated() ? isAuthenticated() : ApiToken();

	const [loading, setLoading] = useState(false);
	const [numberOfTemplatePages, setNumberOfTemplatePages] = useState(1);
	const [currentTemplatePage, setCurrentTemplatePage] = useState(1);
	const [selectedTemplateType, setSelectedTemplateType] = useState("allIdeas");

	const [TemplatesThumbnail, setTemplatesThumbnail] = useState([
		{
			newest_thumbnails: [
				{
					id: 1,
					title: "Templates coming soon..",
					ids: "0:0",
					url: "null",
					has_additional_text1: true,
					has_additional_text2: true,
					// "count": 0
				},
			],
		},
	]);

	const [errors, setErrors] = useState({
		objectives: "",
		design: "",
		tags: "",
		heroText: "",
		cta: "",
	});

	const [secondaryErrors, setSecondaryErrors] = useState({
		objectives: "require",
		tags: "require",
	});

	const newCampaign = async () => {
		flag = false;
		text_1_length = 0;
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();

		dispatch({ type: "LOADING" });

		const delete_campaign_response = await Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND}/delete_query_result/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			data: {
				generated_id: generatedId,
			},
		});

		setErrors({
			objectives: "",
			design: "",
			tags: "",
			heroText: "",
			cta: "",
		});
	};
	useEffect(() => {
		document.title = "MyEra | Create";
		setLoading(false);
		const getData = async () => {
			const { accessToken } = isAuthenticated()
				? isAuthenticated()
				: ApiToken();

			var requestOptions = {
				method: "GET",
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			};

			fetch(
				`${process.env.REACT_APP_BACKEND}/get_tag_recommendations/`,
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					setTagsArray(data.tag_recommendations);
				});

			const designs_response = await Axios.get(
				`${process.env.REACT_APP_BACKEND}/get_design_types/`
			);

			setInLoading(true);
			const TemplatesThumbnails = await Axios({
				method: "GET",
				// url:`${process.env.REACT_APP_BACKEND}/get_templates/0/`,
				url: `${process.env.REACT_APP_BACKEND}/get_templates/0?page=${currentTemplatePage}`,
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});

			setCurrentTemplatePage(TemplatesThumbnails.data.page_number);
			setNumberOfTemplatePages(TemplatesThumbnails.data.total_pages);
			// setTemplatesThumbnail(TemplatesThumbnails.data[0].user_specific_thumbnails.concat(TemplatesThumbnails.data[1].most_chosen_thumbnails.concat(TemplatesThumbnails.data[2].industry_specific_thumbnails.concat(TemplatesThumbnails.data[3].newest_thumbnails))));
			setTemplatesThumbnail(
				TemplatesThumbnails.data.templates.filter((ThumbNail) => {
					return ThumbNail.is_product_mockup != true;
				})
			);
			setInLoading(false);

			// const TextJson = await Axios({
			//     method: 'GET',
			//     url: `${process.env.REACT_APP_BACKEND}/get_text_suggestions/`,
			//     headers: {
			//         Authorization: `Bearer ${accessToken}`
			//     }
			// });
			// var dummy =[];
			// dummy.push(TextJson.data.default[0].groupedText);
			// for(var i =0;i<TextJson.data.user_specific.length; i++){
			//     dummy.push(TextJson.data.user_specific[i].groupedText);
			// }
			// for(var i =0;i<TextJson.data.industry_specific.length; i++){
			//     dummy.push(TextJson.data.industry_specific[i].groupedText);
			// }
			// for(var i =0;i<TextJson.data.common.length; i++){
			//     dummy.push(TextJson.data.common[i].groupedText);
			// }
			// setTextOptions(dummy);
		};
		getData();
	}, []);

	async function onTemplatePageChange(event, page_number) {
		setInLoading(true);
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();

		const TemplatesThumbnails = await Axios({
			method: "GET",
			// url:`${process.env.REACT_APP_BACKEND}/get_templates/0/`,
			url: `${process.env.REACT_APP_BACKEND}/get_templates/${selectedRatio}?page=${page_number}`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		console.log(TemplatesThumbnails.data);

		setCurrentTemplatePage(TemplatesThumbnails.data.page_number);
		setNumberOfTemplatePages(TemplatesThumbnails.data.total_pages);
		// setTemplatesThumbnail(TemplatesThumbnails.data[0].user_specific_thumbnails.concat(TemplatesThumbnails.data[1].most_chosen_thumbnails.concat(TemplatesThumbnails.data[2].industry_specific_thumbnails.concat(TemplatesThumbnails.data[3].newest_thumbnails))));
		setTemplatesThumbnail(
			TemplatesThumbnails.data.templates.filter((ThumbNail) => {
				return ThumbNail.is_product_mockup != true;
			})
		);
		setInLoading(false);
	}

	const onChangeText = (item) => {
		setSelectedText(item);
		setErrors({
			objectives: errors.objectives,
			design: errors.design,
			tags: errors.tags,
			text: "",
			heroText: errors.heroText,
			cta: "",
		});
	};
	const customStylesModal = {
		content: {
			backgroundColor: "rgba(1,15,30,255)",

			borderRadius: "3vh",
		},
		overlay: {
			position: "fixed",

			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
		},
	};

	const TemplatesTypeThumbnails = async (template_ratio_id) => {
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();
		setCurrentTemplatePage(1);
		setInLoading(true);
		const TemplatesThumbnails = await Axios({
			method: "GET",
			url: `${process.env.REACT_APP_BACKEND}/get_templates/${template_ratio_id}?page=1`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		setCurrentTemplatePage(TemplatesThumbnails.data.page_number);
		setNumberOfTemplatePages(TemplatesThumbnails.data.total_pages);
		setSelectedRatio(template_ratio_id);
		setTemplatesThumbnail(
			TemplatesThumbnails.data.templates.filter((ThumbNail) => {
				return ThumbNail.is_product_mockup != true;
			})
		);
		setCurrentTemplatePage(1);
		setInLoading(false);
	};

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			borderBottom: "1px solid rgba(33, 42, 64, 1)",
			backgroundColor: "#121828",
			color: state.isSelected
				? "rgba(255, 255, 255, 1)"
				: "rgba(255, 255, 255, 0.3)",
			padding: 20,
		}),
		control: (_, { selectProps: { width } }) => ({
			display: "flex",
			border: "solid 1px rgba(132, 232, 244, 1)",
			color: "black",
			borderRadius: "1vh",
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";

			return { ...provided, opacity, transition, color: "black" };
		},
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: "rgba(36, 171, 226, 0.24)",
				color: "#24abe2",
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#24abe2",
		}),
	};

	const theme = createTheme({
		palette: {
			mode: "dark",
			primary: {
				main: "rgba(98, 77, 232, 1)",
			},
		},
	});

	const lightTheme = createTheme({
		palette: {
			mode: "light",
			primary: {
				main: "rgba(98, 77, 232, 1)",
			},
		},
	});

	// function setTextParams(){
	//     ctaId = selectedText.cta_id;
	//     if(typeof ctaId==='undefined') ctaId=textOptions[0].cta_id;
	//     heroId = selectedText.hero_id;
	//     if(typeof heroId==='undefined') heroId=textOptions[0].hero_id;
	// }
	const getStarted = async () => {
		if (step === 0) {
			//logic
			if (selectedTagsArray.length) {
				if (tagsInput !== "") {
					let newArray = [...tagsArray];
					newArray.push(tagsInput);
					console.log(tagsArray);
					setTagsInput("");

					setSelectedTagsArray((array) => {
						const newArray = [...array];
						newArray.push(tagsArray.length);

						return newArray;
					});

					setTagsArray(newArray);
				}
				setStep((step) => step + 1);
				setLoading(true);
				setTimeout(() => {
					setLoading(false);
				}, 5000);
			} else {
				setErrors((errors) => ({ ...errors, tags: "required" }));
				setSecondaryErrors((errors) => ({ ...errors, tags: "required" }));
			}

			hashTagsSelected = [];
			selectedTagsArray.forEach((tagIndex) => {
				const hashtag = "#" + tagsArray[tagIndex];
				hashTagsSelected.push(hashtag);
			});
		} else if (step === 1) {
			//logic
			// if (selectedObjective !== '' && selectedDesign !== '') {
			setStep((step) => step + 1);
			//  } else {
			//         if(selectedObjective === '' && selectedDesign === ''){
			//             setErrors(errors => ({ ...errors, objectives: 'required'}));
			//             setErrors(errors => ({ ...errors, design: 'required' }));
			//             setSecondaryErrors(errors => ({ ...errors, objectives: 'required' }));
			//         }
			//         else if(selectedObjective === '') {
			//             setErrors(errors => ({ ...errors, objectives: 'required'}));
			//             setSecondaryErrors(errors => ({ ...errors, objectives: 'required' }));
			//         }

			//         else setErrors(errors => ({ ...errors, design: 'required' }));
			//     }
			// console.log(selectedObjective, selectedDesign)

			// objectiveId = selectedObjective;

			// designId = selectedDesign;
			// // console.log(objectiveId,designId)
			// setSelectedTemplateType('allIdeas');
		}
	};

	const handleTagsInput = (event) => {
		setTagsInput(event.target.value);
	};

	return (
		<div className="semi-outer-container">
			<div className="left" style={{ width: "85%" }}>
				<div style={{ width: "100%" }}>
					<div
						{...props}
						style={{ ...customStylesModal, width: "100%" }}
						// style={{width:'100%'}}
						className={width < 1287 ? `Modal` : ""}
						overlayClassName={width < 1287 ? "Overlay" : ""}
						contentLabel="Example Modal"
						dialogClassName="Modal"
					>
						<div style={{ marginTop: "3vh" }}>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Wrapper>
									<BodyContainer>
										{step === 0 && (
											<>
												<Title>{TitlesArray[1]}</Title>
												{/* <SubTitle>{SubTitlesArray[2]}</SubTitle> */}

												{/* <h3>{showWarning()}</h3> */}

												<div style={{ height: "350px" }}>
													{
														<>
															<div
																style={{
																	width: "40%",
																	marginLeft: "auto",
																	marginRight: "auto",
																	marginTop: "6%",
																	textAlign: "left",
																	padding: "1.5vh",
																	color: "rgba(255, 255, 255, 0.8)",
																	borderRadius: "1vh",
																	display: "flex",
																	justifyContent: "center",
																}}
															>
																<div
																	// component="form"
																	// sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
																	style={{
																		padding: "2px 4px",
																		display: "flex",
																		alignItems: "center",
																		width: "600px",
																		backgroundColor:
																			"rgba(250, 250, 250, 0.93)",
																		borderRadius: "10px",
																	}}
																>
																	<IconButton
																		sx={{ p: "10px" }}
																		aria-label="menu"
																	>
																		<SearchIcon />
																	</IconButton>
																	<InputBase
																		sx={{ ml: 1, flex: 1 }}
																		placeholder="Search"
																		inputProps={{
																			"aria-label": "search google maps",
																		}}
																		onKeyPress={(e) => {
																			if (e.key === "Enter") {
																				if (tagsInput !== "") {
																					let newArray = [...tagsArray];
																					newArray.push(tagsInput);
																					setTagsInput("");
																					setSelectedTagsArray((array) => {
																						const newArray = [...array];
																						newArray.push(tagsArray.length);
																						return newArray;
																					});
																					return setTagsArray(newArray);
																				}
																			}

																			setSecondaryErrors({
																				objectives: secondaryErrors.objectives,
																				tags: "",
																			});
																		}}
																		value={tagsInput}
																		onChange={(event) => handleTagsInput(event)}
																	/>
																</div>
															</div>
														</>
													}

													<div
														style={{
															width: "40%",
															marginLeft: "auto",
															marginRight: "auto",
															marginTop: "3%",
															fontSize: "16px",
															color: "#D9DADC",
														}}
													>
														Trending Topics:
													</div>

													{errors.tags && (
														<div
															style={{
																color: "#FE7373",
																width: "40%",
																marginLeft: "auto",
																marginRight: "auto",
															}}
														>
															{errors.tags}
														</div>
													)}

													<div
														style={{
															display: "flex",
															flexWrap: "wrap",
															marginTop: "2vh",
															width: "40%",
															marginLeft: "auto",
															marginRight: "auto",
														}}
													>
														{tagsArray.map((tag, index) => {
															return (
																<TagsDiv
																	onClick={() => {
																		if (!selectedTagsArray.includes(index)) {
																			setSelectedTagsArray((array) => {
																				const newArray = [...array];
																				newArray.push(index);

																				return newArray;
																			});
																			setErrors({
																				objectives: errors.objectives,
																				design: errors.design,
																				tags: "",
																				heroText: errors.heroText,
																				cta: errors.cta,
																			});
																			setSecondaryErrors({
																				objectives: secondaryErrors.objectives,
																				tags: "",
																			});
																		} else {
																			let newArray = [...selectedTagsArray];
																			const elementIndex =
																				newArray.indexOf(index);
																			newArray.splice(elementIndex, 1);
																			setSelectedTagsArray(newArray);
																		}
																	}}
																	style={
																		selectedTagsArray.includes(index)
																			? {
																					backgroundColor:
																						"rgba(132, 232, 244, 1)",
																					color: "rgba(11, 17, 32, 1)",
																			  }
																			: {}
																	}
																	key={index}
																>
																	{tag}
																</TagsDiv>
															);
														})}
													</div>
													<Button
														style={{
															// backgroundColor: 'rgba(98, 77, 232, 1)',
															borderRadius: "100px",
															fontFamily: "Open Sans",
															width: "18vh",
															textTransform: "none",
															height: "5vh",
															marginBottom: "5vh",
															backgroundColor: secondaryErrors.tags
																? "#A1B0B1"
																: "#84E8F4",
															color: "black",
															marginTop: "8%",
															marginLeft: "45%",
														}}
														variant="contained"
														color="secondary"
														onClick={getStarted}
													>
														{"Next"}
														<ArrowForwardIcon style={{ marginLeft: "10px" }} />
													</Button>
												</div>
											</>
										)}
										{step === 1 && (
											<>
												<Title>{TitlesArray[0]}</Title>
												{/* <SubTitle>{SubTitlesArray[0]}</SubTitle> */}
												{/* <br /> */}

												<div
													style={{
														width: "70%",
														display: "flex",
														margin: "auto",
														marginBottom: "1%",
													}}
												>
													<Button
														style={{
															background: "#84E8F4",
															border: "2px solid #84E8F4",
															borderRadius: "100px",
															color: "#00272C",
														}}
													>
														{tagsArray[selectedTagsArray[0]]}
													</Button>

													<div
														onClick={() => {
															if (
																errors.cta ===
																"No assets found for these inputs"
															) {
																setErrors((errors) => ({ ...errors, cta: "" }));
															}
															setStep((step) => step - 1);
														}}
														style={{
															color: "#84E8F4",
															cursor: "pointer",
															marginLeft: "4%",
															marginBottom: "1%",
															display: "flex",
															justifyContent: "center",
															alignItems: "end",
															textDecorationLine: "underline",
														}}
													>
														Change Topic
													</div>
												</div>

												<ThemeProvider theme={theme}>
													<div
														style={{
															width: "70%",
															display: "flex",
															flexWrap: "wrap",
															margin: "auto",
														}}
													>
														<Button
															id="defaultButton"
															style={{
																background:
																	selectedTemplateType === "allIdeas"
																		? "#84E8F4"
																		: "#343943",
																border:
																	selectedTemplateType === "allIdeas"
																		? "2px solid #84E8F4"
																		: "2px solid #555B68",
																borderRadius: "100px",
																color:
																	selectedTemplateType === "allIdeas"
																		? "#00272C"
																		: "#EAEBEC",
															}}
															onClick={() => {
																setSelectedTemplateType("allIdeas");
																TemplatesTypeThumbnails(0);
															}}
														>
															All Sizes
														</Button>

														<Button
															id="instaPostButton"
															style={{
																background:
																	selectedTemplateType === "instaPost"
																		? "#84E8F4"
																		: "#343943",
																border:
																	selectedTemplateType === "instaPost"
																		? "2px solid #84E8F4"
																		: "2px solid #555B68",
																borderRadius: "100px",
																color:
																	selectedTemplateType === "instaPost"
																		? "#00272C"
																		: "#EAEBEC",
																marginLeft: "2%",
																marginRight: "2%",
															}}
															onClick={() => {
																setSelectedTemplateType("instaPost");
																TemplatesTypeThumbnails(1);
															}}
														>
															1:1 Instagram Post
														</Button>

														<FormControl
															style={{ width: "15%", height: "20px" }}
														>
															<InputLabel
																id="demo-simple-select-label"
																style={{ marginTop: "-7px" }}
															>
																More
															</InputLabel>
															<Select2
																labelId="demo-simple-select-label"
																id="demo-simple-select"
																value={
																	selectedTemplateType === "allIdeas" ||
																	selectedTemplateType === "instaPost"
																		? "none"
																		: selectedTemplateType === "instaStory"
																		? 3
																		: 4
																}
																label="Category"
																style={{ height: "40px" }}
															>
																{/* <MenuItem value={1} onClick={() => { setSelectedTemplateType('allIdeas'); TemplatesTypeThumbnails(0); }}>All</MenuItem>
                                                            <MenuItem value={2} onClick={() => { setSelectedTemplateType('instaPost'); TemplatesTypeThumbnails(1); }}>Insta Post</MenuItem> */}
																<MenuItem
																	value={3}
																	onClick={() => {
																		setSelectedTemplateType("instaStory");
																		TemplatesTypeThumbnails(2);
																	}}
																>
																	Insta Story
																</MenuItem>
																<MenuItem
																	value={4}
																	onClick={() => {
																		setSelectedTemplateType("presentation");
																		TemplatesTypeThumbnails(3);
																	}}
																>
																	Presentation
																</MenuItem>
															</Select2>
														</FormControl>
														<div
															style={{
																fontSize: "17px",
																marginLeft: "auto",
																alignSelf: "center",
															}}
														>
															<button
																style={{
																	cursor: "pointer",
																	borderRadius: "100px",
																	border: "1px solid #84E8F4",
																	fontSize: "2vh",
																	backgroundColor: "rgba(132, 232, 244, 0.001)",
																	color: "white",
																	width: "28vh",
																	height: "5vh",
																	display: "flex",
																}}
															>
																<AddIcon
																	style={{
																		color: "#84E8F4",
																		margin: "auto",
																	}}
																></AddIcon>
																<a
																	href="https://forms.zohopublic.in/myerapro/form/MyEraTemplateRequest/formperma/CnaRQXKAxLABkPsCzLqIA3oFVpt06f5a_ilkmGPh3TM"
																	target="_blank"
																	style={{
																		display: "flex",
																		textDecoration: "none",
																		margin: "auto",
																		color: "#84E8F4",
																		marginLeft: "-5px",
																	}}
																	rel="noreferrer"
																>
																	{"Request a New Template"}
																</a>
															</button>
														</div>
													</div>
													<br></br>
													<LinearProgress
														style={{
															display: inLoading ? "block" : "none",
															width: "95%",
															marginLeft: "3%",
														}}
														sx={{ color: "rgba(98, 77, 232, 1)" }}
													></LinearProgress>
												</ThemeProvider>

												{loading && <Spinner></Spinner>}

												{!loading && (
													<div
														className="templatesScroll"
														style={{
															width: "70%",
															height: "43vh",
															display: "flex",
															flexWrap: "wrap",
															overflowY: "scroll",
															margin: "auto",
														}}
													>
														{TemplatesThumbnail.map((nm) =>
															nm.url != null ? (
																<CreateDiv
																	key={nm.id}
																	style={{
																		width: selectedRatio === 3 ? "45%" : "15%",
																		borderRadius: "4px",
																		boxSizing: "border-box",
																		margin: "auto",
																	}}
																>
																	<img
																		id={"img" + `${nm.id}`}
																		src={nm.url}
																		alt={"template " + `${nm.id}`}
																		width="100%"
																		height="auto"
																		style={{
																			opacity: "1",
																			display: "block",
																			marginLeft: "auto",
																			marginRight: "auto",
																			borderRadius: "4px",
																			marginBottom: "15%",
																		}}
																		onMouseOver={() => {
																			document.getElementById(
																				"img" + `${nm.id}`
																			).style.cursor = "pointer";
																		}}
																		onClick={() => {
																			if (
																				document.getElementById(
																					"img" + `${nm.id}`
																				).style.opacity === "1"
																			) {
																				TemplatesThumbnail.forEach((a) => {
																					if (a.url != null)
																						document.getElementById(
																							"img" + `${a.id}`
																						).style.opacity = "1";
																				});
																				document.getElementById(
																					"img" + `${nm.id}`
																				).style.opacity = "0.4";

																				console.log("TEMPLATES DATA IS: ", nm);

																				if (nm.has_additional_text1)
																					setSelectedThumbnail(true);
																				else setSelectedThumbnail(false);

																				if (nm.has_additional_text2)
																					setSelectedAdditionalThumbnail(true);
																				else
																					setSelectedAdditionalThumbnail(false);

																				let str = nm.title;
																				const myArr = str.split(":");
																				let str2 = nm.ids;
																				const myArr2 = str2.split(":");
																				//  console.log(myArr2)
																				objectiveId = myArr2[0];
																				designId = myArr2[1];
																				//  setSelectedObjective(myArr2[0]);
																				//  setSelectedDesign(myArr2[1]);
																				// console.log(objectiveId,designId);
																				setErrors({
																					objectives: "",
																					design: "",
																					tags: errors.tags,
																					heroText: errors.heroText,
																					cta: errors.cta,
																				});
																				setSecondaryErrors({
																					objectives: "",
																					tags: secondaryErrors.tags,
																				});
																			} else {
																				document.getElementById(
																					"img" + `${nm.id}`
																				).style.opacity = "1";
																				let str = nm.title;
																				const myArr = str.split(":");
																				let str2 = nm.ids;
																				const myArr2 = str2.split(":");
																				// objectiveId = myArr2[0];
																				objectiveId = "";
																				// designId = myArr2[1];
																				designId = "";
																				console.log(objectiveId, designId);
																				//  setSelectedObjective('');
																				//  setSelectedDesign('');
																			}
																			//  console.log(selectedObjective, selectedDesign);
																		}}
																	/>
																</CreateDiv>
															) : null
														)}
													</div>
												)}

												{errors.objectives && (
													<div
														style={{
															color: "#FE7373",
															width: "69%",
															marginLeft: "auto",
															marginRight: "auto",
															marginTop: "-1%",
														}}
													>
														{errors.objectives}
													</div>
												)}
												<br></br>
												<div
													style={{ display: "flex", justifyContent: "center" }}
												>
													<ThemeProvider theme={lightTheme}>
														<Pagination
															color="primary"
															count={numberOfTemplatePages}
															page={currentTemplatePage}
															onChange={onTemplatePageChange}
														></Pagination>
													</ThemeProvider>
												</div>

												{/* setTimeout(() => {
                                                setLoading(false)
                                            }, 5000); */}

												<Link
													to={{
														pathname: props.pathname
															? props.pathname
															: "/create-now/generate_creative_api",
														state: {
															objectiveId: { objectiveId },
															designId: { designId },
															ctaId: { ctaId },
															heroId: { heroId },
															hashTagsSelected: { hashTagsSelected },
														},
													}}
													style={{ textDecoration: "none" }}
												>
													<Button
														disabled={objectiveId === ""}
														style={{
															// backgroundColor: 'rgba(98, 77, 232, 1)',
															borderRadius: "100px",
															fontFamily: "Open Sans",
															width: "18vh",
															textTransform: "none",
															height: "5vh",
															marginBottom: "5vh",
															backgroundColor: secondaryErrors.tags
																? "#A1B0B1"
																: "#84E8F4",
															color: "black",
															marginTop: "-1%",
															marginLeft: "75%",
														}}
														variant="contained"
														color="secondary"
														// onClick={getStarted}
													>
														{"Generate"}
													</Button>
												</Link>
											</>
										)}

										{/* {errors.cta && <p style={{ color: '#FE7373', textAlign: 'left' }}>{errors.cta}</p>} */}
									</BodyContainer>
								</Wrapper>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CampaignJourney;
