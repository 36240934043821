import React, { useEffect, useState } from "react";
import commonStyles from "../MyeraScript.module.css";
import styles from "./BoxPackagingCompany.module.css";
import SizeTab from "./Tabs/SizeTab";
import VariantTab from "./Tabs/VariantTab";
import { toast } from "react-toastify";
import axios from "axios";
import QuantityTab from "./Tabs/QuantityTab";
import AdditionalNotesTab from "./Tabs/AdditionalNotesTab";
import QuoteTab from "./Tabs/QuoteTab";

const BoxPackagingCompany = () => {
	const [innerOuter, setInnerOuter] = useState("outer");
	const [unit, setUnit] = useState("inch");
	const [width, setWidth] = useState(null);
	const [height, setHeight] = useState(null);
	const [length, setLength] = useState(null);

	const [minLength, setMinLength] = useState(3);
	const [maxLength, setMaxLength] = useState(21);
	const [minWidth, setMinWidth] = useState(2);
	const [maxWidth, setMaxWidth] = useState(20);
	const [minHeight, setMinHeight] = useState(1);
	const [maxHeight, setMaxHeight] = useState(6);

	const [data, setData] = useState(null);
	const [quantity, setQuantity] = useState(0);
	const [variantSelections, setVariantSelections] = useState({});
	const [currentTab, setCurrentTab] = useState(0.0);
	const [pantoneCount, setPantoneCount] = useState(0);

	const [allowRequestQuote, setAllowRequestQuote] = useState(false);
	const [allowAdditionalComments, setAllowAdditionalComments] = useState(false);

	const [nickname, setNickname] = useState("");
	const [comments, setComments] = useState("");
	const [assets, setAssets] = useState([]);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");

	let qtyTabIndex = data ? data.variants.length + 1 : 0;
	let additionalNotesTabIndex = data ? data.variants.length + 2 : 0;
	let quoteTabIndex = data ? data.variants.length + 3 : 0;
	const searchParams = new URLSearchParams(window.location.search);
	const product_id = searchParams.get("product_id");

	const getS3URL = async (blob, name) => {
		try {
			const formData = new FormData();

			formData.append("asset_name", name);
			formData.append("tenant_id", "5");
			formData.append("asset", blob);

			// Upload to S3
			const res = await axios.post(
				"https://django.myera.pro/myera/api/save_client_user_asset/",
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			console.log(res.data);
			return res.data.url;
		} catch (err) {
			console.log(err);
			toast.error("Couldn't get s3 URL");
			return "";
		}
	};

	const getTabsInSequence = () => {
		const tabs = [];
		tabs.push({
			tab: (
				<SizeTab
					image={data.shopify_product_image}
					innerOuter={innerOuter}
					setInnerOuter={setInnerOuter}
					unit={unit}
					setUnit={setUnit}
					width={width}
					setWidth={setWidth}
					height={height}
					setHeight={setHeight}
					length={length}
					setLength={setLength}
					minLength={minLength * (unit === "centimeter" ? 2.54 : 1)}
					maxLength={maxLength * (unit === "centimeter" ? 2.54 : 1)}
					minWidth={minWidth * (unit === "centimeter" ? 2.54 : 1)}
					maxWidth={maxWidth * (unit === "centimeter" ? 2.54 : 1)}
					minHeight={minHeight * (unit === "centimeter" ? 2.54 : 1)}
					maxHeight={maxHeight * (unit === "centimeter" ? 2.54 : 1)}
				/>
			),
			priority: 0,
		});
		data.variants.forEach((variant) => {
			tabs.push({
				tab: (
					<VariantTab
						title={variant.label}
						description={variant.variant_description}
						options={variant.options}
						isMulti={variant.is_multi}
						pantoneCount={pantoneCount}
						onChangePantone={(count) => setPantoneCount(count)}
						onSelect={(option) => {
							if (!variant.is_multi) {
								setVariantSelections((prev) => {
									return {
										...prev,
										[variant.variant_code]: option.option_code,
									};
								});
							} else {
								setVariantSelections((prev) => {
									if (prev[variant.variant_code]) {
										if (
											prev[variant.variant_code].includes(option.option_code)
										) {
											return {
												...prev,
												[variant.variant_code]: prev[
													variant.variant_code
												].filter((opt) => opt !== option.option_code),
											};
										} else {
											return {
												...prev,
												[variant.variant_code]: [
													...prev[variant.variant_code],
													option.option_code,
												],
											};
										}
									} else {
										return {
											...prev,
											[variant.variant_code]: [option.option_code],
										};
									}
								});
							}
						}}
						selectedOption={variantSelections[variant.variant_code]}
					/>
				),
				priority: variant.priority,
			});
		});
		tabs.push({
			tab: (
				<QuantityTab
					selectedOption={quantity}
					onSelect={(qty) => {
						setQuantity(qty);
					}}
				/>
			),
			priority: data.variants.length + 1,
			isQtyTab: true,
		});
		allowAdditionalComments &&
			tabs.push({
				tab: (
					<AdditionalNotesTab
						nickname={nickname}
						setNickname={setNickname}
						comments={comments}
						setComments={setComments}
						assets={assets}
						setAssets={setAssets}
					/>
				),
				priority: 1000,
				isAdditionalNotesTab: true,
			});
		allowRequestQuote &&
			tabs.push({
				tab: (
					<QuoteTab
						name={name}
						setName={setName}
						email={email}
						setEmail={setEmail}
						phone={phone}
						setPhone={setPhone}
					/>
				),
				priority: 2000,
				isQuoteTab: true,
			});
		tabs.sort((a, b) => a.priority - b.priority);

		qtyTabIndex = tabs.findIndex((tab) => tab.isQtyTab);
		additionalNotesTabIndex = tabs.findIndex((tab) => tab.isAdditionalNotesTab);
		quoteTabIndex = tabs.findIndex((tab) => tab.isQuoteTab);

		return tabs.map((tab) => tab.tab);
	};

	const fetchData = async () => {
		try {
			const res = await axios.get(
				"https://django.myera.pro/quarkme/api/shopify/shopify_get_variant_data_new/?product_id=" +
					product_id
			);

			console.log(res);
			setAllowRequestQuote(res.data.allow_request_a_quote);
			setAllowAdditionalComments(res.data.allow_additional_comments);
			setData(res.data);

			if (res.data.optional_data[0]?.size_limits) {
				setMinLength(res.data.optional_data[0].size_limits.min_length);
				setMaxLength(res.data.optional_data[0].size_limits.max_length);
				setMinWidth(res.data.optional_data[0].size_limits.min_width);
				setMaxWidth(res.data.optional_data[0].size_limits.max_width);
				setMinHeight(res.data.optional_data[0].size_limits.min_height);
				setMaxHeight(res.data.optional_data[0].size_limits.max_height);
			}

			res.data.variants.forEach((variant) => {
				setVariantSelections((prev) => {
					return {
						...prev,
						[variant.variant_code]: variant.is_multi
							? []
							: variant.options[0].option_code,
					};
				});
			});
		} catch (err) {
			console.log(err);
			toast.error("Something went wrong");
		}
	};

	useEffect(() => {
		fetchData();
		document
			.querySelector(":root")
			.style.setProperty("--myera-script-primary-color", "#161F5C");
	}, []);

	useEffect(() => {
		console.log("currentTab", currentTab);
		document
			.querySelector(":root")
			.style.setProperty("--myera-script-current-tab", currentTab);
		document.getElementById(commonStyles.wrapper).scrollIntoView({
			behavior: "smooth",
		});
	}, [currentTab]);

	return (
		<div
			className={[commonStyles.wrapper, styles.wrapper].join(" ")}
			id={commonStyles.wrapper}
		>
			{data && (
				<div
					className={[styles.main_container, commonStyles.main_container].join(
						" "
					)}
				>
					<div className={styles.tabs_container}>{getTabsInSequence()}</div>
					<div className={styles.cta}>
						<button
							className={[styles.button, styles.secondary].join(" ")}
							onClick={() => {
								if (currentTab > 0) setCurrentTab((prev) => prev - 1);
							}}
						>
							Previous
						</button>
						<button
							className={[styles.button, styles.primary].join(" ")}
							onClick={async () => {
								if (currentTab === qtyTabIndex && quantity === 0) {
									toast.error("Please select a quantity!");
									return;
								}

								if (currentTab === quoteTabIndex) {
									if (name === "") {
										toast.error("Please enter your name!");
										return;
									}
									if (
										email === "" ||
										!RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email)
									) {
										toast.error("Please enter your email!");
										return;
									}
									if (phone !== "" && !RegExp(/^[0-9]{10}$/).test(phone))
										toast.error("Please enter a valid phone number!");

									// if (phone === "") {
									// 	toast.error("Please enter your phone number!");
									// 	return;
									// }
								}

								if (
									currentTab <
									data.variants.length +
										1 +
										(allowAdditionalComments ? 1 : 0) +
										(allowRequestQuote ? 1 : 0)
								) {
									if (currentTab === 0) {
										const mul = unit === "centimeter" ? 2.54 : 1;
										if (
											!length ||
											!width ||
											!height ||
											parseFloat(length) < minLength * mul ||
											parseFloat(length) > maxLength * mul ||
											parseFloat(width) < minWidth * mul ||
											parseFloat(width) > maxWidth * mul ||
											parseFloat(height) < minHeight * mul ||
											parseFloat(height) > maxHeight * mul
										) {
											toast.error("Please enter valid dimensions!");
											return;
										}
									}
									setCurrentTab((prev) => prev + 1);
								} else {
									if (allowRequestQuote) {
										variantSelections["Quantity"] = quantity;
										variantSelections["Dimensions"] = innerOuter;
										variantSelections["Length"] = length;
										variantSelections["Width"] = width;
										variantSelections["Height"] = height;
										variantSelections["Unit"] = unit;

										const quoteData = {
											request_details: variantSelections,
											name: name,
											email: email,
											phone: phone,
											product_id: product_id,
											quantity: quantity,
										};

										if (allowAdditionalComments) {
											quoteData["nickname"] = nickname;
											quoteData["comments"] = comments;
											quoteData["assets"] = [];

											for (const asset of assets) {
												const url = await getS3URL(
													await fetch(asset),
													asset.name
												);

												quoteData["Assets"].push(url);
											}
										}

										console.log(quoteData);

										try {
											const res = await axios.post(
												`https://django.myera.pro/quarkme/api/shopify/request_quote/`,
												quoteData
											);
											console.log(res);

											toast.success(
												"We have received your request! We will get back to you soon."
											);
										} catch (err) {
											console.log(err);
											toast.error("Something went wrong!");
										}
									} else {
										const dataToSend = {};

										try {
											const variant_option_code = [];

											for (let selection of Object.values(variantSelections)) {
												if (typeof selection === "string") {
													variant_option_code.push(selection);
												} else {
													for (let opt_code of selection) {
														variant_option_code.push(opt_code);
													}
												}
											}

											const res = await axios.post(
												`https://django.myera.pro/quarkme/api/shopify/test/get_price/`,
												{
													variant_option_code,
													length:
														parseFloat(length) /
														(unit === "centimeter" ? 2.54 : 1),
													breadth:
														parseFloat(width) /
														(unit === "centimeter" ? 2.54 : 1),
													height:
														parseFloat(height) /
														(unit === "centimeter" ? 2.54 : 1),
													sample: quantity,
													pantone_colors: parseInt(pantoneCount),
													product_id: parseInt(product_id),
												}
											);

											if (allowAdditionalComments) {
												dataToSend["Nickname"] = nickname;
												dataToSend["Comments"] = comments;
												dataToSend["Assets"] = [];

												for (const asset of assets) {
													const url = await getS3URL(asset, asset.name);

													dataToSend["Assets"].push(url);
												}
											}

											dataToSend["Cost Estimation"] = res.data.price.total;
											dataToSend["Cost Break-up"] = res.data.price;
											delete dataToSend["Cost Break-up"].total;
										} catch (err) {
											console.log(err);
											toast.error("Cannot fetch price!");
										}
										dataToSend["Quantity"] = quantity;
										dataToSend["Dimensions"] = innerOuter;
										dataToSend["Length"] = length;
										dataToSend["Width"] = width;
										dataToSend["Height"] = height;
										dataToSend["Unit"] = unit;

										console.log(dataToSend);

										window.parent.postMessage(
											{
												func: "addToCart",
												message: dataToSend,
											},
											"*"
										);
									}
								}
							}}
						>
							{currentTab ===
							data.variants.length +
								1 +
								(allowAdditionalComments ? 1 : 0) +
								(allowRequestQuote ? 1 : 0)
								? "Submit"
								: "Next"}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default BoxPackagingCompany;
