import React from "react";
import styles from "./Tabs.module.css";

const QuoteTab = ({ name, setName, email, setEmail, phone, setPhone }) => {
	return (
		<div className={styles.tab_container}>
			<div className={styles.title}>Request a Quote</div>
			<div className={styles.subtitle}>
				Additional information about this item based on the product and design,
				and any supporting assets (artwork, concept images, sketches, dielines)
				you want to upload?
			</div>
			<div className={styles.input_group}>
				<div className={styles.input_label}>Name</div>
				<input
					type="text"
					className={styles.input}
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
			</div>
			<div className={styles.input_group}>
				<div className={styles.input_label}>Email</div>
				<input
					type="text"
					className={styles.input}
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>
			<div className={styles.input_group}>
				<div className={styles.input_label}>Phone</div>
				<input
					type="text"
					className={styles.input}
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
				/>
			</div>
		</div>
	);
};

export default QuoteTab;
