import React from "react";
import "./ShopifyHome.css"
import axios from "axios";
import { useState , useEffect} from "react"; 
import Axios from "axios";
import { Card, CardGroup } from "react-bootstrap";
import { shopifyUrlExists } from "../../Helper/Endpoints/Endpoints";



export default function ShopifyHome(props) {

  const [isError,setIsError]=useState([]);

  const [boolvar,setBoolvar]=useState(false);
  const [storeSubscribed, setStoreSubscribed] = useState(true);

  


    // const handleSubmit=()=>{
    //     const URL="https://django.myera.pro/quarkme/api/shopify_app_subscribe/"
    //     const payload=
    //     {
    //         "shop_url":"myeratestapi.myshopify.com"
    //     }

    //     axios.post(URL, payload)
    //     .then((response)=>{
    //         console.log(response.data.recurring_application_charge.confirmation_url)


    //         window.location.href = response.data.recurring_application_charge.confirmation_url;
            
    //     }).catch((error)=>{
    //         setIsError(error.message)
    //         console.log(error.message) 
            
    //         window.location.href = "www.google.com";
        

    //     })

        
    // }
    const { storeURL } = shopifyUrlExists();
    useEffect(()=>{
        document.title="MyEra | Shopify Home Page"
        console.log(storeURL);
       if(!storeURL)
       {
           props.history.push('/shopify-login');
       }
       
       

    },[]);

    const handleSubmit=()=>{
        const idParameter=new URLSearchParams(window.location.search);


        const id=idParameter.get("?charge_id");
        console.log(id)


        if(id===null){
            setBoolvar(false);
            console.log("Charge Id not found")
        }else{
            setBoolvar(true);
        }


        if(boolvar===true){
            const payload=id;
            axios.post("https://django.myera.pro/quarkme/api/shopify_app_charge_check/",payload)
            .then(()=>{
                
            }).catch((error)=>{
                console.log(error)
            })
        }
    }

    return(
        <>
        
        {!storeSubscribed && <CardGroup className="freeplan">
        <Card className="free_plans">
      <Card.Body>
        <Card.Title className="freeplansdiv">Free</Card.Title>
        <Card.Text className="freeplansdiv2">
          <ul className="freeplanlist">
            <li>Unlimited Creatives Generation</li>
            <li>7 free posting, scheduling and downloads </li>
            <li>Limited access to Trendwatch</li>
          </ul>
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <center>
          {/* <Link
            to={{
              pathname: "/signup",
              aboutProps: formik.values.email,
            }}
    
            style={{ textDecoration: "none" }}
          >
            <button className="btn contactusbutton">Get Started</button>
          </Link>
           */}
          
            <button className="btn contactusbutton" onClick={handleSubmit}>Get Premium</button>
          
        </center>
      </Card.Footer>
    </Card>
    </CardGroup>}
    {storeSubscribed && <div style={{background: "white"}}>
        Thank you for subscirbing
        </div>}
    </>
        // <div style={{background: "white"}}>
            
        //     <button
		// 				className="submit_button"
		// 				 onClick={handleSubmit}
		// 			>
						
		// 					Buy

		// 			</button>
        // </div>
    
    )
}