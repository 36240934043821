import React, { useState } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import "./imagecropper.css";
import { getCroppedImageService } from "../Generic/messageService";

class ImageCropper extends React.Component {
	constructor() {
		super();
		this.state = {
			imageDestination: "",
			previewFile: "",
		};
		this.imageElement = React.createRef();
	}

	componentDidMount() {
		const cropper = new Cropper(this.imageElement.current, {
			zoomable: false,
			scalable: false,
			aspectRatio: 1,
			autoCropArea: 1,
			data: this.props.position,
			crop: () => {
				const canvas = cropper.getCroppedCanvas();
				this.setState({ imageDestination: canvas.toDataURL("image/png") });
				getCroppedImageService.sendMessage({
					imageURL: canvas.toDataURL("image/png"),
					originalURL: this.props.src,
					position: cropper.getData(true),
					test: "Hello",
				});
			},
		});
	}

	render() {
		return (
			<div >
			<div className="image_cropper_wrapper">
				<div className="img-container">
					
					<img
						ref={this.imageElement}
						src={this.props.src}
						style={{ borderRadius: "5%" }}
						className="bug-solve"
						alt="Source"
						crossorigin
					/>
				</div>
				<div>
					<h6 className="preview">Preview</h6>
					<div>
						<img
							src={this.state.imageDestination}
							className="img-preview"
							alt="Destination"
						/>
					</div>
				</div>
			</div>
			</div>
		);
	}
}

export default ImageCropper;
