import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated, isAuthenticatedApi, ApiToken } from '../Endpoints/Endpoints';
import React, { useState, useEffect } from "react";

const PrivateRoute = ({ component: Component, ...rest }) => {
  
  const [isApiAccess, setisApiAccess] = useState(true);
  const [display,setdisplay] = useState(false);
  
  useEffect(() => { 
    async function apiAuthenticate(){
    isAuthenticatedApi(ApiToken().accessToken).then((data) =>{;
      setisApiAccess(data.ok);
      setdisplay(true);
    });  
  };
  apiAuthenticate();
  }, [])
  
  return display? <Route {...rest} render={props => (isAuthenticated() || isApiAccess ? <Component {...props} /> : <Redirect to="/login" />)} />:<></>
};

export default PrivateRoute;
