import React, { Component, useState } from "react";
import styled from "styled-components";
import "../Campaign/templateScroll.css";
import "./creative_image.css";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import Axios from "axios";
import { IsoRounded } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import tags2 from "../Images/tags2.png";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import PostModal from "../SocialPostsCalander/PostModal";
import { openModalService } from "../Generic/messageService";
import { CircularProgress } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import EventNoteIcon from "@mui/icons-material/EventNote";
import DeleteIcon from "@mui/icons-material/Delete";
toast.configure();

const styleOuterContainer = {
	marginLeft: "15%",
};

const styleTemplateContainer = {
	display: "flex",
	flexWrap: "wrap",
	width: "85%",
	margin: "auto",
	height: "70vh",
	marginTop: "2vh",
};

const styleScroll = {
	overflowY: "scroll",
	display: "flex",
	flexWrap: "wrap",
	width: "85%",
	margin: "auto",
	height: "70vh",
	marginTop: "2vh",
};

const styleImageHover = {
	cursor: "pointer",
	opacity: "0",
};

const styleCreative = {
	width: "275px",
	height: "275px",
	padding: "1.5rem",
	borderRadius: "35px",
	margin: "30px",
	hover: { styleImageHover },
};

const styleOuterContainerONE = {
	marginLeft: "0%",
};

const styleTemplateContainerFOURTOSIX = {
	display: "flex",
	flexWrap: "wrap",
	width: "85%",
	height: "70vh",
	marginTop: "2vh",
};

const styleButton = {
	marginRight: "10px",
	background: "rgb(132, 232, 244)",
	borderRadius: "100vh",
	fontFamily: "Open Sans",
	width: "16vh",
	textTransform: "none",
	height: "5vh",
	marginBottom: "3vh",
	textAlign: "center",
	fontSize: "12px",
};

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: 680,
		width: 400,
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

const modalButtonStyle = {
	backgroundColor: "rgba(132, 232, 244, 1)",
	fontFamily: "Open Sans",
	fontSize: "10px",
	textTransform: "none",
	height: "5vh",
	borderRadius: "25px",
	marginRight: "0.5vh",
	// marginRight: "20%"
};

const modalDropdownButtonStyle = {
	backgroundColor: "rgba(132, 232, 244, 1)",
	fontFamily: "Open Sans",
	textTransform: "none",
	height: "5vh",
	borderRadius: "0 25px 25px 0",
};

const modalLoadButtonStyle = {
	backgroundColor: "rgba(132, 232, 244, 1)",
	fontFamily: "Open Sans",
	textTransform: "none",
	height: "5vh",
	borderRadius: "25px",
	display: "none",
};

const iconStyle = {
	width: "20px",
	height: "20px",
};

class SavedCreatives extends Component {
	constructor(props) {
		super(props);
		this.postRef = React.createRef();
		this.loadRef = React.createRef();
	}

	state = {
		creatives: [],
		modalIsOpen: false,
		currentPostImage: null,
		postCaption: "",
		publishToInsta: true,
		publishToFB: true,
		insta_username: "",
		fb_page_name: "",
		is_facebook_linked: true,
		selectedScheduledDate: new Date(),
		fetchingCreatives: true,
	};

	handleScheduleDateTime = () => {
		this.setState({
			selectedScheduledDate: document.getElementById("datetimepicker").value,
		});
		console.log("selected date: ", this.state.selectedScheduledDate);
	};

	getSavedCreatives() {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/get_saved_creatives/`, { headers })
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				this.setState({
					creatives: data,
					fetchingCreatives: false,
				});
			});

		console.log(this.state.creatives);
	}

	handleDeleteButton = (e, creative_id, thumbnail_url) => {
		const { accessToken } = isAuthenticated();

		var requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				id: creative_id,
				url: thumbnail_url,
			}),
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/delete_saved_creative/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
			});

		// Delete current creative from state variable
		this.setState({
			creatives: this.state.creatives.filter(function (creative) {
				return creative.id != creative_id;
			}),
		});

		toast.success("Deleted", {
			position: "top-center",
			theme: "dark",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	handleDownloadButton = (e, thumbnail) => {
		toast.success("Downloaded", {
			position: "top-center",
			theme: "dark",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	openModal(e, thumbnail) {
		openModalService.sendMessage({
			modalState: "savedPost",
			stateVariables: {
				modalIsOpen: true,
				currentPostImage: thumbnail,
				imageIsUploaded: true,
				isSavedPost: true,
			},
		});

		// this.setState({
		//     modalIsOpen: true,
		//     currentPostImage: thumbnail
		// })
	}

	closeModal() {
		this.setState({
			modalIsOpen: false,
		});
	}

	handleCaptionText(e) {
		this.setState({
			postCaption: e.target.value,
		});
	}

	async SchedulePost() {
		const { accessToken } = isAuthenticated();
		var year = "";
		var month = "";
		var day = "";
		var hour = "";
		var minute = "";

		var occurence = 0;
		var temp = 0;
		var occ1_flag = true;
		var occ2_flag = true;
		var occ3_flag = true;
		var text = this.state.selectedScheduledDate;
		console.log("TEXT: ", this.state.selectedScheduledDate);

		for (var i = 0; i < text.length; i++) {
			if (text[i] === "/") occurence++;
			if (text[i] === " " && occurence === 2) occurence++;
			if (occurence === 1 && occ1_flag) {
				month = text.substring(temp, i);
				temp = i + 1;
				occ1_flag = false;
			}
			if (occurence === 2 && occ2_flag) {
				day = text.substring(temp, i);
				temp = i + 1;
				occ2_flag = false;
			}
			if (occurence === 3 && occ3_flag) {
				year = text.substring(temp, i);
				occ3_flag = false;
			}
		}

		let ind = text.indexOf(":");
		hour = parseInt(text.substring(ind - 2, ind));
		minute = parseInt(text.substring(ind + 1, ind + 3));
		if (text.indexOf("PM") != -1) hour = 12 + hour;

		console.log("Day: ", parseInt(day));
		console.log("Month: ", parseInt(month));
		console.log("Year: ", parseInt(year));
		console.log("hour: ", hour);
		console.log("minute: ", minute);
		console.log("caption: ", this.state.postCaption);
		console.log("photo_url: ", this.state.currentPostImage);

		var requestOptions_ScheduleFB = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				photo_url: this.state.currentPostImage,
				caption: this.state.postCaption,
				schedule_datetime: {
					year: parseInt(year),
					month: parseInt(month),
					day: parseInt(day),
					hour: hour,
					minute: minute,
					second: 0,
				},
			}),
		};
		var requestOptions_ScheduleInsta = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				media_type: "IMAGE",
				media_url: this.state.currentPostImage,
				caption: this.state.postCaption,
				schedule_datetime: {
					year: parseInt(year),
					month: parseInt(month),
					day: parseInt(day),
					hour: hour,
					minute: minute,
					second: 0,
				},
			}),
		};

		if (this.state.publishToFB && this.state.publishToInsta) {
			Promise.all([
				fetch(
					`${process.env.REACT_APP_BACKEND}/schedule_facebook_post/`,
					requestOptions_ScheduleFB
				).then((response) => response.json()),
				fetch(
					`${process.env.REACT_APP_BACKEND}/schedule_insta_post/`,
					requestOptions_ScheduleInsta
				).then((response) => response.json()),
			]).then(([insta_response, fb_response]) => {
				toast.success("Post Scheduled for Facebook and Instagram!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
				});
			});
		} else if (this.state.publishToInsta) {
			await fetch(
				`${process.env.REACT_APP_BACKEND}/schedule_insta_post/`,
				requestOptions_ScheduleInsta
			)
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if (data["error"]) {
						toast.error("Failed: " + data["error"].error_user_title, {
							position: "top-center",
							theme: "dark",
							autoClose: 2500,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					} else {
						toast.success("Post Scheduled for Instagram!", {
							position: "top-center",
							theme: "dark",
							autoClose: 2500,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					}

					this.setState({
						modalIsOpen: false,
					});
				});
		} else if (this.state.publishToFB) {
			await fetch(
				`${process.env.REACT_APP_BACKEND}/schedule_facebook_post/`,
				requestOptions_ScheduleFB
			)
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if (data["error"]) {
						toast.error("Failed: " + data["error"].error_user_title, {
							position: "top-center",
							theme: "dark",
							autoClose: 2500,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					} else {
						toast.success("Post Scheduled for FB!", {
							position: "top-center",
							theme: "dark",
							autoClose: 2500,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					}

					this.setState({
						modalIsOpen: false,
					});
				});
		}
	}

	async postToInsta(url, caption) {
		const { accessToken } = isAuthenticated();

		var requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				media_type: "IMAGE",
				media_url: url,
				caption: caption,
			}),
		};

		await fetch(
			`${process.env.REACT_APP_BACKEND}/publish_insta_post/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				if (data["error"]) {
					toast.error("Failed: " + data["error"].error_user_title, {
						position: "top-center",
						theme: "dark",
						autoClose: 2500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				} else {
					toast.success("Post Published To Instagram!", {
						position: "top-center",
						theme: "dark",
						autoClose: 2500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}

				this.setState({
					modalIsOpen: false,
				});
			});
	}

	async postToFB(url, caption) {
		const { accessToken } = isAuthenticated();

		var requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				photo_url: url,
				caption: caption,
			}),
		};

		fetch(`${process.env.REACT_APP_BACKEND}/publish_fb_post/`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				if (data["error"]) {
					toast.error("Failed: " + data["error"].error_user_title, {
						position: "top-center",
						theme: "dark",
						autoClose: 2500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				} else {
					toast.success("Post Published to Facebook!", {
						position: "top-center",
						theme: "dark",
						autoClose: 2500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}

				this.setState({
					modalIsOpen: false,
				});
			});
	}

	async postToInstaAndFB(url, caption) {
		const { accessToken } = isAuthenticated();

		var requestOptionsFB = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				photo_url: url,
				caption: caption,
			}),
		};

		var requestOptionsInsta = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				media_type: "IMAGE",
				media_url: url,
				caption: caption,
			}),
		};

		Promise.all([
			fetch(
				`${process.env.REACT_APP_BACKEND}/publish_fb_post/`,
				requestOptionsFB
			).then((response) => response.json()),
			fetch(
				`${process.env.REACT_APP_BACKEND}/publish_insta_post/`,
				requestOptionsInsta
			).then((response) => response.json()),
		]).then(([insta_response, fb_response]) => {
			toast.success("Post Published to Facebook and Instagram!", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			this.setState({
				modalIsOpen: false,
			});
		});
	}

	handlePostButton = async (e) => {
		if (!this.state.is_facebook_linked) {
			toast.info(
				"Link your account to Facebook on Dashboard to post on Social Media.",
				{
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}
			);

			this.setState({ modalIsOpen: false });
		}

		if (!this.state.publishToFB && !this.state.publishToInsta) {
			toast.info("Select atleast one platform to publish.", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			return;
		}

		this.postRef.current.style.display = "none";
		this.loadRef.current.style.display = "inline-block";

		if (this.state.publishToFB && this.state.publishToInsta) {
			this.postToInstaAndFB(
				this.state.currentPostImage,
				this.state.postCaption
			);
			return;
		}

		if (this.state.publishToFB) {
			this.postToFB(this.state.currentPostImage, this.state.postCaption);
			return;
		}

		if (this.state.publishToInsta) {
			this.postToInsta(this.state.currentPostImage, this.state.postCaption);
		}
	};

	handleScheduleButton(e) {
		console.log("In Schedule");
	}

	hadnleInstaButton() {
		if (this.state.publishToInsta) this.setState({ publishToInsta: false });
		else this.setState({ publishToInsta: true });
	}

	handleFacebookButon() {
		if (this.state.publishToFB) this.setState({ publishToFB: false });
		else this.setState({ publishToFB: true });
	}

	render = () => {
		return (
			<div>
				<h2 style={{ textAlign: "center", color: "white" }}>
					Your Saved Creatives
				</h2>
				<CircularProgress
					style={{
						marginLeft: "45%",
						marginTop: "20%",
						display: this.state.fetchingCreatives ? "block" : "none",
						color: "rgb(132,232,244)",
					}}
				></CircularProgress>
				<div
					style={
						this.state.creatives.length > 3
							? styleScroll
							: styleTemplateContainer
					}
				>
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							width: "100%",
							margin: "auto",
						}}
					>
						{this.state.creatives.reverse().map((creative) => {
							return (
								<div
									class="col-sm-4"
									style={{ width: "auto", margin: "auto" }}
									key={`$(creative.id)`}
								>
									<a href={creative.thumbnail}>
										<img className="styleImage" src={creative.thumbnail}></img>
									</a>
									<div style={{ textAlign: "center", padding: "10px" }}>
										<a href={creative.thumbnail}>
											<DownloadIcon
												onClick={(e) =>
													this.handleDownloadButton(e, creative.thumbnail)
												}
												style={{ color: "white", cursor: "pointer" }}
											/>
										</a>
										<EditIcon
											style={{ color: "white", cursor: "pointer" }}
											onClick={() => {
												toast.info("Coming soon !!", {
													position: "top-center",
													autoClose: 2000,
													hideProgressBar: true,
													closeOnClick: true,
													pauseOnHover: true,
													draggable: true,
													progress: undefined,
													theme: "dark",
												});
											}}
										/>
										<EventNoteIcon
											onClick={(e) => this.openModal(e, creative.thumbnail)}
											style={{ color: "white", cursor: "pointer" }}
										/>
										<DeleteIcon
											onClick={(e) =>
												this.handleDeleteButton(
													e,
													creative.id,
													creative.thumbnail
												)
											}
											style={{ color: "white", cursor: "pointer" }}
										/>
									</div>
								</div>
							);
						})}
					</div>

					<PostModal></PostModal>
					<Modal isOpen={this.state.modalIsOpen} style={modalStyles}>
						<div style={{ textAlign: "right" }}>
							<img
								src="https://img.icons8.com/external-kiranshastry-gradient-kiranshastry/64/000000/external-close-banking-and-finance-kiranshastry-gradient-kiranshastry.png"
								className="styleCloseButton"
								onClick={(e) => this.closeModal(e)}
							/>
						</div>
						<h4 style={{ color: "rgb(132, 232, 244)" }}>
							Post your Saved Creative
						</h4>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								marginTop: "3%",
							}}
						>
							<span
								style={{ marginRight: "3%", marginTop: "2%", color: "white" }}
							>
								Publish to
							</span>
							<button
								className={
									this.state.publishToInsta
										? "socialButton"
										: "socialButtonDisabled"
								}
								onClick={() => this.hadnleInstaButton()}
							>
								<span style={{ fontSize: "14px" }}>
									<img
										src="https://img.icons8.com/fluency/48/000000/instagram-new.png"
										style={iconStyle}
									/>
									{this.state.insta_username}
								</span>
							</button>
							<button
								className={
									this.state.publishToFB
										? "socialButton"
										: "socialButtonDisabled"
								}
								onClick={() => this.handleFacebookButon()}
							>
								<span style={{ fontSize: "14px" }}>
									<img
										src="https://img.icons8.com/fluency/48/000000/facebook-new.png"
										style={iconStyle}
									/>
									{this.state.fb_page_name}
								</span>
							</button>
						</div>
						<div style={{ marginTop: "5%" }}>
							<img
								src={this.state.currentPostImage}
								style={{ height: 300, width: 300, borderRadius: "5%" }}
							></img>
							<br></br>
							<br></br>
							<div>
								<textarea
									style={{
										width: 300,
										backgroundColor: "rgb(11, 17, 32)",
										color: "rgba(255, 255, 255, 1)",
										height: 125,
									}}
									placeholder="Caption"
									value={this.state.postCaption}
									onChange={(e) => this.handleCaptionText(e)}
								/>
							</div>
							<br></br>
							{/* <div class="dropdown">
                                                    <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" ref={this.postRef} style={modalButtonStyle}>
                                                        Post
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item" href="#" onClick={(e) => this.handlePostButton(e)}>Post Now</a></li>
                                                    <li><a class="dropdown-item" href="#">Schedule</a></li>
                                                    </ul>
                                                </div> */}

							<div style={{ display: "flex" }}>
								<div
									style={{
										backgroundColor: "white",
										paddingLeft: "0.5vh",
										paddingRight: "0.5vh",
										height: "5vh",
									}}
								>
									<DateTimePickerComponent
										id="datetimepicker"
										placeholder="Select a date and time"
										onChange={() => {
											this.handleScheduleDateTime();
										}}
									/>
								</div>
								<div
									class="btn-group"
									ref={this.postRef}
									style={{
										marginLeft: "1vh",
										marginBottom: "2vh",
										display: "flex",
									}}
								>
									<button
										type="button"
										class="btn btn-info hoverClass"
										style={modalButtonStyle}
										onClick={() => this.SchedulePost()}
									>
										Schedule
									</button>
									<button
										type="button"
										class="btn btn-info hoverClass"
										style={modalButtonStyle}
										onClick={(e) => this.handlePostButton(e)}
									>
										Post Now
									</button>
									{/* <button type="button" class="btn btn-info dropdown-toggle hoverClass" data-bs-toggle="dropdown" style={modalDropdownButtonStyle}>
                                                        <span class="sr-only"></span>
                                                    </button> */}
									{/* <ul class="dropdown-menu hoverClass2" onClick={(e) => this.handleScheduleButton(e)}>
                                                        <div class="dropdown-item"><span>Schedule</span></div>
                                                    </ul> */}
								</div>
								<button
									class="btn btn-info"
									type="button"
									ref={this.loadRef}
									style={modalLoadButtonStyle}
								>
									<span
										class="spinner-grow spinner-grow-sm"
										role="status"
										aria-hidden="true"
									></span>
									Posting...
								</button>
							</div>
						</div>
					</Modal>
				</div>
			</div>
		);
	};

	componentDidMount() {
		this.getSavedCreatives();
		var user_data = JSON.parse(localStorage.getItem("Erajwt"));
		this.setState({
			insta_username: user_data.insta_username,
			fb_page_name: user_data.fb_page_name,
			is_facebook_linked: user_data.is_facebook_linked,
		});
	}
}

export default SavedCreatives;
