import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import styles from "./ContactForm.module.css";
import logo from "./logo.png";
import myEraButton from "./button.png";

const ContactForm = () => {
	const [inputs, setInputs] = useState({});
	const [tncChecked, setTncChecked] = useState(false);
	const [colorChecked, setColorChecked] = useState(false);
	const [printChecked, setPrintChecked] = useState(false);
	const [sizeChecked, setSizeChecked] = useState(false);
	const [otherChecked, setOtherChecked] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);
	const [productType, setProductType] = useState(false);
	const [isStoreLive, setIsStoreLive] = useState(false);
	const [productName, setProductName] = useState("");
	const [description, setDescription] = useState("");
	const [specificProductType, setSpecificProductType] = useState("");
	const [specificCustomization, setSpecificCustomization] = useState("");

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInputs({ ...inputs, [name]: value });
	};
	const handleProductTypeChange = (event) => {
		setProductType(event.target.value);
	};

	//   const handleSpecificProductTypeChange = (event) => {
	// 	setSpecificProductType(event.target.value);
	//   };
	//   const handleYesNoOptionChange = (event) => {
	// 	setYesNoOption(event.target.value);
	//   };
	//   const handleOptionChange = (event) => {
	// 	const option = event.target.value;
	// 	if (selectedOptions.includes(option)) {
	// 	  setSelectedOptions(selectedOptions.filter((item) => item !== option));
	// 	} else {
	// 	  setSelectedOptions([...selectedOptions, option]);
	// 	}
	//   };

	//   const handleImageChange = (event) => {
	// 	const fileList = event.target.files;
	// 	const fileArray = Array.from(fileList);
	// 	setImages(fileArray);
	//   };
	if (productName) {
		document.getElementById("productName").style.border =
			"1px solid rgba(128, 128, 128, 0.4)";
	}
	if (productType) {
		document.getElementById("Select").style.border =
			"1px solid rgba(128, 128, 128, 0.4)";
	}
	if (specificProductType) {
		document.getElementById("SelectSpecific").style.border =
			"1px solid rgba(128, 128, 128, 0.4)";
	}
	if (description) {
		document.getElementById("description").style.border =
			"1px solid rgba(128, 128, 128, 0.4)";
	}
	if (specificCustomization) {
		document.getElementById("multiSelectSpecific").style.border =
			"1px solid rgba(128, 128, 128, 0.4)";
	}
	if (
		inputs.email &&
		inputs.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
	) {
		document.getElementById("email").style.border =
			"1px solid rgba(128, 128, 128, 0.4)";
	}
	if (inputs.phone && inputs.phone.match("^[0-9]{0,15}$")) {
		document.getElementById("phone").style.border =
			"1px solid rgba(128, 128, 128, 0.4)";
	}
	if (document.getElementById("images") != null) {
		document.getElementById("images").style.border =
			"1px solid rgba(128, 128, 128, 0.4)";
	}
	if (colorChecked || printChecked || sizeChecked || otherChecked) {
		document.getElementById("multiSelect").style.border =
			"1px solid rgba(128, 128, 128, 0.4)";
	}
	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSubmitting(true);

		try {
			if (!productName) {
				toast.error("Please fill the name of the product!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlock = document.getElementById("productName");
				errorBlock.scrollIntoView();
				errorBlock.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			} else if (!inputs.email) {
				toast.error("Please fill your email address!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlockEmail = document.getElementById("email");
				errorBlockEmail.scrollIntoView();
				errorBlockEmail.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			} else if (!inputs.phone) {
				toast.error("Please fill your phone number", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlockPhone = document.getElementById("phone");
				errorBlockPhone.scrollIntoView();
				errorBlockPhone.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			} else if (!tncChecked) {
				toast.error("Please accept the terms and conditions!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			} else if (
				!inputs.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
			) {
				toast.error("Please enter a valid email address!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlockEmailValid = document.getElementById("email");
				errorBlockEmailValid.scrollIntoView();
				errorBlockEmailValid.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			} else if (!inputs.phone.match("^[0-9]{0,15}$")) {
				toast.error("Please enter a valid phone number!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlockPhoneValid = document.getElementById("phone");
				errorBlockPhoneValid.scrollIntoView();
				errorBlockPhoneValid.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			} else if (!productType) {
				toast.error("Please select a product type!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlockType = document.getElementById("Select");
				errorBlockType.scrollIntoView();
				errorBlockType.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			} else if (productType === "Other" && !specificProductType) {
				toast.error("Please enter a specific product type!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlockTypeSpecific = document.getElementById("SelectSpecific");
				errorBlockTypeSpecific.scrollIntoView();
				errorBlockTypeSpecific.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			} else if (!description) {
				toast.error("Please enter a description!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlockDescription = document.getElementById("description");
				errorBlockDescription.scrollIntoView();
				errorBlockDescription.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			} else if (otherChecked && !specificCustomization) {
				toast.error("Please enter a specific customization!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlockCustomizationSpecific = document.getElementById(
					"multiSelectSpecific"
				);
				errorBlockCustomizationSpecific.scrollIntoView();
				errorBlockCustomizationSpecific.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			} else if (!document.getElementById("images").files.length) {
				toast.error("Please upload an image!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlockImage = document.getElementById("images");
				errorBlockImage.scrollIntoView();
				errorBlockImage.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			} else if (
				!colorChecked &&
				!printChecked &&
				!sizeChecked &&
				!otherChecked
			) {
				toast.error("Please select at least one customization option!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				var errorBlockCustomization = document.getElementById("multiSelect");
				errorBlockCustomization.scrollIntoView();
				errorBlockCustomization.style.border = "2px solid red";
				setIsSubmitting(false);
				return;
			}
			const productDetails = new FormData();
			productDetails.append("product_name", productName);
			if (productType === "Other") {
				productDetails.append("product_type", specificProductType);
			} else {
				productDetails.append("product_type", productType);
			}

			const images = document.getElementById("images").files;
			for (let i = 0; i < images.length; i++) {
				productDetails.append("shopify_form_images", images[i]);
			}
			productDetails.append("product_description", description);
			productDetails.append(
				"form_is_store_live",
				isStoreLive ? "true" : "false"
			);
			if (colorChecked) {
				productDetails.append("product_customization", "Color");
			}
			if (printChecked) {
				productDetails.append("product_customization", "Print");
			}
			if (sizeChecked) {
				productDetails.append("product_customization", "Size");
			}
			if (otherChecked) {
				productDetails.append("product_customization", specificCustomization);
			}
			productDetails.append("form_email", inputs.email);
			productDetails.append("form_number", inputs.phone);

			await axios.post(
				"https://django.myera.pro/quarkme/api/create_customization_request/",
				productDetails
			);

			// setInputs({});

			toast.success("Thank you for your interest!", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				pauseOnHover: false,
				theme: "colored",
			});

			setShowThankYou(true);
			setIsSubmitting(false);
		} catch (err) {
			console.log(err);
			toast.error("Something went wrong, please try again later.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				pauseOnHover: false,
				theme: "colored",
			});
			setIsSubmitting(false);
		}
	};

	return (
		<div className={styles.body} id="contact">
			<div className={[styles.heading].join(" ")}>
				<h1>
					<img src={myEraButton} className={styles.button} alt="myEra Button" />
				</h1>
				<img src={logo} className={styles.img} alt="MyEra Logo" />
				<h2>A product customizer for your shopify store</h2>
				<hr></hr>
				<h2>
					Please fill the form below to get started. We promise to get back to
					you within 48 hours.
				</h2>
			</div>
			{showThankYou ? (
				<div className={styles.thank_you_block}>
					<h1>Thank you for letting us know your requirement!</h1>
				</div>
			) : (
				<div className={styles.form}>
					<div className={styles.form_title}>Please fill your Information:</div>
					<div className={styles.floating_label_group}>
						<input
							name="Product Name"
							type="text"
							value={productName}
							required={true}
							onChange={(e) => setProductName(e.target.value)}
							id="productName"
						/>
						<label className={styles.floating_label}>Product Name</label>
					</div>
					<div className={styles.floating_label_group}>
						<label className={styles.multi_label}>Product Type</label>
						<div>
							<select
								value={productType}
								onChange={handleProductTypeChange}
								id="Select"
							>
								<option value="">-- Select Product Type --</option>
								<option value="Watch">Watch</option>
								<option value="Socks">Socks</option>
								<option value="Pillow">Pillow</option>
								<option value="T-shirt">T-Shirt</option>
								<option value="Mug">Mug</option>
								<option value="Bottle">Bottle</option>
								<option value="Hoodie">Hoodie</option>
								<option value="Card">Business Card</option>
								<option value="Other">Other</option>
							</select>
						</div>
					</div>
					{productType === "Other" && (
						<div className={styles.floating_label_group}>
							<input
								name="Specific Product Type"
								type="text"
								value={specificProductType}
								required={true}
								onChange={(e) => setSpecificProductType(e.target.value)}
								id="SelectSpecific"
							/>
							<label className={styles.floating_label}>
								Specific Product Type
							</label>
						</div>
					)}
					<div className={styles.floating_label_group}>
						<div>
							<label className={styles.multi_label}>
								What all is Customizable
							</label>
						</div>
						<div className={styles.multiSelect} id="multiSelect">
							<div>
								<input
									type="checkbox"
									className={styles.input_field}
									checked={colorChecked}
									onChange={(e) => setColorChecked(e.target.checked)}
									style={{
										cursor: "pointer",
										display: "inline-block",
										width: "16px",
										marginRight: "8px",
									}}
								/>
								<span className={styles.span}>Color</span>
							</div>
							<div>
								<input
									type="checkbox"
									className={styles.input_field}
									checked={printChecked}
									onChange={(e) => setPrintChecked(e.target.checked)}
									style={{
										cursor: "pointer",
										display: "inline-block",
										width: "16px",
										marginRight: "8px",
									}}
								/>
								<span className={styles.span}>Print</span>
							</div>
							<div>
								<input
									type="checkbox"
									className={styles.input_field}
									checked={sizeChecked}
									onChange={(e) => setSizeChecked(e.target.checked)}
									style={{
										cursor: "pointer",
										display: "inline-block",
										width: "16px",
										marginRight: "8px",
									}}
								/>
								<span className={styles.span}>Size</span>
							</div>
							<div>
								<input
									type="checkbox"
									className={styles.input_field}
									checked={otherChecked}
									onChange={(e) => setOtherChecked(e.target.checked)}
									style={{
										cursor: "pointer",
										display: "inline-block",
										width: "16px",
										marginRight: "8px",
									}}
								/>
								<span className={styles.span}>Other</span>
							</div>
						</div>
					</div>
					{otherChecked && (
						<div className={styles.floating_label_group}>
							<input
								name="specific customization"
								type="text"
								value={specificCustomization}
								required={true}
								onChange={(e) => setSpecificCustomization(e.target.value)}
								id="multiSelectSpecific"
							/>
							<label className={styles.floating_label}>
								Specific Customization
							</label>
						</div>
					)}
					<div className={styles.floating_label_group}>
						<input
							name="image"
							type="file"
							id="images"
							multiple
							accept="image/*"
						/>
						<label className={styles.floating_label}>Image</label>
					</div>

					<div className={styles.floating_label_group}>
						<input
							name="email"
							type="text"
							value={inputs.email || ""}
							required={true}
							onChange={handleChange}
							id="email"
						/>
						<label className={styles.floating_label}>Email</label>
					</div>
					<div className={styles.floating_label_group}>
						<input
							name="phone"
							type="number"
							min={0}
							maxLength={15}
							value={inputs.phone || ""}
							required={true}
							onChange={handleChange}
							id="phone"
						/>
						<label className={styles.floating_label}>Phone</label>
					</div>
					<div className={styles.floating_label_group}>
						<textarea
							name="Description"
							type="text"
							value={description}
							required={true}
							style={{
								resize: "none",
								width: "calc(100% - 16px)",
								height: "100px",
								marginRight: "8px",
							}}
							onChange={(e) => setDescription(e.target.value)}
							id="description"
						/>
						<label className={styles.floating_label}>Description</label>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						<input
							type="checkbox"
							className={styles.input_field}
							checked={isStoreLive}
							onChange={(e) => setIsStoreLive(e.target.checked)}
							style={{
								cursor: "pointer",
								display: "inline-block",
								width: "16px",
								marginRight: "8px",
							}}
						/>
						<span className={styles.span}>My store is live on shopify.</span>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							flexDirection: "row",
						}}
					>
						<input
							type="checkbox"
							className={styles.input_field}
							checked={tncChecked}
							onChange={(e) => setTncChecked(e.target.checked)}
							style={{
								cursor: "pointer",
								display: "inline-block",
								width: "16px",
								marginRight: "8px",
							}}
						/>
						<span className={styles.span}>
							I hereby accept all terms and conditions.
						</span>
					</div>
					<button
						className={styles.submit_button}
						onClick={isSubmitting ? () => {} : handleSubmit}
					>
						{isSubmitting ? (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "row",
								}}
							></div>
						) : (
							"Submit"
						)}
					</button>
				</div>
			)}
			<div className={[styles.heading].join(" ")}>
				<h6>
					In case of queries, please reach out to us at{" "}
					<a href="mailto:support@myer.pro">support email</a>
					(support@myera.pro).
				</h6>
			</div>
		</div>
	);
};

export default ContactForm;
