import React, { useState, useEffect } from "react";
import styles from "./choose_templates.module.css";
import { isAuthenticated } from "../../../../../Helper/Endpoints/Endpoints";
import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { ArrowBack } from "@material-ui/icons";

const ChooseTemplates = ({ children, ...props }) => {
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState();
	const [productName, setProductName] = useState("");

	useEffect(() => {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/get_product_templates/`, {
			headers,
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				setTemplates(data.templates || []);
			});
	}, []);

	function createProduct() {
		var userProductData = JSON.parse(localStorage.getItem("userProductData"));

		if (!selectedTemplate) {
			toast.error("Select a template to create product", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			return;
		}

		if (!productName) {
			toast.error("Enter product name to create product", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			return;
		}

		userProductData.template_id = selectedTemplate.id;
		userProductData.product_name = productName;

		const { accessToken } = isAuthenticated();

		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(userProductData),
		};

		fetch(`${process.env.REACT_APP_BACKEND}/create_product/`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);

				toast.success("Product created successfully", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				props.onNext();
			});
	}

	return (
		<>
			<div className={styles.mainContainer}>
				<div className={styles.header}>
					<div className={styles.heading}>Choose Product Template</div>
					<button
						className={styles.header_button}
						onClick={() => {
							toast.info("Coming Soon!", {
								position: "top-center",
								theme: "dark",
								autoClose: 2500,
								hideProgressBar: true,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
							});
						}}
					>
						+ New Template
					</button>
				</div>
				<div className={styles.imageContainer}>
					{/* <div> */}
					{templates.map((template) => {
						return (
							<img
								className={
									selectedTemplate && template.id === selectedTemplate.id
										? styles.selectedStyle
										: styles.imageStyle
								}
								src={template.url}
								onClick={() => setSelectedTemplate(template)}
							></img>
						);
					})}
					{/* </div> */}
				</div>
				<div className={styles.text_field_row}>
					<TextField
						label="Product name"
						value={productName}
						onChange={(e) => setProductName(e.target.value)}
					></TextField>
					<button onClick={() => createProduct()}>Create Product</button>
					<ArrowBack
						className={styles.backArrow}
						onClick={() => props.onBack()}
					></ArrowBack>
				</div>
			</div>
		</>
	);
};

export default ChooseTemplates;
