import React, { useEffect, useRef, useState } from "react";
import commonStyles from "../MyeraScript.module.css";
import styles from "./Blackberrys.module.css";
import Stage from "../Common/Stage/Stage";
import HorizontalCarousel from "../Common/HorizontalCarousel/HorizontalCarousel";
import axios from "axios";
import { toast } from "react-toastify";
import { TabType } from "../../../Helper/enums";
import SingleImageLayer from "../Common/Stage/Layers/SingleImageLayer";
import MultiImageLayer from "../Common/Stage/Layers/MultiImageLayer";
import MultiTextLayer from "../Common/Stage/Layers/MultiTextLayer";
import SingleImageTab from "../Common/Tabs/SingleImageTab";
import MultiImageTab from "../Common/Tabs/MultiImageTab";
import MultiTextTab from "../Common/Tabs/MultiTextTab";
import produce from "immer";
import { Button } from "@mui/material";
import ga4 from "react-ga4";

const Blackberrys = () => {
	const [ product, setProduct ] = useState({});
	const [ tabs, setTabs ] = useState([]);
	const [ selectedTab, setSelectedTab ] = useState(0);

	const stageRef = useRef(null);

	const fetchProduct = async () => {
		try {
			const res = await axios.get(
				"https://django.myera.pro/myera/api/bb/product/123"
			);
			console.log(res.data);
			setProduct(res.data);

			ga4.event("view_item", {
				currency: "INR",
				value: 30.03,
				items: [
					{
						item_id: 123,
						item_name: res.data.name,
						affiliation: "Blackberrys",
					},
				],
			});

			const productLayers = [];

			for (let singleImageTab of res.data.template.single_image_tabs) {
				productLayers.push({
					id: singleImageTab.id,
					name: singleImageTab.name,
					type: TabType.SingleImage,
					priority: singleImageTab.priority,
					element: singleImageTab.image_element,
					images: singleImageTab.images,
					selectedImage: null,
					isUploadable: singleImageTab.is_uploadable,
					scale: 1,
					uploadedImages: [],
				});
			}

			for (let multiImageTab of res.data.template.multi_image_tabs) {
				productLayers.push({
					id: multiImageTab.id,
					name: multiImageTab.name,
					type: TabType.MultiImage,
					priority: multiImageTab.priority,
					elements: multiImageTab.image_elements,
					images: multiImageTab.images,
					selectedImages: new Array(multiImageTab.image_elements.length).fill(
						null
					),
					isUploadable: multiImageTab.is_uploadable,
					uploadedImages: [],
				});
			}

			for (let multiTextTab of res.data.template.multi_text_tabs) {
				productLayers.push({
					id: multiTextTab.id,
					name: multiTextTab.name,
					type: TabType.MultiText,
					priority: multiTextTab.priority,
					elements: multiTextTab.text_elements,
					text: multiTextTab.text_elements[ 0 ].default_text,
					fontSize: multiTextTab.font_size_options[ 0 ],
					fontSizeOptions: multiTextTab.font_size_options,
					fontColor: multiTextTab.font_color_options[ 0 ],
					fontColorOptions: multiTextTab.font_color_options,
					fontFamily: multiTextTab.font_family_options[ 0 ],
					fontFamilyOptions: multiTextTab.font_family_options,
				});
			}

			for (let fixedImageElement of res.data.template.fixed_image_elements) {
				productLayers.push({
					id: fixedImageElement.id,
					name: fixedImageElement.name,
					type: TabType.FixedImage,
					priority: fixedImageElement.priority,
					element: fixedImageElement,
					selectedImage: fixedImageElement.default_image.asset,
				});
			}

			productLayers.sort((a, b) => a.priority - b.priority);

			setTabs(productLayers);
		} catch (err) {
			console.log(err);
			toast.error("Something went wrong");
		}
	};

	const handleStageExport = () => {
		if (stageRef.current) {
			const uri = stageRef.current.toDataURL();
			return uri;
		}
	};

	const getS3URL = async (uri) => {
		try {
			// Convert uri to file
			const data = uri.split(",")[ 1 ];
			const byteString = atob(data);
			const mimeString = "image/png";
			const ab = new ArrayBuffer(byteString.length);
			const ia = new Uint8Array(ab);
			for (let i = 0; i < byteString.length; i++) {
				ia[ i ] = byteString.charCodeAt(i);
			}
			const file = new Blob([ ab ], { type: mimeString });

			// Upload to S3
			const res = await axios.post(
				"https://django.myera.pro/myera/api/bb/s3/upload",
				{
					file: file,
				}
			);
			console.log(res.data);
			return res.data.url;
		} catch (err) {
			console.log(err);
			toast.error("Something went wrong");
		}
	};

	const getProductProperties = async () => {
		const data = {};
		data.preview = handleStageExport();

		for (let tab of tabs) {
			if (tab.type === TabType.SingleImage) {
				if (tab.selectedImage.startsWith("https://")) {
					data[ `${tab.name} Image` ] = tab.selectedImage;
				} else {
					const url = await getS3URL(tab.selectedImage);
					data[ `${tab.name} Image` ] = url;
				}
			} else if (tab.type === TabType.MultiImage) {
				const images = [];
				for (let image of tab.selectedImages) {
					if (image.startsWith("https://")) {
						images.push(image);
					} else {
						const url = await getS3URL(image);
						images.push(url);
					}
				}
				for (let i = 0; i < images.length; i++) {
					data[ `${tab.name} Image ${i + 1}` ] = images[ i ];
				}
			} else if (tab.type === TabType.MultiText) {
				data[ `${tab.name} Text` ] = tab.text;
				data[ `${tab.name} Size` ] = tab.fontSize;
				data[ `${tab.name} Color` ] = tab.fontColor;
				data[ `${tab.name} Font` ] = tab.fontFamily;
			}

			return data;
		}
	};

	const handleAddToCart = async () => {
		const data = await getProductProperties();
		console.log(data);
	};

	const handleBuyNow = async () => {
		const data = await getProductProperties();
		console.log(data);
	};

	const renderTab = () => {
		if (tabs.length === 0) {
			return <></>;
		}
		const tab = tabs[ selectedTab ];
		switch (tab.type) {
			case TabType.SingleImage:
				return (
					<SingleImageTab
						images={ tab.images }
						selectedImage={ tab.selectedImage }
						uploadedImages={ tab.uploadedImages }
						onSelectImage={ (image) => {
							setTabs(
								produce(tabs, (draft) => {
									draft[ selectedTab ].selectedImage = image;
								})
							);
						} }
						isUploadable={ tab.isUploadable }
						setUploadedImages={ (images) => {
							console.log(images);
							setTabs((prev) => {
								return produce(prev, (draft) => {
									draft[ selectedTab ].uploadedImages = images;
								});
							});
						} }
						scale={ tab.scale }
						onScaleChange={ (scale) => {
							setTabs(
								produce(tabs, (draft) => {
									draft[ selectedTab ].scale = scale;
								})
							);
						} }
					/>
				);
			case TabType.MultiImage:
				return (
					<MultiImageTab
						images={ tab.images }
						selectedImages={ tab.selectedImages }
						isUploadable={ tab.isUploadable }
						uploadedImages={ tab.uploadedImages }
						onSelectImage={ (image) => {
							setTabs((prev) => {
								return produce(prev, (draft) => {
									if (draft[ selectedTab ].selectedImages.includes(image)) {
										const idx =
											draft[ selectedTab ].selectedImages.indexOf(image);
										draft[ selectedTab ].selectedImages[ idx ] = null;
									} else {
										if (draft[ selectedTab ].selectedImages.includes(null)) {
											const idx =
												draft[ selectedTab ].selectedImages.indexOf(null);
											draft[ selectedTab ].selectedImages[ idx ] = image;
										} else {
											toast.error(
												"You can only select " +
												tab.selectedImages.length +
												" images"
											);
										}
									}
								});
							});
						} }
						setUploadedImages={ (images) => {
							setTabs((prev) => {
								return produce(prev, (draft) => {
									draft[ selectedTab ].uploadedImages = images;
								});
							});
						} }
					/>
				);
			case TabType.MultiText:
				return (
					<MultiTextTab
						key={ `multi_text_${tab.id}` }
						fontColor={ tab.fontColor }
						fontColorOptions={ tab.fontColorOptions }
						fontFamily={ tab.fontFamily }
						fontFamilyOptions={ tab.fontFamilyOptions }
						fontSize={ tab.fontSize }
						fontSizeOptions={ tab.fontSizeOptions }
						text={ tab.text }
						setText={ (text) => {
							setTabs(
								produce(tabs, (draft) => {
									draft[ selectedTab ].text = text;
								})
							);
						} }
						setFontColor={ (color) => {
							setTabs(
								produce(tabs, (draft) => {
									draft[ selectedTab ].fontColor = color;
								})
							);
						} }
						setFontFamily={ (family) => {
							setTabs(
								produce(tabs, (draft) => {
									draft[ selectedTab ].fontFamily = family;
								})
							);
						} }
						setFontSize={ (size) => {
							setTabs(
								produce(tabs, (draft) => {
									draft[ selectedTab ].fontSize = size;
								})
							);
						} }
					/>
				);
		}
	};

	useEffect(() => {
		fetchProduct();
	}, []);

	return (
		<div
			className={ [ commonStyles.main_container, styles.main_container ].join(" ") }
		>
			<div className={ [ commonStyles.preview, styles.preview ].join(" ") }>
				<HorizontalCarousel updateCarouselRef={ () => { } }>
					<Stage
						stageRef={ stageRef }
						layers={ tabs.map((layer, idx) => {
							if (layer.type === TabType.SingleImage) {
								return (
									<SingleImageLayer
										key={ idx }
										element={ layer.element }
										selectedImage={ layer.selectedImage }
										scale={ layer.scale }
									/>
								);
							} else if (layer.type === TabType.MultiImage) {
								return (
									<MultiImageLayer
										key={ idx }
										elements={ layer.elements }
										selectedImages={ layer.selectedImages }
									/>
								);
							} else if (layer.type === TabType.MultiText) {
								return (
									<MultiTextLayer
										key={ idx }
										elements={ layer.elements }
										text={ layer.text }
										fontSize={ layer.fontSize }
										fontColor={ layer.fontColor }
										fontFamily={ layer.fontFamily }
									/>
								);
							} else if (layer.type === TabType.FixedImage) {
								console.log("FIXED IMAGE", layer);
								return (
									<SingleImageLayer
										key={ idx }
										element={ layer.element }
										selectedImage={ layer.selectedImage }
									/>
								);
							}
						}) }
					/>
					[]
				</HorizontalCarousel>
			</div>
			<div className={ [ commonStyles.elements, styles.elements ].join(" ") }>
				<div
					className={ [ commonStyles.product_title, styles.product_title ].join(
						" "
					) }
				>
					{ product.name }
				</div>
				<div
					className={ [
						commonStyles.product_description,
						styles.product_description,
					].join(" ") }
				>
					{ product.description }
				</div>
				<div
					className={ [ commonStyles.elements_header, styles.element_header ].join(
						" "
					) }
				>
					{ tabs
						.filter((tab) => tab.type != TabType.FixedImage)
						.map((tab, idx) => (
							<div
								className={ [
									commonStyles.element_title,
									styles.element_title,
								].join(" ") }
								onClick={ () => {
									setSelectedTab(idx);
								} }
							>
								{ tab.name }
							</div>
						)) }
				</div>
				{ renderTab() }
				<div className={ [ commonStyles.cta, styles.cta ].join("") }>
					<Button>Add To Cart</Button>
					<Button>Buy Now</Button>
				</div>
			</div>
		</div>
	);
};

export default Blackberrys;
