import { TextField, MenuItem } from "@mui/material";
import React from "react";
import styles from "./tabs.module.css";

const MultiTextTab = ({
	text,
	placeholder,
	setText,
	fontColorOptions,
	fontColor,
	setFontColor,
	fontSizeOptions,
	fontSize,
	setFontSize,
	fontFamilyOptions,
	fontFamily,
	setFontFamily,
	characterLimit,
	multiline,
	showSingleOption = true,
	exportImage = true,
	heading = "Text Options",
	onLoseFocus = null,
}) => {
	return (
		<div className={styles.tab_container}>
			<div className={styles.option_heading}>
				<span>{heading}</span>
				{characterLimit && (
					<span className={styles.text_limit}>
						{" "}
						{text?.length ?? 0} / {characterLimit}
					</span>
				)}
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				<TextField
					variant="outlined"
					label="Text"
					value={text}
					placeholder={placeholder}
					onChange={(e) => setText(e.target.value)}
					fullWidth
					margin="normal"
					multiline={multiline}
					inputProps={{
						maxlength: characterLimit,
					}}
					sx={{
						marginRight: "10px",
					}}
					onBlur={()=>{onLoseFocus(text)}}
				/>
				{fontSizeOptions.length > 0 && (
					<TextField
						select
						variant="outlined"
						label="Font Size"
						value={fontSize}
						onChange={(e) => setFontSize(e.target.value)}
						fullWidth
						margin="normal"
					>
						{fontSizeOptions.map((option) => (
							<MenuItem key={option} value={option}>
								{option}
							</MenuItem>
						))}
					</TextField>
				)}
			</div>
			{(fontColorOptions.length > 1 ||
				(fontColorOptions.length === 1 && showSingleOption)) && (
				<span className={styles.option_heading}>Font Color</span>
			)}
			{(fontColorOptions.length > 1 ||
				(fontColorOptions.length === 1 && showSingleOption)) && (
				<div className={styles.options}>
					{fontColorOptions.map((option) => (
						<div
							key={option}
							className={[
								styles.color_option,
								fontColor === option && styles.selected,
							].join(" ")}
							style={{
								backgroundColor: option,
							}}
							onClick={() => setFontColor(option)}
						></div>
					))}
				</div>
			)}
			{(fontFamilyOptions.length > 1 ||
				(fontFamilyOptions.length === 1 && showSingleOption)) && (
				<span className={styles.option_heading}>Font Family</span>
			)}
			{(fontFamilyOptions.length > 1 ||
				(fontFamilyOptions.length === 1 && showSingleOption)) && (
				<div className={styles.options}>
					{fontFamilyOptions.map((option) => (
						<div
							key={option}
							className={[
								styles.font_option,
								fontFamily === option && styles.selected,
							].join(" ")}
							style={{
								fontFamily: option,
							}}
							onClick={() => setFontFamily(option)}
						>
							{option}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default MultiTextTab;
