import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import ProductCreate from '../../Create/ProductCreate';
import styles from '../../Create/product_create.module.css';

const Preview = (props) => {

    useEffect(() => {
        console.log(props)
        localStorage.setItem('product', props.product)
    }, [])
    

    return (
        <>
            <h1>Preview</h1>
            <Link to="/create-product" target="_blank">
                <button >Open Preview</button>
            </Link>
        </>
    )
}

export default Preview;