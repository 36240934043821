import React from "react";
import { Layer } from "react-konva";
import TextElement from "../Elements/TextElement";

const MultiTextLayer = ({
	elements,
	text,
	fontColor,
	fontSize,
	fontFamily,
	exportLayer = true,
}) => {
	return (
		<Layer className={!exportLayer ? "myera-layer-ignore" : ""}>
			{elements.map((element, idx) => {
				return (
					<TextElement
						x={element.x}
						y={element.y}
						width={element.width}
						height={element.height}
						rotation={element.rotation}
						id={idx.toString()}
						text={text || elements[0].default_text}
						onSelect={() => {}}
						onTransformEnd={() => {}}
						draggable={element.is_movable}
						listening={element.is_movable}
						updateElementRefs={(ref) => {}}
						fontSize={fontSize}
						fontColor={fontColor}
						fontFamily={fontFamily}
						alignment={element.alignment}
					></TextElement>
				);
			})}
		</Layer>
	);
};

export default MultiTextLayer;
