import React, { useEffect } from "react";
import "./ScriptTrial.css";

const ScriptTrial = () => {
	function initMyeraScript() {
		function onMessage(event) {
			// Check sender origin to be trusted
			var data = event.data;

			if (typeof window[data.func] == "function") {
				window[data.func].call(null, data.message);
			}
		}

		window.buyNow = function (data) {
			console.log("IN BUY NOW", data);
			// Add your code for add to cart here
		};

		if (window.addEventListener) {
			console.log("IN EVENT LISTNER");
			window.addEventListener("message", onMessage, false);
		} else if (window.attachEvent) {
			console.log("IN EVENT LISTNER");
			window.attachEvent("onmessage", onMessage, false);
		}
	}

	useEffect(() => {
		// After your initialisations
		initMyeraScript();
	}, []);

	return (
		<div>
			{/* Add your HTML here */}
			<iframe
				id="myera_create_iframe"
				src="https://myera.pro/myerascript/floweraura/?hamper_id=9078821552436"
				title="MyEra FlowerAura Journey"
				className="myera_create_iframe"
			></iframe>
		</div>
	);
};

export default ScriptTrial;
