import { Slider } from "@mui/material";
import React, { useState } from "react";
import styles from "./tabs.module.css";
import { SearchRounded } from "@material-ui/icons";
import ImageUploadModal from "../ImageUploadModal/ImageUploadModal";
import ImageCaptureModal from "../ImageCaptureModal/ImageCaptureModal";
import OptionDialog from "../OptionDialog/OptionDialog";

const SingleImageTab = ({
	onSelectImage,
	selectedImage,
	images,
	isUploadable,
	setUploadedImages,
	uploadedImages,
	scale,
	onScaleChange,
	allowCameraUpload,
	guideline,
	exportImage = true,
	showResultCount = true,
}) => {
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
	const [isCaptureModalOpen, setIsCaptureModalOpen] = useState(false);
	const [showOptionDialog, setShowOptionDialog] = useState(false);

	function onCropperSave(images) {
		setUploadedImages(images);
		setIsUploadModalOpen(false);
	}

	const handleUploadClick = () => {
		if (allowCameraUpload) {
			setShowOptionDialog(true);
		} else {
			document.querySelector("#myera__script__upload__button").click();
		}
	};

	const handleCaptureSubmit = (image) => {
		setUploadedImages([image]);
	};

	return (
		<div className={styles.tab_container}>
			<div className={styles.upload_row}>
				{showResultCount && (
					<div>
						{images.length + uploadedImages.length} result{""}
						{images.length + uploadedImages.length === 1 ? "" : "s"}
					</div>
				)}
				{isUploadable && (
					<input
						type="file"
						id="myera__script__upload__button"
						hidden
						multiple
						accept="image/*"
						onChange={(e) => {
							const files = e.target.files;
							const newFiles = [];
							for (let file of files) {
								newFiles.push(URL.createObjectURL(file));
							}
							setUploadedImages([...uploadedImages, ...newFiles]);
							setIsUploadModalOpen(true);
						}}
					/>
				)}
				{isUploadable && (
					<button
						className={[styles.button, styles.secondary].join(" ")}
						onClick={handleUploadClick}
					>
						Upload Image
					</button>
				)}
			</div>

			<div className={styles.image_list}>
				{uploadedImages.map((image) => (
					<div
						style={{
							position: "relative",
						}}
						className={[
							styles.image_container,
							image === selectedImage?.image && styles.selected_image,
						].join(" ")}
					>
						<img
							src={image}
							alt="uploaded"
							onClick={() => {
								onSelectImage({
									image,
									thumbnail: image,
								});
							}}
							className={[styles.image].join(" ")}
						/>
					</div>
				))}
			</div>
			{uploadedImages.length > 0 && (
				<div className={styles.slider_container}>
					<SearchRounded />
					<Slider
						disabled={
							uploadedImages.includes(selectedImage.image) ? false : true
						}
						sx={{
							marginLeft: "8px",
							root: {
								color: "var(--myera-script-primary-color)",
							},
							"& .MuiSlider-thumb": {
								backgroundColor: "var(--myera-script-primary-color)",
								border: "2px solid var(--myera-script-primary-color)",
							},
							"& .MuiSlider-track": {
								backgroundColor: "var(--myera-script-primary-color)",
							},
							"& .MuiSlider-rail": {
								backgroundColor: "var(--myera-script-primary-color)",
							},
							"&.Mui-disabled": {
								color: "#bdbdbd",
								"& .MuiSlider-thumb": {
									backgroundColor: "#bdbdbd",
									border: "2px solid #bdbdbd",
								},
								"& .MuiSlider-track": {
									backgroundColor: "#bdbdbd",
								},
								"& .MuiSlider-rail": {
									backgroundColor: "#bdbdbd",
								},
							},
						}}
						value={scale}
						onChange={(e, val) => {
							onScaleChange(val);
						}}
						min={0}
						max={2}
						step={0.05}
					/>
				</div>
			)}
			{uploadedImages.length > 0 && images.length > 0 && <hr />}
			<div className={styles.image_list}>
				{images.map((image) => (
					<div
						style={{
							position: "relative",
						}}
						className={[
							styles.image_container,
							(image.image?.asset || image.asset) === selectedImage?.image &&
								styles.selected_image,
						].join(" ")}
					>
						<img
							src={
								image.thumbnail?.asset ||
								image.image?.asset ||
								image.thumbnail ||
								image.asset
							}
							alt="uploaded"
							onClick={() => {
								onSelectImage({
									image: image.image?.asset || image.asset,
									thumbnail:
										image.thumbnail?.asset ||
										image.image?.asset ||
										image.thumbnail ||
										image.asset,
									label: image.label,
								});
							}}
							className={[styles.image].join(" ")}
						/>
						{image.label && (
							<div className={styles.image_label}>{image.label}</div>
						)}
					</div>
				))}
			</div>
			<ImageUploadModal
				isOpen={isUploadModalOpen}
				setIsOpen={setIsUploadModalOpen}
				images={uploadedImages}
				state={"cropper"}
				onCropperSave={onCropperSave}
			></ImageUploadModal>
			<ImageCaptureModal
				isOpen={isCaptureModalOpen}
				setIsOpen={setIsCaptureModalOpen}
				guideline={guideline}
				onSubmit={handleCaptureSubmit}
			/>
			<OptionDialog
				open={showOptionDialog}
				onClose={() => {
					setShowOptionDialog(false);
				}}
				onCaptureFromCamera={() => {
					setShowOptionDialog(false);
					setIsCaptureModalOpen(true);
				}}
				onUploadFromGallery={() => {
					setShowOptionDialog(false);
					document.querySelector("#myera__script__upload__button").click();
				}}
			/>
		</div>
	);
};

export default SingleImageTab;
