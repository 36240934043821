import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import styles from "./tabs.module.css";
import ImageUploadModal from "../ImageUploadModal/ImageUploadModal";

const MultiImageTab = ({
	onSelectImage,
	selectedImages,
	images,
	isUploadable,
	setUploadedImages,
	uploadedImages,
	exportImage = true,
}) => {
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

	function onCropperSave(images) {
		setUploadedImages(images);
		setIsUploadModalOpen(false);
	}

	return (
		<div className={styles.tab_container}>
			<div className={styles.upload_row}>
				<div>
					{images.length + uploadedImages.length} result{""}
					{images.length + uploadedImages.length === 1 ? "" : "s"}
				</div>
				{isUploadable && (
					<input
						type="file"
						id="myera__script__upload__button"
						hidden
						multiple
						accept="image/*"
						onChange={(e) => {
							const files = e.target.files;
							const newFiles = [];
							for (let file of files) {
								newFiles.push(URL.createObjectURL(file));
							}
							setUploadedImages([...uploadedImages, ...newFiles]);
							setIsUploadModalOpen(true);
						}}
					/>
				)}
				{isUploadable && (
					<button
						className={[styles.button, styles.secondary].join(" ")}
						onClick={() =>
							document.querySelector("#myera__script__upload__button").click()
						}
					>
						Upload Image
					</button>
				)}
			</div>
			{uploadedImages.length > 0 && (
				<div className={styles.image_list}>
					{uploadedImages.map((image) => (
						<div
							style={{
								position: "relative",
								height: "125px",
								width: "125px",
							}}
							className={[
								styles.image_container,
								selectedImages.includes(image) && styles.selected_image,
							].join(" ")}
						>
							<img
								src={image}
								alt="uploaded"
								onClick={() => onSelectImage(image)}
								className={[styles.image].join(" ")}
							/>
							{selectedImages.includes(image) && (
								<span className={styles.selected_image_label}>
									{selectedImages.indexOf(image) + 1}
								</span>
							)}
						</div>
					))}
				</div>
			)}
			{uploadedImages.length > 0 && images.length > 0 && <hr />}
			<div className={styles.image_list}>
				{images.map((image) => (
					<div
						style={{
							position: "relative",
							height: "125px",
							width: "125px",
						}}
						className={[
							styles.image_container,
							selectedImages.includes(image.image?.asset || image.asset) &&
								styles.selected_image,
						].join(" ")}
					>
						<img
							src={
								image.thumbnail?.asset ||
								image.image?.asset ||
								image.thumbnail ||
								image.asset
							}
							alt="uploaded"
							onClick={() =>
								onSelectImage(
									image.image?.asset || image.thumbnail || image.asset
								)
							}
							className={[styles.image].join(" ")}
						/>
						{selectedImages.includes(image.image?.asset || image.asset) && (
							<span className={styles.selected_image_label}>
								{selectedImages.indexOf(image.image?.asset || image.asset) + 1}
							</span>
						)}
						{image.label && (
							<div className={styles.image_label}>{image.label}</div>
						)}
					</div>
				))}
			</div>
			<ImageUploadModal
				isOpen={isUploadModalOpen}
				setIsOpen={setIsUploadModalOpen}
				images={uploadedImages}
				state={"cropper"}
				onCropperSave={onCropperSave}
			></ImageUploadModal>
		</div>
	);
};

export default MultiImageTab;
