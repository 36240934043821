import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import ProductCanvas from "../Campaign/ProductCanvas";
const Wrapper = styled.div`
  display: flex;
  background: black;
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;


export default function ProductCanvasMain(props) {
  return (
    <Wrapper id="outer-container-1">
      <Sidebar />
      <MainDashboard>
        <Topbar />
        <ProductCanvas
          objectiveId={props.location.state.objectiveId}
          designId={props.location.state.designId}
          ctaId={props.location.state.ctaId}
          heroId={props.location.state.heroId}
          hashTagsSelected={
            props.location.state.hashTagsSelected
          }
          pathname={"/create-now/generate_product_creative"}
        />
      </MainDashboard>
    </Wrapper>
  );
}
