import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Logo from '../../Components/Images/myEra_NewLogo.png';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../customAPIs/useWindowDimensions';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
//import BlogsMenu from './BlogsMenu.js';
import './blog.css'
import { textAlign } from '@mui/system';

import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";


const EraLogo = styled.img`
  margin-top: 1vh;
  height: 5vh;
`;

const Text = styled.div`
  color: white;
  font-weight: 549;
  
  margin-bottom: auto;
  margin-left: 1vh;
  font-size: 3vh;
`;

const CreateDiv = styled.div`
//   background-color: rgb(34,34,37);
  margin: 3.5vh auto;
  
`;

const CreateContainer = styled.div`
  text-align: left;
  width: 90%;
`;

export default function Blog() {

    const { accessToken } = isAuthenticated();
    // console.log('accessToken = ',accessToken);

    const [BlogsMenu, setBlogsMenu] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    
    useEffect(()=>{
        document.title="MyEra | Blog"
        Axios({
            method: 'GET',
            url:`${process.env.REACT_APP_BACKEND}/blog/`,
          }).then(response=>{
            // console.log('BLOGS1: ', response);
            setBlogsMenu(response.data);
          });
    },[]);
    
    return (
        <>
            <div style={{}}>
            
                <div style={{display: 'flex', alignItems: 'center', height: '10vh', width: '100%', position: 'fixed', top: '0', backgroundColor: 'rgb(25,25,26)'}}>
                    <div style={{display: 'flex', width: '20%'}}>

                        {/* <Link className="navbarLinks" to={"/"}><EraLogo src={Logo} alt="Logo" style={{marginBottom: '1vh', marginLeft: "1vh"}} /></Link>
                        <Link className="navbarLinks"  to={"/"}><Text style={{marginTop: '2vh'}}></Text></Link> */}


                        {/* Go to Landing page if the user is not logged in and to dashboard if the user is logged in  */}

                        <Link className="navbarLinks" to={accessToken?"/dashboard":"/"}><EraLogo src={Logo} alt="Logo" style={{marginBottom: '1vh', marginLeft: "1vh"}} /></Link>
                        <Link className="navbarLinks"  to={accessToken?"/dashboard":"/"}><Text style={{marginTop: '2vh'}}></Text></Link>


                    </div>
                    <div style={{width: '80%', color: 'white', fontWeight: '549', fontSize: isMobile ? '2.4vh' : '3vh', marginTop: '1vh', marginBlock: '1vh', marginLeft: '30%'}}>
                        Welcome to our Blog!
                    </div>
                </div>
                <div style={{color: 'purple', fontWeight: '549', fontSize: '3vh', marginTop: '12vh'}}className='blog-container'>
                    {''}
                    
                    <div className='blogs'>
                        {
                            BlogsMenu.map(
                                idx =>
                                    <div style={{width:'48%',display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                                        <a className="links" href={`/blog/${idx.id}/${idx.Link}`} style={{textDecoration: 'none'}}>
                                            <CreateDiv className="bgimage" key={idx} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                                <img src={`${idx.Image1}`} alt="image here" width='100%'/>
                                            </CreateDiv>    
                                        {/* <CreateDiv className="bgimage" key={idx} style={{backgroundImage: `url(${idx.Image1})`}}>
                                        </CreateDiv>     */}
                                        </a>
                                    {/* {console.log("Link:", idx.Link)} */}
                                    <div className='gap'></div>
                                    <a href={`/blog/${idx.id}/${idx.Link}`} style={{textDecoration: 'none', color: 'white'}}><h4 style={{textAlign: 'center'}}>{idx.Title}</h4></a>
                                    </div> 

                            )
                        }
                    </div>

                </div>
    
            </div>
        </>
    );
}

