import Modal from "react-modal";
import React, { useState, useEffect, useRef } from "react";
import ImageCropper from "../ImageCropper/ImageCropper";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./ImageCaptureModal.module.css";
import Webcam from "react-webcam";

const ImageCaptureModal = ({
	onSubmit,
	isOpen,
	setIsOpen,
	guideline,
}) => {

	const titleMap = {
		cropper: "Crop Image",
	};
	const [ modalHeight, setModalHeight ] = useState("80%");

	const webcamRef = useRef(null);
	const [ snapshot, setSnapshot ] = useState(null);

	// Function to capture a snapshot
	const captureSnapshot = () => {
		const imageSrc = webcamRef.current.getScreenshot();
		// setSnapshot(imageSrc);
		setIsOpen(false);
		onSubmit(imageSrc);
	};

	const modalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			transform: "translate(-50%, -50%)",
			backgroundColor: "white",
			borderRadius: "3vh",
			textAlign: "center",
			height: modalHeight,
			width: "90%",
			color: "#FFFFFF",
			padding: "0%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			maxWidth: "900px",
			zIndex: 1000,
		},

		overlay: {
			background: "rgba(0, 0, 0, 0.25)",
			zIndex: 3,
		},
	};

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 668) {
				setModalHeight("95%");
			} else {
				setModalHeight("80%");
			}
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div>
			<Modal isOpen={ isOpen } style={ modalStyles }>
				<div>
					<div className={ styles.header }>
						<div className={ styles.modal_title }>
							Capture Image
						</div>
						<div className={ styles.modal_subtitle }>
							⚠️ Inappropriate content may lead to order cancellation
						</div>
					</div>
					<div className={ styles.modal_content }>
						<div className={ styles.webcam_container }>
							<Webcam
								forceScreenshotSourceSize={ true }
								ref={ webcamRef }
								mirrored={ true }
								screenshotFormat="image/jpeg"
								className={ styles.webcam }
								videoConstraints={ {
									facingMode: "user",
									aspectRatio: 1,
								} }
							/>
							{ guideline && <img className={ styles.guideline } src={ guideline.asset } /> }
						</div>


						{/* <div className={ styles.snapshot }>
							<div>
								Preview
							</div>
							{ snapshot && <img src={ snapshot } alt="Snapshot" /> }
						</div> */}
					</div>
					<div className={ styles.cta }>
						<button className={ [ styles.button, styles.primary ].join(" ") } onClick={ captureSnapshot }>Capture Snapshot</button>
						{/* <button disabled={ snapshot === null } className={ [ styles.button, styles.primary ].join(" ") } onClick={ () => {
							onSubmit(snapshot);
							setIsOpen(false);
							setSnapshot(null);
						} }>Submit</button> */}
					</div>
				</div>
			</Modal >
		</div >
	);
};

export default ImageCaptureModal;
