import React, { useState, useEffect } from "react";
import ImageSlider from "../ImageSlider";
import TrendingTags from "../TrendingTags/TrendingTags";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import Select from "react-select";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const Wrapper = styled.div`
  display: flex;
  background: rgb(13, 16, 22);
  height: "100vh";
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

const Box = styled.div`
  border-radius: 14px;
  height: 100vh;
  overflow: scroll;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: "1px solid rgba(33, 42, 64, 1)",
    // backgroundColor: "#121828",
    color: state.isSelected ? "rgba(255, 255, 255, 1)" : "black",
    // padding: 20,
    fontSize: "14px",
  }),
  control: (_, { selectProps: { width } }) => ({
    display: "flex",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "100px",
    backgroundColor: "#343943",
    height: "36px",
    fontSize: "14px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
      color: "white",
      fontFamily: "Montserrat",
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "rgba(36, 171, 226, 0.24)",
      color: "#24abe2",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#24abe2",
  }),
};

function ContentIdeas() {
  const [industries, setIndustries] = useState();
  const [selectedIndustry, setSelectedIndustry] = useState({
    value: 1,
    label: "Top Stories",
  });
  const [feeds, setFeeds] = useState([]);

  const onchangeIndustry = (item) => {
    setSelectedIndustry({ value: item.value, label: item.label });
    console.log("SELECTED INDUSTRY: ", selectedIndustry);
  };

  useEffect(() => {
    const { accessToken } = isAuthenticated();

    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    fetch(`${process.env.REACT_APP_BACKEND}/get_topics/`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        var topics = [];
        for (var topic of data.topics) {
          topics.push({ value: topic.id, label: topic.topic });
        }

        setIndustries(topics);
      });
  }, []);

  useEffect(() => {
    const { accessToken } = isAuthenticated();

    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    fetch(
      `${process.env.REACT_APP_BACKEND}/get_rss_feed_topic/${selectedIndustry.value}/`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data.feeds);
        setFeeds(data.feeds);
      });
  }, [selectedIndustry]);

  return (
    <Wrapper id="outer-container">
      <Sidebar />

      <MainDashboard>
        <Topbar />
        <Box>
          <div
            style={{
              color: "white",
              fontSize: "26px",
              paddingLeft: "4%",
              paddingTop: "3%",
              borderBottom: "1px solid #313849",
            }}
          >
            Content Ideas
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "start",
              marginTop: "1%",
            }}
          >
            <div style={{ width: "15%", marginLeft: "3%" }}>
              <Select
                styles={customStyles}
                value={selectedIndustry}
                onChange={onchangeIndustry}
                options={industries}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                // defaultValue = {this.state.selectedPlace}

                placeholder={"All Topics"}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#FFFFFF", // Placeholder color
                  },
                })}
              />
            </div>
            {/* <div style={{ width: '15%', marginLeft: "1%"}}>
                <Select 
                    styles={customStyles}
                    // defaultValue = {this.state.selectedPlace}
                    
                    placeholder={'All Location'} 
                    theme={theme => ({
                      ...theme,
                      colors: {
                          ...theme.colors,
                          neutral50: '#FFFFFF',  // Placeholder color
                      },
                    })}
                  />
                </div> */}
          </div>

          <div style={{ marginTop: "2%" }}>
            {feeds.map((feed, index) => {
              function truncate(str, n) {
                return str?.length > n ? str.substr(0, n - 1) + "..." : str;
              }

              function getImage(feed) {
                const doc = document.createElement("div");
                const summary = feed.summary;
                doc.innerHTML = summary;

                const image = doc.querySelector("img");

                if (image) {
                  return image.src;
                } else {
                  return "https://www.dummytextimages.com/600x400/000/fff";
                }
              }

              function getSubHeading(feed) {
                const doc = document.createElement("div");
                const summary = feed.summary;
                doc.innerHTML = summary;

                const subHeading = doc.querySelector("h1");

                // This will run for the buzzfeed
                if (subHeading) {
                  return subHeading.innerText;
                }

                // This will run for TOI
                else {
                  let str = summary.split("</a>");
                  str = str[1];

                  return str ? str : "NO SUBHEADING FOUND";
                }
              }

              function viewallHandler(link) {
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = link;
                a.click();
              }

              const subHeading = getSubHeading(feed);
              const image = getImage(feed);

              return (
                <>
                  <Card
                    sx={{ maxWidth: 1000 }}
                    style={{
                      backgroundColor: "#0D1018",
                      color: "white",
                      marginLeft: "3%",
                      height: "380px",
                      display: "flex",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="250"
                      //image={feed.summary.replaceAll('<', '').split(" ")[5].substring(5, feed.summary.replaceAll('<', '').split(" ")[5].length - 1)}
                      // image = {feed.summary.substring(feed.summary.indexOf("src") + 5, feed.summary.indexOf("</p>") - 4)}
                      image={image}
                      alt="green iguana"
                    />
                    <CardContent
                      style={{ marginLeft: "2%", marginTop: "-1.8%" }}
                    >
                      <Typography gutterBottom variant="h4" component="div">
                        {feed.title}
                      </Typography>
                      <Typography variant="body1" style={{ color: "white" }}>
                        {/* {truncate(feed.summary.substring(feed.summary.indexOf("</a>") + 4,  feed.summary.length), 200)} */}
                        {/* {truncate(feed.summary.substring(4, feed.summary.indexOf("</h1>")), 150)} */}
                        {truncate(subHeading, 100)}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {/* <Button size="small" onClick={() => {window.location.href = feed.link }}>Learn More</Button> */}
                      <Button
                        style={{
                          backgroundColor: "rgba(132, 232, 244, 1)",
                          color: "black",
                          fontFamily: "Open Sans",
                          borderRadius: "2vh",
                          textTransform: "none",
                          height: "5vh",
                          width: "20vh",
                        }}
                        // variant="contained"
                        // fullWidth={true}
                        color="secondary"
                        onClick={() => {
                          viewallHandler(feed.link);
                        }}
                      >
                        Read More
                      </Button>
                    </CardActions>
                  </Card>
                  <hr />
                </>
              );
            })}
          </div>
        </Box>
      </MainDashboard>
    </Wrapper>
  );
}

export default ContentIdeas;
