import React from "react";
import styles from "./ThemePreview.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaUpload } from "react-icons/fa";

import sampleDial1 from "../../../Images/sample_dial_1.png";
import sampleDial2 from "../../../Images/sample_dial_2.png";
import watchOutput from "../../../Images/watch_output.png";

const ThemePreview = ({ children, ...props }) => {
	return (
		<div
			className={styles.theme_preview}
			style={{
				backgroundColor: props.backgroundColor || "white",
				color: props.textColor
			}}
		>
			<AiOutlineCloseCircle
				style={{
					right: "1%",
					position: "absolute",
				}}
			/>
			<div className={styles.navbar} style={{color: props.textColor}}>
				<span>Dial</span>
				<span>Signature</span>
				<span>Strap</span>
				<span>Case</span>
				<span>Hands</span>
			</div>
			<div className={styles.content}>
				<div>
					<div
						style={{
							marginTop: "14px",
							border: `1px solid ${props.primaryColor}`,
							display: "inline-block",
							color: props.primaryColor,
							padding: "4px 6px",
							borderRadius: "6px",
							fontSize: "10px",
							cursor: "pointer",
						}}
					>
						Upload&nbsp;&nbsp;
						<FaUpload color={props.primaryColor} />
					</div>
					<div className={styles.dials_wrapper}>
						<img
							alt="Sample Dial 1"
							src={sampleDial1}
							className={styles.dial}
							style={{
								border: `2px solid ${props.primaryColor}`,
								width: "54px",
								height: "54px",
								marginRight: "8px",
							}}
						/>
						<img
							alt="Sample Dial 2"
							src={sampleDial2}
							className={styles.dial}
						/>
					</div>
				</div>
				<img src={watchOutput} alt="Watch Output" />
			</div>
			<div
				className={styles.cta}
				style={{
					backgroundColor: props.primaryColor,
					color: props.backgroundColor,
				}}
			>
				Add to Cart
			</div>
		</div>
	);
};

export default ThemePreview;
