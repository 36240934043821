import React, { useEffect, useState } from "react";
import styles from "./FlowerAura.module.css";
import { Step, Stepper } from "@mui/material";
import { StepLabel } from "@material-ui/core";
import produce from "immer";
import { DeleteRounded } from "@mui/icons-material";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { toast } from "react-toastify";
import ga4 from "react-ga4";
import { ReactComponent as BackArrow } from "./assets/back_arrow.svg";

const data = [
	{
		label: "Rakhi",
		priority: 0,
		categories: [
			{
				label: "Rakhi",
				title: "Select your Rakhi",
				priority: 0,
				minimumQuantity: 1,
				maximumQuantity: 10,
				products: [
					{
						ProductName: "Red And Gold Kundan Rakhi",
						Category: "Single",
						AttributeID: 65,
						AttributeName: "red&gold-kundan-rakhi",
						Price: 195,
						ImageUrl:
							"https://imgcdn.floweraura.com/red-gold-and-pearl-rakhi-9887187ra-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 0,
					},
					{
						ProductName: "Best Bro Trophy Rakhi",
						Category: "Single",
						AttributeID: 66,
						AttributeName: "best bro trophy rakhi",
						Price: 225,
						ImageUrl:
							"https://imgcdn.floweraura.com/best-bro-trophy-rakhi-9779437ra-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 1,
					},
					// {
					// 	ProductName: "Handcrafted Vibrant Bhaiya Bhabhi Rakhi",
					// 	Category: "Bhaiya bhabhi",
					// 	AttributeID: 113,
					// 	AttributeName: "handcrafted vibrant bhaiya bhabhi rakhi",
					// 	Price: 225,
					// 	ImageUrl:
					// 		"https://imgcdn.floweraura.com/IMG_5454.jpg?tr=w-298,h-298,dpr-1.5,q-70",
					// 	priority: 2,
					// },
					{
						ProductName: "Bhaiya Bhabhi Pearl Rakhi with Metallic Beads",
						Category: "Bhaiya bhabhi",
						AttributeID: 112,
						AttributeName: "bhaiya bhabhi pearl rakhi with metallic beads",
						Price: 145,
						ImageUrl:
							"https://imgcdn.floweraura.com/Rak-22-5212-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 3,
					},
					{
						ProductName: "Alluring Beaded Bhaiya Bhabhi Rakhi Set",
						Category: "Bhaiya bhabhi",
						AttributeID: 111,
						AttributeName: "alluring beaded bhaiya bhabhi rakhi set",
						Price: 195,
						ImageUrl:
							"https://imgcdn.floweraura.com/IMG_5501.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 4,
					},
					{
						ProductName: "Vibrant Coolest Bro Rakhi",
						Category: "Kids",
						AttributeID: 107,
						AttributeName: "vibrant coolest bro rakhi",
						Price: 115,
						ImageUrl:
							"https://imgcdn.floweraura.com/vibrant-coolest-bro-rakhi-9768707ra-A_0.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 6,
					},
					{
						ProductName: "Embellished Rakhi Trio",
						Category: "Set of 3",
						AttributeID: 74,
						AttributeName: "embellished rakhi trio",
						Price: 245,
						ImageUrl:
							"https://imgcdn.floweraura.com/embellished-rakhi-trio-9849707ra-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 7,
					},
					{
						ProductName: "Embellished Peacock Charm Rakhis",
						Category: "Set of 2",
						AttributeID: 73,
						AttributeName: "embellished peacock charm rakhis",
						Price: 245,
						ImageUrl:
							"https://imgcdn.floweraura.com/embellished-peacock-charm-rakhis-9778547ra-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 8,
					},
					// {
					// 	ProductName: "Two Antique Finish Divine Rakhis",
					// 	Category: "Set of 2",
					// 	AttributeID: 72,
					// 	AttributeName: "two antique finish divine rakhis",
					// 	Price: 225,
					// 	ImageUrl:
					// 		"https://imgcdn.floweraura.com/two-antique-finish-ram-sita-rakhis-9778457ra-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
					// 	priority: 9,
					// },
					{
						ProductName: "Traditional Handpainted Charm Rakhi Duo",
						Category: "Set of 2",
						AttributeID: 71,
						AttributeName: "traditional handpainted charm rakhi duo",
						Price: 265,
						ImageUrl:
							"https://imgcdn.floweraura.com/traditional-handpainted-charm-rakhi-duo-9779317ra-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 10,
					},
					{
						ProductName: "Flower Dial Trinket Rakhi",
						Category: "Single",
						AttributeID: 69,
						AttributeName: "flower dial trinket rakhi",
						Price: 215,
						ImageUrl:
							"https://imgcdn.floweraura.com/flower-dial-trinket-rakhi-9814727ra-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 11,
					},
					{
						ProductName: "Metal Embellished Mauli Rakhi",
						Category: "Single",
						AttributeID: 68,
						AttributeName: "metal embellished mauli rakhi",
						Price: 175,
						ImageUrl:
							"https://imgcdn.floweraura.com/metal-embellished-mauli-rakhi-9814737ra-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 12,
					},
					{
						ProductName: "Radiant Mor Pankh Mirror Rakhi",
						Category: "Single",
						AttributeID: 67,
						AttributeName: "radiant mor pankh mirror rakhi",
						Price: 205,
						ImageUrl:
							"https://imgcdn.floweraura.com/radiant-mor-pankh-mirror-rakhi-9779327ra-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 13,
					},
					{
						ProductName: "Heroic Captain America Shield Rakhi",
						Category: "Kids",
						AttributeID: 109,
						AttributeName: "heroic captain america shield rakhi",
						Price: 250,
						ImageUrl:
							"https://imgcdn.floweraura.com/heroic-captain-america-shield-rakhi-ven-38-kc786534-A_0.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 14,
					},
					{
						ProductName: "Spiderman Rakhi For Kids",
						Category: "Kids",
						AttributeID: 108,
						AttributeName: "spiderman rakhi for kids",
						Price: 175,
						ImageUrl:
							"https://imgcdn.floweraura.com/rak-21-4777-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 15,
					},
					{
						ProductName: "Quirky Football Rakhi",
						Category: "Kids",
						AttributeID: 110,
						AttributeName: "quirky football rakhi",
						Price: 215,
						ImageUrl:
							"https://imgcdn.floweraura.com/Rak-22-5127-A_0.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 16,
					},
					{
						ProductName: "Designer Bhaiya Bhabhi Rakhi Set",
						Category: "Bhaiya bhabhi",
						AttributeID: 116,
						AttributeName: "designer bhaiya bhabhi rakhi set",
						Price: 245,
						ImageUrl:
							"https://imgcdn.floweraura.com/IMG_5497.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 2,
					},
					{
						ProductName: "Exquisite N Antique Charm Om Rudraksha N Pearl Rakhi",
						Category: "Single",
						AttributeID: 117,
						AttributeName:
							"exquisite n antique charm om rudraksha n pearl rakhi",
						Price: 145,
						ImageUrl:
							"https://imgcdn.floweraura.com/Rak-22-5203-B.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 16,
					},
					{
						ProductName: "Two Shiva N Damru Rakhis",
						Category: "Set of 2",
						AttributeID: 118,
						AttributeName: "two shiva n damru rakhis",
						Price: 265,
						ImageUrl:
							"https://imgcdn.floweraura.com/two-shiva-n-damru-rakhis-9779227ra-B_1.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 6.5,
					},
					{
						ProductName: "Silver Finish Fancy Bhaiya Bhabhi Rakhi",
						Category: "Bhaiya bhabhi",
						AttributeID: 119,
						AttributeName: "silver finish fancy bhaiya bhabhi rakhi",
						Price: 225,
						ImageUrl: "https://imgcdn.floweraura.com/IMG_5514.jpg",
						priority: 16,
					},
					{
						ProductName: "Traditional Mirror Rakhi For Couple",
						Category: "Bhaiya bhabhi",
						AttributeID: 120,
						AttributeName: "traditional mirror rakhi for couple",
						Price: 245,
						ImageUrl:
							"https://imgcdn.floweraura.com/mirror-dial-couple-rakhi-set-9778427ra-B_0.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 16,
					},
					{
						ProductName: "Handpainted Floral Rakhi Duo",
						Category: "Set of 2",
						AttributeID: 121,
						AttributeName: "handpainted floral rakhi duo",
						Price: 245,
						ImageUrl:
							"https://imgcdn.floweraura.com/IMG_5469.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 16,
					},
				],
			},
		],
	},
	{
		label: "Eatables",
		priority: 1,
		categories: [
			{
				label: "Eatables",
				title: "Select your Eatables",
				priority: 2,
				minimumQuantity: 1,
				maximumQuantity: 6,
				products: [
					{
						ProductName: "Haldiram Kaju Katli 200 gm",
						Category: "Sweet",
						AttributeID: 64,
						AttributeName: "Haldiram 200gms Kaju Katli",
						Price: 395,
						ImageUrl:
							"https://imgcdn.floweraura.com/17-A_1.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 0,
					},
					{
						ProductName: "Haldiram Milk cake 250 gm",
						Category: "Sweet",
						AttributeID: 50,
						AttributeName: "Haldiram's Milk Cake 250 gm",
						Price: 349,
						ImageUrl:
							"https://imgcdn.floweraura.com/29-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 2,
					},

					{
						ProductName: "2 Hersheys small - golden - almond",
						Category: "Chocolate",
						AttributeID: 98,
						AttributeName: "hersheys small - golden - almond",
						Price: 199,
						ImageUrl:
							"https://imgcdn.floweraura.com/23-A-Recovered.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 3,
					},
					{
						ProductName: "2 Hersheys small - blueberry & acai",
						Category: "Chocolate",
						AttributeID: 48,
						AttributeName: "2 Hershey's Blueberry 33 gm each",
						Price: 199,
						ImageUrl:
							"https://imgcdn.floweraura.com/9-A-Recovered.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 5,
					},
					{
						ProductName: "Almond Rocks 100 gms",
						Category: "Chocolate",
						AttributeID: 61,
						AttributeName: "bk almond rocks",
						Price: 199,
						ImageUrl:
							"https://imgcdn.floweraura.com/bakingo-chocolate-hazelnut-rocks-100-gms-9785960ad-AA.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 1,
					},
					{
						ProductName: "BK Hazelnut rocks",
						Category: "Chocolate",
						AttributeID: 88,
						AttributeName: "bk hazelnut rocks",
						Price: 225,
						ImageUrl:
							"https://imgcdn.floweraura.com/7-A-Recovered_1.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 4,
					},
					{
						ProductName: "Trail Mix pouch",
						Category: "Dry Fruits",
						AttributeID: 89,
						AttributeName: "trail mix pouch",
						Price: 175,
						ImageUrl:
							"https://imgcdn.floweraura.com/IMG_0763%20(1).JPG?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 6,
					},
					{
						ProductName: "Haldiram Motichoor laddoo- 250gm",
						Category: "Sweet",
						AttributeID: 104,
						AttributeName: "Haldiram Motichoor laddoo- 250gm",
						Price: 395,
						ImageUrl:
							"https://imgcdn.floweraura.com/27-A.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 7,
					},
					{
						ProductName: "Om Dhoda Burfi 250gm",
						Category: "Sweet",
						AttributeID: 90,
						AttributeName: "om dhoda burfi 250gm",
						Price: 295,
						ImageUrl:
							"https://imgcdn.floweraura.com/Dhoda%20Burfi%20250gm.JPG?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 8,
					},
					{
						ProductName: "Om Atta Ladoo 250gm",
						Category: "Sweet",
						AttributeID: 91,
						AttributeName: "om atta ladoo 250gm",
						Price: 295,
						ImageUrl:
							"https://imgcdn.floweraura.com/14-A_2.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 9,
					},
					{
						ProductName: "Om Special Patisa 250gm",
						Category: "Sweet",
						AttributeID: 92,
						AttributeName: "om special patisa 250gm",
						Price: 245,
						ImageUrl:
							"https://imgcdn.floweraura.com/IMG_0671.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 10,
					},
					{
						ProductName: "Assorted Baklava 9 Pcs",
						Category: "Sweet",
						AttributeID: 63,
						AttributeName: "assorted baklava 9 pcs",
						Price: 625,
						ImageUrl:
							"https://imgcdn.floweraura.com/4-A_7.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 0.5,
					},
					{
						ProductName: "Almonds N Cashews In Potli 100gm",
						Category: "Sweet",
						AttributeID: 114,
						AttributeName: "almonds n cashews in potli 100gm",
						Price: 495,
						ImageUrl:
							"https://imgcdn.floweraura.com/10-A_1.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 3.5,
					},
					{
						ProductName: "Bakingo Choco N Almond Cookies Combo",
						Category: "Sweet",
						AttributeID: 115,
						AttributeName: "bakingo choco n almond cookies combo",
						Price: 449,
						ImageUrl:
							"https://imgcdn.floweraura.com/IMG_2914-Edited.jpg?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 10,
					},
				],
			},
		],
	},
	{
		label: "Gifts",
		priority: 2,
		categories: [
			{
				label: "Gifts",
				title: "Select your Gifts",
				priority: 1,
				minimumQuantity: 0,
				maximumQuantity: 3,
				products: [
					{
						ProductName: "Diary & Pen Combo",
						Category: "Gift",
						AttributeID: 78,
						AttributeName: "diary-pen-combo",
						Price: 495,
						ImageUrl:
							"https://imgcdn.floweraura.com/premium-notebook-and-pen-set-9804857ad-AA.jpg?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 1,
					},
					{
						ProductName: "Elegant Blue Pooja Thali",
						Category: "Gift",
						AttributeID: 79,
						AttributeName: "blue-pooja-thali",
						Price: 190,
						ImageUrl:
							"https://imgcdn.floweraura.com/IMG_0742_0.jpg?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 4,
					},
					{
						ProductName: "Ganesha Keychain",
						Category: "Gift",
						AttributeID: 80,
						AttributeName: "ganesha-keychain",
						Price: 99,
						ImageUrl:
							"https://imgcdn.floweraura.com/9773137ra-E.JPG?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 6,
					},
					{
						ProductName: "Best Bro Mug",
						Category: "Gift",
						AttributeID: 81,
						AttributeName: "mug-you-are-the-best-bro",
						Price: 245,
						ImageUrl:
							"https://imgcdn.floweraura.com/9773137ra-D_0.JPG?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 0,
					},
					{
						ProductName: "A5 Photo frame",
						Category: "Gift",
						AttributeID: 82,
						AttributeName: "A5-frame",
						Price: 149,
						ImageUrl:
							"https://imgcdn.floweraura.com/DSC_4178.JPG?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 2,
					},
					{
						ProductName: "Ganesha Idol",
						Category: "Gift",
						AttributeID: 84,
						AttributeName: "ganesha-idol",
						Price: 199,
						ImageUrl:
							"https://imgcdn.floweraura.com/9773127ra-C.JPG?tr=w-298,h-298,dpr-1.5,q-70",
						priority: 5,
					},
					{
						ProductName: "Rose & Black oud Scented Candle",
						Category: "Gift",
						AttributeID: 85,
						AttributeName: "rose & black oud scented candle",
						Price: 399,
						ImageUrl:
							"https://imgcdn.floweraura.com/rose-&-black-oud-scented-candle-9787190gf.jpg?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 3,
					},
					{
						ProductName: "Jasmine Marigold Fragrance Reed Diffuser",
						Category: "Gift",
						AttributeID: 86,
						AttributeName: "jasmine marigold fragrance reed diffuser",
						Price: 475,
						ImageUrl:
							"https://imgcdn.floweraura.com/jasmine-marigold-fragrance-reed-diffuser-9778800sd-A_0.jpg?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 7,
					},
				],
			},
		],
	},
	{
		label: "Cards",
		priority: 3,
		categories: [
			{
				label: "Cards",
				title: "Select your Free Card",
				priority: 4,
				minimumQuantity: 0,
				maximumQuantity: 1,
				products: [
					{
						ProductName: "Rakhi Wishes Card",
						Category: "Free Card",
						AttributeID: 94,
						AttributeName: "rakhi wishes card",
						Price: 0,
						ImageUrl:
							"https://imgcdn.floweraura.com/DSC_3434.JPG?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 0,
					},
					{
						ProductName: "Rakhi Quotes Card",
						Category: "Free Card",
						AttributeID: 95,
						AttributeName: "rakhi quotes card",
						Price: 0,
						ImageUrl:
							"https://imgcdn.floweraura.com/DSC_3437.JPG?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 1,
					},
					{
						ProductName: "Happy Rakhi Card",
						Category: "Free Card",
						AttributeID: 96,
						AttributeName: "happy rakhi card",
						Price: 0,
						ImageUrl:
							"https://imgcdn.floweraura.com/DSC_3439.JPG?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 2,
					},
					{
						ProductName: "Tum Hi Ho Card",
						Category: "Free Card",
						AttributeID: 97,
						AttributeName: "tum hi ho card",
						Price: 0,
						ImageUrl:
							"https://imgcdn.floweraura.com/DSC_3440.JPG?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 3,
					},
				],
			},
			{
				label: "Boxes",
				title: "Select your box",
				subtitle: "Box size may vary as per selected items",
				priority: 3,
				minimumQuantity: 1,
				maximumQuantity: 1,
				products: [
					{
						ProductName: " 1 Gift box",
						Category: "Box",
						AttributeID: 93,
						AttributeName: "rakhi celebration cardbox",
						Price: 99,
						ImageUrl:
							"https://imgcdn.floweraura.com/DSC_3427.JPG?tr=w-298,h-298,dpr-1.5,q-70",

						priority: 0,
						disable_delete: true,
					},
				],
			},
		],
	},
];

const FlowerAura = () => {
	const [cart, setCart] = useState({});
	const [stackedImages, setStackedImages] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [showCart, setShowCart] = useState(false);
	const [showError, setShowError] = useState(false);
	const [error, setError] = useState("");

	const getCategoryCount = (categoryName, checkCart = cart) => {
		let productIds = new Set();
		for (let step of data) {
			for (let category of step.categories) {
				if (category.label === categoryName) {
					productIds = new Set(
						category.products.map((product) => product.AttributeID)
					);
					break;
				}
			}
			if (productIds.length > 0) {
				break;
			}
		}
		return Object.values(checkCart).reduce(
			(aggr, product) =>
				aggr + (productIds.has(product.AttributeID) ? product.quantity : 0),
			0
		);
	};

	const addToCart = (product, qty, category) => {
		var DTO = {};
		if (qty > 0) {
			DTO = {
				event_name: "make_your_own_hamper",
				event_params: {
					heading:
						product.CategoryName === "Single" ? "Rakhi" : product.CategoryName,
					sub_heading: product.ProductName,
					event_label: "add",
					validation_message: "",
				},
			};
		} else {
			DTO = {
				event_name: "make_your_own_hamper",
				event_params: {
					heading:
						product.CategoryName === "Single" ? "Rakhi" : product.CategoryName,
					sub_heading: product.ProductName,
					event_label: "delete",
					validation_message: "",
				},
			};
		}
		window.parent.postMessage(
			{
				func: "ga_event_func",
				message: DTO,
			},
			"*"
		);
		ga4.event("product_add_or_delete", DTO);
		console.log(product, qty);
		setCart(
			produce((prev) => {
				if (!(product.AttributeID in prev)) {
					prev[product.AttributeID] = { ...product, quantity: 0, category };
				}
				prev[product.AttributeID].quantity += qty;
				if (prev[product.AttributeID].quantity === 0) {
					delete prev[product.AttributeID];
					if (product.category) {
						const categoryCount = getCategoryCount(
							product.category.label,
							prev
						);
						if (categoryCount < product.category.minimumQuantity) {
							if (product.category.step < activeStep) {
								setActiveStep(product.category.step);
								setShowCart(false);
								setError("Please select mandatory product");
								setShowError(true);
							}
						}
					}
				}
			})
		);
		if (qty > 0) {
			setStackedImages((prev) => {
				return [...prev, product.ImageUrl];
			});
		} else {
			setStackedImages((prev) => {
				const index = prev.findIndex((val) => val === product.ImageUrl);
				if (index !== -1) {
					prev.splice(index, 1);
				}
				return prev;
			});
		}
	};

	const handleDeletion = (product) => {
		setCart(
			produce((prev) => {
				if (product.AttributeID in prev) {
					delete prev[product.AttributeID];
					const categoryCount = getCategoryCount(product.category.label, prev);
					if (categoryCount < product.category.minimumQuantity) {
						if (product.category.step < activeStep) {
							setActiveStep(product.category.step);
							setShowCart(false);
							var DTO = {
								event_name: "make_your_own_hamper",
								event_params: {
									heading:
										product.Category === "Single" ? "Rakhi" : product.Category,
									sub_heading: product.ProductName,
									event_label: "delete",
									validation_message: "Please select mandatory product",
								},
							};
							window.parent.postMessage(
								{
									func: "ga_event_func",
									message: DTO,
								},
								"*"
							);
							ga4.event("product_add_or_delete", DTO);
							setError("Please select mandatory product");
							setShowError(true);
						}
					}
				}
			})
		);
		setStackedImages((prev) => prev.filter((val) => val !== product.ImageUrl));
	};

	const validateStep = (step = activeStep) => {
		for (let category of data[step].categories) {
			const productIds = new Set(
				category.products.map((product) => product.AttributeID)
			);

			const minQty = category.minimumQuantity;
			const maxQty = category.maximumQuantity;

			const count = Object.values(cart).reduce((aggr, curr) => {
				if (productIds.has(curr.AttributeID)) {
					return aggr + curr.quantity;
				}
				return aggr;
			}, 0);

			if (count < minQty || count > maxQty) {
				return false;
			}
		}

		return true;
	};

	const handleBuyNow = async () => {
		if (!showCart) {
			setShowCart((prev) => !prev);
			return;
		}
		for (let i = 0; i < data.length; i++) {
			if (!validateStep(i)) {
				console.log("Qty not satisfied");
				toast.error("Quantities not satisfied for: " + data[i].label);
				return;
			}
		}
		const items = Object.values(cart).map((item) => ({
			item_id: item.AttributeID,
			item_name: item.AttributeName,
			item_category: item.Category,
			price: item.Price,
			quantity: item.quantity,
		}));
		const DTO = {
			event_name: "make_your_own_hamper",
			event_params: {
				items: items,
				event_category: "buy_now",
				event_label: "click",
			},
		};
		window.parent.postMessage(
			{
				func: "ga_event_func",
				message: DTO,
			},
			"*"
		);
		ga4.event("add_to_cart", DTO);
		window.parent.postMessage(
			{
				func: "buyNow",
				message: cart,
			},
			"*"
		);
	};

	const buildCounter = (product, category) => {
		const canAdd = getCategoryCount(category.label) < category.maximumQuantity;

		return (
			<div className={[styles.counter, styles.product_cta].join(" ")}>
				<button
					onClick={() => {
						addToCart(product, -1);
					}}
				>
					-
				</button>
				<span>{cart[product.AttributeID].quantity}</span>
				<button
					className={!canAdd && styles.disabled}
					onClick={() => {
						if (!canAdd) {
							var DTO = {
								event_name: "make_your_own_hamper",
								event_params: {
									heading:
										product.Category === "Single" ? "Rakhi" : product.Category,
									sub_heading: product.ProductName,
									event_label: "add",
									validation_message:
										"You have reached maximum selection limit",
								},
							};
							window.parent.postMessage(
								{
									func: "ga_event_func",
									message: DTO,
								},
								"*"
							);
							ga4.event("product_add_or_delete", DTO);
							setError("You have reached maximum selection limit");
							setShowError(true);
							return;
						}
						addToCart(product, 1);
					}}
				>
					+
				</button>
			</div>
		);
	};

	const buildProductList = (category) => {
		return (
			<div className={styles.product_list}>
				{category.products
					.toSorted((a, b) => (a.priority ?? 0) - (b.priority ?? 0))
					.map((product) => (
						<div key={product.AttributeID} className={styles.product_container}>
							<img src={product.ImageUrl} alt={product.ProductName} />
							<div className={styles.product_details}>
								<div className={styles.product_name}>{product.ProductName}</div>
								<div className={styles.product_footer}>
									<div className={styles.product_price}>
										₹&nbsp;{product.Price}
									</div>
									{category.maximumQuantity === 1 &&
									category.maximumQuantity === 1 &&
									category.products.length === 1 ? (
										<button
											className={[styles.product_cta, styles.disabled].join(
												" "
											)}
											onClick={() => {
												setError("You cannot alter this product");
												setShowError(true);
												return;
											}}
										>
											Added
										</button>
									) : !(product.AttributeID in cart) ? (
										<button
											className={[
												styles.product_cta,
												getCategoryCount(category.label) >=
													category.maximumQuantity && styles.disabled,
											].join(" ")}
											onClick={() => {
												if (
													getCategoryCount(category.label) >=
													category.maximumQuantity
												) {
													setError("You have reached maximum selection limit");
													setShowError(true);
													return;
												}
												addToCart(product, 1, category);
											}}
										>
											Add
										</button>
									) : (
										buildCounter(product, category)
									)}
								</div>
							</div>
						</div>
					))}
			</div>
		);
	};

	const buildImageStack = () => {
		const imageStackLength = 3;
		return (
			<div
				className={styles.image_stack}
				style={{
					marginRight:
						stackedImages.length > 0
							? `${
									(Math.min(stackedImages.length, imageStackLength) - 1) * -28 +
									12
							  }px`
							: "0px",
				}}
			>
				{stackedImages
					.slice(Math.max(stackedImages.length - imageStackLength, 0))
					.map((src, idx) => (
						<img
							key={idx}
							src={src}
							style={{
								transform: `translateX(${idx * -28}px)`,
							}}
							alt="Product"
						/>
					))}
			</div>
		);
	};

	const buildCartList = () => {
		return (
			<div
				className={styles.cart_list}
				style={{
					maxHeight: showCart ? "70vh" : "0vh",
					padding: showCart ? "16px" : "0px",
				}}
			>
				{Object.values(cart).map((product, idx) => (
					<>
						<div className={styles.cart_item}>
							<img src={product.ImageUrl} alt={product.ProductName} />
							<div className={styles.cart_item_details}>
								<div className={styles.cart_item_name}>
									{product.ProductName}
								</div>
								<div className={styles.cart_item_price}>₹ {product.Price}</div>
							</div>
							{product.category.maximumQuantity === 1 &&
							product.category.maximumQuantity === 1 &&
							product.category.products.length === 1 ? (
								<button
									className={[styles.product_cta, styles.disabled].join(" ")}
									onClick={() => {
										setError("You cannot alter this product");
										setShowError(true);
										return;
									}}
								>
									Added
								</button>
							) : (
								<div className={styles.item_cta}>
									<DeleteRounded
										onClick={() => {
											handleDeletion(product);
										}}
									/>
									{buildCounter(product, product.category)}
								</div>
							)}
						</div>
						{idx !== Object.values(cart).length - 1 && (
							<div className={styles.divider}>
								<hr />
							</div>
						)}
					</>
				))}
			</div>
		);
	};

	useEffect(() => {
		data.sort((a, b) => a.priority - b.priority);
		for (let i = 0; i < data.length; i++) {
			const step = data[i];
			for (let category of step.categories) {
				category.step = i;
			}
			step.categories.sort((a, b) => a.priority - b.priority);
		}
	}, []);

	useEffect(() => {
		if (showError) {
			setTimeout(() => {
				setShowError(false);
			}, 2000);
		}
	}, [showError]);

	useEffect(() => {
		if (Object.keys(cart).length === 0) {
			setShowCart(false);
		}
	}, [cart]);

	useEffect(() => {
		const step = data[activeStep];

		document.getElementById("outer-container").scrollTo({
			top: 0,
		});

		if (!step) return;

		step.categories.forEach((category) => {
			if (category.maximumQuantity === 1 && category.products.length === 1) {
				const product = category.products[0];
				setCart((prev) => ({
					...prev,
					[product.AttributeID]: {
						...product,
						quantity: 1,
						category,
					},
				}));
			}
		});
	}, [activeStep]);

	return (
		<div className={styles.main_container}>
			<div
				className={styles.header_backdrop}
				style={{
					backgroundColor: showError
						? "rgba(0, 0, 0, 0.4)"
						: "rgba(0, 0, 0, 0)",
					maxHeight: showError ? "200px" : "0px",
					padding: showError ? "12px" : "0px",
					backdropFilter: showError ? "blur(5px)" : "blur(0px)",
				}}
			>
				{error}
			</div>
			<div
				className={styles.backdrop}
				style={{
					height: showCart ? "100%" : "0%",
					background: showCart ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0)",
				}}
				onClick={() => {
					if (showCart) {
						setShowCart((prev) => !prev);
					}
				}}
			/>
			<div className={styles.header}>
				{activeStep > 0 && (
					<button
						className={[styles.button, styles.secondary].join(" ")}
						onClick={() => {
							setActiveStep((prev) => {
								if (prev - 1 >= 0) {
									return prev - 1;
								}
								return prev;
							});

							setShowCart(false);
						}}
					>
						<BackArrow />
					</button>
				)}
				<div className={styles.stepper_container}>
					<Stepper
						activeStep={activeStep}
						sx={{
							"& .MuiStepConnector-line": {
								borderTopWidth: "2px",
							},
							"& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
								borderColor: "#80D381",
							},
						}}
					>
						{data.map((stage, idx) => (
							<Step
								key={stage.label}
								completed={activeStep >= idx && validateStep(idx)}
							>
								<StepLabel
									style={{
										cursor: "pointer",
									}}
									onClick={() => {
										if (idx > activeStep) {
											for (let i = activeStep; i < idx; i++) {
												if (!validateStep(i)) {
													if (i === activeStep) {
														setError("Please select an item to proceed");
														setShowError(true);
													} else {
														setError("Please select mandatory product");
														setShowError(true);
														setActiveStep(i);
													}
													return;
												}
											}
										}
										setActiveStep(idx);
										setShowCart(false);
									}}
								>
									<div
										style={{
											width: "16px",
											height: "16px",
											borderRadius: "50%",
											background:
												activeStep >= idx && validateStep(idx)
													? "#80D381"
													: "#D9D9D9",
										}}
									/>
								</StepLabel>
							</Step>
						))}
					</Stepper>
				</div>
			</div>
			<div className={styles.stage_container}>
				{data[activeStep].categories.map((category) => (
					<div className={styles.stage}>
						<div className={styles.stage_header}>
							<div className={styles.stage_title}>
								{category.title}{" "}
								{category.minimumQuantity > 0 && (
									<span style={{ color: "red" }}>*</span>
								)}
							</div>
							<div className={styles.stage_subtitle}>
								{category.subtitle ??
									`You can add upto ${category.maximumQuantity} product${
										category.maximumQuantity > 1 ? "s" : ""
									}`}
							</div>
						</div>
						{buildProductList(category)}
					</div>
				))}
			</div>
			<div
				className={styles.cart_footer}
				style={{
					boxShadow: !showCart ? "0px -5px 16px 0px #dddede" : "none",
				}}
			>
				{showCart && (
					<span
						className={styles.cart_close}
						onClick={() => {
							setShowCart(false);
						}}
					>
						&times;
					</span>
				)}
				{buildCartList()}
				<div className={styles.cart_summary_container}>
					<div
						className={styles.cart_summary}
						onClick={() =>
							Object.keys(cart).length > 0 && setShowCart((prev) => !prev)
						}
					>
						{buildImageStack()}
						<div className={styles.cart_info}>
							<div>
								{Object.values(cart).reduce(
									(aggr, curr) => aggr + curr.quantity,
									0
								)}
								&nbsp;item
								{Object.values(cart).reduce(
									(aggr, curr) => aggr + curr.quantity,
									0
								) !== 1
									? "s"
									: ""}
							</div>
							<div>
								₹&nbsp;
								{Object.values(cart).reduce(
									(aggr, curr) => aggr + curr.quantity * curr.Price,
									0
								)}
							</div>
						</div>
						{showCart ? <ArrowDropDown /> : <ArrowDropUp />}
					</div>
					<div className={styles.footer_cta}>
						{activeStep > 0 && (
							<button
								className={[styles.button, styles.secondary].join(" ")}
								onClick={() => {
									const DTO = {
										event_name: "make_your_own_hamper",
										event_params: {
											heading: data[activeStep].label,
											event_label: "back",
											validation_message: "",
										},
									};
									ga4.event("back_button", DTO);
									window.parent.postMessage(
										{
											func: "ga_event_func",
											message: DTO,
										},
										"*"
									);
									setActiveStep((prev) => {
										if (prev - 1 >= 0) {
											return prev - 1;
										}
										return prev;
									});
									setShowCart(false);
								}}
							>
								BACK
							</button>
						)}
						<button
							className={[
								styles.button,
								styles.primary,
								!validateStep() && styles.disabled,
							].join(" ")}
							onClick={() => {
								if (!validateStep()) {
									setError("Please select an item to proceed");
									setShowError(true);
									const DTO = {
										event_name: "make_your_own_hamper",
										event_params: {
											heading: data[activeStep].label,
											event_label: "next",
											validation_message: "Please select an item to proceed",
										},
									};
									ga4.event("next_button", DTO);
									window.parent.postMessage(
										{
											func: "ga_event_func",
											message: DTO,
										},
										"*"
									);
									return;
								}
								if (activeStep < data.length - 1) {
									const DTO = {
										event_name: "make_your_own_hamper",
										event_params: {
											heading: data[activeStep].label,
											event_label: "next",
											validation_message: "",
										},
									};
									ga4.event("next_button", DTO);
									window.parent.postMessage(
										{
											func: "ga_event_func",
											message: DTO,
										},
										"*"
									);
									setActiveStep((prev) => {
										if (prev + 1 < data.length) {
											return prev + 1;
										}
										return prev;
									});
									setShowCart(false);
								} else {
									handleBuyNow();
								}
							}}
						>
							{activeStep === data.length - 1 ? "BUY NOW" : "NEXT"}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FlowerAura;
