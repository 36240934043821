import React from "react";
import ReactDOM from "react-dom";
//import 'intro.js/introjs.css';
//import 'intro.js/themes/introjs-modern.css';

import App from "./App";
import "../src/Components/Campaign/font.css";

if (process.env.NODE_ENV === "production") {
	console.log = () => {};

	console.log = (...args) => {};
}

if (!Array.prototype.toSorted) {
	Array.prototype.toSorted = function(compareFunction) {
	  // Create a shallow copy of the array
	  const copy = this.slice();
	  // Use the Array.prototype.sort() method on the copy
	  return copy.sort(compareFunction);
    };
  }

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
