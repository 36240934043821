import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../Images/myEra_NewLogo.png";
import Button from "@material-ui/core/Button";
import AnimatedImage from "../Images/AnimatedImage.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./facebookBtn.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Signup.css";

toast.configure();

const validationSchema0 = Yup.object({
  first_name: Yup.string().max(255).required("required"),
  last_name: Yup.string().max(255).required("required"),
  email: Yup.string().email("Must be a valid email").required("required"),
  password: Yup.string().min(6).required("required"),
});

export default function Signup(props) {
  let history = useHistory();

  useEffect(() => {
    document.title = "MyEra | Signup";
  }, []);

  const formik0 = useFormik({
    initialValues: {
      first_name: "",
      last_name: " ",
      email: props.location.aboutProps,
      password: "",
      consent: "",
    },
    validationSchema: validationSchema0,
    onSubmit: async ({ first_name, last_name, email, password }) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/register/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ first_name, last_name, email, password }),
        }
      );
      const json = await response.json();
      if (json.access) {
        toast.success("Registered successfully! Welcome to MyEra", {
          position: "top-center",
          theme: "light",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        localStorage.setItem(
          "Erajwt",
          JSON.stringify({
            email,
            accessToken: json.access,
            refreshToken: json.refresh,
            first_name: json.first_name,
            company_type: json.user_details.company_type,
            industry: json.user_details.industry,
            brand_name: json.user_details.brand_name,
            brand_logo: json.user_details.brand_logo,
            userRegistered: json.user_details.isPremium,
            is_facebook_linked: json.user_details.is_facebook_linked,
            is_linkedin_linked: json.user_details.is_linkedin_linked,
            is_twitter_linked: json.user_details.is_twitter_linked,
          })
        );
        history.push("/dashboard");
      } else {
        alert("User with this email already exists");
      }
    },
  });

  const getStarted = async () => {
    if (!document.getElementById("consentCheck").checked) {
      formik0.setErrors({ consent: "Consent is required" });
    } else {
      formik0.handleSubmit();
    }
  };

  return (
    <div>
      <Link to="/">
        <div className="NavDiv">
          <img
            className="EraLogo"
            src={Logo}
            alt="Logo"
            style={{ marginRight: "5px" }}
          />
        </div>
      </Link>

      <div style={{ overflow: "hidden" }}>
        <img className="AnimatedImgDiv" src={AnimatedImage} alt="side " />
      </div>

      <div className="Wrapper">
        <div className="BodyContainer">
          <h1 className="Title">
            Your social media marketing journey begins here
          </h1>

          <>
            <label className="InputLabel">Full Name*</label>
            <div className="InputWrapper">
              <input
                className="Input"
                id="first_name"
                value={formik0.values.first_name}
                type="text"
                onChange={formik0.handleChange}
              />
              {formik0.errors.first_name && formik0.touched.first_name && (
                <p className="Paragraph">{formik0.errors.first_name}</p>
              )}
            </div>

            <label className="InputLabel">Email Address*</label>
            <div className="InputWrapper">
              <input
                className="Input"
                id="email"
                value={formik0.values.email}
                onChange={formik0.handleChange}
                type="email"
              />
              {formik0.errors.email && formik0.touched.email && (
                <p className="Paragraph">{formik0.errors.email}</p>
              )}
            </div>
            <label className="InputLabel">Create a Password*</label>
            <div className="InputWrapper">
              <input
                className="Input"
                id="password"
                value={formik0.values.password}
                type="password"
                x
                onChange={formik0.handleChange}
              />

              {formik0.errors.password && formik0.touched.password && (
                <p className="Paragraph">{formik0.errors.password}</p>
              )}
            </div>
            <br />
            <div style={{ display: "flex" }}>
              <input
                style={{ marginTop: "0.5vh" }}
                type="checkbox"
                id="consentCheck"
                name="consentChecked"
              />
              <label htmlFor="consentCheck" className="Conditions">
                <div style={{ marginLeft: "2%" }}> I agree to the </div>
                <Link
                  to="/terms-of-use"
                  style={{ color: "#f0f9ff", marginLeft: "2%" }}
                >
                  {" "}
                  Terms Of Use{" "}
                </Link>
                <div style={{ marginLeft: "2%" }}> and </div>
                <Link
                  to="/privacy-policy"
                  style={{ color: "#f0f9ff", marginLeft: "2%" }}
                >
                  {" "}
                  Privacy Policy{" "}
                </Link>
              </label>
            </div>
            {formik0.errors.consent && (
              <p
                className="Paragraph"
                style={{ textAlign: "left", color: "red" }}
              >
                {formik0.errors.consent}
              </p>
            )}
            <br />
            <Button
              style={{
                backgroundColor: "rgba(108, 77, 232, 1)",
                fontFamily: "Open Sans",
                borderRadius: "5vh",
                textTransform: "none",
                height: "5vh",
              }}
              variant="contained"
              color="secondary"
              fullWidth={true}
              onClick={getStarted}
            >
              Sign Up
            </Button>
          </>

          <div
            style={{
              display: "flex",
              marginTop: "2vh",
              justifyContent: "center",
            }}
          >
            <label className="InputLabel" style={{ textAlign: "center" }}>
              Already have an account?
            </label>

            <Link to="/login" style={{ textDecoration: "none" }}>
              <div
                className="InputLabel"
                style={{
                  textAlign: "center",
                  color: "rgba(255, 255, 255, 0.8)",
                  marginLeft: "1vh",
                }}
              >
                {" "}
                Login here
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
