const appReducer = (draft, action) => {
  switch (action.type) {
    case 'LOADING':
      draft.loading = true;
      break;
    case 'LOADED':
      draft.loading = false;
      break;
  }
};
export default appReducer;
