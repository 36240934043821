import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div style={{ color: 'white', width: '90%', margin: 'auto' }}>
      <div style={{ marginTop: '5vh', fontSize: '5vh' }}>Refund Policy</div>
      <div style={{ fontSize: '3vh', marginTop: '4vh', marginBottom: '2vh' }}>Since the Website offers non-tangible, irrevocable goods we do not provide refunds after the product is purchased, which you acknowledge prior to purchasing any product on the Website. Please make sure that you’ve carefully read service description before making a purchase.
</div>
<div style={{ marginTop: '5vh', fontSize: '5vh' }}>Contacting Us</div>
<div style={{ fontSize: '3vh', marginTop: '4vh', marginBottom: '2vh' }}>If you have any questions, concerns, or complaints regarding this refund policy, we encourage you to contact us using the details below:</div>
<div style={{ fontSize: '3vh', marginTop: '4vh', marginBottom: '2vh' }}>support@myera.pro</div>
<div style={{ fontSize: '3vh', marginTop: '4vh', marginBottom: '2vh' }}>This document was last updated on March 19, 2022
</div>
    </div>
  );
}
