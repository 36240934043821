import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar"
import { AssetLibrary } from '../AssetLibrary/AssetLibrary';
import {Helmet} from "react-helmet"

const Wrapper = styled.div`
  display: flex;
  background: rgb(13,16, 22);
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

export default function AssetLibraryMain(){
    return(
        <Wrapper id="outer-container">
          <Helmet>
            <title>MyEra | Asset Library</title>
          </Helmet>
            <Sidebar/>
            <MainDashboard>
                <Topbar/>
            <AssetLibrary></AssetLibrary>
            </MainDashboard>
        </Wrapper>
    )
}