import React, { useEffect, useRef, useState } from "react";
import commonStyles from "../MyeraScript.module.css";
import styles from "./Bakingo.module.css";
import Stage from "../Common/Stage/Stage";
import HorizontalCarousel from "../Common/HorizontalCarousel/HorizontalCarousel";
import axios from "axios";
import { toast } from "react-toastify";
import { TabType } from "../../../Helper/enums";
import SingleImageLayer from "../Common/Stage/Layers/SingleImageLayer";
import MultiImageLayer from "../Common/Stage/Layers/MultiImageLayer";
import MultiTextLayer from "../Common/Stage/Layers/MultiTextLayer";
import SingleImageTab from "./SingleImageTab";
import MultiImageTab from "../Common/Tabs/MultiImageTab";
import MultiTextTab from "../Common/Tabs/MultiTextTab";
import produce from "immer";
import { CircularProgress, Slider } from "@mui/material";
import MagnifyingGlass from "../Common/Assets/magnifying_glass.png";
import ga4 from "react-ga4";
import { HiInformationCircle } from "react-icons/hi";

const GTMContainerId = "G-8CFQ42GSBS";

const Bakingo = () => {
	const [ product, setProduct ] = useState({});
	const [ selectedStage, setSelectedStage ] = useState(0);
	const [ selectedTab, setSelectedTab ] = useState(0);
	const [ tenantID, setTenantID ] = useState(1);
	const [ stages, setStages ] = useState([]);
	const [ carouselRef, setCarouselRef ] = useState(null);

	const [ showLeftScrollArrow, setShowLeftScrollArrow ] = useState(false);
	const [ showRightScrollArrow, setShowRightScrollArrow ] = useState(false);

	const [ isAddingToCart, setIsAddingToCart ] = useState(false);

	const stageRefs = useRef([]);
	const searchParams = new URLSearchParams(window.location.search);

	const product_id = searchParams.get("product_id");

	const [ scriptsLoaded, setScriptsLoaded ] = useState(false);

	const fetchProduct = async () => {
		try {
			const res = await axios.get(
				`https://django.myera.pro/myera/api/bakingo/product/` + product_id
			);
			console.log(res.data);
			setTenantID(res.data.tenant);
			setProduct(res.data);
			let DTO = {
				screen_name: res.data.name,
				page_location: window.location.href,
				heading: "personalize_your_cake_popup_shown",
			};

			ga4.event("shown", DTO);

			

			window.parent.postMessage(
				{
					func: "fireGtagEvent",
					message:{ 
						eventName: "shown",
						eventObject:DTO
					},
				},
				"*"
			);

			const stageLayers = [];

			stageRefs.current = stageRefs.current.slice(0, res.data.templates.length);

			res.data.templates.sort((a, b) => a.priority - b.priority);

			for (let i = 0; i < res.data.templates.length; i++) {
				const template = res.data.templates[ i ];
				const currentProductLayers = [];

				for (let singleImageTab of template.single_image_tabs) {
					currentProductLayers.push({
						id: singleImageTab.id,
						name: singleImageTab.name,
						type: TabType.SingleImage,
						priority: singleImageTab.priority,
						element: singleImageTab.image_element,
						images:
							singleImageTab.image_options.length !== 0
								? singleImageTab.image_options
								: singleImageTab.images,
						selectedImage: null,
						isUploadable: singleImageTab.is_uploadable,
						scale: 1,
						uploadedImages: [],
						stage: i,
						tabPriority: singleImageTab.tab_priority,
						allowNull: singleImageTab.allow_null,
						sendAsLineItem: singleImageTab.send_as_line_item,
						lineItemLabel: singleImageTab.line_item_label,
						export: singleImageTab.export,
						allowCameraUpload: singleImageTab.allow_camera_upload,
						guideline: singleImageTab.guideline,
						elementRef: null,
					});
				}

				for (let multiImageTab of template.multi_image_tabs) {
					currentProductLayers.push({
						id: multiImageTab.id,
						name: multiImageTab.name,
						type: TabType.MultiImage,
						priority: multiImageTab.priority,
						elements: multiImageTab.image_elements,
						images:
							multiImageTab.image_options.length !== 0
								? multiImageTab.image_options
								: multiImageTab.images,
						selectedImages: new Array(multiImageTab.image_elements.length).fill(
							null
						),
						isUploadable: multiImageTab.is_uploadable,
						uploadedImages: [],
						stage: i,
						tabPriority: multiImageTab.tab_priority,
						allowNull: multiImageTab.allow_null,
						sendAsLineItem: multiImageTab.send_as_line_item,
						lineItemLabel: multiImageTab.line_item_label,
						export: multiImageTab.export,
					});
				}

				for (let multiTextTab of template.multi_text_tabs) {
					currentProductLayers.push({
						id: multiTextTab.id,
						name: multiTextTab.name,
						type: TabType.MultiText,
						priority: multiTextTab.priority,
						elements: multiTextTab.text_elements,
						text: undefined,
						fontSize: multiTextTab.font_size_options[ 0 ],
						fontSizeOptions: multiTextTab.font_size_options,
						fontColor: multiTextTab.font_color_options[ 0 ],
						fontColorOptions: multiTextTab.font_color_options,
						fontFamily: multiTextTab.font_family_options[ 0 ],
						fontFamilyOptions: multiTextTab.font_family_options,
						stage: i,
						tabPriority: multiTextTab.tab_priority,
						allowNull: multiTextTab.allow_null,
						defaultSize: multiTextTab.default_size,
						characterLimit: multiTextTab.character_limit,
						sendAsLineItem: multiTextTab.send_as_line_item,
						lineItemLabel: multiTextTab.line_item_label,
						multiline: multiTextTab.multiline,
						export: multiTextTab.export,
					});
				}

				for (let fixedImageElement of template.fixed_image_elements) {
					currentProductLayers.push({
						id: fixedImageElement.id,
						name: fixedImageElement.name,
						type: TabType.FixedImage,
						priority: fixedImageElement.priority,
						element: fixedImageElement,
						selectedImage: fixedImageElement.default_image.asset,
						stage: i,
						export: fixedImageElement.export,
					});
				}

				currentProductLayers.sort((a, b) => a.tabPriority - b.tabPriority);
				stageLayers.push(currentProductLayers);
			}

			setStages(stageLayers);

			for (let i = 0; i < stageLayers[ 0 ].length; i++) {
				if (stageLayers[ 0 ][ i ].type !== TabType.FixedImage) {
					setSelectedTab(i);
					break;
				}
			}
		} catch (err) {
			console.log(err);
			toast.error("Something went wrong", { toastId: "error3" });
		}
	};

	const handleStageExport = (stageRef) => {
		if (stageRef) {
			stageRef.children.forEach((layer) => {
				console.log("Layer", layer);
				if (layer.attrs?.className === "myera-layer-ignore") {
					layer.destroy();
				}
			});
			const uri = stageRef.toDataURL({ pixelRatio: 2.16 });
			const data = uri.split(",")[ 1 ];
			const byteString = atob(data);
			const mimeString = "image/png";
			const ab = new ArrayBuffer(byteString.length);
			const ia = new Uint8Array(ab);
			for (let i = 0; i < byteString.length; i++) {
				ia[ i ] = byteString.charCodeAt(i);
			}
			const file = new Blob([ ab ], { type: mimeString });

			return file;
		}
	};

	const getS3URL = async (blob, name) => {
		try {
			const formData = new FormData();

			formData.append("asset_name", name);
			formData.append("tenant_id", tenantID);
			formData.append("asset", blob);

			// Upload to S3
			const res = await axios.post(
				"https://django.myera.pro/myera/api/save_client_user_asset/",
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			console.log(res.data);
			return res.data.url;
		} catch (err) {
			console.log(err);
			toast.error("Couldn't get s3 URL", { toastId: "s3urlerror" });
			return "";
		}
	};

	const getIsometricImage = async () => {
		const stageRef = stageRefs.current[ 0 ];
		if (product.isometric_view) {
			const blob = handleStageExport(stageRef);
			try {
				const formData = new FormData();
				formData.append("s3_image_url", product.isometric_view.asset);
				formData.append("face_image", blob);
				const res = await axios.post("https://django.myera.pro/quarkme/api/shopify/perspective_image/bakingo/", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				});
				return res.data.url;
			} catch (e) {
				console.log(e);
				toast.error("Isometric Image could not be processed");
				return null;
			}
		}
		return null;
	};

	const getProductProperties = async () => {
		const data = {};

		for (let i = 0; i < stageRefs.current.length; i++) {
			const stageRef = stageRefs.current[ i ];
			const blob = handleStageExport(stageRef);
			const url = await getS3URL(blob, "stage_" + i);
			data[ `Stage ${i + 1}` ] = url;
		}

		const isometricUrl = await getIsometricImage();

		if (isometricUrl) {
			data[ "Isometric" ] = isometricUrl;
		}

		for (let tabs of stages) {
			for (let tab of tabs) {
				if (!tab.sendAsLineItem) {
					continue;
				}
				if (tab.type === TabType.SingleImage) {
					if (!tab.allowNull && !tab.selectedImage) {
						throw new Error("Please select an image for " + tab.name);
					}
					if (!tab.selectedImage) {
						data[ tab.lineItemLabel ] = tab.element.default_image.asset;
					} else if (tab.selectedImage.thumbnail.startsWith("https://")) {
						data[ tab.lineItemLabel ] = tab.selectedImage.thumbnail;
					} else {
						const url = await getS3URL(
							await fetch(tab.selectedImage.thumbnail).then((r) => r.blob()),
							tab.name + "_user_upload"
						);
						data[ tab.lineItemLabel ] = url;
					}
				} else if (tab.type === TabType.MultiImage) {
					if (!tab.allowNull && !tab.selectedImages.length) {
						throw new Error("Please select an image for " + tab.name);
					}
					if (!tab.isUploadable) {
						continue;
					}
					const images = [];
					for (let image of tab.selectedImages) {
						if (image.startsWith("https://")) {
							images.push(image);
						} else {
							const url = await getS3URL(
								await fetch(image).then((r) => r.blob()),
								tab.name + "_user_upload"
							);
							images.push(url);
						}
					}
					for (let i = 0; i < images.length; i++) {
						data[ `${tab.name} Image ${i + 1}` ] = images[ i ];
					}
				} else if (tab.type === TabType.MultiText) {
					if (!tab.allowNull && !tab.text) {
						throw new Error("Please enter text for " + tab.name);
					}
					data[ tab.lineItemLabel ] = tab.text;
					data[ `${tab.name} Size` ] = tab.fontSize;
					data[ `${tab.name} Color` ] = tab.fontColor;
					data[ `${tab.name} Font` ] = tab.fontFamily;
				}
			}
		}

		return data;
	};

	const validateNavigation = (showToast = true,event) => {
		// Validate current tab

		if (stages.length === 0) return false;

		const currentTab = stages[ selectedStage ][ selectedTab ];
		if (currentTab.type === TabType.MultiText) {
			if (!currentTab.allowNull && !currentTab.text && (event!==undefined && !(Number(event.dataset.priority)<currentTab.priority))) {
				if (showToast)
					toast.error("Please enter text for " + currentTab.name, {
						toastId: "bakingo_error_text",
					});
				return false;
			}
		} else if (currentTab.type === TabType.SingleImage) {
			if (!currentTab.allowNull && !currentTab.selectedImage && (event!==undefined && !(Number(event.dataset.priority)<currentTab.priority))) {
				if (showToast)
					toast.error("Please select an image for " + currentTab.name, {
						toastId: "bakingo_select_image",
					});
				return false;
			}
			if (!currentTab.allowNull && currentTab.selectedImage) {
				if (!currentTab.selectedImage.image && (event!==undefined && !(Number(event.dataset.priority)<currentTab.priority))) {
					if (showToast)
						toast.error("Please select an image for " + currentTab.name, {
							toastId: "bakingo_select_image",
						});
					return false;
				}
			}
		} else if (currentTab.type === TabType.MultiImage) {
			if (!currentTab.allowNull && !currentTab.selectedImages.length && (event!==undefined && !(Number(event.dataset.priority)<currentTab.priority))) {
				if (showToast)
					toast.error("Please select an image for " + currentTab.name, {
						toastId: "bakingo_multi_iamge",
					});
				return false;
			}
		}

		return true;
	};

	const disableValidation = (showToast = true) => {
		// Validate current tab

		if (stages.length === 0) return false;

		const currentTab = stages[ selectedStage ][ selectedTab ];
		if (currentTab.type === TabType.MultiText) {
			if (!currentTab.allowNull && !currentTab.text ) {
				if (showToast)
					toast.error("Please enter text for " + currentTab.name, {
						toastId: "bakingo_error_text",
					});
				return false;
			}
		} else if (currentTab.type === TabType.SingleImage) {
			if (!currentTab.allowNull && !currentTab.selectedImage ) {
				if (showToast)
					toast.error("Please select an image for " + currentTab.name, {
						toastId: "bakingo_select_image",
					});
				return false;
			}
			if (!currentTab.allowNull && currentTab.selectedImage) {
				if (!currentTab.selectedImage.image ) {
					if (showToast)
						toast.error("Please select an image for " + currentTab.name, {
							toastId: "bakingo_select_image",
						});
					return false;
				}
			}
		} else if (currentTab.type === TabType.MultiImage) {
			if (!currentTab.allowNull && !currentTab.selectedImages.length ) {
				if (showToast)
					toast.error("Please select an image for " + currentTab.name, {
						toastId: "bakingo_multi_iamge",
					});
				return false;
			}
		}

		return true;
	};
	const handleAddToCart = async () => {
		setIsAddingToCart(true);

		
		let DTO = {
			screen_name: product.name,
			page_location: window.location.href,
			button_name: "add_to_cart",
			heading: "add_to_cart_clicked",
		};

		window.parent.postMessage(
			{
				func: "fireGtagEvent",
				message:{ 
					eventName: "button_click",
					eventObject:DTO
				},
			},
			"*"
		);

		ga4.event("button_click", DTO);

		try {
			let data = await getProductProperties();
			data[ "Preview" ] = data[ "Stage 1" ];

			delete data[ "Stage 1" ];

			console.log(data);

			window.parent.postMessage(
				{
					func: "addToCart",
					message: data,
				},
				"*"
			);
		} catch (err) {
			toast.error(err.message);
		} finally {
			setIsAddingToCart(false);
		}
	};

	const handleBuyNow = async () => {
		const data = await getProductProperties();
		console.log(data);

		window.parent.postMessage(
			{
				func: "buyNow",
				message: data,
			},
			"*"
		);
	};

	const hasPrevTab = () => {
		if (selectedStage !== 0) {
			return true;
		}

		if (selectedTab === 0) {
			return false;
		}

		let prevTab = selectedTab - 1;

		while (prevTab >= 0) {
			if (stages[ selectedStage ][ prevTab ].type !== TabType.FixedImage) {
				return true;
			}

			prevTab--;
		}

		return false;
	};

	const hasNextTab = () => {
		if (selectedStage !== stages.length - 1) {
			return true;
		}

		if (selectedTab === stages[ selectedStage ].length - 1) {
			return false;
		}

		let nextTab = selectedTab + 1;

		while (nextTab < stages[ selectedStage ].length) {
			if (stages[ selectedStage ][ nextTab ].type !== TabType.FixedImage) {
				return true;
			}

			nextTab++;
		}

		return false;
	};

	const handleOutOfFocus = (textBeforeFocusOut) => {
		
		let DTO = {
			screen_name: product.name,
			page_location: window.location.href,
			field_name: "message_on_cake",
			field_text: textBeforeFocusOut,
		};
		window.parent.postMessage(
			{
				func: "fireGtagEvent",
				message:{ 
					eventName: "field_text",
					eventObject:DTO
				},
			},
			"*"
		);

		ga4.event("field_text", DTO);
	};

	const renderTab = () => {
		if (stages.length === 0) {
			return <></>;
		}
		const tab = stages[ selectedStage ][ selectedTab ];
		switch (tab.type) {
			case TabType.SingleImage:
				return (
					<SingleImageTab
						images={ tab.images }
						allowNull={ tab.allowNull }
						selectedImage={ tab.selectedImage }
						uploadedImages={ tab.uploadedImages }
						productName={ product.name }
						onSelectImage={ (image) => {
							setStages(
								produce(stages, (draft) => {
									draft[ selectedStage ][ selectedTab ].selectedImage = image;
									if (draft[ selectedStage ][ selectedTab ].uploadedImages) {
										if (
											!draft[ selectedStage ][
												selectedTab
											].uploadedImages.includes(image.image)
										) {
											draft[ selectedStage ][ selectedTab ].scale = 1;
										}
									}
								})
							);
						} }
						isUploadable={ tab.isUploadable }
						setUploadedImages={ (images) => {
							setStages(
								produce(stages, (draft) => {
									if (images.length === 0) {
										draft[ selectedStage ][ selectedTab ].scale = 1;
										draft[ selectedStage ][ selectedTab ].elementRef.x(tab.element.x + tab.element.width / 2);
										draft[ selectedStage ][ selectedTab ].elementRef.y(tab.element.y + tab.element.height / 2);
									}

									draft[ selectedStage ][ selectedTab ].uploadedImages = images;
									draft[ selectedStage ][ selectedTab ].selectedImage = {
										image: images[ 0 ],
										thumbnail: images[ 0 ],
									};
								})
							);
						} }
						scale={ tab.scale }
						onScaleChange={ (scale) => {
							setStages(
								produce(stages, (draft) => {
									draft[ selectedStage ][ selectedTab ].scale = scale;
								})
							);
						} }
						allowCameraUpload={ tab.allowCameraUpload }
						guideline={ tab.guideline }
					/>
				);
			case TabType.MultiImage:
				return (
					<MultiImageTab
						images={ tab.images }
						allowNull={ tab.allowNull }
						selectedImages={ tab.selectedImages }
						isUploadable={ tab.isUploadable }
						uploadedImages={ tab.uploadedImages }
						onSelectImage={ (image) => {
							setStages(
								produce(stages, (draft) => {
									if (
										draft[ selectedStage ][ selectedTab ].selectedImages.includes(
											image
										)
									) {
										const idx =
											draft[ selectedStage ][ selectedTab ].selectedImages.indexOf(
												image
											);
										draft[ selectedStage ][ selectedTab ].selectedImages[ idx ] =
											null;
									} else {
										if (
											draft[ selectedStage ][ selectedTab ].selectedImages.includes(
												null
											)
										) {
											const idx =
												draft[ selectedStage ][
													selectedTab
												].selectedImages.indexOf(null);
											draft[ selectedStage ][ selectedTab ].selectedImages[ idx ] =
												image;
										} else {
											toast.error(
												"You can only select " +
												tab.selectedImages.length +
												" images",
												{ toastId: "bakingo_error1" }
											);
										}
									}
								})
							);
						} }
						setUploadedImages={ (images) => {
							setStages(
								produce(stages, (draft) => {
									draft[ selectedStage ][ selectedTab ].uploadedImages = images;
								})
							);
						} }
					/>
				);
			case TabType.MultiText:
				return (
					<MultiTextTab
						key={ `multi_text_${tab.id}` }
						heading="Name on Cake"
						fontColor={ tab.fontColor }
						allowNull={ tab.allowNull }
						defaultSize={ tab.defaultSize }
						characterLimit={ tab.characterLimit }
						fontColorOptions={ tab.fontColorOptions }
						fontFamily={ tab.fontFamily }
						fontFamilyOptions={ tab.fontFamilyOptions }
						fontSize={ tab.fontSize }
						fontSizeOptions={ tab.fontSizeOptions }
						text={ tab.text }
						placeholder={ tab.elements[ 0 ].default_text }
						multiline={ tab.multiline }
						showSingleOption={ false }
						setText={ (text) => {
							setStages(
								produce(stages, (draft) => {
									draft[ selectedStage ][ selectedTab ].text = text;
								})
							);
						} }
						setFontColor={ (color) => {
							setStages(
								produce(stages, (draft) => {
									draft[ selectedStage ][ selectedTab ].fontColor = color;
								})
							);
						} }
						setFontFamily={ (family) => {
							setStages(
								produce(stages, (draft) => {
									draft[ selectedStage ][ selectedTab ].fontFamily = family;
								})
							);
						} }
						setFontSize={ (size) => {
							setStages(
								produce(stages, (draft) => {
									draft[ selectedStage ][ selectedTab ].fontSize = size;
								})
							);
						} }
						onLoseFocus={ handleOutOfFocus }
					/>
				);
			default:
				return <></>;
		}
	};

	useEffect(() => {
		fetchProduct();
		document
			.querySelector(":root")
			.style.setProperty("--myera-script-primary-color", "#FF5F5D");
		document
			.querySelector(":root")
			.style.setProperty("--myera-script-font-family", "Poppins");
		document
			.getElementById(commonStyles.elements_header)
			.addEventListener("scroll", (e) => {
				const el = e.target;
				console.log(el.scrollLeft, el.scrollWidth, el.clientWidth);
				if (el.scrollLeft > 0) {
					setShowLeftScrollArrow(true);
				} else {
					setShowLeftScrollArrow(false);
				}
				if (el.scrollLeft + el.clientWidth < el.scrollWidth - 2) {
					setShowRightScrollArrow(true);
				} else {
					setShowRightScrollArrow(false);
				}
			});

		setShowRightScrollArrow(
			document.getElementById(commonStyles.elements_header).scrollWidth >
			document.getElementById(commonStyles.elements_header).clientWidth +
			document.getElementById(commonStyles.elements_header).scrollLeft
		);

		window.addEventListener("message", (event) => {
			if (event.data.added) {
				if (event.data.added) {
					toast.success("Added to cart", {
						position: "top-center",
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						onClose: () => {
							window.parent.postMessage(
								{
									func: "closeMyEraModal",
								},
								"*"
							);
						},
					});
					window.location.reload(false);
				} else {
					toast.error("Failed to add to cart", {
						position: "top-center",
						autoClose: 2000,
						toastId: "error2",
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						onClose: () => {
							window.parent.postMessage(
								{
									func: "closeMyEraModal",
								},
								"*"
							);
						},
					});
				}
			}
		});
	}, []);

	useEffect(() => {
		if (carouselRef) {
			carouselRef.current.resetIndex(selectedStage);
		}
	}, [ selectedStage, selectedTab ]);

	useEffect(() => {
		const tabToBeScrolled = document.getElementById(
			`myera_tab_${selectedStage}_${selectedTab}`
		);
		if (tabToBeScrolled) {
			tabToBeScrolled.scrollIntoView({
				behavior: "smooth",
			});
		}
	}, [ selectedTab, selectedStage ]);

	return (
		<div className={ [ commonStyles.wrapper, styles.wrapper ].join(" ") }>
			{ isAddingToCart && (
				<div className={ commonStyles.loader }>
					<CircularProgress
						sx={ {
							marginRight: "8px",
							color: "var(--myera-script-primary-color)",
						} }
					/>
					Adding to cart
				</div>
			) }
			<div className={ [ commonStyles.header, styles.header ].join(" ") }>
				<div className={ [ commonStyles.title, styles.title ].join(" ") }>
					Personalize your cake
				</div>
				<div
					className={ [ commonStyles.subtitle, styles.subtitle ].join(" ") }
				></div>
			</div>
			<div
				className={ [ commonStyles.main_container, styles.main_container ].join(
					" "
				) }
			>
				<div className={ [ commonStyles.preview, styles.preview ].join(" ") }>
					<HorizontalCarousel
						updateCarouselRef={ (ref) => {
							setCarouselRef(ref);
						} }
					>
						{ stages.map((stage, sIdx) => {
							return (
								<Stage
									enableInlineZoom={ false }
									stageRef={ (ref) => {
										stageRefs.current[ sIdx ] = ref;
									} }
									layers={ stage
										.toSorted((a, b) => a.priority - b.priority)
										.map((layer, idx) => {
											if (layer.type === TabType.SingleImage) {
												return (
													<SingleImageLayer
														key={ idx }
														element={ layer.element }
														selectedImage={ layer.selectedImage?.image }
														scale={ layer.scale }
														exportLayer={ layer.export ?? true }
														setImageRef={ (ref) => {
															const layerIdx = stage.findIndex((l) => l.type === TabType.SingleImage && l.id === layer.id);
															if (ref && !stages[ sIdx ][ layerIdx ].elementRef) {
																console.log(ref.x());
																setStages(
																	produce(stages, (draft) => {
																		draft[ sIdx ][ layerIdx ].elementRef = ref;
																	}));
															}

														} }
													/>
												);
											} else if (layer.type === TabType.MultiImage) {
												return (
													<MultiImageLayer
														key={ idx }
														elements={ layer.elements }
														selectedImages={ layer.selectedImages }
														exportLayer={ layer.export ?? true }
													/>
												);
											} else if (layer.type === TabType.MultiText) {
												return (
													<MultiTextLayer
														key={ idx }
														elements={ layer.elements }
														text={ layer.text }
														fontSize={ layer.fontSize || layer.defaultSize }
														fontColor={ layer.fontColor }
														fontFamily={ layer.fontFamily }
														exportLayer={ layer.export ?? true }
													/>
												);
											} else if (layer.type === TabType.FixedImage) {
												return (
													<SingleImageLayer
														key={ idx }
														element={ layer.element }
														selectedImage={ layer.selectedImage }
														exportLayer={ layer.export ?? true }
													/>
												);
											}
										}) }
								/>
							);
						}) }
						{ [] }
					</HorizontalCarousel>
					{ stages.length > 0 &&
						stages[ selectedStage ][ selectedTab ].type === TabType.SingleImage &&
						stages[ selectedStage ][ selectedTab ].uploadedImages.length > 0 && (
							<div className={ styles.info_container }>
								<HiInformationCircle /> Drag the image to adjust it
							</div>
						) }
				</div>
				{ stages.length > 0 && (
					<div
						className={ [
							commonStyles.slider_container,
							styles.slider_container,
						].join(" ") }
						style={ {
							maxWidth:
								stages[ selectedStage ][ selectedTab ].isUploadable &&
									stages[ selectedStage ][ selectedTab ].uploadedImages.length &&
									stages[ selectedStage ][ selectedTab ].uploadedImages.includes(
										stages[ selectedStage ][ selectedTab ].selectedImage.image
									)
									? "1000px"
									: "0px",
							maxHeight:
								stages[ selectedStage ][ selectedTab ].isUploadable &&
									stages[ selectedStage ][ selectedTab ].uploadedImages.length &&
									stages[ selectedStage ][ selectedTab ].uploadedImages.includes(
										stages[ selectedStage ][ selectedTab ].selectedImage.image
									)
									? "1000px"
									: "0px",
						} }
					>
						<Slider
							sx={ {
								root: {
									color: "var(--myera-script-primary-color)",
								},
								"& .MuiSlider-thumb": {
									backgroundColor: "var(--myera-script-primary-color)",
									border: "2px solid var(--myera-script-primary-color)",
									width: "32px",
									height: "32px",
									backgroundImage: `url(${MagnifyingGlass})`,
									backgroundSize: "16px",
									backgroundRepeat: "no-repeat",
									backgroundPosition: "center",
								},
								"& .MuiSlider-track": {
									backgroundColor: "var(--myera-script-primary-color)",
								},
								"& .MuiSlider-rail": {
									backgroundColor: "var(--myera-script-primary-color)",
								},
								"&.Mui-disabled": {
									"& .MuiSlider-thumb": {
										backgroundColor: "#bdbdbd",
										border: "2px solid #bdbdbd",
									},

									"& .MuiSlider-track": {
										backgroundColor: "#bdbdbd",
									},

									"& .MuiSlider-rail": {
										backgroundColor: "#bdbdbd",
									},
								},
							} }
							value={ stages[ selectedStage ][ selectedTab ].scale }
							onChange={ (e, val) => {
								setStages(
									produce(stages, (draft) => {
										draft[ selectedStage ][ selectedTab ].scale = val;
									})
								);
							} }
							min={ 0.5 }
							max={ 1.5 }
							step={ 0.05 }
						/>
					</div>
				) }
				<div className={ [ commonStyles.elements, styles.elements ].join(" ") }>
					<div
						id={ commonStyles.elements_header }
						className={ [
							commonStyles.elements_header,
							styles.elements_header,
						].join(" ") }
					>
						{ stages.map((stage, stageIdx) => {
							return stage.map((tab, idx) =>
								tab.type === TabType.FixedImage ? (
									<></>
								) : (
									<div
										id={ `myera_tab_${stageIdx}_${idx}` }
										data-priority={tab.priority}
										className={ [
											commonStyles.element_title,
											styles.element_title,
											selectedStage === stageIdx &&
											selectedTab === idx &&
											commonStyles.selected,
											selectedStage === stageIdx &&
											selectedTab === idx &&
											styles.selected,
										].join(" ") }
										onClick={ (mouseevent) => {
											if (selectedTab === idx) {
												// Ignore existing tab click
												return;
											}
											if (!validateNavigation(true,mouseevent.target)) {
												return;
											}
											setSelectedStage(stageIdx);
											setSelectedTab(idx);
										} }
									>
										{ tab.name }
									</div>
								)
							);
						}) }
					</div>
					{ renderTab() }
					<div className={ [ commonStyles.cta, styles.cta ].join(" ") }>
						<div
							className={ [ commonStyles.cta_right, styles.cta_right ].join(" ") }
						>
							{ hasNextTab() ? (
								<button
									disabled={ !disableValidation(false) }
									className={ [
										commonStyles.button,
										styles.button,
										commonStyles.primary,
										styles.primary,
									].join(" ") }
									onClick={ (mouseevent) => {
										if (!validateNavigation(true,mouseevent.target)) {
											return;
										}

										// Go to Next Non Fixed Image Tab
										let nextTab = selectedTab + 1;
										let nextStage = selectedStage;

										while (nextStage < stages.length) {
											if (nextTab >= stages[ nextStage ].length) {
												nextStage++;
												nextTab = 0;
											}
											if (
												stages[ nextStage ][ nextTab ].type !== TabType.FixedImage
											) {
												break;
											}
											nextTab++;
										}

										
										let DTO = {
											screen_name: product.name,
											page_location: window.location.href,
											button_name: "save_&_continue",
											heading: "save_&_continue",
										};
										window.parent.postMessage(
											{
												func: "fireGtagEvent",
												message:{ 
													eventName: "button_click",
													eventObject:DTO
												},
											},
											"*"
										);

										ga4.event("button_click", DTO);

										setSelectedStage(nextStage);
										setSelectedTab(nextTab);
									} }
								>
									Save & Continue
								</button>
							) : (
								<button
									disabled={ !disableValidation(false) || isAddingToCart }
									className={ [
										commonStyles.button,
										styles.button,
										commonStyles.primary,
										styles.primary,
									].join(" ") }
									onClick={ handleAddToCart }
								>
									{ isAddingToCart ? (
										<CircularProgress
											size={ 24 }
											sx={ {
												color: "white",
												margin: "0 24px",
											} }
										/>
									) : (
										"Add to Cart"
									) }
								</button>
							) }
						</div>
					</div>
				</div>
			</div>
			<div className={ [ commonStyles.cta_mobile, styles.cta_mobile ].join(" ") }>
				<div className={ [ commonStyles.cta_right, styles.cta_right ].join(" ") }>
					{ hasNextTab() ? (
						<button
							disabled={ !disableValidation(false) }
							className={ [
								commonStyles.button,
								styles.button,
								commonStyles.primary,
								styles.primary,
							].join(" ") }
							onClick={ (mouseevent) => {
								if (!validateNavigation(true,mouseevent.target)) {
									return;
								}

								// Go to Next Non Fixed Image Tab
								let nextTab = selectedTab + 1;
								let nextStage = selectedStage;

								while (nextStage < stages.length) {
									if (nextTab >= stages[ nextStage ].length) {
										nextStage++;
										nextTab = 0;
									}
									if (stages[ nextStage ][ nextTab ].type !== TabType.FixedImage) {
										break;
									}
									nextTab++;
								}

								setSelectedStage(nextStage);
								setSelectedTab(nextTab);
							} }
						>
							Save & Continue
						</button>
					) : (
						<button
							disabled={ isAddingToCart || !disableValidation(false) }
							className={ [
								commonStyles.button,
								styles.button,
								commonStyles.primary,
								styles.primary,
							].join(" ") }
							onClick={ handleAddToCart }
						>
							{ isAddingToCart ? (
								<CircularProgress
									size={ 24 }
									sx={ {
										color: "white",
										margin: "0 10px",
									} }
								/>
							) : (
								"Add to Cart"
							) }
						</button>
					) }
				</div>
			</div>
		</div>
	);
};

export default Bakingo;
