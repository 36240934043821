import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./template_event.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import facebook from "./facebook.png";
import instagram from "./instagram.png";
import twitter from "./twitter.png";
import default_image from "./default_image.png";
import Modal from "react-modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
// import CampaignModal from "../Campaign/CampaignModal";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";
import { openModalService } from "../Generic/messageService";
import axios from "axios";
import coloredLinkedIn from "../Images/LinkedinLogo.png";
import PersonIcon from "@mui/icons-material/Person";

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: 700,
		width: 950,
		color: "rgb(132, 232, 244)",
		padding: "0%",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

const savedModalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: 500,
		width: 1200,
		color: "rgb(132, 232, 244)",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

const Input = styled("input")({
	display: "none",
});

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "rgb(132, 232, 244)",
		},
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

class PostModal extends Component {
	state = {
		modalIsOpen: this.props.modalIsOpen,
		postCaption: "",
		inPosting: false,
		imageIsUploaded: this.props.imageIsUploaded
			? this.props.imageIsUploaded
			: false,
		currentPostImage: this.props.postImage ? this.props.postImage : "",
		currentPostImageFile: null,
		selectedDate: new Date(),
		imageOption: this.props.imageOption ? this.props.imageOption : "none",
		isScheduledPost: true,
		postOption: this.props.postOption ? this.props.postOption : "postnow",
		selectedPlatforms: [],
		inPosting: false,
		saved_creatives: [],
		isSavedModalOpen: false,
		isCampaignModalOpen: false,
		selectedScheduledPostId: null,
		isSavedPost: false,
		insta_username: "instagram",
		fb_page_name: "facebook",
		twitter_name: "twitter",
		linkedin_org_name: "linkedin",
		linkedin_profile_name: "linkedin personal",
		is_linkedin_org_linked: "linkedin page",
		is_facebook_linked: true,
		is_twitter_linked: true,
		is_linkedin_linked: true,
	};

	constructor(props) {
		super(props);

		openModalService.getMessage().subscribe((message) => {
			if (message.modalState === "newPost") {
				this.openModal();
			}

			if (message.modalState === "scheduledPost") {
				this.setState(message.stateVariables);
			}

			if (message.modalState === "savedPost") {
				this.setState(message.stateVariables);
			}
		});
	}

	openModal() {
		this.setState({ modalIsOpen: true });
		// this.props.openModal();
		console.log("IN OPEN MODAL");
	}

	closeModal() {
		this.setState({ modalIsOpen: false });

		if (!this.state.isScheduledPost) {
			this.setState({
				isScheduledPost: true,
				selectedDate: new Date(),
				currentPostImage: default_image,
				postCaption: "",
				imageOption: "none",
				postOption: "postnow",
			});
		}
	}

	handleCaptionText(e) {
		this.setState({
			postCaption: e.target.value,
		});
	}

	onFileChange(event) {
		var url = URL.createObjectURL(event.target.files[0]);
		this.setState({
			imageIsUploaded: true,
			currentPostImage: url,
			currentPostImageFile: event.target.files[0],
		});
	}

	handleSelectedDate(value) {
		this.setState({ selectedDate: value.toString().split("GMT")[0].trim() });
		console.log(this.state.selectedDate);
	}

	setImageOption(event) {
		if (event.target.getAttribute("id") === "uploadImage")
			this.setState({ imageOption: "uploadImage" });

		if (event.target.getAttribute("id") === "imageSaved")
			this.setState({ imageOption: "imageSaved" });
	}

	onPostRadioChange(event) {
		this.setState({ postOption: event.target.value });
	}

	handleChange = (event) => {
		const {
			target: { value },
		} = event;
		this.setState({
			selectedPlatforms: typeof value === "string" ? value.split(",") : value,
		});
		console.log(value);
		console.log(this.state.selectedPlatforms);
	};

	getSavedCreatives() {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/get_saved_creatives/`, { headers })
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				this.setState({ saved_creatives: data });
			});

		console.log(this.state.saved_creatives);
	}

	handleSavedCreativesButton(event) {
		if (event.target.getAttribute("id") === "imageSaved")
			this.setState({ imageOption: "imageSaved" });

		this.setState({ isSavedModalOpen: true });
		this.getSavedCreatives();
		var user_data = JSON.parse(localStorage.getItem("Erajwt"));
		this.setState({
			insta_username: user_data.insta_username,
			fb_page_name: user_data.fb_page_name,
			is_facebook_linked: user_data.is_facebook_linked,
		});
	}

	handleSavedCreativeClick(e, thumbnail) {
		this.setState({
			currentPostImage: thumbnail,
			isSavedModalOpen: false,
			imageIsUploaded: true,
		});
	}

	handleCreateNow() {
		this.setState({ isCampaignModalOpen: true, modalIsOpen: false });
	}

	publsih_fb_post(requestOptions) {
		fetch(`${process.env.REACT_APP_BACKEND}/publish_fb_post/`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				toast.success("Post published to Facebook Successfully!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
					inPosting: false,
				});
			});
	}

	publsih_insta_post(requestOptions) {
		fetch(
			`${process.env.REACT_APP_BACKEND}/publish_insta_post/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				toast.success("Post published to Instagram Successfully!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
					inPosting: false,
				});
			});
	}

	publish_twitter_post(requestOptions) {
		fetch(`${process.env.REACT_APP_BACKEND}/publish_tweet/`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				toast.success("Post published to Twitter Successfully!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
					inPosting: false,
				});
			});
	}

	publish_linkedin_post(requestOptions) {
		fetch(
			`${process.env.REACT_APP_BACKEND}/publish_linkedin_org_post/`,
			requestOptions
		)
			// .then(response => response.json())
			.then((response) => {
				if (response.status === 404) {
					console.log(response.json());
					toast.error(
						"LinkedIn is not linked. Please link your account in profile page.",
						{
							position: "top-center",
							theme: "dark",
							autoClose: 2500,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						}
					);

					this.setState({
						inPosting: false,
					});
				} else {
					console.log(response.json());
					toast.success("Post published to LinkedIn Successfully!", {
						position: "top-center",
						theme: "dark",
						autoClose: 2500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});

					this.setState({
						modalIsOpen: false,
						inPosting: false,
					});
				}
			})
			.catch((error) => {
				toast.error("Oops, something has gone wrong..", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					inPosting: false,
				});
			});
	}

	publish_linkedin_personal_post(requestOptions) {
		fetch(
			`${process.env.REACT_APP_BACKEND}/publish_linkedin_post/`,
			requestOptions
		)
			// .then(response => response.json())
			.then((response) => {
				if (response.status === 404) {
					console.log(response.json());
					toast.error(
						"LinkedIn is not linked. Please link your account in profile page.",
						{
							position: "top-center",
							theme: "dark",
							autoClose: 2500,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						}
					);

					this.setState({
						inPosting: false,
					});
				} else {
					console.log(response.json());
					toast.success("Post published to LinkedIn Successfully!", {
						position: "top-center",
						theme: "dark",
						autoClose: 2500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});

					this.setState({
						modalIsOpen: false,
						inPosting: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error("Oops, something has gone wrong.. Please try again later", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					inPosting: false,
				});
			});
	}

	publsih_fb_post_insta_post(instaRequestOptions, facebookRequestOptions) {
		Promise.all([
			fetch(
				`${process.env.REACT_APP_BACKEND}/publish_fb_post/`,
				facebookRequestOptions
			).then((response) => response.json()),
			fetch(
				`${process.env.REACT_APP_BACKEND}/publish_insta_post/`,
				instaRequestOptions
			).then((response) => response.json()),
		]).then(([insta_response, fb_response]) => {
			toast.success("Post published to Facebook and Instagram successfully!", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			this.setState({
				modalIsOpen: false,
				inPosting: false,
			});
		});
	}

	publish_post() {
		console.log("PUBLISH POST");

		if (JSON.stringify(this.state.selectedPlatforms) === JSON.stringify([])) {
			toast.error("Please select at least one platform to publish!", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			this.setState({ inPosting: false });
			return;
		}

		if (
			this.state.selectedPlatforms.includes(this.state.facebook_name) ||
			this.state.selectedPlatforms.includes(this.state.instagram_name)
		) {
			if (this.state.currentPostImage === "") {
				toast.error("Please choose an image to post on Instagram/Facebook!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				this.setState({ inPosting: false });
				return;
			}
		}

		if (this.state.selectedPlatforms.includes(this.state.twitter_name)) {
			if (this.state.postCaption === "") {
				toast.error("Please enter text to tweet!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				this.setState({ inPosting: false });
				return;
			}
		}

		// if (this.state.currentPostImage === "") {
		//   console.log(
		//     this.state.selectedPlatforms.length === 1 &&
		//       this.state.selectedPlatforms.includes(this.state.twitter_name)
		//   );
		//   if (
		//     this.state.selectedPlatforms.length === 1 &&
		//     this.state.selectedPlatforms.includes(this.state.twitter_name)
		//   ) {
		//     if (this.state.postCaption === "") {
		//       toast.error("Please enter text to tweet!", {
		//         position: "top-center",
		//         theme: "dark",
		//         autoClose: 2500,
		//         hideProgressBar: true,
		//         closeOnClick: true,
		//         pauseOnHover: true,
		//         draggable: true,
		//         progress: undefined,
		//       });
		//       this.setState({ inPosting: false });
		//       return;
		//     }
		//   } else {
		//     toast.error("Please choose an image to post in Instagram/Facebook!", {
		//       position: "top-center",
		//       theme: "dark",
		//       autoClose: 2500,
		//       hideProgressBar: true,
		//       closeOnClick: true,
		//       pauseOnHover: true,
		//       draggable: true,
		//       progress: undefined,
		//     });

		//     this.setState({ inPosting: false });
		//     return;
		//   }
		// }

		// if (JSON.stringify(this.state.selectedPlatforms) === JSON.stringify([])) {
		//   toast.error("Please select atleast one platform to publish!", {
		//     position: "top-center",
		//     theme: "dark",
		//     autoClose: 2500,
		//     hideProgressBar: true,
		//     closeOnClick: true,
		//     pauseOnHover: true,
		//     draggable: true,
		//     progress: undefined,
		//   });

		//   this.setState({ inPosting: false });
		//   return;
		// }

		// if(this.state.selectedPlatforms.includes(this.state.linkedin_profile_name))
		// {
		//     toast.warning('Publishing to linkedin personal profile coming soon! Please choose other platforms to post.', {
		//         position: "top-center",
		//         theme: 'dark',
		//         autoClose: 2500,
		//         hideProgressBar: true,
		//         closeOnClick: true,
		//         pauseOnHover: true,
		//         draggable: true,
		//         progress: undefined,
		//     });

		//     this.setState({inPosting: false})
		//     return;
		// }

		var form_data = new FormData();
		if (this.state.imageOption === "uploadImage") {
			form_data.append("isImageFile", "true");
			form_data.append("image_file", this.state.currentPostImageFile);
		} else {
			form_data.append("isImageFile", "false");
			form_data.append("image_url", this.state.currentPostImage);
		}

		form_data.append("caption", this.state.postCaption);

		const { accessToken } = isAuthenticated();
		var facebookRequestOptions = {
			method: "POST",
			headers: {
				// 'content-type': 'multipart/form-data',
				// 'Accept': '*/*',
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		form_data = new FormData();
		form_data.append("media_type", "IMAGE");
		if (this.state.imageOption === "uploadImage") {
			form_data.append("isImageFile", "true");
			form_data.append("image_file", this.state.currentPostImageFile);
		} else {
			form_data.append("isImageFile", "false");
			form_data.append("media_url", this.state.currentPostImage);
		}

		form_data.append("caption", this.state.postCaption);

		var instaRequestOptions = {
			method: "POST",
			headers: {
				// 'content-type': 'multipart/form-data',
				// 'Accept': '*/*',
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		var form_data = new FormData();
		if (this.state.currentPostImageFile || this.state.currentPostImage != "") {
			if (this.state.imageOption === "uploadImage") {
				form_data.append("isImageFile", "true");
				form_data.append("media", this.state.currentPostImageFile);
			} else {
				form_data.append("isImageFile", "false");
				form_data.append("media_url", this.state.currentPostImage);
			}
		}
		form_data.append("text", this.state.postCaption);

		var twitterRequestOptions = {
			method: "POST",
			headers: {
				// 'content-type': 'multipart/form-data',
				// 'Accept': '*/*',
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		var form_data = new FormData();
		if (this.state.currentPostImageFile || this.state.currentPostImage != "") {
			if (this.state.imageOption === "uploadImage") {
				form_data.append("isFile", "true");
				form_data.append("image", this.state.currentPostImageFile);
			} else {
				form_data.append("isFile", "false");
				form_data.append("image", this.state.currentPostImage);
			}
		}
		form_data.append("caption", this.state.postCaption);

		var linkedInRequestOptions = {
			method: "POST",
			headers: {
				// 'content-type': 'multipart/form-data',
				// 'Accept': '*/*',
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		var form_data = new FormData();
		if (this.state.currentPostImageFile || this.state.currentPostImage != "") {
			if (this.state.imageOption === "uploadImage") {
				form_data.append("isFile", "true");
				form_data.append("image", this.state.currentPostImageFile);
			} else {
				form_data.append("isFile", "false");
				form_data.append("image", this.state.currentPostImage);
			}
		}
		form_data.append("text", this.state.postCaption);
		form_data.append("title", "Post from MyEra");

		var linkedInPersonalRequestOptions = {
			method: "POST",
			headers: {
				// 'content-type': 'multipart/form-data',
				// 'Accept': '*/*',
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		if (this.state.selectedPlatforms.includes(this.state.insta_username)) {
			// console.log("INSTA")
			this.publsih_insta_post(instaRequestOptions);
		}
		if (this.state.selectedPlatforms.includes(this.state.fb_page_name)) {
			// console.log("FB")
			this.publsih_fb_post(facebookRequestOptions);
		}
		if (this.state.selectedPlatforms.includes(this.state.twitter_name)) {
			// console.log("Twitter")
			this.publish_twitter_post(twitterRequestOptions);
		}
		if (this.state.selectedPlatforms.includes(this.state.linkedin_org_name)) {
			// console.log("LinkedIn")
			this.publish_linkedin_post(linkedInRequestOptions);
		}
		if (
			this.state.selectedPlatforms.includes(this.state.linkedin_profile_name)
		) {
			// alert('IN')
			this.publish_linkedin_personal_post(linkedInPersonalRequestOptions);
		}
	}

	delete_post() {
		console.log("Deleting Post " + this.state.selectedScheduledPostId);
		const { accessToken } = isAuthenticated();
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};

		var post_id = this.state.selectedScheduledPostId;

		fetch(
			`${process.env.REACT_APP_BACKEND}/` + "delete_scheduled_post/" + post_id,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				toast.success("Post deleted Successfully!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({ inPosting: false, modalIsOpen: false });

				this.setState({
					scheduled_posts: this.state.scheduled_posts.filter(function (post) {
						return post.Id != post_id;
					}),
				});

				// window.location.reload();
			});
	}

	schedule_fb_post(requestOptions) {
		fetch(
			`${process.env.REACT_APP_BACKEND}/schedule_facebook_post/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				toast.success("Post Scheduled to Facebook Successfully!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
					inPosting: false,
				});
			});
	}

	schedule_insta_post(requestOptions) {
		fetch(
			`${process.env.REACT_APP_BACKEND}/schedule_insta_post/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				toast.success("Post Scheduled to Instagram Successfully!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
					inPosting: false,
				});
			});
	}

	schedule_twitter_post(requestOptions) {
		fetch(
			`${process.env.REACT_APP_BACKEND}/schedule_twitter_post/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				toast.success("Post Scheduled to Twitter Successfully!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
					inPosting: false,
				});
			});
	}

	schedule_linkedin_post(requestOptions) {
		fetch(
			`${process.env.REACT_APP_BACKEND}/schedule_linkedin_post/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				toast.success("Post Scheduled to Linkedin Successfully!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
					inPosting: false,
				});
			})
			.catch((error) => {
				toast.error("There's something wrong, please try again later..", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
					inPosting: false,
				});
			});
	}

	schedule_linkedin_personal_post(requestOptions) {
		fetch(
			`${process.env.REACT_APP_BACKEND}/schedule_linkedin_personal_post/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				toast.success("Post Scheduled to Linkedin Successfully!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
					inPosting: false,
				});
			})
			.catch((error) => {
				toast.error("There's something wrong, please try again later..", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					modalIsOpen: false,
					inPosting: false,
				});
			});
	}

	schedule_fb_insta_post(instaRequestOptions, facebookRequestOptions) {
		Promise.all([
			fetch(
				`${process.env.REACT_APP_BACKEND}/schedule_facebook_post/`,
				facebookRequestOptions
			).then((response) => response.json()),
			fetch(
				`${process.env.REACT_APP_BACKEND}/schedule_insta_post/`,
				instaRequestOptions
			).then((response) => response.json()),
		]).then(([insta_response, fb_response]) => {
			toast.success("Post scheduled to Facebook and Instagram successfully!", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			this.setState({
				modalIsOpen: false,
				inPosting: false,
			});
		});
	}

	async schedule_post() {
		if (this.state.currentPostImage === "") {
			console.log(
				this.state.selectedPlatforms.length === 1 &&
					this.state.selectedPlatforms.includes(this.state.twitter_name)
			);
			if (
				this.state.selectedPlatforms.length === 1 &&
				this.state.selectedPlatforms.includes(this.state.twitter_name)
			) {
				if (this.state.postCaption === "") {
					toast.error("Please enter text to tweet!", {
						position: "top-center",
						theme: "dark",
						autoClose: 2500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					this.setState({ inPosting: false });
					return;
				}
			} else {
				toast.error("Please choose an image to post in Instagram/Facebook!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({ inPosting: false });
				return;
			}
		}

		// if (
		//   this.state.selectedPlatforms.includes(this.state.linkedin_profile_name)
		// ) {
		//   toast.warning(
		//     "Scheduling post through LinkedIn personal account is coming soon. Try scheduling to other platforms...",
		//     {
		//       position: "top-center",
		//       theme: "dark",
		//       autoClose: 2500,
		//       hideProgressBar: true,
		//       closeOnClick: true,
		//       pauseOnHover: true,
		//       draggable: true,
		//       progress: undefined,
		//     }
		//   );

		//   this.setState({ inPosting: false });
		//   return;
		// }

		if (JSON.stringify(this.state.selectedPlatforms) === JSON.stringify([])) {
			toast.error("Please select atleast one platform to publish!", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			this.setState({ inPosting: false });
			return;
		}

		var selectedDate = new Date(this.state.selectedDate);
		if (isNaN(selectedDate)) {
			toast.error("Please select a valid date", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			this.setState({ inPosting: false });
			return;
		}

		if (new Date() > new Date(this.state.selectedDate)) {
			toast.error("Please select a date in future to schedule", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			this.setState({ inPosting: false });
			return;
		}

		// FACEBOOK DATA
		var form_data = new FormData();
		if (this.state.imageOption === "uploadImage") {
			form_data.append("isImageFile", "true");
			form_data.append("image_file", this.state.currentPostImageFile);
		} else {
			form_data.append("isImageFile", "false");
			form_data.append("image_url", this.state.currentPostImage); //CHANGE IT BACK TO 'image_url'
		}

		form_data.append("caption", this.state.postCaption);

		var d = new Date();
		var unique_post_id = d.getTime();

		console.log(unique_post_id);
		form_data.append("unique_post_id", unique_post_id);
		form_data.append("schedule_datetime", this.state.selectedDate);

		const { accessToken } = isAuthenticated();
		var isForbidden = true;
		await axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND}/schedule_limit/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
			.then((res) => {
				console.log("limit", res);
			})
			.catch((err) => {
				if (err.response) {
					console.log(err.response.status);
					isForbidden = false;
					toast.error("You have exhausted your schedules for this month", {
						position: "top-center",
						theme: "light",
						autoClose: 2500,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
			});
		if (isForbidden === false) return;
		var facebookRequestOptions = {
			method: "POST",
			headers: {
				// 'content-type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		// INSTA DATA
		form_data = new FormData();
		form_data.append("media_type", "IMAGE");
		if (this.state.imageOption === "uploadImage") {
			form_data.append("isImageFile", "true");
			form_data.append("image_file", this.state.currentPostImageFile);
		} else {
			form_data.append("isImageFile", "false");
			form_data.append("media_url", this.state.currentPostImage);
		}

		form_data.append("caption", this.state.postCaption);
		form_data.append("unique_post_id", unique_post_id);
		form_data.append("schedule_datetime", this.state.selectedDate);

		var instaRequestOptions = {
			method: "POST",
			headers: {
				// 'content-type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		// TWITTER DATA
		var form_data = new FormData();
		if (this.state.currentPostImageFile) {
			if (this.state.imageOption === "uploadImage") {
				form_data.append("isImageFile", "true");
				form_data.append("media", this.state.currentPostImageFile);
			} else {
				form_data.append("isImageFile", "false");
				form_data.append("media_url", this.state.currentPostImage);
			}
		}

		form_data.append("text", this.state.postCaption);

		var d = new Date();
		var unique_post_id = d.getTime();

		console.log(unique_post_id);
		form_data.append("unique_post_id", unique_post_id);
		form_data.append("schedule_datetime", this.state.selectedDate);

		var twitterRequestOptions = {
			method: "POST",
			headers: {
				// 'content-type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		// LINKEDIN DATA
		var form_data = new FormData();
		if (this.state.imageOption === "uploadImage") {
			form_data.append("isFile", "true");
			form_data.append("image", this.state.currentPostImageFile);
		} else {
			form_data.append("isFile", "false");
			form_data.append("image", this.state.currentPostImage);
		}

		form_data.append("caption", this.state.postCaption);

		var d = new Date();
		var unique_post_id = d.getTime();

		console.log(unique_post_id);
		form_data.append("unique_post_id", unique_post_id);
		form_data.append("schedule_datetime", this.state.selectedDate);

		var linkedinRequestOptions = {
			method: "POST",
			headers: {
				// 'content-type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		// LINKEDIN PERSONAL DATA
		var form_data = new FormData();
		if (this.state.imageOption === "uploadImage") {
			form_data.append("isFile", "true");
			form_data.append("image", this.state.currentPostImageFile);
		} else {
			form_data.append("isFile", "false");
			form_data.append("image", this.state.currentPostImage);
		}

		form_data.append("caption", this.state.postCaption);

		var d = new Date();
		var unique_post_id = d.getTime();

		console.log(unique_post_id);
		form_data.append("unique_post_id", unique_post_id);
		form_data.append("schedule_datetime", this.state.selectedDate);

		var linkedinPersonalRequestOptions = {
			method: "POST",
			headers: {
				// 'content-type': 'multipart/form-data',
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		if (this.state.selectedPlatforms.includes(this.state.twitter_name)) {
			this.schedule_twitter_post(twitterRequestOptions);
		}

		if (this.state.selectedPlatforms.includes(this.state.insta_username)) {
			this.schedule_insta_post(instaRequestOptions);
		}

		if (this.state.selectedPlatforms.includes(this.state.fb_page_name)) {
			this.schedule_fb_post(facebookRequestOptions);
		}

		if (this.state.selectedPlatforms.includes(this.state.linkedin_org_name)) {
			this.schedule_linkedin_post(linkedinRequestOptions);
		}

		if (
			this.state.selectedPlatforms.includes(this.state.linkedin_profile_name)
		) {
			this.schedule_linkedin_personal_post(linkedinPersonalRequestOptions);
		}
	}

	onPostSubmit() {
		console.log(
			this.state.selectedPlatforms.includes(this.state.insta_username) ||
				this.state.selectedPlatforms.includes(this.state.fb_page_name)
		);
		if (
			(this.state.selectedPlatforms.includes(this.state.insta_username) ||
				this.state.selectedPlatforms.includes(this.state.fb_page_name)) &&
			!this.state.is_facebook_linked
		) {
			toast.info("Please link your facebook account to post!", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			return;
		}

		this.setState({ inPosting: true });

		if (!this.state.isScheduledPost) {
			this.delete_post();
			return;
		}

		if (this.state.postOption === "postnow" && this.state.isScheduledPost) {
			this.publish_post();
		}

		if (
			this.state.postOption === "scheduleforlater" &&
			this.state.isScheduledPost
		) {
			this.schedule_post();
		}
	}

	render() {
		return (
			<>
				<ThemeProvider theme={theme}>
					<Modal isOpen={this.state.modalIsOpen} style={modalStyles}>
						<LinearProgress
							style={{ display: this.state.inPosting ? "block" : "none" }}
						/>

						<br></br>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								paddingLeft: "2%",
								paddingRight: "2%",
							}}
						>
							<div>
								<h3 style={{ textAlign: "center" }}>New Post</h3>
							</div>
							<div className="closeIcon" style={{}}>
								<CloseIcon onClick={() => this.closeModal()}> </CloseIcon>
							</div>
						</div>
						<br></br>
						<br></br>
						<Stack
							direction="row"
							divider={
								<Divider orientation="vertical" sx={{ minHeight: "440px" }} />
							}
							spacing={7}
							style={{ marginLeft: "5%" }}
						>
							<div>
								<ButtonGroup
									variant="outlined"
									aria-label="outlined button group"
									style={{
										display: this.state.isScheduledPost
											? this.state.isSavedPost
												? "none"
												: "inline-block"
											: "none",
									}}
								>
									{/* <input type="file" onChange={(e) => this.onFileChange(e)} /> */}
									{/* <Button variant="outlined" size="small">Upload</Button> */}
									<label
										style={{ display: "inline-block" }}
										htmlFor="contained-button-file"
									>
										<Input
											accept="image/*"
											id="contained-button-file"
											multiple
											type="file"
											onChange={(e) => this.onFileChange(e)}
										/>
										<Button
											id="uploadImage"
											variant={
												this.state.imageOption === "uploadImage"
													? "contained"
													: "outlined"
											}
											component="span"
											size="small"
											onClick={(e) => this.setImageOption(e)}
											sx={{ borderRadius: "5px 0px 0px 5px" }}
											style={{
												color:
													this.state.imageOption === "uploadImage"
														? "black"
														: "white",
											}}
										>
											Upload
										</Button>
									</label>
									<Button
										id="imageSaved"
										variant={
											this.state.imageOption === "imageSaved"
												? "contained"
												: "outlined"
										}
										size="small"
										style={{
											color:
												this.state.imageOption === "imageSaved"
													? "black"
													: "white",
										}}
										onClick={(e) => this.handleSavedCreativesButton(e)}
										sx={{ borderRadius: "0px" }}
									>
										Choose from Saved Creatives
									</Button>
									<Link to="/create-now">
										<Button
											variant="outlined"
											size="small"
											style={{ color: "white" }}
										>
											Create Now
										</Button>
									</Link>
								</ButtonGroup>
								<br></br>
								<br></br>

								{/* <div style={{display: 'flex', marginLeft: '50px', marginTop: '50px'}}>
                        <FileUploadOutlinedIcon fontSize="large" sx={{backgroundColor: 'beige', m: 1, borderRadius: '10px'}}></FileUploadOutlinedIcon>
                        <SavedSearchOutlinedIcon fontSize="large" sx={{backgroundColor: 'beige', m: 1, borderRadius: '10px'}}></SavedSearchOutlinedIcon>
                        <AddOutlinedIcon fontSize="large" sx={{backgroundColor: 'beige', m: 1, borderRadius: '10px'}}/>
                        </div> */}
								<img
									src={default_image}
									style={{
										height: 240,
										width: 240,
										borderRadius: "5%",
										display: this.state.imageIsUploaded
											? "none"
											: "inline-block",
									}}
								></img>
								<img
									src={this.state.currentPostImage}
									style={{
										height: 240,
										width: "auto",
										borderRadius: "5%",
										display: this.state.imageIsUploaded
											? "inline-block"
											: "none",
									}}
								></img>
								<br></br>
								<br></br>
								{/* <TextField
                        id="standard-multiline-static"
                        label="Multiline"
                        multiline
                        rows={4}
                        defaultValue="Default Value"
                        variant="standard"
                    /> */}
								<div>
									<textarea
										style={{
											width: 350,
											backgroundColor: "rgb(11, 17, 32)",
											color: "rgba(255, 255, 255, 1)",
											height: 125,
										}}
										placeholder="Caption"
										value={this.state.postCaption}
										onChange={(e) => this.handleCaptionText(e)}
										disabled={!this.state.isScheduledPost}
									/>
									<p style={{ color: "rgba(255, 255, 255, 1)" }}>
										Character count: {this.state.postCaption.length}
									</p>
								</div>
								<div className="text">
									* Image is optional for Twitter, Facebook and LinkedIn
								</div>
							</div>

							<div>
								<br></br>
								<br></br>

								<div>
									<FormControl sx={{ m: 1, width: 300 }}>
										<InputLabel id="demo-multiple-chip-label">
											Publish to
										</InputLabel>
										<Select
											labelId="demo-multiple-chip-label"
											id="demo-multiple-chip"
											multiple
											value={this.state.selectedPlatforms}
											onChange={this.handleChange}
											input={
												<OutlinedInput
													id="select-multiple-chip"
													label="Publish to"
												/>
											}
											renderValue={(selected) => (
												<Box
													sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
												>
													{selected.map((value) => (
														<div>
															<Chip
																key={value}
																label={
																	<div style={{ display: "flex" }}>
																		<img
																			src={
																				value === this.state.insta_username ||
																				value === "instagram"
																					? instagram
																					: value === this.state.fb_page_name ||
																					  value === "facebook"
																					? facebook
																					: value === this.state.twitter_name ||
																					  value === "twitter"
																					? twitter
																					: value ==
																							this.state.linkedin_org_name ||
																					  value ==
																							this.state
																								.linkedin_profile_name ||
																					  value === "linkedin" ||
																					  value === "linkedin personal"
																					? coloredLinkedIn
																					: null
																			}
																			style={{
																				height: "15px",
																				width: "15px",
																				marginTop: "3.7%",
																			}}
																		></img>
																		<div style={{ marginLeft: "3%" }}>
																			{value.split(":")[1]}
																			{value ===
																			this.state.linkedin_org_name ? (
																				<img
																					src="https://img.icons8.com/color/96/000000/company.png"
																					height="20px"
																					style={{ marginLeft: "2%" }}
																				/>
																			) : (
																				<></>
																			)}
																			{value ==
																			this.state.linkedin_profile_name ? (
																				<PersonIcon
																					color="primary"
																					sx={{ ml: "2%", mb: "1.5%" }}
																				></PersonIcon>
																			) : (
																				<></>
																			)}
																		</div>
																	</div>
																}
															></Chip>
														</div>
													))}
												</Box>
											)}
											MenuProps={MenuProps}
											disabled={!this.state.isScheduledPost}
										>
											<MenuItem key="1" value={this.state.insta_username}>
												<img
													src={instagram}
													style={{ height: "15px", width: "15px" }}
												></img>{" "}
												<div style={{ marginLeft: "3%" }}>
													{this.state.insta_username
														? this.state.insta_username.split(":")[1]
														: "instagram"}
												</div>
												{!this.state.is_facebook_linked ? (
													<Link to="/SocialAccounts">
														<button className="button_plus"></button>
													</Link>
												) : (
													<></>
												)}
											</MenuItem>
											<MenuItem key="2" value={this.state.fb_page_name}>
												<img
													src={facebook}
													style={{ height: "15px", width: "15px" }}
												></img>{" "}
												<div style={{ marginLeft: "3%" }}>
													{this.state.is_facebook_linked
														? this.state.fb_page_name.split(":")[1]
														: "facebook"}
												</div>
												{!this.state.is_facebook_linked ? (
													<Link to="/SocialAccounts">
														<button className="button_plus"></button>
													</Link>
												) : (
													<></>
												)}
											</MenuItem>
											<MenuItem key="3" value={this.state.twitter_name}>
												<img
													src={twitter}
													style={{ height: "20px", marginLeft: "-1%" }}
												></img>{" "}
												<div style={{ marginLeft: "3%" }}>
													{this.state.is_twitter_linked
														? this.state.twitter_name.split(":")[1]
														: "twitter"}
												</div>
												{!this.state.is_twitter_linked ? (
													<Link to="/SocialAccounts">
														<button className="button_plus"></button>
													</Link>
												) : (
													<></>
												)}
											</MenuItem>

											<MenuItem key="4" value={this.state.linkedin_org_name}>
												<img
													src={coloredLinkedIn}
													style={{ height: "20px", marginLeft: "-1%" }}
												></img>{" "}
												<div style={{ marginLeft: "3%" }}>
													{this.state.is_linkedin_org_linked
														? this.state.linkedin_org_name.split(":")[1]
														: "LinkedIn Organization"}
												</div>
												<img
													src="https://img.icons8.com/color/96/000000/company.png"
													height="20px"
													style={{ marginLeft: "3%" }}
												/>
												{!this.state.is_linkedin_org_linked ? (
													<Link to="/SocialAccounts">
														<button className="button_plus"></button>
													</Link>
												) : (
													<></>
												)}
											</MenuItem>

											<MenuItem
												key="5"
												value={this.state.linkedin_profile_name}
											>
												<img
													src={coloredLinkedIn}
													style={{ height: "20px", marginLeft: "-1%" }}
												></img>{" "}
												<div style={{ marginLeft: "3%" }}>
													{this.state.is_linkedin_linked
														? this.state.linkedin_profile_name.split(":")[1]
														: "LinkedIn Personal"}
												</div>
												{
													<PersonIcon
														color="primary"
														sx={{ ml: "3%", mb: "1.5%" }}
													></PersonIcon>
												}
												{!this.state.is_linkedin_linked ? (
													<Link to="/SocialAccounts">
														<button className="button_plus"></button>
													</Link>
												) : (
													<></>
												)}
											</MenuItem>
										</Select>
									</FormControl>
								</div>

								<br></br>
								<br></br>

								<div>
									<FormControl
										style={{
											display: this.state.isScheduledPost
												? "inline-block"
												: "none",
											marginRight: "35%",
										}}
									>
										<FormLabel
											id="demo-radio-buttons-group-label"
											style={{ color: "rgb(132, 232, 244)" }}
										>
											Post Options
										</FormLabel>
										<RadioGroup
											aria-labelledby="demo-radio-buttons-group-label"
											defaultValue="postnow"
											name="radio-buttons-group"
											onChange={(e) => this.onPostRadioChange(e)}
											style={{ color: "white" }}
										>
											<FormControlLabel
												value="postnow"
												control={<Radio />}
												label="Post Now"
											/>
											<FormControlLabel
												value="scheduleforlater"
												control={<Radio />}
												label="Schedule for later"
											/>
										</RadioGroup>
									</FormControl>
								</div>

								<br></br>
								<br></br>

								<div
									style={{
										display:
											this.state.postOption === "postnow"
												? "none"
												: "inline-block",
										marginRight: "16%",
									}}
								>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DateTimePicker
											renderInput={(props) => (
												<TextField
													{...props}
													sx={{ input: { color: "white" } }}
												/>
											)}
											label="Schedule Datetime"
											value={this.state.selectedDate}
											onChange={(newValue) => {
												this.handleSelectedDate(newValue);
											}}
											mt={"10%"}
											mr={"15%"}
											disabled={!this.state.isScheduledPost}
										/>
									</LocalizationProvider>
								</div>
							</div>
						</Stack>

						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								mt: "5%",
								mr: "4%",
								borderRadius: 1,
							}}
						>
							<Button
								variant="contained"
								size="small"
								onClick={() => this.onPostSubmit()}
								disabled={this.state.inPosting}
								style={{ marginRight: "5%" }}
							>
								{!this.state.isScheduledPost
									? "Delete Post"
									: this.state.postOption === "postnow"
									? "Post Now"
									: "Schedule"}
							</Button>
							{/* <Button variant="contained" size="small" onClick={() => this.closeModal()}>close</Button> */}
						</Box>
					</Modal>
					<Modal isOpen={this.state.isSavedModalOpen} style={savedModalStyles}>
						<h3>Choose from Saved Creatives</h3>
						<div>
							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									width: "100%",
									margin: "auto",
								}}
							>
								{this.state.saved_creatives.reverse().map((creative) => {
									return (
										<div
											class="col-sm-4"
											style={{ width: "auto", margin: "auto" }}
											key={`$(creative.id)`}
										>
											<a>
												<img
													className="styleImage"
													src={creative.thumbnail}
													onClick={(e) =>
														this.handleSavedCreativeClick(e, creative.thumbnail)
													}
												></img>
											</a>
										</div>
									);
								})}
							</div>
						</div>
						<Button onClick={() => this.setState({ isSavedModalOpen: false })}>
							Close
						</Button>
					</Modal>
				</ThemeProvider>
			</>
		);
	}

	componentDidMount() {
		var user_data = JSON.parse(localStorage.getItem("Erajwt"));
		this.setState({
			is_facebook_linked: user_data.is_facebook_linked,
			is_linkedin_linked: user_data.is_linkedin_linked,
			is_linkedin_org_linked: user_data.is_linkedin_org_linked,
			is_twitter_linked: user_data.is_twitter_linked,
		});
		if (user_data.is_facebook_linked)
			this.setState({
				insta_username: user_data.insta_username,
				fb_page_name: user_data.fb_page_name,
			});
		if (user_data.is_twitter_linked)
			this.setState({ twitter_name: user_data.twitter_name });
		if (user_data.is_linkedin_linked)
			this.setState({
				linkedin_org_name: user_data.linkedin_org_name,
				linkedin_profile_name: user_data.linkedin_profile_name,
			});
	}
}

export default PostModal;
