import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// import Navbar from './Components/Navbar/Navbar';
import Login from "./Components/Login/Login";
import ShopifyLogin from "./Components/Login/ShopifyLogin";
import Signup from "./Components/Signup/Signup";
import Trendwatch from "./Components/Dashboard/Trendwatch";
import PrivateRoute from "./Helper/Auth/Privateroute";
import ForgotPassword from "./Components/Login/ForgotPassword";
import OTP from "./Components/Login/OTP";
import ShopifyLoginForm from "./Components/ShopifyLoginForm/shopifyLoginForm";
import ShopifyHome from "./Components/ShopifyLoginForm/ShopifyHome";
import AboutUs from "./Routes/AboutUs";
import PrivacyPolicy from "./Routes/PrivacyPolicy";
import RefundPolicy from "./Routes/RefundPolicy";
import TermsOfUse from "./Routes/TermsOfUse";
import Loader from "./Components/Loader/Loader";
import Blog from "./Components/Blog/Blog";
import BlogPage from "./Components/Blog/BlogPage";
import "../src/Components/Campaign/font.css";
import LandingPagelatest from "../src/Components/desktop view/landing_page_m1.js";

//!context
import { Statecontext } from "./Statemanagement/Statecontext";
import ErrorPage from "./ErrorPage/ErrorPage";
import PaymentPage from "./Components/PaymentPage/PaymentPage";
import RemoveWatermark from "./Components/Dashboard/RemoveWatermark";
import HowToUse from "./Components/Dashboard/HowToUse";
import Scheduler from "./Components/Dashboard/Scheduler";
import SavedCreatives from "./Components/Dashboard/SavedCreatives";
import ProfileMain from "./Components/Dashboard/ProfileMain";
import SocialAccountsMain from "./Components/Dashboard/SocialAccountsMain";
import DashboardOverview from "./Components/Dashboard/DashboardOverview";
import Analytics from "./Components/Dashboard/Analytics";
import AssetLibraryMain from "./Components/Dashboard/AssetLibraryMain";
import UploadLibraryMain from "./Components/Dashboard/UploadLibraryMain";
// import CampaignCanvas from "./Components/Campaign/CampaignCanvas";
// import ProductCanvas from "./Components/Campaign/ProductCanvas";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import Test from "./Components/Dashboard/Test";
import Advisor from "./Components/Advisor/Advisor";
import TrendingMedia from "./Components/Dashboard/TrendingMedia";
import TrendingHashtag from "./Components/Dashboard/TrendingHashtag";
import ContentIdeas from "./Components/Dashboard/ContentIdeas";
import Plans from "./Components/MyPlan/Plans";
import Apps from "./Components/Apps/Apps";
import CreateApp from "./Components/Apps/CreateApp";
import SwagEasyPublish from "./Components/Client/SwagEasyPublish";
// import LinkedinPublish from "./Components/LinkedinPublish/LinkedinPublish";
import CampaignJourneyMain from "./Components/Dashboard/CampaignJourneyMain";
import ProductJourneyMain from "./Components/Dashboard/ProductJourneyMain";
import TryOnJourneyMain from "./Components/Dashboard/TryOnJourneyMain";
import CreateNowApi from "./Components/Campaign/CreateNowApi";
import TryOnJourney from "./Components/Campaign/TryOnJourney";
import ProductJourney from "./Components/Campaign/ProductJourney";
import CampaignJourney from "./Components/Campaign/CampaignJourney";
import CampaignCanvasMain from "./Components/Dashboard/CampaignCanvasMain";
import CampaignCanvasMainApi from "./Components/Dashboard/CampaignCanvasMainApi";
import ProductCanvasMain from "./Components/Dashboard/ProductCanvasMain";
import ProductCanvasMainApi from "./Components/Dashboard/ProductCanvasMainApi";
import ViewApp from "./Components/Apps/ViewApp";
import LinkedinPub from "./Components/LinkedinPublishNew/LinkedinPub";

import ShopifyWrapper from "./Components/Shopify/ShopifyWrapper";
import ProductCreate from "./Components/Shopify/Create/ProductCreate";
// import ShopifySpineMat from "./Components/Shopify/ShopifySpineMat";

import AppOverview from "./Components/Apps/AppOverview";
import PricingPage from "./Routes/PricingPage";
// import Revamped from "./Components/RevampedLanding/Revamped";

import Indibni from "./Components/MyeraScript/Indibni/Indibni";
import Blackberrys from "./Components/MyeraScript/Blackberrys/Blackberrys";
import BoxPackagingCompany from "./Components/MyeraScript/BoxPackagingCompany/BoxPackagingCompany.jsx";
import JaipurWatchCompany from "./Components/MyeraScript/JaipurWatchCompany/JaipurWatchCompany.jsx";

import ReactGA from "react-ga4";
import Ugaao from "./Components/MyeraScript/Ugaao/Ugaao.jsx";
import Moochuu from "./Components/MyeraScript/Moochuu/Moochuu.jsx";
import TestStore from "./Components/MyeraScript/TestStore/TestStore.jsx";
import Bakingo from "./Components/MyeraScript/Bakingo/Bakingo.jsx";
import BambooIndia from "./Components/MyeraScript/BambooIndia/BambooIndia.jsx";
import TryonTest from "./Components/MyeraScript/TryonTest/TryonTest.jsx";
import ScriptTrial from "./Components/MyeraScript/ScriptTrial/ScriptTrial.jsx";
import { OpenCvProvider } from "opencv-react";
import TemplateCreator from "./Components/MyeraScript/TemplateCreator/TemplateCreator.jsx";
import FlowerAura from "./Components/MyeraScript/FlowerAura/FlowerAura.jsx";

const GA_MEASUREMENT_ID = "G-8NBNVP8N81";
ReactGA.initialize(GA_MEASUREMENT_ID);

function App() {
	return (
		<Statecontext>
			<BrowserRouter>
				<div
					id="outer-container"
					style={ {
						width: "100vw",
						height: "100vh",
						overflowX: "hidden",
						backgroundColor: `${window.location.pathname.split("/")[ 1 ] === "dashboard" ||
							window.location.pathname.split("/")[ 1 ] === "trendwatch" ||
							window.location.pathname.split("/")[ 1 ] === "remove-watermark" ||
							window.location.pathname.split("/")[ 1 ] === "how-to-use"
							? "rgba(234, 242, 255, 0.8)"
							: window.location.pathname.split("/")[ 1 ] === "blog"
								? "rgb(25,25,26)"
								: "#19191A"
							}`,
					} }
				>
					<Loader />

					<Switch>
						{/*<Route exact path="/signup" component={Signup}></Route>*/ }
						<Route
							path="/signup"
							render={ () => {
								window.location.href =
									"https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button";
								return null;
							} }
						/>
						<Route
							exact
							path="/shopify-form"
							component={ ShopifyLoginForm }
						></Route>
						<Route exact path="/shopify-login" component={ ShopifyLogin }></Route>
						<Route exact path="/shopify-home" component={ ShopifyHome }></Route>
						<Route exact path="/signup-last" component={ Signup }></Route>
						<Route exact path="/login" component={ Login }></Route>
						{/* <PrivateRoute exact path="/dashboard" component={Dashboard}></PrivateRoute> */ }
						<PrivateRoute
							exact
							path="/dashboard"
							component={ DashboardOverview }
						></PrivateRoute>
						<Route
							exact
							path="/linkedin/callback/"
							component={ LinkedInCallback }
						></Route>
						<PrivateRoute
							exact
							path="/twitter/callback/"
							component={ SocialAccountsMain }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/trendwatch"
							component={ Trendwatch }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/analytics"
							component={ Analytics }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/trendwatch/trending-hashtag"
							component={ TrendingHashtag }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/trendwatch/trending-media"
							component={ TrendingMedia }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/trendwatch/content-ideas"
							component={ ContentIdeas }
						></PrivateRoute>
						{/* <PrivateRoute exact path="/dashboard/brand-health" component={Dashboard}></PrivateRoute> */ }
						{/* <PrivateRoute exact path="/dashboard/brand-health" component={Dashboard}></PrivateRoute> */ }
						<PrivateRoute
							exact
							path="/post-scheduler"
							component={ Scheduler }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/saved-creatives"
							component={ SavedCreatives }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/remove-watermark"
							component={ RemoveWatermark }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/how-to-use"
							component={ HowToUse }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/create-now/campaign-journey"
							component={ CampaignJourneyMain }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/create-now/campaign-journey-api"
							component={ CampaignJourney }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/create-now/product-journey"
							component={ ProductJourneyMain }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/create-now/product-journey-api"
							component={ ProductJourney }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/create-now/try-on-journey"
							component={ TryOnJourneyMain }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/create-now"
							component={ Test }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/create-now/try-on-journey-api"
							component={ TryOnJourney }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/profile"
							component={ ProfileMain }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/SocialAccounts"
							component={ SocialAccountsMain }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/asset-library"
							component={ AssetLibraryMain }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/upload-library"
							component={ UploadLibraryMain }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/my-plans"
							component={ Plans }
						></PrivateRoute>
						<PrivateRoute
							exact
							path="/advisor"
							component={ Advisor }
						></PrivateRoute>
						<Route
							exact
							path="/forgot-password"
							component={ ForgotPassword }
						></Route>
						<Route exact path="/otp" component={ OTP }></Route>
						<Route
							exact
							path="/privacy-policy"
							component={ PrivacyPolicy }
						></Route>
						<Route exact path="/refund-policy" component={ RefundPolicy }></Route>
						<Route exact path="/terms-of-use" component={ TermsOfUse }></Route>
						<Route exact path="/terms-of-use" component={ TermsOfUse }></Route>
						<Route exact path="/" component={ LandingPagelatest }></Route>
						<Route exact path="/blog" component={ Blog }></Route>
						<Route exact path="/about-us" component={ AboutUs }></Route>
						<Route exact path="/pricing" component={ PricingPage }></Route>
						<Route
							exact
							path="/blog/:blogid/:blogtitle"
							component={ BlogPage }
						></Route>
						<Route
							exact
							path="/dashboard/PaymentPage"
							component={ PaymentPage }
						></Route>
						<Route
							exact
							path="/create-now/generate_creative_api"
							component={ CampaignCanvasMainApi }
						></Route>
						<Route
							exact
							path="/create-now/generate_creative"
							component={ CampaignCanvasMain }
						></Route>
						<Route
							exact
							path="/create-now/generate_product_creative"
							component={ ProductCanvasMain }
						></Route>
						<Route
							exact
							path="/create-now/generate_product_creative_api"
							component={ ProductCanvasMainApi }
						></Route>
						<Route
							exact
							path="/contact-us"
							component={ () => {
								window.location.href = "https://myera.tawk.help/";
								return null;
							} }
						/>
						<Route
							exact
							path="/create-now-api/:accessToken"
							component={ CreateNowApi }
						></Route>
						<Route exact path="/developer" component={ AppOverview }></Route>
						<Route exact path="/developer/my-apps" component={ Apps }></Route>
						<Route
							exact
							path="/developer/my-apps/:app_id/"
							component={ ViewApp }
						></Route>
						<Route
							exact
							path="/developer/create_app"
							component={ CreateApp }
						></Route>
						<Route
							exact
							path="/publish/swageazy/dec2022"
							component={ SwagEasyPublish }
						></Route>
						{/* <Route exact path="/publish/linkedin" component={LinkedinPublish}></Route> */ }
						<Route
							exact
							path="/publish/linkedin"
							component={ LinkedinPub }
						></Route>
						<Route exact path="/shopify" component={ ShopifyWrapper } />
						<Route exact path="/create-product" component={ ProductCreate } />

						<Route
							exact
							path="/myerascript/indibni"
							component={ Indibni }
						></Route>
						<Route
							exact
							path="/myerascript/blackberrys"
							component={ Blackberrys }
						></Route>
						<Route
							exact
							path="/myerascript/boxpackagingcompany"
							component={ BoxPackagingCompany }
						></Route>
						<Route
							exact
							path="/myerascript/jaipurwatchcompany"
							component={ JaipurWatchCompany }
						></Route>
						<Route exact path="/myerascript/ugaao" component={ Ugaao }></Route>
						<Route
							exact
							path="/myerascript/moochuu"
							component={ Moochuu }
						></Route>
						<Route
							exact
							path="/myerascript/bakingo"
							children={
								<OpenCvProvider>
									<Bakingo />
								</OpenCvProvider>
							}
						></Route>
						<Route
							exact
							path="/myerascript/tryontest"
							component={ TryonTest }
						></Route>
						<Route
							exact
							path="/myerascript/bambooindia"
							component={ BambooIndia }
						></Route>
						<Route
							exact
							path="/myerascript/trial"
							component={ ScriptTrial }
						></Route>
						<Route
							exact
							path="/template-creator"
							component={ TemplateCreator }
						></Route>
						<Route
							exact
							path="/myerascript/floweraura"
							component={ FlowerAura }
						></Route>
						<Route
							exact
							path="/myerascript/hamper-personalization-demo"
							component={ FlowerAura }
						></Route>
						<Route
							exact
							path="/myerascript/:tenant"
							component={ TestStore }
						></Route>

						<Route exact path="*" component={ ErrorPage }></Route>
					</Switch>
				</div>
			</BrowserRouter>
		</Statecontext>
	);
}

export default App;
