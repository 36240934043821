import { Button } from '@mui/material';
import React, {useState, useEffect} from 'react';
import styles from './review_in_progress.module.css';
import preview_image from './review_image.png'

const ReviewInProgress = () => {

    return (
        <>
            <br/><br/>
            <div className={styles.heading}>
                Preview
            </div>
            <br/><br/><br/>
            <div className={styles.content}>
                {/* <div className={styles.textStyle}>
                    Once you click the submit button, MyEra will adjust the uploaded images to match the 
                    product mockup design to produce the best possible variations
                    <br/><br/>
                    <Button variant='contained'>Submit for review</Button>
                </div> */}

                <div className={styles.imageBoxStyle}>
                    <img className={styles.imageStyle} src={preview_image}></img>
                    <div className={styles.reviewPendingStyle}>
                        Review pending...
                    </div>
                </div>
                <br/>
                Review In Progress, please come back after some time to check your preview...

            </div>
        </>
    )
}

export default ReviewInProgress;