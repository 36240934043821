import React, { useEffect, useState } from "react";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import { useParams } from "react-router-dom";
import './view_app.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { ContentCopy } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";

export default function ViewApp() {

    const [appName, setAppName] = useState("")
    const [appLogo, setAppLogo] = useState(null)
    const [postImage, setPostImage] = useState(null)
    const [postCaption, setPostCaption] = useState("")
    const [createdOn, setCreatedOn] = useState();
    const [appId, setAppId] = useState();
    const [secretAccessKey, setSecretAccessKey] = useState();
    const [code, setCode] = useState(null);
    const [createCode, setCreateCode] = useState(null);
    const [accessToken, setAccessToken] = useState("");
    const [products, setProducts] = useState([]);

    const { app_id } = useParams();

    const permissions_obj = {
        1: "MyEra Create",
        2: "MyEra Create for products",
        3: "MyEra Publish"
    }

    useEffect(() => {
        const { accessToken } = isAuthenticated();
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        document.title = "View App";

        console.log(app_id)

        fetch(`${process.env.REACT_APP_BACKEND}/get_app/${app_id}/`, { headers })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            setAppName(data[0].name)
            setAppLogo(data[0].logo)
            setPostImage(data[0].post_image)
            setPostCaption(data[0].post_caption)
            setCreatedOn(data[0].created_on)
            setAppId(data[0].app_id)
            setSecretAccessKey(data[0].secret_access_key)
            setProducts(data[0].permissions)
          });
      }, []);

      function generateCode(){

        // let requestOptions = {
        //     method: 'POST',
        //     body: JSON.stringify({
        //         "app_id": "20052137768028", 
        //         "secret_access_key": "7o95s7781ect8ffclufsz9m9auwn0z"
        //     })
        // }

        // fetch( `${process.env.REACT_APP_BACKEND}/generate_api_access_token/`, requestOptions)
        // .then((response) => response.json())
        // .then((data) => {
        //     console.log(data.access_token)
        //     setAccessToken(data.access_token)
        // })

        setCode(
            `
                <script
                                            id="myera__create__product__script"
                                            type="text/javascript"
                                            src="https://myera-assets.s3.ap-south-1.amazonaws.com/MyEra-API/myera_create_product_minified.js"
                                            data-app_id=${appId}
                                            data-secret_access_key=${secretAccessKey}
                ></script>
                `
        )
      }

      function generateCreateCode() {
        let requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                "app_id": appId, 
                "secret_access_key": secretAccessKey
            })
        }

        fetch( `${process.env.REACT_APP_BACKEND}/generate_api_access_token/`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            console.log(data.access_token)
            setAccessToken(data.access_token)

            setCreateCode(
                `
                <script
                                            id="myera__create__script"
                                            type="text/javascript"
                                            src="https://myera-assets.s3.ap-south-1.amazonaws.com/MyEra-API/myera_create_minified.js"
                                            data-access-code=${data.access_token.split(".")[0]}
                ></script>
                `
            )

        })
      }

    return (
        <div>
            <div className="details-container">
                <div class="header-style">
                <h2 style={{textAlign: 'center'}}>App details</h2>
                <Link to="/developer/my-apps" style={{textDecoration: 'none'}}>
                    <ArrowBackIcon></ArrowBackIcon>
                </Link>
                </div>
                <div class="inner-container-details">
                    <div class="row-details">
                        <div className="label-text">App Name:</div><div className="value-text">{appName}</div>
                    </div>
                    <div class="row-details">
                        <div className="label-text">App ID:</div><div className="value-text">{appId}</div>
                    </div>
                    <div class="row-details">
                        <div className="label-text">Secret Access Key:</div><div className="value-text">{secretAccessKey}</div>
                    </div>
                    <div class="row-details">
                        <div className="label-text">Logo:</div><div className="value-text"><img src={appLogo} height="100px"></img></div>
                    </div>
                    <br/>
                    <div class="row-details">
                        <div className="label-text">Products:</div><div className="value-text">
                            {products.map((product) => {
                                return (<div className="product-text">{permissions_obj[product]}</div>)
                            })
                                // for (let product of products) {
                                //     return()
                                    
                                // }
                            }
                        </div>
                    </div>  
                    <br/><br/>
                    <hr></hr>
                    <br/>
                    {products.includes(2) ? (
                        <div>
                            <h4 className="product-heading">MyEra Publish</h4>
                            <div class="row-details">
                                <div className="label-text">Post Image:</div><div className="value-text"><img src={postImage} height="100px"></img></div>
                            </div>
                            <div class="row-details">
                                <div className="label-text">Post Caption:</div><div className="value-text">{postCaption}</div>
                            </div>
                            <br/>
                            <hr></hr>
                        </div>
                    ) : (<></>)
                    }
                    {
                        products.includes(2) ? (
                            <div>
                                <h4 className="product-heading">MyEra Create for products</h4>
                                {
                                code ? (<></>) : (
                                    <div>
                                        <Button onClick={() => generateCode()} variant="contained" style={{textTransform: 'none'}}>Generate Code</Button>
                                    </div>
                                )
                                }
                                <div className="code-container">
                                    
                                    {code ? (
                                        <div>
                                        <ContentCopy className="copy-code-icon" onClick={() => {
                                            navigator.clipboard.writeText(code)
                                            toast.success("Code copied", {
                                                position: "top-center",
                                                theme: "dark",
                                                autoClose: 2500,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                        }}></ContentCopy>
                                        <SyntaxHighlighter language="html" style={atomOneDark}>
                                            {code}
                                        </SyntaxHighlighter>
                                        Add this html code to your website and get the power of MyEra create!
                                    </div>
                                    ) : <></>}
                                </div>
                                <br/>
                                <hr></hr>
                            </div>
                        ) : (<></>)
                    }
                    {
                        products.includes(1) ? (
                            <div>
                                <h4 className="product-heading">MyEra Create</h4>
                                {
                                createCode ? (<></>) : (
                                    <div>
                                        <Button onClick={() => generateCreateCode()} variant="contained" style={{textTransform: 'none'}}>Generate Code</Button>
                                    </div>
                                )}
                                <div className="code-container">
                                    {createCode ? (
                                        <div>
                                        <ContentCopy className="copy-code-icon" onClick={() => {
                                            navigator.clipboard.writeText(createCode)
                                            toast.success("Code copied", {
                                                position: "top-center",
                                                theme: "dark",
                                                autoClose: 2500,
                                                hideProgressBar: true,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            });
                                        }}></ContentCopy>
                                        <SyntaxHighlighter language="html" style={atomOneDark}>
                                        {createCode}
                                    </SyntaxHighlighter>
                                    Add this html code to your website and get the power of MyEra create for products!
                                    </div>
                                    ) : <></>}
                                </div>
                                
                                <br/>
                                <hr></hr>
                            </div>
                        ) : (<></>)
                    }
                </div>
            </div>
        </div>
    )
}