import styled from "styled-components";
import React, { useEffect } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Image1 from "../../Components/Images/create_now_1.png";
import Image2 from "../../Components/Images/create_now_2.png";
import Image3 from "../../Components/Images/create_now_3.png";
import "./CreateNow.css";
import { Link } from "react-router-dom";
import { isAuthenticatedApi } from "../../Helper/Endpoints/Endpoints";

const Wrapper = styled.div`
  display: flex;
  background: rgb(238, 245, 255);
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

const CreateNowApi = (props) => {
  const { accessToken } = useParams();
  useEffect(() => { 
   const authenticated = isAuthenticatedApi(accessToken);
  }, []);

  return (
    <Wrapper id="outer-container-1">
      <MainDashboard>
        <div
          style={{
            marginTop: "0%",
            backgroundColor: "rgb(13,16, 22)",
            height: "100vh",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "80%",
              display: "flex",
              marginBottom: "4vh",
              display: "flex",
            }}
          >
            <div
              style={{
                background: "#2C303A",
                width: "95%",
                height: "100%",
                borderRadius: "40px",
                margin: "auto",
                marginTop: "8vh",
              }}
            >
              <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "600",
                  fontSize: "4vh",
                  marginLeft: "auto",
                  marginTop: "4vh",
                  marginBottom: "3vh",
                  textAlign: "center",
                  color: "#c8daff",
                }}
              >
                What do you want to create?
              </div>
              <div
                style={{
                  marginTop: "7vh",
                  marginBottom: "15vh",
                  display: "flex",
                }}
              >
                <Link
                  to="/create-now/product-journey-api"
                  className="divContainer"
                >
                  <img className="img-card" src={Image1}></img>
                  <div className="text-card">Product Mockups</div>
                </Link>
                <Link
                  to="/create-now/campaign-journey-api"
                  className="divContainer"
                >
                  <img className="img-card" src={Image2}></img>
                  <div className="text-card">Social Media Creative</div>
                </Link>
                <Link
                  to="/create-now/try-on-journey-api"
                  className="divContainer"
                >
                  <img className="img-card" src={Image3}></img>
                  <div className="text-card">Experience Try-On</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </MainDashboard>
    </Wrapper>
  );
};

export default CreateNowApi;
