import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar"
import BrandHealth from "./BrandHealth";
import { useState, useEffect } from "react";
const Wrapper = styled.div`
  display: flex;
  background: rgb(13,16, 22);
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

export default function Analytics(){

    useEffect(() => { 
        document.title = "MyEra | Analytics"; 
      }, []);

    return(
        <Wrapper id="outer-container-1">
            <Sidebar/>
            <MainDashboard>
                <Topbar/>
                <div style={{color: "white", fontSize: "26px", paddingLeft: "4%", paddingTop: "2%", borderBottom: "1px solid #313849"}}>
                Analytics
              </div>
            <BrandHealth />
            </MainDashboard>
        </Wrapper>
    )
}