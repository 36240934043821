import Modal from "react-modal";
import React, { useState, useEffect } from "react";
import ImageCropper from "../ImageCropper/ImageCropper";
import styles from "./ImageUploadModal.module.css";
import { CloseRounded } from "@mui/icons-material";

const ImageUploadModal = ({
	state,
	images,
	onCropperSave,
	isOpen,
	setIsOpen,
}) => {
	console.log(images);

	const titleMap = {
		cropper: "Crop Image",
	};
	const [ modalHeight, setModalHeight ] = useState("80%");

	const modalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			transform: "translate(-50%, -50%)",
			backgroundColor: "white",
			borderRadius: "3vh",
			textAlign: "center",
			height: modalHeight,
			width: "90%",
			color: "#FFFFFF",
			padding: "0%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			maxWidth: "900px",
			zIndex: 1000,
			height: "fit-content",
			padding: "40px",
		},

		overlay: {
			background: "rgba(0, 0, 0, 0.25)",
			zIndex: 3,
		},
	};

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 668) {
				setModalHeight("95%");
			} else {
				setModalHeight("80%");
			}
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div>
			<Modal isOpen={ isOpen } style={ modalStyles }>
				<div>
					<div className={ styles.header }>
						<div className={ styles.modal_title }>
							{ titleMap.state ? titleMap.state : "Crop Image" }
						</div>
						<div className={ styles.modal_subtitle }>
							⚠️ Inappropriate content may lead to order cancellation
						</div>
					</div>
					{ state === "cropper" && (
						<ImageCropper
							images={ images }
							onSave={ (images) => {
								onCropperSave(images);
								setIsOpen(false);
							} }
						/>
					) }
				</div>
				<div className={ styles.close_icon } onClick={ () => {
					onCropperSave([]);
					setIsOpen(false);
				}
				}>
					<CloseRounded />
				</div>
			</Modal>
		</div>
	);
};

export default ImageUploadModal;
