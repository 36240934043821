import React, { Component, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import Modal from "react-modal";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import { ToastContainer, toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import "./apps.css";
import { Delete } from "@mui/icons-material";

const theme = createTheme({
  palette: {
    mode: "dark",
    // primary: {
    //     main: "rgb(132, 232, 244)",

    // }
  },
});

export default function Apps() {
  const [apps, setApps] = useState([]);
  // const [secretAccessKey, setsecretAccessKey] = useState();

  useEffect(() => {
    const { accessToken } = isAuthenticated();
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    document.title = "apps page";

    fetch(`${process.env.REACT_APP_BACKEND}/get_apps/`, { headers })
      .then((response) => response.json())
      .then((data) => {
        console.log("API CALL!");
        console.log(data);
        setApps(data)
        // setsecretAccessKey(data[0].secret_access_key);
      });
  }, []);

  function handleDelete(app_id)
  {
    const { accessToken } = isAuthenticated();
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/delete_app/${app_id}`, { headers })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setApps(apps.filter(
          function(app){
              return app.app_id != app_id
          }
      ))
      
      toast.success("App deleted successfully", {
          position: "top-center",
          theme: "dark",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });

      });
  }

  return (
    <ThemeProvider theme={theme}>
      <div class="outer-container">
        <div class="header">
          <h2>My Apps</h2>
          <Button
            sx={{ ml: "10%" }}
            variant="contained"
            style={{textTransform: 'none'}}
            startIcon={<AddIcon></AddIcon>}
            component={Link}
            to="/developer/create_app"
          >
            New App
          </Button>
        </div>
        <br></br>
        <div className="apps-container">
          <Grid container spacing={2} rowSpacing={2}>
            {
              apps.map( app => {
                let app_id_link = `/developer/my-apps/${app.app_id}`
                return(
                  <Grid item xs={3}>
                    <Card sx={{ minWidth: 275, maxWidth: 375 }}>
                      <CardContent>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.secondary"
                          gutterBottom
                        >
                          App
                          <DeleteIcon style={{marginLeft: '90%', marginTop: '-38px', cursor: 'pointer'}} onClick={() => handleDelete(app.app_id)}></DeleteIcon>
                        </Typography>
                        <Typography variant="h5" component="div">
                          {app.name}
                        </Typography>
                        <Typography sx={{ fontSize: 13 }} color="text.secondary">
                          App ID: {app.app_id}
                          <br></br>
                          {/* Created on: 8th Dec 2022 */}
                          Secret Access Key: {app.secret_access_key}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Link to={app_id_link} style={{textDecoration: 'none'}}>
                          <Button size="small">View</Button>
                        </Link>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })
            }
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  );
}
// export default class Apps extends Component {
//   render() {
//     return (
//       <ThemeProvider theme={theme}>
//         <div class="outer-container">
//           <div class="header">
//             <h2>Apps</h2>
//             <Button
//               sx={{ ml: "10%" }}
//               variant="contained"
//               startIcon={<AddIcon></AddIcon>}
//               component={Link}
//               to="/developer/create_app"
//             >
//               New App
//             </Button>
//           </div>
//           <br></br>
//           <div class="inner-container">
//             <Grid container spacing={2} rowSpacing={2}>
//               <Grid item xs={3}>
//                 <Card sx={{ minWidth: 275, maxWidth: 375 }}>
//                   <CardContent>
//                     <Typography
//                       sx={{ fontSize: 14 }}
//                       color="text.secondary"
//                       gutterBottom
//                     >
//                       App
//                     </Typography>
//                     <Typography variant="h5" component="div">
//                       App Name
//                     </Typography>
//                     <Typography sx={{ fontSize: 13 }} color="text.secondary">
//                       App ID: 987654321
//                       <br></br>
//                       Created on: 8th Dec 2022
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button size="small">View</Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//             </Grid>
//           </div>
//         </div>
//       </ThemeProvider>
//     );
//   }
// }
