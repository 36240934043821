import React from "react";
import styles from "../Common/Tabs/tabs.module.css";

const SizeTab = ({ sizes, onSelectSize, variantID, sizeURL }) => {
	return (
		<div className={ styles.tab_container }>
			<div>
				<a style={ {
					unset: "all",
					cursor: "pointer",

				} } id="MyEraSizeImage" href={ sizeURL } target="_blank" >See Size Reference</a>
			</div>
			<div className={ styles.options }>
				{ sizes.map((option) => (
					<div
						key={ option }
						className={ [
							styles.font_option,
							variantID === option.id && styles.selected,
						].join(" ") }
						onClick={ () => onSelectSize(option) }
					>
						{ option.size }
					</div>
				)) }
			</div>
		</div>
	);
};

export default SizeTab;
