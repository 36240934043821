import { display } from "@mui/system";
import ImageSlider from "../ImageSlider";
import TrendingTags from "../TrendingTags/TrendingTags";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar"
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import RSSFeeds from "../RSSFeeds/RSSFeeds";

const Wrapper = styled.div`
  display: flex;
  background: rgb(13,16, 22);
  height:  "100vh";
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

function Trendwatch() {
  
  return (

    <Wrapper id="outer-container">

      <Sidebar />

      <MainDashboard>
        <Topbar/>
        <div style={{ width: "95%", height: '500px', marginLeft: '2%', display: "flex", justifyContent: "space-between", marginTop: "1vh"}}>
          <TrendingTags />
          <br></br>
          {/*<p style={{ textAlign: "left", fontSize: "20px" }}>
              <strong>Design Inspirations</strong>
            </p>
            <br></br>
      <ImageSlider />*/}
          <br></br>
          <ImageSlider />
        </div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "-3vh"}}>
          <RSSFeeds />
        </div>
      </MainDashboard>

    </Wrapper>

    // <div style={{ display: 'flex', flexDirection: 'row' }}>

    //   <Sidebar />
    //   <div>
    //     <Topbar />
    //     <div style={{ width: "80%", height: '850px', marginLeft: '6%'}}>
    //       <p style={{ textAlign: "left", fontSize: "20px" }}>
    //         <strong>Explore Trending Tags</strong>
    //       </p>
    //       <TrendingTags />
    //       <br></br>
    //       {/*<p style={{ textAlign: "left", fontSize: "20px" }}>
    //           <strong>Design Inspirations</strong>
    //         </p>
    //         <br></br>
    //   <ImageSlider />*/}
    //       <p
    //         style={{
    //           textAlign: "left",
    //           fontSize: "20px",
    //           marginTop: "3vh",
    //         }}
    //       >
    //         <strong>Creative Inspirations</strong>
    //       </p>
    //       <br></br>
    //       <ImageSlider />
    //     </div>
    //   </div>
    // </div>

  );
}

export default Trendwatch;