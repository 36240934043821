import React, { Component } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Modal from "react-modal";
import { IconButton, Input, TextField } from "@mui/material";
import { Cancel, Crop, Delete, PhotoCamera } from "@material-ui/icons";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import { ToastContainer, toast } from "react-toastify";
import LinearProgress from "@mui/material/LinearProgress";
import { LoadingButton } from "@mui/lab";
import "./asset_library.css";
import default_image from "../SocialPostsCalander/default_image.png";
import ImageCropper from "../ImageCropper/ImageCropper";
import { getCroppedImageService } from "../Generic/messageService";
import { CircularProgress } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "rgb(132, 232, 244)",
		},
	},
});

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: "67.5%",
		width: "45%",
		color: "#FFFFFF",
		padding: "0%",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

export class AssetLibrary extends Component {
	constructor() {
		super();

		getCroppedImageService.getMessage().subscribe((message) => {
			var byteString = atob(message.imageURL.split(",")[1]);
			var mimeString = message.imageURL
				.split(",")[0]
				.split(":")[1]
				.split(";")[0];
			var ab = new ArrayBuffer(byteString.length);
			var ia = new Uint8Array(ab);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			var blob = new Blob([ab], { type: mimeString });
			var file = new File([blob], "image.png");
			// console.log(file)

			let updated = this.state.ImageURLs;
			let pos = this.state.cropperPosition;
			updated[message.originalURL] = file;
			pos[message.originalURL] = message.position;
			this.setState({ ImageURLs: updated, cropperPosition: pos });
		});

		this.state = {
			modalIsOpen: false,
			selectedImage: null,
			assetNote: "",
			fetchingAssets: true,
			addingAssets: false,
			user_assets: [],
			croppedImage: null,
			ImageURLs: {},
			cropperPosition: {},
			editFieldIsOpen: false,
		};

		this.imageElement = React.createRef();
	}

	openModal() {
		this.setState({ modalIsOpen: true });
	}

	closeModal() {
		this.setState({ modalIsOpen: false });
	}

	onFileChange(e) {
		let images = {};
		let position = {};

		for (let file of e.target.files) {
			var url = URL.createObjectURL(file);
			images[url] = file;
			position[url] = {};
		}

		this.setState({
			selectedImage: Object.keys(images)[0],
			ImageURLs: images,
			cropperPosition: position,
		});
	}

	addAsset() {
		this.setState({ addingAssets: true });

		var form_data = new FormData();
		form_data.append("asset_note", this.state.assetNote);
		for (const [img, file] of Object.entries(this.state.ImageURLs))
			form_data.append("assets", file);

		const { accessToken } = isAuthenticated();
		var requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/upload_multiple_user_assets/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				toast.success("Asset added to Library", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					addingAssets: false,
					selectedImage: null,
					assetNote: "",
					ImageURLs: {},
				});

				let user_assets = [];
				for (let ele of data.data) user_assets.push(ele[0]);

				this.setState({
					user_assets: user_assets.concat(this.state.user_assets),
				});
			})
			.catch((err) => {
				toast.error("Asset failed to be added to Library", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				this.setState({
					addingAssets: false,
					selectedImage: null,
					assetNote: "",
					ImageURLs: {},
				});
			});
	}

	handleDownloadButton(e, url) {
		fetch(url, {
			mode: "no-cors",
		})
			.then((response) => response.blob())
			.then((blob) => {
				let blobUrl = window.URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.download = "image.png";
				a.href = blobUrl;
				document.body.appendChild(a);
				a.click();
				a.remove();
			});

		toast.success("Asset downloaded", {
			position: "top-center",
			theme: "dark",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}

	handleDeleteButton(id) {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/delete_user_asset/${id}`, {
			headers,
		})
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				this.setState({
					user_assets: this.state.user_assets.filter(function (asset) {
						return asset.id != id;
					}),
				});

				toast.success("Asset deleted!", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});
	}

	render() {
		let Cropper = <></>;
		if (this.state.selectedImage) {
			console.log("Cropping");

			Cropper = (
				<div key={this.state.selectedImage} style={{ display: "flex" }}>
					<div style={{ marginLeft: "25%" }}>
						<ImageCropper
							src={this.state.selectedImage}
							position={this.state.cropperPosition[this.state.selectedImage]}
						></ImageCropper>
					</div>
					<Cancel
						onClick={() => {
							const updated = { ...this.state.ImageURLs };
							delete updated[this.state.selectedImage];
							this.setState({ ImageURLs: updated });
							if (this.state.ImageURLs.length == 1)
								this.setState({ selectedImage: null });
							else if (
								Object.keys(this.state.ImageURLs)[0] ===
								this.state.selectedImage
							)
								this.setState({
									selectedImage: Object.keys(this.state.ImageURLs)[1],
								});
							else
								this.setState({
									selectedImage: Object.keys(this.state.ImageURLs)[0],
								});
						}}
						style={{ marginLeft: "10%" }}
					></Cancel>
				</div>
			);
		} else {
			Cropper = (
				<div
					style={{
						borderRadius: "5%",
						border: "1px solid rgb(255, 255, 255, 0.2)",
						height: "250px",
						width: "250px",
						margin: "auto",
						marginTop: "20px",
						display: this.state.selectedImage ? "none" : "block",
					}}
				>
					<label style={{ display: "block" }}>
						<input
							accept="image/*"
							id="contained-button-file"
							multiple
							type="file"
							onChange={(e) => this.onFileChange(e)}
							style={{ display: "none" }}
						/>
						<Button
							sx={{ mt: "25%", textTransform: "none" }}
							color="primary"
							aria-label="upload picture"
							component="span"
						>
							<div>
								<div>
									<CloudUploadOutlinedIcon
										style={{ width: "75px", height: "75px" }}
									></CloudUploadOutlinedIcon>
								</div>
								<div style={{ display: "block", fontSize: "20px" }}>
									Select File
								</div>
							</div>
						</Button>
					</label>
				</div>
			);
		}

		return (
			<ThemeProvider theme={theme}>
				<div>
					<br></br>
					<div style={{ display: "flex", marginLeft: "37%", color: "white" }}>
						<h3>Asset Library</h3>
						<Button
							variant="contained"
							size="medium"
							style={{ marginLeft: "32%" }}
							onClick={() => this.openModal()}
						>
							Add Asset
						</Button>
					</div>
					<CircularProgress
						style={{
							marginLeft: "45%",
							marginTop: "20%",
							display: this.state.fetchingAssets ? "block" : "none",
						}}
					></CircularProgress>
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							width: "90%",
							margin: "auto",
							overflowY: "visible",
						}}
					>
						{this.state.user_assets.map((asset) => {
							return (
								<div
									class="col-sm-4"
									style={{ width: "auto", margin: "auto" }}
									key={asset.id}
								>
									{/* <a href={asset.asset}>  */}
									<img className="styleImage" src={asset.asset}></img>
									{/* </a> */}
									<p style={{ color: "white", textAlign: "center" }}>
										{asset.asset_note}
										<DeleteIcon
											onClick={(e) => this.handleDeleteButton(asset.id)}
											className="styleIcon"
											style={{ color: "white" }}
										/>
									</p>
								</div>
							);
						})}
					</div>
				</div>
				<Modal isOpen={this.state.modalIsOpen} style={modalStyles}>
					<LinearProgress
						style={{ display: this.state.addingAssets ? "block" : "none" }}
					/>
					<div>
						<CloseIcon
							style={{
								position: "absolute",
								top: "25px",
								right: "25px",
								cursor: "pointer",
							}}
							onClick={() => this.closeModal()}
						></CloseIcon>
					</div>

					{this.state.selectedImage ? (
						<div
							className="user_assets"
							style={{
								position: "absolute",
								cursor: "pointer",
								top: 130,
								left: 15,
								height: "45vh",
								width: "25%",
								overflow: "auto",
							}}
						>
							{Object.entries(this.state.ImageURLs).map(([image, file]) => {
								return (
									<div
										key={image}
										className="img-wrap1"
										onClick={() => {
											this.setState({ selectedImage: image });
										}}
										style={{
											borderRadius: "10px",
											height: "75px",
											width: "50px",
											marginTop: "15px",
											marginBottom: "50px",
										}}
									>
										<img className="styleAssetImage" src={image} />
									</div>
								);
							})}
						</div>
					) : (
						<></>
					)}

					<br></br>
					<br></br>
					<br></br>
					<h3>Asset Upload</h3>

					{/* <div style={{borderRadius: '5%', border: '1px solid rgb(255, 255, 255, 0.2)', height: '275px', width: '275px', marginLeft: '33%', display: this.state.selectedImage ? 'none' : 'block'}}>
                        <label style={{display: 'block'}}>
                        <input accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => this.onFileChange(e)} onInput={() => this.handleImageCrop()} style={{display: 'none'}}/>
                        <Button sx={{mt: '45%', textTransform: 'none'}} color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera></PhotoCamera> Upload
                        </Button>
                        </label>
                    </div> */}
					{/* <div style={{display: this.state.selectedImage ? 'flex' : 'none', justifyContent: 'center', marginLeft: '5%'}}> 
                        {Cropper}
                        <ImageCropper src = "https://quarkme-assets.s3.amazonaws.com/media/image_fZup4z7.png"></ImageCropper>
                        <Button onClick={() => this.setState({selectedImage: null})}>Choose another</Button>

                        <Cancel onClick={() => this.setState({selectedImage: null, selectedFile: null})}></Cancel>
                    </div> */}
					{Cropper}

					<br></br>
					<TextField
						label="Asset Tag"
						sx={{ width: "30%", input: { textAlign: "center" } }}
						value={this.state.assetNote}
						onChange={(e) => this.setState({ assetNote: e.target.value })}
					></TextField>
					<br></br>
					<br></br>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<LoadingButton
							variant="contained"
							loading={this.state.addingAssets}
							style={{
								textTransform: "none",
								backgroundColor: "#634fe5",
								width: "30%",
								borderRadius: "20px",
								height: "50px",
								color: "white",
								fontSize: "22px",
								fontWeight: "lighter",
							}}
							onClick={() => this.addAsset()}
						>
							Upload
						</LoadingButton>
					</div>
				</Modal>
			</ThemeProvider>
		);
	}

	componentDidMount() {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/get_user_assets/`, { headers })
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				this.setState({ user_assets: data });
				this.setState({ fetchingAssets: false });
			});
	}
}

// export default AssetLibrary;
