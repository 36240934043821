import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { forgotPassword } from '../../Helper/Endpoints/Endpoints';

 const InputWrapper = styled.div`
  margin-bottom: 4vh;
  font-family: 'Open Sans', sans-serif;
`;

const Input = styled.input`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: rgba(40, 43, 48, 0.5);
  color: rgba(255, 255, 255, 1);
  outline: none;
`;

 const InputLabel = styled.div`
  color: rgba(255, 255, 255, 0.35);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-align: left;
  font-weight: 100;
`;

 const ButtonWrapper = styled.div`
  margin-top: 6vh;
`;

const MobileNav = styled.div`
  display: flex;
  color: white;
  background-color: rgba(1, 15, 30, 255);
  height: 8vh;
  justify-content: space-between;
  border-bottom: solid rgba(241, 241, 241, 0.07);
  text-align: center;
`;

const validationSchema = Yup.object({
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
});

export default function ForgotPassword(props) {
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: async ({ email }) => {
      console.log(email);
      const response = await forgotPassword({ email });
      console.log(response);
     
      props.history.push("/otp", { email: email});

    }
  });


  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ margin: 'auto', width: '50%' }}>

        <div style={{marginTop: '-25%'}}>
          <div class="Bodycontainer_inner" >Forgot Password ?</div>
          <div class=" InputLabel" style={{textAlign: "center"}}>Get OTP on your registered email address to reset your password.</div>
        </div>

        <div style={{marginTop: '15%'}}>
          <InputLabel>Registered Email Address*</InputLabel>
          <InputWrapper>
            <Input id="email" value={formik.values.email} onChange={formik.handleChange} type="email" />
          </InputWrapper>
          <ButtonWrapper>
            <Button
              style={{
                backgroundColor: 'rgba(108, 77, 232, 1)',
                fontFamily: 'Open Sans',
                borderRadius: '5vh',
                textTransform: 'none',
                height: '5vh'
              }}
              variant="contained"
              color="secondary"
              type="button"
              fullWidth={true}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Continue
            </Button>
          </ButtonWrapper>
        </div>
      </div>
    </div>
  );
}
