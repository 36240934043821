import { useState } from "react";
import React from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FaSlackHash, FaGlobe, FaSave, FaPlus } from "react-icons/fa";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { BsTrophyFill } from "react-icons/bs";
import { HiOutlineHome } from "react-icons/hi";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { fontFamily } from "@mui/system";
import "./Sidebar.css";
import { Tooltip } from "@material-ui/core";

const CreateDiv = styled.div`
  background-color: #1e2638;
  border-radius: 15px;
  // width: 85%;
  // height: 20vh;
  margin: 2.5vh auto 1vh auto;
  padding: 1rem;
  font-size: 0.9rem;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CreateContainer = styled.div`
  margin: auto;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const TextDiv = styled.div`
  color: white;
  padding: 15px;
  margin: "0 .5rem";
`;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(true);

  // added styles
  const styles = {
    menuIcon: {
      marginTop: "20px",
      marginLeft: "20px",
      fontFamily: "Wavehaus",
    },
  };
  const onClickMenuIcon = () => {
    setCollapsed(!collapsed);
  };

  return (
    <ProSidebar
      collapsed={collapsed}
      style={{
        backgroundColor: "#0B1120",
        height: "100vh",
        fontSize: "16.5px",
      }}
    >
      <SidebarHeader style={{ height: "8vh" }}>
        <div style={styles.menuIcon} onClick={onClickMenuIcon}>
          <AiOutlineMenu size={24} style={{ cursor: "pointer" }} />
        </div>
      </SidebarHeader>
      {!collapsed && (
        <CreateDiv>
          <CreateContainer>
            <TextDiv>
              Pro Tip: Make your post
              <br />
              in 30 seconds with MyEra!
            </TextDiv>
            <Link style={{ textDecoration: "none" }} to="/create-now">
              <Button
                style={{
                  backgroundColor: "rgba(132, 232, 244, 1)",
                  fontFamily: "Open Sans",
                  textTransform: "none",
                  borderRadius: "100px",
                  padding: "1vh 3vh",
                }}
                variant="contained"
                //  onClick={openModal}
              >
                + Create
              </Button>
            </Link>
          </CreateContainer>
        </CreateDiv>
      )}

      <Menu iconShape="square" style={{ marginTop: "4%" }}>
        {collapsed && (
          <Tooltip
            title={
              collapsed ? (
                <div style={{ fontSize: "14px", padding: 3 }}>Create</div>
              ) : null
            }
            enterTouchDelay={0}
            arrow
            placement="right"
          >
            <MenuItem
              icon={<FaPlus size={20} />}
              style={{ marginTop: "1%", fontSize: "17px", marginBottom: "-1%" }}
              onClick={() => {
                document.title = "MyEra | Create";
              }}
            >
              <Link
                style={{ textDecoration: "none" }}
                to="/create-now"
              ></Link>
            </MenuItem>
          </Tooltip>
        )}
        <Tooltip
          title={
            collapsed ? (
              <div style={{ fontSize: "14px", padding: 3 }}>Dashboard</div>
            ) : (
              ""
            )
          }
          arrow
          placement="right"
        >
          <MenuItem
            icon={<HiOutlineHome size={20} />}
            style={
              window.location.pathname === "/dashboard"
                ? {
                    color: "#84E8F4 ",
                    backgroundColor: "#292E3B",
                    width: "85%",
                    borderRadius: "0px 100px 100px 0px",
                    fontSize: "18px",
                  }
                : { fontSize: "20px" }
            }
            onClick={() => {
              document.title = "MyEra | Dashboard";
            }}
          >
            <Link to="/dashboard">Dashboard</Link>
          </MenuItem>
        </Tooltip>

        {/*<Tooltip title={collapsed?<div style={{fontSize: "14px",padding: 3,}}>Analytics</div>:''} arrow placement="right">
        <MenuItem icon={<AnalyticsIcon size={20}/>} style={
          window.location.pathname === "/analytics"
            ? {
                color: "#84E8F4 ",
                backgroundColor: "#292E3B",
                width: "85%",
                borderRadius: "0px 100px 100px 0px",
                fontSize: "18px"
              }
            : { fontSize: "20px" }
        } onClick={() => {document.title = "MyEra | Analytics"; }}>
          <Link  to="/analytics">
            
              Analytics
          </Link>
        </MenuItem>
        </Tooltip>*/}

        <SubMenu
          title="Trendwatch"
          icon={<FaGlobe size={20} />}
          style={
            window.location.pathname === "/trendwatch"
              ? {
                  marginTop: "3%",
                  color: "#84E8F4 ",
                  backgroundColor: "#292E3B",
                  width: "85%",
                  borderRadius: "0px 100px 100px 0px",
                  fontSize: "18px",
                }
              : { marginTop: "3%", fontSize: "18px" }
          }
        >
          <MenuItem
            icon={<FaGlobe size={20} />}
            style={{ marginTop: "1.5%", fontSize: "17px", color: "#adadad" }}
            onClick={() => {
              document.title = "MyEra | Trendwatch";
            }}
          >
            <Link style={{ textDecoration: "none" }} to="/trendwatch">
              Summary
            </Link>
          </MenuItem>
          <MenuItem
            icon={<LightbulbIcon fontSize="small" />}
            style={{ marginTop: "1.5%", fontSize: "17px", color: "#adadad" }}
            onClick={() => {
              document.title = "MyEra | Trendwatch";
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to="/trendwatch/content-ideas"
            >
              Content Ideas
            </Link>
          </MenuItem>

          <MenuItem
            icon={<FaSlackHash size={20} />}
            style={{ marginTop: "1.5%", fontSize: "17px", color: "#adadad" }}
            onClick={() => {
              document.title = "MyEra | Trendwatch";
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to="/trendwatch/trending-hashtag"
            >
              Trending Hashtags
            </Link>
          </MenuItem>

          <MenuItem
            icon={<PhotoLibraryIcon fontSize="small" />}
            style={{ marginTop: "1.5%", fontSize: "17px", color: "#adadad" }}
            onClick={() => {
              document.title = "MyEra | Trendwatch";
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to="/trendwatch/trending-media"
            >
              Trending Media
            </Link>
          </MenuItem>
        </SubMenu>
        <Tooltip
          title={
            collapsed ? (
              <div style={{ fontSize: "14px", padding: 3 }}>Publish</div>
            ) : (
              ""
            )
          }
          arrow
          placement="right"
        >
          <MenuItem
            icon={<SendIcon size={20} />}
            style={
              window.location.pathname === "/post-scheduler"
                ? {
                    marginTop: "3%",
                    color: "#84E8F4 ",
                    backgroundColor: "#292E3B",
                    width: "85%",
                    borderRadius: "0px 100px 100px 0px",
                    fontSize: "18px",
                  }
                : { marginTop: "3%", fontSize: "18px" }
            }
            onClick={() => {
              document.title = "MyEra | Publish";
            }}
          >
            <Link style={{ textDecoration: "none" }} to="/post-scheduler">
              Publish
            </Link>
          </MenuItem>
        </Tooltip>

        <Tooltip
          title={
            collapsed ? (
              <div style={{ fontSize: "14px", padding: 3 }}>Saved Posts</div>
            ) : (
              ""
            )
          }
          arrow
          placement="right"
        >
          <MenuItem
            icon={<FaSave size={20} />}
            style={
              window.location.pathname === "/saved-creatives"
                ? {
                    marginTop: "3%",
                    color: "#84E8F4 ",
                    backgroundColor: "#292E3B",
                    width: "85%",
                    borderRadius: "0px 100px 100px 0px",
                    fontSize: "18px",
                  }
                : { marginTop: "3%", fontSize: "18px" }
            }
            onClick={() => {
              document.title = "MyEra | Saved Posts";
            }}
          >
            <Link style={{ textDecoration: "none" }} to="/saved-creatives">
              Saved Posts
            </Link>
          </MenuItem>
        </Tooltip>

        {/* <MenuItem icon={<AutoFixHighOutlinedIcon size={20}/> } style={{ marginTop: "3%", fontSize: "18px" }} onClick={() => {document.title = "MyEra | Advisor"; }}>
          <Link style={{ textDecoration: "none" }} to="/advisor">
            Advisor 
          </Link>
        </MenuItem> */}
        <Tooltip
          title={
            collapsed ? (
              <div style={{ fontSize: "14px", padding: 3 }}>Upgrade Plan</div>
            ) : (
              ""
            )
          }
          arrow
          placement="right"
        >
          <MenuItem
            icon={<BsTrophyFill size={20} />}
            style={
              //   window.location.pathname === "/upgrade-plan" ? { marginTop: "3%", color: "#84E8F4 ",
              // backgroundColor: "#292E3B",
              // width: "85%",
              // borderRadius: "0px 100px 100px 0px",
              // fontSize: "18px" }:
              { marginTop: "3%", fontSize: "18px" }
            }
            onClick={() => {
              document.title = "MyEra | Upgrade Plan";
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href="https://payu.in/web/504A3B93FCE8C13FE87FB5F84C789853"
              target="_blank"
              rel="noreferrer"
            >
              Upgrade Plan
            </a>
          </MenuItem>
        </Tooltip>
      </Menu>

      <Menu style={{ marginTop: "30%" }}>
        <Tooltip
          title={
            collapsed ? (
              <div style={{ fontSize: "14px", padding: 3 }}>How to use</div>
            ) : (
              ""
            )
          }
          arrow
          placement="right"
        >
          <MenuItem
            icon={<ExploreOutlinedIcon />}
            style={{ fontSize: "17px" }}
            onClick={() => {
              document.title = "MyEra | How to Use";
            }}
          >
            <Link style={{ textDecoration: "none" }} to="/how-to-use">
              How to use
            </Link>
          </MenuItem>
        </Tooltip>

        <Tooltip
          title={
            collapsed ? (
              <div style={{ fontSize: "14px", padding: 3 }}>MyEra Support</div>
            ) : (
              ""
            )
          }
          arrow
          placement="right"
        >
          <MenuItem
            icon={<HelpOutlineOutlinedIcon />}
            style={{ fontSize: "17px" }}
            onClick={() => {
              document.title = "MyEra | Contact Us";
            }}
          >
            <Link style={{ textDecoration: "none" }} to="/contact-us">
              MyEra Support
            </Link>
          </MenuItem>
        </Tooltip>
      </Menu>
    </ProSidebar>
  );
};

export default Sidebar;
