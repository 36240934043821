import React, { useState } from "react";
import styles from "./ChooseTheme.module.css";
import ThemePreview from "./ThemePreview";
import { Button } from "@mui/material";
import { ArrowBack} from "@material-ui/icons";

const ChooseTheme = ({ children, ...props }) => {
	const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
	const [primaryColor, setPrimaryColor] = useState("#E7B90B");
	const [textColor, setTextColor] = useState("#000000");

	function setLocalStorageValues() {
		let userProductData = {
			background_color: backgroundColor,
			primary_color: primaryColor,
			secondary_color: textColor
		};

		localStorage.setItem("userProductData", JSON.stringify(userProductData));
	}

	return (
		<>
			<br />
			<h1 className={styles.heading}>Theme</h1>
			<ArrowBack className={styles.backArrow} onClick={() => props.onBack()}></ArrowBack>
			<div className={styles.content}>
				<div className={styles.options}>
					<div className={styles.option_row}>
						Background Color
						<input
							value={backgroundColor}
							type="color"
							onChange={(e) => {
								setBackgroundColor(e.target.value);
							}}
						/>
					</div>
					<div className={styles.option_row}>
						Primary Color
						<input
							value={primaryColor}
							type="color"
							onChange={(e) => {
								setPrimaryColor(e.target.value);
							}}
						/>
					</div>
					<div className={styles.option_row}>
						Text Color
						<input
							value={textColor}
							type="color"
							onChange={(e) => {
								setTextColor(e.target.value);
							}}
						/>
					</div>
					<div className={styles.option_row}>
						Font Face{" "}
						<select>
							<option value="Arial">Arial</option>
						</select>
					</div>
				</div>
				<div className={styles.preview_container}>
					<ThemePreview
						backgroundColor={backgroundColor}
						primaryColor={primaryColor}
						textColor={textColor}
					/>
				</div>
			</div>
			<button
				className={styles.button}
				onClick={() => {
					setLocalStorageValues();
					props.onNext();
				}}
			>
				Next
			</button>
		</>
	);
};

export default ChooseTheme;
