import React, { Component, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import Image1 from "../../Components/Images/create_now_1.png";
import Image2 from "../../Components/Images/create_now_2.png";
import Image3 from "../../Components/Images/create_now_3.png";
import "./CreateNow.css";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "rgb(132, 232, 244)",
    },
  },
  specialOutline: {
    borderColor: "pink",
    borderWidth: 4,
  },
});

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(1,15,30,255)",
    borderRadius: "3vh",
    textAlign: "center",
    height: "67.5%",
    width: "45%",
    color: "#FFFFFF",
    padding: "0%",
  },

  overlay: {
    background: "rgba(0, 0, 0, 0.25)",
  },
};

function CreateNow(props) {
  useEffect(() => {
    const { accessToken } = isAuthenticated();
  }, []);

  return (
    <>
      <div
        style={{
          marginTop: "0%",
          backgroundColor: "rgb(13,16, 22)",
          height: "92vh",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            marginBottom: "4vh",
            display: "flex",
          }}
        >
          <div
            style={{
              background: "#2C303A",
              width: "80%",
              borderRadius: "40px",
              marginTop: "8vh",
              marginLeft: "9vw",
            }}
          >
            <label
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "600",
                fontSize: "3vh",
                width: "100%",
                textAlign: "center",
                margin: "auto",
                marginTop: "4vh",
                marginBottom: "3vh",
                color: "#c8daff",
              }}
            >
              What do you want to create?
            </label>
            <div
              style={{
                marginTop: "7vh",
                marginBottom: "15vh",
                display: "flex",
              }}
            >
              <Link to="/create-now/product-journey" className="divContainer">
                <img className="img-card" src={Image1}></img>
                <div className="text-card">Product Mockups</div>
              </Link>
              <Link to="/create-now/campaign-journey" className="divContainer">
                <img className="img-card" src={Image2}></img>
                <div className="text-card">Social Media Creative</div>
              </Link>
              <Link to="/create-now/try-on-journey" className="divContainer">
                <img className="img-card" src={Image3}></img>
                <div className="text-card">Experience Try-On</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CreateNow;
