import React, { useState, useEffect } from "react";
import Axios from "axios";
import Select from 'react-select';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft, FaCopy } from "react-icons/fa";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar"
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import "./TrendingMedia.css";

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    display: "flex",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "100px",
    backgroundColor: "#343943",
    height: '36px',
    fontSize: "14px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
      color: "white",
      fontFamily: 'Montserrat',
    };
  },
};
const Wrapper = styled.div`
  display: flex;
  background: #0D1016;
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

const TrendingMedia = ({ }) => {
  const [current, setCurrent] = useState(0);
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const [images_data, setImagesdata] = useState([]);
  const [light, setLight] = useState({
    photoIndex: 0,
    isOpen: false,
  });

  useEffect(() => {
    const { accessToken } = isAuthenticated();
    Axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND}/get_trending_templates/`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      setImagesdata(response.data.trending_templates);
    });
  }, []);
  console.log(images_data);

  const length = images_data.length;

  return (
    <Wrapper id="outer-container">
      <Sidebar />
      <MainDashboard>
        <Topbar />
        <div style={{height: "100vh", overflow: "scroll", overflowX: "hidden"}} className="outer__box">

          
            <div style={{ color: "white", fontSize: "26px", marginLeft: "2%", paddingTop: "1%" }}>
              Trending Media
            </div>

            {/* <div style={{ display: "flex", justifyContent: "start", marginTop: "3%", marginLeft: "3%" }}>
              <div style={{ width: '15%' }}>
                <Select
                  styles={customStyles}
                  // defaultValue = {this.state.selectedPlace}
                  // onChange={this.handleDropdownChange} 
                  // options={this.state.places} 
                  placeholder={'All Industry'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: '#FFFFFF',  // Placeholder color
                    },
                  })}
                />
              </div>
              <div style={{ width: '15%', marginLeft: "1%" }}>
                <Select
                  styles={customStyles}
                  // defaultValue = {this.state.selectedPlace}
                  // onChange={this.handleDropdownChange} 
                  // options={this.state.places} 
                  placeholder={'All Location'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: '#FFFFFF',  // Placeholder color
                    },
                  })}
                />
              </div>
              <div style={{ width: '15%', marginLeft: "1%" }}>
                <Select
                  styles={customStyles}
                  // defaultValue = {this.state.selectedPlace}
                  // onChange={this.handleDropdownChange} 
                  // options={this.state.places} 
                  placeholder={'All Media'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: '#FFFFFF',  // Placeholder color
                    },
                  })}
                />
              </div>
              <div style={{ width: '15%', marginLeft: "1%" }}>
                <Select
                  styles={customStyles}
                  // defaultValue = {this.state.selectedPlace}
                  // onChange={this.handleDropdownChange} 
                  // options={this.state.places} 
                  placeholder={'All Tags'}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      neutral50: '#FFFFFF',  // Placeholder color
                    },
                  })}
                />
              </div>

            </div> */}
            

            <div style={{ display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", marginTop: "2%", height: "100%"}}>

              {images_data.map((img, index) => {
                return (<div style={{ display: "flex", flexDirection: "column", width: "25%"}}>
                  <img id={`${img.tags[0]}`}
                    src={img.template}
                    alt="creative insipiration"
                    className="image"
                    onClick={() =>
                      setLight({ photoIndex: index, isOpen: true })
                    }
                    style={{margin: "auto"}}
                  />
                  <div className="imageSlider__copy">
                    <label className="imageSlider__columnLabel" htmlFor={`${img.tags[0]}`}>{img.tags[0]} </label>
                    <FaCopy className="copyText" onClick={() => { navigator.clipboard.writeText(img.tags[0]) }} />
                  </div>
                </div>)
              })
              }

            </div>

          
            {light.isOpen && (
              <Lightbox
                mainSrc={images_data[light.photoIndex].template}
                nextSrc={images_data[(light.photoIndex + 1) % length].template}
                prevSrc={
                  images_data[(light.photoIndex + length - 1) % length].template
                }
                onCloseRequest={() => setLight({ ...light, isOpen: false })}
                onMovePrevRequest={() =>
                  setLight({
                    ...light,
                    photoIndex: (light.photoIndex + length - 1) % length,
                  })
                }
                onMoveNextRequest={() =>
                  setLight({
                    ...light,
                    photoIndex: (light.photoIndex + 1) % length,
                  })
                }
                imageTitle={`${images_data[light.photoIndex].tags[0]}`}
              />
            )}

          
          
        </div>
      </MainDashboard>
    </Wrapper>
  )
};

export default TrendingMedia;
