import React, { useState, useEffect } from "react";
import styles from "./upload_images.module.css";
import { isAuthenticated } from "../../../../../Helper/Endpoints/Endpoints";
import { Button, Tooltip } from "@mui/material";
import ImageUploadModal from "../../../../Common/ImageUploadModal";
import { Info } from "@material-ui/icons";
import { toast } from "react-toastify";
import guidelines_image from "./gudlines_image.png";

const UploadImages = (props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [Images, setImages] = useState([]);
	const [uploadedImages, setUploadedImages] = useState([]);
	const [uploadedImageFiles, setUploadedImageFiles] = useState([]);

	useEffect(() => {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/get_user_product_assets/${props.product}/`,
			{ headers }
		)
			.then((response) => response.json())
			.then((data) => {
				setImages(data.assets);
			});
	}, []);

	function renderGuidelines() {
		return (
			<>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<div className={styles.content}>
					<div className={styles.guidelinesBox}>
						<text className={styles.guidelineHeading}>Guidlines</text>
						<br />
						<br />
						1. Place the product on plain or white background <br />
						2. Take the image from front or back, avoid taking images at an
						angle. <br />
						3. Ensure product covers majority of the image.
						<br />
						4. Image should be high resolution
					</div>
					<div className={styles.previewBox}>
						<img className={styles.previewImage} src={guidelines_image}></img>
						Sample Image
					</div>
				</div>
			</>
		);
	}

	function UploadImages() {
		const { accessToken } = isAuthenticated();

		let form_data = new FormData();
		form_data.append("product_id", props.product);
		for (let imageFile of uploadedImageFiles) {
			form_data.append("product_images", imageFile);
		}

		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/add_product_assets/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);

				toast.success("Images uploaded and submited for review", {
					position: "top-center",
					theme: "dark",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});

				props.onNext();
			});
	}

	function RenderContent() {
		if (Images.length === 0 && uploadedImages.length === 0) {
			return (
				<>
					<br />
					<br />
					<br />
					<br />
					<br />
					<br />
					<div className={styles.content}>
						<div className={styles.guidelinesBox}>
							<text className={styles.guidelineHeading}>Guidlines</text>
							<br />
							<br />
							1. Place the product on plain or white background <br />
							2. Take the image from front or back, avoid taking images at an
							angle. <br />
							3. Ensure product covers majority of the image.
							<br />
							4. Image should be high resolution
						</div>
						<div className={styles.previewBox}>
							<img className={styles.previewImage} src={guidelines_image}></img>
							Sample Image
						</div>
					</div>
				</>
			);
		} else if (Images.length != 0 && uploadedImages.length === 0) {
			return (
				<>
					<br />
					<br />
					<div className={styles.imagesBox}>
						{Images.map((image) => {
							return <img src={image.url} className={styles.imageStyle}></img>;
						})}
					</div>
				</>
			);
		} else {
			return (
				<>
					<br />
					<br />
					<div className={styles.imagesBox}>
						{uploadedImages.map((image) => {
							return <img src={image} className={styles.imageStyle}></img>;
						})}
					</div>
				</>
			);
		}
	}

	return (
		<>
			<div className={styles.mainContainer}>
				<br></br>
				<div className={styles.heading}>
					Upload your images
					<button
						variant="contained"
						className={styles.button}
						onClick={() => {
							setIsModalOpen(true);
						}}
					>
						+ Images
					</button>
				</div>
				<RenderContent></RenderContent>
			</div>
			<br />
			<div
				className={styles.nextBox}
				style={{ display: uploadedImages.length != 0 ? "flex" : "none" }}
			>
				<button
					className={styles.nextButton}
					onClick={() => {
						UploadImages();
					}}
				>
					Submit for review
				</button>
				<Tooltip
					title="Once you click the submit button, MyEra will adjust the uploaded images to match the 
                    product mockup design to produce the best possible variations"
				>
					<Info className={styles.infoStyle}></Info>
				</Tooltip>
			</div>
			<ImageUploadModal
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
				}}
				onSave={(data) => {
					setUploadedImages(Object.keys(data));
					setUploadedImageFiles(Object.values(data));
					setIsModalOpen(false);
				}}
			/>
		</>
	);
};

export default UploadImages;
