import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import Avatar from "../Images/Avatar.png";
import styled from "styled-components";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import { withRouter } from "react-router";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { Person } from "@material-ui/icons";
import "./facebookBtn.css";
import CloudIcon from '@mui/icons-material/Cloud';
import { useLinkedIn } from "react-linkedin-login-oauth2";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Logo from "../Images/myEra_NewLogo.png";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import StoreIcon from "@mui/icons-material/Store";

// New imports
import coloredLinkedIn from "../Images/LinkedinLogo.png";
import coloredInstagram from "../Images/InstagramLogo.png";
import coloredFacebook from "../Images/FacebookLogo.png";
import coloredTwitter from "../Images/twitterLogo.svg";
// New imports

import "./Topbar.css";
import { Grayscale } from "konva/lib/filters/Grayscale";
//const { first_name, brand_name, brand_logo } = isAuthenticated(); used avatar instead of brand_logo for now

const NavDiv = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  //   background-color: red;
  background-color: #0b1120;
  height: 8vh;
  padding: 2%;
  border-bottom: 1px solid #313849;
`;

function Topbar(props) {
  // console.log('Inside Topbar , props = ', props);
  // const {showLinkedinLogin , setShowLinkedinLogin , showTwitterLogin , setShowTwitterLogin} = props;

  const location = props.location.pathname;
  const { brand_name } = isAuthenticated();
  useEffect(() => {
    // const ig_user = JSON.parse(localStorage.getItem("ig-user-id"));

    const userData = JSON.parse(localStorage.getItem("Erajwt"));
    const facebookLinked = userData["is_facebook_linked"];
    const linkedinLinked = userData["is_linkedin_linked"];
    const twitterLinked = userData["is_twitter_linked"];

    if (facebookLinked) {
      setShowLogin(true);
    } else {
      setShowLogin(false);
    }
    // console.log("showLogin = ", showLogin);

    if (linkedinLinked) {
      setShowLinkedinLogin(true);
    } else setShowLinkedinLogin(false);

    if (twitterLinked) setShowTwitterLogin(true);
    else setShowTwitterLogin(false);
  }, []);

  const [showLogin, setShowLogin] = useState(false);
  const [showLinkedinLogin, setShowLinkedinLogin] = useState(false);
  const [code, setCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showTwitterLogin, setShowTwitterLogin] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //   console.log(showLinkedinLogin);
  //   console.log(showTwitterLogin);

  const handleClose = () => {
    setAnchorEl(null);
  };
  // function ConnectToLinkedIn() {
  // const { linkedInLogin } = useLinkedIn({
  //     clientId: "77l5e7nu3i10q5",
  //     redirectUri: `${window.location.origin}/linkedin/callback/`,
  //     onSuccess: (code) => {
  //         console.log(code);
  //         setCode(code);
  //         // alert(`Hurray!! LinkedIn is connected. \nCode: ${code}`)

  //         const { accessToken } = isAuthenticated();
  //         const requestOptions = {
  //             headers: {
  //                 Authorization: `Bearer ${accessToken}`
  //             }
  //         }

  //         fetch(`${process.env.REACT_APP_BACKEND}/link_linkedin/${code}/`, requestOptions)
  //             .then(response => response.json())
  //             .then(data => {
  //                 console.log(data)
  //                 var userData = JSON.parse(localStorage.getItem("Erajwt"))
  //                 userData.is_linkedin_linked = true
  //                 localStorage.setItem('Erajwt', JSON.stringify(userData))
  //                 window.location.reload();
  //             })

  //     },
  //     scope: "r_emailaddress r_liteprofile w_member_social",
  //     onError: (error) => {
  //         console.log(error);
  //         setCode("");
  //         setErrorMessage(error.errorMessage);
  //     },
  // });

  //   }

  const authHandler = (err, data) => {
    // console.log(err, data);
  };

  const logout = () => {
    const { accessToken } = isAuthenticated();
    localStorage.removeItem("Erajwt");
    localStorage.clear();
    const response = fetch(`${process.env.REACT_APP_BACKEND}/logout_user`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    // console.log("Logout " + response);
    // props.history.push("/login");
    props.history.push("/login");
  };

  return (
    <NavDiv>
      <div>
        <Link to="/dashboard">
          <img className="eralogo1" src={Logo} alt="Logo" />
        </Link>
      </div>

      <div
        // style={{
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        // }}
        className="social-media-button-container"
      >
        {location === "/dashboard" && !showTwitterLogin && !showLogin ? (
          <div className="link-media-box arrow-top">Link Social Media !</div>
        ) : (
          ""
        )}
        <button
          className="social-media-button"
          style={{
            animation:
              location === "/dashboard" && !showTwitterLogin && !showLogin
                ? "pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite"
                : null,
          }}
        >
          <Link to="/SocialAccounts">
            {/* LinkedIn  */}
            {showLinkedinLogin ? (
              <img
                src={coloredLinkedIn}
                alt=""
                className="social-media-button-images bd"
              />
            ) : (
              <img
                src={coloredLinkedIn}
                alt=""
                className="social-media-button-images  black-white"
              />
            )}

            {/* Facebook  , complete this section*/}
            {showLogin ? (
              <img
                src={coloredFacebook}
                alt=""
                className="social-media-button-images"
              />
            ) : (
              <img
                src={coloredFacebook}
                alt=""
                className="social-media-button-images  black-white"
              />
            )}

            {/* Twitter  */}
            {showTwitterLogin ? (
              <img
                src={coloredTwitter}
                alt=""
                className="social-media-button-images"
              />
            ) : (
              <img
                src={coloredTwitter}
                alt=""
                className="social-media-button-images  black-white"
              />
            )}

            {/* Instagram  , showLogin controls the state of both instagram and facebook */}

            {showLogin ? (
              <img
                src={coloredInstagram}
                alt=""
                className="social-media-button-images"
              />
            ) : (
              <img
                src={coloredInstagram}
                alt=""
                className="social-media-button-images  black-white"
              />
            )}
          </Link>
        </button>

        {/* <button
                    style = {{
                        color: 'white',
                        backgroundColor: 'black', 
                        padding: '0 1rem',
                        border: 'none'

                    }}
                    
                    
                >
                    <Link to="/profile">
                    <img src={linkedIn} alt=""  style = {{ 
                        padding: '0 1rem'
                    }}/>
                    <img src={instagram} alt=""  style = {{ 
                        padding: '0 1rem'
                    }}/>
                    <img src={facebook} alt=""  style = {{ 
                        padding: '0 1rem'
                    }}/>
                    <img src={twitter} alt=""  style = {{ 
                        padding: '0 1rem'
                    }}/>

                    </Link>
                
                </button> */}

        <NotificationsActiveIcon
          style={{ color: "white", marginRight: 15 }}
          fontSize="large"
          onClick={() => {
            toast.info("No New Notifications", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }}
        />

        {/* <SettingImg
                  alt="settings "
                  style={{ marginRight: "2vh" }}
                  src={SettingIcon}
                /> */}
        {/*<img style={{ marginRight: '2vh', height: '5vh', }} src={brand_logo || Avatar} alt="" />*/}

        <div style={{ marginRight: "2vh", fontSize: "1.7vh", color: "white" }}>
          {brand_name || "My Brand"}
        </div>

        <React.Fragment>
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            <Tooltip title="Profile settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2, mr: 1 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 40, height: 40 }}></Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={
              window.location.pathname != "/profile"
                ? {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }
                : {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                      display: "flex",
                      flexDirection: "column",
                      padding: ".2rem",
                    },
                  }
            }
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem component={Link} to="/profile">
              <Person style={{ marginLeft: "-2%", marginRight: "6%" }}></Person>{" "}
              Profile
            </MenuItem>

            <MenuItem component={Link} to="/upload-library">
               <CloudIcon style={{ marginLeft: "-2%", marginRight: "6%" }}></CloudIcon> 
               My Uploads
            </MenuItem>

            {
              <MenuItem component={Link} to="/my-plans">
                <StoreIcon
                  size="small"
                  style={{
                    height: "20px",
                    marginRight: "5%",
                    marginLeft: "-2%",
                  }}
                ></StoreIcon>{" "}
                My Plans
              </MenuItem>
            }

            {
              <MenuItem component={Link} to="/SocialAccounts" className="item">
                <img
                  src="https://img.icons8.com/ios-filled/100/000000/share-2.png"
                  style={{
                    height: "20px",
                    marginRight: "7%",
                    marginLeft: "-2%",
                  }}
                />
                Social Accounts
              </MenuItem>
            }

            <Divider />

            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
      </div>
    </NavDiv>
  );
}

export default withRouter(Topbar);
