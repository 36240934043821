import React, {useState, useEffect} from 'react'
import styled from "styled-components";
import Select from 'react-select';
import { isAuthenticated } from '../../Helper/Endpoints/Endpoints';
import { LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';

function createData(platform, name, calories, fat, carbs, protein, price) {

    return {
      platform,
      name,
      calories,
      fat,
      carbs,
      protein,
      price,
      history: [
        {
          date: '2020-01-05',
          customerId: '11091700',
          amount: 3,
        },
        {
          date: '2020-01-02',
          customerId: 'Anonymous',
          amount: 1,
        },
      ],
    };
  }

const Box = styled.div`
  border-radius: 14px;
  background-color: #2C303A;
  width: 90%;
  height: 500px;
  margin-left: 5vh;
`;

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          {/* <TableCell component="th" scope="row" align='left'>{row.platform}</TableCell> */}
          <TableCell component="th" scope="row" style={{color: 'white', fontFamily: 'Montserrat', fontWeight: '900', fontSize: '13px'}}>
            {row.platform}
            {row.name}
          </TableCell>
          <TableCell style={{color: 'white', fontSize: '20px'}}>{row.calories}/100</TableCell>
          <TableCell style={{color: 'white', fontSize: '20px'}}>{row.fat}</TableCell>
          <TableCell style={{color: 'white', fontSize: '20px'}}>{row.carbs}</TableCell>
          <TableCell style={{color: 'white', fontSize: '20px'}}>{row.protein}</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Total price ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.customerId}</TableCell>
                        <TableCell align="right">{historyRow.amount}</TableCell>
                        <TableCell align="right">
                          {Math.round(historyRow.amount * row.price * 100) / 100}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  Row.propTypes = {
    row: PropTypes.shape({
      calories: PropTypes.number.isRequired,
      carbs: PropTypes.number.isRequired,
      fat: PropTypes.number.isRequired,
      history: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          customerId: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      protein: PropTypes.number.isRequired,
    }).isRequired,
  };
  

function BrandHealth() {

  const [instagramInsights, setInstagramInsights] = useState([]);
  const [linkedinInsights, setLinkedinInsights] = useState([]);
  const [twitterInsights, setTwitterInsights] = useState([]);
  // const [instaUsername, setInstaUsername] = useState();
  // const [linkedinOrgName, setLinkedinOrgName] = useState();
  // const [twitterScreenName, setTwitterScreenName] = useState();

  const rows = [
    createData(<InstagramIcon style={{color: '#eb3f96', marginRight: '5px'}}/>, 'myera.pro', instagramInsights.benchmark_score, instagramInsights.engagement, instagramInsights.followers, instagramInsights.reach, 3.99),
    createData(<LinkedInIcon style={{color: "#0a66c2", marginRight: '5px'}}/>, 'MyEra', linkedinInsights.benchmark_score, linkedinInsights.engagement, linkedinInsights.followers, linkedinInsights.reach, 4.99),
    createData(<TwitterIcon style={{color: "#55acee", marginRight: '5px'}}/>, 'myerapro', twitterInsights.benchmark_score, twitterInsights.followers, twitterInsights.engagements, twitterInsights.reach, 3.79),
    createData(<FacebookIcon style={{color: "#1877f2", marginRight: '5px'}}/>, 'MyEra', 305, 3.7, 67, 4.3, 2.5),
    createData(<PinterestIcon style={{color: "#cb1f27", marginRight: '5px'}}/>, 'myerapro', 356, 16.0, 49, 3.9, 1.5),
  ];

    useEffect(() => {

      // const userData = JSON.parse(localStorage.getItem("Erajwt"));
      //   setInstaUsername(userData.insta_username);
      //   setTwitterScreenName(userData.twitter_name);
      //   setLinkedinOrgName(userData.linkedin_org_name);

        const {accessToken} = isAuthenticated();
    
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }   
        }

        fetch(`${process.env.REACT_APP_BACKEND}/instagram_insights/`, requestOptions)
        .then(response => response.json())
          .then(data => {
            // console.log(data)
            
            setInstagramInsights(data);
          }) 

        fetch(`${process.env.REACT_APP_BACKEND}/linkedin_insights/`, requestOptions)
        .then(response => response.json())
          .then(data => {
            // console.log(data)
            
            setLinkedinInsights(data);
          })

        fetch(`${process.env.REACT_APP_BACKEND}/twitter_insights/`, requestOptions)
        .then(response => response.json())
          .then(data => {
            console.log(data)
            
            setTwitterInsights(data);
            
          })
    }, []);

  return (
    <Box style={{backgroundColor: "#2C303A", marginTop: "5vh"}} className='cardStyle'>
            <div style={{color: "white", fontSize: "26px", marginLeft: "4%", display: "flex", flexDirection: "column", marginTop: "2%"}}>

                <div style={{width: "15%", fontSize: "23px"}}>
                    Brand Health
                </div>

                <div>
                    <TableContainer component={Paper} style={{backgroundColor: "#2C303A"}}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                            <TableRow>
                                <TableCell style={{color: 'white', fontFamily: 'Chivo'}}>Channels</TableCell>
                                <TableCell style={{color: 'white', fontFamily: 'Chivo'}}>BenchMark Score</TableCell>
                                <TableCell  style={{color: 'white', fontFamily: 'Chivo'}} >Total Followers</TableCell>
                                <TableCell  style={{color: 'white', fontFamily: 'Chivo'}}>Engagements</TableCell>
                                <TableCell  style={{color: 'white', fontFamily: 'Chivo'}}>Reach</TableCell>
                                <TableCell  style={{color: 'white'}}></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <Row key={row.name} row={row} />
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
    </Box>
  )
}

export default BrandHealth