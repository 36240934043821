import React from 'react';
import loading from './spinner.gif'

export default function Spinner(){
    return(
        <div style={{ width : '100%',
        display: 'flex', justifyContent: 'center', marginTop: '2%', marginBottom: '2%' }}>
                <img style={{width: '100px', height: '100px'}} src={loading} alt="loading"></img>
        </div>
    )

}

