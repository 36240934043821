import {Subject} from 'rxjs';

const subject = new Subject();

export const openModalService = {
    sendMessage: message => subject.next(message),
    getMessage: () => subject.asObservable()
}

export const getCroppedImageService = {
    sendMessage: message => subject.next(message),
    getMessage: () => subject.asObservable()
}

export const updateImageSliderValue = {
    sendMessage: message => subject.next(message),
    getMessage: () => subject.asObservable()
}
