import React, {
	useState,
	useRef,
	useImperativeHandle,
	forwardRef,
} from "react";
import { Text, Transformer } from "react-konva";

const TextElement = ({
	id,
	text,
	x: initialX,
	y: initialY,
	width: initialWidth,
	height: initialHeight,
	rotation: initialRotation,
	onChange,
	fontSize,
	fontColor,
	fontFamily,
	alignment,
	draggable,
	listening,
	...props
}) => {
	const textRef = useRef(null);

	const [x, setX] = useState(initialX);
	const [y, setY] = useState(initialY);
	const [height, setHeight] = useState(initialHeight);
	const [width, setWidth] = useState(initialWidth);
	const [rotation, setRotation] = useState(initialRotation);

	return (
		<Text
			x={x}
			y={y}
			text={text}
			fontSize={fontSize}
			fontFamily={fontFamily}
			fill={fontColor}
			stroke={fontColor}
			strokeWidth={0.2}
			width={width}
			height={height}
			rotation={rotation}
			align={alignment ? alignment.toLowerCase() : "center"}
			ref={textRef}
			draggable={draggable}
			listening={listening}
		></Text>
	);
};

export default TextElement;
