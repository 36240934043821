import React, { useState } from "react";
import styles from "./Tabs.module.css";

const QuantityTab = ({ onSelect, selectedOption }) => {
	const [options, setOptions] = useState([
		{
			option_name: 100,
		},
		{
			option_name: 200,
		},
		{
			option_name: 300,
		},
		{
			option_name: 400,
		},
		{
			option_name: 500,
			description: "Sampling cost reimbursed",
		},
		{
			option_name: 750,
			description: "Sampling cost reimbursed",
		},
		{
			option_name: 1000,
			description: "Sampling cost reimbursed",
		},
		{
			option_name: 1500,
			description: "Sampling cost reimbursed",
		},
		{
			option_name: 2000,
			description: "Sampling cost reimbursed",
		},
		{
			option_name: 2500,
			description: "Sampling cost reimbursed",
		},
		{
			option_name: 5000,
			description: "Sampling cost reimbursed",
		},
	]);

	return (
		<div className={styles.tab_container}>
			<div className={styles.title}>Select a Quantity</div>
			<div className={styles.subtitle}>
				Tip: Try exploring higher quanitites. Unit prices for custom packaging
				are often much lower at higher quantities
			</div>
			<div className={[styles.chips_grid, styles.quad].join(" ")}>
				{options.map((option) => {
					return (
						<div
							className={[
								styles.chip,
								option.description === undefined && styles.vertical_center,
								selectedOption === option.option_name && styles.selected,
							].join(" ")}
							style={{
								margin: "0",
							}}
							onClick={() => onSelect(option.option_name)}
						>
							<div className={styles.chip__title}>
								{option.option_name} Units
							</div>
							{option.description && (
								<div className={styles.chip__description}>
									{option.description}
								</div>
							)}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default QuantityTab;
