import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { PropaneSharp } from "@mui/icons-material";
import { propTypes } from "react-bootstrap/esm/Image";
import "./create_app.css";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const theme = createTheme({
  palette: {
    mode: "dark",
    width: "500px",

    // primary: {
    //     main: "#624DE8",
    // }
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const products = [
  "MyEra Publish",
  "MyEra Create",
  "MyEra Create for products"
]

export default function CreateApp(props) {

  const [appname, setAppName] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [postImage, setPostImage] = useState(null);
  const [postImageURL, setPostImageURL] = useState(null);
  const [postCaption, setPostCaption] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [test, setTest] = useState();

  useEffect(() => {
    document.title = "Create App Page";
  }, []);

  const handlesubmit = () => {

    if(!appname)
    {
      toast.error("App name is required", {
        position: "top-center",
        theme: "dark",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const { accessToken } = isAuthenticated();

    var forrm_data = new FormData()
    forrm_data.append('app_name', appname)
    if(logo)
      forrm_data.append('logo', logo)
    if(postImage)
      forrm_data.append('post_image', postImage)
    if(postCaption)
      forrm_data.append('post_caption', postCaption)

    forrm_data.append('products', selectedProducts)

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND}/create_app/`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'content-type': 'multipart/form-data' 
      },
      data: forrm_data,
    }).then((res) => {
      console.log(res);
      toast.success("App created successfully", {
        position: "top-center",
        theme: "dark",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
      props.history.push("/developer/my-apps");
    })
    .catch((error) => {
      console.log(error)
      toast.error("App name already taken, please choose another name", {
        position: "top-center",
        theme: "dark",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    });
  };

  function onLogoChange(e){
    setLogo(e.target.files[0])
    var url = URL.createObjectURL(e.target.files[0])
    console.log(url)
    setLogoURL(url);
  }

  function onPostImageChange(e){
    setPostImage(e.target.files[0])
    var url = URL.createObjectURL(e.target.files[0])
    console.log(url)
    setPostImageURL(url);
  }

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setProduct(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  function toggleSelected(product) {
      if(selectedProducts.includes(product)){
        setSelectedProducts(selectedProducts.filter(
          function(prod){
            console.log(prod, product)
            return prod != product
          }
        ))
      }
      else {
        let selected_products_array = selectedProducts
        selected_products_array.push(product)
        setSelectedProducts(selected_products_array)
      }

      setTest(new Date().getMilliseconds())
      console.log(selectedProducts)
    }

  return (
    <ThemeProvider theme={theme}>
      <div className="outer-container1">
        <h2 className="create-heading">Create App</h2>
        <div className="inner-container1">
            <h4>General</h4>
            <div>
              App Name*:
              <br/>
              <input type="text" onChange={(e) => {
                  setAppName(e.target.value);
                  console.log("app name:", appname);
              }}></input>
            </div>
            <br/>
            <div>
              Upload Logo:
              <br/>
              <input type="file" onChange={onLogoChange}></input>
            </div>
            <br/><br/>

            <h4>Add products</h4>
            <div style={{display: "flex"}}>
              <Button size="small" variant={selectedProducts.includes("publish") ? "contained" : "outlined"} onClick={() => toggleSelected("publish")} style={{marginRight: '4%'}}>MyEra Publish</Button>
              <Button size="small" variant={selectedProducts.includes("create") ? "contained" : "outlined"} onClick={() => toggleSelected("create")} style={{marginRight: '4%'}}>MyEra Create</Button>
              <Button size="small" variant={selectedProducts.includes("create_product") ? "contained" : "outlined"} onClick={() => toggleSelected("create_product")} style={{marginRight: '4%'}}>MyEra Create for products</Button>
            </div>
            <br/>
            <hr></hr>
            {selectedProducts.includes("publish") ? (
              <div>
              <div>
              <h4>MyEra Publish</h4>
              <div className="note-text">
                The default image and default post caption choosen will be visible for your user in the MyEra post popup where the
                caption can be edited by the user. 
              </div>
              </div>
              <br/>
              <div>
                Default post image:
                <br/>
                <input type="file" onChange={onPostImageChange}></input>
              </div>
              <br/>
              <div>
                Default post caption:
                <br/>
                <textarea style={{width:"35%", height:"80px"}} onChange={(e) => {
                    setPostCaption(e.target.value);
                    //   console.log("app name:", appname);
                  }}></textarea>
              </div>
              <br/>
              <hr></hr>
              </div>
            ) : (<></>)}
            
            
            { selectedProducts.includes("create") ? (
              <div>
                <h4>MyEra Create</h4>
                <div className="note-text">
                  We take your app tokens and generate a html code for you to just copy paste in your website, and its done. Your users can
                  create flawless designs in a matter of few clicks.
                </div>
                <br/>
                <div>
                  No configuration required.
                </div>
                <br/>
                <hr></hr>
              </div>
            ) : (<></>)}
            
            { selectedProducts.includes("create_product") ? (
              <div>
                <h4>MyEra Create for product mockups</h4>
                <div className="note-text">
                Create a product in MyEra and add the product to the app so that your user can personalize products in a click.
                </div>
                <br/>
                <div>
                  Add products:
                  <br/>
                  <input type="text" disabled></input>
                  &nbsp;&nbsp;Coming soon...
                </div>
                <br/>
                <hr></hr>
              </div>
            ) : (<></>)}

            
            {/* <div className="form-row-1">
              <div>
              <TextField
                id="filled-basic"
                label="App Name"
                variant="filled"
                onChange={(e) => {
                  setAppName(e.target.value);
                  //   console.log("app name:", appname);
                }}
              />
              </div>
              <div className="upload-logo-container">
                <label for="myfile">Upload Logo</label>
                &nbsp;&nbsp;
                <input type="file" id="myfile" name="myfile" onChange={onLogoChange}/>
              </div>
              <div style={{width: '100px'}}>
                <img src={logoURL} height="75px"></img>
              </div>
            </div>
            <div className="form-row-2">
              <div className="upload-container-2">
                <label for="myfile">Upload default Post Image</label>
                &nbsp;&nbsp;
                <input type="file" id="myfile" name="myfile" onChange={onPostImageChange}/>
              </div>
              <div style={{width: '100px'}}>
                <img src={postImageURL} height="75px"></img>
              </div>
              <TextField
                  id="filled-basic"
                  label="Default Caption"
                  variant="filled"
                  onChange={(e) => {
                    setPostCaption(e.target.value);
                    //   console.log("app name:", appname);
                  }}
                />
            </div> */}
            <br></br><br></br>  
            <div style={{textAlign: 'center'}}>
              <Button variant="contained" onClick={handlesubmit}>
                Submit
              </Button>
            </div>
        </div>
      </div>
    </ThemeProvider>
    
  )

}
