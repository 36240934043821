import { Close, ZoomInRounded } from "@material-ui/icons";
import { Button, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { detectIncognito } from "detectincognitojs";
import React, { useEffect, useRef, useState } from "react";
import { Layer, Stage } from "react-konva";
import Modal from "react-modal";
import { toast } from "react-toastify";
import TextElement from "../../Campaign_editor/KonvaTextLayer";
import URLImage from "../../Campaign_editor/URLImage2";
import { updateImageSliderValue } from "../../Generic/messageService";
import HorizontalCarousel from "../../HorizontalCarousel/HorizontalCarousel";
import ElementSelection from "./ElementSelection";
import ElementSlider from "./Slider";
import loader from "./loader-unscreen.gif";
import styles from "./product_create.module.css";
import ReactGA from 'react-ga4';
import {Helmet} from "react-helmet";

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: "100%",
		width: "100%",
		color: "black",
		padding: "0%",
		justifyContent: "center",
		alignItems: "center",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

const incognitoModalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "white",
		borderRadius: "3vh",
		textAlign: "center",
		height: "auto",
		width: "40%",
		color: "black",
		padding: "0%",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

const ProductCreate = (props) => {
	const [productId, setProductId] = useState();
	const [shopifyProductId, setShopifyProductId] = useState(0);
	const [elements, setElements] = useState([]);
	const [elementsCopy, setElementsCopy] = useState([]);

	const stageRef = useRef(null);

	const [folders, setFolders] = useState();
	const [selectedTab, setSelectedTab] = useState();
	const [selectedElement, setSelectedElement] = useState();
	const [editableElements, setEditableElements] = useState();
	const [_, setTest] = useState();
	const [textTabs, setTextTabs] = useState();
	const [primaryColor, setPrimaryColor] = useState("#6610F2");
	const [isIdinvalid, setisIdInvalid] = useState(false);
	const [accessToken, setAccessToken] = useState();
	const [fontSize, setFontSize] = useState(10);
	const [selectedFontFamilyText, setSelectedFontFamilyText] =
		useState("Montserrat");
	const [elementRefs, setElementRefs] = useState({});
	const [elementSelectionRef, setElementSelectionRef] = useState();
	const [productViewImages, setProductViewImages] = useState([]);
	const [sliderDisabled, setSliderDisabled] = useState(true);
	const [carouselRef, setCarouselRef] = useState(true);
	const [charLimit, setCharLimit] = useState(30);
	const [waitForAddToCart, setWaitForAddToCart] = useState(false);
	const [waitForBuyNow, setWaitForBuyNow] = useState(false);
	const [uploadBgRemoval, setUploadBgRemoval] = useState("");
	const [cameraBgRemoval, setCameraBgRemoval] = useState("");
	const [allowCameraUploads, setAllowCameraUploads] = useState(false);
	const [showGuidelines, setShowGuidelines] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [invertCanvasView, setInvertCanvasView] = useState(false);
	const [faqs, setFaqs] = useState([]);
	const [disableAddToCart, setDisableAddToCart] = useState({});
	const [mandatoryFontOptions, setMandatoryFontOptions] = useState({});
	const [productTitle, setProductTitle] = useState();
	const [showBuyNowButton, setShowBuyNowButton] = useState();
	const [fontSizes, setFontSizes] = useState();
	const [textLabel, setTextLabel] = useState();
	const [restrictCharLimit, setRestrictCharLimit] = useState();
	const [showVerticalCarousel, setShowVerticalCarousel] = useState(true);
	const [contentsFont, setContentsFont] = useState("Roboto");
	const [isFontEditable, setIsFontEditable] = useState(false);
	const [productDescription, setProductDescription] = useState();
	const [instructions, setInstructions] = useState({});
	const [colorTitle, setColorTitle] = useState("");
	const [pageTitle, setPageTitle] = useState("MyEra Product Customizer");
	const [pageDescription, setPageDescription] = useState("MyEra Product Customizer");

	const blackberrysProductIDs = [
		"8156942565541",
		"8156942598309",
		"8156942532773",
		"8156942631077",
	];

	const blackberrysButtonSx = {
		backgroundColor: "white",
		color: "black",
		border: "1px solid grey",
		transition: "all 0.3s ease-in-out",
		boxShadow: "none",
		width: "90%",
		"&:hover": {
			backgroundColor: "black",
			color: "white",
		},
	};

	// const accessToken =
	// 	"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjgwOTY1Mzc4LCJqdGkiOiJhN2RkN2ZmN2Q3MmE0Mjc0OWQyMGIzODMyMTU3M2RmNiIsInVzZXJfaWQiOjIxNzN9.wcqxPM-6H9N6nYvlkthoq-X83sh-vF133NBwsr-EoHI";
	const [showIncognitoPopup, setShowIncognitoPopup] = useState(false);

	const theme = createTheme({
		palette: {
			mode: "light",
			primary: {
				main: primaryColor,
			},
		},

		typography: {
			fontFamily: contentsFont ? contentsFont : "roboto",
			fontSize: fontSizes ? fontSize.content : null,
			// color: "inherit",
			// fontWeightLight: "lighter"
		},
	});

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const product_id_parameter = urlParams.get("product_id");
	const myera_product_id = urlParams.get("myera_product_id");
	const client_product_id = urlParams.get("client_product_id");
	const app_id = urlParams.get("app_id");
	const secret_access_key = urlParams.get("secret_access_key");

	useEffect(() => {

		// console.log("Cookies Enabled:", navigator.cookieEnabled);

		window.addEventListener("message", (event) => {
			if (event.data.added) {
				if (event.data.added) {
					toast.success("Added to cart", {
						position: "top-center",
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						onClose: () => {
							window.parent.postMessage(
								{
									func: "closeMyEraModal",
								},
								"*"
							);
						},
					});
				} else {
					toast.error("Failed to add to cart", {
						position: "top-center",
						autoClose: 2000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: false,
						draggable: false,
						onClose: () => {
							window.parent.postMessage(
								{
									func: "closeMyEraModal",
								},
								"*"
							);
						},
					});
				}
			}
		});

		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		let elements_array = [];
		let editable_elements_array = [];

		if (!product_id_parameter && !myera_product_id && !client_product_id) {
			setisIdInvalid(true);
		}

		let requestOptions = {
			method: "POST",
			body: JSON.stringify({
				app_id: app_id,
				secret_access_key: secret_access_key,
			}),
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/generate_api_access_token/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((accessdata) => {
				// console.log(accessdata.access_token);
				setAccessToken(accessdata.access_token);

				let requestHeaders = {
					headers: {
						Authorization: `Bearer ${accessdata.access_token}`,
					},
				};

				fetch(`${process.env.REACT_APP_BACKEND}/get_page_metadata/create-product/`, requestHeaders)
				.then((response) => response.json())
				.then((data) => {
					if(data && data.data) {
						setPageTitle(data.data.title)
						setPageDescription(data.data.description)
					}
				})

				ReactGA.send({hitType: "pageview", page: "/create-product", title: pageTitle})

				const productURL = myera_product_id
					? `${process.env.REACT_APP_BACKEND}/get_product/${myera_product_id}`
					: client_product_id
					? `${process.env.REACT_APP_BACKEND}/get_product_from_client_id/${client_product_id}`
					: `${process.env.REACT_APP_BACKEND}/get_product_from_shopify_id/${product_id_parameter}`;
				fetch(productURL, requestHeaders)
					.then((response) => response.json())
					.then((product_data) => {
						// console.log(product_data);
						setProductId(product_data.id);
						setShopifyProductId(product_data.shopify_product_id);
						if (product_data.show_product_title)
							setProductTitle(product_data.name);
						setProductDescription(product_data.desription);
						setUploadBgRemoval(product_data.bg_removal_for_uploads);
						setCameraBgRemoval(product_data.bg_removal_for_camera_image);
						setAllowCameraUploads(product_data.allow_camera_uploads);
						setShowGuidelines(product_data.show_guidelines_frame);

						modalStyles.content.backgroundColor = product_data.background_color;
						setPrimaryColor(product_data.primary_color);
						modalStyles.content.color = product_data.secondary_color;
						setInvertCanvasView(product_data.invert_canvas_input_view);
						setShowVerticalCarousel(product_data.show_vertical_carousel);
						setInstructions({
							type: product_data.instructions_type,
							title: product_data.instructions_title,
							text: product_data.instructions_text,
							faqs: product_data.faqs
						})
						setFaqs(product_data.faqs);
						setShowBuyNowButton(product_data.show_buy_now_button);
						setMandatoryFontOptions({
							text: product_data.is_text_mandatory,
							font: product_data.is_font_mandatory,
							color: product_data.is_font_color_mandatory,
						});

						setDisableAddToCart({
							text: product_data.is_text_mandatory,
							font: product_data.is_font_mandatory,
							color: product_data.is_font_color_mandatory,
						});

						if (product_data.content_font.font_name)
							setContentsFont(product_data.content_font.font_name);

						setFontSizes({
							tab: product_data.tab_font_size,
							content: product_data.content_font_size,
							faqs: product_data.faq_font_size,
						});
						setIsFontEditable(product_data.is_font_editable);

						let requestOptions = {
							headers: {
								Authorization: `Bearer ${accessdata.access_token}`,
							},
							method: "POST",
							body: JSON.stringify({
								tags: ["#watch"],
								design_id: product_data.template.design_id,
								objective_id: product_data.template.objective_id,
							}),
						};

						fetch(
							`${process.env.REACT_APP_BACKEND}/get_product_view_images/${product_data.id}/`,
							{
								headers: {
									Authorization: `Bearer ${accessdata.access_token}`,
								},
							}
						)
							.then((res) => res.json())
							.then((data) => {
								setProductViewImages(data.product_views);
							})
							.catch((err) => {
								console.log(err);
							});

						fetch(
							`${process.env.REACT_APP_BACKEND}/generate_results_new/`,
							requestOptions
						)
							.then((response) => response.json())
							.then((data) => {
								// console.log(data);

								let text_tabs = [];
								for (let element of data.elements) {
									if (element.elem_type === "image")
										elements_array.push({
											id: element.id,
											x: element.elem_dimensions.startX,
											y: element.elem_dimensions.startY,
											width: element.elem_dimensions.width,
											height: element.elem_dimensions.height,
											rotation: element.rotation,
											image: element.url ? element.url : data.assets[0],
											// choosen_image: null,
											folder: element.folder,
											layer: element.layer,
											name: element.elem_name,
											is_editable: element.is_editable,
										});
									else {
										elements_array.push({
											id: element.id,
											x: element.elem_dimensions.startX,
											y: element.elem_dimensions.startY,
											width: element.elem_dimensions.width,
											height: element.elem_dimensions.height,
											rotation: element.rotation,
											text: element.default_text
												? element.default_text
												: "Signature",
											font_size: element.font_size,
											font_color: element.font_color,
											font_style: element.font_style,
											char_limit: element.character_limit,
											restrict_to_limit: element.restrict_to_max_chars,
											layer: element.layer,
											name: element.elem_name,
											is_editable: element.is_editable,
											text_alignment: element.text_alignment,
											text_label: element.text_label,
											text_color_heading: element.text_color_heading
										});
										text_tabs.push({
											id: element.id,
											name: element.elem_name,
											font_style: element.font_style,
											font_size: element.font_size,
											font_color: element.font_color,
											char_limit: element.character_limit,
											text_event: element.text_event,
											allow_numbers: element.allow_numbers,
											allow_special_chars: element.allow_special_chars,
											allow_spaces: element.allow_space,
										});
										setSelectedFontFamilyText(element.font_style.font_name);
										setCharLimit(element.character_limit);
										setRestrictCharLimit(element.restrict_to_max_chars);
										setFontSize(element.font_size);
										setTextLabel(element.text_label);
										setColorTitle(element.text_color_heading)
									}

									if (
										element.is_editable === true &&
										element.elem_type === "image"
									) {
										editable_elements_array.push({
											id: element.id,
											x: element.elem_dimensions.startX,
											y: element.elem_dimensions.startY,
											width: element.elem_dimensions.width,
											height: element.elem_dimensions.height,
											image: element.url ? element.url : data.assets[0],
											folder: element.folder,
											layer: element.layer,
											name: element.elem_name,
											is_editable: element.is_editable,
										});
									}

									SetElementRefsCallback(element.id);
								}

								elements_array.sort((ele1, ele2) =>
									ele1.layer > ele2.layer ? 1 : ele1.layer < ele2.layer ? -1 : 0
								);

								setElements(elements_array);
								setEditableElements(editable_elements_array);
								setElementsCopy(JSON.parse(JSON.stringify(elements_array)));
								setTextTabs(text_tabs);
							});

						let assetRequestOptions = {
							headers: {
								Authorization: `Bearer ${accessdata.access_token}`,
							},
						};

						fetch(
							`${process.env.REACT_APP_BACKEND}/get_product_asset_folders/${product_data.id}/`,
							assetRequestOptions
						)
							.then((response) => response.json())
							.then((data) => {
								for (let folder of Object.keys(data)) {
									data[folder].imageType = "default";
								}
								setFolders(data);
								setSelectedTab(Object.keys(data)[0]);
							});
					})
					.catch((error) => {
						setisIdInvalid(true);
					});
			});
	}, []);

	useEffect(() => {
		document.title = pageTitle
	}, [pageTitle])

	function SetElementRefsCallback(ele_id) {}

	function selectElement(ele) {
		console.log(ele === selectedElement);
		if (ele === selectedElement) {
			setSelectedElement(false);
			return;
		}
		setSelectedElement(ele);
	}

	function getElementIdFromFolder(folder, elementType = "image") {
		if (elementType === "text") return parseInt(folder.id);

		for (let ele of elements) {
			if (ele.folder === parseInt(folder.split(" ")[0])) return ele.id;
		}
	}

	function getElementPositionFromId(id) {
		for (let ele of elements) {
			if (ele.id === id)
				return { x: ele.x, y: ele.y, height: ele.height, width: ele.width };
		}
	}

	function changeElementSize(event) {
		let value = event.target.value;
		elementRefs[editableElements[0].id].current.updateImageDimensions(
			value,
			value
		);
	}

	function dataURItoBlob(dataURI) {
		var byteString = atob(dataURI.split(",")[1]);
		var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ab], { type: mimeString });
	}

	async function getS3URL(entity) {
		let image;

		if (entity === "canvas") {
			const dataURI = await stageRef.current.toDataURL({ pixelRatio: 2.16 });
			let blob = dataURItoBlob(dataURI);
			image = new File([blob], "image.png");
		}
		// } else if (selectedUploadFile) {
		// 	image = selectedUploadFile;
		// }
		else {
			let imageSrc = elementRefs[editableElements[0].id].current.getImageSrc();
			if (imageSrc.includes("quarkme-assets")) return imageSrc;

			image = elementSelectionRef.current.getSelectedUploadImage();
		}

		if (!image) return null;

		let form_data = new FormData();
		form_data.append("file", image);
		let requestOptions = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			method: "POST",
			body: form_data,
		};

		const response = await fetch(
			`${process.env.REACT_APP_BACKEND}/get_s3_url/`,
			requestOptions
		);
		const data = await response.json();

		return data.url;
	}

	const checkDeselect = (e) => {
		const clickedOnEmpty = e.target === e.target.getStage();
		if (clickedOnEmpty) {
			setSelectedElement(false);
		}
	};

	function renderInvalid() {
		console.log("Error in fetching shopify product");
		return <>Invalid</>;
	}

	function resetDimensions() {
		let folder = elementSelectionRef.current.getSelectedFolder();
		let id = getElementIdFromFolder(folder);
		elementRefs[id].current.resetImagePostition();
		updateImageSliderValue.sendMessage({ value: 500 });
		setSliderDisabled(true);
	}

	function setProductViewAsset(id, tab) {
		const product_views = productViewImages.map((product_view, idx) => {
			if (
				product_view.asset_folder.id &&
				product_view.asset_folder.id === tab.split(" ")[0]
			) {
				product_view.current_asset = product_view.product_view_images[id].url;
			}
			return product_view;
		});

		setProductViewImages(product_views);
	}

	function updateFontOptions(is_font_color_editale, is_font_editable) {
		if (
			is_font_color_editale &&
			!is_font_editable &&
			mandatoryFontOptions.color
		)
			setDisableAddToCart({
				...disableAddToCart,
				color: is_font_color_editale,
			});
		else if (
			!is_font_color_editale &&
			is_font_editable &&
			mandatoryFontOptions.font
		)
			setDisableAddToCart({
				...disableAddToCart,
				font: is_font_editable,
			});
		else if (
			is_font_color_editale &&
			is_font_editable &&
			mandatoryFontOptions.color &&
			mandatoryFontOptions.font
		) {
			setDisableAddToCart({
				...disableAddToCart,
				color: is_font_color_editale,
				font: is_font_editable,
			});
		} else {
		}
	}

	function renderElementSelection() {
		return (
			<div className={styles.inputBox}>
				{folders && textTabs && primaryColor ? (
					<ElementSelection
						productId={productId}
						accessToken={accessToken}
						foldersProp={folders}
						textTabsProp={textTabs}
						primaryColor={primaryColor}
						productDescription={productDescription}
						textCharLimit={charLimit}
						restrictCharLimit={restrictCharLimit}
						fontSize={fontSize}
						contentsFont={contentsFont}
						contentFontSizes={fontSizes}
						textLabel={textLabel}
						allowCameraUploads={allowCameraUploads}
						showGuidelines={showGuidelines}
						upladBgRemoval={uploadBgRemoval}
						cameraBgRemoval={cameraBgRemoval}
						faqs={faqs}
						instructions={instructions}
						colorTitle={colorTitle}
						updateFontOptions={updateFontOptions}
						updateSelectedTabType={() => {
							carouselRef.current.resetIndex();
						}}
						onTextChange={(text, tab, charLimit, restrict_to_limit) => {
							// console.log(tab)
							let id = getElementIdFromFolder(tab, "text");
							// console.log(elementRefs)
							// console.log(id)
							elementRefs[id].current.reRender({ text: text });
							if (
								text &&
								(!restrict_to_limit ||
									(restrict_to_limit && charLimit === text.length))
							)
								setDisableAddToCart({
									...disableAddToCart,
									text: false,
								});
							else
								setDisableAddToCart({
									...disableAddToCart,
									text: true,
								});

							carouselRef.current.resetIndex();
						}}
						onTabChanged={(folder) => {
							if (folder.is_uploadable && folder.imageType === "uploaded") {
								setSliderDisabled(false);
							} else {
								setSliderDisabled(true);
							}
						}}
						onImageSelect={(asset_id, url, tab, imageType) => {
							let id = getElementIdFromFolder(tab);
							let elePos = getElementPositionFromId(id);
							elementRefs[id].current.updateImageURL(url);
							if (imageType === "default") {
								elementRefs[id].current.resetImagePostition(elePos);
								updateImageSliderValue.sendMessage({ value: elePos.height });
							} else {
								elementRefs[id].current.resetImagePostition(elePos);
								updateImageSliderValue.sendMessage({ value: elePos.height });
							}

							// console.log(imageType);
							elementRefs[id].current.setListening(
								imageType === "default" ? false : true
							);
							if (imageType === "default") {
								setSliderDisabled(true);
							} else {
								setSliderDisabled(false);
							}
							carouselRef.current.resetIndex();

							setProductViewAsset(asset_id, tab);
						}}
						onFontSizeChange={(fontSize, tab) => {
							let id = getElementIdFromFolder(tab, "text");
							// console.log(elementRefs)
							// console.log(id)
							elementRefs[id].current.reRender({ fontSize: fontSize });
							carouselRef.current.resetIndex();
						}}
						onFontFamilyChange={(fontFamily, tab) => {
							let id = getElementIdFromFolder(tab, "text");
							// console.log(elementRefs)
							// console.log(id)
							setSelectedFontFamilyText(fontFamily);
							elementRefs[id].current.reRender({
								fontFamily: fontFamily,
							});
							setDisableAddToCart({
								...disableAddToCart,
								font: false,
							});
							carouselRef.current.resetIndex();
						}}
						onColorChange={(color, tab) => {
							let id = getElementIdFromFolder(tab, "text");
							// console.log("Colour:", color);
							elementRefs[id].current.reRender({
								fontColor: color,
							});
							setDisableAddToCart({
								...disableAddToCart,
								color: false,
							});
							carouselRef.current.resetIndex();
						}}
						defaultTab={Object.keys(folders)[0]}
						updateElementSelectionRef={(ref) => {
							setElementSelectionRef(ref);
						}}
					></ElementSelection>
				) : (
					<></>
				)}
			</div>
		);
	}

	function renderCanvas() {
		return (
			<div className={styles.canvas_container}>
				<HorizontalCarousel
					updateCarouselRef={(carouselRef) => {
						setCarouselRef(carouselRef);
					}}
					invertCanvasView={invertCanvasView}
					showVerticalCarousel={showVerticalCarousel}
				>
					<div>
						<div className={styles.canvasBox}>
							<Stage
								width={500}
								height={500}
								ref={stageRef}
								className={styles.stageStyle}
								onMouseDown={checkDeselect}
								onTouchStart={checkDeselect}
								onMouseMove={(e) => {
									const stage = stageRef.current;
									const mousePos = stage.getPointerPosition();
									const scale = 1.5; // your zoom scale
									stage.scale({ x: scale, y: scale });
									stage.position({
										x: -mousePos.x * (scale - 1),
										y: -mousePos.y * (scale - 1),
									});
									stage.batchDraw();
								}}
								onMouseLeave={() => {
									const stage = stageRef.current;
									stage.to({
										x: 0,
										y: 0,
										scaleX: 1,
										scaleY: 1,
										duration: 0.3, // duration in seconds
									});
								}}
								onTouchMove={(e) => {
									const stage = stageRef.current;
									const touchPos = stage.getPointerPosition();
									const scale = 1.5;
									stage.scale({ x: scale, y: scale });
									stage.position({
										x: -touchPos.x * (scale - 1),
										y: -touchPos.y * (scale - 1),
									});
									stage.batchDraw();
								}}
								onTouchEnd={() => {
									const stage = stageRef.current;
									stage.to({
										x: 0,
										y: 0,
										scaleX: 1,
										scaleY: 1,
										duration: 0.3,
									});
								}}
							>
								{elements ? (
									elements.map((ele, i) => {
										if ("image" in ele) {
											// console.log(
											// 	selectedElement ? selectedElement.id : null
											// );
											return (
												<Layer>
													<URLImage
														key={i}
														image={ele}
														onSelect={() => selectElement(ele)}
														onChange={(new_ele) => {
															console.log(new_ele);
															const new_elements = elements.map((element) => {
																if (element.id === ele.id) {
																	element.x = new_ele.x;
																	element.y = new_ele.y;
																	element.height = new_ele.height;
																	element.width = new_ele.width;
																	return element;
																}
																return element;
															});
															setElements(new_elements);
														}}
														isSelected={
															ele.id ===
															(selectedElement ? selectedElement.id : null)
														}
														onTransformEnd={() => {}}
														listening={false}
														updateElementRefs={(ref) => {
															var elementRefsCopy = elementRefs;
															elementRefsCopy[ele.id] = ref;
															setElementRefs(elementRefsCopy);
														}}
													></URLImage>
												</Layer>
											);
										} else
											return (
												<Layer>
													<TextElement
														ele={{
															x: ele.x,
															y: ele.y,
															width: ele.width,
															height: ele.height,
															text: ele.text,
															rotation: ele.rotation,
															fontSize: ele.font_size,
															fontColor: ele.font_color,
															fontFamily: selectedFontFamilyText,
															color: "black",
															alignment: ele.text_alignment,
														}}
														updateElementRefs={(ref) => {
															var elementRefsCopy = elementRefs;
															elementRefsCopy[ele.id] = ref;
															setElementRefs(elementRefsCopy);
															// console.log(elementRefs)
														}}
														ref={elementRefs[ele.id]}
													></TextElement>
												</Layer>
											);
									})
								) : (
									<></>
								)}
							</Stage>

							<div
								style={{
									paddingTop: "12px",
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
									transition: "0.3s",
									maxWidth: sliderDisabled ? "0px" : "1000px",
									opacity: 1,
									overflow: "hidden",
								}}
							>
								<ZoomInRounded />
								<ElementSlider
									onChange={(e) => changeElementSize(e)}
									disabled={sliderDisabled}
									primaryColor={primaryColor}
								/>
								<Button
									onClick={() => {
										// let elements_copy = elementsCopy
										console.log(elementsCopy);
										setElements(elementsCopy);
										resetDimensions();
										setTest(new Date().getMilliseconds());
									}}
									style={{ marginLeft: "3%" }}
								>
									Reset
								</Button>
							</div>
						</div>
					</div>
					{productViewImages.map((product_view, idx) => (
						<div>
							<img
								className={styles.carousel_image}
								src={product_view.current_asset}
								alt=""
							/>
							<div>
								<Typography fontSize={16}>{product_view.name}</Typography>
							</div>
						</div>
					))}
				</HorizontalCarousel>
			</div>
		);
	}

	function renderSubmitBox() {
		// console.log(
		// 	"***** RENDER SUBMIT BOX *****",
		// 	shopifyProductId,
		// 	blackberrysProductIDs,
		// 	blackberrysProductIDs.includes(shopifyProductId.toString())
		// );
		return (
			<div
				className={styles.submitBox}
				// style={{
				// 	justifyContent:
				// 		showBuyNowButton && window.matchMedia("(min-width:768px)").matches
				// 			? "space-between"
				// 			: "left",
				// }}
			>
				{waitForAddToCart ? (
					<Button
						variant="contained"
						sx={
							blackberrysProductIDs.includes(shopifyProductId.toString())
								? blackberrysButtonSx
								: {
										backgroundColor: primaryColor,
										color: "#fff",
										width: showBuyNowButton ? "45%" : "90%",
										fontFamily: "inherit",
										letterSpacing: "1px",
										borderRadius: "8px",

										"&:hover": {
											backgroundColor: "#020441",
											color: "#fff",
										},
								  }
						}
					>
						Preparing <img src={loader} alt="" height="25px" />
					</Button>
				) : (
					<Button
						variant="contained"
						disabled={Object.values(disableAddToCart).includes(true)}
						sx={
							blackberrysProductIDs.includes(shopifyProductId.toString())
								? blackberrysButtonSx
								: {
										backgroundColor: primaryColor,
										color: "#fff",
										width: showBuyNowButton ? "45%" : "90%",

										fontFamily: "inherit",
										letterSpacing: "inherit",
										borderRadius: "8px",

										"&:hover": {
											backgroundColor: "#b48d62ff",
											color: "#fff",
										},
								  }
						}
						onClick={async () => {

							ReactGA.event({
								category: "Add to Cart",
								action: "Click"
							})

							ReactGA.event('add_to_cart', {
								currency: "INR",
								value: 2500,
								item_id: shopifyProductId
							})

							setWaitForAddToCart(true);

							setTimeout(() => {
								setWaitForAddToCart(false);
							}, 3000);

							let previewImageURL = await getS3URL("canvas");
							let backgroundURL;
							// let previewImageURL = "SomeRandomURL";
							if (editableElements.length > 0) {
								backgroundURL = await getS3URL("");
							}

							const data = {};
							data["previewurl"] = previewImageURL;
							if (backgroundURL) data["backgroundurl"] = backgroundURL;

							// Text elements data
							const textElement = elements.find((ele) => !("image" in ele));
							console.log(textElement);
							if (textElement) {
								const textElementData =
									elementSelectionRef.current.getTextDetails();
								data["text"] = textElementData.text;
								data["font_size"] = textElementData.fontSize;
								data["color"] = textElementData.color;
								if (isFontEditable) {
									data["font_family"] = selectedFontFamilyText;
								}
							}

							console.log(data);

							window.parent.postMessage(
								{
									func: "addToCart",
									message: data,
								},
								"*"
							);
						}}
					>
						<Typography variant="button" fontSize={16}>
							Add to cart
						</Typography>
					</Button>
				)}
				{showBuyNowButton &&
					(waitForBuyNow ? (
						<Button
							variant="contained"
							sx={
								blackberrysProductIDs.includes(shopifyProductId.toString())
									? blackberrysButtonSx
									: {
											backgroundColor: primaryColor,
											color: "#fff",
											width: "45%",
											marginTop: "10px",
											fontFamily: "inherit",
											letterSpacing: "1px",
											borderRadius: "8px",

											"&:hover": {
												backgroundColor: "#b48d62ff",
												color: "#fff",
											},
									  }
							}
						>
							Prepearing <img src={loader} alt="" height="25px" />
						</Button>
					) : (
						<Button
							variant="contained"
							disabled={Object.values(disableAddToCart).includes(true)}
							sx={
								blackberrysProductIDs.includes(shopifyProductId.toString())
									? blackberrysButtonSx
									: {
											backgroundColor: primaryColor,
											color: "#fff",
											width: "45%",
											marginTop: "10px",
											fontFamily: "inherit",
											letterSpacing: "inherit",
											borderRadius: "8px",

											"&:hover": {
												backgroundColor: "#b48d62ff",
												color: "#fff",
											},
									  }
							}
							onClick={async () => {
								setWaitForBuyNow(true);

								setTimeout(() => {
									setWaitForBuyNow(false);
								}, 3000);

								let previewImageURL = await getS3URL("canvas");
								let backgroundURL;
								// let previewImageURL = "SomeRandomURL";
								if (editableElements.length > 0) {
									backgroundURL = await getS3URL("");
								}

								const data = {};
								data["previewurl"] = previewImageURL;
								if (backgroundURL) data["backgroundurl"] = backgroundURL;

								// Text elements data
								const textElement = elements.find((ele) => !("image" in ele));
								console.log(textElement);
								if (textElement) {
									const textElementData =
										elementSelectionRef.current.getTextDetails();
									data["text"] = textElementData.text;
									data["font_size"] = textElementData.fontSize;
									data["color"] = textElementData.color;
									// data["font_family"] = textElementData.fontFamily;
								}

								console.log(data);

								window.parent.postMessage(
									{
										func: "buyNow",
										message: data,
									},
									"*"
								);
							}}
						>
							<Typography variant="button" fontSize={16}>
								Buy Now
							</Typography>
						</Button>
					))}
			</div>
		);
	}

	return (
		<ThemeProvider theme={theme}>
			{isIdinvalid ? (
				renderInvalid()
			) : (
				<Modal style={modalStyles} isOpen={true}>
					<div className={styles.parent}>
					<Helmet>
						<title>{pageTitle}</title>
						<meta name="description" content={pageDescription}/>
					</Helmet>
						<div className={styles.outerBox}>
							{window.innerWidth < 768 ? (
								<>
									{renderElementSelection()}
									{renderCanvas()}
								</>
							) : (
								<>
									{!invertCanvasView ? (
										<div style={{ display: "block" }}>
											{renderElementSelection()}
											{renderSubmitBox()}
										</div>
									) : null}
									<div style={{ display: "block" }}>{renderCanvas()}</div>
									{invertCanvasView ? (
										<div style={{ display: "block" }}>
											{renderElementSelection()}
											{renderSubmitBox()}
										</div>
									) : null}
								</>
							)}
						</div>
						{window.innerWidth < 768 && <>{renderSubmitBox()}</>}
					</div>
				</Modal>
			)}
			{/* <Modal style={incognitoModalStyles} isOpen={showIncognitoPopup}>
				<div style={{textAlign: "left", padding: "3%"}}>
				<div style={{textAlign: "end"}}>
					<Close style={{cursor: "pointer"}} onClick={() => {setShowIncognitoPopup(false)}}/>
				</div>
				<h2 style={{textAlign: "center"}}> You have gone Incognito</h2>
				<p>
					Your cookies settings might not allow contents to load in this popup. Click below to load the contents in a new tab.
				</p>
				<div style={{display: "flex", gap: "2vh", justifyContent: "center"}}>
					<Button variant="outlined	" onClick={() => {setShowIncognitoPopup(false)}} sx={{textTransform: "none"}}>
							Ignore
					</Button>
					<a href={`https://myera.pro/create-product?product_id=${product_id_parameter}&app_id=${app_id}&secret_access_key=${secret_access_key}`} target="_blank" rel="noreferrer">
					<Button variant="contained" sx={{textTransform: "none"}}>
						Launch in new tab
					</Button>
					</a>
				</div>
				<br /><br />
				<p>
					If the problem persists, try these:
					<br />
					1. Load the page in normal browsing mode, OR
					<br />
					2. Check your cookies settings.
				</p>
				</div>
			</Modal> */}
		</ThemeProvider>
	);
};

export default ProductCreate;
