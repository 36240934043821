import React from "react";
import {
	Inject,
	ScheduleComponent,
	Day,
	Week,
	Month,
	Agenda,
	ViewDirective,
	ViewsDirective,
	EventSettingsModel,
	DragAndDrop,
	Resize,
} from "@syncfusion/ej2-react-schedule";
import "./template_event.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import facebook from "./facebook.png";
import instagram from "./instagram.png";
import default_image from "./default_image.png";
import Modal from "react-modal";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import PostModal from "./PostModal";
import { openModalService } from "../Generic/messageService";
import coloredLinkedIn from "../Images/LinkedinLogo.png";

const modalButtonStyle = {
	backgroundColor: "rgba(132, 232, 244, 1)",
	fontFamily: "Open Sans",
	textTransform: "none",
	height: "5vh",
	borderRadius: "25px 0 0 25px",
	// marginRight: "20%"
};

const modalDropdownButtonStyle = {
	backgroundColor: "rgba(132, 232, 244, 1)",
	fontFamily: "Open Sans",
	textTransform: "none",
	height: "5vh",
	borderRadius: "0 25px 25px 0",
};

const styleTemplateContainer = {
	display: "flex",
	flexWrap: "wrap",
	width: "85%",
	margin: "auto",
	height: "70vh",
	marginTop: "2vh",
};

const styleScroll = {
	overflowY: "scroll",
	display: "flex",
	flexWrap: "wrap",
	width: "85%",
	margin: "auto",
	height: "70vh",
	marginTop: "2vh",
};

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: 700,
		width: 950,
		color: "rgb(132, 232, 244)",
		padding: "0%",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

const savedModalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: 500,
		width: 1200,
		color: "rgb(132, 232, 244)",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

const Input = styled("input")({
	display: "none",
});

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "rgb(132, 232, 244)",
		},
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

class Calander extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			scheduled_posts: [],
			modalIsOpen: false,
			postCaption: "",
			imageIsUploaded: false,
			currentPostImage: "",
			currentPostImageFile: null,
			selectedDate: new Date(),
			imageOption: "none",
			isScheduledPost: true,
			postOption: "postnow",
			selectedPlatforms: [],
			inPosting: false,
			saved_creatives: [],
			isSavedModalOpen: false,
			isCampaignModalOpen: false,
			selectedScheduledPostId: null,
			insta_username: "instagram",
			fb_page_name: "facebook",
			twitter_name: "twitter",
			linkedin_org_name: "linkedin",
			is_facebook_linked: true,
		};

		this.openModal = this.openModal.bind(this);
		this.child = React.createRef();
	}

	scheduled_posts = [
		{
			Id: 1,
			Subject: "New Post",
			image: "https://quarkme-assets.s3.amazonaws.com/media/image_m31EujW.png",
			StartTime: new Date(2022, 2, 1, 1, 30, 0),
			EndTime: new Date(2022, 2, 1, 2, 0, 0),
			post_to_facebook: true,
			post_to_insta: true,
		},

		{
			Id: 2,
			Subject: "Second Post",
			image: "https://quarkme-assets.s3.amazonaws.com/media/image_m31EujW.png",
			StartTime: new Date(2022, 2, 1, 3, 45, 0),
			EndTime: new Date(2022, 2, 1, 3, 45, 0) + 30 * 60000,
			post_to_facebook: true,
			post_to_insta: false,
		},

		{
			Id: 3,
			Subject: "Third Post",
			image: "https://quarkme-assets.s3.amazonaws.com/media/image_m31EujW.png",
			StartTime: new Date(2022, 2, 2, 1, 30, 0),
			EndTime: new Date(2022, 2, 2, 2, 0, 0),
			post_to_facebook: false,
			post_to_insta: true,
		},
	];

	setSchedulePosts(schedule_posts) {
		var posts = [];
		for (var post of schedule_posts) {
			posts.push({
				Id: post.id,
				Subject: post.caption,
				image: post.image ? post.image : post.image_file,
				StartTime: new Date(post.schedule_datetime),
				EndTime: new Date(post.schedule_datetime) + 30 * 60000,
				post_to_insta: post.post_to_insta,
				post_to_facebook: post.post_to_facebook,
				post_to_twitter: post.post_to_twitter,
				post_to_linkedin: post.post_to_linkedin,
			});
		}

		this.setState({ scheduled_posts: posts });
	}

	getStyles(name, platform, theme) {
		return {
			fontWeight:
				platform.indexOf(name) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
	}

	eventTemplate(props) {
		console.log(props);
		return (
			<div className="template-wrap">
				{props.image ? (
					<img
						src={props.image}
						style={{ height: "80px", width: "80px" }}
					></img>
				) : (
					<div
						style={{
							height: "80px",
							width: "80px",
							color: "black",
							textAlign: "center",
							border: "1px solid",
						}}
					>
						<p>{props.Subject}</p>
					</div>
				)}
				{/* <img src={props.image} style={{height: '80px', width: '80px'}}></img> */}
				<div style={{ marginLeft: "8px" }}>
					<div
						style={{
							marginBottom: "27px",
							marginTop: "5px",
							display: props.post_to_insta ? "block" : "none",
						}}
					>
						<img
							src={instagram}
							style={{ height: "20px", width: "20px" }}
						></img>
					</div>
					<div
						style={{
							display: props.post_to_facebook ? "block" : "none",
							marginTop: "5px",
						}}
					>
						<img src={facebook} style={{ height: "20px", width: "20px" }}></img>
					</div>
					<div
						style={{
							display: props.post_to_twitter ? "block" : "none",
							marginTop:
								props.post_to_insta && props.post_to_facebook ? "-180%" : "5px",
						}}
					>
						<img
							src="https://img.icons8.com/color/48/undefined/twitter--v1.png"
							style={{ height: "25px", width: "25px", marginLeft: "-11%" }}
						></img>
					</div>
					<div
						style={{
							display: props.post_to_linkedin ? "block" : "none",
							marginTop:
								props.post_to_insta && props.post_to_facebook ? "-180%" : "5px",
						}}
					>
						<img
							src={coloredLinkedIn}
							style={{ height: "20px", width: "20px" }}
						></img>
					</div>
				</div>
			</div>
		);
	}

	onDragStart(args) {
		args.scroll = { enable: false };
	}

	onPopupOpen(args) {
		if (args.type === "Editor") {
			args.cancel = true;
			console.log(args); // Has start time and endtime

			this.setState({
				modalIsOpen: true,
				isScheduledPost: true,
				selectedDate: new Date(),
				currentPostImage: default_image,
				postCaption: "",
				imageOption: "none",
				postOption: "postnow",
			});
		}
	}

	onEventClick(args) {
		var image = args.event.image;
		var schedule_datetime = args.event.StartTime;
		var caption = args.event.Subject;
		var post_id = args.event.Id;

		// this.setState({currentPostImage: image, postCaption: caption, selectedDate: schedule_datetime, imageIsUploaded: true, isScheduledPost: false, postOption: 'scheduleforlater', selectedScheduledPostId: post_id})
		var arr = [];

		if (args.event.post_to_facebook) arr.push(this.state.fb_page_name);

		if (args.event.post_to_insta) arr.push(this.state.insta_username);

		if (args.event.post_to_twitter) arr.push(this.state.twitter_name);

		if (args.event.post_to_linkedin) arr.push(this.state.linkedin_org_name);

		openModalService.sendMessage({
			modalState: "scheduledPost",
			stateVariables: {
				currentPostImage: image,
				postCaption: caption,
				selectedDate: schedule_datetime,
				imageIsUploaded: true,
				isScheduledPost: false,
				postOption: "scheduleforlater",
				selectedScheduledPostId: post_id,
				modalIsOpen: true,
				selectedPlatforms: arr,
			},
		});

		// this.openModal();
	}

	openModal() {
		// this.setState({modalIsOpen: true})
		openModalService.sendMessage({
			modalState: "newPost",
		});
		// this.child.current.openModal();
	}

	render() {
		return (
			<div>
				<ThemeProvider theme={theme}>
					<div style={{ display: "flex" }}>
						<h4 style={{ marginLeft: "36%", color: "white" }}>
							Your Scheduled Posts
						</h4>
						{/* <div class="btn-group" style={{marginLeft: '30%'}}>

                    <button type="button" class="btn btn-info hoverClass" style={modalButtonStyle} onClick={(e) => this.handleCreatePostButton(e)}>Create Post</button>
                    <button type="button" class="btn btn-info dropdown-toggle hoverClass" data-bs-toggle="dropdown" style={modalDropdownButtonStyle}>
                        <span class="sr-only"></span>
                    </button>
                    <ul class="dropdown-menu hoverClass2" onClick={(e) => this.handleScheduleButton(e)}>
                        <div class="dropdown-item"><span>Schedule</span></div>
                    </ul>
                    </div> */}
						<Button
							variant="contained"
							size="medium"
							style={{ marginLeft: "32%", borderRadius: "25px", height: "4vh" }}
							onClick={() => this.openModal()}
						>
							Create Post
						</Button>
					</div>
					<br></br>
					<ScheduleComponent
						height="600px"
						width="auto"
						eventSettings={{
							dataSource: this.state.scheduled_posts,
							template: this.eventTemplate.bind(this),
						}}
						showQuickInfo={false}
						eventClick={this.onEventClick.bind(this)}
						popupOpen={this.onPopupOpen.bind(this)}
						allowDragAndDrop={false}
						dragStart={this.onDragStart.bind(this)}
						allowResizing={false}
						cssClass="schedule-cell-dimension"
					>
						<Inject services={[Month, Agenda, Week]} />
						<ViewsDirective>
							<ViewDirective option="Month" isSelected={true}></ViewDirective>
							<ViewDirective option="Agenda" displayName="List"></ViewDirective>
							<ViewDirective option="Week"></ViewDirective>
						</ViewsDirective>
					</ScheduleComponent>
					<PostModal></PostModal>
				</ThemeProvider>
			</div>
		);
	}

	componentDidMount() {
		const { accessToken } = isAuthenticated();
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/` + "get_scheduled_posts/",
			requestOptions
		)
			// fetch('http://localhost:8000/quarkme/api/get_scheduled_posts/')
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				this.setSchedulePosts(data);
			});

		var user_data = JSON.parse(localStorage.getItem("Erajwt"));
		this.setState({ is_facebook_linked: user_data.is_facebook_linked });
		if (user_data.is_facebook_linked)
			this.setState({
				insta_username: user_data.insta_username,
				fb_page_name: user_data.fb_page_name,
			});
		if (user_data.is_twitter_linked)
			this.setState({
				twitter_name: user_data.twitter_name,
				linkedin_org_name: user_data.linkedin_org_name,
			});
	}
}

export default Calander;
