import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  display: flex;
  background: #0d1016;
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;
const Card = styled.div`
  border-radius: 14px;
`;

function Plans() {
  const [userPlan, setUserPlan] = useState({
    downloads: 0,
    schedules: 0,
    subscription: "None",
  });

  const [isPremium, setIsPremium] = useState(null);
  const expiryDate = userPlan.end_date;
  useEffect(() => {
    const { accessToken } = isAuthenticated();

    var requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    fetch(`${process.env.REACT_APP_BACKEND}/get_user_plan/`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUserPlan(data);
        console.log(userPlan.end_date);
      });

    fetch(`${process.env.REACT_APP_BACKEND}/is_premuim_member/`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsPremium(data);
      });
  }, []);
  return (
    <Wrapper id="outer-container">
      <Helmet>
        <title>MyEra | My Plan</title>
      </Helmet>

      <Sidebar />
      <MainDashboard>
        <Topbar />

        <div
          style={{
            height: "500px",
            width: "40%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            marginTop: "10%",
            backgroundColor: "#2D313C",
          }}
        >
          <div
            style={{
              height: "40%",
              color: "white",
              fontSize: "25px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                height: "50%",
                display: "flex",
                marginLeft: "5vh",
                marginTop: "2vh",
              }}
            >
              <h1 style={{}}>
                {" "}
                {userPlan.subscription === "None" ? (
                  <p>Free Plan</p>
                ) : (
                  <p>{userPlan.subscription}</p>
                )}
              </h1>
            </div>

            <div style={{ height: "50%" }}>
              <div style={{ width: "13%", marginLeft: "85%" }}>
                <div
                  style={{
                    backgroundColor: "#84E7F2",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  ACTIVE
                </div>
              </div>
            </div>
            <div
              style={{
                color: "white",
                fontSize: "20px",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2%",
              }}
            >
              <div
                style={{
                  marginTop: "-2vh",
                  fontSize: "20px",
                  marginLeft: "2.5vh",
                }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ fontSize: "28px" }}>₹{userPlan.price}</span>
                <br />
                &nbsp;&nbsp;&nbsp;Next Renewal amount
              </div>
              <div style={{ marginRight: "2%" }}>
                Expiry Date{" "}: 
                {userPlan.subscription === "None" ? <span style={{ color: "red" }}>NA</span> : <span style={{ color: "red" }}>{" "}{(expiryDate).slice(0,10)}</span>}
              {/* {console.log("Date type:" ,typeof(expiryDate))} */}
                {" "}
              </div>
            </div>
          </div>
          <div
            style={{
              height: "45%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                width: "33%",
                color: "white",
                fontSize: "25px",
                margin: "auto",
                textAlign: "center",
                color: "black",
              }}
            >
              Duration <br /> Yearly
            </div>

            <div
              style={{
                width: "33%",
                color: "white",
                fontSize: "25px",
                margin: "auto",
                textAlign: "center",
                color: "black",
              }}
            >
              Downloads <br /> {userPlan.downloads}
            </div>

            <div
              style={{
                width: "33%",
                color: "white",
                fontSize: "25px",
                margin: "auto",
                textAlign: "center",
                color: "black",
              }}
            >
              Scheduled <br /> {userPlan.schedules}
            </div>
          </div>

          <div
            style={{
              height: "20%",
              display: "flex",
              backgroundColor: "white",
              borderTop: "5px solid #F7F7F8",
            }}
          >
            <div
              style={{
                width: "50%",
                color: "white",
                fontSize: "25px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <a href="https://payu.in/web/504A3B93FCE8C13FE87FB5F84C789853">Renew Plan</a>
            </div>
          </div>
        </div>
      </MainDashboard>
    </Wrapper>
  );
}

export default Plans;
