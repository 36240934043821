import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div style={{ color: 'white', width: '90%', margin: 'auto' }}>
      <div style={{ marginTop: '5vh', fontSize: '5vh' }}>Privacy Policy</div>
      <div>
        <div style={{ fontSize: '4vh', marginTop: '4vh', marginBottom: '2vh' }}>Introduction</div>
        <div style={{ marginBottom: '2vh' }}>We are committed to safeguarding the privacy of our website visitors (general users), service users (clients) and service providers (vendors).</div>
        <div style={{ marginBottom: '2vh' }}>This policy applies where we are acting as a data controller with respect to the personal data of our general users, clients and vendors; in other words, where we determine the purposes and means of the processing of that personal data.</div>
        <div style={{ marginBottom: '2vh' }}>We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our services and are used only to make your overall user experience, a hassle-free one, we will ask you to consent to our use of cookies when you first visit our website.</div>
        <div style={{ marginBottom: '2vh' }}>Our website incorporates privacy controls which affect how we will process your data. By using the privacy controls, you can specify whether you would like to receive direct marketing communications and limit the publication of your information. You can access the privacy controls while registering on the platform for the first time.</div>
        <div style={{ marginBottom: '2vh' }}>In this policy, "we", "us" and "our" refer to MyEra Technologies Pvt. Ltd. For more information about us, see Section 8.</div>
        <div style={{ marginBottom: '2vh' }}>Credit</div>
        <div style={{ marginBottom: '2vh' }}>This document was created using a template from SEQ Legal (https://seqlegal.com)</div>
        <div style={{ marginBottom: '2vh' }}>How we use your data</div>
        <div style={{ marginBottom: '2vh' }}>In this Section three we have established:</div>
        <div style={{ marginBottom: '2vh' }}>
          <ul>
            <li>The general categories of personal data that we may process;</li>
            <li>In the case of personal data that we did not obtain directly from you, the source and specific categories of that data.</li>
            <li>The purposes for which we may process personal data; and</li>
            <li>The legal bases of the processing.</li>
          </ul>
        </div>
        <div style={{ marginBottom: '2vh' }}>We may process data about your use of our website and services ("usage data"). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our proprietary analytics tracking system. This usage data may be processed for the purposes of analyzing the use of the website and services. The legal basis for this processing is consent or our legitimate interests, namely monitoring and improving our website and services or improving the overall user-experience for the website and platform.</div>
        <div style={{ marginBottom: '2vh' }}>We may process your account data ("account data"). The account data may include your name and email address. The source of the account data is you or your employer who creates an account on your behalf. The account data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our website and business or the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</div>
        <div style={{ marginBottom: '2vh' }}>We may process your information included in your profile on our website ("profile data"). The profile data may include your name, address, telephone number, email address, profile pictures, gender and employment details. The profile data may be processed for the purposes of enabling and monitoring your use of our website and services. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our website and business or the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</div>
        <div style={{ marginBottom: '2vh' }}>We may process your data that are provided during the use of our services ("service data"). The service data may include activity and related information, conversions and related information; and other business related information. The source of the service data is you or your employer. The service data may be processed for the purposes of operating our website, providing our services, ensuring the security of our website and services, maintaining back-ups of our databases and communicating with you. The legal basis for this processing is consent or our legitimate interests, namely the proper administration of our website and business or the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</div>
        <div style={{ marginBottom: '2vh' }}>We may process information contained in any inquiry you submit to us regarding goods and/or services ("inquiry data"). The inquiry data may be processed for the purposes of offering, marketing and selling relevant goods and/or services to you]. The legal basis for this processing is consent or our legitimate interests.</div>
        <div style={{ marginBottom: '2vh' }}>We may process information relating to our customer relationships, including customer contact information ("customer relationship data"). The customer relationship data may include your name, your employer, your job title or role, your contact details, and information contained in communications between us and you or your employer. The source of the customer relationship data is you or your employer. The customer relationship data may be processed for the purposes of managing our relationships with customers, communicating with customers, keeping records of those communications and promoting our products and services to customers. The legal basis for this processing is consent or legitimate interests, namely the proper management of our customer relationships.</div>
        <div style={{ marginBottom: '2vh' }}>We may process information relating to transactions, including purchases of goods and services, that you enter into with us and/or through our website ("transaction data"). The transaction data may include your contact details, your card details and the transaction details. The transaction data may be processed for the purpose of supplying the purchased goods and services and keeping proper records of those transactions. The legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract and our legitimate interests, namely the proper administration of our website and business.</div>
        <div style={{ marginBottom: '2vh' }}>We may process information that you provide to us for the purpose of subscribing to our email notifications and/or newsletters ("notification data"). The notification data may be processed for the purposes of sending you the relevant notifications and/or newsletters. The legal basis for this processing is consent or the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</div>
        <div style={{ marginBottom: '2vh' }}>We may process information contained in or relating to any communication that you send to us ("correspondence data"). The correspondence data may include [the communication content and metadata associated with the communication. Our website will generate the metadata associated with communications made using the contact forms(s) provided through our website. The correspondence data may be processed for the purposes of communicating with you and record-keeping. The legal basis for this processing is our legitimate interests, namely the proper administration of our website and business and communications with users.</div>
        <div style={{ marginBottom: '2vh' }}>We may process any of your data identified in this policy where necessary for the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests, namely the protection and assertion of our legal rights, your legal rights and the legal rights of other stakeholders.</div>
        <div style={{ marginBottom: '2vh' }}>We may process any of your data identified in this policy where necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, or obtaining professional advice. The legal basis for this processing is our legitimate interests, namely the proper protection of our business against risks.</div>
        <div style={{ marginBottom: '2vh' }}>In addition to the specific purposes for which we may process your data as established in this Section 3, we may also process any of your data where such processing is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</div>
        <div style={{ marginBottom: '2vh' }}>Please do not supply any other person's personal data to us to avoid liability and infringement against any person, unless we prompt you to do so; in which case, the privacy of the data you share with us, will be deemed legally bound by our Terms of Service and other applicable policies.</div>
        <div style={{ marginBottom: '2vh' }}>Providing your data to others We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy. Information about our group of companies can be found at www.MyEra.pro</div>
        <div style={{ marginBottom: '2vh' }}>We may disclose your data to our insurers and/or professional advisers insofar as reasonably necessary for the purposes of obtaining or maintaining insurance coverage, managing risks, obtaining professional advice, or the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</div>
        <div style={{ marginBottom: '2vh' }}>We may disclose your contact and service data to our vendors or or clients identified during the creating or availing of a particular service insofar as reasonably necessary for processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract and our legitimate interests, namely the proper administration of our website and business.</div>
        <div style={{ marginBottom: '2vh' }}>Financial transactions relating to our website and services are handled by our payment services providers. We will share transaction data with our payment services providers only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds. You can find information about the payment services providers' privacy policies and practices at www.MyEra.pro</div>
        <div style={{ marginBottom: '2vh' }}>In addition to the specific disclosures of personal data established in this Section 4, we may disclose your data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</div>
        <div style={{ marginBottom: '2vh' }}>Retaining and Deleting Personal Data</div>
        <div style={{ marginBottom: '2vh' }}>This Section 6 sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations concerning the retention and deletion of personal data.</div>
        <div style={{ marginBottom: '2vh' }}>Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</div>
        <div style={{ marginBottom: '2vh' }}>We will retain your data as follows: "transaction data" and "service data" will be retained for a minimum period of 1 year following the date of collection/creaton, and for a maximum period of 5 years following the date of collection/creation.</div>
        <div style={{ marginBottom: '2vh' }}>In some cases it is not possible for us to specify in advance the periods for which your data will be retained. In such cases, we will determine the period of retention based on the following criteria: The period of retention of "account data", "usage data" and "correspondence data" will be determined based on our necessary requirement to retain access for the same at any given point of time.</div>
        <div style={{ marginBottom: '2vh' }}>Notwithstanding the other provisions of this Section 5, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</div>
        <div style={{ marginBottom: '2vh' }}>Amendments</div>
        <div style={{ marginBottom: '2vh' }}>We may update this policy from time to time by publishing a new version on our website.</div>
        <div style={{ marginBottom: '2vh' }}>You should check this page occasionally to ensure you are happy with any changes to this policy.</div>
        <div style={{ marginBottom: '2vh' }}>We may notify you of significant changes to this policy by email or through the private messaging system on our website.</div>
        <div style={{ marginBottom: '2vh' }}>Your Rights</div>
        <div style={{ marginBottom: '2vh' }}>In this Section 7, we have summarized the rights that you have under data protection law. Some of the rights are complex, and not all of the details have been included in our summaries. Accordingly, you should read the relevant laws and guidance from the regulatory authorities for a full explanation of these rights.</div>
        <div style={{ marginBottom: '2vh' }}>Your principal rights under data protection law are:</div>
        <div style={{ marginBottom: '2vh' }}>
          <ul>
            <li>The right to access;</li>
            <li>The right to rectification;</li>
            <li>The right to erasure;</li>
            <li>The right to restrict processing;</li>
            <li>The right to object to processing;</li>
            <li>The right to data portability;</li>
            <li>The right to complain to a supervisory authority; and</li>
            <li>The right to withdraw consent;</li>
          </ul>
        </div>
        <div style={{ marginBottom: '2vh' }}>You have the right to confirmation as to whether or not we process your personal data and, where we do, access to the personal data, together with certain additional information. That additional information includes details of the purposes of the processing, the categories of personal data concerned and the recipients of the personal data. Providing the rights and freedoms of others are not affected, we will supply you a copy of your data. The first copy will be provided free of charge, but additional copies may be subject to a reasonable fee.</div>
        <div style={{ marginBottom: '2vh' }}>You have the right to have any inaccurate personal data about you rectified and, taking into account the purposes of the processing, to have any incomplete personal data about you completed.</div>
        <div style={{ marginBottom: '2vh' }}>In some circumstances you have the right to the erasure of your personal data without undue delay. Those circumstances include: the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions include where processing is necessary: for exercising the right of freedom of expression and information; for compliance with a legal obligation; or for the establishment, exercise or defence of legal claims.</div>
        <div style={{ marginBottom: '2vh' }}>In some circumstances you have the right to restrict the processing of your personal data. Those circumstances are: you contest the accuracy of the personal data; processing is unlawful but you oppose erasure; we no longer need the personal data for the purposes of our processing, but you require personal data for the establishment, exercise or defense of legal claims; and you have objected to processing, pending the verification of that objection. Where processing has been restricted on this basis, we may continue to store your data. However, we will only otherwise process it: with your consent; for the establishment, exercise or defense of legal claims; for the protection of the rights of another natural or legal person; or for reasons of important public interest.</div>
        <div style={{ marginBottom: '2vh' }}>You have the right to object to our processing of your data on grounds relating to your particular situation, but only to the extent that the legal basis for the processing is that the processing is necessary for: the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or the purposes of the legitimate interests pursued by us or by a third party. If you make such an objection, we will cease to process the personal information unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defense of legal claims.</div>
        <div style={{ marginBottom: '2vh' }}>You have the right to object to our processing of your data for scientific or historical research purposes or statistical purposes on grounds relating to your particular situation, unless the processing is necessary for the performance of a task carried out for reasons of public interest.</div>
        <div style={{ marginBottom: '2vh' }}>To the extent that the legal basis for our processing of your data is:</div>
        <div style={{ marginBottom: '2vh' }}>
          <ul>
            <li>consent; and/or</li>
            <li>that the processing is necessary for the performance of a contract to which you are party or in order to take steps at your request prior to entering into a contract,</li>
          </ul>
        </div>
        <div style={{ marginBottom: '2vh' }}>and such processing is carried out by automated means, you have the right to receive your personal data from us in a structured, commonly used and machine-readable format. However, this right does not apply where it would adversely affect the rights and freedoms of others.</div>
        <div style={{ marginBottom: '2vh' }}>If you consider that our processing of your personal information infringes data protection laws, you have a legal right to lodge a complaint with a supervisory authority responsible for data protection. You may do so in the judicial bodies present in the Indian member state of your habitual residence, your place of work or the place of the alleged infringement.</div>
        <div style={{ marginBottom: '2vh' }}>To the extent that the legal basis for the processing of your personal information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.</div>
        <div style={{ marginBottom: '2vh' }}>You may exercise any of your rights concerning your personal data by written notice to us OR by a self-declarative email sent from your official email address as specified in our correspondence data, in addition to the other methods specified in this Section 7.</div>

        <div style={{ marginBottom: '2vh' }}>By registering on MyEra you give us permission to communicate with you over email.</div>
        <div style={{ marginBottom: '2vh' }}>Our Details</div>

        <div style={{ marginBottom: '2vh' }}>This website is owned and operated by MyEra Technologies Pvt. Ltd.</div>
        <div style={{ marginBottom: '2vh' }}>You can contact us:</div>
        <div style={{ marginBottom: '2vh', marginBottom: '10vh' }}>
          <ul>
            <li>by post, to the postal address given above;</li>
            <li>using our website 's contact form;</li>
            <li>by telephone, on the contact number published on our website from time to time; or</li>
            <li>by email, using the email address published on our website from time to time.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
