import React, { useState, useEffect, useRef, useContext } from "react";
import { Stage, Layer, Rect } from "react-konva";
import { Image as KonvaImage } from "react-konva";
import URLImage from "../Campaign_editor/URLImage";
import EditorText from "../Campaign_editor/EditorText";
import { Link } from "react-router-dom";
import {
	appContext,
	dispatchContext,
} from "../../Statemanagement/Statecontext";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import styled from "styled-components";
import "./CampaignJourney.css";
import Select from "react-select";
import Button from "@mui/material/Button";
import Axios from "axios";
import axios from "axios";
import useWindowDimensions from "../../customAPIs/useWindowDimensions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isAuthenticated, ApiToken } from "../../Helper/Endpoints/Endpoints";
import { Color, ColorPicker, createColor } from "material-ui-color";
import AddPencil from "../Images/AddPencil.png";
import SaveHeart from "../Images/SaveHeart.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Download from "../Images/Download.svg";
import text from "../Images/text.png";
import upload from "../Images/upload.png";
import BlueHeart from "../Images/BlueHeart.svg";
import tags2 from "../Images/tags2.png";
import close from "../Images/close.png";
import TextEditor from "../Campaign_editor/TextJsons/TextEditor";
import * as PropTypes from "prop-types";
import EditorBackground from "./EditorBackground";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { async } from "rxjs";
import Modal from "react-modal";
import ImageCropper from "../ImageCropper/ImageCropper";
import { Cancel } from "@material-ui/icons";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { getCroppedImageService } from "../Generic/messageService";

toast.configure();

let ctaId = "";
let heroId = "";
let designId = "";
let objectiveId = "";
let hashTagsSelected = [];

const Text = styled.div`
	font-family: "Open Sans", sans-serif;
	font-weight: 549;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 1vh;
	font-size: 3vh;
`;
const HeadingInput = styled.input`
	margin-top: 4vh;
	margin-left: 1%;
	font-size: 16px;
	width: 65%;
	height: 43px;
	color: #ffffff;
	background-color: rgba(217, 217, 217, 0.001);
	border: 1px solid #7e8188;
	border-radius: 12px;
	padding-left: 12px;
`;

const BodyDiv = styled.div`
	display: flex;

	color: white;
`;
const HeaderDiv = styled.div`
	display: flex;
	color: white;
`;

const customStyles = {
	option: (provided, state) => ({
		...provided,
		borderBottom: "1px solid rgba(33, 42, 64, 1)",
		backgroundColor: state.value,
		color: state.value > "#8b8680" ? "black" : "white",
		fontSize: "60%",
	}),
	control: (_, { selectProps: { width } }) => ({
		display: "flex",
		border: "solid 1px #7E8188",
		color: "rgba(255, 255, 255, 0.8)",
		borderRadius: "10px",
		width: "19vh",
		fontSize: "60%",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";

		return {
			...provided,
			opacity,
			transition,
			color: "rgba(255, 255, 255, 0.8)",
		};
	},
};

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: "67.5%",
		width: "40%",
		color: "#FFFFFF",
		padding: "0%",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

var flag = false;
var flag_additional = false;
var text_1_length = 0;
var additional_text_1_length = 0;

function ProductCanvas(props) {
	const [selectedEditorType, setSelectedEditorType] = useState("editorType");
	const [widthLayoutCanvas, setWidthLayoutCanvas] = useState(500);
	const [heightLayoutCanvas, setHeightLayoutCanvas] = useState(500);
	const [scale_factor_layout, setScale_factor_layout] = useState(1);
	const [selectedDesign, setSelectedDesign] = useState("");
	const [initializeParams, setInitializeParams] = useState(false);
	//const { accessToken } = isAuthenticated() ? isAuthenticated()  :  ApiToken();
	const [generateIndex, setGenerateIndex] = useState(0);
	const [selectedTagsArray, setSelectedTagsArray] = useState([]);
	const { width } = useWindowDimensions();
	const dispatch = useContext(dispatchContext);
	const [selectedAssetState, setSelectedAssetState] = useState(false);
	const [assetImage, setAssetImage] = useState(null);
	const [logoImage, setLogoImage] = useState(null);
	const [checked, setChecked] = useState(true);
	const [fontFamilyOptions, setFontFamilyOptions] = useState([]);
	const [fontSizeOptions, setfontSizeOptions] = useState(
		Array.from(Array(200).keys())
	);
	const [fontFamily, setFontFamily] = useState("");
	const [generateResultArray, setGenerateResultArray] = useState(false);
	const [selectedThumbnail, setSelectedThumbnail] = useState(true);
	const [selectedAdditionalThumbnail, setSelectedAdditionalThumbnail] =
		useState(true);
	const [colorText, setColorText] = useState(createColor("black"));
	const [userAssets, setUserAssets] = useState([]);
	const [colorbg, setColorbg] = useState(createColor("red"));

	const [freeCreatives, setFreeCreatives] = useState([15]);
	const [showEditText, setShowEditText] = useState(false);
	const [showEditHeading, setShowEditHeading] = useState(false);
	const [showEditAdditionalText, setShowEditAdditionalText] = useState(false);
	const [showEditAdditionalText2, setShowEditAdditionalText2] = useState(false);
	const [assetChosenState, setAssetChosenState] = useState(false);
	const [selectedFontFamilyText, setSelectedFontFamilyText] = useState("");
	const [selectedFontFamilySize, setSelectedFontFamilySize] =
		useState("Font Size");
	const [selectedFontFamilySizeBody, setSelectedFontFamilySizeBody] =
		useState("Font Size");
	const [selectedFontFamilySizeAdd, setSelectedFontFamilySizeAdd] =
		useState("Font Size");
	const [selectedFontFamilySizeHero, setSelectedFontFamilySizeHero] =
		useState("Font Size");
	const [editHeading, setEditHeading] = useState("Edit Hero Text");
	const [editBodyText, setEditBodyText] = useState("Edit Body Text");
	const [editAddText, setEditAddText] = useState("Edit CTA Text");
	const [editAdd2Text, setEditAdd2Text] = useState("Edit Additional Text 1");

	const [backgroundData, setBackgroundData] = useState({});
	const [textData, setTextData] = useState({});
	const [brand_colors, setbrand_colors] = useState([]);
	const [check, setCheck] = useState(false);
	const [select_value, setSelectValue] = useState({ label: "Brand Colors..." });
	const [select_bgcolor, setSelect_bgcolor] = useState("#ffffff");
	const [errors, setErrors] = useState({
		objectives: "",
		design: "",
		tags: "",
		heroText: "",
		cta: "",
	});

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [croppedImage, setCroppedImage] = useState(null);
	const [userlogos, setuserLogos] = useState([]);
	const [user_bg, setUserBg] = useState([]);
	const [uniqueImages, setUniqueImages] = useState([]);

	const customStylesFont = {
		option: (provided, state) => ({
			...provided,
			borderBottom: "1px solid rgba(33, 42, 64, 1)",
			backgroundColor: "#121828",
			color: state.isSelected
				? "rgba(255, 255, 255, 1)"
				: "rgba(255, 255, 255, 0.3)",
			padding: 20,
		}),
		control: (_, { selectProps: { width } }) => ({
			display: "flex",
			border: "solid 1px #7E8188",
			color: "rgba(255, 255, 255, 0.8)",
			borderRadius: "10px",
			marginLeft: "1vh",
			marginTop: "3.5vh",
			width: "25vh",
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";

			return {
				...provided,
				opacity,
				transition,
				color: "rgba(255, 255, 255, 0.8)",
			};
		},
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: "rgba(36, 171, 226, 0.24)",
				color: "#24abe2",
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#24abe2",
		}),
	};

	function openModal() {
		setModalIsOpen(true);
	}

	function closeModal() {
		setModalIsOpen(false);
	}

	function onFileChange(e) {
		var url = URL.createObjectURL(e.target.files[0]);
		setSelectedImage(url);
		setSelectedFile(e.target.files[0]);
		console.log(url);
	}

	function addLogoFile() {
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();
		var form_data3 = new FormData();
		form_data3.append("logo", croppedImage);

		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND}/add_user_logo/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			data: form_data3,
		})
			.then((res) => {
				setuserLogos([...userlogos, res["data"]]);
				toast.success("Logo Added !!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((err) => {
				toast.error("Error: Couldn't add new logo!", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});

		closeModal();
	}

	function updateUniqueImages(data) {
		var res = data.reduce(function (a, b) {
			if (!a[b["asset_note"]]) {
				a[b["asset_note"]] = b;
			}
			return a;
		}, {});
		setUniqueImages(Object.keys(res).map((key) => res[key]));
	}

	let Cropper = <></>;
	if (selectedImage) {
		console.log("Cropping");

		Cropper = (
			<div style={{ display: "flex" }}>
				<div style={{ marginLeft: "15%" }}>
					<ImageCropper src={selectedImage}></ImageCropper>
				</div>
				<Cancel
					onClick={() => setSelectedImage(null)}
					style={{ marginLeft: "10%" }}
				></Cancel>
			</div>
		);
	} else {
		Cropper = (
			<div
				style={{
					borderRadius: "5%",
					border: "1px solid rgb(255, 255, 255, 0.2)",
					height: "250px",
					width: "250px",
					margin: "auto",
					marginTop: "20px",
					display: selectedImage ? "none" : "block",
				}}
			>
				<label style={{ display: "block" }}>
					<input
						accept="image/*"
						id="contained-button-file"
						multiple
						type="file"
						onChange={(e) => onFileChange(e)}
						style={{ display: "none" }}
					/>
					<Button
						sx={{ mt: "25%", textTransform: "none" }}
						color="primary"
						aria-label="upload picture"
						component="span"
					>
						<div>
							<div>
								<CloudUploadOutlinedIcon
									style={{ width: "75px", height: "75px" }}
								></CloudUploadOutlinedIcon>
							</div>
							<div style={{ display: "block", fontSize: "20px" }}>
								Select File
							</div>
						</div>
					</Button>
				</label>
			</div>
		);
	}

	const onchangeFontFamilyText = (item) => {
		if (item.font_name === "Select Font Family...") {
			setSelectedFontFamilyText(item);
			setFontFamily(generateResultArray[generateIndex].font_name);
			setCheck_textvar(true);
		} else {
			setSelectedFontFamilyText(item);
			setFontFamily(item.font_name);
			textData.text_font = item.font_name;
			setTextData(textData);
			setCheck_textvar(true);
		}
	};
	const onChangeFontSize = (item) => {
		if (item.font_name === "Font Size") {
			setSelectedFontFamilySize(item);
			setFontFamily(generateResultArray[generateIndex].hero_text);
			setCheck_textvar(true);
		} else {
			// setFontFamily(item.font_name);
			// textData.text_font = item.font_name
			setTextData(textData);
			setCheck_textvar(true);
		}
		console.log("Font Size Changed");
	};

	const handleChangeColorPickerText = (newValue) => {
		setColorText(newValue);
		textData.text_color = newValue;
		setTextData(textData);
		setCheck_textvar(true);
	};

	const handleChangeColorPicker = (newValue) => {
		if (check) setCheck(!check);

		setColorbg(newValue);
		setSelectValue({ label: "Brand Colors..." });
	};
	const handleAssetImage = (assetSrc) => {
		setAssetImage(assetSrc);
	};

	const handleSelectedAsset = (asset) => {
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();
		setGenerateIndex(0);
		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND}/generate_results/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			data: {
				objective_id: props.objectiveId.objectiveId,
				design_id: props.designId.designId,
				cta_id: props.ctaId.ctaId,
				hero_id: props.heroId.heroId,
				tags: ["#" + asset.asset_note],
			},
		})
			.then((response) => {
				Axios({
					method: "post",
					url: `${process.env.REACT_APP_BACKEND}/generate_results_custom/`,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					data: {
						objective_id: parseInt(props.objectiveId.objectiveId),
						design_id: parseInt(props.designId.designId),
						tags: ["#" + asset.asset_note],
					},
				})
					.then((custom_response) => {
						let data = custom_response.data.results;
						data = data.concat(response.data.results);
						response.data.results = data;
					})
					.finally(() => {
						console.log("SETTING USEEFFECT VARIABLES");
						// UserAssetLibrary();
						console.log("USER ASSET DATA: ", userAssets);
						setSelectedAssetState(false);

						console.log("Response: " + JSON.stringify(response));
						let currentCreative = response.data.results[0];
						let backgroundVar = {
							background_color: currentCreative.background,
							background_image: {
								url: currentCreative.shape,
								x_cord: currentCreative.shape_x,
								y_cord: currentCreative.shape_y,
								height: currentCreative.shape_height,
								width: currentCreative.shape_width,
								imgSrc: currentCreative.shape,
							},
						};
						initializeBackgroundValues(backgroundVar);

						setAssetImage(response.data.results[0].photo_url);
						let textDataVar = {
							text_color: createColor(currentCreative.text_color),
							text_font: currentCreative.font_name,
						};
						setTextData(textDataVar);
						setGenerateResultArray(response.data.results);
						setSelectedEditorType("editorType");
						if (response.data.results[generateIndex].ratio === "insta_post") {
							setWidthLayoutCanvas(500);
							setHeightLayoutCanvas(500);
							setScale_factor_layout(1);
						}
						if (
							response.data.results[generateIndex].ratio === "desktop_wallpaper"
						) {
							setWidthLayoutCanvas(960);
							setHeightLayoutCanvas(540);
							setScale_factor_layout(0.72);
						}
						if (response.data.results[generateIndex].ratio === "insta_story") {
							setWidthLayoutCanvas(540);
							setHeightLayoutCanvas(960);
							setScale_factor_layout(0.52);
						}
						setChecked(true);
						dispatch({ type: "LOADED" });
						setInitializeParams(true);
					});
			})
			.catch((error) => {
				Axios({
					method: "post",
					url: `${process.env.REACT_APP_BACKEND}/generate_results_custom/`,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					data: {
						objective_id: parseInt(props.objectiveId.objectiveId),
						design_id: parseInt(props.designId.designId),
						tags: ["#" + asset.asset_note],
					},
				}).then((response) => {
					console.log("SETTING USEEFFECT VARIABLES");
					UserAssetLibrary();
					console.log("USER ASSET DATA: ", userAssets);
					setSelectedAssetState(false);

					console.log("Response: " + JSON.stringify(response));
					let currentCreative = response.data.results[0];
					let backgroundVar = {
						background_color: currentCreative.background,
						background_image: {
							url: currentCreative.shape,
							x_cord: currentCreative.shape_x,
							y_cord: currentCreative.shape_y,
							height: currentCreative.shape_height,
							width: currentCreative.shape_width,
							imgSrc: currentCreative.shape,
						},
					};
					initializeBackgroundValues(backgroundVar);

					setAssetImage(response.data.results[0].photo_url);
					let textDataVar = {
						text_color: createColor(currentCreative.text_color),
						text_font: currentCreative.font_name,
					};
					setTextData(textDataVar);
					setGenerateResultArray(response.data.results);
					setSelectedEditorType("editorType");
					if (response.data.results[generateIndex].ratio === "insta_post") {
						setWidthLayoutCanvas(500);
						setHeightLayoutCanvas(500);
						setScale_factor_layout(1);
					}
					if (
						response.data.results[generateIndex].ratio === "desktop_wallpaper"
					) {
						setWidthLayoutCanvas(960);
						setHeightLayoutCanvas(540);
						setScale_factor_layout(0.72);
					}
					if (response.data.results[generateIndex].ratio === "insta_story") {
						setWidthLayoutCanvas(540);
						setHeightLayoutCanvas(960);
						setScale_factor_layout(0.52);
					}
					setChecked(true);
					dispatch({ type: "LOADED" });
					setInitializeParams(true);
				});
			});
	};

	const handleLogoImage = (LogoSrc) => {
		setLogoImage(LogoSrc);
	};

	const handleBackgroundImage = (BgSrc) => {
		console.log(BgSrc);
		let bg = backgroundData;
		bg.background_image["url"] = BgSrc;
		bg.background_image["imgSrc"] = BgSrc;
		setBackgroundData(bg);
	};

	const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();
	useEffect(() => {
		document.title = "MyEra | Product Canvas";
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};
		console.log("OBJECTIVE ID: ", props.objectiveId.objectiveId);
		console.log("INSIDE USEEFFECT");

		let user_logo_count = 0;
		async function getUserData() {
			fetch(`${process.env.REACT_APP_BACKEND}/get_user/`, { headers })
				.then((response) => response.json())
				.then((data) => {
					// console.log("API CALL!")
					// console.log(data)
					user_logo_count = data.user_logos.length;
					setuserLogos(data.user_logos);

					if (user_logo_count != 0) setLogoImage(data.user_logos[0].logo);

					let colors = [];

					colors.push({ value: { select_bgcolor }, label: "Default" });
					for (let color of data.brand_colors.split(",")) {
						var dict = {
							value: color.substring(0, color.length - 2),
							label: color.substring(0, color.length - 2),
						};

						colors.push(dict);
					}

					colors.push({ value: "Add More", label: "Add More" });
					setbrand_colors(colors);
				});
		}
		getUserData();

		fetch(`${process.env.REACT_APP_BACKEND}/get_backgrounds/`, { headers })
			.then((response) => response.json())
			.then((data) => {
				setUserBg(data);
			});

		const free_creatives_count = Axios({
			method: "GET",
			url: `${process.env.REACT_APP_BACKEND}/get_user_downloads/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
		setFreeCreatives(free_creatives_count.data);
		const fonts_response = Axios({
			method: "GET",
			url: `${process.env.REACT_APP_BACKEND}/get_fonts/`,
		}).then((response) => {
			setFontFamilyOptions(response.data);
		});

		let cta_id = "";
		let heor_id = "";
		Axios({
			method: "GET",
			url: `${process.env.REACT_APP_BACKEND}/get_text_suggestions/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
			.then((response) => {
				if (typeof props.ctaId.ctaId != "number")
					cta_id = response.data.default[0].groupedText.cta_id;
				else cta_id = props.ctaId.ctaId;
				if (typeof props.heroId.heroId != "number")
					cta_id = response.data.default[0].groupedText.hero_id;
				else cta_id = props.heroId.heroId;
			})
			.then((res) => {
				Axios({
					method: "post",
					url: `${process.env.REACT_APP_BACKEND}/generate_results/`,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					data: {
						objective_id: props.objectiveId.objectiveId,
						design_id: props.designId.designId,
						cta_id: props.ctaId.ctaId,
						hero_id: props.heroId.heroId,
						tags: props.hashTagsSelected.hashTagsSelected,
					},
				})
					.then((response) => {
						Axios({
							method: "post",
							url: `${process.env.REACT_APP_BACKEND}/generate_results_custom/`,
							headers: {
								Authorization: `Bearer ${accessToken}`,
							},
							data: {
								objective_id: parseInt(props.objectiveId.objectiveId),
								design_id: parseInt(props.designId.designId),
								tags: props.hashTagsSelected.hashTagsSelected,
							},
						})
							.then((custom_response) => {
								console.log(custom_response.data);
								let data = custom_response.data.results;
								data = data.concat(response.data.results);
								response.data.results = data;
							})
							.finally(() => {
								console.log("SETTING USEEFFECT VARIABLES");
								UserAssetLibrary();
								console.log("USER ASSET DATA: ", userAssets);
								setSelectedAssetState(false);

								console.log("Response: " + JSON.stringify(response));
								let currentCreative = response.data.results[0];
								let backgroundVar = {
									background_color: currentCreative.background,
									background_image: {
										url: currentCreative.shape,
										x_cord: currentCreative.shape_x,
										y_cord: currentCreative.shape_y,
										height: currentCreative.shape_height,
										width: currentCreative.shape_width,
										imgSrc: currentCreative.shape,
									},
								};
								initializeBackgroundValues(backgroundVar);

								setAssetImage(response.data.results[0].photo_url);
								if (user_logo_count == 0)
									setLogoImage(response.data.results[0].logo);
								let textDataVar = {
									text_color: createColor(currentCreative.text_color),
									text_font: currentCreative.font_name,
								};
								setTextData(textDataVar);
								setGenerateResultArray(response.data.results);
								setSelectedEditorType("editorType");
								if (
									response.data.results[generateIndex].ratio === "insta_post"
								) {
									setWidthLayoutCanvas(500);
									setHeightLayoutCanvas(500);
									setScale_factor_layout(1);
								}
								if (
									response.data.results[generateIndex].ratio ===
									"desktop_wallpaper"
								) {
									setWidthLayoutCanvas(960);
									setHeightLayoutCanvas(540);
									setScale_factor_layout(0.72);
								}
								if (
									response.data.results[generateIndex].ratio === "insta_story"
								) {
									setWidthLayoutCanvas(540);
									setHeightLayoutCanvas(960);
									setScale_factor_layout(0.52);
								}
								setChecked(true);
								dispatch({ type: "LOADED" });
								setInitializeParams(true);
							});
					})
					.catch((error) => {
						Axios({
							method: "post",
							url: `${process.env.REACT_APP_BACKEND}/generate_results_custom/`,
							headers: {
								Authorization: `Bearer ${accessToken}`,
							},
							data: {
								objective_id: parseInt(props.objectiveId.objectiveId),
								design_id: parseInt(props.designId.designId),
								tags: props.hashTagsSelected.hashTagsSelected,
							},
						})
							.then((response) => {
								console.log(response.data);
								console.log("SETTING USEEFFECT VARIABLES");
								UserAssetLibrary();
								console.log("USER ASSET DATA: ", userAssets);
								setSelectedAssetState(false);

								console.log("Response: " + JSON.stringify(response));
								let currentCreative = response.data.results[0];
								let backgroundVar = {
									background_color: currentCreative.background,
									background_image: {
										url: currentCreative.shape,
										x_cord: currentCreative.shape_x,
										y_cord: currentCreative.shape_y,
										height: currentCreative.shape_height,
										width: currentCreative.shape_width,
										imgSrc: currentCreative.shape,
									},
								};
								initializeBackgroundValues(backgroundVar);

								setAssetImage(response.data.results[0].photo_url);
								if (user_logo_count == 0)
									setLogoImage(response.data.results[0].logo);
								let textDataVar = {
									text_color: createColor(currentCreative.text_color),
									text_font: currentCreative.font_name,
								};
								setTextData(textDataVar);
								setGenerateResultArray(response.data.results);
								setSelectedEditorType("editorType");
								if (
									response.data.results[generateIndex].ratio === "insta_post"
								) {
									setWidthLayoutCanvas(500);
									setHeightLayoutCanvas(500);
									setScale_factor_layout(1);
								}
								if (
									response.data.results[generateIndex].ratio ===
									"desktop_wallpaper"
								) {
									setWidthLayoutCanvas(960);
									setHeightLayoutCanvas(540);
									setScale_factor_layout(0.72);
								}
								if (
									response.data.results[generateIndex].ratio === "insta_story"
								) {
									setWidthLayoutCanvas(540);
									setHeightLayoutCanvas(960);
									setScale_factor_layout(0.52);
								}
								setChecked(true);
								dispatch({ type: "LOADED" });
								setInitializeParams(true);
							})
							.catch((err) => {
								alert("No images found for this input");
								setErrors({ ...err, cta: "No assets found for these inputs" });
								dispatch({ type: "LOADED" });
							});
					});
			});

		getCroppedImageService.getMessage().subscribe((message) => {
			var byteString = atob(message.imageURL.split(",")[1]);
			var mimeString = message.imageURL
				.split(",")[0]
				.split(":")[1]
				.split(";")[0];
			var ab = new ArrayBuffer(byteString.length);
			var ia = new Uint8Array(ab);
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			var blob = new Blob([ab], { type: mimeString });
			var file = new File([blob], "image.png");
			setCroppedImage(file);
		});
	}, []);

	const newCampaign = async () => {
		flag = false;
		text_1_length = 0;
		dispatch({ type: "LOADING" });

		setErrors({
			objectives: "",
			design: "",
			tags: "",
			heroText: "",
			cta: "",
		});

		setTimeout(() => {
			dispatch({ type: "LOADED" });
		}, 1000);
	};

	function initializeBackgroundValues(jsonData) {
		setBackgroundData(jsonData);
		setColorbg(createColor(jsonData["background_color"]));
		setSelect_bgcolor(jsonData["background_color"]);
	}

	function dataURItoBlob(dataURI) {
		var byteString = atob(dataURI.split(",")[1]);
		var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		return new Blob([ab], { type: mimeString });
	}

	const customStylesModal = {
		content: {
			backgroundColor: "rgba(1,15,30,255)",

			borderRadius: "3vh",
		},
		overlay: {
			position: "fixed",

			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
		},
	};

	const resetAsset = async () => {
		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND}/generate_results/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			data: {
				objective_id: props.objectiveId.objectiveId,
				design_id: props.designId.designId,
				cta_id: props.ctaId.ctaId,
				hero_id: props.heroId.heroId,
				tags: props.hashTagsSelected.hashTagsSelected,
			},
		})
			.then((response) => {
				Axios({
					method: "post",
					url: `${process.env.REACT_APP_BACKEND}/generate_results_custom/`,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					data: {
						objective_id: parseInt(props.objectiveId.objectiveId),
						design_id: parseInt(props.designId.designId),
						tags: props.hashTagsSelected.hashTagsSelected,
					},
				})
					.then((custom_response) => {
						let data = custom_response.data.results;
						data = data.concat(response.data.results);
						response.data.results = data;
					})
					.finally(() => {
						UserAssetLibrary();
						setSelectedAssetState(false);
						setAssetImage(response.data.results[generateIndex].photo_url);
						setLogoImage(response.data.results[generateIndex].logo);
						setGenerateResultArray(response.data.results);
						dispatch({ type: "LOADED" });
					});
			})
			.catch((error) => {
				Axios({
					method: "post",
					url: `${process.env.REACT_APP_BACKEND}/generate_results_custom/`,
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
					data: {
						objective_id: parseInt(props.objectiveId.objectiveId),
						design_id: parseInt(props.designId.designId),
						tags: props.hashTagsSelected.hashTagsSelected,
					},
				})
					.then((response) => {
						UserAssetLibrary();
						setSelectedAssetState(false);
						setAssetImage(response.data.results[generateIndex].photo_url);
						setLogoImage(response.data.results[generateIndex].logo);
						setGenerateResultArray(response.data.results);
						dispatch({ type: "LOADED" });
					})
					.catch((err) => {
						setErrors({ ...errors, cta: "No assets found for these inputs" });
						dispatch({ type: "LOADED" });
					});
			});
	};
	const theme = createTheme({
		palette: {
			mode: "dark",
			primary: {
				main: "rgba(98, 77, 232, 1)",
			},
		},
	});
	const UserAssetLibrary = async () => {
		const assets = await Axios({
			mode: "no-cors",
			method: "GET",
			url: `${process.env.REACT_APP_BACKEND}/get_user_assets/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		var updated_data = assets.data.filter(function (asset) {
			return asset.is_background != true;
		});

		setUserAssets(updated_data);
		// First Images for each asset note
		updateUniqueImages(updated_data);
	};

	let img_handler = [
		{
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			image: "",
			id: "",
		},
	];

	let txt_handler = [
		{
			x: 0,
			y: 0,
			// width: 0,
			// height: 0,
			text: "",
			fontSize: "",
			fontFamily: "",
			fill: "",
			id: "",
		},
	];

	const [images, setImages] = useState(img_handler);
	const [texts, setTexts] = useState(txt_handler);
	// const [imagesDownload, setImagesDownload] = useState(img_handler);
	// const [textsDownload, setTextsDownload] = useState(txt_handler);
	const [selectedId, selectShape] = useState(null);
	const [check_var, setCheck_var] = useState(true);
	const [check_textvar, setCheck_textvar] = useState(true);
	const [selected, setSelected] = useState(false);

	function checkimgFunction(imgs) {
		setImages(imgs);
		console.log("Hitted");
		setCheck_var(false);
	}

	function checktxtFunction(txts) {
		setTexts(txts);
		console.log("Hitted");
		setCheck_textvar(false);
	}

	function handleChangeBrandColor(selected) {
		if (selected.label == "Default") {
			setSelectValue({ value: { select_bgcolor }, label: selected.label });
			setColorbg(createColor(select_bgcolor));
		} else if (selected.label === "Add More") {
			props.history.push("/profile");
		} else {
			setSelectValue({ value: selected.value, label: selected.label });
			setColorbg(createColor(selected.value));
		}
	}
	// function checkimgFunctionDownload(imgs){
	//   setImagesDownload(imgs);
	//     console.log("Hitted");
	//     setCheck_var(false);
	// }
	// function checktxtFunctionDownload(txts){
	//   setTextsDownload(txts);
	//     console.log("Hitted");
	//     setCheck_textvar(false);
	// }

	const stageRef = React.useRef(null);

	const handleDownload = async () => {
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();

		await Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND}/download_limit/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
			.then((res) => {
				handleExport();
				console.log("limit", res);
			})
			.catch((err) => {
				if (err.response) {
					console.log(err.response.status);
					toast.error(
						"You have exhausted the limit of downloads for this month",
						{
							position: "top-center",
							theme: "light",
							autoClose: 2500,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						}
					);
				}
			});
	};
	const handleExport = () => {
		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();

		var scale_factor = 1;
		if (scale_factor_layout === 1) scale_factor = 2.16 / scale_factor_layout;
		else scale_factor = 1.46 / scale_factor_layout;
		console.log("STAGE REF: ", stageRef);

		const uri = stageRef.current.toDataURL({ pixelRatio: scale_factor });

		var link = document.createElement("a");
		link.download = "stage.png";
		link.href = uri;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		Axios({
			method: "post",
			url: `${process.env.REACT_APP_BACKEND}/downloaded/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			data: {
				filename: generateResultArray[generateIndex].filename,
				photo_url: assetImage,
				tags: props.hashTagsSelected.hashTagsSelected,
				objective_id: props.objectiveId.objectiveId,
				design_id: props.designId.designId,
			},
		})
			.then((response) => {
				console.log("Response: ", response);
				return response;
			})
			.catch((err) => {
				console.log("Error: ", err);
				return err;
			});
		setFreeCreatives(freeCreatives);

		toast.success("Creative Downloaded", {
			position: "top-center",
			theme: "light",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const handleSaveButton = (event) => {
		var scale_factor = 1;
		if (scale_factor_layout === 1) scale_factor = 2.16 / scale_factor_layout;
		else scale_factor = 1.46 / scale_factor_layout;
		document.getElementById("save-button").innerHTML = "Saving...";
		document.getElementById("save-button").style.width = "10vh";

		const { accessToken } = isAuthenticated() ? isAuthenticated() : ApiToken();
		const dataURI = stageRef.current.toDataURL({ pixelRatio: scale_factor });
		var blob = dataURItoBlob(dataURI);
		var file = new File([blob], "image.png");
		var result_data = generateResultArray[generateIndex];
		var form_data = new FormData();
		for (var key in result_data) {
			form_data.append(key, result_data[key]);
		}
		form_data["background"] = `${colorbg["hex"]}`;
		var url = `${process.env.REACT_APP_BACKEND}/save_creative/`;

		form_data.append("thumbnail", file);

		const response = axios
			.post(url, form_data, {
				headers: {
					"content-type": "multipart/form-data",
					Authorization: `Bearer ${accessToken}`,
				},
			})
			.then((res) => {
				console.log(res.data);
				toast.success("Creative Saved", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			})
			.catch((err) => {
				console.log(err);
				toast.error("You have reached the limit of 10 creatives per month", {
					position: "top-center",
					theme: "light",
					autoClose: 2500,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			});

		setTimeout(() => {
			document.getElementById("save-button").innerHTML = "Save";
		}, 500);

		console.log("response", response);
	};

	const checkSavedLimit = (event) => {
		handleSaveButton(event);
	};

	const setCanvas = () => {
		if (generateResultArray && initializeParams) {
			// Hero Text

			// Hero Text
			let tWidth_hero = parseInt(
				generateResultArray[generateIndex].hero_text_width * scale_factor_layout
			);
			let tHeight_hero = parseInt(
				generateResultArray[generateIndex].hero_text_width * scale_factor_layout
			);
			let fontSize_hero_max = parseInt(
				generateResultArray[generateIndex].hero_font_size * scale_factor_layout
			);
			let fontSize_hero = fontSize_hero_max; // / 3;
			let font_hero = fontSize_hero + `px ${textData.text_font}`;
			let x_hero = generateResultArray[generateIndex].hero_text;
			let yCoord_hero = parseInt(
				generateResultArray[generateIndex].hero_text_y * scale_factor_layout
			);

			// while (true) {
			//   //ctx.font = fontSize + `px ${textData.text_font}`;
			//   //ctx.font = fontSize + `px ${generateResultArray[generateIndex].font_name}`;
			//   let textWidth = tWidth_hero;
			//   let textHeight = tHeight_hero;
			//   if (textHeight > tHeight_hero || textWidth > tWidth_hero || fontSize_hero > fontSize_hero_max) {
			//     break;
			//   }
			//   fontSize_hero = fontSize_hero + 1;
			// }

			let xCoord_hero = parseInt(
				generateResultArray[generateIndex].hero_text_x * scale_factor_layout
			);
			if (generateResultArray[generateIndex].is_bold) {
				if (generateResultArray[generateIndex].is_hero_italics)
					font_hero =
						"italic bold " + fontSize_hero + `px ${textData.text_font}`;
				else font_hero = "bold " + fontSize_hero + `px ${textData.text_font}`;
			}

			// CTA

			let maxFontSize_cta = parseInt(
				generateResultArray[generateIndex].cta_font_size * scale_factor_layout
			);
			let tcWidth_cta = parseInt(
				generateResultArray[generateIndex].cta_text_width * scale_factor_layout
			);
			let tcHeight_cta = parseInt(
				generateResultArray[generateIndex].cta_text_width * scale_factor_layout
			);
			let fontSize_cta = maxFontSize_cta; // / 3;
			let font_cta = fontSize_cta + `px ${textData.text_font}`;
			let x1_cta = generateResultArray[generateIndex].cta_text;

			let ctaX_cta = parseInt(
				generateResultArray[generateIndex].cta_text_x * scale_factor_layout
			);
			let ctaY_cta = parseInt(
				generateResultArray[generateIndex].cta_text_y * scale_factor_layout
			);
			if (generateResultArray[generateIndex].is_bold) {
				if (generateResultArray[generateIndex].is_hero_italics)
					font_cta = "italic bold " + fontSize_cta + `px ${textData.text_font}`;
				else font_cta = "bold " + fontSize_cta + `px ${textData.text_font}`;
			}

			// Body Text

			let maxFontSize_body = parseInt(
				generateResultArray[generateIndex].body_font_size * scale_factor_layout
			);
			let fontSize_body = maxFontSize_body; // / 3;
			let font_body = fontSize_body + `px ${textData.text_font}`;
			let x2_body1 = generateResultArray[generateIndex].body_text_1;
			let x2_body2 = generateResultArray[generateIndex].body_text_2;

			let bodyX = parseInt(
				generateResultArray[generateIndex].body_text_x * scale_factor_layout
			);
			let bodyY = parseInt(
				generateResultArray[generateIndex].body_text_y * scale_factor_layout
			);
			console.log("the bodyY is: ", bodyY);
			let bodyWidth = parseInt(
				generateResultArray[generateIndex].body_text_width * scale_factor_layout
			);
			let bodyHeight = parseInt(
				generateResultArray[generateIndex].body_text_height *
					scale_factor_layout
			);
			let bodyY2 = bodyY + bodyHeight;
			if (generateResultArray[generateIndex].is_body_italics)
				font_body =
					"italic " +
					fontSize_body +
					`px ${generateResultArray[generateIndex].font_name}`;

			//Additional Text 2 (Newly Added)

			let maxFontSize_additional = parseInt(
				generateResultArray[generateIndex].additional_font_size *
					scale_factor_layout
			);
			let fontSize_additional = maxFontSize_additional; // / 3;
			let font_additional = fontSize_additional + `px ${textData.text_font}`;
			let additional_x2 = generateResultArray[generateIndex].additional_text_1;
			let additional_bodyX = parseInt(
				generateResultArray[generateIndex].additional_text_x *
					scale_factor_layout
			);
			let additional_bodyY = parseInt(
				generateResultArray[generateIndex].additional_text_y *
					scale_factor_layout
			);
			let additional_bodyHeight = parseInt(
				generateResultArray[generateIndex].additional_text_height *
					scale_factor_layout
			);
			let additional_bodyWidth = parseInt(
				generateResultArray[generateIndex].additional_text_width *
					scale_factor_layout
			);

			if (generateResultArray[generateIndex].is_additional_italics)
				font_additional =
					"italic " +
					fontSize_additional +
					`px ${generateResultArray[generateIndex].font_name}`;
			let additional_x2_2 =
				generateResultArray[generateIndex].additional_text_2;

			let backgroundImage = "";
			if (generateResultArray[generateIndex].shape != null)
				backgroundImage = generateResultArray[generateIndex].shape;
			else backgroundImage = "";
			let img_asset = assetImage;
			let img_logo = logoImage;

			// let img1_obj = new window.Image();
			// img1_obj.src = img1;
			// img1_obj.crossOrigin = 'Anonymous';

			let backgroundImage_x =
				backgroundData.background_image.x_cord * scale_factor_layout;
			let backgroundImage_y =
				backgroundData.background_image.y_cord * scale_factor_layout;
			let backgroundImage_width =
				backgroundData.background_image.width * scale_factor_layout;
			let backgroundImage_height =
				backgroundData.background_image.height * scale_factor_layout;

			let img_asset_x =
				generateResultArray[generateIndex].photo_x * scale_factor_layout;
			let img_asset_y =
				generateResultArray[generateIndex].photo_y * scale_factor_layout;
			let img_asset_width =
				generateResultArray[generateIndex].photo_width * scale_factor_layout;
			let img_asset_height =
				generateResultArray[generateIndex].photo_height * scale_factor_layout;

			let img_logo_x =
				generateResultArray[generateIndex].logo_x * scale_factor_layout;
			let img_logo_y =
				generateResultArray[generateIndex].logo_y * scale_factor_layout;
			let img_logo_width =
				generateResultArray[generateIndex].logo_width * scale_factor_layout;
			let img_logo_height =
				generateResultArray[generateIndex].logo_height * scale_factor_layout;

			let img_use = [
				{
					x: backgroundImage_x,
					y: backgroundImage_y,
					width: backgroundImage_width,
					height: backgroundImage_height,
					image: backgroundData.background_image.url,
					id: "background",
				},
				{
					x: img_asset_x,
					y: img_asset_y,
					width: img_asset_width,
					height: img_asset_height,
					image: assetImage,
					id: "asset",
				},
				{
					x: img_logo_x,
					y: img_logo_y,
					width: img_logo_width,
					height: img_logo_height,
					image: img_logo,
					id: "logo",
				},
			];

			let text_use = [
				{
					x: xCoord_hero,
					y: yCoord_hero,
					// width: tWidth_hero,
					// height: tHeight_hero,
					text: x_hero,
					fontSize: fontSize_hero,
					fontFamily: textData.text_font,
					fill: "#" + textData.text_color["hex"],
					id: "hero",
				},
				{
					x: ctaX_cta,
					y: ctaY_cta,
					// width: tcWidth_cta,
					// height: tcHeight_cta,
					text: x1_cta,
					fontSize: fontSize_cta,
					fontFamily: textData.text_font,
					fill: "#" + textData.text_color["hex"],
					id: "CTA",
				},
				{
					x: bodyX,
					y: bodyY,
					// width: bodyWidth,
					// height: bodyHeight,
					text: x2_body1,
					fontSize: fontSize_body,
					fontFamily: textData.text_font,
					fill: "#" + textData.text_color["hex"],
					id: "body1",
				},
				{
					x: bodyX,
					y: bodyY2,
					// width: bodyWidth,
					// height: bodyHeight,
					text: x2_body2,
					fontSize: fontSize_body,
					fontFamily: textData.text_font,
					fill: "#" + textData.text_color["hex"],
					id: "body2",
				},
				{
					x: additional_bodyX,
					y: additional_bodyY,
					// width: additional_bodyWidth,
					// height: additional_bodyHeight,
					text: additional_x2,
					fontSize: fontSize_additional,
					fontFamily: textData.text_font,
					fill: "#" + textData.text_color["hex"],
					id: "additional1",
				},
				{
					x: additional_bodyX,
					y: additional_bodyY + additional_bodyHeight,
					// width: additional_bodyWidth,
					// height: additional_bodyHeight,
					text: additional_x2_2,
					fontSize: fontSize_additional,
					fontFamily: textData.text_font,
					fill: "#" + textData.text_color["hex"],
					id: "additional2",
				},
			];

			if (check_var) {
				checkimgFunction(img_use);
			}

			if (check_textvar) {
				checktxtFunction(text_use);
			}

			const checkDeselect = (e) => {
				const clickedOnEmpty = e.target === e.target.getStage();
				if (clickedOnEmpty) {
					selectShape(null);
				}
			};

			return (
				<>
					<Stage
						width={widthLayoutCanvas * scale_factor_layout}
						height={heightLayoutCanvas * scale_factor_layout}
						onMouseDown={checkDeselect}
						onTouchStart={checkDeselect}
						ref={stageRef}
					>
						<EditorBackground
							widthLayoutCanvas={widthLayoutCanvas}
							scaleFactorLayout={scale_factor_layout}
							heightLayoutCanvas={heightLayoutCanvas}
							colorbg={colorbg}
							backgroundData={backgroundData}
							selectedId={selectedId}
							onSelect={() => {
								selectShape(backgroundData.url.id);
							}}
						/>
						<Layer>
							{/* <KonvaImage x={img1_x} y={img1_y} width={img1_width} height={img1_height} image={img1_obj} id='background' onSelect={() => {selectShape('background');}} />   */}
							{images.map((image, i) => {
								return (
									<URLImage
										key={i}
										image={image}
										isSelected={image.id === selectedId}
										onSelect={() => {
											selectShape(image.id);
										}}
										onChange={(newAttrs) => {
											const imgs = images.slice();
											imgs[i] = newAttrs;
											setImages(imgs);

											setGenerateResultArray((array) => {
												let newArray = [...array];
												newArray.forEach((obj) => {
													for (var i = 0; i < imgs.length; i++) {
														if (imgs[i].id === "asset") {
															obj.photo_width =
																imgs[i].width / scale_factor_layout;
															obj.photo_height =
																imgs[i].height / scale_factor_layout;
															obj.photo_x = imgs[i].x / scale_factor_layout;
															obj.photo_y = imgs[i].y / scale_factor_layout;
														}
														if (imgs[i].id === "logo") {
															// console.log("Inside Body")
															obj.logo_width =
																imgs[i].width / scale_factor_layout;
															obj.logo_height =
																imgs[i].height / scale_factor_layout;
															obj.logo_x = imgs[i].x / scale_factor_layout;
															obj.logo_y = imgs[i].y / scale_factor_layout;
														}
													}
												});
												return newArray;
											});
										}}
									/>
								);
							})}
						</Layer>
						<Layer>
							{texts.map((textObj, i) => {
								return (
									<>
										<TextEditor
											key={i}
											x={textObj.x}
											y={textObj.y}
											fontSize={textObj.fontSize}
											fontFamily={textObj.fontFamily}
											text={textObj.text}
											colour={textObj.fill}
											onTextChange={(value) => {
												const textObjs = texts.slice();
												textObjs[i].text = value;
												// setEditHeading(textObjs[0].text)
												// setEditAddText(textObjs[2].text)
												// setEditAdd2Text(textObjs[3].text)
												// setEditBodyText(textObjs[1].text)
												// setGenerateResultArray(array => {
												//   let newArrayEmpty = [...array];
												//   newArrayEmpty.forEach(obj => {
												//     //obj.${id} = event.target.value;
												//   });
												//   return newArrayEmpty;
												// });
												// setCheck_textvar(true);
												if (textObjs[i].id === "hero") {
													setEditHeading(value);
												} else if (textObjs[i].id === "CTA") {
													setEditAddText(value);
												} else if (textObjs[i].id === "body1") {
													setEditBodyText(value);
												} else if (textObjs[i].id === "additional1") {
													setEditAdd2Text(value);
												}
												setTexts(textObjs);
												setGenerateResultArray((array) => {
													let newArray3 = [...array];
													let k;
													for (k = 0; k < newArray3.length; k++) {
														if (textObjs[i].id === "hero") {
															newArray3[k].hero_text = value;
														} else if (textObjs[i].id === "CTA") {
															newArray3[k].cta_text = value;
														} else if (textObjs[i].id === "body1") {
															newArray3[k].body_text_1 = value;
														} else if (textObjs[i].id === "additional1") {
															newArray3[k].additional_text_1 = value;
														}
													}
													return newArray3;
												});
											}}
											width={textObj.width}
											height={textObj.height}
											selected={selected}
											onTextResize={(newWidth, newHeight) => {
												const textObjs = texts.slice();
												textObjs[i].width = newWidth;
												textObjs[i].height = newHeight;
												setTexts(textObjs);
											}}
											onPositionChange={(newX, newY, index) => {
												const textObjs = texts.slice();
												textObjs[i].x = newX;
												textObjs[i].y = newY;
												setTexts(textObjs);
												setGenerateResultArray((array) => {
													let newArray3 = [...array];
													let k;
													for (k = 0; k < newArray3.length; k++) {
														newArray3[k].x = newX;
														newArray3[k].y = newY;

														if (textObjs[i].id === "hero") {
															newArray3[k].hero_text_x = newX;
															newArray3[k].hero_text_y = newY;
														} else if (textObjs[i].id === "CTA") {
															newArray3[k].cta_text_x = newX;
															newArray3[k].cta_text_y = newY;
														} else if (textObjs[i].id === "body1") {
															newArray3[k].body_text_x = newX;
															newArray3[k].body_text_y = newY;
														} else if (textObjs[i].id === "additional1") {
															newArray3[k].additional_text_x = newX;
															newArray3[k].additional_text_y = newY;
														}
													}
													// newArray3.forEach(obj => {
													//     obj.x = newX;
													//     obj.y = newY;
													//     obj.hero_text_x=newX;
													//     obj.hero_text_y=newY;
													//
													// });

													return newArray3;
												});
											}}
											onClick={() => {
												setSelected(!selected);
											}}
											onTextClick={(newSelected) => {
												setSelected(newSelected);
											}}
										/>
									</>
								);
							})}
						</Layer>
					</Stage>
				</>
			);
		} else {
			return <> </>;
		}
	};

	return (
		<>
			<div
				{...props}
				style={width >= 1287 ? customStylesModal : ""}
				className={width < 1287 ? `Modal` : ""}
			>
				<div
					style={{
						backgroundColor: "rgba(1,15,30,255)",
						width: "85%",
						height: "50%",
						background: "#414652",
						boxShadow: "0px 4px 30px 3px #030023",
						borderRadius: "36px",
						marginTop: "2%",
						marginLeft: "auto",
						marginRight: "auto",
					}}
				>
					<HeaderDiv>
						{/* <Link
                                    style={{textDecoration: "none"}}
                                    to="/campaign-journey"
                                >
                                    <button onClick={() => newCampaign()} style={{
                                        cursor: 'pointer',
                                        borderRadius: '5vh',
                                        border: 'solid rgba(255, 255, 255, 0.25) 1px',
                                        fontSize: '2vh',
                                        backgroundColor: 'rgba(11, 17, 32, 1)',
                                        color: 'white',
                                        width: '23vh',
                                        height: '6vh',
                                        display: 'flex',
                                        marginTop: '3vh',
                                        marginLeft: '4vh'
                                    }}>
                                        <div style={{margin: 'auto'}}>New Creative</div>
                                    </button>
                                </Link> */}

						<div
							style={{
								display: "flex",
								marginLeft: "80%",
								marginTop: "2vh",
							}}
						>
							<button
								id="save-button"
								onClick={(e) => checkSavedLimit(e)}
								style={{
									cursor: "pointer",
									borderRadius: "5vh",
									border: "solid #84E8F4 1px",
									fontSize: "2vh",
									backgroundColor: "#414652",
									color: "white",
									marginRight: "2vh",
									width: "5.5vh",
									height: "5vh",
								}}
							>
								<BookmarkBorderOutlinedIcon style={{ color: "#84E8F4" }} />
							</button>

							<button
								onClick={handleDownload}
								style={{
									cursor: "pointer",
									borderRadius: "100px",
									border: "none",
									fontSize: "2vh",
									backgroundColor: "#84E8F4",
									color: "white",
									marginRight: "3vh",
									width: "18vh",
									height: "5vh",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<div style={{ color: "#074850", fontSize: "16px" }}>
									Download
								</div>
								<FileDownloadOutlinedIcon
									style={{ color: "black", marginLeft: "4%" }}
								/>
							</button>
						</div>
						{/* <Link
                                    style={{textDecoration: "none"}}
                                    to="/test">
                                    <img src={close} style={{marginRight: '3vh'}} height="20px" width="20px"/>
                                </Link> */}
					</HeaderDiv>

					<BodyDiv>
						<div style={{ marginLeft: "0vh", width: "40%" }}>
							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									marginLeft: "6.5%",
								}}
							>
								<div
									id="defaultEditorButton"
									type="submit"
									style={{
										textTransform: "none",
										width: "13%",
										height: "3vh",
										color:
											selectedEditorType === "editorType"
												? "#84E8F4"
												: "#FFFFFF",
										borderBottom:
											selectedEditorType === "editorType"
												? "1px solid #84E8F4"
												: "none",
										cursor: "pointer",
									}}
									onClick={() => {
										document.getElementById("defaultEditorButton").style.color =
											"rgba(98, 77, 232, 1)";
										document.getElementById("customAssetButton").style.color =
											"rgba(255, 255, 255, 0.7)";
										document.getElementById(
											"customBackgroundButton"
										).style.color = "rgba(255, 255, 255, 0.7)";
										document.getElementById("customLogoButton").style.color =
											"rgba(255, 255, 255, 0.7)";
										setSelectedEditorType("editorType");
									}}
								>
									{/* <img width='25vh' height='25vh' src={text}
                                                 style={{marginRight: '1vh', marginBottom: '0.5vh'}}/> */}
									Text
								</div>

								<div
									id="customAssetButton"
									type="submit"
									style={{
										marginLeft: "4vh",
										textTransform: "none",
										color:
											selectedEditorType === "customAssetType"
												? "#84E8F4"
												: "#FFFFFF",
										borderBottom:
											selectedEditorType === "customAssetType"
												? "1px solid #84E8F4"
												: "none",
										width: "15%",
										height: "3vh",
									}}
									onClick={() => {
										document.getElementById("customAssetButton").style.color =
											"rgba(98, 77, 232, 1)";
										document.getElementById("defaultEditorButton").style.color =
											"rgba(255, 255, 255, 0.7)";
										document.getElementById(
											"customBackgroundButton"
										).style.color = "rgba(255, 255, 255, 0.7)";
										document.getElementById("customLogoButton").style.color =
											"rgba(255, 255, 255, 0.7)";
										setSelectedEditorType("customAssetType");
									}}
								>
									{/* <img width='30vh' height='30vh' src={upload}
                                                 style={{marginRight: '1vh', marginBottom: '0.5vh'}}/> */}
									Product
								</div>

								<div
									id="customBackgroundButton"
									type="submit"
									style={{
										marginLeft: "4vh",
										textTransform: "none",
										color:
											selectedEditorType === "customBackgroundType"
												? "#84E8F4"
												: "#FFFFFF",
										borderBottom:
											selectedEditorType === "customBackgroundType"
												? "1px solid #84E8F4"
												: "none",
										width: "15%",
										height: "3vh",
									}}
									onClick={() => {
										document.getElementById(
											"customBackgroundButton"
										).style.color = "rgba(98, 77, 232, 1)";
										document.getElementById("defaultEditorButton").style.color =
											"rgba(255, 255, 255, 0.7)";
										document.getElementById("customAssetButton").style.color =
											"rgba(255, 255, 255, 0.7)";
										document.getElementById("customLogoButton").style.color =
											"rgba(255, 255, 255, 0.7)";
										setSelectedEditorType("customBackgroundType");
									}}
								>
									{/* <img width='30vh' height='30vh' src={upload}
                                                  style={{marginRight: '1vh', marginBottom: '0.5vh'}}/> */}
									Background
								</div>

								<div
									id="customLogoButton"
									type="submit"
									style={{
										marginLeft: "4vh",
										textTransform: "none",
										color:
											selectedEditorType === "customLogoType"
												? "#84E8F4"
												: "#FFFFFF",
										borderBottom:
											selectedEditorType === "customLogoType"
												? "1px solid #84E8F4"
												: "none",
										width: "15%",
										height: "3vh",
									}}
									onClick={() => {
										document.getElementById("customLogoButton").style.color =
											"rgba(98, 77, 232, 1)";
										document.getElementById("defaultEditorButton").style.color =
											"rgba(255, 255, 255, 0.7)";
										document.getElementById(
											"customBackgroundButton"
										).style.color = "rgba(255, 255, 255, 0.7)";
										document.getElementById("customAssetButton").style.color =
											"rgba(255, 255, 255, 0.7)";
										setSelectedEditorType("customLogoType");
									}}
								>
									{/* <img width='30vh' height='30vh' src={upload}
                                                 style={{marginRight: '1vh', marginBottom: '0.5vh'}}/> */}
									Logo
								</div>
							</div>

							<div style={{ height: "60%" }}>
								{selectedEditorType === "customAssetType" ? (
									<div
										style={{
											marginTop: "5%",
											marginLeft: "4.5%",
											height: "55vh",
										}}
									>
										<div>
											<Link
												style={{ textDecoration: "none" }}
												to="/upload-library"
											>
												<button
													style={{
														cursor: "pointer",
														borderRadius: "100px",
														border: "1px solid #84E8F4",
														fontSize: "2vh",
														backgroundColor: "rgba(132, 232, 244, 0.001)",
														color: "white",
														width: "15vh",
														height: "5vh",
														display: "flex",
														marginTop: "4vh",
														marginLeft: "1.5vh",
													}}
												>
													<div style={{ margin: "auto", color: "#84E8F4" }}>
														Upload
													</div>
													<FileUploadOutlinedIcon
														style={{
															color: "#84E8F4",
															marginTop: "2px",
															marginRight: "5px",
														}}
													/>
												</button>
											</Link>
										</div>

										<div
											className="templatesScroll"
											style={{
												textAlign: "center",
												overflowY: "scroll",
												display: "flex",
												flexWrap: "wrap",
												marginTop: "2%",
												height: "38vh",
												width: "55vh",
											}}
										>
											{uniqueImages.map((nm) => (
												<img
													id={"imgAsset" + `${nm.id}`}
													src={nm.asset}
													width="27%"
													height="27%"
													style={{
														cursor: "pointer",
														margin: "3%",
														objectFit: "contain",
													}}
													onClick={() => {
														handleAssetImage(nm.asset);
														handleSelectedAsset(nm);
														// setSelectedAssetState(true);
														setAssetChosenState(true);
														setCheck_var(true);
													}}
												/>
											))}
										</div>

										<div>
											<button
												onClick={() => {
													setAssetImage(
														generateResultArray[generateIndex].photo_url
													);
													setAssetChosenState(false);
													setCheck_var(true);
													setCheck_textvar(true);
												}}
												style={{
													cursor: "pointer",
													borderRadius: "100px",
													border: "1px solid #84E8F4",
													fontSize: "2vh",
													backgroundColor: "rgba(132, 232, 244, 0.001)",
													color: "white",
													width: "12vh",
													height: "5vh",
													display: "flex",
													marginTop: "3vh",
													marginLeft: "1.5vh",
												}}
											>
												<div style={{ margin: "auto", color: "#84E8F4" }}>
													Reset
												</div>
											</button>
										</div>
									</div>
								) : selectedEditorType === "editorType" ? (
									<div
										style={{
											marginLeft: "5%",
											marginTop: "5%",
											marginBottom: "1.9%",
										}}
									>
										<div style={{}}>
											<Select
												styles={customStylesFont}
												value={selectedFontFamilyText}
												onChange={onchangeFontFamilyText}
												options={fontFamilyOptions}
												getOptionValue={(option) => option.font_name}
												getOptionLabel={(option) => option.font_name}
												placeholder={"Arial"}
												theme={(theme) => ({
													...theme,
													colors: {
														...theme.colors,
														neutral50: "#D9DADC", // Placeholder color
													},
												})}
											/>
										</div>

										<div style={{ display: "flex" }}>
											{/* <button
                                                        onClick={() => setShowEditHeading(showEditHeading => !showEditHeading)}
                                                        style={{
                                                            marginLeft: '5vh',
                                                            marginTop: '3vh',
                                                            cursor: 'pointer',
                                                            borderRadius: '100vh',
                                                            border: 'solid rgba(255, 255, 255, 0.25) 1px',
                                                            width: '7vh',
                                                            height: '7vh',
                                                            fontSize: '2.5vh',
                                                            backgroundColor: 'rgba(11, 17, 32, 1)',
                                                            color: 'white',
                                                            marginRight: '2vh'
                                                        }}>
                                                        {' '}
                                                        <img src={AddPencil} style={{
                                                            marginLeft: '0.7vh',
                                                            marginTop: '0.5vh',
                                                            width: '70%',
                                                            height: '70%',
                                                            objectFit: 'contain'
                                                        }} alt=""/>{' '}
                                                    </button> */}

											<HeadingInput
												placeholder={editHeading}
												value={editHeading}
												onChange={(event) => {
													if (event.target.value !== "") {
														setGenerateResultArray((array) => {
															let newArray2 = [...array];
															newArray2.forEach((obj) => {
																obj.hero_text = event.target.value;
															});

															return newArray2;
														});
														setCheck_textvar(true);
													} else {
														setGenerateResultArray((array) => {
															let newArrayEmpty = [...array];
															newArrayEmpty.forEach((obj) => {
																obj.hero_text = event.target.value;
															});

															return newArrayEmpty;
														});
														setCheck_textvar(true);
													}
													setEditHeading(event.target.value);
												}}
											/>
											<Select
												styles={customStylesFont}
												value={selectedFontFamilySizeHero}
												onChange={(event) => {
													if (event !== "") {
														setGenerateResultArray((array) => {
															let newArray3 = [...array];
															newArray3.forEach((obj) => {
																obj.hero_font_size = event;
															});

															return newArray3;
														});
														setCheck_textvar(true);
													} else {
														setGenerateResultArray((array) => {
															let newArrayEmpty = [...array];
															newArrayEmpty.forEach((obj) => {
																obj.hero_font_size = event;
															});

															return newArrayEmpty;
														});
														setCheck_textvar(true);
													}
													setSelectedFontFamilySizeHero(event);
												}}
												options={fontSizeOptions}
												getOptionValue={(option) => option}
												getOptionLabel={(option) => option}
												placeholder={selectedFontFamilySizeHero}
												theme={(theme) => ({
													...theme,
													colors: {
														...theme.colors,
														neutral50: "#D9DADC", // Placeholder color
													},
												})}
											/>
											{/* {showEditHeading ? (

                                                    ) : (
                                                        <div
                                                            onClick={() => setShowEditHeading(showEditHeading => !showEditHeading)}
                                                            style={{
                                                                fontSize: '2.3vh',
                                                                marginTop: '5vh',
                                                                marginLeft: '2vh'
                                                            }}> {editHeading}</div>
                                                    )} */}
										</div>

										{selectedThumbnail ? (
											<div style={{ display: "flex" }}>
												{/* <button
                                                            onClick={() => setShowEditText(showEditText => !showEditText)}
                                                            style={{
                                                                marginLeft: '5vh',
                                                                marginTop: '3vh',
                                                                cursor: 'pointer',
                                                                borderRadius: '100vh',
                                                                border: 'solid rgba(255, 255, 255, 0.25) 1px',
                                                                width: '7vh',
                                                                height: '7vh',
                                                                fontSize: '2.5vh',
                                                                backgroundColor: 'rgba(11, 17, 32, 1)',
                                                                color: 'white',
                                                                marginRight: '2vh'
                                                            }}>
                                                            {' '}
                                                            <img src={AddPencil} style={{
                                                                marginLeft: '0.7vh',
                                                                marginTop: '0.5vh',
                                                                width: '70%',
                                                                height: '70%',
                                                                objectFit: 'contain'
                                                            }} alt=""/>{' '}
                                                        </button> */}

												<HeadingInput
													placeholder={editBodyText}
													value={editBodyText}
													onKeyPress={(e) => {
														if (e.key === "Enter") {
															flag = true;
														}
													}}
													onChange={(event) => {
														if (event.target.value !== "") {
															setGenerateResultArray((array) => {
																if (
																	flag === true &&
																	event.target.selectionStart <=
																		array[generateIndex].body_text_1.length
																) {
																	flag = false;
																}
																if (!flag) {
																	let newArray = [...array];
																	text_1_length = event.target.value.length;
																	newArray.forEach((obj) => {
																		if (event.target.value.length === 0)
																			obj.body_text_1 = "";
																		else
																			obj.body_text_1 =
																				event.target.value.replace(
																					obj.body_text_2,
																					""
																				);
																	});
																	return newArray;
																}
																if (flag) {
																	let newArray = [...array];
																	var tempStr = event.target.value;
																	var tempo = tempStr.slice(text_1_length);
																	newArray.forEach((obj) => {
																		obj.body_text_2 = tempo;
																		if (
																			event.target.value.length -
																				text_1_length ==
																			0
																		) {
																			flag = false;
																			obj.body_text_2 = "";
																		}
																	});
																	return newArray;
																}
															});
															setCheck_textvar(true);
														} else {
															setGenerateResultArray((array) => {
																let newArrayEmpty = [...array];
																newArrayEmpty.forEach((obj) => {
																	obj.body_text_1 = event.target.value;
																});
																return newArrayEmpty;
															});
															setCheck_textvar(true);
														}
														setEditBodyText(event.target.value);
													}}
												/>
												<Select
													styles={customStylesFont}
													value={selectedFontFamilySizeBody}
													onChange={(event) => {
														if (event !== "") {
															setGenerateResultArray((array) => {
																let newArray3 = [...array];
																newArray3.forEach((obj) => {
																	obj.body_font_size = event;
																});

																return newArray3;
															});
															setCheck_textvar(true);
														} else {
															setGenerateResultArray((array) => {
																let newArrayEmpty = [...array];
																newArrayEmpty.forEach((obj) => {
																	obj.body_font_size = event;
																});

																return newArrayEmpty;
															});
															setCheck_textvar(true);
														}
														setSelectedFontFamilySizeBody(event);
													}}
													options={fontSizeOptions}
													getOptionValue={(option) => option}
													getOptionLabel={(option) => option}
													placeholder={selectedFontFamilySizeBody}
													theme={(theme) => ({
														...theme,
														colors: {
															...theme.colors,
															neutral50: "#D9DADC", // Placeholder color
														},
													})}
												/>
												{/* {showEditText ? (

                                                        ) : (
                                                            <div
                                                                onClick={() => setShowEditText(showEditText => !showEditText)}
                                                                style={{
                                                                    fontSize: '2.3vh',
                                                                    marginTop: '5vh',
                                                                    marginLeft: '2vh'
                                                                }}> {editBodyText}</div>
                                                        )} */}

												{/* <img src={tags2} width='20' height='20'
                                                             style={{marginTop: '5vh', marginLeft: '2vh'}}
                                                             onMouseOver={() => {
                                                                 document.getElementById('tag-text').style.visibility = 'visible';
                                                             }}
                                                             onMouseLeave={() => {
                                                                 document.getElementById('tag-text').style.visibility = 'hidden';
                                                             }}
                                                        /> */}
											</div>
										) : (
											<div></div>
										)}

										<div style={{ display: "flex" }}>
											{/* <button
                                                        onClick={() => setShowEditAdditionalText(showEditAdditionalText => !showEditAdditionalText)}
                                                        style={{
                                                            marginLeft: '5vh',
                                                            marginTop: '3vh',
                                                            cursor: 'pointer',
                                                            borderRadius: '100vh',
                                                            border: 'solid rgba(255, 255, 255, 0.25) 1px',
                                                            width: '7vh',
                                                            height: '7vh',
                                                            fontSize: '2.5vh',
                                                            backgroundColor: 'rgba(11, 17, 32, 1)',
                                                            color: 'white',
                                                            marginRight: '2vh'
                                                        }}>
                                                        {' '}
                                                        <img src={AddPencil} style={{
                                                            marginLeft: '0.7vh',
                                                            marginTop: '0.5vh',
                                                            width: '70%',
                                                            height: '70%',
                                                            objectFit: 'contain'
                                                        }} alt=""/>{' '}
                                                    </button> */}

											<HeadingInput
												placeholder={editAddText}
												value={editAddText}
												onChange={(event) => {
													if (event.target.value !== "") {
														setGenerateResultArray((array) => {
															let newArray3 = [...array];
															newArray3.forEach((obj) => {
																obj.cta_text = event.target.value;
															});

															return newArray3;
														});
														setCheck_textvar(true);
													} else {
														setGenerateResultArray((array) => {
															let newArrayEmpty = [...array];
															newArrayEmpty.forEach((obj) => {
																obj.cta_text = event.target.value;
															});

															return newArrayEmpty;
														});
														setCheck_textvar(true);
													}
													setEditAddText(event.target.value);
												}}
											/>

											<Select
												styles={customStylesFont}
												value={selectedFontFamilySize}
												onChange={(event) => {
													if (event !== "") {
														setGenerateResultArray((array) => {
															let newArray3 = [...array];
															newArray3.forEach((obj) => {
																obj.cta_font_size = event;
															});

															return newArray3;
														});
														setCheck_textvar(true);
													} else {
														setGenerateResultArray((array) => {
															let newArrayEmpty = [...array];
															newArrayEmpty.forEach((obj) => {
																obj.cta_font_size = event;
															});

															return newArrayEmpty;
														});
														setCheck_textvar(true);
													}
													setSelectedFontFamilySize(event);
												}}
												options={fontSizeOptions}
												getOptionValue={(option) => option}
												getOptionLabel={(option) => option}
												placeholder={selectedFontFamilySize}
												theme={(theme) => ({
													...theme,
													colors: {
														...theme.colors,
														neutral50: "#D9DADC", // Placeholder color
													},
												})}
											/>

											{/* {showEditAdditionalText ? (

                                                    ) : (
                                                        <div
                                                            onClick={() => setShowEditAdditionalText(showEditAdditionalText => !showEditAdditionalText)}
                                                            style={{
                                                                fontSize: '2.3vh',
                                                                marginTop: '5vh',
                                                                marginLeft: '2vh'
                                                            }}>{editAddText}</div>
                                                    )} */}
										</div>

										{selectedAdditionalThumbnail ? (
											<div style={{ display: "flex" }}>
												{/* <button
                                                            onClick={() => setShowEditAdditionalText2(showEditAdditionalText2 => !showEditAdditionalText2)}
                                                            style={{
                                                                marginLeft: '5vh',
                                                                marginTop: '3vh',
                                                                cursor: 'pointer',
                                                                borderRadius: '100vh',
                                                                border: 'solid rgba(255, 255, 255, 0.25) 1px',
                                                                width: '7vh',
                                                                height: '7vh',
                                                                fontSize: '2.5vh',
                                                                backgroundColor: 'rgba(11, 17, 32, 1)',
                                                                color: 'white',
                                                                marginRight: '2vh'
                                                            }}>
                                                            {' '}
                                                            <img src={AddPencil} style={{
                                                                marginLeft: '0.7vh',
                                                                marginTop: '0.5vh',
                                                                width: '70%',
                                                                height: '70%',
                                                                objectFit: 'contain'
                                                            }} alt=""/>{' '}
                                                        </button> */}

												<HeadingInput
													placeholder={editAdd2Text}
													value={editAdd2Text}
													onKeyPress={(e) => {
														if (e.key === "Enter") {
															flag_additional = true;
														}
													}}
													onChange={(event) => {
														if (event.target.value !== "") {
															setGenerateResultArray((array) => {
																if (
																	flag_additional === true &&
																	event.target.selectionStart <=
																		array[generateIndex].additional_text_1
																			.length
																) {
																	flag_additional = false;
																}
																if (!flag_additional) {
																	let newArray = [...array];
																	additional_text_1_length =
																		event.target.value.length;
																	newArray.forEach((obj) => {
																		if (event.target.value.length === 0)
																			obj.additional_text_1 = "";
																		else
																			obj.additional_text_1 =
																				event.target.value.replace(
																					obj.additional_text_2,
																					""
																				);
																	});
																	return newArray;
																}
																if (flag_additional) {
																	let newArray = [...array];
																	var tempStr = event.target.value;
																	var tempo = tempStr.slice(
																		additional_text_1_length
																	);
																	newArray.forEach((obj) => {
																		obj.additional_text_2 = tempo;
																		if (
																			event.target.value.length -
																				additional_text_1_length ==
																			0
																		) {
																			flag_additional = false;
																			obj.additional_text_2 = "";
																		}
																	});
																	return newArray;
																}
															});
															setCheck_textvar(true);
														} else {
															setGenerateResultArray((array) => {
																let newArrayEmpty = [...array];
																newArrayEmpty.forEach((obj) => {
																	obj.additional_text_1 = event.target.value;
																});
																return newArrayEmpty;
															});
															setCheck_textvar(true);
														}
														setEditAdd2Text(event.target.value);
													}}
												/>
												<Select
													styles={customStylesFont}
													value={selectedFontFamilySizeAdd}
													onChange={(event) => {
														if (event !== "") {
															setGenerateResultArray((array) => {
																let newArray3 = [...array];
																newArray3.forEach((obj) => {
																	obj.additional_font_size = event;
																});

																return newArray3;
															});
															setCheck_textvar(true);
														} else {
															setGenerateResultArray((array) => {
																let newArrayEmpty = [...array];
																newArrayEmpty.forEach((obj) => {
																	obj.additional_font_size = event;
																});

																return newArrayEmpty;
															});
															setCheck_textvar(true);
														}
														setSelectedFontFamilySizeAdd(event);
													}}
													options={fontSizeOptions}
													getOptionValue={(option) => option}
													getOptionLabel={(option) => option}
													placeholder={selectedFontFamilySizeAdd}
													theme={(theme) => ({
														...theme,
														colors: {
															...theme.colors,
															neutral50: "#D9DADC", // Placeholder color
														},
													})}
												/>
												{/* {showEditAdditionalText2 ? (

                                                        ) : (
                                                            <div
                                                                onClick={() => setShowEditAdditionalText2(showEditAdditionalText2 => !showEditAdditionalText2)}
                                                                style={{
                                                                    fontSize: '2.3vh',
                                                                    marginTop: '5vh',
                                                                    marginLeft: '2vh'
                                                                }}> {editAdd2Text}</div>
                                                        )} */}
												{/* <img src={tags2} width='20' height='20'
                                                             style={{marginTop: '5vh', marginLeft: '2vh'}}
                                                             onMouseOver={() => {
                                                                 document.getElementById('tag-text2').style.visibility = 'visible';
                                                             }}
                                                             onMouseLeave={() => {
                                                                 document.getElementById('tag-text2').style.visibility = 'hidden';
                                                             }}
                                                        /> */}
											</div>
										) : (
											<div></div>
										)}
									</div>
								) : selectedEditorType === "customLogoType" ? (
									<div
										style={{
											marginTop: "5%",
											marginLeft: "4.5%",
											height: "55vh",
										}}
									>
										<div>
											<div style={{ textDecoration: "none" }}>
												<button
													style={{
														cursor: "pointer",
														borderRadius: "100px",
														border: "1px solid #84E8F4",
														fontSize: "2vh",
														backgroundColor: "rgba(132, 232, 244, 0.001)",
														color: "white",
														width: "15vh",
														height: "5vh",
														display: "flex",
														marginTop: "4vh",
														marginLeft: "1.5vh",
													}}
													onClick={() => openModal()}
												>
													<div style={{ margin: "auto", color: "#84E8F4" }}>
														Upload
													</div>
													<FileUploadOutlinedIcon
														style={{
															color: "#84E8F4",
															marginTop: "2px",
															marginRight: "5px",
														}}
													/>
												</button>
											</div>
										</div>

										<Modal isOpen={modalIsOpen} style={modalStyles}>
											{/* <LinearProgress style={{display: (this.state.addingAssets ? 'block' : 'none')}}/> */}
											<div>
												<CloseIcon
													style={{
														position: "absolute",
														top: "25px",
														right: "25px",
														cursor: "pointer",
													}}
													onClick={() => closeModal()}
												></CloseIcon>
											</div>
											<br></br>
											<br></br>
											<br></br>
											<h3>Brand Logo Upload</h3>

											{/* <div style={{borderRadius: '5%', border: '1px solid rgb(255, 255, 255, 0.2)', height: '275px', width: '275px', marginLeft: '33%', display: this.state.selectedImage ? 'none' : 'block'}}>
                        <label style={{display: 'block'}}>
                        <input accept="image/*" id="contained-button-file" multiple type="file" onChange={(e) => this.onFileChange(e)} onInput={() => this.handleImageCrop()} style={{display: 'none'}}/>
                        <Button sx={{mt: '45%', textTransform: 'none'}} color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera></PhotoCamera> Upload
                        </Button>
                        </label>
                    </div> */}
											{/* <div style={{display: this.state.selectedImage ? 'flex' : 'none', justifyContent: 'center', marginLeft: '5%'}}> 
                        {Cropper}
                        <ImageCropper src = "https://quarkme-assets.s3.amazonaws.com/media/image_fZup4z7.png"></ImageCropper>
                        <Button onClick={() => this.setState({selectedImage: null})}>Choose another</Button>

                        <Cancel onClick={() => this.setState({selectedImage: null, selectedFile: null})}></Cancel>
                    </div> */}
											{Cropper}

											<br></br>
											{/* <TextField label="Asset Name" sx={{width: '30%', input: {textAlign: "center"}}} value={this.state.assetNote} onChange={(e) => this.setState({assetNote: e.target.value})}></TextField> */}
											<br></br>
											<br></br>
											<div
												style={{ display: "flex", justifyContent: "center" }}
											>
												{
													<LoadingButton
														variant="contained"
														style={{
															textTransform: "none",
															backgroundColor: "#634fe5",
															width: "30%",
															borderRadius: "20px",
															height: "50px",
															color: "white",
															fontSize: "22px",
															fontWeight: "lighter",
															marginRight: "5px",
														}}
														onClick={() => {
															addLogoFile();
															setSelectedImage(null);
															setCroppedImage(null);
														}}
													>
														Upload
													</LoadingButton>
												}
											</div>
										</Modal>
										<div
											className="templatesScroll"
											style={{
												textAlign: "center",
												overflowY: "scroll",
												display: "flex",
												flexWrap: "wrap",
												marginTop: "2%",
												height: "38vh",
												width: "55vh",
											}}
										>
											{userlogos.map((image, index) => (
												<img
													className="brand_logo_image"
													src={image.logo}
													width="27%"
													height="27%"
													style={{
														cursor: "pointer",
														marginTop: "3%",
														objectFit: "contain",
													}}
													onClick={() => {
														handleLogoImage(image.logo);
														setCheck_var(true);
													}}
												/>
											))}
										</div>
									</div>
								) : (
									<div
										style={{
											marginTop: "5%",
											marginLeft: "4.5%",
											height: "55vh",
										}}
									>
										<div>
											<div style={{ textDecoration: "none" }}>
												<Link
													style={{ textDecoration: "none" }}
													to="/upload-library"
												>
													<button
														style={{
															cursor: "pointer",
															borderRadius: "100px",
															border: "1px solid #84E8F4",
															fontSize: "2vh",
															backgroundColor: "rgba(132, 232, 244, 0.001)",
															color: "white",
															width: "15vh",
															height: "5vh",
															display: "flex",
															marginTop: "4vh",
															marginLeft: "1.5vh",
														}}
													>
														<div style={{ margin: "auto", color: "#84E8F4" }}>
															Upload
														</div>
														<FileUploadOutlinedIcon
															style={{
																color: "#84E8F4",
																marginTop: "2px",
																marginRight: "5px",
															}}
														/>
													</button>
												</Link>
											</div>
										</div>

										<div
											className="templatesScroll"
											style={{
												textAlign: "center",
												overflowY: "scroll",
												display: "flex",
												flexWrap: "wrap",
												marginTop: "2%",
												height: "38vh",
												width: "55vh",
											}}
										>
											{user_bg.map((image, index) => (
												<img
													className="brand_logo_image"
													src={image.asset}
													width="27%"
													height="27%"
													style={{
														cursor: "pointer",
														marginTop: "3%",
														objectFit: "contain",
													}}
													onClick={() => {
														handleBackgroundImage(image.asset);
														setCheck_var(true);
													}}
												/>
											))}
										</div>
										<div>
											<button
												onClick={() => {
													handleBackgroundImage(
														generateResultArray[generateIndex].shape
													);
													setCheck_var(true);
													setCheck_textvar(true);
												}}
												style={{
													cursor: "pointer",
													borderRadius: "100px",
													border: "1px solid #84E8F4",
													fontSize: "2vh",
													backgroundColor: "rgba(132, 232, 244, 0.001)",
													color: "white",
													width: "12vh",
													height: "5vh",
													display: "flex",
													marginTop: "3vh",
													marginLeft: "1.5vh",
												}}
											>
												<div style={{ margin: "auto", color: "#84E8F4" }}>
													Reset
												</div>
											</button>
										</div>
									</div>
								)}
							</div>
						</div>

						<div style={{ fontSize: "3vh", width: "60%" }}>
							<div style={{ display: "flex", marginLeft: "3.5%" }}>
								<div
									style={{
										display: "flex",
										cursor: "pointer",
										border: "none",
										fontSize: "2vh",
										color: "white",
										height: "5vh",
										// marginTop: '2.35vh',
										marginRight: "2vh",
									}}
								>
									<div style={{ color: "#D9DADC", marginRight: "5px" }}>
										Text
									</div>
									<div style={{ marginTop: "-12%" }}>
										<ColorPicker
											value={colorText}
											onChange={handleChangeColorPickerText}
											hideTextfield
										/>
									</div>
								</div>

								<div
									style={{
										display: "flex",
										cursor: "pointer",
										border: "none",
										fontSize: "2vh",
										color: "white",
										height: "5vh",
										// marginTop: '2.35vh'
									}}
								>
									<div style={{ color: "#D9DADC", marginRight: "5px" }}>
										Background
									</div>
									<div style={{ marginTop: "-8%" }}>
										<ColorPicker
											value={colorbg}
											onChange={handleChangeColorPicker}
											hideTextfield
										/>
									</div>
								</div>

								<div style={{ marginTop: "-2.5%", marginLeft: "15px" }}>
									<Select
										options={brand_colors}
										value={select_value}
										onChange={handleChangeBrandColor}
										styles={customStyles}
										placeholder="Brand Colors..."
										// isOptionDisabled={(option) => option.isdisabled} // disable an option
										theme={(theme) => ({
											...theme,
											colors: {
												...theme.colors,
												neutral50: "#D9DADC", // Placeholder color
											},
										})}
									/>
								</div>
							</div>

							<div style={{ display: "flex", marginTop: "-1%" }}>
								<div style={{ paddingTop: "32%", marginRight: "0.5%" }}>
									<ArrowBackIosIcon
										onClick={() => {
											setSelectValue({ label: "Brand Colors..." });
											setGenerateIndex((index) => {
												if (index > 0) {
													setColorbg(
														createColor(
															generateResultArray[generateIndex - 1].background
														)
													);
													setSelect_bgcolor(
														generateResultArray[generateIndex - 1].background
													);
													setColorText(
														createColor(
															generateResultArray[generateIndex - 1].text_color
														)
													);
													if (!selectedAssetState) {
														handleAssetImage(
															generateResultArray[generateIndex - 1].photo_url
														);
													}
													return index - 1;
												} else {
													setColorbg(
														createColor(
															generateResultArray[generateIndex].background
														)
													);
													setSelect_bgcolor(
														generateResultArray[generateIndex].background
													);
													setColorText(
														createColor(
															generateResultArray[generateIndex].text_color
														)
													);
													if (!selectedAssetState) {
														handleAssetImage(
															generateResultArray[generateIndex].photo_url
														);
													}
													return index;
												}
											});
											setCheck_var(true);
											setCheck_textvar(true);
										}}
										style={{ cursor: "pointer" }}
									/>
								</div>

								<div>{setCanvas()}</div>

								<div style={{ paddingTop: "32%", marginLeft: "1%" }}>
									<ArrowForwardIosIcon
										onClick={() => {
											setSelectValue({ label: "Brand Colors..." });
											setGenerateIndex((index) => {
												if (index < generateResultArray.length - 1) {
													setColorbg(
														createColor(
															generateResultArray[generateIndex + 1].background
														)
													);
													setSelect_bgcolor(
														generateResultArray[generateIndex + 1].background
													);
													setColorText(
														createColor(
															generateResultArray[generateIndex + 1].text_color
														)
													);
													if (!selectedAssetState) {
														handleAssetImage(
															generateResultArray[generateIndex + 1].photo_url
														);
													}
													return index + 1;
												} else {
													setColorbg(
														createColor(
															generateResultArray[generateIndex].background
														)
													);
													setSelect_bgcolor(
														generateResultArray[generateIndex].background
													);
													setColorText(
														createColor(
															generateResultArray[generateIndex].text_color
														)
													);
													if (!selectedAssetState) {
														handleAssetImage(
															generateResultArray[generateIndex].photo_url
														);
													}
													return index;
												}
											});
											setCheck_var(true);
											setCheck_textvar(true);
										}}
										style={{ cursor: "pointer" }}
									/>
								</div>
							</div>

							{/* <div style={{display: 'none'}}>{setCanvas_download()}</div> */}
							<div
								style={{
									display: "flex",
									pointer: "none",
									marginLeft: "3.7%",
									marginTop: "0.6%",
								}}
							>
								{/* Commenting gradient for CanvasEditor for now */}
								{/* <div style={{display: 'flex', fontSize: '2vh', color: 'white', height: '5vh', marginTop: '2.35vh', marginRight: '2vh'}}>
                <label for="solidBgCheck" style={{ marginTop: '1.2vh'}}>
                    Gradient:
                </label>
                <input
                    type="checkbox"
                    defaultChecked={checked}
                    onClick={()=>{setChecked(!checked)}}
                    style={{marginLeft: '1vh', marginTop: '2vh'}}
                  />
              </div> */}
								{/* marginLeft: '14vh' could be added below if needed */}

								<div
									style={{
										display: "flex",
										textAlign: "left",
										fontSize: "14px",
									}}
								>
									Idea {generateIndex + 1}
								</div>
							</div>
							<div style={{ textAlign: "center", marginBottom: "2vh" }}></div>
						</div>
					</BodyDiv>
				</div>

				<div className="font_preload" style={{ opacity: 0 }}>
					<span style={{ fontFamily: "Oswald" }}></span>
					<span style={{ fontFamily: "Poppins Medium" }}></span>
					<span style={{ fontFamily: "Dancing Script" }}></span>
					<span style={{ fontFamily: "Licorice" }}></span>
					<span style={{ fontFamily: "JokerMan" }}></span>
					<span style={{ fontFamily: "Comic Sans MS" }}></span>
					<span style={{ fontFamily: "Roboto" }}></span>
					<span style={{ fontFamily: "Precious" }}></span>
					<span style={{ fontFamily: "Montserrat-Bold" }}></span>
					<span style={{ fontFamily: "Lobster" }}></span>
					<span style={{ fontFamily: "Montserrat-BoldItalic" }}></span>
					<span style={{ fontFamily: "Sansation_Regular" }}></span>
					<span style={{ fontFamily: "Sansation_Light" }}></span>
					<span style={{ fontFamily: "Open Sans" }}></span>
					<span style={{ fontFamily: "Kanit" }}></span>
					<span style={{ fontFamily: "Rubik" }}></span>
					<span style={{ fontFamily: "Poppins Medium" }}></span>
					<span style={{ fontFamily: "Bebas Neue" }}></span>
					<span style={{ fontFamily: "Fjalla One" }}></span>
					<span style={{ fontFamily: "Poppins Regular" }}></span>
					<span style={{ fontFamily: "Breathing Personal Use" }}></span>
					<span style={{ fontFamily: "Times New Roman" }}></span>
					<span style={{ fontFamily: "ImperialScript-Regular" }}></span>
					<span style={{ fontFamily: "TheNautigalr" }}></span>
					<span style={{ fontFamily: "Neonderthaw-Regular" }}></span>
					<span style={{ fontFamily: "Architect's Daughter" }}></span>
					<span style={{ fontFamily: "Abril Fatface" }}></span>
					<span style={{ fontFamily: "Playfair Display" }}></span>
					<span style={{ fontFamily: "Playfair Display-Italics" }}></span>
					<span style={{ fontFamily: "Jim Nightshade" }}></span>
					<span style={{ fontFamily: "Lato" }}></span>
					<span style={{ fontFamily: "Raleway" }}></span>
					<span style={{ fontFamily: "Merriweather" }}></span>
					<span style={{ fontFamily: "Libre Baskerville" }}></span>
					<span style={{ fontFamily: "Cormorant Garamond" }}></span>
					<span style={{ fontFamily: "Viaoda Libre" }}></span>
					<span style={{ fontFamily: "VT323" }}></span>
					<span style={{ fontFamily: "Squada One" }}></span>
					<span style={{ fontFamily: "Anton" }}></span>
					<span style={{ fontFamily: "Quicksand" }}></span>
					<span style={{ fontFamily: "Belleza" }}></span>
					<span style={{ fontFamily: "Dihjautis" }}></span>
					<span style={{ fontFamily: "Aileron" }}></span>
					<span style={{ fontFamily: "Azonix" }}></span>
					<span style={{ fontFamily: "Camar" }}></span>
					<span style={{ fontFamily: "Basement Grotesque" }}></span>
					<span style={{ fontFamily: "Ilyas" }}></span>
					<span style={{ fontFamily: "Imbue" }}></span>
					<span style={{ fontFamily: "JetBrains Mono" }}></span>
					<span style={{ fontFamily: "Sora" }}></span>
					<span style={{ fontFamily: "DM Serif Display" }}></span>
					<span style={{ fontFamily: "DM Sans" }}></span>
					<span style={{ fontFamily: "Cooper Hewitt" }}></span>
					<span style={{ fontFamily: "Cooper Hewitt" }}></span>
					<span style={{ fontFamily: "Kiona" }}></span>
					<span style={{ fontFamily: "Orkney" }}></span>
					<span style={{ fontFamily: "Cormorant Upright" }}></span>
					<span style={{ fontFamily: "Shrikhand" }}></span>
					<span style={{ fontFamily: "Ikaros" }}></span>
					<span style={{ fontFamily: "Rozha One" }}></span>
					<span style={{ fontFamily: "Alegre Sans" }}></span>
					<span style={{ fontFamily: "Dolce Vita" }}></span>
					<span style={{ fontFamily: "Cinzel" }}></span>
					<span style={{ fontFamily: "Archivo Narrow" }}></span>
					<span style={{ fontFamily: "Amarante" }}></span>
					<span style={{ fontFamily: "Amarante" }}></span>
					<span style={{ fontFamily: "Roboto Condensed" }}></span>
					<span style={{ fontFamily: "Quattrocento" }}></span>
					<span style={{ fontFamily: "Italiana" }}></span>
					<span style={{ fontFamily: "Bitter" }}></span>
					<span style={{ fontFamily: "Questrial" }}></span>
					<span style={{ fontFamily: "Orbitron" }}></span>
					<span style={{ fontFamily: "Chivo" }}></span>
				</div>
			</div>
		</>
	);
}

export default ProductCanvas;
