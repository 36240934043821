import React from "react";
import styles from "./Tabs.module.css";
import sampleBox from "../assets/sample_box.jpg";

const SizeTab = ({
	innerOuter,
	setInnerOuter,
	unit,
	setUnit,
	width,
	setWidth,
	height,
	setHeight,
	length,
	setLength,
	minHeight,
	maxHeight,
	minWidth,
	maxWidth,
	minLength,
	maxLength,
	image,
}) => {
	return (
		<div className={styles.tab_container}>
			<div className={styles.title}>Input a Size</div>
			<div className={styles.subtitle}>
				If you're not sure, input a size that you think works best. We're happy
				to help in determining the perfect size for your use case.
			</div>
			<div className={styles.chips}>
				<div
					className={[
						styles.chip,
						innerOuter === "outer" && styles.selected,
					].join(" ")}
					onClick={() => setInnerOuter("outer")}
				>
					<div className={styles.chip__title}>Outer Dimensions</div>
					<div className={styles.chip__description}>
						The dimensions reflect the outside of the box
					</div>
				</div>
				<div
					className={[
						styles.chip,
						innerOuter === "inner" && styles.selected,
					].join(" ")}
					onClick={() => setInnerOuter("inner")}
				>
					<div className={styles.chip__title}>Inner Dimensions</div>
					<div className={styles.chip__description}>
						The dimensions reflect the net space inside of the box
					</div>
				</div>
			</div>
			<div className={styles.edit_section}>
				<div className={styles.inputs}>
					<div className={styles.chips}>
						<div
							className={[
								styles.chip,
								styles.center,
								unit === "inch" && styles.selected,
							].join(" ")}
							onClick={() => setUnit("inch")}
						>
							<div className={styles.chip__title}>Inches</div>
						</div>
						<div
							className={[
								styles.chip,
								styles.center,
								unit === "centimeter" && styles.selected,
							].join(" ")}
							onClick={() => setUnit("centimeter")}
						>
							<div className={styles.chip__title}>CM</div>
						</div>
					</div>
					<div className={styles.input}>
						<div className={styles.label}>
							<div className={styles.input__title}>
								<strong>Length</strong>, in {unit}
							</div>
							<div className={styles.input__description}>
								min: {minLength} max: {maxLength}
							</div>
						</div>
						<input
							placeholder="0"
							type="number"
							min={minLength}
							max={maxLength}
							value={length}
							onChange={(e) => setLength(e.target.value)}
						/>
					</div>
					<div className={styles.input}>
						<div className={styles.label}>
							<div className={styles.input__title}>
								<strong>Width</strong>, in {unit}
							</div>
							<div className={styles.input__description}>
								min: {minWidth} max: {maxWidth}
							</div>
						</div>
						<input
							placeholder="0"
							type="number"
							value={width}
							min={minWidth}
							max={maxWidth}
							onChange={(e) => setWidth(e.target.value)}
						/>
					</div>
					<div className={styles.input}>
						<div className={styles.label}>
							<div className={styles.input__title}>
								<strong>Height</strong>, in {unit}
							</div>
							<div className={styles.input__description}>
								min: {minHeight} max: {maxHeight}
							</div>
						</div>
						<input
							placeholder="0"
							type="number"
							value={height}
							min={minHeight}
							max={maxHeight}
							onChange={(e) => setHeight(e.target.value)}
						/>
					</div>
				</div>
				<div className={styles.preview}>
					<img src={image || sampleBox} alt="Sample Box" />
				</div>
			</div>
		</div>
	);
};

export default SizeTab;
