import React, { useState } from "react";
import { Image as KonvaImage, Transformer } from "react-konva";

const URLImageElement = ({
	id,
	image,
	x: initialX,
	y: initialY,
	width,
	height,
	rotation: initialRotation,
	listening,
	isSelected,
	onSelect,
	onChange,
	draggable,
	setImageRef = null,
	rotatable = false,
	...props
}) => {
	const updateElementRefs = props.updateElementRefs;
	var lastCenter = null;
	var lastDist = 0;

	initialX = initialX + width / 2;
	initialY = initialY + height / 2;

	const componentRef = React.useRef();
	const imageRef = React.useRef();
	const trRef = React.useRef();
	const [ img, setImg ] = useState();
	const [ x, setX ] = useState(initialX);
	const [ y, setY ] = useState(initialY);
	const [ transformer, setTransformer ] = useState(false);
	const [ editable, setEditable ] = useState(listening);
	const [ rotation, setRotation ] = useState(initialRotation);

	React.useEffect(() => {
		let img_var = new Image();
		img_var.src = image;
		img_var.setAttribute("crossOrigin", "anonymous");
		setImg(img_var);
	}, [ image ]);

	React.useEffect(() => {
		if (transformer) {
			// we need to attach transformer manually
			trRef.current.nodes([ imageRef.current ]);
			trRef.current.getLayer().batchDraw();
		}
	}, [ transformer ]);

	React.useEffect(() => {
		updateElementRefs(componentRef);
	}, []);

	function setTransformerState() {
		console.log(transformer);
		if (transformer) {
			setTransformer(false);
		} else {
			setTransformer(true);
		}
	}

	function getDistance(p1, p2) {
		return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
	}

	function getCenter(p1, p2) {
		return {
			x: (p1.x + p2.x) / 2,
			y: (p1.y + p2.y) / 2,
		};
	}

	function handleTouch(e) {
		e.evt.preventDefault();
		var touch1 = e.evt.touches[ 0 ];
		var touch2 = e.evt.touches[ 1 ];
		const stage = imageRef.current;
		if (stage !== null) {
			if (touch1 && touch2) {
				if (stage.isDragging()) {
					stage.stopDrag();
				}

				var p1 = {
					x: touch1.clientX,
					y: touch1.clientY,
				};
				var p2 = {
					x: touch2.clientX,
					y: touch2.clientY,
				};

				if (!lastCenter) {
					lastCenter = getCenter(p1, p2);
					return;
				}
				var newCenter = getCenter(p1, p2);

				var dist = getDistance(p1, p2);

				if (!lastDist) {
					lastDist = dist;
				}

				// local coordinates of center point
				var pointTo = {
					x: (newCenter.x - stage.x()) / stage.scaleX(),
					y: (newCenter.y - stage.y()) / stage.scaleX(),
				};

				var scale = stage.scaleX() * (dist / lastDist);

				stage.scaleX(scale);
				stage.scaleY(scale);

				// calculate new position of the stage
				var dx = newCenter.x - lastCenter.x;
				var dy = newCenter.y - lastCenter.y;

				var newPos = {
					x: newCenter.x - pointTo.x * scale + dx,
					y: newCenter.y - pointTo.y * scale + dy,
				};

				stage.position(newPos);
				stage.draw();

				lastDist = dist;
				lastCenter = newCenter;
			}
		}
	}

	function handleTouchEnd() {
		lastCenter = null;
		lastDist = 0;
	}

	return (
		<React.Fragment>
			<KonvaImage
				crossorigin="anonymous"
				onClick={ rotatable ? () => setTransformerState() : null }
				onTap={ rotatable ? () => setTransformerState() : null }
				ref={ (ref) => {
					imageRef.current = ref;
					if (setImageRef) {
						setImageRef(ref);
					}
				} }
				image={ img }
				x={ x }
				y={ y }
				width={ width }
				height={ height }
				rotation={ rotation }
				id={ id }
				draggable={ draggable }
				listening={ editable }
				offset={ { x: width / 2, y: height / 2 } }
				// hitGraphEnabled={true}
				onDragEnd={ (e) => {
					setX(e.target.x());
					setY(e.target.y());
					// onChange({
					//   image: img,
					//  width:image.width,
					//   height:image.height,
					//   id:image.id,
					//   x: e.target.x(),
					//   y: e.target.y(),
					// });
				} }
				onTransformEnd={ (e) => {
					// transformer is changing scale of the node
					// and NOT its width or height
					// but in the store we have only width and height
					// to match the data better we will reset scale on transform end
					const node = imageRef.current;
					const scaleX = node.scaleX();
					const scaleY = node.scaleY();

					// we will reset it back
					node.scaleX(1);
					node.scaleY(1);
					console.log("old x and y: " + image.x + " " + image.y);
					console.log("new x and y: " + node.x() + " " + e.target.y());
					console.log(
						"old width and height: " + image.width + " " + image.height
					);

					setX(node.x());
					setY(node.y());
					// setWidth(Math.max(5, node.width() * scaleX));
					// setHeight(Math.max(node.height() * scaleY));
					// onChange({
					//   image: img,
					//   id:image.id,
					//   x: node.x(),
					//   y: node.y(),
					//   // set minimal value
					//   width: Math.max(5, node.width() * scaleX),
					//   height: Math.max(node.height() * scaleY),
					// });
				} }
				onTouchMove={ handleTouch }
				onTouchEnd={ handleTouchEnd }
			/>
			{ transformer && (
				<>
					<Transformer
						ref={ trRef }
						boundBoxFunc={ (oldBox, newBox) => {
							// limit resize
							if (newBox.width < 5 || newBox.height < 5) {
								return oldBox;
							}
							return newBox;
						} }
					/>
				</>
			) }
		</React.Fragment>
	);
};

export default URLImageElement;
