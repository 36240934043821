import styled from "styled-components";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar"
import HowToUse from "../HowToUse/HowToUse";

const Wrapper = styled.div`
  display: flex;
  background: rgb(13,16, 22);
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;

export default function HowToUseMain(){
    return(
        <Wrapper id="outer-container">
            <Sidebar/>
            <MainDashboard>
                <Topbar/>
            <div style={{ width: "70%", marginTop: "5%", marginLeft: "15%" }}>
                <HowToUse></HowToUse>
            </div>
            </MainDashboard>
        </Wrapper>
    )
}