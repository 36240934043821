import React, { useState, useEffect } from "react";
import EditableText from "./EditableText";

function TextEditor({
  colour,
  text,
  fontSize,
  fontFamily,
  x,
  y,
  width,
  height,
  onClick,
  onTextResize,
  onTextChange,
  selected,
  onTextClick,
  onPositionChange,
  key
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [isTransforming, setIsTransforming] = useState(false);

  useEffect(() => {
    if (!selected && isEditing) {
      setIsEditing(false);
    } else if (!selected && isTransforming) {
      setIsTransforming(false);
    }
  }, [selected, isEditing, isTransforming]);

  function toggleEdit() {
    setIsEditing(!isEditing);
    onTextClick(!isEditing);
  }

  function toggleTransforming() {
    setIsTransforming(!isTransforming);
    onTextClick(!isTransforming);
  }

  return (      
      <EditableText
        perfectDrawEnabled={false}
        onClick={onClick}
        onTap={onClick}
        x={x}
        y={y}
        fontSize={fontSize}
        fontFamily={fontFamily}
        fill={colour}
        text={text}
        width={width}
        height={height}
        onResize={onTextResize}
        isEditing={isEditing}
        isTransforming={isTransforming}
        onToggleEdit={toggleEdit}
        onToggleTransform={toggleTransforming}
        onChange={onTextChange}
        onPositionChange={onPositionChange}
        key={key}
      />
  );
}
export default TextEditor;