import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import "./CampaignJourney.css";
import Image from "../../Components/Images/experience_try_on.png";
import "./CreateNow.css";

const Wrapper = styled.div`
  display: flex;
  background: #414652;
  box-shadow: 0px 4px 30px 3px #624de8;
  border-radius: 36px;
  width: 100%;
  height: 80vh;
  margin-top: 10px;
  margin-right: 2vh;
  background-image: linear-gradient(rgba(0,0,0, 0) 0%,rgba(0,0,0, 1) 90%);
`;

const BodyContainer = styled.div`
  color: rgba(255, 255, 255, 1);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 1278px) {
    width: 100%;
  }
`;

const Title = styled.div`
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 3vh;
  margin-top: 4vh;
  margin-bottom: 3vh;
  margin-right: 1vh;
  text-align: center;
  color: #c8daff;
`;

function TryOnJourney(props) {
  return (
    <div className="semi-outer-container">
      <div className="left" style={{ width: "85%" }}>
        <div>
          <div style={{ marginTop: "3vh" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Wrapper>
                <BodyContainer>
                  {
                    <>
                      <div style={{position: "relative" }}>
                        <div
                          style={{
                            fontSize: "10vh",
                            position: "absolute",
                            marginTop: "24%",
                            width: "100%",
                            textAlign: "center",
                            zIndex: "2",
                          }}
                        >
                          Coming Soon!
                        </div>
                        <div
                          style={{
                            width: "100%",
                            margin: "auto",
                            opacity: "0.2",
                            position: "absolute",
                          }}
                        >
                          <Title>Upload Your Picture</Title>
                          <div style={{margin:"5px",float: "right", width: "40%",height:"40%"}}>
                            <img className="Image"
                              style={{
                                maxWidth: "60%",
                                maxHeight: "60%",
                                marginRight: "25%",
                              }}
                              src={Image}
                            ></img>  
                            </div>
                          <p
                            style={{
                              width: "70%",
                              marginRight: "auto",
                              marginLeft: "auto",
                              marginTop: "15%",
                              fontSize: "20px",
                              color: "#D9DADC",
                            }}
                          >
                            Upload your image using the given guidelines to
                            <br></br>
                            try out the selected products. MyEra allows users...
                          </p>
                        </div>
                        <div
                        style={{
                          borderBottomRightRadius: "36px",
                          borderBottomLeftRadius: "36px",
                          position: "absolute",
                          top:"48vh",
                          width: "100%",
                          height: "30vh",
                          zIndex: "1",
                          backgroundImage: "linear-gradient(rgba(0,0,0, 0) 0%,rgba(0,0,0, 1) 90%)"
                            
                        }}
                      ></div>
                      </div>
                    </>
                  }
                </BodyContainer>
              </Wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TryOnJourney;
