import React from "react";
import { Card, Typography } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import myera_image from './myera_image.png';
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const theme = createMuiTheme({
  palette: {
    type: "dark"
  }
});

const styles = {
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      color: "#fff",
      padding: '5%'
    //   backgroundColor: "#303030"
    },
    heading: {
        textAlign: "center",
        // marginTop: "3rem",
        color: "black",
        fontWeight: "bold",
        fontFamily: "unset",
        backgroundImage: `url(${myera_image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        padding: "3rem 0",
        height: '250px',
        // width: '1000px'
    },
    cardContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: "3rem"
    },
    card: {
      width: "45%",
      margin: "0 1rem 2rem 1rem",
      // borderRadius: "10px",
      // boxShadow: "0 0 10px #fff",
      padding: "2%",
      backgroundColor: "#424242",
      cursor: "pointer"
    },
    cardTitle: {
      color: "#fff",
      fontWeight: "bold",
      marginBottom: "1rem"
    },
    cardBody: {
      color: "#fff",
      marginTop: "1rem"
    }
  };
  
  
  

export default function AppOverview() {
  return (
    <MuiThemeProvider theme={theme}>
    <div style={styles.root}>
      <div>
        <div style={styles.heading}>
          <h3>Welcome to MyEra Developer Page</h3>
          <br/><br/>
          <div>
          <Link to="/developer/create_app">
            <Button variant="contained" style={{textTransform: 'none', marginRight: '5%'}}>+ New App</Button>
          </Link>
          <Link to="/developer/my-apps">
            <Button variant="contained" style={{textTransform: 'none'}}>My Apps</Button>
          </Link>
          </div>
        </div>
        <br></br>
        <Typography>
          There are many things you can achieve through MyEra application from designing to improving marketing strategies.
          MyEra developer powers your application to use all these features through a simple integration. 

          Create your apps and start you MyEra API journey now!
        </Typography>
        <div style={styles.cardContainer}>
          <Card style={styles.card}>
            <Typography variant="h5">MyEra Create</Typography>
            <br />
            <Typography>
              Create flawless designs in matter of few clicks and stand ahead in the race of content creation!
            </Typography>
          </Card>
          <Card style={styles.card}>
            <Typography variant="h5">MyEra Create for Design</Typography>
            <br />
            <Typography>
            Providing your customers with a whole new level of product customization options!
            </Typography>
          </Card>
          <Card style={styles.card}>
            <Typography variant="h5">MyEra Publish</Typography>
            <br />
            <Typography>
              Publish content to social media though your application
            </Typography>
          </Card>
        </div>
      </div>
    </div>
    {/* <div>
      Create app
    </div> */}
    </MuiThemeProvider>
  );
}
