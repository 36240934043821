import { Button, CircularProgress } from "@mui/material";
import React, { Component, useEffect, useState} from "react";
import Modal from 'react-modal';
import './linkedin_pub.css'
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../Components/Images/myEra_NewLogo.png";
import BlueChecked from "../../Components/Images/blueChecked.png";
import { Download } from "@mui/icons-material";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { setISODay } from "date-fns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{backgroundColor: '#0274B3', borderBottom: '15px solid #0274B3'}}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon style={{color: '#E9E9E9'}} />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

export default function SwagEasyPublish() {

    const [open, setOpen] = React.useState(false);
    const [userData, setUserData] = useState([]);
    const [postCaption, setPostCaption] = useState("");
    const [access_token, setAccessToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);   
    const [appId, setAppId] = useState(''); 
    const [app, setApp] = useState();
    const [logo, setLogo] = useState();
    const [postImage, setPostImage] = useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const app_id = urlParams.get('app_id')
        const accessToken = urlParams.get('access_token')
        if (accessToken)
            setAccessToken(accessToken)
        
        if(app_id)
            setAppId(app_id)

        console.log(accessToken)
        console.log(app_id)

        const requestOptions = {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };

        fetch(`${process.env.REACT_APP_BACKEND}/get_app/${app_id}`, requestOptions)
        .then((request) => request.json())
        .then(data => {
            console.log(data)
            setLogo(data[0].logo)
            setPostCaption(data[0].post_caption)
            setPostImage(data[0].post_image)
        })

    }, [])

    const { linkedInLogin } = useLinkedIn(
        {
        
        clientId: '77l5e7nu3i10q5',
        redirectUri: 'https://myera.pro/linkedin/callback/', // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
        scope: "r_emailaddress r_liteprofile w_member_social",
        onSuccess: (code) => {
            setLoading(true);
            console.log(code);

            fetch(`${process.env.REACT_APP_BACKEND}/linkedin_connect?code=${code}&app_id=${parseInt(appId)}`, {
                headers: {Authorization: `Bearer ${access_token}`},
            })
            .then((response) => response.json())
            .then(data => {
                
                var form_data = new FormData();
                form_data.append('image', postImage)
                form_data.append('isFile', 'false')
                form_data.append('caption', postCaption)
                form_data.append('access_token', data.access_token)
                form_data.append('person_id', data.user_data.person_id)
                form_data.append('app_id', parseInt(appId))

                let requestOptions = {
                    method: 'POST',
                    headers: {Authorization: `Bearer ${access_token}`},
                    body: form_data
                }

                // console.log(data);
                setUserData(data.user_data);

                fetch(`${process.env.REACT_APP_BACKEND}/linkedin_post/`, requestOptions)
                .then((response) => response.json())
                .then(data => {
                    console.log(data)
                    toast.success(`Posted to linkedin successfully`, {
                        position: "top-center",
                        theme: "dark",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    setLoading(false);
                    setIsSuccess(true);
                    
                })
                .catch(error => {
                    toast.error(`Something went wrong, please try again later...`, {
                        position: "top-center",
                        theme: "dark",
                        autoClose: 2500,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    setLoading(false);
                })

            })
        },
        onError: (error) => {
            console.log(error);
        },
    });

    function dataURItoBlob(dataURI) {
        console.log(dataURI)
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

    function handleCaptionChange(e) {
        console.log(e.target.value);
        setPostCaption(e.target.value)
    }

    function androidOrIOS() {
        const userAgent = navigator.userAgent;
        if(/android/i.test(userAgent)){
            window.location.href = "https://www.linkedin.com/in/anirudh19/overlay/create-post"
        }
        if(/iPad|iPhone|iPod/i.test(userAgent)){
            window.location.href = "https://www.linkedin.com"
        }
    }
    
    function copyAndLaunchAndroid(){
        console.log(postCaption)
        navigator.clipboard.writeText(postCaption)
        window.location.href = "https://www.linkedin.com/in/anirudh19/overlay/create-post"
    }

    function copyAndLaunchIos(){
        console.log(postCaption)
        navigator.clipboard.writeText(postCaption)
        window.location.href = "https://www.linkedin.com"
    }

    function showToaster(message) {
        toast.success(message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }    

    return(<div class="swageazy_container">
        {(!loading && !isSuccess) && <div>
            
            <div className="heading">
                <img 
                    className="link-logo"
                    src = "https://upload.wikimedia.org/wikipedia/commons/0/01/LinkedIn_Logo.svg"
                    alt="LinkedIn logo"
                />

                <div style={{display: 'flex'}}>
                    {/* <img src={swagEasyLogo} className="swag-logo"></img> */}
                    <div style={{color: '#000000', fontWeight: '600', fontSize: '20px', marginLeft: '5px', paddingTop: '5px'}}><img src={logo} height="85px"></img></div>
                </div>
            </div>

            <div className="inner-container">

                <div style={{marginBottom: '5%', marginTop: '5%', display: 'flex', marginLeft: '7%'}}>
                    <Avatar src="/broken-image.jpg" />
                    <div style={{width: '100%', marginLeft: '5%'}}>
                        <div style={{backgroundColor: '#D9D9D9', height: '60%', width: '50%', marginBottom: '2%', borderRadius: '3px'}}></div>
                        <div style={{backgroundColor: '#D9D9D9', height: '30%', width: '30%', borderRadius: '3px'}} ></div>
                    </div>
                </div>

                <div class="text-container">
                    <textarea
                        class="textBox"
                        placeholder={postCaption}
                        value={postCaption}
                        onChange={handleCaptionChange}
                        // disabled={!this.state.isScheduledPost}
                        style={{backgroundColor: 'white', border: 'none', color: 'rgba(0, 0, 0, 0.76)', fontSize: '16px', fontFamily: 'overpass', height: '15vh', border: "1px solid lightgrey", borderRadius: "8px"}}
                    />
                </div>

                {/* <div className="download-container">
                    <Download style={{color: 'gray'}}></Download>
                    <a href="https://quarkme-assets.s3.amazonaws.com/media/image_firLztx.png" target="_blank">
                        <Button style={{textTransform: 'none', color: 'gray'}}>Download Image</Button>
                    </a>
                </div> */}

                <div class="image-container">
                    <img src={postImage} class="image__style"></img>
                </div>
                
                

                <div style={{display: 'flex', paddingLeft: '3%', marginTop: '5%'}}>

                    <div style={{display: 'flex', marginRight: '12px'}}>
                        <ThumbUpAltIcon className="insights-icon" style={{fontSize: '20px'}} />
                        <div className="insights">Like</div>
                    </div>

                    <div style={{display: 'flex', marginRight: '12px'}}>
                        <CommentIcon className="insights-icon" style={{fontSize: '20px'}} />
                        <div className="insights">Comment</div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <ShareIcon className="insights-icon" style={{fontSize: '20px'}} />
                        <div className="insights">Share</div>
                    </div>

                </div>

            </div>
            
            <br></br>

            <Button
                    style={{
                        // backgroundColor: 'rgba(98, 77, 232, 1)',
                        borderRadius: "10px",
                        fontFamily: "Montserrat",
                        fontSize: '22px',
                        fontWeight: '700',
                        width: "85%",
                        textTransform: "none",
                        height: "65px",
                        marginBottom: "3vh",
                        backgroundColor: "#0274B3",
                        color: "white",
                        // marginTop: "8%",
                        // marginLeft: "45%",
                        }}
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={linkedInLogin}
                >
                    Post on Linkedin
                </Button>

            <br></br>
            
            {/*
            <div className="inner-container">

                <br></br>

                <div className="text-container">
                    <text className="heading-2">Want to post this manually using you Linkedin App?</text>
                    <br></br>
                    <text className="info-text">Click below and follow the steps</text>
                    // <br></br><br></br>
                    <Button
                        style={{
                            // backgroundColor: 'rgba(98, 77, 232, 1)',
                            borderRadius: "10px",
                            border: '1px solid #0274B3',
                            fontFamily: "Montserrat",
                            fontSize: '15px',
                            fontWeight: '700',
                            width: "90%",
                            textTransform: "none",
                            height: "45px",
                            marginTop: "2.5vh",
                            marginBottom: "3vh",
                            backgroundColor: "#E9E9E9",
                            color: "#0274B3",
                            // marginTop: "8%",
                            // marginLeft: "45%",
                            }}
                            variant="contained"
                            color="secondary"
                            size="small"
                            // onClick={copyAndLaunch}
                            onClick={handleClickOpen}
                    >
                        Copy text & launch Linkedin
                    </Button>

                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                        
                        </BootstrapDialogTitle>
                        <DialogContent style={{backgroundColor: '#0274B3'}}>
                        
                        <div style={{maxWidth: '400px'}}>

                            <div style={{color: 'white', fontSize: '14px'}}>1. Copy Text</div>

                            <div style={{display: 'flex', backgroundColor: '#E9E9E9', borderRadius: '10px', marginTop: '3%'}}>

                                <div style={{color: 'rgba(27, 27, 27, 0.76)', fontSize: '14px', padding: '10%'}}>
                                    {postCaption}
                                </div>

                                <div style={{paddingTop: '10%', marginRight: '10%', display: 'flex'}}> 
                                    <ContentCopyIcon style={{color: '#0274B3', fontSize: '20px', cursor: 'pointer'}} 
                                        onClick={() => { navigator.clipboard.writeText(postCaption) && showToaster("Copied to Clipboard!")}}
                                    />
                                    <div style={{color: '#0274B3'}}>Copy</div>
                                </div>

                            </div>

                        </div>

                        <div style={{marginTop: '8%'}}>

                            <div style={{color: 'white', fontSize: '14px'}}>2. Download Media</div>

                            <div style={{display: 'flex', backgroundColor: '#E9E9E9', borderRadius: '10px', marginTop: '3%'}}>

                                <div style={{padding: '5%'}}>
                                    <img src={postImage} height="250px"></img>
                                </div>

                                // <div style={{paddingTop: '20%', marginRight: '10%'}}> 
                                //     <a href={swageazy__linkedin} download="swageazy.mp4" target="_blank">
                                //         <DownloadForOfflineIcon style={{color: '#0274B3', fontSize: '35px', marginLeft: '25%'}}/>
                                //     </a>
                                //     <div style={{color: '#0274B3'}}>Download</div>
                                // </div> 

                            </div>

                        </div>

                        <div style={{marginTop: '8%'}}>

                            <div style={{color: 'white', fontSize: '14px'}}>3. Launch LinkedIn</div>

                            <Button
                                style={{
                                        borderRadius: "10px",
                                        border: '1px solid #0274B3',
                                        fontFamily: "Montserrat",
                                        fontSize: '15px',
                                        fontWeight: '700',
                                        width: "100%",
                                        textTransform: "none",
                                        height: "45px",
                                        marginTop: "2.5vh",
                                        marginBottom: "3vh",
                                        backgroundColor: "#E9E9E9",
                                        color: "#0274B3",
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    onClick= {androidOrIOS}
                            >
                                Launch LinkedIn
                            </Button>

                        </div>

                        
                        </DialogContent>
                        
                    </BootstrapDialog>
                </div>

            </div>
            */}

        </div>}

        {loading && <div>
            
            <div className="heading">
                <img 
                    className="link-logo"
                    src = "https://upload.wikimedia.org/wikipedia/commons/0/01/LinkedIn_Logo.svg"
                    alt="LinkedIn logo"
                />

                <div style={{display: 'flex'}}>
                    <img src={logo} className="swag-logo"></img>
                    <div style={{color: '#000000', fontWeight: '600', fontSize: '20px', marginLeft: '5px', paddingTop: '5px'}}>Post Preview</div>
                </div>
            </div>

            <div className="inner-container">
                {/* <br></br> */}
                <div style={{marginBottom: '5%', marginTop: '5%', display: 'flex', marginLeft: '7%'}}>
                    <Avatar src="/broken-image.jpg" />
                    <div style={{width: '100%', marginLeft: '5%'}}>
                        <div style={{width: '50%', fontWeight: '700', color: 'rgba(0, 0, 0, 0.77)'}}> {userData.first_name} {userData.last_name} </div>
                        <div style={{width: '30%', borderRadius: '3px', fontWeight: '400', color: 'rgba(0, 0, 0, 0.77)', fontSize: '12px', marginLeft: '-3%'}}> Just Now </div>
                    </div>
                </div>

                <div class="text-container">
                    <textarea
                        class="textBox"
                        placeholder={postCaption}
                        value={postCaption}
                        onChange={handleCaptionChange}
                        // disabled={!this.state.isScheduledPost}
                        style={{backgroundColor: 'white', border: 'none', color: 'rgba(0, 0, 0, 0.76)', fontSize: '16px', fontFamily: 'overpass', height: '15vh'}}
                    />
                </div>

                {/* <div className="download-container">
                    <Download style={{color: 'gray'}}></Download>
                    <a href="https://quarkme-assets.s3.amazonaws.com/media/image_firLztx.png" target="_blank">
                        <Button style={{textTransform: 'none', color: 'gray'}}>Download Image</Button>
                    </a>
                </div> */}

                <div class="image-container">
                    <img src={postImage} class="image__style"></img>                
                </div>
                
                

                <div style={{display: 'flex', paddingLeft: '3%', marginTop: '5%'}}>

                    <div style={{display: 'flex', marginRight: '12px'}}>
                        <ThumbUpAltIcon className="insights-icon" style={{fontSize: '20px'}} />
                        <div className="insights">Like</div>
                    </div>

                    <div style={{display: 'flex', marginRight: '12px'}}>
                        <CommentIcon className="insights-icon" style={{fontSize: '20px'}} />
                        <div className="insights">Comment</div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <ShareIcon className="insights-icon" style={{fontSize: '20px'}} />
                        <div className="insights">Share</div>
                    </div>

                </div>

            </div>
            
            <br></br>

            <div className="inner-container">

                <div>
                    
                    <CircularProgress style={{marginTop: '5%'}}></CircularProgress>
                    
                    <br></br><br></br>
                    <h2 style={{color: '#0274B3', fontWeight: '500', fontFamily: 'Montserrat', marginTop: '-5%'}}>Posting</h2>

                </div>

                <Button
                        style={{
                                borderRadius: "10px",
                                border: '1px solid #0274B3',
                                fontFamily: "Montserrat",
                                fontSize: '15px',
                                fontWeight: '700',
                                width: "90%",
                                textTransform: "none",
                                height: "45px",
                                marginTop: "1.5vh",
                                marginBottom: "3vh",
                                backgroundColor: "#E9E9E9",
                                color: "#0274B3",
                            }}
                            variant="contained"
                            color="secondary"
                            size="small"
                            // disabled = {isCancel} 
                    >
                        Tap to cancel
                </Button>

            </div>

        </div>}
        
        {isSuccess && ( <>
        
            <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px'}}>
                <div>
                    <img src={Logo} style={{height: '40px', width: '80px', marginLeft: '-27%'}}></img>
                    <div style={{marginTop: '-20%', fontSize: '35px'}}>publish</div>
                </div>

                <img src={logo} style={{width: '200px', objectFit: 'contain'}}></img>
            </div>    

            <div className="success-container">

                <img src={BlueChecked} style={{width: '125px'}}/>
                    <br></br><br></br>
                <h1 style={{color: '#3B3B3B'}}>Post Published</h1>

                <a href="https://linkedin.com" style={{textDecoration: 'none'}}>
                    <Button
                        style={{
                                borderRadius: "10px",
                                fontFamily: "Montserrat",
                                fontSize: '22px',
                                fontWeight: '700',
                                width: "50%",
                                textTransform: "none",
                                height: "65px",
                                marginBottom: "3vh",
                                backgroundColor: "#005AB8",
                                color: "white",
                                marginTop: '5%',
                            }}
                            variant="contained"
                            color="secondary"
                            size="small"
                    >
                        Launch LinkedIn
                    </Button>
                </a>

            </div>

            <div className="swageazy__url">
                <a href="https://swageazy.com" style={{color: '#93969B', fontSize: '20px'}}>www.swageazy.com</a>
            </div>
            
            </>
        )}
                        
        </div>
    )
}

