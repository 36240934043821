import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import { updatePassword } from '../../Helper/Endpoints/Endpoints';
import styled from 'styled-components';

const InputWrapper = styled.div`
 margin-bottom: 4vh;
 font-family: 'Open Sans', sans-serif;
`;

const Input = styled.input`
 font-family: 'Montserrat', sans-serif;
 font-weight: 400;
 width: 100%;
 padding: 12px 20px;
 margin: 8px 0;
 display: inline-block;
 border: none;
 border-radius: 4px;
 box-sizing: border-box;
 background-color: rgba(40, 43, 48, 0.5);
 color: rgba(255, 255, 255, 1);
 outline: none;
`;

const InputLabel = styled.div`
 color: rgba(255, 255, 255, 0.35);
 font-family: 'Montserrat', sans-serif;
 font-weight: 400;
 text-align: left;
 font-weight: 100;
`;

const ButtonWrapper = styled.div`
 margin-top: 6vh;
`;

const MobileNav = styled.div`
 display: flex;
 color: white;
 background-color: rgba(1, 15, 30, 255);
 height: 8vh;
 justify-content: space-between;
 border-bottom: solid rgba(241, 241, 241, 0.07);
 text-align: center;
`;

const validationSchema = Yup.object({
  otp: Yup.string()
});

export default function OTP(props) {
 
  const email = props.history.location.state.email;
 
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('red');

  const formik = useFormik({
    initialValues: {
      otp: '',
      email: email,
      password: '',
      confirmPassword: ''
    },
    validationSchema,
    onSubmit: async ({ otp, email, password, confirmPassword }) => {
      console.log(otp);
      const response = await updatePassword({ otp, email, password, confirm_password: confirmPassword });
      if (response.message === 'Password updated') {
        setMessageColor('green');
      }
      setMessage(response.message);
      console.log(response);
    }
  });

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ margin: 'auto', width: '50%' }}>

        <InputLabel>Email*</InputLabel>
        <InputWrapper>
          <Input id="email" value={formik.values.email} onChange={formik.handleChange} type="text"/>
        </InputWrapper>
        <InputLabel>OTP*</InputLabel>
        <InputWrapper>
          <Input id="otp" value={formik.values.otp} onChange={formik.handleChange} type="text" />
        </InputWrapper>
        <InputLabel>Password*</InputLabel>
        <InputWrapper>
          <Input id="password" value={formik.values.password} onChange={formik.handleChange} type="password" autoComplete="new-password"/>
        </InputWrapper>
        <InputLabel>Confirm Password*</InputLabel>
        <InputWrapper>
          <Input id="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange} type="password" />
        </InputWrapper>

        {message && (
          <div style={{ color: 'green', marginBottom: '3vh', display: 'flex' }}>
            <div style={{ marginRight: '2vh', textDecoration: 'none', color: 'red' }}>{message}</div>
            {message === 'Password updated' && <Link to="/login">Login Here</Link>}
          </div>
        )}

        <ButtonWrapper>
          <Button
            style={{
              backgroundColor: 'rgba(108, 77, 232, 1)',
              fontFamily: 'Open Sans',
              borderRadius: '5vh',
              textTransform: 'none',
              height: '5vh'
            }}
            variant="contained"
            color="secondary"
            type="button"
            fullWidth={true}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </div>
    </div>
  );
}
