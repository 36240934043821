import React, { useRef, useEffect } from "react";
import { Text, Transformer } from "react-konva";

function ResizableText({
  x,
  y,
  fontSize,
  fontFamily,
  fill,
  text,
  isSelected,
  width,
  onResize,
  onClick,
  onDoubleClick,
  onPositionChange,
  key
}) {
  const textRef = useRef(null);
  const transformerRef = useRef(null);

  useEffect(() => {
    if (isSelected && transformerRef.current !== null) {
      transformerRef.current.nodes([textRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  function handleResize() {
    if (textRef.current !== null) {
      const textNode = textRef.current;
      const newWidth = textNode.width() * textNode.scaleX();
      const newHeight = textNode.height() * textNode.scaleY();
      textNode.setAttrs({
        width: newWidth,
        scaleX: 1
      });
      onResize(newWidth, newHeight);
    }
  }

  const transformer = isSelected ? (
    <Transformer
    ref={transformerRef}
    boundBoxFunc={(oldBox, newBox) => {
      // limit resize
      if (newBox.width < 5 || newBox.height < 5) {
        return oldBox;
      }
      return newBox;
    }}
    />
  ) : null;

  return (
    <>
      <Text
        x={x}
        y={y}
        ref={textRef}
        text={text}
        fontSize={fontSize}
        fontFamily={fontFamily}
        fill={fill}
        perfectDrawEnabled={false}
        onTransform={handleResize}
        onClick={onClick}
        onTap={onClick}
        onDblClick={onDoubleClick}
        onDblTap={onDoubleClick}
        width={width}
        draggable
          onDragEnd={(e) => {
            // debugger;
            console.log(e.target.x())
            onPositionChange(
               e.target.x(), e.target.y(),{key});
          }}
      />
      {transformer}
    </>
  );
}
export default ResizableText;