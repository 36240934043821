import React, {
	useRef,
	useState,
	forwardRef,
	useImperativeHandle,
} from "react";
import styles from "./HorizontalCarousel.module.css";
import { ChevronLeftRounded, ChevronRightRounded } from "@material-ui/icons";

const HorizontalCarousel = forwardRef(({ children, ...props }) => {
	const carouselRef = useRef(null);

	const [ currIndex, setStateCurrIndex ] = useState(0);

	const carouselLength = children[ 1 ].length + children[ 0 ].length - 1;

	React.useEffect(() => {
		props.updateCarouselRef(carouselRef);

		const r = document.querySelector(":root");
		r.style.setProperty(
			"--carousel-flex-direction",
			props.invertCanvasView ? "row" : "row-reverse"
		);
		r.style.setProperty(
			"--carousel-margin-right",
			props.invertCanvasView ? "40px" : "0px"
		);
		r.style.setProperty(
			"--carousel-margin-left",
			props.invertCanvasView ? "0px" : "40px"
		);
	}, [ props.invertCanvasView, props.updateCarouselRef ]);

	const setCurrIndex = (index) => {
		setStateCurrIndex(index);

		if (props.showVerticalCarousel) {
			document.getElementById("vertical_preview_" + index).scrollIntoView({
				behavior: "smooth",
				block: "nearest",
				inline: "center",
			});
		}
	};

	useImperativeHandle(carouselRef, () => ({
		resetIndex(index = 0) {
			setCurrIndex(index);
		},

		gotoNext() {
			scrollRight();
		},

		gotoPrev() {
			scrollLeft();
		},
	}));

	const scrollLeft = () => {
		if (currIndex > 0) {
			setCurrIndex(currIndex - 1);
		}
	};

	const scrollRight = () => {
		if (currIndex < carouselLength) {
			setCurrIndex(currIndex + 1);
		}
	};

	return (
		<div className={ styles.carousel_container }>
			{ props.showVerticalCarousel && (
				<div className={ styles.vertical_preview }>
					{ children[ 0 ].map((child, index) => (
						<div
							style={ {
								transform: "scale(0.16)",
								transformOrigin: window.matchMedia("(min-width: 768px)").matches
									? "top"
									: "left",
								marginTop: "0",
								marginBottom: window.matchMedia("(max-width: 768px)").matches
									? "0"
									: "-420px",
								marginLeft: "0",
								marginRight: window.matchMedia("(max-width: 768px)").matches
									? "-420px"
									: "0",
								border: currIndex === index ? "2px solid #020441" : "",
								width: "504px",
								height: "504px",
							} }
							id={ `vertical_preview_${index}` }
							onClick={ () => {
								setCurrIndex(index);
							} }
						>
							{ child }
						</div>
					)) }
					{ children[ 1 ].map((child, index) => {
						return (
							<div
								key={ index }
								id={ "vertical_preview_" + (index + children[ 0 ].length) }
								className={
									styles.preview_item +
									" " +
									(currIndex === index + children[ 0 ].length
										? styles.selected
										: "")
								}
								onClick={ () => {
									setCurrIndex(index + children[ 0 ].length);
								} }
							>
								{ child }
							</div>
						);
					}) }
				</div>
			) }

			<div className={ styles.horizontal_carousel }>
				<div
					ref={ carouselRef }
					className={ styles.carousel_content }
					style={ {
						transform: `translateX(-${currIndex * 100}%)`,
					} }
				>
					{ children }
				</div>
				{ carouselLength > 0 && <button className={ styles.carousel_nav_left } onClick={ scrollLeft }>
					<ChevronLeftRounded color="white" />
				</button> }
				{ carouselLength > 0 && <button className={ styles.carousel_nav_right } onClick={ scrollRight }>
					<ChevronRightRounded color="white" />
				</button> }
			</div>
		</div>
	);
});

export default HorizontalCarousel;
