import React from "react";
import styles from "./Tabs.module.css";
import { FileOpenRounded } from "@mui/icons-material";
import { DeleteRounded, InsertDriveFileRounded } from "@material-ui/icons";

const AdditionalNotesTab = ({
	nickname,
	setNickname,
	comments,
	setComments,
	assets,
	setAssets,
}) => {
	return (
		<div className={styles.tab_container}>
			<div className={styles.title}>Additional Instructions</div>
			<div className={styles.subtitle}>
				Additional information about this item based on the product and design,
				and any supporting assets (artwork, concept images, sketches, dielines)
				you want to upload?
			</div>
			<div className={styles.input_group}>
				<div className={styles.input_label}>Nickname</div>
				<input
					type="text"
					className={styles.input}
					value={nickname}
					onChange={(e) => setNickname(e.target.value)}
				/>
			</div>
			<div className={styles.input_group}>
				<div className={styles.input_label}>Comments</div>
				<textarea
					className={styles.input}
					value={comments}
					onChange={(e) => setComments(e.target.value)}
				/>
			</div>
			<div className={styles.input_group}>
				<div className={styles.input_label}>Assets</div>
				<input
					type="file"
					className={styles.input}
					multiple
					onChange={(e) => {
						console.log([...e.target.files]);
						setAssets([...e.target.files]);
					}}
				/>

				<div className={styles.assets}>
					{assets.map((asset) => (
						<div className={styles.asset}>
							<InsertDriveFileRounded className={styles.asset_icon} />
							<div className={styles.asset_name}>{asset.name}</div>

							<div className={styles.asset_size}>
								{Math.round(asset.size / 1024)}KB
							</div>
							<DeleteRounded className={styles.asset_icon} />
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default AdditionalNotesTab;
