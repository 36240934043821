import React, {
	useState,
	useEffect,
	useRef,
	forwardRef,
	useImperativeHandle,
} from "react";
import {
	Typography,
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "./product_create.module.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ImageUploadModal from "../../Common/ImageUploadModal";
import Axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

const ElementSelection = forwardRef((props) => {
	const productId = props.productId;
	const foldersProp = props.foldersProp;
	const primaryColor = props.primaryColor;
	const onTextChange = props.onTextChange;
	const onImageSelect = props.onImageSelect;
	const onFontSizeChange = props.onFontSizeChange;
	const onFontFamilyChange = props.onFontFamilyChange;
	const onColorChange = props.onColorChange;
	const updateElementSelectionRef = props.updateElementSelectionRef;
	const updateSelectedTabType = props.updateSelectedTabType;
	const accessToken = props.accessToken;
	const textCharLimit = props.textCharLimit;
	const restrictCharLimit = props.restrictCharLimit;
	const upladBgRemoval = props.upladBgRemoval;
	const cameraBgRemoval = props.cameraBgRemoval;
	const allowCameraUploads = props.allowCameraUploads;
	const showGuidelines = props.showGuidelines;
	const faqs = props.faqs;
	const updateFontOptions = props.updateFontOptions;
	const contentsFont = props.contentsFont;
	const contentFontSizes = props.contentFontSizes;
	const textLabel = props.textLabel;
	const instructions = props.instructions;
	const colorTitle = props.colorTitle;

	const [folders, setFolders] = useState(foldersProp);
	const [selectedTab, setSelectedTab] = useState(props.defaultTab);
	const [selectedFolder, setSelectedFolder] = useState(
		folders[props.defaultTab]
	);
	const [uploadedImages, setUploadedImages] = useState({});
	const [selectedURL, setSelectedURL] = useState();
	const [textTabs, setTextTabs] = useState(props.textTabsProp);
	const [selectedTabType, setSelectedTabType] = useState(
		Object.keys(foldersProp).length === 0 ? "text" : "image"
	);
	const [selectedTextTab, setSelectedTextTab] = useState(
		Object.keys(foldersProp).length === 0 ? props.textTabsProp[0] : null
	);
	const [selectedTabText, setSelectedTabText] = useState("");
	const [OrgFontSize, setOrgFontSize] = useState(props.fontSize);
	const [fontSize, setFontSize] = useState(props.fontSize);
	const [fontFamilyOptions, setFontFamilyOptions] = useState([]);
	const [selectedFontFamilyText, setSelectedFontFamilyText] =
		useState("Montserrat");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedUploadFile, setSelectedUploadFile] = useState();
	const [fontColors, setFontColors] = useState([
		"#000000",
		"#808080",
		"#FFFF00",
		"#FF0000",
		"#0000FF",
	]);
	const [selectedColor, setSelectedColor] = useState("#000000");
	const [selectedFont, setSelectedFont] = useState();
	const [isFontEditable, setIsFontEditable] = useState(false);
	const [isFontSizeEditable, setIsFontSizeEditable] = useState(false);
	const [isFontColorEditable, setIsFontColorEditable] = useState(false);
	const [selectColorInitial, setSelectColorInitial] = useState(false);
	const [imageType, setImageType] = useState("defaut");

	const componentRef = React.useRef();
	const textRef = useRef(null);
	const hiddenFileInput = React.useRef(null);

	const theme = createTheme({
		palette: {
			mode: "light",
			primary: {
				main: primaryColor,
			},
		},

		typography: {
			fontFamily: contentsFont,
			fontSize: 14,
		},
	});

	const modalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			transform: "translate(-50%, -50%)",
			backgroundColor: "rgba(1,15,30,255)",
			borderRadius: "3vh",
			textAlign: "center",
			height: "100%",
			width: "100%",
			color: "black",
			padding: "0%",
		},

		overlay: {
			background: "rgba(0, 0, 0, 0.25)",
		},
	};

	const useStyles = makeStyles((theme) => ({
		helperText: {
			marginLeft: 0,
		},
	}));

	const classes = useStyles();

	useImperativeHandle(componentRef, () => ({
		getSelectedUploadImage() {
			return selectedUploadFile;
		},

		getSelectedFolder() {
			return selectedTab;
		},

		getTextDetails() {
			return {
				text: selectedTabText,
				fontSize: fontSize,
				fontFamily: selectedFontFamilyText,
				color: selectedColor,
			};
		},
	}));

	useEffect(() => {
		const fonts_response = Axios({
			method: "GET",
			url: `${process.env.REACT_APP_BACKEND}/get_product_fonts_and_colors/${productId}/`,
			headers: { Authorization: `Bearer ${accessToken}` },
		}).then((response) => {
			// console.log(response.data)
			setFontFamilyOptions(response.data.fonts);
			setFontColors(response.data.colors);
			setIsFontEditable(response.data.is_font_editable);
			setIsFontColorEditable(response.data.is_font_color_editale);
			setIsFontSizeEditable(response.data.is_font_size_editable);
			updateFontOptions(
				response.data.is_font_color_editale,
				response.data.is_font_editable
			);
		});

		updateElementSelectionRef(componentRef);
	}, []);

	function selectImage(url, file) {
		// if(!url)
		// 	url = URL.createObjectURL(file)

		// setElements((prev) => {
		// 	for (let ele of prev) {
		// 		console.log(ele.folder, parseInt(selectedTab.split(" ")[0]));
		// 		if (ele.folder === parseInt(selectedTab.split(" ")[0])) ele.image = url;
		// 	}

		// 	return prev;
		// });
		setSelectedURL(url);
		if (file) {
			setSelectedUploadFile(file);
		}
	}

	const onchangeFontFamilyText = (item) => {
		setSelectedFontFamilyText(item.target.value);
		onFontFamilyChange(item.target.value, selectedTextTab);
	};

	function updateSelectedColor(color) {
		onColorChange(color, selectedTextTab);
	}

	function updateFontSize(size) {
		setFontSize(size);
		onFontSizeChange(size, selectedTextTab);
	}

	function updateSelectedText(text) {
		setSelectedTabText(text);
		onTextChange(text, selectedTextTab, textCharLimit, restrictCharLimit);
	}

	function camelCase(str) {
		// Using replace method with regEx
		return str
			.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
				return index === 0 ? word.toLowerCase() : word.toUpperCase();
			})
			.replace(/\s+/g, "");
	}

	function handleTextInput(e) {
		let regex = "a-zA-Z";
		if (selectedTextTab.allow_numbers) {
			regex += "1-9";
		}
		if (selectedTextTab.allow_spaces) {
			regex += " ";
		}
		if (selectedTextTab.allow_special_chars) {
			regex += "!@#$%^&*-_";
		}
		regex = "^[" + regex + "]+$";
		const TEXT_REGEX = new RegExp(regex);
		const value = e.target.value;
		if (value !== "" && !TEXT_REGEX.test(value)) {
			return;
		}

		if (selectedTextTab.text_event === "Capitalize")
			updateSelectedText(e.target.value.toUpperCase());
		else if (selectedTextTab.text_event === "Lowercase")
			updateSelectedText(e.target.value.toLowerCase());
		else if (selectedTextTab.text_event === "Camelcase")
			updateSelectedText(camelCase(e.target.value));
		else {
			updateSelectedText(e.target.value);
		}
	}

	return (
		<ThemeProvider theme={theme}>
			<div className={styles.inputBox}>
				<div className={styles.elementsBox}>
					<div className={styles.tabs}>
						{folders ? (
							Object.keys(folders).map((folder) => {
								return (
									<div
										onClick={() => {
											setSelectedTab(folder);
											props.onTabChanged(folders[folder]);
											setSelectedFolder(folders[folder]);
											setSelectedTabType("image");
											updateSelectedTabType();
										}}
										className={
											selectedTab === folder && selectedTabType === "image"
												? styles.selectedTab
												: null
										}
									>
										<div className={styles.folderName}>
											<Typography
												sx={{
													fontSize: contentFontSizes.tab,
													fontWeight:
														selectedTabType === "image" &&
														selectedTab === folder
															? 700
															: null,
												}}
											>
												{folder.split(" ")[1]}
											</Typography>
										</div>
									</div>
								);
							})
						) : (
							<></>
						)}
						{textTabs ? (
							textTabs.map((textObj) => {
								return (
									<div
										onClick={() => {
											setSelectedTextTab(textObj);
											setSelectedTabType("text");
											updateSelectedTabType();
										}}
										className={
											selectedTextTab === textObj && selectedTabType === "text"
												? styles.selectedTab
												: null
										}
									>
										<div className={styles.folderName}>
											<Typography
												sx={{
													fontSize: contentFontSizes.tab,
													fontWeight:
														Object.keys(folders).length != 0 &&
														selectedTabType === "text" &&
														selectedTextTab === textObj
															? 700
															: null,
												}}
											>
												{textObj.name}
											</Typography>
										</div>
									</div>
								);
							})
						) : (
							<></>
						)}
					</div>
					<div className={styles.description_type}>
						<Typography
							sx={{
								fontSize: contentFontSizes.content,
								fontWeight: 500,
								textAlign: "left",
								color: "#5A5A5A",
							}}
						>
							{props.productDescription}
						</Typography>
					</div>
					{folders && folders[selectedTab] && selectedTabType === "image" ? (
						<div className={styles.results_div}>
							<div>
								<Typography>
									{folders[selectedTab].is_uploadable
										? (folders[selectedTab]?.assets?.length || 0) +
										  (uploadedImages[selectedTab]?.length || 0)
										: folders[selectedTab]?.assets?.length || 0}
									{/* {(folders[selectedTab]?.assets?.length || 0) +
								(uploadedImages?.length || 0)}{" "}   */}
									{/* this above commented code is replaced with its above code */}
									{"  "} Results
								</Typography>
							</div>
							<label
								style={{
									display:
										folders[selectedTab].is_uploadable &&
										(folders[selectedTab].assets.length != 0 ||
											uploadedImages[selectedTab])
											? "block"
											: "none",
									fontFamily: "inherit",
								}}
								for="file-input"
							>
								<input
									type="file"
									id="file-input"
									onChange={(e) => {
										// setUploadedImages(Object.values(e.target.files));
										// selectImage(URL.createObjectURL(e.target.files[0]), e.target.files[0])
									}}
									multiple
									style={{ display: "none" }}
									ref={hiddenFileInput}
								/>
								<Button
									// variant="outlined"
									sx={{
										color: "#020441",
										display: "flex",
										justifyContent: "center",
										border: "3px solid #020441",
										fontFamily: "inherit",
										letterSpacing: "inherit",
									}}
									onClick={() => {
										setIsModalOpen(true);
									}}
								>
									<div style={{ margin: "auto" }}>Upload</div>

									<FileUploadOutlinedIcon
										style={{
											marginTop: "-2px",
											marginLeft: "5px",
										}}
									/>
								</Button>
							</label>
						</div>
					) : (
						<></>
					)}

					{selectedTabType === "image" ? (
						<div className={styles.inputs}>
							{!uploadedImages[selectedTab] &&
								folders[selectedTab].assets.length === 0 && (
									<div className={styles.uploadBox}>
										<label style={{ fontFamily: "inherit" }} for="file-input">
											<input
												type="file"
												id="file-input"
												multiple
												style={{ display: "none" }}
												ref={hiddenFileInput}
											/>
											<Button
												variant="text"
												onClick={() => {
													setIsModalOpen(true);
												}}
											>
												<div style={{ margin: "auto" }}>Upload Image</div>

												<FileUploadOutlinedIcon
													style={{
														marginTop: "-2px",
														marginLeft: "5px",
													}}
												/>
											</Button>
										</label>
									</div>
								)}
							{uploadedImages[selectedTab] &&
								folders[selectedTab].is_uploadable && (
									<div className={styles.image_list}>
										{uploadedImages[selectedTab].map((file) => {
											return (
												<img
													className={
														styles.imageStyle +
														(file === selectedUploadFile
															? " " + styles.selected
															: "")
													}
													// style={
													// 	dial === 0 ? { border: "2px solid white", borderRadius: "3vh" } : {}
													// }
													src={URL.createObjectURL(file)}
													onClick={() => {
														selectImage(URL.createObjectURL(file), file);
														onImageSelect(
															0,
															URL.createObjectURL(file),
															selectedTab,
															"uploaded"
														);
														setImageType("uploaded");
														setFolders((folders) => {
															const newFolders = { ...folders };
															newFolders[selectedTab].imageType = "uploaded";
															return newFolders;
														});
													}}
												></img>
											);
										})}
									</div>
								)}
							{uploadedImages[selectedTab] &&
								folders[selectedTab].is_uploadable && (
									<div className={styles.divider_style}></div>
								)}
							<div className={styles.image_list}>
								{folders && selectedTab ? (
									folders[selectedTab].assets.map((asset_obj) => {
										let id = asset_obj.asset.id;
										let url = asset_obj.asset.url;
										let thumbnail_url = asset_obj.thumbnail;
										return (
											<img
												className={
													styles.imageStyle +
													(url === selectedURL ? " " + styles.selected : "")
												}
												// style={
												// 	dial === 0 ? { border: "2px solid white", borderRadius: "3vh" } : {}
												// }
												src={thumbnail_url ? thumbnail_url : url}
												onClick={() => {
													selectImage(url, null);
													onImageSelect(id, url, selectedTab, "default", {
														variant_name: asset_obj.asset.variant_name,
														variant_id: asset_obj.asset.variant_id,
													});
													setImageType("default");
													setFolders((folders) => {
														const newFolders = { ...folders };
														newFolders[selectedTab].imageType = "default";
														return newFolders;
													});
												}}
											></img>
										);
									})
								) : (
									<></>
								)}
							</div>
						</div>
					) : (
						<div className={styles.textInputs}>
							<br />
							<div style={{ marginBottom: "6%", display: "flex" }}>
								<div>
									<TextField
										type="text"
										label={textLabel ? textLabel : "Edit text"}
										value={selectedTabText}
										onChange={(e) => handleTextInput(e)}
										FormHelperTextProps={{
											className: classes.helperText,
										}}
										// helperText={`Character limit: ${textCharLimit}`}
										// focused
										sx={{
											input: {
												color: modalStyles.content.color,
												fontSize: "16px",
											},
											label: {
												fontSize: "16px",
											},
											fieldset: {
												fontSize: "16px",
											},
										}}
										inputProps={{ maxLength: selectedTextTab.char_limit }}
									></TextField>
									{/* <div style={{color: "gray"}}> */}
									<Typography
										align="left"
										sx={{ color: "gray", fontSize: "8px", marginTop: "2%" }}
									>
										{restrictCharLimit
											? `Characters required: ${textCharLimit}`
											: `Character limit: ${textCharLimit}`}
										{/* </div> */}
									</Typography>
								</div>
								{isFontSizeEditable ? (
									<FormControl style={{ marginLeft: "10%" }}>
										<InputLabel
											sx={{
												fontSize: "16px",
											}}
										>
											Font size
										</InputLabel>
										<Select
											value={fontSize}
											onChange={(e) => updateFontSize(e.target.value)}
											label="Select Font"
											defaultValue={fontSize}
											sx={{
												fontSize: "16px",
											}}
										>
											<MenuItem
												sx={{ fontSize: "16px" }}
												value={OrgFontSize - 4}
											>
												x-small
											</MenuItem>
											<MenuItem
												sx={{ fontSize: "16px" }}
												value={OrgFontSize - 2}
											>
												small
											</MenuItem>
											<MenuItem sx={{ fontSize: "16px" }} value={OrgFontSize}>
												medium
											</MenuItem>
											<MenuItem
												sx={{ fontSize: "16px" }}
												value={OrgFontSize + 2}
											>
												large
											</MenuItem>
											<MenuItem
												sx={{ fontSize: "16px" }}
												value={OrgFontSize + 4}
											>
												x-large
											</MenuItem>
										</Select>
									</FormControl>
								) : (
									<></>
								)}
							</div>
							<hr></hr>
							{/* <br /> */}
							{isFontEditable ? (
								<div>
									<div style={{ textAlign: "left" }}>
										<Typography
											sx={{
												fontSize: contentFontSizes.content,
												fontWeight: 700,
											}}
										>
											Choose a font
										</Typography>
									</div>
									<div style={{ display: "flex", flexWrap: "wrap" }}>
										{fontFamilyOptions.map((font) => {
											return (
												<div
													style={{
														padding: "3%",
														border:
															selectedFont === font.font_name
																? `2px solid ${primaryColor}`
																: "1px solid gray",
														margin: "2% 3% 2% 0%",
														fontFamily: font.font_name,
														cursor: "pointer",
														minWidth: "max-content",
														fontSize: "16px",
													}}
													onClick={() => {
														onFontFamilyChange(font.font_name, selectedTextTab);
														setSelectedFont(font.font_name);
													}}
												>
													{font.font_name}

													{/* <CheckCircleOutlineIcon style={{display: selectedFont === font.font_name ? 'inline-block' : 'none'}}></CheckCircleOutlineIcon> */}
												</div>
											);
										})}
									</div>
								</div>
							) : (
								<></>
							)}

							{isFontColorEditable ? (
								<div>
									<Typography
										style={{
											textAlign: "left",
											marginBottom: "16px",
											fontSize: contentFontSizes.content,
											fontWeight: 700,
										}}
									>
										{colorTitle}
									</Typography>
									<div style={{ display: "flex", alignItems: "center" }}>
										{fontColors.map((color) => {
											return (
												<div
													className={
														styles.colorBox +
														(selectColorInitial && selectedColor === color
															? ` ${styles.selected}`
															: "")
													}
												>
													<div
														style={{
															backgroundColor: color,
															height: "25px",
															width: "25px",
															borderRadius: "4px",
															cursor: "pointer",
														}}
														onClick={() => {
															setSelectColorInitial(true);
															setSelectedColor(color);
															updateSelectedColor(color);
														}}
													/>
												</div>
											);
										})}
									</div>
								</div>
							) : (
								<></>
							)}
							{faqs.length != 0 ? (
								<div className={styles.faq_box}>
									<hr style={{ color: "black" }}></hr>
									<Typography
										component={"div"}
										sx={{
											color: "black",
											marginBottom: "8px",
											fontSize: "16px",
											fontWeight: 600,
											fontFamily: "roboto",
										}}
									>
										{instructions.title}
									</Typography>
									{instructions.type === "FAQs" ? (
										<div>
											{faqs.map((faq, i) => {
												return (
													<>
														<Typography
															sx={{ fontSize: contentFontSizes.faqs }}
															gutterBottom
														>{`${i + 1}. ${faq.question}`}</Typography>
														<Typography
															sx={{ fontSize: contentFontSizes.faqs }}
														>{`Ans. ${faq.answer}`}</Typography>
													</>
												);
											})}
										</div>
									) : (
										<Typography
											sx={{ fontSize: contentFontSizes.faqs }}
											gutterBottom
										>
											{instructions.text.split("\n").map((line) => {
												return (
													<span>
														{line.replaceAll("\\n", "")}
														<br />
													</span>
												);
											})}
										</Typography>
									)}
								</div>
							) : null}
						</div>
					)}
				</div>
			</div>

			<ImageUploadModal
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
				}}
				onSave={(data) => {
					setUploadedImages((prev) => {
						return {
							...prev,
							[selectedTab]: Object.values(data),
						};
					});
					selectImage(
						URL.createObjectURL(Object.values(data)[0]),
						Object.values(data)[0]
					);
					onImageSelect(
						0,
						URL.createObjectURL(Object.values(data)[0]),
						selectedTab,
						"uploaded"
					);
					setFolders((folders) => {
						const newFolders = { ...folders };
						newFolders[selectedTab].imageType = "uploaded";
						return newFolders;
					});
					setIsModalOpen(false);
				}}
				primaryColor={primaryColor}
				upladBgRemoval={upladBgRemoval}
				cameraBgRemoval={cameraBgRemoval}
				allowCameraUploads={allowCameraUploads}
				showGuidelines={showGuidelines}
				guidelineFrame={selectedFolder ? selectedFolder.guideline_frame : null}
				accessToken={accessToken}
				message="Upload images"
				heading="Upload images"
				defaultAction="upload"
			/>
		</ThemeProvider>
	);
});

export default ElementSelection;
