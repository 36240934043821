import React, {useState, useEffect} from 'react'
import styled from "styled-components";
import Select from 'react-select';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { isAuthenticated } from '../../Helper/Endpoints/Endpoints';
import { LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import "./RSSFeeds.css"; 

const Box = styled.div`
  border-radius: 14px;
  background-color: #2C303A;
  width: 90%;
  height: 500px;
  margin-left: -5%;
`;

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        // borderBottom: "1px solid rgba(33, 42, 64, 1)",
        
        color: state.isSelected
          ? "rgba(255, 255, 255, 1)"
          : "black",
        // padding: 20,
        fontSize: "14px",
      }),
    control: (_, { selectProps: { width } }) => ({
      display: "flex",
      color: "rgba(255, 255, 255, 1)",
      borderRadius: "100px",
      backgroundColor: "#343943",
      height: '36px',
      fontSize: "14px",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
  
      return {
        ...provided,
        opacity,
        transition,
        color: "white",
        fontFamily: 'Montserrat',
      };
    },
    multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "rgba(36, 171, 226, 0.24)",
          color: "#24abe2",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "#24abe2",
      }),
  };

function RSSFeeds() {

    const [industries, setIndustries] = useState();
    const [selectedIndustry, setSelectedIndustry] = useState({"value": 1, "label" : 'Top Stories'});
    const [feeds, setFeeds] = useState([]);

    const onchangeIndustry = (item) => {
        setSelectedIndustry({"value": item.value, "label" : item.label});
        console.log("SELECTED INDUSTRY: ", selectedIndustry);
    };

    useEffect(() => {
        const {accessToken} = isAuthenticated();
    
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }   
        }

        fetch(`${process.env.REACT_APP_BACKEND}/get_topics/`, requestOptions)
        .then(response => response.json())
          .then(data => {
            console.log(data)
            var topics = [];
            for(var topic of data.topics) {
                topics.push({"value" : topic.id, "label" : topic.topic})
            }

            setIndustries(topics)
          }) 

        
    }, []);

    useEffect(() => {
        const {accessToken} = isAuthenticated();
    
        var requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            }   
        }

        fetch(`${process.env.REACT_APP_BACKEND}/get_rss_feed_topic/${selectedIndustry.value}/`, requestOptions)
        .then(response => response.json())
        .then(data => {
            console.log(data.feeds)
            setFeeds(data.feeds)

        }) 
    }, [selectedIndustry])


  return (
    <Box style={{backgroundColor: "#2C303A" }} className='cardStyle'>
              <div style={{color: "white", fontSize: "26px", marginLeft: "4%", display: "flex", marginTop: "2%"}}>

                <div style={{width: "15%", fontSize: "23px"}}>
                    Content Ideas
                </div>

                <div style={{display: "flex", justifyContent: "stretch", width: "75%"}}>
                    <div style={{ width: '15%'}}>
                    <Select 
                        styles={customStyles}
                        value={selectedIndustry}
                        onChange={onchangeIndustry}
                        options={industries}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => option.label}
                        
                        // defaultValue = {this.state.selectedPlace}
                        placeholder={'All Topics'} 
                        theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            neutral50: '#FFFFFF',  // Placeholder color
                        },
                        })}
                    />
                    </div>
                    {/* <div style={{ width: '15%'}}>
                    <Select 
                        styles={customStyles}
                        // defaultValue = {this.state.selectedPlace}
                        placeholder={'All Location'} 
                        theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            neutral50: '#FFFFFF',  // Placeholder color
                        },
                        })}
                    />
                    </div>
                    <div style={{ width: '15%'}}>
                    <Select 
                        styles={customStyles}
                        // defaultValue = {this.state.selectedPlace}
                        placeholder={'All Platform'} 
                        theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            neutral50: '#FFFFFF',  // Placeholder color
                        },
                        })}
                    />
                    </div>
                    <div style={{ width: '15%'}}>
                    <Select 
                        styles={customStyles}
                        // defaultValue = {this.state.selectedPlace}
                        placeholder={'All Industry'} 
                        theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            neutral50: '#FFFFFF',  // Placeholder color
                        },
                        })}
                    />
                    </div>
                    <div style={{ width: '15%'}}>
                    <Select 
                        styles={customStyles}
                        // defaultValue = {this.state.selectedPlace}
                        placeholder={'All Industry'} 
                        theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            neutral50: '#FFFFFF',  // Placeholder color
                        },
                        })}
                    />
                    </div> */}
                </div>

                <div style={{textAlign: "center", color: "#007AFF", width: "10%"}}>
                  <Link to="/trendwatch/content-ideas" style={{fontSize: "15px", textDecoration: "none"}}>View All</Link>
                </div>

              </div>

              {/* <CircularProgress style={{marginLeft: '50%', marginTop: '2%', marginBottom: '2%', display: this.state.fetchingTags ? 'block' : 'none'}}></CircularProgress> */}
              <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: "row", padding: '1.5%', justifyContent: "space-between", width: "90%", marginLeft: "50px"}}>
              <Swiper
                slidesPerView={3}
                spaceBetween={30}
                slidesPerGroup={3}
                loop={true}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                
            >
               
                    {
                        feeds.map((feed, index) => {

                            function truncate(str, n) {
                                return str?.length > n ? str.substr(0, n-1) + "..." : str;
                            }

                            function getImage(feed){
                                const doc = document.createElement('div')
                                const summary = feed.summary;
                                doc.innerHTML = summary

                                const image = doc.querySelector('img')

                                if(image){
                                    return image.src;
                                }

                                else{
                                    return 'https://www.dummytextimages.com/600x400/000/fff'
                                }

                                
                            }

                            function getSubHeading(feed){
                                const doc = document.createElement('div')
                                const summary = feed.summary;
                                doc.innerHTML = summary

                                const subHeading = doc.querySelector('h1')

                                // This will run for the buzzfeed
                                if(subHeading){
                                    return subHeading.innerText;
                                }

                                // This will run for TOI 
                                else{
                                    
                                    let str = summary.split('</a>')
                                    str = str[1]

                                    return str ? str : 'NO SUBHEADING FOUND'
                                }
                                
                            }

                            function viewallHandler(link){
                                let a= document.createElement('a');
                                a.target= '_blank';
                                a.href= link;
                                a.click();
                            }

                            const subHeading = getSubHeading(feed)
                            const image = getImage(feed)

                            // console.log('--abhinav-ford mustang , feed summary = ',feed.summary)
                            {/* console.log(feed.summary); */}

                            return feed.summary.length > 0 ? ( <SwiperSlide><Card sx={{ maxWidth: 345, maxHeight: 380 }} style={{backgroundColor: "#0D1018", color: "white", marginLeft: "15%", height: "380px", borderRadius: "15px" , display: 'flex' , flexDirection: 'column'}}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    // image={feed.summary.replaceAll('<', '').split(" ")[5].substring(5, feed.summary.replaceAll('<', '').split(" ")[5].length - 1)}
                                    // image = {feed.summary.substring(feed.summary.indexOf("src") + 5, feed.summary.indexOf("</p>") - 4)}
                                    image = {image}
                                    alt="green iguana"
                                    
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h7" component="div" style={{fontWeight: "bold"}}>
                                    {truncate(feed.title, 80)}
                                    </Typography>
                                    <Typography variant="body2" style={{color: "white"}}>
                                    {/* {truncate(feed.summary.substring(feed.summary.indexOf("</a>") + 4,  feed.summary.length), 200)} */}
                                    {/* {truncate(feed.summary.substring(4, feed.summary.indexOf("</h1>")), 150)} */}
                                    {truncate(subHeading , 100)}
                                    </Typography>
                                </CardContent>
                                <CardActions style={{justifyContent: "space-between", marginTop: "auto" }}>
                                    {/* <Button size="small" onClick={() => {window.location.href = feed.link }}><br />Learn More</Button> */}
                                    <Button size="small" onClick={() => {viewallHandler(feed.link) }}><br />Learn More</Button>
                                    <div style={{marginTop: "7%"}}>{feed.published.substring(5, 16)}</div>
                                </CardActions>
                            </Card>
                            </SwiperSlide>): <></>
                        })
                    }
                </Swiper>
                    </div>
    </Box>
  )
}

export default RSSFeeds