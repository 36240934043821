import React, { useState, useEffect } from "react";
import styles from "./my_products.module.css";
import { isAuthenticated } from "../../../../../Helper/Endpoints/Endpoints";

const MyProducts = (props) => {
	const [products, setProducts] = useState({});
	const [tab, setTab] = useState("Not requested");

	useEffect(() => {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/get_products/`, { headers })
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				// setProducts(data.products);
				var notReqested = data.products.filter(function (p) {
					return p.request_status == "Not requested";
				});
				var reqested = data.products.filter(function (p) {
					return p.request_status == "Requested for review";
				});
				var requestApproved = data.products.filter(function (p) {
					return p.request_status == "Approved";
				});

				var productsDict = {
					"Not requested": notReqested,
					"Requested for review": reqested,
					"Review approved": requestApproved,
				};

				console.log(productsDict);

				setProducts(productsDict);
			});
	}, []);

	function getActionText() {
		if (tab === "Not requested") {
			return "Upload images";
		} else if (tab === "Requested for review") {
			return "Check review status";
		} else {
			return "Preview";
		}
	}

	return (
		<div className={styles.mainContainer}>
			<div className={styles.heading}>My Products</div>
			<button className={styles.header_button} onClick={() => props.onCreate()}>
				+ Create product
			</button>
			<br />
			<div className={styles.tabsBox}>
				<div
					className={
						tab === "Not requested" ? styles.selectedTab : styles.tabStyle
					}
					onClick={() => setTab("Not requested")}
				>
					Not requested
				</div>
				<div
					className={
						tab === "Requested for review"
							? styles.selectedTab
							: styles.tabStyle
					}
					onClick={() => setTab("Requested for review")}
				>
					Requested for review
				</div>
				<div
					className={
						tab === "Review approved" ? styles.selectedTab : styles.tabStyle
					}
					onClick={() => setTab("Review approved")}
				>
					Review approved
				</div>
			</div>
			<hr></hr>
			<div className={styles.products_container}>
				{products[tab] ? (
					products[tab].map((product) => {
						return (
							<div className={styles.imageContainer}>
								<img
									className={styles.imageStyle}
									src={product.template_url}
								></img>
								<div
									className={styles.image_overlay}
									onClick={() => props.onNext(product.id, getActionText())}
								>
									<button className={styles.edit_icon}>
										{getActionText()}
									</button>
								</div>
								<span>{product.name}</span>
							</div>
						);
					})
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default MyProducts;
