import React, { Component } from "react";
import Sidebar from "../Dashboard/Sidebar";
import Topbar from "../Dashboard/Topbar";
import styled from "styled-components";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import "./advisor.css";
import default_image from "../SocialPostsCalander/default_image.png";
import Modal from "react-modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import GradeSharpIcon from "@mui/icons-material/GradeSharp";
import StarBorderPurple500SharpIcon from "@mui/icons-material/StarBorderPurple500Sharp";
import LoadingButton from "@mui/lab/LoadingButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

const Wrapper = styled.div`
	display: flex;
	background: rgb(238, 245, 255);
`;

const MainDashboard = styled.div`
	width: 100%;
	justify-content: space-evenly;
`;

const Input = styled("input")({
	display: "none",
});

const savedModalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		backgroundColor: "rgba(1,15,30,255)",
		borderRadius: "3vh",
		textAlign: "center",
		height: 500,
		width: 1200,
		color: "rgb(132, 232, 244)",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.25)",
	},
};

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "rgb(132, 232, 244)",
		},
	},
});

export default class TrendingTags extends Component {
	state = {
		isScheduledPost: false,
		imageOption: "",
		isScheduledPost2: false,
		imageOption2: "",
		imageIsUploaded: false,
		currentUploadedImage: "",
		currentUploadedImageFile: null,
		isSavedModalOpen: false,
		saved_creatives: [],
		imageIsUploaded2: false,
		currentUploadedImage2: "",
		currentUploadedImageFile2: null,
		savedCreativeChoosedFrom: "",
		rating1: 0,
		message1: "message 1",
		rating2: 0,
		message2: "message 2",
		inPredicting: false,
		inPredicting2: false,
		ratingToMessage: {
			1: "We can do better.",
			2: "There is potential for improvement",
			3: "Almost there! This creative needs slight adjustments.",
			4: "You are consistent! This creative should perform similar to your last 2 posts",
			5: "Kudos! This creative will perform better than your last 2 posts",
		},
	};

	handleSavedCreativesButton(event) {
		if (event.target.getAttribute("id") === "imageSaved")
			this.setState({ imageOption: "imageSaved" });

		this.setState({ isSavedModalOpen: true, savedCreativeChoosedFrom: "one" });
		this.getSavedCreatives();
	}

	handleSavedCreativesButton2(event) {
		if (event.target.getAttribute("id") === "imageSaved2")
			this.setState({ imageOption2: "imageSaved" });

		this.setState({ isSavedModalOpen: true, savedCreativeChoosedFrom: "two" });
		this.getSavedCreatives();
	}

	getSavedCreatives() {
		const { accessToken } = isAuthenticated();
		const headers = {
			Authorization: `Bearer ${accessToken}`,
		};

		fetch(`${process.env.REACT_APP_BACKEND}/get_saved_creatives/`, { headers })
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				this.setState({ saved_creatives: data });
			});

		console.log(this.state.saved_creatives);
	}

	handleSavedCreativeClick(e, thumbnail) {
		if (this.state.savedCreativeChoosedFrom === "one")
			this.setState({
				currentUploadedImage: thumbnail,
				isSavedModalOpen: false,
				imageIsUploaded: true,
			});
		else
			this.setState({
				currentUploadedImage2: thumbnail,
				isSavedModalOpen: false,
				imageIsUploaded2: true,
			});
	}

	onFileChange(event) {
		var url = URL.createObjectURL(event.target.files[0]);
		this.setState({
			imageIsUploaded: true,
			currentUploadedImage: url,
			currentUploadedImageFile: event.target.files[0],
		});
	}

	onFileChange2(event) {
		var url = URL.createObjectURL(event.target.files[0]);
		this.setState({
			imageIsUploaded2: true,
			currentUploadedImage2: url,
			currentUploadedImageFile2: event.target.files[0],
		});
		console.log(this.state.imageIsUploaded + " " + this.state.imageIsUploaded2);
		console.log(
			this.state.currentUploadedImage + " " + this.state.currentUploadedImage2
		);
	}

	setImageOption(event) {
		if (event.target.getAttribute("id") === "uploadImage")
			this.setState({ imageOption: "uploadImage" });

		if (event.target.getAttribute("id") === "imageSaved")
			this.setState({ imageOption: "imageSaved" });
	}

	setImageOption2(event) {
		if (event.target.getAttribute("id") === "uploadImage2")
			this.setState({ imageOption2: "uploadImage" });

		if (event.target.getAttribute("id") === "imageSaved2")
			this.setState({ imageOption2: "imageSaved" });
	}

	predictLikesAndRating1() {
		if (
			this.state.currentUploadedImage === "" &&
			!this.state.currentUploadedImageFile
		) {
			toast.info("Please select an image to predict", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			return;
		}

		this.setState({ inPredicting: true, rating1: 0 });

		const { accessToken } = isAuthenticated();
		var form_data = new FormData();

		if (this.state.currentUploadedImageFile) {
			form_data.append("isFile", "true");
			form_data.append("image", this.state.currentUploadedImageFile);
		} else {
			form_data.append("isFile", "false");
			form_data.append("image", this.state.currentUploadedImage);
		}

		var requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/predict_likes_from_image/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				this.setState({
					rating1: data.rating.rating,
					message1: data.rating.message,
					inPredicting: false,
				});
			});

		// this.setState({inPredicting: false})
	}

	predictLikesAndRating2() {
		if (
			this.state.currentUploadedImage2 === "" &&
			!this.state.currentUploadedImageFile2
		) {
			toast.info("Please select an image to predict", {
				position: "top-center",
				theme: "dark",
				autoClose: 2500,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			return;
		}

		this.setState({ inPredicting2: true, rating2: 0 });

		const { accessToken } = isAuthenticated();
		var form_data = new FormData();

		if (this.state.currentUploadedImageFile2) {
			form_data.append("isFile", "true");
			form_data.append("image", this.state.currentUploadedImageFile2);
		} else {
			form_data.append("isFile", "false");
			form_data.append("image", this.state.currentUploadedImage2);
		}

		var requestOptions = {
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			body: form_data,
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/predict_likes_from_image/`,
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				this.setState({
					rating2: data.rating.rating,
					message2: data.rating.message,
					inPredicting2: false,
				});
			});

		// this.setState({inPredicting: false})
	}

	renderRating1() {
		let stars = [];
		for (let i = 0; i < this.state.rating1; i++) {
			console.log(i);
			stars.push(<GradeSharpIcon sx={{ color: "yellow" }}></GradeSharpIcon>);
		}

		for (let i = 0; i < 5 - this.state.rating1; i++) {
			stars.push(
				<StarBorderPurple500SharpIcon
					sx={{ color: "gray" }}
				></StarBorderPurple500SharpIcon>
			);
		}

		stars.push(<br></br>);
		stars.push(
			<p
				class="rating-message"
				style={this.state.rating1 <= 2 ? { color: "red" } : { color: "lime" }}
			>
				{this.state.ratingToMessage[this.state.rating1]}
			</p>
		);

		return stars;
	}

	renderRating2() {
		let stars = [];
		for (let i = 0; i < this.state.rating2; i++) {
			console.log(i);
			stars.push(<GradeSharpIcon sx={{ color: "yellow" }}></GradeSharpIcon>);
		}

		for (let i = 0; i < 5 - this.state.rating2; i++) {
			stars.push(
				<StarBorderPurple500SharpIcon
					sx={{ color: "gray" }}
				></StarBorderPurple500SharpIcon>
			);
		}

		stars.push(<br></br>);
		stars.push(
			<p
				class="rating-message"
				style={this.state.rating2 <= 2 ? { color: "red" } : { color: "lime" }}
			>
				{this.state.ratingToMessage[this.state.rating2]}
			</p>
		);

		return stars;
	}

	componentDidMount() {
		const { accessToken } = isAuthenticated();

		var requestOptions = {
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};
	}

	render() {
		return (
			<Wrapper id="outer-container">
				<Sidebar />

				<MainDashboard>
					<Topbar />
					<div class="wrapper-container">
						<br></br>
						<div class="heading-class">
							<h3>Advisor</h3>
						</div>
						<br></br>
						<br></br>
						<ThemeProvider theme={theme}>
							<Stack
								direction="row"
								divider={
									<Divider orientation="vertical" sx={{ minHeight: "440px" }} />
								}
								spacing={10}
								style={{ marginLeft: "21%" }}
							>
								<div class="image-1-box">
									<ButtonGroup
										variant="outlined"
										aria-label="outlined button group"
										style={{
											display: this.state.isSavedPost ? "none" : "inline-block",
										}}
									>
										{/* <input type="file" onChange={(e) => this.onFileChange(e)} /> */}
										{/* <Button variant="outlined" size="small">Upload</Button> */}
										<label htmlFor="contained-button-file">
											<Input
												accept="image/*"
												id="contained-button-file"
												multiple
												type="file"
												onChange={(e) => this.onFileChange(e)}
											/>
											<Button
												id="uploadImage"
												variant={
													this.state.imageOption === "uploadImage"
														? "contained"
														: "outlined"
												}
												component="span"
												size="small"
												onClick={(e) => this.setImageOption(e)}
												sx={{ borderRadius: "5px 0px 0px 5px" }}
												style={{
													color:
														this.state.imageOption === "uploadImage"
															? "black"
															: "white",
												}}
											>
												Upload
											</Button>
										</label>
										<Button
											id="imageSaved"
											variant={
												this.state.imageOption === "imageSaved"
													? "contained"
													: "outlined"
											}
											size="small"
											style={{
												color:
													this.state.imageOption === "imageSaved"
														? "black"
														: "white",
											}}
											onClick={(e) => this.handleSavedCreativesButton(e)}
											sx={{ borderRadius: "0px" }}
										>
											Choose from Saved Creatives
										</Button>
									</ButtonGroup>
									<br></br>
									<br></br>
									<img
										src={default_image}
										style={{
											height: 240,
											width: 240,
											borderRadius: "5%",
											marginLeft: "10%",
											display: this.state.imageIsUploaded
												? "none"
												: "inline-block",
										}}
									></img>
									<img
										src={this.state.currentUploadedImage}
										style={{
											height: 240,
											width: "auto",
											borderRadius: "5%",
											marginLeft: "10%",
											display: this.state.imageIsUploaded
												? "inline-block"
												: "none",
										}}
									></img>
									<br></br>
									<br></br>
									<LoadingButton
										sx={{
											ml: "30%",
											borderRadius: "100px",
											textTransform: "none",
											fontSize: "14px",
											fontFamily: "Open Sans",
											textAlign: "center",
											width: "120px",
										}}
										variant="contained"
										size="small"
										loading={this.state.inPredicting}
										// loadingIndicator="Predicting..."
										loadingPosition="end"
										onClick={() => this.predictLikesAndRating1()}
									>
										Predict
									</LoadingButton>
									<br></br>
									<br></br>
									<div style={{ display: "flex" }}>
										<div class="rating-container" id="rating-1-box">
											{this.renderRating1()}
										</div>
										<Tooltip
											title="MyEra Advisor studies your creative to check for design principals which work well on Social media, and gives you a performace score based on you current audience."
											arrow
											placement="right"
										>
											<InfoOutlinedIcon
												size="small"
												sx={{ ml: "5%", color: "gray" }}
											></InfoOutlinedIcon>
										</Tooltip>
									</div>
								</div>
								<div class="image-2-box">
									<ButtonGroup
										variant="outlined"
										aria-label="outlined button group"
										style={{
											display: this.state.isSavedPost2
												? "none"
												: "inline-block",
										}}
									>
										{/* <input type="file" onChange={(e) => this.onFileChange(e)} /> */}
										{/* <Button variant="outlined" size="small">Upload</Button> */}
										<label htmlFor="contained-button-file-2">
											<Input
												accept="image/*"
												id="contained-button-file-2"
												multiple
												type="file"
												onChange={(e) => this.onFileChange2(e)}
											/>
											<Button
												id="uploadImage2"
												variant={
													this.state.imageOption2 === "uploadImage"
														? "contained"
														: "outlined"
												}
												component="span"
												size="small"
												onClick={(e) => this.setImageOption2(e)}
												sx={{ borderRadius: "5px 0px 0px 5px" }}
												style={{
													color:
														this.state.imageOption2 === "uploadImage"
															? "black"
															: "white",
												}}
											>
												Upload
											</Button>
										</label>
										<Button
											id="imageSaved2"
											variant={
												this.state.imageOption2 === "imageSaved"
													? "contained"
													: "outlined"
											}
											size="small"
											style={{
												color:
													this.state.imageOption2 === "imageSaved"
														? "black"
														: "white",
											}}
											onClick={(e) => this.handleSavedCreativesButton2(e)}
											sx={{ borderRadius: "0px" }}
										>
											Choose from Saved Creatives
										</Button>
									</ButtonGroup>
									<br></br>
									<br></br>
									<img
										src={default_image}
										style={{
											height: 240,
											width: 240,
											borderRadius: "5%",
											marginLeft: "10%",
											display: this.state.imageIsUploaded2
												? "none"
												: "inline-block",
										}}
									></img>
									<img
										src={this.state.currentUploadedImage2}
										style={{
											height: 240,
											width: "auto",
											borderRadius: "5%",
											marginLeft: "10%",
											display: this.state.imageIsUploaded2
												? "inline-block"
												: "none",
										}}
									></img>
									<br></br>
									<br></br>
									<LoadingButton
										sx={{
											ml: "30%",
											borderRadius: "100px",
											textTransform: "none",
											fontSize: "14px",
											fontFamily: "Open Sans",
											textAlign: "center",
											width: "120px",
										}}
										variant="contained"
										size="small"
										loading={this.state.inPredicting2}
										// loadingIndicator="Predicting..."
										loadingPosition="end"
										onClick={() => this.predictLikesAndRating2()}
									>
										Predict
									</LoadingButton>
									<br></br>
									<br></br>
									<div style={{ display: "flex" }}>
										<div class="rating-container" id="rating-1-box">
											{this.renderRating2()}
										</div>
										<Tooltip
											title="MyEra Advisor studies your creative to check for design principals which work well on Social media, and gives you a performace score based on you current audience."
											arrow
											placement="right"
										>
											<InfoOutlinedIcon
												size="small"
												sx={{ ml: "5%", color: "gray" }}
											></InfoOutlinedIcon>
										</Tooltip>
									</div>
								</div>
							</Stack>
							<br></br>
						</ThemeProvider>
					</div>
					<Modal isOpen={this.state.isSavedModalOpen} style={savedModalStyles}>
						<h3>Choose from Saved Creatives</h3>
						<div>
							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									width: "100%",
									margin: "auto",
								}}
							>
								{this.state.saved_creatives.map((creative) => {
									return (
										<div
											class="col-sm-4"
											style={{ width: "auto", margin: "auto" }}
											key={`$(creative.id)`}
										>
											<a>
												<img
													className="styleImage"
													src={creative.thumbnail}
													onClick={(e) =>
														this.handleSavedCreativeClick(e, creative.thumbnail)
													}
												></img>
											</a>
										</div>
									);
								})}
							</div>
						</div>
						<Button onClick={() => this.setState({ isSavedModalOpen: false })}>
							Close
						</Button>
					</Modal>
				</MainDashboard>
			</Wrapper>
		);
	}
}
