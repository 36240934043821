import React, { useState, useEffect } from "react";
import Axios from "axios";
import Select from 'react-select';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft , FaCopy } from "react-icons/fa";
import { isAuthenticated } from "../Helper/Endpoints/Endpoints";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./ImageSlider.css";
import { Link } from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    display: "flex",
    color: "rgba(255, 255, 255, 1)",
    borderRadius: "100px",
    backgroundColor: "#343943",
    height: '36px',
    fontSize: "14px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
      color: "white",
      fontFamily: 'Montserrat',
    };
  },
};

const ImageSlider = ({}) => {
  const [current, setCurrent] = useState(0);
  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const [images_data, setImagesdata] = useState([]);
  const [light, setLight] = useState({
    photoIndex: 0,
    isOpen: false,
  });

  useEffect(() => {
    const { accessToken } = isAuthenticated();
    Axios({
      method: "GET",
      url: `${process.env.REACT_APP_BACKEND}/get_trending_templates/`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {
      setImagesdata(response.data.trending_templates);
    });
  }, []);
  console.log(images_data);
  const length = images_data.length;

  const nextSlide = () => {
    let temp = 0;
    if (current != 0) {
      temp = current - 1;
    }
    if (length % 3 === 0) {
      setCurrent(temp === length - 4 ? 0 : current + 3);
    } else if (length % 3 === 1) {
      setCurrent(temp === length - 2 ? 0 : current + 3);
    } else {
      setCurrent(temp === length - 3 ? 0 : current + 3);
    }

    setRight(right + 1);
  };

  const prevSlide = () => {
    if (length % 3 === 0) {
      setCurrent(current === 0 ? length - 4 : current - 3);
    } else if (length % 3 === 1) {
      setCurrent(current === 0 ? length - 1 : current - 3);
    } else {
      setCurrent(current === 0 ? length - 2 : current - 3);
    }

    setLeft(left + 1);
  };

  return (
    <div style={{width: "57%"}}>
      {length ? (
        <> 
          <section className="slider cardStyle">
            <div style={{color: "white", fontSize: "26px", marginLeft: "2%", paddingTop: "1%"}}>
                  Trending Media
            </div>

          {/* <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "3%"}}>
              <div style={{ width: '15%'}}>
              <Select 
                  styles={customStyles}
                  // defaultValue = {this.state.selectedPlace}
                  // onChange={this.handleDropdownChange} 
                  // options={this.state.places} 
                  placeholder={'All Industry'} 
                  theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: '#FFFFFF',  // Placeholder color
                    },
                  })}
                />
              </div>
              <div style={{ width: '15%'}}>
              <Select 
                  styles={customStyles}
                  // defaultValue = {this.state.selectedPlace}
                  // onChange={this.handleDropdownChange} 
                  // options={this.state.places} 
                  placeholder={'All Location'} 
                  theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: '#FFFFFF',  // Placeholder color
                    },
                  })}
                />
              </div>
              <div style={{ width: '15%'}}>
              <Select 
                  styles={customStyles}
                  // defaultValue = {this.state.selectedPlace}
                  // onChange={this.handleDropdownChange} 
                  // options={this.state.places} 
                  placeholder={'All Media'} 
                  theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: '#FFFFFF',  // Placeholder color
                    },
                  })}
                />
              </div>
              <div style={{ width: '15%'}}>
              <Select 
                  styles={customStyles}
                  // defaultValue = {this.state.selectedPlace}
                  // onChange={this.handleDropdownChange} 
                  // options={this.state.places} 
                  placeholder={'All Tags'} 
                  theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: '#FFFFFF',  // Placeholder color
                    },
                  })}
                />
              </div>
              <div style={{ width: '15%'}}>
              <Select 
                  styles={customStyles}
                  // defaultValue = {this.state.selectedPlace}
                  // onChange={this.handleDropdownChange} 
                  // options={this.state.places} 
                  placeholder={'All Platform'} 
                  theme={theme => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        neutral50: '#FFFFFF',  // Placeholder color
                    },
                  })}
                />
              </div>
          </div> */}

            <div style={{display: "flex", justifyContent: "space-evenly", marginTop: "3%", width: "85%"}}>
            <Swiper
                slidesPerView={3}
                spaceBetween={30}
                slidesPerGroup={3}
                loop={true}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                <div className="imageSlider__column" style={{display: "flex", flexDirection: "row"}}>
                
                {images_data.map((img, index) => {
                    return (<SwiperSlide><div style={{display: "flex", flexDirection: "column", marginLeft: "15%"}}>
                      <img id ={`${img.tags[0]}`}
                        src={img.template}
                        alt="creative insipiration"
                        className="image"
                        onClick={() =>
                          setLight({ photoIndex: index, isOpen: true })
                        }
                      />
                      <div className="imageSlider__copy">
                        <label className="imageSlider__columnLabel" htmlFor={`${images_data[index].tags[0]}`}>{images_data[index].tags[0]} </label>
                        <FaCopy className="copyText" onClick={() => {navigator.clipboard.writeText(images_data[index].tags[0])} }/>
                      </div>
                    </div></SwiperSlide>)
                  })
                }
                
              </div>
              
              </Swiper>
            </div>

            {light.isOpen && (
              <Lightbox
                mainSrc={images_data[light.photoIndex].template}
                nextSrc={images_data[(light.photoIndex + 1) % length].template}
                prevSrc={
                  images_data[(light.photoIndex + length - 1) % length].template
                }
                onCloseRequest={() => setLight({ ...light, isOpen: false })}
                onMovePrevRequest={() =>
                  setLight({
                    ...light,
                    photoIndex: (light.photoIndex + length - 1) % length,
                  })
                }
                onMoveNextRequest={() =>
                  setLight({
                    ...light,
                    photoIndex: (light.photoIndex + 1) % length,
                  })
                }
                imageTitle = {`${images_data[light.photoIndex].tags[0]}`}
              />
            )}

            <div style={{textAlign: "center", color: "#007AFF", marginTop: "1%"}}>
            <Link to="/trendwatch/trending-media" style={{textDecoration: "none" , fontSize:'15px'}}>View All</Link>
            </div>

          </section>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ImageSlider;
