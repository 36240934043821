import React, {useState, useEffect, useRef, useImperativeHandle, forwardRef, useReducer} from 'react';
import {Text, Transformer } from 'react-konva';

const TextElement = forwardRef((props, ref) => {

    const ele = props.ele

    const textRef = useRef(null);
    const componentRef = useRef(null);

    const [x, setX] = useState(ele.x);
    const [y, setY] = useState(ele.y);
    const [height, setHeight] = useState(ele.height);
    const [width, setWidth] = useState(ele.width);
    const [text, setText] = useState(ele.text);
    const [rotation, setRotation] = useState(ele.rotation);
    const [fontSize, setFontSize] = useState(ele.fontSize);
    const [fontColor, setFontColor] = useState(ele.fontColor);
    const [fontFamily, setFontFamily] = useState(ele.fontFamily);
    const [alignment, setAlignment] = useState(ele.alignment)

    // const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    React.useEffect(() => {
        props.updateElementRefs(componentRef)
      }, []);
    
    useImperativeHandle(componentRef, () => ({

        reRender(fontObj) {
            // console.log("Updating text to:", text)
            if("text" in fontObj)
                setText(fontObj["text"])
            
            if("fontSize" in fontObj)
            setFontSize(fontObj["fontSize"])

            if("fontFamily" in fontObj){
                console.log(fontObj)
                setFontFamily(fontObj["fontFamily"])
            }

            if("fontColor" in fontObj){
                // console.log(fontObj["fontColor"])
                setFontColor(fontObj["fontColor"])
            }
        }

    }));
      
    return(
        <Text
            x={x}
            y={y}
            text={text}
            fontSize={fontSize}
            fontFamily={fontFamily}
            fill={fontColor}
            stroke={fontColor}
            strokeWidth={0.2}
            // strokeWidth={"1px"}
            // fontStyle='100'
            width={width}
            height={height}
            rotation={rotation}
            align={alignment ? alignment.toLowerCase() : "center"}
            ref={textRef}
        >

        </Text>
    )

})

export default TextElement;