import styled from "styled-components";
import ProductCanvas from "../Campaign/ProductCanvas";
const Wrapper = styled.div`
  display: flex;
  background: rgb(13,16,22);
  height: 100%;
`;

const MainDashboard = styled.div`
  width: 100%;
  justify-content: space-evenly;
`;


export default function ProductCanvasMainApi(props) {
  return (
    <Wrapper id="outer-container-1">
      <MainDashboard>
        <ProductCanvas
          objectiveId={props.location.state.objectiveId}
          designId={props.location.state.designId}
          ctaId={props.location.state.ctaId}
          heroId={props.location.state.heroId}
          hashTagsSelected={
            props.location.state.hashTagsSelected
          }
        />
      </MainDashboard>
    </Wrapper>
  );
}