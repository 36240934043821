import {Layer, Rect} from "react-konva";
import URLImage from "../Campaign_editor/URLImage";
import * as PropTypes from "prop-types";
import React from "react";

function EditorBackground(props) {
    return <Layer id="background_konva">
        <Rect x={0} y={0} width={props.widthLayoutCanvas * props.scaleFactorLayout}
              height={props.heightLayoutCanvas * props.scaleFactorLayout} fill={"#" + `${props.colorbg["hex"]}`}/>
        {props.backgroundData.url && <URLImage
            image={props.backgroundData.url}
            isSelected={props.backgroundData.url.id === props.selectedId}
            onSelect={props.onSelect}

        />
        }
    </Layer>;
}

EditorBackground.propTypes = {
    widthLayoutCanvas: PropTypes.number,
    scaleFactorLayout: PropTypes.number,
    heightLayoutCanvas: PropTypes.number,
    colorbg: PropTypes.any,
    backgroundData: PropTypes.shape({}),
    selectedId: PropTypes.any,
    onSelect: PropTypes.func
};
export default EditorBackground;