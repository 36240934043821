import React from "react";
import { Link } from "react-router-dom";
import { Card, CardGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const PricingPage = () => {
  return(
    <>
      <div className="plans">Choose the plan that's right for you.</div>

<div className="container mb-5 mt-5 plansdiv">
  <CardGroup className="freeplan">
    <Card className="free_plans">
      <Card.Body>
        <Card.Title className="freeplansdiv">Free</Card.Title>
        <Card.Text className="freeplansdiv2">
          <ul className="freeplanlist">
            <li>Unlimited Creatives Generation</li>
            <li>7 free posting, scheduling and downloads </li>
            <li>Limited access to Trendwatch</li>
          </ul>
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <center>
          {/* <Link
            to={{
              pathname: "/signup",
              aboutProps: formik.values.email,
            }}
    
            style={{ textDecoration: "none" }}
          >
            <button className="btn contactusbutton">Get Started</button>
          </Link>
           */}
          <Link
            to='/signup'
            style={{ textDecoration: "none" }}
          >
            <button className="btn contactusbutton">Get Started</button>
          </Link>
        </center>
      </Card.Footer>
    </Card>
    <Card className="paid_plans">
      <Card.Body>
        <Card.Title className="paidplansdiv">
          Premium <br />
          <div style={{ fontSize: "23px" }}>
            (INR 8,280/<span style={{ fontSize: "20px" }}>month</span>)
          </div>
        </Card.Title>
        <Card.Text className="freeplansdiv2">
          <ul className="paidplanlist">
            <li>Unlimited Creatives Generation and downloads</li>
            <li>Add your own templates</li>
            <li>
              Unlimited posting and scheduling as per channel policy
            </li>
            <li>Create upto 10 trendboards</li>
            <li>Personalized trend recommendations</li>
            <li>Email alerts for upto 10 topics</li>
          </ul>
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        <center>
          <a
            href="https://payu.in/web/504A3B93FCE8C13FE87FB5F84C789853"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn contactusbutton">
              Subscribe Now
            </button>
          </a>
        </center>
      </Card.Footer>
    </Card>

    {/* <Card className="paid_plans"> 

      <Card.Body> 
        <Card.Title className="paidplansdiv">Premium + <br />(INR 10,000/month)</Card.Title> 
        <Card.Text className="freeplansdiv2"> 
          <ul className="paidplanlist"> 
            <li>Everyting in premium, plus</li> 
            <li>5 custom templates by professional designers</li> 
            <li>Instagram and facebook account management.* TCA*</li> 
            <li>Marketing strategy for 1 month</li> 
            <li>15 Posts by MyEra team</li> 
          </ul> 
        </Card.Text> 
      </Card.Body> 
      <Card.Footer> 
        <center> 
          <a href="https://pmny.in/9IZWGMIn4Oou"> 
            <button className="btn contactusbutton"> 
              Subscribe Now 
            </button> 
          </a> 
        </center> 
        </Card.Footer> 

</Card>*/}
  </CardGroup>
</div>
    </>
  )
}

export default PricingPage;