import React from "react";
import { Layer } from "react-konva";
import URLImageElement from "../Elements/URLImageElement";

const MultiImageLayer = ({ elements, selectedImages, exportLayer = true }) => {
	return (
		<Layer className={!exportLayer ? "myera-layer-ignore" : ""}>
			{elements.map((element, idx) => {
				return (
					<URLImageElement
						x={element.x}
						y={element.y}
						width={element.width}
						height={element.height}
						rotation={element.rotation}
						id={idx}
						image={selectedImages[idx] ?? element.default_image.asset}
						onSelect={() => {}}
						onChange={(new_ele) => {}}
						isSelected={false}
						onTransformEnd={() => {}}
						draggable={element.is_movable}
						listening={element.is_movable}
						updateElementRefs={(ref) => {}}
					></URLImageElement>
				);
			})}
		</Layer>
	);
};

export default MultiImageLayer;
