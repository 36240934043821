function HowToUse() {
    return (
        <>
        <div
              style={{
                margin: "0 auto",
                marginLeft: "0 auto",
                marginRight: "0 auto",
                height: "60vh",
                borderRadius: "15px",
                marginTop: "5vh",
                boxShadow: "-1px -1px 16px 12px rgba(28,33,148,0.87)",
              }}
            >

              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/J198SGIm1qY`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                boxShadow="0px -1px 46px -6px rgba(42,49,209,1)"
                title="Embedded youtube"
              />
            </div>
            <div
              style={{
                textAlign: "center",
                padding: "40px",
                fontWeight: "bold",
              }}
            >
              Click on the video to play
            </div>
        </>
    );
}
    
export default HowToUse;