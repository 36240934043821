import React, { useEffect, useState } from "react";
import ImageUploadModal from "../Common/ImageUploadModal";
import Navbar from "./Navbar";
import styles from "./ShopifyWrapper.module.css";
import Sidebar from "./Sidebar";
import ChooseTheme from "./tabs/ChooseTheme/ChooseTheme";
import ChooseTemplates from "./tabs/ManageProducts/ChooseTemplates/ChooseTemplates";
import MyProducts from "./tabs/ManageProducts/MyProducts/MyProducts";
import UploadImages from "./tabs/ManageProducts/UploadImages/UploadImages";
import ReviewInProgress from "./tabs/Review/ReviewInProgress";
import Submit from "./tabs/Review/Submit";

const ShopifyWrapper = ({ children }) => {
	const [selectedTab, setSelectedTab] = useState("my_products");
	const [currentProduct, setCurrentProduct] = useState();

	// useEffect(() => {
	// 	// setSelectedTab(localStorage.getItem('selected_tab'))
	// 	window.addEventListener("storage", () => {
	// 		// When local storage changes, dump the list to
	// 		// the console.
	// 		console.log("CHANGE");
	// 		setSelectedTab(JSON.parse(localStorage.getItem("selected_tab")));
	// 	});
	// }, []);

	function setTab(tab) {
		console.log("IN SET TAB");
		setSelectedTab(tab);
	}

	function getComponent() {
		console.log(selectedTab);
		if (selectedTab === "choose_theme")
			return (
				<ChooseTheme
					onNext={() => {
						setTab("choose_templates");
					}}
					onBack={() => {
						setTab("my_products");
					}}
				></ChooseTheme>
			);
		else if (selectedTab === "choose_templates") {
			return (
				<ChooseTemplates
					onNext={() => {
						setTab("my_products");
					}}
					onBack={() => {
						setTab("choose_theme");
					}}
				></ChooseTemplates>
			);
		} else if (selectedTab === "upload_images") {
			return (
				<UploadImages
					onNext={() => {
						setTab("submit");
					}}
					product={currentProduct}
				></UploadImages>
			);
		} else if (selectedTab === "my_products") {
			return (
				<MyProducts
					onNext={(product_id, action) => {
						setCurrentProduct(product_id);
						if (action === "Upload images") {
							setTab("upload_images");
						} else {
							setTab("submit");
						}
					}}
					onCreate={() => setTab("choose_theme")}
				></MyProducts>
			);
		} else {
			return <Submit product={currentProduct}></Submit>;
		}
	}

	return (
		<>
			<Navbar />
			<div className={styles.body}>
				<Sidebar setTab={setTab} />
				<div className={styles.content}>{getComponent()}</div>
			</div>
		</>
	);
};

export default ShopifyWrapper;
