import React, { useState, useEffect } from "react";
import "./landing_page_m1.css";
import logo from "./assets/logo.png";
import maskgrp10 from "./assets/Maskgroup10.png";
import calender from "./assets/calendar-bHy0pd0rsP.png";
import tv from "./assets/animat-responsive 1.png";
import speach from "./assets/speech-bubble-BOGASYoonJ.png";
import message from "./assets/message-sent-successfully-plane-QbtxbRhyJI.png";
import arrld from "./assets/arrld.png";
import twitter from "./assets/twitter.png";
import facebook from "./assets/facebook.png";
import linkedin from "./assets/linkedin.png";
import insta from "./assets/insta.png";
import group from "./assets/Group.png";
import warrld from "./assets/warrld.png";
import tick from "./assets/tick.png";
import colons from "./assets/colons.png";
import review from "./assets/review.png";
import ugaoo_review from "./assets/ugaao.png";
import blackberry from "./assets/clients/Blackberrys-myera.png";
import jwc from "./assets/clients/Jaipur Watch - myera.png";
import jaipurprinters from "./assets/clients/Jaipur printers - myera.png";
import swageasy from "./assets/clients/Swageazy - myera.png";
import ugaoo from "./assets/clients/ugaoowhitelogo.png";
import bpc from "./assets/clients/bpctry.png";
import moochuu from "./assets/clients/moochuuwhite.png";
import bakingologo from "./assets/clients/bakingologo.png";
import flowerauralogo from "./assets/clients/flowerauralogo.png";
import bambooindia from "./assets/clients/bamboo-india.png";
import blackberrysReview from "./assets/blackberrys_review_myera.png";
import aicbt from "./assets/investors/aic-bimtech-myera.png";
import evolvex from "./assets/investors/evolvex-myera-wfc.png";
import istart from "./assets/investors/istart-myera.png";
import wefounder from "./assets/investors/we-founder-circle-myera-wfc.png";
import maintanance from "./assets/Website Popup.png";
import myerabanner from "./assets/gifs/myera-watch-customization-banner-gif.mp4";
import myeragifthamper from "./assets/gifs/myera-gift-hamper-customization-gif.mp4";
import lookbook from "./assets/gifs/myera-customization-look-book-virtual-try-on-gif.mp4";
import eyewear from "./assets/gifs/Myera-Eyewear.mp4";
import quote_cal from "./assets/gifs/quote_calc_final.mp4";
import interior_mp4 from "./assets/gifs/myera-interior design gif-1.mp4";
import virtualtryon from "./assets/gifs/myera-look-book-virtual-try-on-gif.mp4";
import customizer from "./assets/MyEra-customizer-webisite.png";
import productimage1 from "./assets/product-image1.png";
import productimage2 from "./assets/product-image2.png";
import productimage3 from "./assets/product-image3.png";
import viestories from "./assets/media/viestories.webp";
import inc42 from "./assets/media/inc42.png";
import startupstory from "./assets/media/startupstory.webp";
import { Helmet } from "react-helmet";

import Modal from "react-modal";
import { Close } from "@material-ui/icons";

const modalStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		padding: "0%",
	},

	overlay: {
		background: "rgba(0, 0, 0, 0.5)",
	},
};

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
}

function handle_review_blur_highlighted() {
	const cards = document.querySelectorAll(".section6_card");
	const middleIndex = Math.floor(cards.length / 2); // Calculate index of middle card

	// Function to apply highlighting and blurring effects to cards
	const highlightMiddleCard = () => {
		cards.forEach((card, index) => {
			if (index === middleIndex) {
				card.classList.add("highlighted");
			} else {
				card.classList.add("blurred");
			}
		});
	};

	// Initial highlighting on page load
	highlightMiddleCard();

	cards.forEach((card) => {
		card.addEventListener("mouseenter", () => {
			// Remove the highlighted class from all cards
			cards.forEach((c) => {
				c.classList.remove("highlighted");
				c.classList.add("blurred");
			});
			// Add the highlighted class to the clicked card
			card.classList.remove("blurred");
			card.classList.add("highlighted");
		});
	});
}

function made_in_india_carousel() {
	document.addEventListener("DOMContentLoaded", () => {
		const carousel = document.querySelector(".carousel");
		const firstItem = carousel.firstElementChild.cloneNode(true);
		carousel.appendChild(firstItem);
	});
}
// function cards_animation() {
//   const observerOptions = {
//     root: null,
//     threshold: 0.5, // Adjust based on when you want the animation to start
//   };
//
//   const observerCallback = (entries) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         // Make the current entry fully visible and move it to the top
//         entry.target.style.opacity = 1;
//         entry.target.style.zIndex = '999'; // High z-index to ensure it's on top
//       } else {
//         // Optional: Reset style if you want it to fade out when leaving viewport
//         // entry.target.style.opacity = 0;
//       }
//     });
//   };
//   const observer = new IntersectionObserver(observerCallback, observerOptions);
//   const targets = document.querySelectorAll('.sec3_container, .sec3_container_rev');
//   targets.forEach(target => observer.observe(target));
// }

function initializeScrollableCards(section11Container) {
	const scrollable = section11Container.querySelector(
		".section8_cards_scrollable"
	);
	const cards = section11Container.querySelectorAll(
		".section8_card,.section8_media_card"
	);
	if (!scrollable || cards.length === 0) return; // Ensure elements exist

	generatePaginationDots(scrollable, Math.ceil(cards.length / 3)); // Adjust for 3 cards at a time
	setupArrowButtons(scrollable, cards);
	setupScrollListener(scrollable, cards, section11Container);
}

function setupArrowButtons(scrollable, cards) {
	const leftBtn = scrollable.parentNode.querySelector(".scroll_btn.left");
	const rightBtn = scrollable.parentNode.querySelector(".scroll_btn.right");

	const cardWidth =
		cards[0].offsetWidth +
		parseInt(window.getComputedStyle(cards[0]).marginRight);
	const scrollAmount = cardWidth * 3;

	leftBtn.addEventListener("click", () =>
		scrollable.scrollBy({ left: -scrollAmount, behavior: "smooth" })
	);
	rightBtn.addEventListener("click", () =>
		scrollable.scrollBy({ left: scrollAmount, behavior: "smooth" })
	);
}

function generatePaginationDots(scrollable, numberOfPages) {
	const paginationDotsContainer =
		scrollable.parentNode.querySelector(".pagination_dots");
	paginationDotsContainer.innerHTML = ""; // Clear existing dots
	for (let i = 0; i < numberOfPages; i++) {
		const dot = document.createElement("div");
		dot.classList.add("pagination_dot");
		paginationDotsContainer.appendChild(dot);
	}
}

function updateActiveDot(index, selector) {
	const dots = selector.querySelectorAll(".pagination_dot");
	dots.forEach((dot, i) => {
		dot.classList.toggle("active", i === index);
	});
}

function setupScrollListener(scrollable, cards, parent_elector) {
	const numberOfCardsPerView = 3; // Number of cards you want to show in one view
	const totalScrollableWidth = scrollable.scrollWidth - scrollable.offsetWidth;
	const numberOfPages = Math.ceil(cards.length / numberOfCardsPerView);

	scrollable.addEventListener("scroll", () => {
		const scrollPosition = scrollable.scrollLeft;
		const percentageScrolled = scrollPosition / totalScrollableWidth;

		// Calculate active index based on the percentage of the total scrollable area that's been scrolled
		let activeIndex = Math.round((numberOfPages - 1) * percentageScrolled);

		// Ensure activeIndex stays within bounds
		activeIndex = Math.max(0, Math.min(activeIndex, numberOfPages - 1));

		updateActiveDot(activeIndex, parent_elector);
	});
}
export function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		made_in_india_carousel();
		handle_review_blur_highlighted();
		const section11Containers = document.querySelectorAll(
			".section11,div.section8"
		);
		section11Containers.forEach((container) => {
			initializeScrollableCards(container);
		});

		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

const Landing_page_m1 = () => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const { height, width } = useWindowDimensions();

	return (
		<>
			<Helmet>
				<title>MyEra | Commerce Plugins</title>
				<meta
					name="description"
					content="Discover the MyEra Button – a seamless personalization tool for your online store. Empower buyers to customize products on your site effortlessly.
              From colors to graphics, enhance engagement and reduce returns. Explore new features like the MyEra Quote Calculator and AR-based visualization. MyEra: Elevating retail experiences across diverse platforms."
				/>
				<meta
					name="keywords"
					content="Software, plugin, e-commerce, Shopify, api, customization, customisation, virtual try-on, look-book, wordpress, api-integration, saas, MyEra, my-era, personalization, visualisation, visualization, woo-commerce, hampers, eyewear, bundle"
				/>
			</Helmet>
			<Modal isOpen={modalIsOpen} style={modalStyles}>
				<div className="modal-content">
					<div
						className="close-container"
						onClick={() => setModalIsOpen(false)}
					>
						<Close />
					</div>
					<img
						src={maintanance}
						className="popup-image"
						alt="site under maintenance"
					></img>
				</div>
			</Modal>
			<div className="page">
				<section className="section-1">
					<div className="top">
						<img src={logo} alt="logo" className="logo_myera" />
						<a
							href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
							target="_blank"
							rel="noreferrer"
							className={"href-no-hyper-link"}
						>
							<button>
								{" "}
								Get MyEra <img src={warrld} alt="arrow icon" />{" "}
							</button>
						</a>
					</div>

					<div className="white_rectangle">
						<div className="white_rectangle_left">
							<div className="boxx">
								<div className="white_rectangle_left_heading">
									Sell an experience before a product
								</div>

								<div className="white_rectangle_left_para">
									MyEra’s visual integration solutions let customers experience
									your product, before they buy it.
								</div>

								<a
									href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
									target="_blank"
									rel="noreferrer"
								>
									<button className="book_demo">
										Book a Demo <img src={warrld} alt="arrow icon" />{" "}
									</button>
								</a>
							</div>
						</div>

						<div className="white_rectangle_right">
							<video
								width={width < 768 ? "300px" : width < 1200 ? "350px" : null}
								autoPlay={true}
								loop
								muted
							>
								<source src={myerabanner} type="video/mp4" />
							</video>
						</div>
					</div>

					{/*<div className="rectangle_black">*/}
					{/*  <img src={blackberry} />*/}
					{/*  <img src={jwc} />*/}
					{/*  <img src={jaipurprinters} />*/}
					{/*  <img src={swageasy} />*/}
					{/*  <img src={swageasy} />*/}
					{/*  <img src={swageasy} />*/}
					{/*</div>*/}
				</section>
			</div>
			<div className="client-banner">
				<div className="client-banner-slide">
					<img src={blackberry} alt="blackberry logo" />
					<img src={jwc} alt="jaipur watch company logo" />
					<img src={jaipurprinters} alt="jaipur printers logo" />
					<img src={swageasy} alt="swageazy logo" />
					<img src={ugaoo} alt="ugaoo logo" />
					<img src={moochuu} alt="moochuu logo" />
					<img src={bpc} alt="box packaging logo" />
					<img src={bambooindia} alt="bamboo india logo" />
					<img src={bakingologo} alt="bakingo logo" />
					<img src={flowerauralogo} alt="floweraura logo" />
				</div>
				<div className="client-banner-slide">
					<img src={blackberry} alt="blackberry logo" />
					<img src={jwc} alt="jaipur watch company logo" />
					<img src={jaipurprinters} alt="jaipur printers logo" />
					<img src={swageasy} alt="swageazy logo" />
					<img src={ugaoo} alt="ugaoo logo" />
					<img src={moochuu} alt="moochuu logo" />
					<img src={bpc} alt="box packaging logo" />
					<img src={bambooindia} alt="bamboo india logo" />
					<img src={bakingologo} alt="bakingo logo" />
					<img src={flowerauralogo} alt="floweraura logo" />
				</div>
			</div>

			<section className="section2">
				<div className="section2_container">
					<div className="section2_container_up">
						<div className="section2_container_up_left">
							<video className="features_video" autoPlay={true} loop muted>
								<source src={myeragifthamper} type="video/mp4" />
							</video>
						</div>

						<div className="section2_container_up_right">
							<h2 className="feature_head">Product Customization</h2>
							<p className="features">Accurate & realistic product preview</p>
							<p className="features">Seamless omnichannel Integration</p>
							<p className="features">Highly configurable</p>
							<p className="features">E-commerce platform agnostic</p>
						</div>
					</div>

					<a
						href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
						target="_blank"
						rel="noreferrer"
						className={"href-no-hyper-link"}
					>
						<div className="section2_container_down">
							<span className="exp_fet">Explore Features</span>
							<button className="arr">
								<img src={warrld} alt="arrow icon" />
							</button>
						</div>
					</a>
				</div>

				<div className="section2_container">
					<div className="section2_container_up">
						<div className="section2_container_up_left">
							<video className="features_video" autoPlay="autoplay" loop muted>
								<source src={virtualtryon} type="video/mp4" />
							</video>
						</div>

						<div className="section2_container_up_right">
							<h2 className="feature_head">Virtual Try-On</h2>
							<p className="features">
								Multi-category application (Apparels, Accessories, Decor and
								more)
							</p>
							<p className="features">Bundle SKUs and complete look books</p>
							<p className="features">Seamless omnichannel integration</p>
							<p className="features">Rapid product generation</p>
						</div>
					</div>

					<a
						href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
						target="_blank"
						rel="noreferrer"
						className={"href-no-hyper-link"}
					>
						<div className="section2_container_down">
							<span className="exp_fet">Explore Features</span>
							<button className="arr">
								<img src={warrld} alt="arrow icon" />
							</button>
						</div>
					</a>
				</div>
			</section>
			<section className="section2">
				<div className="section2_container">
					<div className="section2_container_up">
						<div className="section2_container_up_left">
							<video className="features_video" autoPlay={true} loop muted>
								<source src={interior_mp4} type="video/mp4" />
							</video>
						</div>

						<div className="section2_container_up_right">
							<h2 className="feature_head">Interior Visualization</h2>
							<p className="features">Turn material images into a scene</p>
							<p className="features">Dynamic image transformation</p>
							<p className="features">Visualization over imagination</p>
							<p className="features">Plugin play technology</p>
						</div>
					</div>

					<a
						href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
						target="_blank"
						rel="noreferrer"
						className={"href-no-hyper-link"}
					>
						<div className="section2_container_down">
							<span className="exp_fet">Explore Features</span>
							<button className="arr">
								<img src={warrld} alt="arrow icon" />
							</button>
						</div>
					</a>
				</div>

				<div className="section2_container">
					<div className="section2_container_up">
						<div className="section2_container_up_left">
							<video className="features_video" autoPlay="autoplay" loop muted>
								<source src={quote_cal} type="video/mp4" />
							</video>
						</div>

						<div className="section2_container_up_right">
							<h2 className="feature_head">Quote calculator</h2>
							<p className="features">Interactive User Journey</p>
							<p className="features">Converts formulas into simple wizard</p>
							<p className="features">Realtime quote presentation</p>
							<p className="features">Scalable & Customizable</p>
						</div>
					</div>

					<a
						href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
						target="_blank"
						rel="noreferrer"
						className={"href-no-hyper-link"}
					>
						<div className="section2_container_down">
							<span className="exp_fet">Explore Features</span>
							<button className="arr">
								<img src={warrld} alt="arrow icon" />
							</button>
						</div>
					</a>
				</div>
			</section>

			<section className="section3">
				<div className="sec3_head">
					Customers create their own product & buy it too
				</div>
				<div className="sec3_para">
					MyEra’s Product Customizer delivers real-time custom product
					visualisation that translates into remarkable conversions. It’s like
					selling personalised products with less effort than a panda.
				</div>

				<div className="sec3_outer_container">
					<div className="sec3_container">
						<div className="sec3_container_left">
							<img src={customizer} alt="myera customizer" />
						</div>
						<div className="sec3_container_right">
							<p>Product Customization</p>
							<div className="sec3_container_right_head">
								Boost your conversion rates by up to 250%
							</div>
							<div className="sec3_container_right_para">
								Statistics claim personalisation lifts revenue by 10% to 30%
								with lasting loyalty. When introduced to personalised designs,
								retail customers drive a cycle of creation, visualisation,
								purchase, re-purchase and even recommendation, and thus a slack
								in the return rate.{" "}
							</div>
							<a
								href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
								target="_blank"
								rel="noreferrer"
							>
								<button className="sec3_container_right_btn">
									Book a Demo <img src={warrld} alt="arrow icon" />
								</button>
							</a>
						</div>
					</div>

					<div className="sec3_container sec3_container_rev">
						<div className="sec3_container_right_rev">
							<p>Product Customization</p>
							<div className="sec3_container_right_head">
								Go beyond the conventional catalogue
							</div>
							<div className="sec3_container_right_para">
								From naming birthday cakes to hoarding their favourite
								pop-culture merchandise, customers have always enjoyed
								personalisation. It’s time to open the world of unlimited
								possibilities with a virtual canvas for their designs.
							</div>
							<a
								href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
								target="_blank"
								rel="noreferrer"
							>
								<button className="sec3_container_right_btn">
									Book a Demo <img src={warrld} alt="arrow icon" />
								</button>
							</a>
						</div>

						<div className="sec3_container_left">
							<video autoPlay="autoplay" loop muted>
								<source src={lookbook} type="video/mp4" />
							</video>
						</div>
					</div>

					<div className="sec3_container">
						<div className="sec3_container_left">
							<img src={customizer} alt="customizer icon" />
						</div>
						<div className="sec3_container_right">
							<p>Product Customization</p>
							<div className="sec3_container_right_head">
								Make customers the real Custom-ers
							</div>
							<div className="sec3_container_right_para">
								Contemporary shoppers wish to see themselves as co-creators of
								unique products, more than just buyers. With a tech-led
								solution, you leverage customization as a tool for enhanced
								purchase experience within the e-commerce funnel.
							</div>
							<a
								href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
								target="_blank"
								rel="noreferrer"
							>
								<button className="sec3_container_right_btn">
									Book a Demo <img src={warrld} alt="arrow icon" />
								</button>
							</a>
						</div>
					</div>
				</div>

				<div className="sec3_head">
					Customers create their own product & buy it too
				</div>
				<div className="sec3_para">
					MyEra’s Product Customizer delivers real-time custom product
					visualisation that translates into remarkable conversions. It’s like
					selling personalised products with less effort than a panda.
				</div>

				<div className="sec3_container">
					<div className="sec3_container_left">
						<video autoPlay="autoplay" loop muted style={{ width: "inherit" }}>
							<source src={eyewear} type="video/mp4" />
						</video>
					</div>
					<div className="sec3_container_right">
						<p>Virtual Try-On</p>
						<div className="sec3_container_right_head">
							Bring every product to life, for every customer
						</div>
						<div className="sec3_container_right_para">
							Virtual try-on serves as an experiential AR tool for customers to
							experience your products just like in a physical outlet, enabling
							them to try before they buy.
						</div>
						<a
							href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
							target="_blank"
							rel="noreferrer"
						>
							<button className="sec3_container_right_btn">
								Book a Demo <img src={warrld} alt="arrow icon" />
							</button>
						</a>
					</div>
				</div>

				<div className="sec3_container sec3_container_rev">
					<div className="sec3_container_right_rev">
						<p>Virtual Try-On</p>
						<div className="sec3_container_right_head">
							Build blocks of customer confidence
						</div>
						<div className="sec3_container_right_para">
							When you serve customers with an experience, you drive both
							attention and retention for the long term. No matter where they
							are in their journey, a virtual try-on will always be the
							catalyst.
						</div>
						<a
							href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
							target="_blank"
							rel="noreferrer"
						>
							<button className="sec3_container_right_btn">
								Book a Demo <img src={warrld} alt="arrow icon" />
							</button>
						</a>
					</div>

					<div className="sec3_container_left">
						<img src={customizer} alt="customizer" />
					</div>
				</div>

				<div className="sec3_container">
					<div className="sec3_container_left">
						<video autoPlay="autoplay" loop muted>
							<source src={lookbook} type="video/mp4" />
						</video>
					</div>
					<div className="sec3_container_right">
						<p>Virtual Try-On</p>
						<div className="sec3_container_right_head">
							Be ahead of the curve, or make your own
						</div>
						<div className="sec3_container_right_para">
							The Future is AR. Transform how online consumers make their
							shopping decisions by presenting an instant demo of your product
							and in turn, creating a robust revenue magnet.
						</div>
						<a
							href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
							target="_blank"
							rel="noreferrer"
						>
							<button className="sec3_container_right_btn">
								Book a Demo <img src={warrld} alt="arrow icon" />
							</button>
						</a>
					</div>
				</div>
			</section>
			<section className="section4">
				<div className="intigrate">
					<div className="intigrate_left">
						<div className="intigrate_written">
							How can your business integrate MyEra?
						</div>

						<div className="intigrate_logos">
							<div className="intigarate_logos_box">
								<div className="intigrate_logos_box_para">Schedule a demo</div>
								<div className="img_div">
									<img src={calender} alt="calendar" />
								</div>
							</div>
							<div className="intigarate_logos_box">
								<div className="intigrate_logos_box_para">
									Explain your query to our team
								</div>
								<div className="img_div">
									<img src={speach} alt="speech" />
								</div>
							</div>
							<div className="intigarate_logos_box">
								<div className="intigrate_logos_box_para">
									Test with your product
								</div>
								<div className="img_div">
									<img src={tv} alt="tv " />
								</div>
							</div>
							<div className="intigarate_logos_box">
								<div className="intigrate_logos_box_para">
									Deploy on your platform
								</div>
								<div className="img_div">
									<img src={message} alt="deploy" />
								</div>
							</div>
						</div>

						<a
							href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
							target="_blank"
							className="intigrate_demo"
							rel="noreferrer"
						>
							<button className="sec3_container_right_btn">
								Book a Demo <img src={warrld} alt="arrow icon" />
							</button>
						</a>
					</div>
					<div className="intigrate_right">
						<img src={maskgrp10} alt="integrate myera" />
					</div>
				</div>
			</section>

			<section className="section5">
				<div className="section5_head">MyEra brings your product to life</div>

				<div className="section5_container">
					<div className="section5_container_box">
						<div className="section5_container_boxI_img">
							<img className="img_set" src={productimage1} alt="technology " />
						</div>
						<div className="section5_container_box_head">
							Cutting-Edge Technology
						</div>
						<div className="section5_container_box_para">
							Our cutting-edge technology transforms flat product images into
							interactive
						</div>
					</div>

					<div className="section5_container_box">
						<div className="section5_container_boxI_img">
							<img
								className="img_set"
								src={productimage2}
								alt="customizable wonders "
							/>
						</div>
						<div className="section5_container_box_head">
							Customizable wonders
						</div>
						<div className="section5_container_box_para">
							Customizable wonders, letting customers design, personalize, and
							virtually try-on products
						</div>
					</div>

					<div className="section5_container_box">
						<div className="section5_container_boxI_img">
							<img
								className="img_set"
								src={productimage3}
								alt="future of commerce "
							/>
						</div>
						<div className="section5_container_box_head">
							Future of e-commerce
						</div>
						<div className="section5_container_box_para">
							With MyEra, where shopping elevates into a journey of
							self-expression!
						</div>
					</div>
				</div>
			</section>

			<section className="section6">
				<div className="section6_head">
					Multiple sellers and DTC brands trust MyEra. Here’s proof
				</div>

				<div className="section6_cards">
					<div className="section6_card">
						<div className="section6_img">
							<img src={colons} alt="" />
						</div>

						<div className="section6_card_para">
							We at Swageazy are really looking forward to collaborate and
							optimize the overall flow of creative & marketing tasks with MyEra
						</div>

						<div className="section6_card_review">
							<div className="section_card_review_left">
								<img src={review} alt="" />
							</div>

							<div className="section_card_review_right">
								<div>Sagar Bothra</div>
								<div className="hnm">Swageasy</div>
							</div>
						</div>
					</div>

					<div className="section6_card">
						<div className="section6_img">
							<img src={colons} alt="" />
						</div>

						<div className="section6_card_para">
							Working with MyEra has been absolutely amazing for Blackberrys.
							Their innovative MyEra Button API seamlessly integrated into our
							ShopifyPlus platform,{" "}
							<strong>
								helping us achieve a personalization module in no time and that
								too effortlessly.
							</strong>
							<br />
							<br /> The responsive MyEra team truly understands the business
							requirements and their passion to offer a personalized experience
							in the fashion industry is commendable.
							<br />
							<br /> We're excited about our future collaborations with MyEra
							and look forward to embarking on even bigger and more impactful
							projects like{" "}
							<strong>Lookbook, Occasion module, etc together!</strong>
						</div>

						<div className="section6_card_review">
							<div className="section_card_review_left">
								<img
									style={{
										width: "100%",
									}}
									src={blackberrysReview}
									alt=""
								/>
							</div>

							<div className="section_card_review_right">
								<div></div>
							</div>
						</div>
					</div>

					<div className="section6_card">
						<div className="section6_img">
							<img src={colons} alt="" />
						</div>

						<div className="section6_card_para">
							"Selecting customised gifting solution proved to be a pivotal
							decision. The user-friendly interface and innovative features
							surpassed my expectations. Thanks to MyEra customisable solution,
							I've achieved 50% jump in personalised gifting orders and the
							support team's responsiveness made the experience even better.
							Highly recommend it!"
						</div>

						<div className="section6_card_review">
							<div className="section_card_review_left">
								<img src={ugaoo_review} alt="" />
							</div>

							<div className="section_card_review_right">
								<div></div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="section7">
				{width < 688 ? (
					<div className="section7_container">
						<div className="section7_container_box1">
							<div>By the numbers, our achievements and impact</div>
						</div>
						<div className="section7_stats_box">
							<div className="section7_container_box2">
								<div>1000+</div>
								<span>Registered accounts</span>
							</div>
							<div className="section7_container_box2">
								<div>20+</div>
								<span>Business collaborations</span>
							</div>
							<div className="section7_container_box2">
								<div>60 Min</div>
								<span>Avg setup time</span>
							</div>
						</div>
					</div>
				) : (
					<div className="section7_container">
						<div className="section7_container_box1">
							<div>By the numbers, our achievements and impact</div>
						</div>
						<div className="section7_container_box2">
							<div>1000+</div>
							<span>Registered accounts</span>
						</div>
						<div className="section7_container_box2">
							<div>20+</div>
							<span>Bussiness collaborations</span>
						</div>
						<div className="section7_container_box2">
							<div>60 Min</div>
							<span>Avg setup time</span>
						</div>
					</div>
				)}
			</section>

			<section className="section8">
				<div className="section8_head">Choose plan that’s right for you</div>
				<div className="section8_in">
					<div className="section8_card">
						<div className="plan_name">
							<span>Customizer</span>
							<p>PRICING STARTS AT</p>
						</div>
						<div className="plan">
							<div className="plan_rate">$300</div>
							<div className="plan_duration">/Month</div>
						</div>
						<div className="plan_offers">
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">Setup cost: $600, one time</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">Integration in existing e-store</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">10 customizable products</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">Basic Ui personalization</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">
									On-demand development (chargeable)
								</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">
									Tech hand-holding & priority support
								</span>
							</div>
						</div>
						<a
							href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
							target="_blank"
							rel="noreferrer"
						>
							<button className="plan_button">
								Contact Sales <img src={warrld} alt="" />
							</button>
						</a>
					</div>

					<div className="section8_card">
						<div className="plan_name">
							<span>Virtual try-on</span>
							<p>PRICING STARTS AT</p>
						</div>
						<div className="plan">
							<div className="plan_rate">$500</div>
							<div className="plan_duration">/Month</div>
						</div>
						<div className="plan_offers">
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">Setup cost: $1000, one time</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">Integration in existing e-store</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">10 virtual trial products</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">Basic Ui personalization</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">
									On-demand development (chargeable)
								</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">
									Tech hand-holding & priority support
								</span>
							</div>
						</div>
						<a
							href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
							target="_blank"
							rel="noreferrer"
						>
							<button className="plan_button">
								Contact Sales <img src={warrld} alt="" />
							</button>
						</a>
					</div>

					<div className="section8_card">
						<div className="plan_name combo">
							<span>Combo</span>
							<p>PRICING STARTS AT</p>
						</div>
						<div className="plan">
							<div className="plan_rate">$600</div>
							<div className="plan_duration">/Month</div>
						</div>
						<div className="plan_offers">
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">Setup cost: $1200, one time</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">Integration in existing e-store</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">
									10 customizable, OR virtual trial products
								</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">Advanced Ui personalization</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">
									On-demand development (chargeable)
								</span>
							</div>
							<div>
								<span className="tick">
									<img src={tick} alt="" />
								</span>
								<span className="offer2">
									Tech hand-holding & priority support
								</span>
							</div>
						</div>
						<a
							href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
							target="_blank"
							rel="noreferrer"
						>
							<button className="plan_button">
								Contact Sales <img src={warrld} alt="" />
							</button>
						</a>
					</div>
				</div>
			</section>

			<section>
				<div className="section8">
					<div className="section8_head">Media Spotlight</div>
					<div className="section8_container">
						<div className="section8_media_in">
							<div className="section8_cards_scrollable">
								<div className="section8_media_card">
									<div className="section8_card_upper">
										<div className="section8_card_upper_img_box">
											<img src={inc42} alt="inc42 coverage" />
										</div>
										<div className="section8_card_upper_img_date"></div>
										<button className="section11_buttons">Inc42 Stories</button>
									</div>

									<hr className="hr" />

									<div className="section8_card_lower">
										<div>
											MyEra made it to Inc42 Media’s ‘30 Startups To Watch’
											list!
										</div>
										<a
											href="https://inc42.com/startups/30-startups-to-watch-startups-that-caught-our-eyes-in-january-2024/"
											target="_blank"
										>
											<button className="plan_button">
												View
												<img src={warrld} alt="view more" />
											</button>
										</a>
									</div>
								</div>

								<div className="section8_media_card">
									<div className="section8_card_upper">
										<div className="section8_card_upper_img_box">
											<img src={viestories} alt="vie story" />
										</div>
										<div className="section8_card_upper_img_date"></div>
										<button className="section11_buttons">Vie Stories</button>
									</div>

									<hr className="hr" />

									<div className="section8_card_lower">
										<div>
											[Funding alert] Mar-tech Startup MyEra Secures Pre-Seed
											Funding Led by EvolveX
										</div>
										<a
											href="https://viestories.com/mar-tech-myera-secures-pre-seed-funding-evolvex/"
											target="_blank"
										>
											<button className="plan_button">
												View
												<img src={warrld} alt="view more" />
											</button>
										</a>
									</div>
								</div>

								<div className="section8_media_card">
									<div className="section8_card_upper">
										<div className="section8_card_upper_img_box">
											<img src={startupstory} alt="startupstory" />
										</div>
										<div className="section8_card_upper_img_date"></div>
										<button className="section11_buttons">Startup Story</button>
									</div>

									<hr className="hr" />

									<div className="section8_card_lower">
										<div>
											MyEra, a Mar-tech startup, Secures Undisclosed Investment
											from EvolveX
										</div>

										<a
											href="https://startupstorymedia.com/insights-myera-a-mar-tech-startup-secures-undisclosed-investment-from-evolvex/"
											target="_blank"
										>
											<button className="plan_button">
												View
												<img src={warrld} alt="view more" />
											</button>
										</a>
									</div>
								</div>

								<div className="section8_media_card">
									<div className="section8_card_upper">
										<div className="section8_card_upper_img_box">
											<img
												src="https://quarkme-assets.s3.amazonaws.com/media/Copy_of_MyEra_Apps_Intro_Video_-_YUG_2.gif"
												alt="myera"
											/>
										</div>
										<div className="section8_card_upper_img_date"></div>
										<button className="section11_buttons">Deal Street</button>
									</div>

									<hr className="hr" />

									<div className="section8_card_lower">
										<div>
											Asia Digest: East Ventures backs Campaniown; EvolveX
											invests in MyEra
										</div>

										<a
											href="https://www.dealstreetasia.com/stories/asia-digest-east-ventures-compawnion-evolvex-myera-369600?utm_content=buffere76fe&utm_medium=social&utm_source=linkedin.com&utm_campaign=buffer"
											target="_blank"
										>
											<button className="plan_button">
												View
												<img src={warrld} alt="view more" />
											</button>
										</a>
									</div>
								</div>

								<div className="section8_media_card">
									<div className="section8_card_upper">
										<div className="section8_card_upper_img_box">
											<img
												src="https://media.licdn.com/dms/image/D5622AQGLRkgC1Ndsng/feedshare-shrink_2048_1536/0/1699965241699?e=1704931200&v=beta&t=2rZcy1bGsbhYyIGFHnOEl081K9dKRrUmDDB_k8-L-kA"
												alt="myera "
											/>
										</div>
										<div className="section8_card_upper_img_date"></div>
										<button className="section11_buttons">HustleHub</button>
									</div>

									<hr className="hr" />

									<div className="section8_card_lower">
										<div>
											MyEra : A Milestone Achieved with Strategic Investment!
										</div>

										<a
											href="https://www.linkedin.com/feed/update/urn:li:activity:7130282693413548032/"
											target="_blank"
										>
											<button className="plan_button">
												View
												<img src={warrld} alt="view more" />
											</button>
										</a>
									</div>
								</div>

								<div className="section8_media_card">
									<div className="section8_card_upper">
										<div className="section8_card_upper_img_box">
											<img
												src="https://quarkme-assets.s3.amazonaws.com/media/Copy_of_MyEra_Apps_Intro_Video_-_YUG_2.gif"
												alt="yourstory"
											/>
										</div>
										<div className="section8_card_upper_img_date"></div>
										<button className="section11_buttons">Your Story</button>
									</div>

									<hr className="hr" />

									<div className="section8_card_lower">
										<div>
											New Delhi-based marketing tech startup MyEra has secured
											undisclosed funding from We Founder Circle's early-stage
											programme, EvolveX.
										</div>

										<a
											href="https://yourstory.com/2023/11/startup-news-and-updates-daily-roundup-november-1-2023"
											target="_blank"
										>
											<button className="plan_button">
												View
												<img src={warrld} alt="view more" />
											</button>
										</a>
									</div>
								</div>

								<div className="section8_media_card">
									<div className="section8_card_upper">
										<div className="section8_card_upper_img_box">
											<img src={viestories} alt="" />
										</div>
										<div className="section8_card_upper_img_date"></div>
										<button className="section11_buttons">
											Rajasthan Patrika
										</button>
									</div>

									<hr className="hr" />

									<div className="section8_card_lower">
										<div>
											Rajasthan Patrika covers MyEra and Co-founder Anirudh
											Sharma
										</div>

										<a
											href="https://www.linkedin.com/feed/update/urn:li:activity:7021414702341664769/?utm_source=share&utm_medium=member_desktop"
											target="_blank"
										>
											<button className="plan_button">
												View
												<img src={warrld} alt="" />
											</button>
										</a>
									</div>
								</div>
							</div>

							<div className="section8_controls">
								<div className="pagination_dots"></div>
								<div className="arrows">
									<button className="scroll_btn left">&lt;</button>
									<button className="scroll_btn right">&gt;</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="section9">
				<div className="section9_head">Backed By</div>

				<div className="sponser_box">
					<a href="https://wefoundercircle.com/" target="_blank">
						<div className="sponser">
							<img src={wefounder} alt="we founder circle logo" />
						</div>
					</a>

					<a href="https://www.evolvexaccelerator.com/" target="_blank">
						<div className="sponser">
							<img src={evolvex} alt="evolvex logo" />
						</div>
					</a>

					<a href="https://istart.rajasthan.gov.in/" target="_blank">
						<div className="sponser">
							<img src={istart} alt="istart logo" />
						</div>
					</a>

					<a href="https://aicbimtech.com/" target="_blank">
						<div className="sponser">
							<img src={aicbt} alt="aic bimtech logo" />
						</div>
					</a>
				</div>
			</section>

			<section className="section10">
				<div className="section10_boxx">
					<div className="section10_heading">
						Try Personalized Shopify Try-ons & Customizations
					</div>

					<a
						type="button"
						href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
						className="section10_button href-no-hyper-link"
						target="_blank"
						rel="noreferrer"
					>
						Install MyEra Button <img src={warrld} alt=" arrow icon" />
					</a>
				</div>
			</section>

			<section className="section11">
				<div className="section11_head">
					Stay informed with our latest news & updates
				</div>
				<div className="section8_container">
					<div className="section8_in">
						<div className="section8_cards_scrollable">
							<div className="section8_card">
								<div className="section8_card_upper">
									<div className="section8_card_upper_img_box">
										<img
											src="https://quarkme-assets.s3.amazonaws.com/media/MyEra_Ugaoo.png"
											alt=""
										/>
									</div>
									<div className="section8_card_upper_img_date">
										<p>Published Date</p> <span>15/02/2024</span>
									</div>
								</div>

								<hr className="hr" />

								<div className="section8_card_lower">
									<div>
										Blooming Love: MyEra and Ugaoo's Valentine's Day Triumph
									</div>

									<a
										href="https://myera.pro/blog/14/ugaoo-hampers-for-valentines-day"
										target="_blank"
									>
										<button className="plan_button">
											View
											<img src={warrld} alt="" />
										</button>
									</a>
								</div>
							</div>
							<div className="section8_card">
								<div className="section8_card_upper">
									<div className="section8_card_upper_img_box">
										<img
											src="https://quarkme-assets.s3.amazonaws.com/media/MyEra_Button__Partner_Program_4.png"
											alt=""
										/>
									</div>
									<div className="section8_card_upper_img_date">
										<p>Published Date</p> <span>20-06-2023</span>
									</div>
								</div>

								<hr className="hr" />

								<div className="section8_card_lower">
									<div>
										Unleashing Opportunities: MyEra Button | Partner Program
										Launch
									</div>

									<a
										href="https://myera.pro/blog/12/MyEra-partner-program-for-shopify-experts-start-earning"
										target="_blank"
									>
										<button className="plan_button">
											View
											<img src={warrld} alt="" />
										</button>
									</a>
								</div>
							</div>
							<div className="section8_card">
								<div className="section8_card_upper">
									<div className="section8_card_upper_img_box">
										<img
											src="https://quarkme-assets.s3.amazonaws.com/media/MyEra_Button_Boost_Sales_Tvo57RR.png"
											alt=""
										/>
									</div>
									<div className="section8_card_upper_img_date">
										<p>Published Date</p> <span>01-05-23</span>
									</div>
								</div>

								<hr className="hr" />

								<div className="section8_card_lower">
									<div>Why MyEra Button? How can it Boost your sales?</div>

									<a
										href="https://myera.pro/blog/11/MyEra-Button-API-For-Better-sales-customer-retention"
										target="_blank"
									>
										<button className="plan_button">
											View
											<img src={warrld} alt="" />
										</button>
									</a>
								</div>
							</div>
							<div className="section8_card">
								<div className="section8_card_upper">
									<div className="section8_card_upper_img_box">
										<img
											src="https://quarkme-assets.s3.amazonaws.com/media/Copy_of_MyEra_Apps_Intro_Video_-_YUG_2.gif"
											alt=""
										/>
									</div>
									<div className="section8_card_upper_img_date">
										<p>Published Date</p> <span>21-02-2023</span>
									</div>
								</div>

								<hr className="hr" />

								<div className="section8_card_lower">
									<div>
										Create stunning mockups fast, no design team needed.
									</div>

									<a
										href="https://myera.pro/blog/8/MyEra_Discover_The_Power_of_Mockup_Generator"
										target="_blank"
									>
										<button className="plan_button">
											View
											<img src={warrld} alt="" />
										</button>
									</a>
								</div>
							</div>
						</div>
						<div class="section8_controls">
							<div class="pagination_dots"></div>
							<div class="arrows">
								<button class="scroll_btn left">&lt;</button>
								<button class="scroll_btn right">&gt;</button>
							</div>
						</div>
					</div>
				</div>
				{/* <div className='section11_imgs'>
          <a href="https://viestories.com/mar-tech-myera-secures-pre-seed-funding-evolvex/">
            <div className='section11_imgs_img1'>
              <button className='section11_buttons'>Vie Stories</button>
              <div className='section11_para'>
              [Funding alert] Mar-tech Startup MyEra Secures Pre-Seed Funding Led by EvolveX
              </div>
            </div>
          </a>

          <div className='section11_imgs_img2'>
            <button className='section11_buttons'>Startup Story</button>
            <div className='section11_para'>
              MyEra, a Mar-tech startup, Secures Undisclosed Investment from EvolveX
            </div>
          </div>

          <div className='section11_imgs_img3'>
            <button className='section11_buttons'>Medium</button>
            <div className='section11_para'>
              Why MyEra Button? How can it Boost your sales?
            </div>
          </div>

          <div className='section11_imgs_img3'>
            <button className='section11_buttons'>Medium</button>
            <div className='section11_para'>
              Why MyEra Button? How can it Boost your sales?
            </div>
          </div>

          <div className='section11_imgs_img3'>
            <button className='section11_buttons'>Medium</button>
            <div className='section11_para'>
              Why MyEra Button? How can it Boost your sales?
            </div>
          </div>

          <div className='section11_imgs_img3'>
            <button className='section11_buttons'>Medium</button>
            <div className='section11_para'>
              Why MyEra Button? How can it Boost your sales?
            </div>
          </div>
        </div> */}
			</section>

			<section className="love_india">
				<div className="carousel">
					<div className="carousel_item">Made with ❤️ in India!</div>
					<div className="carousel_item">Made with ❤️ in India!</div>
					<div className="carousel_item">Made with ❤️ in India!</div>
					<div className="carousel_item">Made with ❤️ in India!</div>
					<div className="carousel_item">Made with ❤️ in India!</div>
					<div className="carousel_item">Made with ❤️ in India!</div>
				</div>
			</section>

			<section className="footer">
				<div>
					<div className="footer_left">
						<div className="footer_left_img">
							<img src={logo} alt="myera logo" />
						</div>

						<div className="footer_left_para">
							MyEra Technologies Pvt. Ltd, 2nd Floor, Oahfeo Workspaces -
							Frappe1094, Huda Colony, Sector 46, Gurugram, Haryana 12201
						</div>

						<div className="footer_left_menu">
							<span>Products</span>
							<span>Pricing</span>
							<a
								href="mailto: support@myera.pro"
								className={"href-no-hyper-link"}
							>
								<span>Contact</span>
							</a>
							<a href="/blog" className={"href-no-hyper-link"}>
								<span>Blog</span>
							</a>
						</div>

						<div className="copyright">Copyright 2024 MyEra</div>
					</div>

					<div className="footer_right">
						{/*<div className="footer_left_img">Stay up to date</div>*/}
						
						{/*<div className="footer_input">*/}
						{/*	<div>Enter Your Email Address</div>*/}
						
						{/*	<button>*/}
						{/*		Subscribe <img src={arrld} alt="arrow icon" />*/}
						{/*	</button>*/}
						{/*</div>*/}

						<div>
							<div className="footer_right_icon">
								<a href="https://twitter.com/myerapro">
									<span>
										<img src={twitter} alt="Twitter" />
									</span>
								</a>
								<span>
									<a href="https://in.linkedin.com/company/myerapro">
										<img src={linkedin} alt="LinkedIn" />
									</a>
								</span>
								<span>
									<a href="https://instagram.com/myera.pro">
										<img src={insta} alt="Instagram" />
									</a>
								</span>
								<span>
									<a href="https://www.facebook.com/myera.pro">
										<img src={facebook} alt="Facebook" />
									</a>
								</span>
							</div>

						</div>
					</div>
				</div>

				<hr className="hr" />

				<div className="copyright_righteq">
					<a href="/refund-policy" className={"href-no-hyper-link"}>
						<span>Refund Policy</span>
					</a>
					<span>
						<a href="/terms-of-use">Terms of Services</a>
					</span>
					<span>
						<a href="/privacy-policy">Privacy Policy</a>
					</span>
				</div>
			</section>
		</>
	);
};

export default Landing_page_m1;
