export const login = async ({ email, password }) => {
	const response = await fetch(`${process.env.REACT_APP_BACKEND}/login/`, {
		// const response = await fetch('http://localhost:8000/quarkme/api/get_saved_creatives/', {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ email, password }),
	});

	return await response.json();
};

export const authenticate = async ({ response, email }, next) => {
	// console.log(response);
	if (typeof window !== "undefined") {
		if ("user_data" in response) {
			localStorage.setItem(
				"Erajwt",
				JSON.stringify({
					email,
					accessToken: response.access,
					refreshToken: response.refresh,
					first_name: response.first_name,
					brand_name: response.user_data.brand_name,
					brand_logo: response.user_data.brand_logo,
					userRegistered: response.user_data.isPremium,
					is_facebook_linked: response.user_data.is_facebook_linked,
					insta_username: `instagram:${response.socail_data.insta_username}`,
					fb_page_name: `facebook:${response.socail_data.page_name}`,
					twitter_name: `twitter:${response.socail_data.twitter_name}`,
					is_linkedin_linked: response.user_data.is_linkedin_linked,
					is_linkedin_org_linked: response.user_data.is_linkedin_org_linked,
					is_twitter_linked: response.user_data.is_twitter_linked,
					linkedin_org_name: `linkedin_org:${response.socail_data.linkedin_org_name}`,
					linkedin_profile_name: `linkedin_profile:${response.socail_data.linkedin_profile_name}`,
				})
			);
		} else {
			localStorage.setItem(
				"Erajwt",
				JSON.stringify({
					email,
					accessToken: response.access,
					refreshToken: response.refresh,
					first_name: response.first_name,
				})
			);
		}

		next();
	}
};

export const isAuthenticated = () => {
	if (typeof window === "undefined") {
		return false;
	}
	if (localStorage.getItem("Erajwt")) {
		return JSON.parse(localStorage.getItem("Erajwt"));
	} else {
		return false;
	}
};

export const shopifyUrlExists = () => {
	if (typeof window === "undefined") {
		return false;
	}
	if (localStorage.getItem("Userstore")) {
		return JSON.parse(localStorage.getItem("Userstore"));
	} else {
		return false;
	}
};

var ApiAuthenticateToken = "";

export const isAuthenticatedApi = async (accessToken) => {
	const response = await fetch(
		`${process.env.REACT_APP_BACKEND}/is_app_token/`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);
	ApiAuthenticateToken = accessToken;
	return response;
};

export const ApiToken = () => {
	return { accessToken: ApiAuthenticateToken };
};

export const signup = async ({ first_name, last_name, email, password }) => {
	if (typeof window !== "undefined") {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND}/register/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ first_name, last_name, email, password }),
				}
			);

			return await response.json();
		} catch (err) {
			console.log("error: ", err);
			return console.log("Email ID Already exists");
		}
	}
};

export const Shopifysignup = async ({ email, storeURL, password }) => {
	if (typeof window !== "undefined") {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND}/shopify_update_email/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ email, storeURL, password }),
				}
			);

			return await response.json();
		} catch (err) {
			console.log("error: ", err);
			return console.log("Email ID Already exists");
		}
	}
};

export const signout = async (next) => {
	if (typeof window !== "undefined") {
		const { accessToken } = isAuthenticated();
		localStorage.removeItem("Erajwt");
		next();

		const response = await fetch(
			`${process.env.REACT_APP_BACKEND}/logout_user`,
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		console.log("Logout " + response);
	}
};

export const forgotPassword = async ({ email }, next) => {
	if (typeof window !== "undefined") {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND}/forgot_pass/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ email }),
				}
			);

			return await response.json();
		} catch (err) {
			return console.log(err);
		}
	}
};

export const updatePassword = async (
	{ email, otp, password, confirm_password },
	next
) => {
	if (typeof window !== "undefined") {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND}/update_pass/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ email, otp, password, confirm_password }),
				}
			);

			return await response.json();
		} catch (err) {
			return console.log(err);
		}
	}
};
