import React, { useEffect } from "react";
import Logo from "../Images/myEra_NewLogo.png";
import { Link } from "react-router-dom";
// import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Era_Elephant from "../Images/Era_Elephant.png";
import * as Yup from "yup";
import "./login.css";
import { useFormik } from "formik";
import { login, authenticate } from "../../Helper/Endpoints/Endpoints";

const validationSchema = Yup.object({
	email: Yup.string()
		.email("must be a valid email")
		.max(255)
		.required("required"),
	password: Yup.string().min(6).required("required"),
});

export default function Login(props) {
	if (document.getElementById("outer-container"))
		document.getElementById("outer-container").style.backgroundColor =
			"rgba(11, 17, 32, 1)";

	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema,
		onSubmit: async ({ email, password }) => {
			login({ email, password }).then((response) => {
				if (response.error) {
					formik.setErrors({ email: "Email and password do not match" });
					return;
				}
				authenticate({ response, email }, () => {
					props.history.push({
						pathname: `/dashboard`,
					});
				});
				// window.location.reload();
			});
		},
	});

	useEffect(() => {
		document.title = "MyEra | Login";
	}, []);

	return (
		<div>
			<div className="mobilenave_outer">
				<Link to="/">
					<img
						class="eralogo"
						src={Logo}
						alt="Logo"
						style={{ marginTop: "4px", marginRight: "5px" }}
					/>
				</Link>
			</div>
			<div>
				<div class="EraElephantDiv">
					<img class="EraElephant_img" src={Era_Elephant} style={{}} alt="" />
				</div>
			</div>

			<div class="Wrapperdiv">
				<div class="bodycontainer">
					<div class="Bodycontainer_inner">Login to your account</div>
					<div class=" InputLabel" style={{ textAlign: "center" }}>
						See your social media growth and get creatives
					</div>
					<div class="outside_container" style={{}}>
						<div class="InputLabel">Email Address*</div>
						<div class="inputwrapper">
							<input
								class="input_email Inputs"
								id="email"
								value={formik.values.email}
								onChange={formik.handleChange}
								type="email"
							/>
							{formik.errors.email && formik.touched.email && (
								<p>{formik.errors.email}</p>
							)}
						</div>
						<div class="InputLabel">Password*</div>
						<div class="inputwrapper">
							<input
								class="input_pass Inputs"
								id="password"
								value={formik.values.password}
								type="password"
								onChange={formik.handleChange}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										formik.handleSubmit();
									}
								}}
							/>
							{formik.errors.password && formik.touched.password && (
								<p style={{}}>{formik.errors.password}</p>
							)}
						</div>
						<div class="inner_container" style={{}}>
							<div class="inner_container_1" style={{}}>
								<input class="input_remember" type="checkbox" style={{}} />
								<div class="InputLabel"> Remember Me</div>
							</div>
							<Link class="link_forgotPass" to="/forgot-password" style={{}}>
								<div class="label_forgotPass InputLabel" style={{}}>
									{" "}
									Forgot Password ?
								</div>
							</Link>
						</div>
					</div>

					<div class="ButtonWrapper">
						<Button
							class="submit_button"
							style={{}}
							variant="contained"
							color="secondary"
							type="button"
							fullWidth={true}
							onClick={() => {
								formik.handleSubmit();
							}}
						>
							Continue
						</Button>

						<div class="submit_outer">
							<div class="submit_outer_input">Not registered yet?</div>
							<Link class="submit_link" to="/signup">
								<div class="submit_inner_input">Create an account</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
