import React from "react";
import styles from "./Tabs.module.css";
import { TextField } from "@mui/material";

const VariantTab = ({
	title,
	description,
	options,
	onSelect,
	selectedOption,
	isMulti,
	onChangePantone,
	pantoneCount,
}) => {
	return (
		<div className={styles.tab_container}>
			<div className={styles.title}>{title}</div>
			<div className={styles.subtitle}>{description}</div>
			<div className={styles.chips_grid}>
				{options.map((option) => {
					return (
						<div
							className={[
								styles.chip,
								styles.image,
								isMulti &&
									(selectedOption || []).includes(option.option_code) &&
									styles.selected,
								!isMulti &&
									selectedOption === option.option_code &&
									styles.selected,
							].join(" ")}
							onClick={() => onSelect(option)}
						>
							{option.image && <img src={option.image} />}
							<div>
								<div className={styles.chip__title}>{option.label}</div>
								<div className={styles.chip__description}>
									{option.description}
								</div>
							</div>
						</div>
					);
				})}
			</div>
			{title.toLowerCase() === "colors" &&
				selectedOption &&
				selectedOption.toLowerCase().includes("pantone") && (
					<TextField
						label="Pantone Colors"
						variant="outlined"
						type="number"
						value={pantoneCount}
						sx={{
							marginTop: "24px",
						}}
						onChange={(e) => onChangePantone(e.target.value)}
					/>
				)}
		</div>
	);
};

export default VariantTab;
