// import React from 'react';
import React, {  useEffect } from "react";
import './AboutUs.css'
import facebook from "../Components/Images/facebook.png"; 
import twitter from "../Components/Images/twitter.png";
import logo from "../Components/desktop view/assets/logo.png";
import warrld from "../Components/desktop view/assets/warrld.png";
import abhiavatar from "./abhiAvatar.jpeg"
import arrld from "../Components/desktop view/assets/arrld.png";
import linkedin from "../Components/desktop view/assets/linkedin.png";
import insta from "../Components/desktop view/assets/insta.png";
import group from "../Components/desktop view/assets/Group.png";



export default function AboutUs(){
    const founderArray = [
      {name : "Anirudh Sharma", position : "Co-Founder & CEO", image: abhiavatar},
      {name : "Abhinandan Agrawal", position : "Co-Founder & CTO", image: abhiavatar},
      {name : "Shantanu Sriraj", position : "Co-Founder & CPO", image: abhiavatar},
      {name : "Yug Dave", position : "Co-Founder & COO", image: abhiavatar}
    ];
    useEffect(() => { 
      document.title = "MyEra | About Us"; 
    }, []);

    return(
        <>
          <div className="top">
            <img src={logo} alt="logo" className="logo_myera" />
            <a
                href="https://myera-40158928.hubspotpagebuilder.com/schedule-a-demo-myera-product-customizer-button"
                target="_blank"
                rel="noreferrer"
                className={"href-no-hyper-link"}
            >
              <button>
                {" "}
                Get MyEra <img src={warrld} alt="arrow icon" />{" "}
              </button>
            </a>
          </div>
        <div className="heading">About Us</div>
        <div className="content">
          Welcome to MyEra, where we revolutionize commerce with innovative, no/low-code plugins designed for platforms like Shopify, WooCommerce, and React.
          Our applications streamline every step of the e-commerce process, offering solutions for product personalization, enhanced visualization, bundle
          customization, virtual trials, B2B quote calculations, and more.
        </div>

          <div className="heading">Our Journey</div>
          <div className="content">
            Our founders, Anirudh, Abhinandan, Yug, and Shantanu, bring over a decade of collaborative experience, having built successful ventures like QuarkMe.
            Their deep domain expertise and relentless commitment drive our mission to become the Google Workspace of commerce applications.
          </div>


            <div className="founder-section">
                <div className="founder-box">
                    {founderArray.map((el,i)=>
                        (
                            <div className="founder-box-details">
                                <div className="founder-box-image">
                                    <img src={el.image} alt={el.name} />
                                </div>
                                <div className="founder-box-content">
                                    <h3>{el.name}</h3>
                                    <h3>{el.position}</h3>
                                </div>
                            </div>


                        ))}
                </div>
            </div>

            <section className="footer">
                <div>
                    <div className="footer_left">
                        <div className="footer_left_img">
                            <img src={logo} alt="myera logo" />
                        </div>

                        <div className="footer_left_para">
                            MyEra Technologies Pvt. Ltd, 2nd Floor, Oahfeo Workspaces -
                            Frappe1094, Huda Colony, Sector 46, Gurugram, Haryana 12201
                        </div>

                        <div className="footer_left_menu">
                            <span>Products</span>
                            <span>Pricing</span>
                            <a
                                href="mailto: support@myera.pro"
                                className={"href-no-hyper-link"}
                            >
                                <span>Contact</span>
                            </a>
                            <a href="/blog" className={"href-no-hyper-link"}>
                                <span>Blog</span>
                            </a>
                        </div>

                        <div className="copyright">Copyright 2024 MyEra</div>
                    </div>

                    <div className="footer_right">
                        <div className="footer_left_img">Stay up to date</div>

                        <div className="footer_input">
                            <div>Enter Your Email Address</div>

                            <button>
                                Subscribe <img src={arrld} alt="arrow icon" />
                            </button>
                        </div>

                        <div>
                            <div className="footer_right_icon">
                                <a href="https://twitter.com/myerapro">
									<span>
										<img src={twitter} alt="Twitter" />
									</span>
                                </a>
                                <span>
									<a href="https://in.linkedin.com/company/myerapro">
										<img src={linkedin} alt="LinkedIn" />
									</a>
								</span>
                                <span>
									<a href="https://instagram.com/myera.pro">
										<img src={insta} alt="Instagram" />
									</a>
								</span>
                                <span>
									<a href="https://www.facebook.com/myera.pro">
										<img src={facebook} alt="Facebook" />
									</a>
								</span>
                            </div>
                            <div className="footer_right_img">
                                <a
                                    href="https://www.producthunt.com/posts/myera?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-myera"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={group} alt="myera at product hunt" />{" "}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <hr className="hr" />

                <div className="copyright_righteq">
                    <a href="/refund-policy" className={"href-no-hyper-link"}>
                        <span>Refund Policy</span>
                    </a>
                    <span>
						<a href="/terms-of-use">Terms of Services</a>
					</span>
                    <span>
						<a href="/privacy-policy">Privacy Policy</a>
					</span>
                </div>
            </section>

        </>
    )
}