import React, { useState, useEffect } from "react";
// import SummaryLeftArrow from "../Images/SummaryLeftArrow.svg";
// import SummaryRightArrow from "../Images/SummaryRightArrow.svg";
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	// CartesianGrid,
	Tooltip,
} from "recharts";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { isAuthenticated } from "../../Helper/Endpoints/Endpoints";
import { toast } from "react-toastify";
import Polygon from "../Images/Polygon.svg";
import DotMenu from "../Images/DotMenu.svg";
import FireIcon from "../Images/FireIcon.png";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import InstaRectangle from "../Images/InstaRectangle.png";
// import InstaMobileRectangle from "../Images/InstaMobileRectangle.png";
import {
	CircularProgressbarWithChildren,
	buildStyles,
} from "react-circular-progressbar";
import Axios from "axios";
// import CampaignModal from "../Campaign_editor/CampaignModal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import Select from "react-select";
// import { LinearProgress } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import "./Overview.css";
import swal from "sweetalert";
import LaptopIcon from "@mui/icons-material/Laptop";
// import { select } from "@syncfusion/ej2-react-schedule";

// const ClippedDrawer = styled.div`
//   background: rgba(11, 17, 32, 1);
//   border-radius: 15px;
//   width: 40vh;
//   height: 98vh;
//   margin-top: 1vh;
//   margin-left: 1vh;
// `;

// const EraLogo = styled.img`
//   margin-top: -3vh;
//   margin-right: -5vh;
//   margin-left: 4vh;
//   height: 15vh;
//   width: 15vh;
// `;

// const Text = styled.div`
//   color: white;
//   font-weight: 549;
//   margin-top: auto;
//   margin-bottom: auto;
//   margin-left: 1vh;
//   font-size: 3vh;
// `;

// const TextDiv = styled.div`
//   color: white;
//   margin: 2vh 0;
// `;

// const CreateDiv = styled.div`
//   background-color: rgba(21, 43, 67, 1);
//   border-radius: 15px;
//   width: 85%;
//   height: 30vh;
//   margin: 3.5vh auto;
// `;

// const CreateContainer = styled.div`
//   margin: auto;
//   text-align: left;
//   width: 80%;
// `;

const Wrapper = styled.div`
	display: flex;
	background: rgb(13, 16, 22);
`;

// const MainDashboard = styled.div`
//   width: 100%;
//   justify-content: space-evenly;
// `;

// const NavDiv = styled.div`
//   justify-content: space-between;
//   margin: 3vh auto;
//   width: 90%;
//   display: flex;
//   background-color: rgba(234, 242, 255, 0.8);
//   height: 7vh;
// `;

// const BellImg = styled.img`
//   height: 4vh;
// `;

// const SettingImg = styled.img`
//   height: 4vh;
// `;

const LeftDiv = styled.div`
	width: 50%;
	height: 90%;
`;

const RightDiv = styled.div`
	text-align: center;

	width: 40%;
	// height: 90%;
`;

const StatsDiv = styled.div`
	width: 94%;
	margin: 5vh auto;
	height: 19rem;
	// height: 45%;
	display: flex;
	justify-content: space-between;
`;

const CircularProgressbarWrapper = styled.div`
	width: 43%;
	margin: 1vh auto;
`;

const BenchMarkScoreDiv = styled.div`
	height: 100%;
	width: 48%;
	background-color: rgba(98, 77, 232, 1);
	border-radius: 15px;
`;

// const BenchMarkScoreMobileDiv = styled.div`
//   height: 100%;
//   margin-top: 3vh;
//   background-color: rgba(98, 77, 232, 1);
//   border-radius: 15px;
// `;

const StatisticDivsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;

	width: 50%;
	justify-content: space-evenly;
`;

const StatisticDiv = styled.div`
	border-radius: 15px;

	width: 45%;
	height: 47%;
	background-color: rgba(255, 255, 255, 1);
`;

const FireIconWrapper = styled.div`
	width: 80%;
	font-size: 1.2vh;
	margin: 2vh auto 1vh auto;
	display: flex;
`;

// const WelcomeDiv = styled.div`
//   background-image: url(${InstaRectangle});
//   background-repeat: no-repeat;
//   background-position: right;
//   width: 94%;
//   margin: 5vh auto;
//   height: 45%;
//   background-color: rgba(11, 17, 32, 1);
//   width: 98%%;

//   border-radius: 15px;
// `;

const TrendDiv = styled.div`
	margin: 5vh 10%;
	height: 36%;
	width: 90%;
	background-color: rgba(11, 17, 32, 1);
	border-radius: 15px;
`;

const TrendDivBody = styled.div`
	margin-left: 4vh;
	text-align: left;
	width: 100%;
`;

const PostSUmmaryDiv = styled.div`
	width: 94%;
	margin: 5vh 10%;
	height: 45%;

	width: 98%%;
`;

// const BackNextButton = styled.div`
//   cursor: pointer;
//   display: flex;
//   height: 5vh;
//   width: 5vh;
//   border: solid 0.5px rgba(1, 15, 30, 1);
//   border-radius: 500px;
//   margin-right: 2vh;
// `;

// const DrawerOption = styled.div`
//   font-family: Open Sans;
//   padding: 1.7vh 3vh;
//   color: rgba(132, 232, 244, 0.4);
//   font-size: 1.8vh;
//   display: flex;
// `;

// const DrawerOptionMobile = styled.div`
//   font-family: Open Sans;
//   padding: 3vh 2.5vh;
//   color: rgba(132, 232, 244, 0.4);
//   font-size: 1.8vh;
//   display: flex;
// `;

// const MobileFooterNav = styled.div`
//   position: fixed;
//   bottom: 0;
//   width: 100%;
//   height: 11vh;
//   background-color: rgba(255, 255, 255, 1);
// `;

// const MobileWelcomeDiv = styled.div`
//   height: 100vh;
//   width: 100%;
//   background-color: rgba(11, 17, 32, 1);
//   background-image: url(${InstaMobileRectangle});
//   background-position-x: right;
//   background-position-y: 8vh;
//   background-repeat: no-repeat;
//   margin-bottom: 5vh;
// `;

// const MobileBodyDiv = styled.div`
//   width: 100%;
//   background-color: rgba(255, 255, 255, 1);
//   border-radius: 5vh 5vh 0 0;
// `;
// const WelcomeBody = styled.div`
//   margin-left: 4vh;
//   padding-top: 6vh;
//   width: 50%;
// `;

const FluroscentTag = styled.button`
	border-radius: 15px;
	border: none;
	padding: 0.5vh 2vh;
	background-color: rgba(7, 26, 48, 1);
	color: rgba(255, 255, 255, 0.31);
	margin-right: 0;
	font-size: 1.5vh;
	cursor: pointer;
`;

const Card = styled.div`
	border-radius: 14px;
	background-color: #2c303a;
	max-height: 30vh;
	margin-bottom: 15vh;
	padding: 0;
`;
// margin-bottom: 15vh;

const customStyles = {
	control: (_, { selectProps: { width } }) => ({
		display: "flex",
		color: "rgba(255, 255, 255, 1)",
		borderRadius: "100px",
		backgroundColor: "#343943",
		// backgroundColor: "red",
		height: "36px",
		fontSize: "14px",
		width: "145px",
	}),
	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";

		return {
			...provided,
			opacity,
			transition,
			// backgroundColor:'red',
			color: "white",
			fontFamily: "Montserrat",
		};
	},
};

const initialState = {
	industries: [],
	// places: 'worldwide',
	places: require("./places_data_modified.json"),

	tags: [],

	selectedPlace: {
		placeType: {
			code: 12,
			name: "Country",
		},
		url: "http://where.yahooapis.com/v1/place/23424848",
		parentid: 1,
		country: "India",
		woeid: 23424848,
		countryCode: "IN",
		label: "India",
		value: "India",
	},

	fetchingTags: true,
};

export default function Overview(props) {
	const [postSummaryIndex, setPostSummaryIndex] = useState(0);
	const [instaPosts, setInstaPosts] = useState([{ id: 0 }]);
	const [postSummaryImage, setPostSummaryImage] = useState(null);
	const [inLoading, setInLoading] = useState(false);
	const [score, setScore] = useState("");
	const [profilViews, setProfileViews] = useState(0);
	const [impressions, setImpressions] = useState(0);
	const [reach, setReach] = useState(0);
	const [postSummaryEngagement, setPostSummaryEngagement] = useState(0);
	const [postSummaryEngagementChange, setPostSummaryEngagementChange] =
		useState(0);
	const [postSummaryReach, setPostSummaryReach] = useState(0);
	const [postSummaryReachChange, setPostSummaryReachChange] = useState(0);
	const [followerCount, setFollowerCount] = useState(0);
	// const [trendPlatform, setTrendPlatform] = useState("Instagram");
	const [trendPlatform, setTrendPlatform] = useState("Twitter");
	const [mobileview, setMobileView] = useState(false);
	const [hashtagDisplay, sethashtagDisplay] = useState(initialState);
	const [platformButtons, setPlatformButtons] = useState([
		{ value: "Twitter", label: "Twitter" },
		{ value: "Facebook", label: "Facebook", isdisabled: true },
		{ value: "Instagram", label: "Instagram", isdisabled: true },
		{ value: "LinkedIn", label: "LinkedIn", isdisabled: true },
	]);

	// const [showLogin, setShowLogin] = useState("");
	// const [openSidebar, setOpenSidebar] = useState(false);

	const [graphData, setGraphData] = useState([
		{
			name: "Page A",
			score: 4000,
		},
	]);

	const { accessToken } = isAuthenticated();

	useEffect(() => {
		loadAllLocationHashTag();
		if (window.innerWidth >= 1200) setMobileView(false);
		else setMobileView(true);
	}, []);

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 1200) {
				setMobileView(false);
				window.reload();
			} else setMobileView(true);
		};
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const userData = JSON.parse(localStorage.getItem("Erajwt"));
		// console.log("userData", userData);
		const facebookLinked = userData["is_facebook_linked"];
		// console.log(facebookLinked);
		Axios({
			method: "get",
			url: `${process.env.REACT_APP_BACKEND}/is_token_expired/`,
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		})
			.then((response) => {
				// console.log("TOKEN EXPIRED: ", response.data.isTokenEpired);
				if (response.data.isTokenEpired) {
					//console.log("LOG OUT");
					localStorage.removeItem("Erajwt");
					localStorage.clear();
					props.history.push("/login");
				}
			})
			.catch((err) => {
				//console.log("ERROR: ", err);
				//console.log("LOG OUT");
				localStorage.removeItem("Erajwt");
				localStorage.clear();
				props.history.push("/login");
			});

		// if (!facebookLinked) {
		//   setShowLogin(1);
		// }

		async function getInsights() {
			if (facebookLinked) {
				// const { accessToken } = isAuthenticated();
				const requestOptions = {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				};

				Promise.all([
					fetch(
						`${process.env.REACT_APP_BACKEND}/get_total_followers/`,
						requestOptions
					).then((response) => response.json()),
					fetch(
						`${process.env.REACT_APP_BACKEND}/get_social_insights/day/`,
						requestOptions
					).then((response) => response.json()),
					fetch(
						`${process.env.REACT_APP_BACKEND}/get_insta_posts/`,
						requestOptions
					).then((response) => response.json()),
					fetch(
						`${process.env.REACT_APP_BACKEND}/get_insta_stats/`,
						requestOptions
					).then((response) => response.json()),
				]).then(([followerData, insightsData, postData, statsData]) => {
					setFollowerCount(followerData.followers_count);
					setInstaPosts(postData.data);
					setPostSummaryImage(postData.data[0].media_url);
					setScore(parseInt(statsData.score));
					setGraphData((graphData) => {
						let dataArray = statsData.graph_x_axis.map((element, index) => ({
							name: element.split("T")[0],
							score: statsData.graph_y_axis[index],
						}));
						return dataArray;
					});

					//console.log(insightsData);

					for (var insight of insightsData.insights) {
						if (insight.name === "impressions")
							setImpressions(insight.values[0].value);

						if (insight.name === "profile_views")
							setProfileViews(insight.values[0].value);

						if (insight.name === "reach") setReach(insight.values[0].value);
					}

					fetch(
						`${process.env.REACT_APP_BACKEND}/get_post_insights/` +
							postData.data[0].id +
							"/",
						requestOptions
					)
						.then((response) => response.json())
						.then((response) => {
							setPostSummaryEngagement(response.engagement);
							setPostSummaryReach(response.reach);
							if (postData.data.length >= 1) {
								fetch(
									`${process.env.REACT_APP_BACKEND}/get_post_insights/` +
										postData.data[1].id +
										"/",
									requestOptions
								)
									.then((resp) => resp.json())
									.then((data) => {
										//console.log(response.engagement + ' ' + data.engagement)
										if (data.engagement === 0)
											setPostSummaryEngagementChange(response.engagement * 100);
										else
											setPostSummaryEngagementChange(
												parseInt(
													((response.engagement - data.engagement) * 100) /
														data.engagement
												)
											);
										if (data.reach === 0)
											setPostSummaryReachChange(response.reach * 100);
										else
											setPostSummaryReachChange(
												parseInt(
													((response.reach - data.reach) * 100) / data.reach
												)
											);
									});
							}
						});
				});
			}
		}

		getInsights();

		document.title = "MyEra | Dashboard";
	}, []);

	// Modal.setAppElement("#root");

	// function openModal() {
	//   setIsOpen(true);
	// }

	// function closeModal() {
	//   setIsOpen(false);
	// }

	// const handleOpen = () => {
	//   setOpenSidebar(true);
	// };

	// const logout = () => {
	//   const { accessToken } = isAuthenticated();
	//   localStorage.removeItem("Erajwt");
	//   localStorage.clear();
	//   const response = fetch(`${process.env.REACT_APP_BACKEND}/logout_user`, {
	//     method: "GET",
	//     headers: {
	//       Authorization: `Bearer ${accessToken}`,
	//     },
	//   });
	//   //console.log("Logout " + response);
	//   props.history.push("/login");
	// };

	const responseFacebook = async (response) => {
		//console.log('Linking...')
		//console.log(response.accessToken)
		// const { accessToken } = isAuthenticated();
		var requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				access_token: response.accessToken,
			}),
		};

		fetch(`${process.env.REACT_APP_BACKEND}/link_facebook/`, requestOptions)
			.then((response) => response.json())
			.then((data) => {
				//console.log(data)
				var userData = JSON.parse(localStorage.getItem("Erajwt"));
				userData.is_facebook_linked = true;
				localStorage.setItem("Erajwt", JSON.stringify(userData));
				//console.log(JSON.parse(localStorage.getItem("Erajwt")))
				window.location.reload();
			});
		//console.log('linked')

		// var userData = JSON.parse(localStorage.getItem("Erajwt"))
		// userData.is_facebook_linked = true
		// localStorage.setItem('Erajwt', JSON.stringify(userData))

		// localStorage.setItem(
		//   "facebook_creds",
		//   JSON.stringify({
		//     accessToken: response.accessToken,
		//     userID: response.userID,
		//     name: response.name,
		//   })
		// );
		// const accounts_response = await Axios.get(
		//   `https://graph.facebook.com/v11.0/me?fields=id,name,accounts&access_token=${response.accessToken}`
		// );

		// localStorage.setItem(
		//   "facebook_pages",
		//   JSON.stringify({ pages: accounts_response.data.accounts.data })
		// );
		// const pagesArray = accounts_response.data.accounts.data;
		// const instagram_business_accounts = [];
		// for (let i = 0; i < pagesArray.length; i++) {
		//   const instagram_business_account = await Axios.get(
		//     `https://graph.facebook.com/v11.0/${pagesArray[i].id}?fields=instagram_business_account&access_token=${pagesArray[i].access_token}`
		//   );

		//   if (instagram_business_account.data.instagram_business_account) {
		//     instagram_business_accounts.push({
		//       id: instagram_business_account.data.instagram_business_account.id,
		//       access_token: pagesArray[i].access_token,
		//       facebook_page_id: pagesArray[i].id,
		//       facebook_page: pagesArray[i].name,
		//     });
		//   }
		// }
		// localStorage.setItem(
		//   "insta_accounts",
		//   JSON.stringify({ accounts: instagram_business_accounts })
		// );
		// localStorage.setItem(
		//   "ig-user-id",
		//   JSON.stringify({ id: instagram_business_accounts[0].id })
		// );
		// window.location.reload();
	};

	function handlePostSummaryBack() {
		// const { accessToken } = isAuthenticated();
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};

		if (postSummaryIndex != 0) {
			setPostSummaryIndex(postSummaryIndex - 1);
			setInLoading(true);
			fetch(
				`${process.env.REACT_APP_BACKEND}/get_post_insights/` +
					instaPosts[parseInt(postSummaryIndex - 1)].id +
					"/",
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => {
					setPostSummaryReach(data.reach);
					setPostSummaryEngagement(data.engagement);
					setPostSummaryImage(instaPosts[postSummaryIndex - 1].media_url);

					if (postSummaryReach === 0)
						setPostSummaryReachChange(data.reach * 100);
					else
						setPostSummaryReachChange(
							parseInt(
								((data.reach - postSummaryReach) * 100) / postSummaryReach
							)
						);
					if (postSummaryEngagement === 0)
						setPostSummaryEngagementChange(data.engagement * 100);
					else
						setPostSummaryEngagementChange(
							parseInt(
								((data.engagement - postSummaryEngagement) * 100) /
									postSummaryEngagement
							)
						);
				});

			setInLoading(false);
			// console.log(data.reach, postSummaryReach)
		}
	}

	function handlePostSummaryFront() {
		// const { accessToken } = isAuthenticated();
		const requestOptions = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};

		if (postSummaryIndex < instaPosts.length - 1) {
			setInLoading(true);
			setPostSummaryIndex(postSummaryIndex + 1);
			fetch(
				`${process.env.REACT_APP_BACKEND}/get_post_insights/` +
					instaPosts[parseInt(postSummaryIndex + 1)].id +
					"/",
				requestOptions
			)
				.then((response) => response.json())
				.then((data) => {
					setPostSummaryReach(data.reach);
					setPostSummaryEngagement(data.engagement);
					setPostSummaryImage(instaPosts[postSummaryIndex + 1].media_url);

					if (postSummaryIndex < instaPosts.length - 2) {
						fetch(
							`${process.env.REACT_APP_BACKEND}/get_post_insights/` +
								instaPosts[parseInt(postSummaryIndex + 2)].id +
								"/",
							requestOptions
						)
							.then((response) => response.json())
							.then((oldStats) => {
								if (oldStats.reach === 0)
									if (oldStats.reach === 0)
										setPostSummaryReachChange(data.reach * 100);
									else
										setPostSummaryReachChange(
											parseInt(
												((data.reach - oldStats.reach) * 100) / oldStats.reach
											)
										);
								if (oldStats.engagement === 0)
									setPostSummaryEngagementChange(data.engagement * 100);
								else
									setPostSummaryEngagementChange(
										parseInt(
											((data.engagement - oldStats.engagement) * 100) /
												oldStats.engagement
										)
									);
							});
					}
				});

			setInLoading(false);
		}
	}

	function showToaster(message) {
		toast.info(message, {
			position: "top-center",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
		});
	}

	function handleDropdownChange(selectedPlace) {
		sethashtagDisplay({ ...hashtagDisplay, fetchingTags: true });
		var place = selectedPlace.woeid;
		// const { accessToken } = isAuthenticated();

		var requestOptions = {
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/get_trending_tags/` + place + "/",
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				//console.log(data)
				sethashtagDisplay({
					...hashtagDisplay,
					tags: data.trends,
					fetchingTags: false,
				});
				// this.setState({tags: data.trends, fetchingTags: false})
			})
			.catch((error) => console.log(error));
	}

	function socialMediaDropdown(selected) {
		if (selected.value === "Twitter") {
			console.log("load options");
		} else {
			showToaster("coming soon!");
		}
	}

	function onCopy(e, tag) {
		navigator.clipboard.writeText(tag.substring(1));
		toast.success("Tag Copied", {
			position: "top-center",
			theme: "dark",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}

	function loadAllLocationHashTag() {
		// const { accessToken } = isAuthenticated();
		// console.log("access token:", accessToken);

		var requestOptions = {
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		};

		fetch(
			`${process.env.REACT_APP_BACKEND}/get_trending_tags/` +
				hashtagDisplay.selectedPlace.woeid +
				"/",
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				// console.log(data.trends)
				sethashtagDisplay({
					...hashtagDisplay,
					tags: data.trends,
					fetchingTags: false,
				});
			});

		console.log(
			"hashtagDisplay.selectedPlace.woeid = ",
			hashtagDisplay.selectedPlace
		);
	}

	function highlightIfSelected(trendPlatform, socialMediaPlatform) {
		const style =
			trendPlatform === socialMediaPlatform
				? {
						backgroundColor: "rgba(132, 232, 244, 0.1)",
						color: "rgba(132, 232, 244, 1)",
				  }
				: {};

		return style;
	}

	// function handleDelinkButton() {

	//   const { accessToken } = isAuthenticated();
	//   const requestOptions = {
	//     headers: {
	//       Authorization: `Bearer ${accessToken}`
	//     }
	//   }

	//   fetch(`${process.env.REACT_APP_BACKEND}/delink_facebook/`, requestOptions)
	//     .then(response => response.json())
	//     .then(data => {
	//       toast.success('Removed Facebook Account Successfully', {
	//         position: "top-center",
	//         theme: 'dark',
	//         autoClose: 2500,
	//         hideProgressBar: true,
	//         closeOnClick: true,
	//         pauseOnHover: true,
	//         draggable: true,
	//         progress: undefined,
	//       });

	//       var userData = JSON.parse(localStorage.getItem("Erajwt"))
	//       delete userData['insta_username'];
	//       delete userData['fb_page_name'];
	//       userData.is_facebook_linked = false
	//       localStorage.setItem('Erajwt', JSON.stringify(userData))
	//       window.location.reload();

	//     });

	//   console.log(JSON.parse(localStorage.getItem("Erajwt")))
	// }

	return (
		<>
			{mobileview &&
				swal({
					className: "mobile-prompt",
					icon: "info",
					title: "Please use MyEra on Desktop for viewing this page.",
					text: " ",
					buttons: false,
					closeOnClickOutside: false,
					closeOnEsc: false,
					timer: 10000,
				}).then(() => {
					window.location.href = "/";
				})}
			<Wrapper id="outer-container">
				<LeftDiv>
					<StatsDiv>
						<BenchMarkScoreDiv>
							<div
								style={{ height: "1rem", borderRadius: "15px 15px 0 0" }}
							></div>
							<CircularProgressbarWrapper>
								<CircularProgressbarWithChildren
									styles={buildStyles({
										textColor: "red",
										pathColor: "rgba(255, 255, 255, 1)",
										trailColor: "rgba(122, 104, 235, 1)",
									})}
									value={score}
								>
									<div style={{ color: "white", fontSize: "3vh" }}>{score}</div>
									<div style={{ color: "rgba(255, 255, 255, 0.43)" }}>/100</div>
								</CircularProgressbarWithChildren>
							</CircularProgressbarWrapper>
							<div
								style={{
									color: "rgba(255, 255, 255, 1)",
									textAlign: "center",
									fontSize: "2.5vh",
								}}
							>
								MyEra Benchmark Score
							</div>
							<div
								style={{
									width: "100%",
									textAlign: "center",
								}}
							>
								{/*<div style={{ display: 'flex', justifyContent: 'center', height: '2.5vh', marginBottom: '2vh', fontSize: '6px' }}>
                        <img style={{ margin: '3px 0 0 0' }} src={UpArrow} alt="" />
                        <div style={{ color: 'white', fontSize: '2vh', marginLeft: '1vh' }}>9.1%</div>
                    </div>*/}
								<Button
									style={{
										backgroundColor: "rgba(122, 104, 235, 1)",
										color: "rgba(255, 255, 255, 1)",
										fontFamily: "Open Sans",
										textTransform: "none",
										height: "5vh",
										boxShadow: "none",
										borderRadius: "25px",
									}}
									variant="contained"
									onClick={() => showToaster("coming soon!")}
								>
									Show more
								</Button>
							</div>
						</BenchMarkScoreDiv>

						<StatisticDivsWrapper>
							<StatisticDiv>
								<FireIconWrapper>
									<img
										style={{ marginRight: "0.8vh" }}
										src={FireIcon}
										height="25"
										alt=""
									/>
									<div style={{ fontWeight: 675, fontSize: "10px" }}>
										Follower Count
									</div>
								</FireIconWrapper>
								<div style={{ width: "60%", margin: "auto" }}>
									<div style={{ fontSize: "3vh" }}>{followerCount}</div>
									{/*<div style={{ fontSize: '1.3vh', color: 'rgba(94, 216, 150, 1)' }}>
                          <img src={Polygon} alt="" /> 9.1%
                      </div>*/}
								</div>
							</StatisticDiv>
							<StatisticDiv>
								<FireIconWrapper>
									<img
										style={{ marginRight: "0.8vh" }}
										src={FireIcon}
										height="25"
										alt=""
									/>
									<div style={{ fontWeight: 675, fontSize: "10px" }}>
										Impressions (Day)
									</div>
								</FireIconWrapper>
								<div style={{ width: "60%", margin: "auto" }}>
									<div style={{ fontSize: "3vh" }}>{impressions}</div>
									{/*<div style={{ fontSize: '1.3vh', color: 'rgba(94, 216, 150, 1)' }}>
                          <img src={Polygon} alt="" /> 9.1%
                        </div>*/}
								</div>
							</StatisticDiv>
							<StatisticDiv>
								<FireIconWrapper>
									<img
										style={{ marginRight: "0.8vh" }}
										src={FireIcon}
										alt=""
										height="25"
									/>
									<div style={{ fontWeight: 675, fontSize: "10px" }}>
										Profile Views (Day)
									</div>
								</FireIconWrapper>
								<div style={{ width: "60%", margin: "auto" }}>
									<div style={{ fontSize: "3vh" }}>{profilViews}</div>
									{/*<div style={{ fontSize: '1.3vh', color: 'rgba(94, 216, 150, 1)' }}>
                          <img src={Polygon} alt="" /> 9.1%
                        </div>*/}
								</div>
							</StatisticDiv>
							<StatisticDiv>
								<FireIconWrapper>
									<img
										style={{ marginRight: "0.8vh" }}
										src={FireIcon}
										height="25"
										alt=""
									/>
									<div style={{ fontWeight: 675, fontSize: "10px" }}>
										Reach (Day)
									</div>
								</FireIconWrapper>
								<div style={{ width: "60%", margin: "auto" }}>
									<div style={{ fontSize: "3vh" }}>{reach}</div>
									{/*<div style={{ fontSize: '1.3vh', color: 'rgba(94, 216, 150, 1)' }}>
                          <img src={Polygon} alt="" /> 9.1%
                        </div>*/}
								</div>
							</StatisticDiv>
						</StatisticDivsWrapper>
					</StatsDiv>

					<div
						style={{
							backgroundImage: `url(${InstaRectangle})`,
							backgroundRepeat: "no-repeat",
							backgroundPosition: "right",
							margin: "5rem 1rem",
							height: "20rem",
							// margin: "5vh auto",
							// height: "45%",
							backgroundColor: "rgba(11, 17, 32, 1)",
							width: "95%",
							borderRadius: "15px",
						}}
					>
						<div
							style={{
								marginleft: "4vh",
								paddingTop: "6vh",
								width: "100%",
							}}
						>
							<Carousel
								nextLabel=""
								prevLabel=""
								style={{
									paddingLeft: "5%",
									marginRight: "5%",
									width: "100%",
									zIndex: "0",
								}}
							>
								{/* Adding New carousel Item cards here  */}

								<Carousel.Item className="carousel-item">
									<div className="carousel-item-heading">
										Check out what's Trending
									</div>

									<div className="carousel-item-subheading">
										Visit MyEra Trendwatch to pick an idea!
									</div>

									<Link style={{ textDecoration: "none" }} to="/trendwatch">
										<Button
											className="carousel-item-button"
											variant="contained"
										>
											Pick trends
										</Button>
									</Link>
								</Carousel.Item>
								{/* Adding New carousel Item cards here  */}

								<Carousel.Item className="carousel-item">
									<div className="carousel-item-heading">
										Auto-Generate Designs
									</div>

									<div className="carousel-item-subheading two-lines">
										Use MyEra content generator to create designs in just a
										matter of few clicks!
									</div>

									<Link style={{ textDecoration: "none" }} to="/create-now">
										<Button
											className="carousel-item-button"
											variant="contained"
										>
											New design
										</Button>
									</Link>
								</Carousel.Item>
								{/* Adding New carousel Item cards here  */}

								<Carousel.Item className="carousel-item">
									<div className="carousel-item-heading">Schedule Posts</div>

									<div className="carousel-item-subheading two-lines">
										Use MyEra Post Scheduler to plan and distribute content
										across multiple channels automatically!
									</div>

									<Link style={{ textDecoration: "none" }} to="/post-scheduler">
										<Button
											className="carousel-item-button"
											variant="contained"
										>
											New post
										</Button>
									</Link>
								</Carousel.Item>
								{/* Adding New carousel Item cards here  */}

								<Carousel.Item className="carousel-item">
									<div className="carousel-item-heading">How to use MyEra?</div>

									<div className="carousel-item-subheading">
										Watch a quick demo video to start using MyEra like a PRO!
									</div>

									<Link style={{ textDecoration: "none" }} to="/how-to-use">
										<Button
											className="carousel-item-button"
											variant="contained"
										>
											Watch video
										</Button>
									</Link>
								</Carousel.Item>
							</Carousel>
						</div>
					</div>
				</LeftDiv>

				{/* style={{ borderLeft: "2px solid #313849", height: "750px", marginLeft: "4%", marginTop: "10px" }} */}
				<div
					className="vl"
					style={{
						borderLeft: "2px solid #313849",
						height: "750px",
						marginLeft: "4%",
					}}
				></div>

				<RightDiv>
					<TrendDiv>
						<TrendDivBody>
							<div
								style={{
									fontSize: "3vh",
									color: "white",
									marginBottom: "1vh",
								}}
							>
								Trending Hashtags
							</div>
							{/* <div style={{ display: "flex" }}>

                <FluroscentTag
                  style= {highlightIfSelected(trendPlatform , "Twitter")}
                  onClick={() => setTrendPlatform("Twitter")}
                >
                  Twitter
                </FluroscentTag>
                <FluroscentTag
                  style= {highlightIfSelected(trendPlatform , "Instagram")}

                  onClick={() => showToaster('coming soon!')}
                >
                  Instagram
                </FluroscentTag>

                <FluroscentTag
                  style= {highlightIfSelected(trendPlatform , "Facebook")}

                  onClick={() => showToaster('coming soon!')}
                >
                  Facebook
                </FluroscentTag>

                <FluroscentTag
                  style= {highlightIfSelected(trendPlatform , "LinkedIn")}

                  onClick={() => showToaster('coming soon!')}
                >
                  LinkedIn
                </FluroscentTag>
              </div> */}

							{/* style={{ marginTop: "2vh", overflow: "hidden"}} */}
							<div className="card-background">
								<Card className="cardStyle">
									<div className="card-top">
										{/* <div className="trending-hashtag-social-media-buttons" >

                      <FluroscentTag
                        style={highlightIfSelected(trendPlatform, "Twitter")}
                        onClick={() => setTrendPlatform("Twitter")}
                      >
                        Twitter
                      </FluroscentTag>
                      <FluroscentTag
                        style={highlightIfSelected(trendPlatform, "Instagram")}
                        onClick={() => showToaster('coming soon!')}
                      >
                        Instagram
                      </FluroscentTag>

                      <FluroscentTag
                        style={highlightIfSelected(trendPlatform, "Facebook")}
                        onClick={() => showToaster('coming soon!')}
                      >
                        Facebook
                      </FluroscentTag>

                      <FluroscentTag
                        style={highlightIfSelected(trendPlatform, "LinkedIn")}
                        onClick={() => showToaster('coming soon!')}
                      >
                        LinkedIn
                      </FluroscentTag>

                    </div> */}

										{/* style={{display: "flex",  marginTop: "4%" , paddingLeft: '3%'}} */}

										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												width: "100%",
												paddingLeft: 0,
												margin: 0,
												gap: "10px",
											}}
										>
											{/* Social Media button dropdown menu  */}
											<div>
												<Select
													styles={customStyles}
													onChange={socialMediaDropdown}
													options={platformButtons}
													placeholder={"Twitter"}
													isOptionDisabled={(option) => option.isdisabled} // disable an option
													theme={(theme) => ({
														...theme,
														colors: {
															...theme.colors,
															neutral50: "#FFFFFF", // Placeholder color
														},
													})}
												/>
											</div>

											{/* location dropdown menu  */}
											<div>
												<Select
													styles={customStyles}
													onChange={handleDropdownChange}
													options={hashtagDisplay.places}
													placeholder={"All Location"}
													theme={(theme) => ({
														...theme,
														colors: {
															...theme.colors,
															neutral50: "#FFFFFF", // Placeholder color
														},
													})}
												/>
											</div>
										</div>
									</div>

									{/* 
                  <LinearProgress style={{ display: (hashtagDisplay.fetchingTags ? 'block' : 'none') }} />

                  <div style={{ paddingBottom: '1rem', display: hashtagDisplay.fetchingTags ? 'block' : 'none', height: '100px' }}>

                  </div> */}

									<div
										className="trending-hashtags card-middle"
										style={{
											display: hashtagDisplay.fetchingTags ? "none" : "flex",
										}}
									>
										{hashtagDisplay.tags.slice(0, 3).map((tag, index) => {
											return (
												<div style={{ margin: "0.75%", borderRadius: "5px" }}>
													<div
														className="copyText"
														style={{
															fontSize: "20px",
															fontWeight: "100",
															padding: "5px",
															color: "white",
														}}
														onClick={(e) => onCopy(e, tag.name)}
													>
														{index + 1}. {tag.name}
													</div>
												</div>
											);
										})}

										{/* <div style={{textAlign: "center", color: "#007AFF",paddingBottom: '0'}}>
                      <Link to="/trendwatch/trending-hashtag" style={{textDecoration: "none" ,  fontSize:'15px'}}>View All</Link>
                    </div> */}
									</div>

									<div
										className="card-bottom"
										style={{
											textAlign: "center",
											color: "#007AFF",
											paddingBottom: "1rem",
										}}
									>
										<Link
											to="/trendwatch/trending-hashtag"
											style={{
												textDecoration: "none",
												fontSize: "15px",
												paddingBottom: "3vh",
											}}
										>
											View All
										</Link>
									</div>
								</Card>
							</div>

							{/* <div style={{ marginTop: "2vh", overflow: "hidden" }}>
                <AreaChart
                  width={350}
                  height={150}
                  data={graphData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="score"
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                </AreaChart>

              </div> */}
						</TrendDivBody>
					</TrendDiv>
					<PostSUmmaryDiv>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}
						>
							<div
								style={{ textAlign: "left", fontSize: "3.1vh", color: "white" }}
							>
								Post Summary
							</div>
							<div style={{ display: "flex" }}>
								{/* <BackNextButton>
                            <img
                              style={{ margin: "auto", height: "3vh", opacity: (postSummaryIndex === 0) ? "0.3" : "1"}}
                              src={SummaryLeftArrow}
                              alt=""
                              onClick={handlePostSummaryBack}
                            />
                          </BackNextButton> */}
								<ArrowBackIosIcon
									onClick={handlePostSummaryBack}
									style={{
										color: "white",
										marginTop: "15%",
										marginRight: "5%",
										cursor: "pointer",
									}}
								/>
								<ArrowForwardIosOutlinedIcon
									onClick={handlePostSummaryFront}
									style={{
										color: "white",
										marginTop: "15%",
										cursor: "pointer",
									}}
								/>
								{/* <BackNextButton>
                            <img
                              style={{ margin: "auto", height: "3vh", opacity: (postSummaryIndex === instaPosts.length-1) ? "0.3" : "1"}}
                              src={SummaryRightArrow}
                              alt=""
                              onClick={handlePostSummaryFront}
                            />
                          </BackNextButton> */}
							</div>
						</div>

						<LinearProgress
							style={{
								display: inLoading ? "block" : "none",
								width: "95%",
								marginLeft: "3%",
							}}
							sx={{ color: "rgba(98, 77, 232, 1)" }}
						></LinearProgress>

						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								borderRadius: "15px",
								backgroundColor: "white",
								marginTop: "2vh",
							}}
						>
							<div style={{ width: "50%" }}>
								<img
									style={{
										height: "27vh",

										margin: "1vh",
										borderRadius: "2vh",
									}}
									src={postSummaryImage}
									alt=""
								/>
							</div>
							{/* Uncommented below StaticDivsWrapper for testing*/}
							<StatisticDivsWrapper
								style={{ flexDirection: "column", marginRight: "-20%" }}
							>
								<StatisticDiv>
									<FireIconWrapper>
										<img
											style={{ marginRight: "0.8vh" }}
											src={FireIcon}
											height="25"
											alt=""
										/>
										<div>Organic Reach</div>
									</FireIconWrapper>
									<div style={{ width: "60%", margin: "auto" }}>
										<div style={{ fontSize: "3vh" }}>{postSummaryReach}</div>
										<div
											style={{
												fontSize: "1.3vh",
												color:
													postSummaryReachChange >= 0
														? "rgba(94, 216, 150, 1)"
														: "red",
											}}
										>
											{postSummaryReachChange < 0 ? (
												<svg style={{ height: 20, width: 20 }}>
													<polygon
														points="0,1 7,1 3.5,7"
														fill="red"
														stroke="red"
													/>
												</svg>
											) : (
												<img src={Polygon} alt="" />
											)}{" "}
											{postSummaryReachChange}%
										</div>
									</div>
								</StatisticDiv>

								<StatisticDiv>
									<FireIconWrapper>
										<img
											style={{ marginRight: "0.8vh" }}
											src={FireIcon}
											alt=""
											height="25"
										/>
										<div>Engagement</div>
									</FireIconWrapper>
									<div style={{ width: "60%", margin: "auto" }}>
										<div style={{ fontSize: "3vh" }}>
											{postSummaryEngagement}
										</div>
										<div
											style={{
												fontSize: "1.3vh",
												color:
													postSummaryEngagementChange >= 0
														? "rgba(94, 216, 150, 1)"
														: "red",
											}}
										>
											{postSummaryEngagementChange < 0 ? (
												<svg style={{ height: 5, width: 7 }}>
													<polygon
														points="0,0 6.5,0 3.5,7"
														fill="red"
														stroke="red"
													/>
												</svg>
											) : (
												<img src={Polygon} alt="" />
											)}{" "}
											{postSummaryEngagementChange}%
										</div>
									</div>
								</StatisticDiv>
							</StatisticDivsWrapper>
						</div>
					</PostSUmmaryDiv>
				</RightDiv>
			</Wrapper>
		</>
	);
}
