import React, { createContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import appReducer from './appReducer';

export const appContext = createContext();
export const dispatchContext = createContext();

export const Statecontext = props => {
  const initialState = {
    loading: false
  };

  const [state, dispatch] = useImmerReducer(appReducer, initialState);

  useEffect(() => {
    let mounted = true;

    return () => {
      mounted = false;
    };
  }, [dispatch]);

  return (
    <appContext.Provider value={{ state }}>
      <dispatchContext.Provider value={dispatch}>{props.children}</dispatchContext.Provider>
    </appContext.Provider>
  );
};
